import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { format } from 'date-fns'
import { toLower } from 'lodash'
import { useSelector } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { useUser } from '../../auth/hooks/useUser'

/**
 * Query params used in GET /leader_time_off_decisions in the time off api (for a given range of dates, location and
 * optional parameters - list of keys, departments, jobs, statuses, types, perPage, sortOrder and for a given page
 * returns all the eligible team member time off records in the sort order specified).
 *
 * @param selectedLocationId Required - String - 4 number string representing location number
 * @param startDate Required - String (Format 'yyyy-MM-dd') - start range of which to get requests by their effective date.
 * @param endDate Required - String (Format 'yyyy-MM-dd') - end range of which to get requests by their effective date.
 * @param searchableName Optional - String - Character sequence used to match first or last name
 * @param columnSortBy Optional - String - Name of data to sort results by
 * @param sortOrder Optional - String (default: 'ASC') - Either 'ASC' or 'DESC'
 * @param filterParams Optional - Array of objects. Note: objects in array can have same key
 *                     Example [{ key: 'statuses', value: 'Pending' },
 *                              { key: 'statuses', value: 'Failed' },
 *                              { key: 'types', value: 'PTO - Vacation' }]
 * @param filterRequired Optional - Boolean - A flag to determine if a filter value is required depending on user.
 * @param perPage Optional - Number (default: 50) - The amount of records per page. Max is 500.
 * @param pageNum Optional - Number (default: 1) - The pagination page number. First page starts at 1.
 * @param category Optional - snake case value.
 */
export function TimeOffRequestsQueryParams(
  selectedLocationId,
  startDate,
  endDate,
  searchableName,
  columnSortBy,
  sortOrder,
  filterParams,
  filterRequired,
  perPage,
  pageNum,
  category,
) {
  const { locationData } = useUser().user

  this.selectedLocationId = selectedLocationId
  this.startDate = startDate
  this.endDate = endDate
  this.searchableName = searchableName
  this.columnSortBy = columnSortBy
  this.sortOrder = sortOrder
  this.filterParams = filterParams
  this.filterRequired = filterRequired
  this.perPage = perPage
  this.pageNum = pageNum
  this.category = category

  this.getUrlSearchParams = function () {
    const params = new URLSearchParams()
    params.append('key', `${apiConfig.timeOff.key}`)
    if (this.selectedLocationId) params.append('location_id', this.selectedLocationId)
    if (this.startDate) params.append('start_date', this.startDate)
    if (this.endDate) params.append('end_date', this.endDate)
    if (this.searchableName) params.append('searchable_name', this.searchableName)
    if (this.columnSortBy) params.append('sort_by', this.columnSortBy)
    if (this.sortOrder) params.append('sort_order', this.sortOrder)
    if (this.filterParams) {
      this.filterParams.forEach((queryParam) => {
        params.append(queryParam.key, queryParam.value)
      })
    }
    if (this.filterRequired !== null) params.append('filter_required', this.filterRequired)
    if (this.pageNum) params.append('page', this.pageNum)
    if (this.perPage) params.append('per_page', this.perPage)
    if (this.category) {
      params.append(
        'category',
        category === 'time_off' || category === 'time_off_partial_action' || category === 'time_off_read_only'
          ? `${'time_off'}_${toLower(locationData.location_type)}`
          : category,
      )
    }
    return params
  }

  this.hasRequiredParams = function () {
    return !!(this.selectedLocationId && this.startDate && this.endDate && typeof this.filterRequired == 'boolean')
  }

  this.getQueryKey = function () {
    return [
      'timeOffRequests',
      this.category,
      this.selectedLocationId,
      this.startDate,
      this.endDate,
      this.searchableName,
      this.columnSortBy,
      this.sortOrder,
      this.filterParams,
      this.filterRequired,
      this.perPage,
      this.pageNum,
    ]
  }
}

const getTimeOffRequests = (queryParamsString) => {
  // temporary code until availability hook up with actual endpoint
  const searchParams = new URLSearchParams(queryParamsString)
  let apiUrl = ''
  if (searchParams.get('category') === 'availability') {
    apiUrl = `${apiConfig.storeAvailability.url}` + `/leader_availability_decisions?` + queryParamsString
  } else if (searchParams.get('category') === 'punch_correction') {
    apiUrl = `${apiConfig.punchCorrection.url}` + `/leader_punch_correction_decisions?` + queryParamsString
  } else {
    queryParamsString = queryParamsString.replace('time_off_office', 'time_off_str')
    apiUrl = `${apiConfig.timeOff.url}` + `/leader_time_off_decisions?` + queryParamsString
  }

  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

export function useTimeOffRequests(timeOffRequestsQueryParams) {
  const { isAuthenticated, login } = useAuth()
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }
  const selectedCategory = useSelector((state) => state.leader.selectedCategory)
  const selectedLocation = useSelector((state) => state.leader.selectedLocation)
  const selectedCategoryState = useSelector((state) => state.leader[selectedCategory])

  let queryParams = timeOffRequestsQueryParams
    ? timeOffRequestsQueryParams
    : new TimeOffRequestsQueryParams(
        selectedLocation,
        selectedCategoryState?.startDate ? format(selectedCategoryState?.startDate, 'yyyy-MM-dd') : null,
        selectedCategoryState?.endDate ? format(selectedCategoryState?.endDate, 'yyyy-MM-dd') : null,
        selectedCategoryState?.searchText,
        getSelectedSortBy(selectedCategoryState?.sortableColumns, selectedCategoryState?.selectedSortByIndex),
        selectedCategoryState?.sortOrder,
        getSelectedFilters(selectedCategoryState?.filters),
        false,
        selectedCategoryState?.rowsPerPage,
        selectedCategoryState?.page,
        selectedCategoryState?.configFeatureName,
      )

  return useQuery({
    queryKey: queryParams.getQueryKey(),
    queryFn: () => getTimeOffRequests(queryParams.getUrlSearchParams().toString()),
    enabled: queryParams.hasRequiredParams(),
    keepPreviousData: true,
    staleTime: 1000 * 5,
    onError: (error) => {
      const loggingInfo = {
        message: 'GET /leader_time_off_decisions response has an error (useTimeOffRequests).',
        url: window.location.href,
        error: {
          url: error?.config?.url,
          data: error?.response?.data,
          headers: error?.response?.headers,
          status: error?.response?.status,
          statusText: error?.response?.statusText,
          timeOffRequestsQueryParams: timeOffRequestsQueryParams,
        },
      }
      const optionalLoggingOptions = {
        level: LogLevel.Error,
      }
      logEvent(loggingInfo, optionalLoggingOptions)
    },
  })
}

const getSelectedFilters = (filters) => {
  let selectedArray = []
  if (filters) {
    Object.entries(filters).forEach(([key, filterObject]) => {
      filterObject?.selectedValues?.forEach((value) => {
        selectedArray.push({ key, value })
      })
    })
  }
  if (selectedArray === undefined || selectedArray?.length === 0) return null
  return selectedArray
}

const getSelectedSortBy = (sortableColumns, index) => {
  if (sortableColumns === undefined || index === undefined) return null
  return sortableColumns[index]?.columnName
}
