import JobIcon from '@mui/icons-material/Label'
import { Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useAuth } from '@praxis/component-auth'
import { differenceInMinutes } from 'date-fns'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { giveUpShift, rtcCancelShift } from '../../store/WeeklySchedulePage/actionCreator'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { MultiClickButton as ConfirmGiveUpShiftButton } from '../../utils/MultiClickButton'
import { truncateString } from '../../utils/strings'
import DisplayPostShiftSegment from './DisplayPostShiftSegment'

const ConfirmPostShiftModal = (props) => {
  const { styles, selectedShift, schedule } = props
  const outputDateFormat = 'dddd, MMMM D'
  const [processIndicator, setProcessIndicator] = useState(false)
  const auth = useAuth()
  const { session } = auth

  const dispatch = useDispatch()

  const postShiftPostResponse = useSelector((state) => state.weeklySchedule.postShiftPostResponse)
  const postShiftPostErrResponse = useSelector((state) => state.weeklySchedule.postShiftPostErrResponse)

  useEffect(() => {
    setProcessIndicator(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postShiftPostResponse, postShiftPostErrResponse])

  const handleSubmit = () => {
    setProcessIndicator(true)
    selectedShift.status !== 'PENDING' ? processPostShiftRequest() : processRetractShiftRequest()
  }

  const processPostShiftRequest = () => {
    const { segment_start, segment_end, location, jobs, shift_label } = props.selectedShift
    const { userInfo } = session
    let format = 'YYYY-MM-DDTHH:mm:ss'
    let segmentStart = moment(segment_start)?.format(format)
    let segmentEnd = moment(segment_end)?.format(format)
    let org_ids_ids = []
    org_ids_ids.push(...jobs.map((job) => job.org_ids_id))

    const requestBody = {
      worker_id: padEmpIdWithZeros(userInfo?.empid, 10),
      segment_start: segmentStart,
      segment_end: segmentEnd,
      location_id: location,
      org_ids_ids: org_ids_ids,
      shift_label: shift_label ? shift_label : null,
    }
    dispatch(giveUpShift(requestBody))

    props.disableCoverShiftButton()
    handleClose()
  }

  const processRetractShiftRequest = () => {
    const { request_id, status } = props.selectedShift
    const requestBody = {
      request_id: request_id,
      request_status: status,
      updated_status: 'CANCELLED',
    }
    dispatch(rtcCancelShift(requestBody))
    props.disableCoverShiftButton()
    handleClose()
  }

  const renderJob = (jobs) => {
    return (
      <React.Fragment>
        {jobs.map((job, index) => (
          <div style={styles.jobContainerAtPopup} key={index}>
            <JobIcon color="primary" sx={styles.iconMargin} />
            <Typography variant="body2" sx={styles.jobName}>
              {job}
            </Typography>
          </div>
        ))}
      </React.Fragment>
    )
  }

  const handleClose = () => {
    props.handleClose()
  }

  const handleDialogTitle = () => {
    return selectedShift.status !== 'PENDING' ? 'Post Shift' : 'Posted Shift'
  }

  const handleHours = (shift) => {
    let shiftInHours =
      differenceInMinutes(
        new Date(shift.segment_end.split(' ')[0] + 'T' + shift.segment_end.split(' ')[1]),
        new Date(shift.segment_start.split(' ')[0] + 'T' + shift.segment_start.split(' ')[1]),
      ) / 60
    return Number.parseFloat(shiftInHours).toFixed(2)
  }

  const renderDialogInfoText = () => {
    return (
      <>
        <Typography sx={styles.displaySegmentContainer} variant="body2">
          {selectedShift.status !== 'PENDING'
            ? 'This action will post your shift and make it available for other team members to pick up.'
            : 'Your shift has not yet been covered by another team member.'}
        </Typography>
        <Typography sx={styles.displaySegmentContainer} variant="body2">
          {selectedShift.status !== 'PENDING' ? (
            <span>
              <b>Attention:</b> You are responsible for this shift until it is removed from your schedule.
            </span>
          ) : (
            <span>
              <b>Attention:</b> You are responsible for this shift until it is removed from your schedule.
            </span>
          )}
        </Typography>
      </>
    )
  }

  const ShiftLabel = () => {
    const { selectedShift } = props
    if (selectedShift.shift_label) {
      let shiftLabel = truncateString(selectedShift.shift_label, 3)

      return (
        <Typography sx={styles.shiftLabelContainerInModal}>
          <div style={styles.shiftLabel}>
            <span>{`${shiftLabel}`}</span>
          </div>
        </Typography>
      )
    } else {
      return null
    }
  }

  return (
    <Dialog
      disableEscapeKeyDown
      open={props.open}
      aria-label="responsive-dialog-title"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason)
        }
      }}
    >
      <DialogTitle id="responsive-dialog-title">{handleDialogTitle()}</DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={styles.displaySegmentContainer}>
          <Typography>
            {selectedShift.status !== 'PENDING' ? 'Posting a ' : 'You have previously posted this '}
            <b>{handleHours(selectedShift)} hour</b> shift on{' '}
            <b>{moment(schedule.schedule_date)?.format(outputDateFormat)}</b>
          </Typography>
        </Grid>
        <DisplayPostShiftSegment shift={selectedShift} styles={styles} />
        <ShiftLabel />
        {selectedShift.jobList && selectedShift.jobList.length > 0 && (
          <div style={styles.addtionalJobsContainer}>
            <Typography variant="body2">This shift contains job transfers:</Typography>
            {renderJob(selectedShift.jobList)}
          </div>
        )}
        {renderDialogInfoText()}
      </DialogContent>
      <DialogActions>
        {selectedShift.status !== 'PENDING' ? (
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <ConfirmGiveUpShiftButton
              label={'Confirm'}
              handleClick={handleSubmit}
              handleDoubleClick={handleSubmit}
              processIndicator={processIndicator}
              styles={styles}
              pageName="postShiftConfirm"
            />
          </>
        ) : (
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            &nbsp; &nbsp;
            <ConfirmGiveUpShiftButton
              label={'Retract Shift'}
              handleClick={handleSubmit}
              handleDoubleClick={handleSubmit}
              processIndicator={processIndicator}
              styles={styles}
              pageName="postShiftConfirm"
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmPostShiftModal
