import {
  GET_DAILY_SCHEDULE_ERROR,
  GET_DAILY_SCHEDULE_SUCCESS,
  GET_TIMECARD_DATA_DAILY_ERROR,
  GET_TIMECARD_DATA_DAILY_SUCCESS,
  SET_TIMECARD_DAILY_LOADING,
  SET_TIMECARD_DAILY_SELECTED_DATE,
  SET_TOTAL_SCHEDULED_HRS_DAILY_LOADING,
} from './actionType'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { format } from 'date-fns'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function getTimecardDataDaily(tmNumber, selectedDate, locationId) {
  const dateFormat = 'yyyy-MM-dd'
  let date = format(selectedDate, dateFormat)

  return (dispatch) => {
    let apiUrl =
      `${apiConfig.timecards.url}` +
      '/timecard_daily_view' +
      '?date=' +
      date +
      '&location_id=' +
      locationId +
      '&worker_id=' +
      tmNumber +
      '&key=' +
      `${apiConfig.timecards.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleDailySuccess(response))
      })
      .catch((err) => {
        dispatch(handleDailyError(err))
      })
  }
}

export function handleDailySuccess(data) {
  return {
    type: GET_TIMECARD_DATA_DAILY_SUCCESS,
    data,
  }
}

export function handleDailyError(data) {
  return {
    type: GET_TIMECARD_DATA_DAILY_ERROR,
    data,
  }
}

export function setTimecardDailyLoading(text) {
  return {
    type: SET_TIMECARD_DAILY_LOADING,
    payload: text,
  }
}

export function setTimecardDailySelectedDate(date) {
  return {
    type: SET_TIMECARD_DAILY_SELECTED_DATE,
    date,
  }
}

export function handleDailyScheduleGetData(tmNumber, selectedDate, locationId) {
  const dateFormat = 'yyyy-MM-dd'

  let scheduleDate = format(selectedDate, dateFormat)

  return (dispatch) => {
    let apiUrl =
      `${apiConfig.schedule.url}` +
      '/daily_schedules' +
      '?team_member_number=' +
      tmNumber +
      '&schedule_date=' +
      scheduleDate +
      '&location_id=' +
      locationId +
      '&key=' +
      `${apiConfig.schedule.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetDailyScheduleSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetDailyScheduleError(response))
      })
  }
}

export function handleGetDailyScheduleError(record) {
  return {
    type: GET_DAILY_SCHEDULE_ERROR,
    record,
  }
}

export function handleGetDailyScheduleSuccess(record) {
  return {
    type: GET_DAILY_SCHEDULE_SUCCESS,
    record,
  }
}

export function setTotalScheduledHrsDailyLoading(text) {
  return {
    type: SET_TOTAL_SCHEDULED_HRS_DAILY_LOADING,
    payload: text,
  }
}
