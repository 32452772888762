import {
  CLEAR_PREVIOUS_STATE,
  DESIRED_HOURS_POST_REQUEST_ERROR,
  DESIRED_HOURS_POST_REQUEST_SUCCESS,
  SET_DESIRED_HOURS_LOADING,
} from './actionType'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function postDesiredHours(requestBody) {
  return (dispatch) => {
    let apiUrl = `${apiConfig.worker.url}` + '/worker_desired_hours' + '?key=' + `${apiConfig.worker.key}`

    return axios
      .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handlePostRequestSuccess(response))
      })
      .catch((response) => {
        dispatch(handlePostRequestError(response))
      })
  }
}

export function handlePostRequestError(payload) {
  return {
    type: DESIRED_HOURS_POST_REQUEST_ERROR,
    payload,
  }
}

export function handlePostRequestSuccess(payload) {
  return {
    type: DESIRED_HOURS_POST_REQUEST_SUCCESS,
    payload,
  }
}

export function setDesiredHoursLoading(payload) {
  return {
    type: SET_DESIRED_HOURS_LOADING,
    payload,
  }
}

export function clearPreviousState() {
  return {
    type: CLEAR_PREVIOUS_STATE,
  }
}
