import {
  RESET_FILTERS,
  SET_CONTROL_PANEL_SETTINGS,
  SET_FILTERS,
  SET_LEADER_CONFIG,
  SET_LEADER_DATE,
  SET_LEADER_FILTER_CONFIG,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  SET_SEARCH_NAME_TEXT,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_LOCATION,
  SET_SORT_BY,
  SET_SORT_ORDER,
  SET_TOTAL_COUNT,
  TOGGLE_PANEL_EXPAND_COLLAPSE,
} from './actionType'

export function setSelectedLocation(location) {
  return {
    type: SET_SELECTED_LOCATION,
    payload: location,
  }
}

export function setSelectedCategory(table) {
  return {
    type: SET_SELECTED_CATEGORY,
    payload: table,
  }
}

export function setSortOrder(sortOrder) {
  return {
    type: SET_SORT_ORDER,
    payload: sortOrder,
  }
}

export function setSortBy(sortBy) {
  return {
    type: SET_SORT_BY,
    payload: sortBy,
  }
}

export function setLeaderConfig(config) {
  return {
    type: SET_LEADER_CONFIG,
    payload: config,
  }
}

export function setDate(name, date) {
  return {
    type: SET_LEADER_DATE,
    name: name,
    date: date,
  }
}

export function togglePanelExpandCollapse() {
  return {
    type: TOGGLE_PANEL_EXPAND_COLLAPSE,
  }
}

export function setLeaderFilterConfig(filterData, selectedCategory) {
  return {
    type: SET_LEADER_FILTER_CONFIG,
    payload: filterData,
    selectedCategory: selectedCategory,
  }
}

export function resetFilters() {
  return {
    type: RESET_FILTERS,
  }
}

export function setFilters(filterKey, value) {
  return {
    type: SET_FILTERS,
    filterKey: filterKey,
    value: value,
  }
}

export function setSearchNameText(searchText) {
  return {
    type: SET_SEARCH_NAME_TEXT,
    searchText: searchText,
  }
}

export function setPage(page) {
  return {
    type: SET_PAGE,
    payload: page,
  }
}

export function setRowsPerPage(rows) {
  return {
    type: SET_ROWS_PER_PAGE,
    payload: rows,
  }
}

export function setTotalCount(totalCount) {
  return {
    type: SET_TOTAL_COUNT,
    payload: totalCount,
  }
}

export function setControlPanelSettings(category, startDate, endDate, filterOverride, searchText, sortBy, sortOrder) {
  return {
    type: SET_CONTROL_PANEL_SETTINGS,
    category: category,
    startDate: startDate,
    endDate: endDate,
    filterOverride: filterOverride,
    searchText: searchText,
    sortBy: sortBy,
    sortOrder: sortOrder,
  }
}
