import { Box, CircularProgress, Divider, Paper, Typography, useTheme } from '@mui/material'
import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useInfiniteRequests } from '../../api/requests/useRequests'
import {
  REQUEST_TYPE_AVAILABILITY,
  REQUEST_TYPE_MASS_VACATION,
  REQUEST_TYPE_PUNCH_CORRECTION,
} from '../../constants/RequestConstants'
import {
  ERROR_CODES_SHOW_MESSAGE,
  LOAD_REQUESTS_ERROR_MESSAGE,
  NO_REQUESTS_MESSAGE,
  TIME_OFF_ERROR_CODE,
} from '../../constants/errorConstants'
import { formatErrorCode } from '../../utils/ErrorHandling'
import AvailabilityRequestAccordion from './AvailabilityRequestAccordion'
import MassVacationGuidelines from './MassVacation/MassVacationGuidelines'
import MassVacationRequestAccordion from './MassVacation/MassVacationRequestAccordion'
import PunchCorrectionRequestAccordion from './PunchCorrection/PunchCorrectionRequestAccordion'
import RequestAccordion from './RequestAccordion'
import { useUser } from '../../auth/hooks/useUser'

const getStyles = (theme) => ({
  mainScrollContainer: {
    ...theme.mainScrollContainer,
    [theme.breakpoints.up('md')]: {
      marginBottom: '20px',
    },
  },
  mainContainerPosition: theme.mainContainerPosition,
  infoMessage: theme.infoMessages,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  loadingIconContainer: theme.loadingIconContainer,

  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
})

const RequestsBody = ({ status }) => {
  const { locationData } = useUser().user
  let requestType = useSelector((state) => state.requests.requestType)
  const theme = useTheme()
  const styles = getStyles(theme)

  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage, error, isLoading, isFetching } =
    useInfiniteRequests(status)

  // ========== begin: handle infinite scroll observer ============
  const observerRef = useRef(null)

  const callbackFunction = useCallback(
    (entries) => {
      const [entry] = entries
      if (entry.isIntersecting && hasNextPage) {
        fetchNextPage()
      }
    },
    [fetchNextPage, hasNextPage],
  )

  useEffect(() => {
    if (data) {
      const options = { threshold: 0 }
      const currentRef = observerRef.current
      const observer = new IntersectionObserver(callbackFunction, options)
      if (currentRef) observer.observe(currentRef)

      return () => {
        if (currentRef) observer.unobserve(currentRef)
      }
    }
  }, [data, observerRef, callbackFunction])

  // ========== end: handle infinite scroll observer ============

  const infiniteScrollLoader = (size = 24) => {
    if (isFetchingNextPage) {
      return (
        <Box sx={styles.loadMoreContainer}>
          <CircularProgress size={size} />
        </Box>
      )
    }
  }

  const loader = (size = 48) => {
    if (isLoading && isFetching) {
      return (
        <Box sx={styles.loadingIconContainer}>
          <CircularProgress size={size} />
        </Box>
      )
    }
  }

  const displayErrors = (errorObj) => {
    if (errorObj.message && errorObj.code && ERROR_CODES_SHOW_MESSAGE.includes(errorObj.code)) {
      return (
        <Box sx={styles.mainContainerPosition}>
          <p style={styles.errorMessage}>{errorObj.message}</p>
        </Box>
      )
    } else {
      return (
        <Box sx={styles.mainContainerPosition}>
          <p style={styles.errorMessage}>
            {LOAD_REQUESTS_ERROR_MESSAGE}
            <span style={styles.errorCodeMessage}>{formatErrorCode(errorObj, TIME_OFF_ERROR_CODE)}</span>
          </p>
        </Box>
      )
    }
  }

  const renderData = () => {
    return (
      <>
        {data.pages.map((page, pageIndex) => {
          return page.requests.map((request, requestIndex) => {
            if (requestType === REQUEST_TYPE_AVAILABILITY) {
              return (
                <AvailabilityRequestAccordion
                  key={(pageIndex + 1) * requestIndex}
                  request={request}
                  workerId={page.worker_id}
                  isoTimeZone={locationData.iso_time_zone_code}
                />
              )
            } else if (requestType === REQUEST_TYPE_PUNCH_CORRECTION) {
              return (
                <PunchCorrectionRequestAccordion
                  key={(pageIndex + 1) * requestIndex}
                  request={request}
                  workerId={page.worker_id}
                  isoTimeZone={locationData.iso_time_zone_code}
                />
              )
            } else if (requestType === REQUEST_TYPE_MASS_VACATION) {
              return (
                <MassVacationRequestAccordion
                  key={(pageIndex + 1) * requestIndex}
                  request={request}
                  shiftLengthMinutes={parseInt(data?.pages[0]?.shift_length_minutes) || 0}
                  workerId={page.worker_id}
                  isoTimeZone={locationData.iso_time_zone_code}
                />
              )
            } else {
              return (
                <RequestAccordion
                  key={(pageIndex + 1) * requestIndex}
                  request={request}
                  workerId={page.worker_id}
                  isoTimeZone={locationData.iso_time_zone_code}
                />
              )
            }
          })
        })}
      </>
    )
  }

  const renderMessage = () => {
    if (error !== null) {
      return displayErrors(error)
    } else if (isSuccess && data.pages[0].total_requests === 0) {
      return displayInfoMessage(NO_REQUESTS_MESSAGE)
    }
  }

  const renderScrollableContent = () => {
    if (data) {
      return renderData()
    }
  }

  const displayInfoMessage = (message) => {
    return (
      <Box sx={styles.mainContainerPosition}>
        <p style={styles.infoMessage}>{message}</p>
      </Box>
    )
  }

  return (
    <Box sx={styles.mainContainerPosition}>
      <Paper elevation={1} square sx={styles.mainScrollContainer}>
        {requestType === 'mass_vacation' ? (
          <MassVacationGuidelines
            massVacationResponse={data?.pages[0]}
            totalRequests={data?.pages[0]['x-total-count']}
          />
        ) : (
          <></>
        )}
        {renderScrollableContent()}
        <Divider ref={observerRef} />
        {infiniteScrollLoader()}
      </Paper>
      {requestType ? (
        loader()
      ) : (
        <Typography textAlign={'center'} padding={1}>
          Please select a request type
        </Typography>
      )}
      {renderMessage()}
    </Box>
  )
}

export default RequestsBody
