// error codes
export const ERROR_CODES_SHOW_MESSAGE = [
  'wfm-1-5',
  'wfm-2-5',
  'wfm-2-11',
  'wfm-14-5',
  'wfm-14-11',
  'wfm-15-5',
  'wfm-15-11',
  'wfm-17-11',
  'wfm-18-5',
  'wfm-18-11',
  'wfm-19-11',
  'wfm-19-2',
  'wfm-15-1',
]
export const ERROR_CODE_CANNOT_CONNECT_TO_SERVER = 'wfm-14-0'
export const RULES_VIOLATION_ERROR = ['wfm-14-12']
export const TIME_OFF_ERROR_CODE = 'wfm-15-0'

export const LOAD_REQUESTS_ERROR_MESSAGE =
  'Unable to load requests at this time. Please try again later and if the issue persists, contact the CSC.'

// error messages for available shifts
export const LOAD_AVAILABLE_SHIFTS_ERROR_MESSAGE =
  'Unable to load available shifts at this time.  Please try again later and if the issue persists, contact the CSC.'
export const NO_AVAILABLE_SHIFTS_MESSAGE =
  'There are no available shifts available shift that you are eligible to cover'
export const NO_AVAILABLE_SHIFTS_MESSAGE_MULTI_LOCATION =
  'There are no available shifts that you are eligible to cover at the selected location(s)'
export const NO_LOCATION_SELECTED_MESSAGE = 'Please select a location'
export const UNABLE_TO_SAVE = 'Unable to save your changes.'
export const UNABLE_TO_LOAD = 'Unable to load data.'
export const SUCCESS_ON_SAVE = 'Your changes saved successfully.'
export const RULES_VIOLATION_ERR_MSG = 'Unable to Cover Shift - Rule Violation:'
export const SHIFT_NO_LONGER_AVAIL_MSG = 'The available shift no longer exists.'
export const MISSED_PUNCH_MESSAGE =
  'Unable to pick up a shift due to a missing punch that may impact total hours for this week. Please resolve the missing punch to be eligible to pick up additional hours.'
export const NO_REQUESTS_MESSAGE = 'No requests available'

// error codes for timecards
export const ERROR_CODES_SHOW_MESSAGE_TIMECARD = ['wfm-1-5', 'wfm-2-5', 'wfm-2-11', 'wfm-13-5', 'wfm-13-11']
export const ERROR_CODE_CANNOT_CONNECT_TO_SERVER_TIMECARD = 'wfm-13-0'

//  error messages for timecards
export const NO_TIMECARD_MESSAGE = 'No timecards found for this week. Navigate to past weeks to check it.'
export const UNABLE_TO_LOAD_TIMECARDS = 'Unable to load timecards at this time. Please try again later'

//Apple/iOS Download
export const ERROR_CODES_SHOW_MESSAGE_REDEMPTION_CODE = ['wfm-6-5', 'wfm-6-11']
export const LOAD_REDEMPTION_CODES_ERROR_MESSAGE =
  'Unable to load codes at this time.  Please try again later and if the issue persists, contact the CSC.'
export const ERROR_CODE_CANNOT_CONNECT_TO_WORKER_SERVER = 'wfm-6-0'

//Shift Swap messages
export const INITIATED_SHIFT_SWAP_MESSAGE = 'Your Shift Swap Request is successfully initiated.'
export const WITHDRAW_SHIFT_SWAP_MESSAGE = 'Your Shift Swap Request is successfully withdrawn.'
export const ACCEPTED_SHIFT_SWAP_MESSAGE = 'You have successfully accepted Shift Swap Request'
export const RULES_VIOLATION_ERR_MSG_FOR_SWAP = 'Unable to Swap Shift - Rule Violation:'
export const ERROR_CODE_CANNOT_CONNECT_TO_SERVER_SHIFT_SWAP = 'wfm-17-0'
export const NO_RESULTS_FOUND = 'No result found. Please refine your search options and try again.'

//Push Notification Opt-In/ Opt-Out messages
export const PN_SETTINGS_SUBMIT_SUCCESS_MESSAGE = 'Your Push Notification preferences have been saved successfully.'
export const PN_SETTINGS_SUBMIT_ERROR_MESSAGE = 'Error in saving your Push Notification preferences.'
export const ERROR_CODE_CANNOT_CONNECT_TO_SERVER_PN_SETTINGS = 'wfm-6-0'
export const ERROR_CODES_SHOW_MESSAGE_PN_SETTINGS = ['wfm-6-5', 'wfm-6-11']

// error messages for availability
export const LOAD_AVAILABILITY_ERROR_MESSAGE =
  'Unable to load your availability at this time. Please try again later and if the issue persists, contact the CSC.'
export const NO_AVAILABILITY_MESSAGE = 'Your core availability not found. Please contact your leader'
export const NO_AVAILABILITY_SET_FOR_THIS_WEEK_RANGE =
  'No availability set. Please submit a new availability request, or contact your leader for assistance.'
export const ERROR_CODE_CANNOT_CONNECT_TO_SERVER_AVAILABIILITY = 'wfm-18-0'

//error messages for punch correction
export const ERROR_CODE_CANNOT_CONNECT_TO_SERVER_PUNCH_CORRECTION = 'wfm-19-0'
