import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import {
  AUTOMATION_VOLUNTARY_AVAILABILITY_POST_ERROR,
  AUTOMATION_VOLUNTARY_AVAILABILITY_SET_POST_RESPONSE,
  AUTOMATION_VOLUNTARY_SET_DATA_COLUMN_VIEW,
  AUTOMATION_VOLUNTARY_SET_DOWN_SHIFT_TYPE,
  AUTOMATION_VOLUNTARY_SET_END_TIME,
  AUTOMATION_VOLUNTARY_SET_IS_INPUT_TYPE_TIME,
  AUTOMATION_VOLUNTARY_SET_SAVE_DIALOG_OPEN,
  AUTOMATION_VOLUNTARY_SET_START_TIME,
  AUTOMATION_VOLUNTARY_SHOULD_LOAD_DATA,
  DOWNLOAD_CSV_ERROR,
  DOWNLOAD_CSV_SUCCESS,
  GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_ERROR,
  GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_SUCCESS,
  GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_ERROR,
  GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS,
  LEADER_AUTOMATION_DECISION_ERROR,
  LEADER_AUTOMATION_DECISION_SUCCESS,
  SET_AUTOMATION_VOLUNTARY_DATA_LOADING,
  SET_AUTOMATION_VOLUNTARY_DATA_PAGE,
  SET_AUTOMATION_VOLUNTARY_DATA_PER_PAGE,
  SET_AUTOMATION_VOLUNTARY_DATA_SAVING,
  SET_AUTOMATION_VOLUNTARY_DATA_SEARCH,
  SET_AUTOMATION_VOLUNTARY_DATA_SELECTED_FILTERS,
  SET_AUTOMATION_VOLUNTARY_DATA_SORT,
  SET_AUTOMATION_VOLUNTARY_DATA_TABLE_COLUMNS_TO_DEFAULT,
  SET_AUTOMATION_VOLUNTARY_DATA_TABLE_FILTERS_TO_DEFAULT,
  SET_AUTOMATION_VOLUNTARY_DATA_TABLE_STATE_TO_DEFAULT,
  SET_AUTOMATION_VOLUNTARY_POST_RESPONSE_INELIGIBILITIES,
} from './actionType'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function setVoluntaryDataSelectedFilters(filters) {
  return {
    type: SET_AUTOMATION_VOLUNTARY_DATA_SELECTED_FILTERS,
    payload: filters,
  }
}

export function setVoluntaryColumnView(column, columnAction) {
  return {
    type: AUTOMATION_VOLUNTARY_SET_DATA_COLUMN_VIEW,
    column: column,
    columnAction: columnAction,
  }
}

export function setVoluntaryDataLoading(loading, lastSearchRequest, lastSelectedFilters) {
  return {
    type: SET_AUTOMATION_VOLUNTARY_DATA_LOADING,
    payload: loading,
    search: lastSearchRequest,
    selectedFilters: lastSelectedFilters,
  }
}

export function setVoluntaryDataSaving(isSaving) {
  return {
    type: SET_AUTOMATION_VOLUNTARY_DATA_SAVING,
    isSaving: isSaving,
  }
}

export function setVoluntaryPostResponseIneligibilities(ineligibilities) {
  return {
    type: SET_AUTOMATION_VOLUNTARY_POST_RESPONSE_INELIGIBILITIES,
    ineligibilities: ineligibilities,
  }
}

export function setVoluntaryDataSearch(search) {
  return {
    type: SET_AUTOMATION_VOLUNTARY_DATA_SEARCH,
    payload: search,
  }
}

export function setVoluntaryDataSort(sort) {
  return {
    type: SET_AUTOMATION_VOLUNTARY_DATA_SORT,
    payload: sort,
  }
}

export function setVoluntaryDataPage(page) {
  return {
    type: SET_AUTOMATION_VOLUNTARY_DATA_PAGE,
    payload: page,
  }
}

export function setVoluntaryDataPerPage(perPage) {
  return {
    type: SET_AUTOMATION_VOLUNTARY_DATA_PER_PAGE,
    payload: perPage,
  }
}

export function downloadVoluntaryCSV(queryParams) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.availability.url}` +
      '/voluntary_leader_scheduling_decisions/csv' +
      queryParams +
      '&key=' +
      apiConfig.availability.key

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        return dispatch(handleVoluntaryCSVSuccess(response))
      })
      .catch((response) => {
        return dispatch(handleVoluntaryCSVError(response))
      })
  }
}

function handleVoluntaryCSVSuccess(record) {
  return {
    type: DOWNLOAD_CSV_SUCCESS,
    record,
  }
}

function handleVoluntaryCSVError(record) {
  return {
    type: DOWNLOAD_CSV_ERROR,
    record,
  }
}

export function loadGetLeaderAvailabilityFiltersRequest(upDownState, locationId) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.configuration.url}` +
      '/team_member_enablement_filters' +
      '?location_id=' +
      locationId +
      '&location_type=DC' +
      '&feature=voluntary_' +
      upDownState +
      '&key=' +
      `${apiConfig.availability.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetLeaderAvailabilityFiltersRequestSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetLeaderAvailabilityFiltersRequestError(response))
      })
  }
}

function handleGetLeaderAvailabilityFiltersRequestSuccess(record) {
  return {
    type: GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_SUCCESS,
    record,
  }
}

function handleGetLeaderAvailabilityFiltersRequestError(record) {
  return {
    type: GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_ERROR,
    record,
  }
}

export function loadGetWorkersAvailabilityPreferencesRequest(queryParams) {
  return (dispatch) => {
    let apiUrl = `${apiConfig.availability.url}` + '/voluntary_leader_automated_scheduling_decisions' + queryParams

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetWorkersAvailabilityPreferencesRequestSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetWorkersAvailabilityPreferencesRequestError(response))
      })
  }
}

function handleGetWorkersAvailabilityPreferencesRequestSuccess(record) {
  return {
    type: GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS,
    record,
  }
}

function handleGetWorkersAvailabilityPreferencesRequestError(record) {
  return {
    type: GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_ERROR,
    record,
  }
}

export function setVoluntaryDataTableStateToDefault() {
  return {
    type: SET_AUTOMATION_VOLUNTARY_DATA_TABLE_STATE_TO_DEFAULT,
  }
}

export function setVoluntaryDataTableFiltersToDefault() {
  return {
    type: SET_AUTOMATION_VOLUNTARY_DATA_TABLE_FILTERS_TO_DEFAULT,
  }
}

export function setVoluntaryDataTableColumnsToDefault() {
  return {
    type: SET_AUTOMATION_VOLUNTARY_DATA_TABLE_COLUMNS_TO_DEFAULT,
  }
}

export function setSaveVoluntaryDialogOpen(isOpen) {
  return {
    type: AUTOMATION_VOLUNTARY_SET_SAVE_DIALOG_OPEN,
    payload: isOpen,
  }
}

export function setIsInputTypeTime(isTime) {
  return {
    type: AUTOMATION_VOLUNTARY_SET_IS_INPUT_TYPE_TIME,
    payload: isTime,
  }
}

export function setDownShiftType(shiftType) {
  return {
    type: AUTOMATION_VOLUNTARY_SET_DOWN_SHIFT_TYPE,
    payload: shiftType,
  }
}

export function setStartTime(startTime) {
  return {
    type: AUTOMATION_VOLUNTARY_SET_START_TIME,
    payload: startTime,
  }
}

export function setEndTime(endTime) {
  return {
    type: AUTOMATION_VOLUNTARY_SET_END_TIME,
    payload: endTime,
  }
}

export function updateVoluntaryLeaderDecisions(body) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.availability.url}` +
      '/voluntary_leader_automated_scheduling_decisions?key=' +
      `${apiConfig.availability.key}`

    return axios
      .post(apiUrl, body, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleUpdateVoluntaryLeaderDecisionsSuccess(response))
      })
      .catch((response) => {
        dispatch(handleUpdateVoluntaryLeaderDecisionsError(response))
      })
  }
}

function handleUpdateVoluntaryLeaderDecisionsSuccess(record) {
  return {
    type: LEADER_AUTOMATION_DECISION_SUCCESS,
    record,
  }
}

function handleUpdateVoluntaryLeaderDecisionsError(record) {
  return {
    type: LEADER_AUTOMATION_DECISION_ERROR,
    record,
  }
}

export function setVoluntaryPostResponse(response) {
  return {
    type: AUTOMATION_VOLUNTARY_AVAILABILITY_SET_POST_RESPONSE,
    payload: response,
  }
}

export function setVoluntaryPostError(error) {
  return {
    type: AUTOMATION_VOLUNTARY_AVAILABILITY_POST_ERROR,
    error,
  }
}

export function setShouldLoadVoluntaryData(shouldLoad) {
  return {
    type: AUTOMATION_VOLUNTARY_SHOULD_LOAD_DATA,
    shouldLoad,
  }
}
