import { Divider } from '@mui/material'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLocationDetails } from '../../../../../api/location/useLocationDetails'
import { useUserFeatures } from '../../../../../api/userAccess/useUserFeatures'
import { CALL_OFF_ABSENT, CATEGORY_FEATURE_MAP } from '../../../../../constants/RequestConstants'
import { getConfigDetails } from '../../../../../constants/leaderConstants'
import { setControlPanelSettings } from '../../../../../store/leader/actionCreator'
import TotalTimeOffRequestsByStatusButton from '../../TotalTimeOffRequestsByStatusButton'

const CallOffAtAGlance = () => {
  const { data: locationDetails } = useLocationDetails()
  const { data: userFeatures } = useUserFeatures()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { configMap } = useMemo(() => getConfigDetails(locationDetails, userFeatures), [locationDetails, userFeatures])

  const handleCallOffRequestsButtonClick = () => {
    dispatch(
      setControlPanelSettings(
        CALL_OFF_ABSENT,
        configMap[CALL_OFF_ABSENT].defaultStartDate,
        configMap[CALL_OFF_ABSENT].defaultEndDate,
        {},
        configMap[CALL_OFF_ABSENT].searchText,
        configMap[CALL_OFF_ABSENT].selectedSortByIndex,
        configMap[CALL_OFF_ABSENT].sortOrder,
      ),
    )
    navigate('/leader/myteam')
  }

  return (
    <>
      <Divider aria-hidden="true" />
      <TotalTimeOffRequestsByStatusButton
        status={''}
        startDate={configMap[CALL_OFF_ABSENT].defaultStartDate}
        endDate={configMap[CALL_OFF_ABSENT].defaultEndDate}
        handleButtonClick={handleCallOffRequestsButtonClick}
        buttonMessage={'Call Off Requests'}
        selectedCategory={CATEGORY_FEATURE_MAP.get(CALL_OFF_ABSENT)}
        categoryName={CALL_OFF_ABSENT}
      />
    </>
  )
}
export default CallOffAtAGlance
