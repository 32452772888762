// @flow

import HomeIcon from '@mui/icons-material/Home'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import { Link } from 'react-router-dom'

type Props = {
  route: string
  title: string
}

export default function NotFound(props: Props) {
  const { route, title } = props

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        left: 0,
        top: 0,
        width: '100%',
        '.body': {
          background: '#ddd',
        },
      }}
    >
      <Box
        sx={{
          boxSizing: 'border-box',
          margin: '0 auto',
          maxWidth: '320px',
          padding: '20px 30px',
          textAlign: 'center',
          '.heading': {
            fontWeight: '300',
            fontSize: '34px',
            margin: '0',
            opacity: 0.75,
          },
          '.subheading': {
            fontWeight: '400',
            fontSize: '18px',
            lineHeight: 1.5,
            margin: '1.8em 10px 2em',
            opacity: 0.5,
          },
        }}
      >
        <div className="heading">Page Not Found</div>
        <div className="subheading">
          This page may have moved. Please use the left navigation or bottom toolbar to find what you're looking for.
        </div>
        <Fab component={Link} role="link" to={route} title={title} aria-label={title} color="primary">
          <HomeIcon />
        </Fab>
      </Box>
    </Box>
  )
}

NotFound.defaultProps = {
  route: '/',
  title: 'Home',
}
