import { Grid, MenuItem, TextField, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PTO_PREFIX } from '../../../../constants/RequestConstants'
import { changePTODetailValue, setDayError } from '../../../../store/requestTimeOff/actionCreator'
import { createCypressDate } from '../../../../utils/DateUtil'
import { MoreRowActions } from '../RequestRow'
import TimePicker from '../../../common/calendar/TimePicker'

const renderMenuItemList = (itemArray) => {
  return itemArray?.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    )
  })
}

export default function TimeOffPTORow({ dateKey }) {
  const dispatch = useDispatch()
  const detail = useSelector((store) => store.requestTimeOff?.request[dateKey])
  const requestConfig = useSelector((store) => store.requestTimeOff.requestConfig)

  useEffect(() => {
    // check if daily limit of PTO exceeds configured maxPaidPerDay
    if (detail?.requestDetails.length > 1 && !detail.error) {
      const totalPaidMinutes = detail?.requestDetails.reduce((agg, currentDetail) => {
        if (currentDetail?.type.includes(PTO_PREFIX)) {
          return (agg += currentDetail?.minutes || 0)
        }
        return agg
      }, 0)
      if (totalPaidMinutes > requestConfig.content.maxPaidPerDay) {
        dispatch(
          setDayError(
            dateKey,
            `Request exceeds the daily max PTO limit of ${requestConfig.content.maxPaidPerDay / 60} hrs`,
          ),
        )
      }
    }
  }, [detail, requestConfig.content.maxPaidPerDay, dateKey, dispatch])

  const onChange = (date, index, field, value) => {
    let newValue = value
    let newField = field === 'hours' ? 'minutes' : field
    if (newField === 'minutes') {
      const currentHours = Math.floor(detail.requestDetails[index].minutes / 60)
      const currentMinutes = detail.requestDetails[index].minutes % 60
      const newHours = field === 'hours' ? value : currentHours
      const newMinutes = field === 'minutes' ? value : currentMinutes
      newValue = newHours * 60 + newMinutes
    }

    dispatch(changePTODetailValue(date, index, newField, newValue))
  }

  const createCypressTag = (componentName, index) => {
    return `requestTimeOffRowInput_${componentName}_${createCypressDate(new Date(dateKey))}_${index}`
  }

  return detail?.requestDetails.map((rowDetail, index) => {
    const hours = Math.floor(rowDetail.minutes / 60)
    const minutes = rowDetail.minutes % 60
    const timeDurationError = rowDetail?.error?.minutes

    return (
      <Grid
        container
        justifyContent={'space-between'}
        key={index}
        paddingLeft={0.5}
        data-cy={`requestTimeOffDetail_${createCypressDate(new Date(dateKey))}_${index}`}
      >
        <Grid item xs>
          <TimePicker
            id="startTime"
            label="Start Time"
            defaultValue={rowDetail.startTime}
            onChange={(startTime) => onChange(detail.date, index, 'startTime', startTime)}
            minutesStep={requestConfig?.content?.startTimeMinutesStep}
            slotProps={{
              textField: {
                id: 'startTime',
                name: 'startTime',
                sx: { minWidth: 80, maxWidth: 140 },
                'data-cy': createCypressTag('startTime', index),
              },
            }}
          />
        </Grid>
        <Grid container item xs justifyContent={'center'}>
          <Grid container direction={'column'}>
            <Grid item container xs wrap="nowrap" justifyContent={'center'}>
              <TextField
                sx={{ minWidth: '45px' }}
                id="Hours"
                label="Hours"
                name="hours"
                error={!!timeDurationError}
                select
                value={hours}
                onChange={(event) => onChange(detail.date, index, 'hours', Number(event.target.value))}
                data-cy={createCypressTag('hours', index)}
              >
                {renderMenuItemList(requestConfig?.content?.paidHourDropdownValues)}
              </TextField>
              <TextField
                sx={{ minWidth: '45px' }}
                id="Minutes"
                label="Minutes"
                name="minutes"
                error={!!timeDurationError}
                select
                value={minutes}
                onChange={(event) => onChange(detail.date, index, 'minutes', Number(event.target.value))}
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: '510px' },
                  },
                }}
                data-cy={createCypressTag('minutes', index)}
              >
                {renderMenuItemList(requestConfig?.content?.paidMinuteDropdownValues)}
              </TextField>
            </Grid>
            <Grid item textAlign={'center'} height={20}>
              {!!timeDurationError && (
                <Typography variant="caption" color={'primary'} data-cy={createCypressTag('timeError', index)}>
                  {timeDurationError}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <TextField
            id="type"
            select
            label="Type"
            value={rowDetail.type}
            onChange={(event) => onChange(detail.date, index, 'type', event.target.value)}
            sx={{ minWidth: 80, maxWidth: 250, display: 'flex', flexGrow: 1 }}
            data-cy={createCypressTag('type', index)}
          >
            {renderMenuItemList(requestConfig?.content?.types?.selectableTypes)}
          </TextField>
        </Grid>
        <Grid item alignContent={'center'}>
          <MoreRowActions
            numberOfDetailRows={detail?.requestDetails?.length}
            dateKey={dateKey}
            rowDetailIndex={index}
          />
        </Grid>
      </Grid>
    )
  })
}
