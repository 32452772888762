import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTotalCount } from '../../../../../store/leader/actionCreator'

export default function useSyncTotalCount(newTotalCount) {
  const selectedCategory = useSelector((store) => store.leader.selectedCategory)
  const currentTotalCount = useSelector((store) => store.leader[selectedCategory]?.totalCount)

  const dispatch = useDispatch()

  useEffect(() => {
    if (currentTotalCount !== newTotalCount) {
      dispatch(setTotalCount(newTotalCount))
    }
  }, [currentTotalCount, newTotalCount, dispatch])
}
