import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Fab, Typography, useTheme } from '@mui/material'
import { QueryErrorResetBoundary } from '@tanstack/react-query'
import { ErrorBoundary } from 'react-error-boundary'

import { Home } from '@mui/icons-material'
import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { AxiosError } from 'axios'
import React from 'react'
import { useLocation, useNavigate, useRouteError } from 'react-router-dom'
import { IError } from '../../../api/globalResponses'
import HeaderTitle from '../../Header/HeaderTitle'
import CenteredLoader from '../loaders/CenteredLoader'

// MakeFetchingEasy
export const QueryBoundaries = ({ children }: { children: React.ReactNode }) => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <ErrorBoundary onReset={reset} FallbackComponent={ErrorComponent}>
        <React.Suspense fallback={<LoadingView />}>{children}</React.Suspense>
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
)

// Spinner
const LoadingView = () => <CenteredLoader />

interface ErrorComponentProps {
  error: AxiosError<IError, any>
  resetErrorBoundary: () => void
}

const getStyles = (theme: any) => ({
  mainContainerPosition: theme.mainContainerPosition,
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: '600px',
    padding: '20px 30px',
    textAlign: 'center',
  },
  subheading: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '1.5',
    opacity: '.5',
  },
  errorHeading: {
    fontWeight: '400',
    fontSize: '16px',
    margin: '0',
    opacity: '.75',
  },
  errorDetails: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.5',
    opacity: '.5',
  },
  heading: {
    fontWeight: '300',
    fontSize: '34px',
    margin: '0',
    opacity: '.75',
  },
  retry: {
    paddingY: 2,
  },
})

const CSC_ERROR_MESSAGE = 'Oops! Something went wrong, please try again later.'

const createRouteErrorObject = (path: string) => {
  const errorId = Math.floor(Math.random() * 9999) + 1
  return {
    message: `Unknown error on ${path}`,
    code: `${path} mytime-ui-${errorId}`,
  }
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ error, resetErrorBoundary }) => {
  const theme = useTheme()
  const { session } = useAuth()
  const styles = getStyles(theme)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  let customError = createRouteErrorObject(pathname)

  let routeError = useRouteError()

  const loggingInfo = {
    message: customError.message,
    url: window.location.href,
    error: {
      message: error.response?.data.message,
      code: error.response?.data.code,
      ui_code: customError.code,
      worker_id: session?.userInfo?.empid,
      location_id: session?.userInfo?.locationid,
      routeError: routeError
        ? JSON.stringify(routeError, Object.getOwnPropertyNames(routeError))
        : 'routeError is undefined',
    },
  }
  const optionalLoggingOptions = {
    level: LogLevel.Error,
  }
  logEvent(loggingInfo, optionalLoggingOptions)

  return (
    <div style={styles.mainContainerPosition}>
      <HeaderTitle title={''} />
      <Box sx={styles.content}>
        <div style={styles.heading}>{CSC_ERROR_MESSAGE}</div>
        <div style={styles.subheading}>
          If the issue persists please contact the Client Support Center at (612) 304-4357.
        </div>
        <Box sx={styles.retry}>
          <Fab onClick={resetErrorBoundary} title={'Reload'} variant="extended" aria-label={'Reload'} color="primary">
            <RefreshIcon />
            Try Again
          </Fab>
          &nbsp;
          <Fab
            onClick={() => navigate('/', { replace: true })}
            variant="extended"
            title={'Home'}
            aria-label={'Home'}
            color="primary"
          >
            <Home />
            Home
          </Fab>
        </Box>
        <Typography sx={styles.errorHeading} marginBottom={'0px'}>
          Error Details:
        </Typography>
        <Typography sx={styles.errorDetails}>{customError.code}</Typography>
        <Typography sx={styles.errorDetails} marginBottom={'0px'}>
          {error.response?.data.message}
          <br />
          {error.response?.data.code}
        </Typography>
      </Box>
    </div>
  )
}

export default ErrorComponent
