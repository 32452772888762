import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { NON_EXEMPT_USER_GROUPS } from '../../constants/userConstants'
import { showNotificationError } from '../../store/notification/actionCreator'
import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { useUserFeatures } from '../userAccess/useUserFeatures'
import { ITimecards } from './responses/timecardDaily'

interface TimecardDailyParams {
  workerId?: string
  locationId?: string
  selectedDate?: Date | number
}

export const getTimeCardDaily = async ({
  workerId,
  locationId,
  selectedDate,
}: TimecardDailyParams): Promise<ITimecards> => {
  let date = format(selectedDate ? selectedDate : getDateOfTodayWithNoTimestamp(), 'yyyy-MM-dd')

  let apiUrl =
    `${apiConfig.timecards.url}` +
    '/timecard_daily_view' +
    '?date=' +
    date +
    '&location_id=' +
    locationId +
    '&worker_id=' +
    workerId +
    '&key=' +
    `${apiConfig.timecards.key}`

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  return data
}

interface UseTimecardDailyParams {
  selectedDate?: Date
  isEnabled?: boolean
}

export function useTimeCardDaily({
  selectedDate = getDateOfTodayWithNoTimestamp(),
  isEnabled,
}: UseTimecardDailyParams) {
  const { session, isAuthenticated, login } = useAuth()
  const dispatch = useDispatch()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  return useQuery(
    ['timeCardDaily', { selectedDate }],
    () =>
      getTimeCardDaily({
        workerId: padEmpIdWithZeros(session?.userInfo?.empid, 10),
        locationId: session?.userInfo?.locationid,
        selectedDate: selectedDate,
      }),
    {
      staleTime: 1000 * 10,
      enabled: isEnabled && !!selectedDate && isAuthenticated(),
      onError: () => {
        dispatch(showNotificationError(true, 'Error loading time card data'))
      },
    },
  )
}

export function usePunchedInStatus(userGroups = NON_EXEMPT_USER_GROUPS) {
  const { data: userFeatures } = useUserFeatures()

  // The date doesn't matter when only needing the punched in status
  const { data, isError, isFetching } = useTimeCardDaily({
    selectedDate: getDateOfTodayWithNoTimestamp(),
    isEnabled: userGroups.includes(userFeatures?.user_group),
  })
  let punchedInStatus = { isPunchedIn: undefined } as { isPunchedIn: undefined | boolean }
  if (data?.timecards) {
    punchedInStatus.isPunchedIn = data.timecards.some((timecard) => timecard.has_punched_in as boolean)
  }
  return { punchedInStatus, isError, isFetching }
}
