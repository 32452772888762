/* eslint-disable react-hooks/exhaustive-deps */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_CADENCE } from '../../../constants/AvailabilityConstants'
import { setAvailabilityForSingleWeek } from '../../../store/availability/actionCreator'
import { checkIfEndTimeSmaller, checkLessThanMinHrs, checkOverlapping } from '../../../utils/AvailabilityUtils'
import CustomCalendarForAvailability from './CustomCalendarForAvailability'
import TimePeriodCard from './TimePeriodCard'

const getStyles = (theme) => ({
  dotColor: {
    color: 'green',
  },
  timings: {
    marginLeft: '150px',
    marginTop: '2px',
  },
  rowDetailContainer: {
    padding: '0px 0px -1px 16px',
  },
  accordionDetailContainer: {
    padding: '0px 20px 5px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '2px',
  },
  daySelectGrid: {
    paddingTop: '0px !important',
  },
  daySelection: {
    margin: '3% 2% 3% 2%',
    minWidth: 100,
    flex: 1,
    fontSize: '10px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260,
    maxWidth: 300,
  },
  formGrp: {
    display: 'inline',
  },
  formLabel: {
    marginRight: '10px',
  },
  selectStyle: {
    margin: '2% 0% 0% -2%',
    minWidth: 100,
    flex: 1,
    fontSize: '10px',
  },
  gridContainer: {
    paddingLeft: '18px !important',
  },

  timePeriodContainer: {
    marginBottom: '1px',
  },
  infoBoxCls: {
    padding: '10px',
    margin: '7px 0',
    backgroundColor: '#EBEBEB',
  },
  infoTextCls: {
    fontSize: '0.95rem',
  },
  dateColumn: {
    textAlign: 'right',
  },
  checkBoxContainer: {
    margin: '10px 0 0 2px',
    '& label': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& span': {
      fontSize: '0.95rem',
    },
  },
  errorMsg: {
    color: '#cc0000',
    padding: '0 0 0 12px',
    fontSize: '0.85rem',
  },
  flexContainer: {
    borderBottom: '1px solid #D0D0D0',
    borderTop: '1px solid #D0D0D0',
    height: 45,
    padding: '5px 10px 5px 10px',
    backgroundColor: '#eeeeee',
    width: '100%',
  },
  flexChildLeft: {
    display: ' inline-block',
    width: '75%',
    textAlign: 'left',
  },
  flexChildRight: {
    display: ' inline-block',
    width: '25%',
    textAlign: 'right',
    paddingTop: '5px',
    verticalAlign: 'middle',
  },
})

const RequestAvailabilityDetailByDate = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { dataOnSubmit } = props
  const [weekLabelsArr, setWeekLabelsArr] = useState([{ index: 0, label: 'Week 1' }])
  const [currentWeek, setCurrentWeek] = useState(weekLabelsArr[0])
  const [weekWiseAvailabilities, setWeekWiseAvailabilities] = useState({ week_1: [] })
  const [week1, setWeek1] = useState([])
  const [week2, setWeek2] = useState([])
  const [patternEnd, setPatternEnd] = useState('')
  const daysArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const availabilityReqStartDate = useSelector((state) => state.availability.availabilityReqStartDate)
  const availabilityReqEndDate = useSelector((state) => state.availability.availabilityReqEndDate)
  const availabilityReqType = useSelector((state) => state.availability.availabilityReqType)
  const outputDateFormat = 'MMMM D, YYYY'
  const singleWeekAvailability = useSelector((state) => state.availability.singleWeekAvailability)
  const [isWeek2Enabled, setIsWeek2Enabled] = useState(false)

  const dispatch = useDispatch()

  let week = []

  daysArr.forEach((day, index) => {
    let dayObj = {}
    dayObj.day_id = index + 1
    dayObj.day = day
    dayObj.time_intervals = []

    week.push(dayObj)
  })

  useEffect(() => {
    if (availabilityReqType === 'Temporary') {
      setPatternEnd(moment(availabilityReqEndDate)?.format(outputDateFormat))
    } else {
      setPatternEnd('FOREVER')
    }
  }, [availabilityReqEndDate, availabilityReqType])

  useEffect(() => {
    setWeek1(cloneDeep(week))
    return () => {
      dispatch(setAvailabilityForSingleWeek(false))
    }
  }, [])

  useEffect(() => {
    if (week1.length > 0) {
      setWeekWiseAvailabilities({ ...weekWiseAvailabilities, week_1: week1 })
      setWeek2(cloneDeep(week))
    }
  }, [week1])

  useEffect(() => {
    if (week2.length > 0) {
      setWeekWiseAvailabilities({ ...weekWiseAvailabilities, week_2: week2 })
    }
  }, [week2])

  useEffect(() => {
    dataOnSubmit(weekWiseAvailabilities, isWeek2Enabled)
  }, [weekWiseAvailabilities])

  const weekChanged = (wk) => {
    setCurrentWeek(wk)
  }

  useEffect(() => {
    if (isWeek2Enabled) {
      if (weekLabelsArr.length === 1) {
        setWeekLabelsArr([...weekLabelsArr, { index: 1, label: 'Week 2' }])
        // setWeek2(cloneDeep(week))
      }
    } else {
      if (weekLabelsArr.length === 2) {
        let newWeekLablesArr = weekLabelsArr.filter((item) => item.label === 'Week 1')
        setWeekLabelsArr(newWeekLablesArr)
      }

      /* let allWeekData = { ...weekWiseAvailabilities }

      if (Object.keys(allWeekData).length > 1) {
        if (allWeekData['week_2']) {
          delete allWeekData['week_2']
        }
        setWeekWiseAvailabilities({ ...allWeekData })
      } */
    }
  }, [isWeek2Enabled])

  const onStartTimeChange = (val, wkLabel, dayVal, tIndex) => {
    let copyOfWeek = [...weekWiseAvailabilities[wkLabel]]
    if (val) {
      for (let dayObj of copyOfWeek) {
        if (dayObj.day === dayVal) {
          for (let i = 0; i < dayObj.time_intervals.length; i++) {
            if (tIndex === i) {
              let isEndTimeGreaterThanStartTime = checkIfEndTimeSmaller(val, dayObj.time_intervals[i].end_time)
              let isLessThanMinHrs = checkLessThanMinHrs(val, dayObj.time_intervals[i].end_time)
              if (!isEndTimeGreaterThanStartTime) {
                dayObj.time_intervals[i].start_time = val
                dayObj.time_intervals[i].isEndTimeGreater = false
              } else {
                dayObj.time_intervals[i].start_time = val
                dayObj.time_intervals[i].isEndTimeGreater = true
              }
              dayObj.time_intervals[i].isLessThanMinHrs = isLessThanMinHrs
            }
          }
        }
      }
    }
    shareDetailsBetweenTheWeeks(wkLabel, dayVal, copyOfWeek)
  }

  const onEndTimeChange = (val, wkLabel, dayVal, tIndex) => {
    let copyOfWeek = [...weekWiseAvailabilities[wkLabel]]
    if (val) {
      for (let dayObj of copyOfWeek) {
        if (dayObj.day === dayVal) {
          for (let i = 0; i < dayObj.time_intervals.length; i++) {
            if (tIndex === i) {
              let isEndTimeGreaterThanStartTime = checkIfEndTimeSmaller(dayObj.time_intervals[i].start_time, val)
              let isLessThanMinHrs = checkLessThanMinHrs(dayObj.time_intervals[i].start_time, val)
              if (!isEndTimeGreaterThanStartTime) {
                dayObj.time_intervals[i].end_time = val === '00:00' ? '24:00' : val
                dayObj.time_intervals[i].isEndTimeGreater = false
              } else {
                dayObj.time_intervals[i].end_time = val === '00:00' ? '24:00' : val
                dayObj.time_intervals[i].isEndTimeGreater = true
              }
              dayObj.time_intervals[i].isLessThanMinHrs = isLessThanMinHrs
            }
          }
        }
      }
    }
    shareDetailsBetweenTheWeeks(wkLabel, dayVal, copyOfWeek)
  }

  const onRepeatOptionChange = (val, wkLabel, day, dayId, tIndex) => {
    let copyOfWeek = [...weekWiseAvailabilities[wkLabel]]
    if (val) {
      for (let dayObj of copyOfWeek) {
        if (dayObj.day === day) {
          for (let i = 0; i < dayObj.time_intervals.length; i++) {
            if (tIndex === i) {
              dayObj.time_intervals[i].repeatOptionSelected = val
              dayObj.time_intervals[i].isEveryOtherWeek = val === 'Every other week'
              break
            }
          }
        }
      }
    }
    if (wkLabel === 'week_1') {
      checkIfToEnableWeek2(copyOfWeek)
    }
    shareDetailsBetweenTheWeeks(wkLabel, day, copyOfWeek)
  }

  const addTimePeriodCard = (e, day, wkLabel) => {
    e.preventDefault()
    let copyOfWeek = [...weekWiseAvailabilities[wkLabel]]
    copyOfWeek.forEach((element) => {
      if (element.day.toLowerCase() === day.toLowerCase()) {
        let timePeriodObj = {}
        timePeriodObj.level = 'AVAILABLE'
        timePeriodObj.start_time = ''
        timePeriodObj.end_time = ''
        timePeriodObj.isEndTimeGreater = false
        timePeriodObj.isEveryOtherWeek = false
        timePeriodObj.isLessThanMinHrs = false
        timePeriodObj.isOverlapping = false
        timePeriodObj.repeatOptionSelected = REQUEST_CADENCE[0].value
        timePeriodObj.ref_id = 0
        timePeriodObj.time_interval_id = 1

        if (element.time_intervals.length < 2) {
          element.time_intervals.push(timePeriodObj)
        }
      }
    })
    shareDetailsBetweenTheWeeks(wkLabel, day, copyOfWeek)
  }

  const onRemovalOfTimePeriod = (id) => {
    const wkLabel = `week_${id.split('_')[1]}`
    const day_id = id.split('_')[2]
    const time_interval_id = id.split('_')[3]

    let copyOfWeek = [...weekWiseAvailabilities[wkLabel]]

    let timeIntervalsArr = [...copyOfWeek[day_id - 1].time_intervals]
    timeIntervalsArr.forEach((element, index) => {
      if (element.time_interval_id === parseInt(time_interval_id)) {
        timeIntervalsArr.splice(index, 1)
      }
    })
    copyOfWeek[day_id - 1].time_intervals = timeIntervalsArr
    findOverlappingBetweenIntervals(copyOfWeek[day_id - 1])
    if (wkLabel === 'week_1') {
      checkIfToEnableWeek2(copyOfWeek)
    }
    deleteDetailsFromOtherWeek(wkLabel, day_id, time_interval_id, copyOfWeek)
  }

  const checkIfToEnableWeek2 = (arr) => {
    for (let dayObj of arr) {
      if (dayObj.time_intervals.some((item) => item.isEveryOtherWeek)) {
        setIsWeek2Enabled(true)
        return
      }
    }

    setIsWeek2Enabled(false)
  }

  const deleteDetailsFromOtherWeek = (wkLabel, dayId, timeIntervalId, arr) => {
    let allWeekData = { ...weekWiseAvailabilities }
    let week1Data = allWeekData['week_1']
    let week2Data = allWeekData['week_2']

    if (wkLabel === 'week_1') {
      for (let day2obj of week2Data) {
        if (day2obj.day_id === parseInt(dayId)) {
          for (let i = 0; i < day2obj.time_intervals.length; i++) {
            if (day2obj.time_intervals[i].ref_id === parseInt(timeIntervalId)) {
              day2obj.time_intervals.splice(i, 1)
              findOverlappingBetweenIntervals(day2obj)
            }
          }
        }
      }
      setWeekWiseAvailabilities({ ...weekWiseAvailabilities, week_1: arr, week_2: week2Data })
    } else {
      for (let day1obj of week1Data) {
        if (day1obj.day_id === parseInt(dayId)) {
          for (let i = 0; i < day1obj.time_intervals.length; i++) {
            if (day1obj.time_intervals[i].ref_id === parseInt(timeIntervalId)) {
              day1obj.time_intervals.splice(i, 1)
              findOverlappingBetweenIntervals(day1obj)
            }
          }
        }
      }
      setWeekWiseAvailabilities({ ...weekWiseAvailabilities, week_1: week1Data, week_2: arr })
    }
  }

  const shareDetailsBetweenTheWeeks = (wkLabel, dayVal, copyOfWeek) => {
    let allWeekData = { ...weekWiseAvailabilities }
    let week1Data
    let week2Data

    if (wkLabel === 'week_1') {
      week1Data = copyOfWeek
      week2Data = allWeekData['week_2']
      week2Data = cloneDetailsToOtherWeek(dayVal, week1Data, week2Data)
    } else {
      week1Data = allWeekData['week_1']
      week2Data = copyOfWeek
      week1Data = cloneDetailsToOtherWeek(dayVal, week2Data, week1Data)
    }

    setWeekWiseAvailabilities({ ...weekWiseAvailabilities, week_1: week1Data, week_2: week2Data })
  }

  const cloneDetailsToOtherWeek = (dayVal, currentWeekData, otherWeekData) => {
    let arr = [...currentWeekData]

    for (let dayObj of arr) {
      if (dayObj.day === dayVal) {
        findOverlappingBetweenIntervals(dayObj)
        for (let i = 0; i < dayObj.time_intervals.length; i++) {
          let otherWkDayObjTimeIntervals = otherWeekData[daysArr.indexOf(dayVal)].time_intervals

          if (dayObj.time_intervals[i].repeatOptionSelected === REQUEST_CADENCE[0].value) {
            // Every Week
            if (otherWkDayObjTimeIntervals.length === 0) {
              let obj = { ...dayObj.time_intervals[i] }
              // obj.time_interval_id = parseInt(dayObj.time_intervals[0].time_interval_id) + 1
              obj.time_interval_id = 2
              obj.ref_id = 1
              dayObj.time_intervals[0].ref_id = 2
              dayObj.time_intervals[0].time_interval_id = 1
              otherWkDayObjTimeIntervals.push(obj)
            } else if (otherWkDayObjTimeIntervals.length === 1) {
              if (dayObj.time_intervals[i].ref_id === otherWkDayObjTimeIntervals[0].time_interval_id) {
                let obj = { ...dayObj.time_intervals[i] }
                obj.time_interval_id = otherWkDayObjTimeIntervals[0].time_interval_id
                obj.ref_id = otherWkDayObjTimeIntervals[0].ref_id
                // dayObj.time_intervals[i].ref_id = 2

                otherWkDayObjTimeIntervals[0] = obj
              } else {
                let obj = { ...dayObj.time_intervals[i] }
                obj.time_interval_id = 4 //parseInt(dayObj.time_intervals[0].time_interval_id) + 1
                obj.ref_id = 3 //obj.time_interval_id
                dayObj.time_intervals[i].time_interval_id = 3
                dayObj.time_intervals[i].ref_id = 4
                otherWkDayObjTimeIntervals.push(obj)
              }
            } else if (otherWkDayObjTimeIntervals.length === 2) {
              for (let j = 0; j < otherWkDayObjTimeIntervals.length; j++) {
                if (
                  (otherWkDayObjTimeIntervals[0].repeatOptionSelected === REQUEST_CADENCE[0].value &&
                    otherWkDayObjTimeIntervals[1].repeatOptionSelected === REQUEST_CADENCE[1].value) ||
                  (otherWkDayObjTimeIntervals[1].repeatOptionSelected === REQUEST_CADENCE[0].value &&
                    otherWkDayObjTimeIntervals[0].repeatOptionSelected === REQUEST_CADENCE[1].value)
                ) {
                  if (i === 1 && dayObj.time_intervals[0].repeatOptionSelected === REQUEST_CADENCE[0].value) {
                    dayObj.time_intervals[1].repeatOptionSelected = REQUEST_CADENCE[1].value
                  }
                }
                if (dayObj.time_intervals[i].ref_id === otherWkDayObjTimeIntervals[j].time_interval_id) {
                  var obj = { ...dayObj.time_intervals[i] }
                  obj.time_interval_id = otherWkDayObjTimeIntervals[j].time_interval_id
                  obj.ref_id = otherWkDayObjTimeIntervals[j].ref_id
                  otherWkDayObjTimeIntervals[j] = obj //{ ...dayObj.time_intervals[i] }
                }
              }
              findOverlappingBetweenIntervals(otherWeekData[daysArr.indexOf(dayVal)])
            }
          } else {
            // Every Other Week
            for (let j = 0; j < otherWkDayObjTimeIntervals.length; j++) {
              if (dayObj.time_intervals[i].time_interval_id === otherWkDayObjTimeIntervals[j].ref_id) {
                otherWkDayObjTimeIntervals.splice(j, 1)
                dayObj.time_intervals[i].ref_id = 0
              }
            }
          }
        }
      }
    }
    return otherWeekData
  }

  const findOverlappingBetweenIntervals = (dayObj) => {
    let isOverlapping
    if (dayObj.time_intervals.length === 2) {
      isOverlapping = checkOverlapping(
        dayObj.time_intervals[0].start_time,
        dayObj.time_intervals[0].end_time,
        dayObj.time_intervals[1].start_time,
        dayObj.time_intervals[1].end_time,
      )

      for (let i = 0; i < dayObj.time_intervals.length; i++) {
        dayObj.time_intervals[i].isOverlapping = !!isOverlapping
      }
    } else if (dayObj.time_intervals.length === 1) {
      dayObj.time_intervals[0].isOverlapping = false
    }
  }

  const renderCalendar = () => {
    return (
      <CustomCalendarForAvailability
        id="availability-calendar"
        weekLabelsArr={weekLabelsArr}
        currentWeekIndex={currentWeek.index}
        onCurrentWeekChange={(wk) => weekChanged(wk)}
      />
    )
  }

  const renderInfoBox = () => {
    return (
      <div style={styles.infoBoxCls}>
        <Grid container>
          <Grid container>
            <Grid item xs={6}>
              <Typography sx={styles.infoTextCls}>
                <b>Start Date: </b>
                {moment(availabilityReqStartDate)?.format(outputDateFormat)}
              </Typography>
            </Grid>

            <Grid item xs={6} sx={styles.dateColumn}>
              <Typography sx={styles.infoTextCls}>
                <b>End Date: </b>
                {patternEnd}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderComponent = () => {
    let wkLabel = currentWeek.label.toLowerCase().split(' ').join('_')

    return (
      <React.Fragment>
        <Grid container direction="row" alignItems="center">
          {weekWiseAvailabilities[wkLabel].map((card, cIndex) => {
            return (
              <React.Fragment key={`${wkLabel}_${cIndex}`}>
                <div
                  style={styles.flexContainer}
                  aria-label={`click to add time intervals for ${currentWeek.label.toLowerCase()} ${card.day}`}
                  onClick={(e) => addTimePeriodCard(e, card.day, wkLabel)}
                  role="button"
                  tabIndex="0"
                >
                  <div style={styles.flexChildLeft}>
                    <Typography variant="body1">{card.day}</Typography>
                  </div>
                  <div style={styles.flexChildRight}>
                    <AddCircleOutlineIcon id={`plusIcon_${card.day}`} />
                  </div>
                </div>
                {card.time_intervals.map((timeInterval, tIndex) => {
                  return (
                    <Grid
                      container
                      sx={styles.timePeriodContainer}
                      key={`${wkLabel}_${card.day_id}_${timeInterval.time_interval_id}`}
                      data-key={`${wkLabel}_${card.day_id}_${timeInterval.time_interval_id}`}
                    >
                      <Grid item xs={12} md={12} lg={12}>
                        <TimePeriodCard
                          weekLabel={wkLabel}
                          weekAndDayLblA11y={`${currentWeek.label.toLowerCase()} ${card.day}`}
                          id={`${wkLabel}_${card.day_id}_${timeInterval.time_interval_id}`}
                          startTime={timeInterval.start_time}
                          endTime={timeInterval.end_time}
                          repeatOptionSelected={timeInterval.repeatOptionSelected}
                          onStartTimeChange={(val) => onStartTimeChange(val, wkLabel, card.day, tIndex)}
                          onEndTimeChange={(val) => onEndTimeChange(val, wkLabel, card.day, tIndex)}
                          onRepeatOptionChange={(val) =>
                            onRepeatOptionChange(val, wkLabel, card.day, card.day_id, tIndex)
                          }
                          onRemovalOfTimePeriod={(id) => onRemovalOfTimePeriod(id)}
                          isEndTimeGreater={timeInterval.isEndTimeGreater}
                          isEveryOtherWeek={timeInterval.isEveryOtherWeek}
                          isLessThanMinHrs={timeInterval.isLessThanMinHrs}
                          isOverlapping={timeInterval.isOverlapping}
                          singleWeekAvailability={singleWeekAvailability}
                          disableRepeatdropDown={timeInterval.disableRepeatdropDown}
                        />
                      </Grid>
                    </Grid>
                  )
                })}
              </React.Fragment>
            )
          })}
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <div>
      {renderCalendar()}
      {renderInfoBox()}
      {renderComponent()}
    </div>
  )
}

export default RequestAvailabilityDetailByDate
