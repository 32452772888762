import { Refresh } from '@mui/icons-material'
import { Box, Button, Fab, Grid, Typography } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { IError } from '../../../api/globalResponses'
import { processError } from '../../../utils/api-errors'
import HeaderTitle from '../../Header/HeaderTitle'
import { TeamMemberContainer } from '../layout/Layouts'

const getStyles = () => ({
  content: {
    textAlign: 'center',
  },
  subheading: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '1.5',
    opacity: '.65',
  },
  errorHeading: {
    fontWeight: '400',
    fontSize: '16px',
    margin: '0',
    opacity: '.75',
  },
  errorDetails: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.5',
    opacity: '.65',
  },
  heading: {
    fontWeight: '300',
    fontSize: '34px',
    margin: '0',
    opacity: '.8',
  },
  retry: {
    paddingY: 2,
  },
})

const NOT_AUTHORIZED_MESSAGE = 'You are not authorized'
interface ErrorComponentProps {
  error?: IError
}

const PreRouterError: React.FC<ErrorComponentProps> = ({ error }) => {
  const { session, logout } = useAuth()
  const styles = getStyles()

  const loggingInfo = {
    message: error?.message || 'Unknown Pre-Router Error',
    url: window.location.href,
    error: {
      message: error?.message,
      code: error?.code,
      worker_id: session?.userInfo?.empid,
      location_id: session?.userInfo?.locationid,
    },
  }
  const optionalLoggingOptions = {
    level: LogLevel.Error,
  }
  logEvent(loggingInfo, optionalLoggingOptions)

  let processedError = processError(error)

  return (
    <TeamMemberContainer sx={{ padding: 2 }}>
      <HeaderTitle title={'Access Error'} />
      <Grid container item justifyContent={'right'} marginBottom={2}>
        <Button variant="contained" onClick={logout}>
          Logout
        </Button>
      </Grid>
      <Box sx={styles.content}>
        <Typography sx={styles.heading} paddingBottom={2}>
          {NOT_AUTHORIZED_MESSAGE}
        </Typography>
        <Typography sx={styles.subheading}>
          <b>Error Message: </b>
          {processedError.message}
          <br />
          <br />
          <b>Error Code: </b> {processedError.code}
        </Typography>
        <Box sx={styles.retry}>
          <Fab
            onClick={() => window.location.reload()}
            title={'Reload'}
            variant="extended"
            aria-label={'Reload'}
            color="primary"
          >
            <Refresh />
            Try Again
          </Fab>
        </Box>
      </Box>
    </TeamMemberContainer>
  )
}

export default PreRouterError
