import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HelpIcon from '@mui/icons-material/Help'
import { Button, Grid, IconButton, Popover, TextField, Tooltip } from '@mui/material'
import { useRef, useState } from 'react'
import { useUpdateCommunication } from '../api/userProfiles/useUpdateCommunication'
import HeaderTitle from '../components/Header/HeaderTitle'
import CommunicationDialogPreview from '../components/admin/communication/CommunicationPreview'
import CommunicationTable from '../components/admin/communication/CommunicationTable'
import BulkAddDialog from '../components/admin/communication/form/BulkAdd'
import FeatureDropdown from '../components/admin/communication/form/FeatureDropdown'
import LocationDropdown from '../components/admin/communication/form/LocationDropdown'
import UserGroupDropdown from '../components/admin/communication/form/UserGroupDropdown'
import useConfirm from '../hooks/useConfirm'
import { toSnakeCase } from '../utils/strings'

const helperDefaultMessage =
  'Starting <b>10/10/2023</b> you will have access to this feature.<br><br>Navigate to the <b>feature</b> page to try it out!<br><br><hr><sub><em><b>Note: </b>You still need leader approval</em></sub>'

const initialFormValues = {
  id: null,
  title: 'New Communication',
  start_timestamp: '',
  end_timestamp: '',
  audience_locations: [],
  audience_features: [],
  audience_roles: [],
  text: helperDefaultMessage,
}

const initialInputSelectValues = {
  audience_locations: '',
  audience_features: '',
  audience_roles: '',
}

export default function Communication() {
  const [formValues, setFormValues] = useState(initialFormValues)
  const currentEditedCommunicationRef = useRef(null)

  const [openBulkAddDialog, setOpenBulkAddDialog] = useState(false)
  const [selectInputValues, setSelectInputValues] = useState(initialInputSelectValues)

  const communicationMutation = useUpdateCommunication()

  // for focusing title input
  const inputRef = useRef(null)

  const { isConfirmed } = useConfirm()

  const handleFormValueChange = (name, value) => {
    if (!currentEditedCommunicationRef.current) {
      currentEditedCommunicationRef.current = formValues
    }
    setFormValues({ ...formValues, [name]: value })
  }

  const handleInputValueChange = (name, value) => {
    setSelectInputValues({ ...selectInputValues, [name]: value })
  }

  const confirmChangeAction = async () => {
    if (hasUnsavedChanges()) {
      return await isConfirmed('You have unsaved changes. Are you sure you want to continue?')
    }
    return true
  }

  const hasUnsavedChanges = () => {
    if (!currentEditedCommunicationRef.current) {
      return false
    }

    return JSON.stringify(currentEditedCommunicationRef.current) !== JSON.stringify(formValues)
  }

  const handleEdit = async (communication) => {
    const confirmEdit = await confirmChangeAction()
    if (confirmEdit) {
      currentEditedCommunicationRef.current = communication
      setSelectInputValues(initialInputSelectValues)
      setFormValues(communication)
      inputRef.current.focus()
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const confirmed = await isConfirmed('Are you sure you want to submit/update this communication?')

    if (confirmed) {
      let communicationBody = {
        ...formValues,
        audience_locations: formValues.audience_locations.join(','),
        audience_features: formValues.audience_features.map(toSnakeCase).join(','),
        audience_roles: formValues.audience_roles.join(','),
      }
      delete communicationBody.created_by
      delete communicationBody.updated_by
      delete communicationBody.created_timestamp
      delete communicationBody.updated_timestamp

      communicationMutation.mutate(communicationBody, {
        onSuccess: () => {
          currentEditedCommunicationRef.current = null
        },
      })
    }
  }

  const handleNew = async () => {
    const confirmEdit = await confirmChangeAction()
    if (confirmEdit) {
      setSelectInputValues(initialInputSelectValues)
      setFormValues(initialFormValues)
      inputRef.current.focus()
      currentEditedCommunicationRef.current = null
    }
  }

  const handleBulkAddButton = () => {
    setOpenBulkAddDialog(true)
  }

  const handleBulkDialogYes = (name = 'audience_locations', value) => {
    const mergedLocations = formValues[name].concat(value)
    const uniqueLocations = [...new Set(mergedLocations)]
    setFormValues({ ...formValues, [name]: uniqueLocations })
    setOpenBulkAddDialog(false)
  }

  const handleBulkDialogNo = () => {
    setOpenBulkAddDialog(false)
  }

  return (
    <>
      <Grid container sx={{ height: 'calc(100% - 64px)' }}>
        <HeaderTitle title={'Communication'} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit} onReset={handleNew}>
              <Grid container spacing={2}>
                <Grid container item xs={12} md={2} gap={1}>
                  <TextField
                    fullWidth
                    label="Title"
                    name="title"
                    value={formValues.title}
                    inputRef={inputRef}
                    onChange={(e) => handleFormValueChange(e.target.name, e.target.value)}
                  />
                  <TextField
                    fullWidth
                    label="Start Date (CT)"
                    type="datetime-local"
                    name="start_timestamp"
                    value={formValues.start_timestamp}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleFormValueChange(e.target.name, e.target.value)}
                  />
                  <TextField
                    fullWidth
                    label="End Date (CT)"
                    type="datetime-local"
                    name="end_timestamp"
                    value={formValues.end_timestamp}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleFormValueChange(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid container item xs={12} md={6} gap={1}>
                  <LocationDropdown
                    handleFormValueChange={handleFormValueChange}
                    handleInputValueChange={handleInputValueChange}
                    formValues={formValues}
                    selectInputValues={selectInputValues}
                    handleBulkAddButton={handleBulkAddButton}
                  />
                  <FeatureDropdown
                    handleFormValueChange={handleFormValueChange}
                    handleInputValueChange={handleInputValueChange}
                    formValues={formValues}
                    selectInputValues={selectInputValues}
                  />
                  <UserGroupDropdown
                    handleFormValueChange={handleFormValueChange}
                    handleInputValueChange={handleInputValueChange}
                    formValues={formValues}
                    selectInputValues={selectInputValues}
                  />
                </Grid>
                <Grid item xs={12} md={4} sx={{ position: 'relative' }}>
                  <HelpPopover />
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    label="Message"
                    name="text"
                    rows={6}
                    InputLabelProps={{ sx: { paddingTop: '10px' } }}
                    sx={{ overflow: 'scroll', paddingTop: '10px' }}
                    value={formValues.text}
                    onChange={(e) => handleFormValueChange(e.target.name, e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2} paddingTop={2} justifyContent={'flex-end'}>
                <Grid item>
                  <Button variant="outlined" color="primary" type="reset">
                    Create New Communication
                  </Button>
                </Grid>
                <Grid item>
                  <CommunicationDialogPreview communication={formValues} />
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid container item xs={12}>
            <CommunicationTable handleEdit={handleEdit} />
          </Grid>
        </Grid>
        <BulkAddDialog
          handleBulkDialogYes={handleBulkDialogYes}
          handleBulkDialogNo={handleBulkDialogNo}
          openBulkAddDialog={openBulkAddDialog}
        />
      </Grid>
    </>
  )
}

function HelpPopover() {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const popoverId = open ? 'help-popover' : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={{ position: 'absolute', left: -5, top: 4, zIndex: '10' }}>
      <IconButton color="primary" aria-describedby={popoverId} onClick={handleClick}>
        <HelpIcon />
      </IconButton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <HtmlElements />
      </Popover>
    </div>
  )
}

function HtmlElements() {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 2000)
      },
      (err) => {
        console.error('Could not copy text:', err)
      },
    )
  }

  return (
    <Grid container flexDirection={'column'} gap={2} padding={2}>
      {isCopied ? <CheckCircleIcon color="primary" sx={{ position: 'absolute', left: 20 }} /> : <></>}
      {htmlTags.map((html) => {
        const innerHTML = `${html.tag}${html.description}${html.closingTag}`
        return (
          <Tooltip title={'Copy'}>
            <Grid
              container
              item
              justifyContent={'center'}
              onClick={() => handleCopyClick(innerHTML)}
              style={{ cursor: 'pointer' }}
            >
              {`${html.tag}`}
              <div dangerouslySetInnerHTML={{ __html: innerHTML }} />
              {`${html.closingTag}`}
            </Grid>
          </Tooltip>
        )
      })}
    </Grid>
  )
}

const htmlTags = [
  {
    tag: '<b>',
    description: 'Defines bold text',
    closingTag: '</b>',
  },
  {
    tag: '<em>',
    description: 'Defines emphasized text',
    closingTag: '</em>',
  },
  {
    tag: '<i>',
    description: 'Defines a part of text in an alternate voice or mood',
    closingTag: '</i>',
  },
  {
    tag: '<small>',
    description: 'Defines smaller text',
    closingTag: '</small>',
  },
  {
    tag: '<strong>',
    description: 'Defines important text',
    closingTag: '</strong>',
  },
  {
    tag: '<sub>',
    description: 'Defines subscripted text',
    closingTag: '</sub>',
  },
  {
    tag: '<sup>',
    description: 'Defines superscripted text',
    closingTag: '</sup>',
  },
  {
    tag: '<ins>',
    description: 'Defines inserted text',
    closingTag: '</ins>',
  },
  {
    tag: '<del>',
    description: 'Defines deleted text',
    closingTag: '</del>',
  },
  {
    tag: '<mark>',
    description: 'Defines marked/highlighted text',
    closingTag: '</mark>',
  },
  {
    tag: '<br>',
    description: 'Defines new line break',
    closingTag: '',
  },
  {
    tag: '<hr>',
    description: 'Defines horizontal line',
    closingTag: '',
  },
]
