import { CircularProgress, Divider, Typography, useTheme } from '@mui/material'
import React from 'react'
import { formatHours } from '../../utils/formatHours'

const getStyles = (theme) => ({
  root: {
    width: '100%',
  },
  loadingIconContainer: theme.loadingIconContainer,
  highlights: {
    fontWeight: 'bold',
    fontSize: '105%',
    paddingTop: '2px',
  },
  highlightsContainer: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
  },
  highlightsContainerPosition: {
    width: '100%',
  },
  row: {
    margin: 0,
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  col: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
  },
  colStart: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  colCenter: {
    flexGrow: '1',
    maxWidth: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
  },
  colEnd: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'right',
  },
  colEndTextLeft: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  redText: {
    color: '#CC0000',
  },
  rightAlign: {
    textAlign: 'right',
  },
  missedPunchAdjHrsStatus: {
    marginTop: '3px',
  },
  thickerBorder: {
    height: 2,
    backgroundColor: theme.palette.tertiary.main,
    border: 'none',
  },
})

const HoursSummaryWeekly = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const { scheduledHrs, totalScheduledHrsLoader, totalHrs, missedPunch } = props

  const showTotalScheduledHrs = () => {
    if (totalScheduledHrsLoader) {
      return (
        <>
          {`Scheduled Hrs: `}{' '}
          <div>
            <CircularProgress size={18} sx={styles.loadingIconContainer} />
          </div>
        </>
      )
    } else {
      return <>{`Scheduled Hrs: ${formatHours?.format(scheduledHrs)}`}</>
    }
  }

  return (
    <React.Fragment>
      <div style={styles.highlightsContainerPosition}>
        <div style={{ ...styles.row, ...styles.highlightsContainer }}>
          <Typography
            component="div"
            variant="body2"
            tabIndex={0}
            role="heading"
            aria-label={totalScheduledHrsLoader ? '' : 'scheduled hours: ' + scheduledHrs + ' hours'}
            sx={{ ...styles.colStart, ...styles.highlights, ...(missedPunch && styles.missedPunchAdjHrsStatus) }}
          >
            {showTotalScheduledHrs()}
          </Typography>
          <Typography
            variant="body2"
            tabIndex={0}
            role="heading"
            aria-label={
              missedPunch
                ? 'total hours: ' + totalHrs + ' hours with one or more missed punches'
                : 'total hours: ' + totalHrs + ' hours'
            }
            sx={{ ...styles.colEnd, ...styles.highlights }}
          >
            {`Total Hrs: ${formatHours?.format(totalHrs)}`}
            {missedPunch && (
              <sup aria-hidden="true" style={styles.redText}>
                *
              </sup>
            )}
          </Typography>
        </div>
        <Divider role="presentation" sx={styles.thickerBorder} />
        {missedPunch && (
          <div aria-hidden="true" style={{ ...styles.rightAlign, ...styles.row }}>
            <Typography variant="body2" sx={{ ...styles.colEnd, ...styles.redText }}>
              <sup>*</sup> {' Missed Punch(es)'}
            </Typography>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default HoursSummaryWeekly
