import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { showNotificationError } from '../../store/notification/actionCreator'
import { useLocationDetails } from '../location/useLocationDetails'

export interface FeedbackQuestions {
  questions: Question[]
}

export interface Question {
  id: number
  question: string
}

interface QuestionsParams {
  persona: string
  location_id: string
}

const fetchFeedbackQuestions = async (params: QuestionsParams): Promise<FeedbackQuestions> => {
  let apiUrl = `${apiConfig.feedback.url}/questions?key=${apiConfig.location.key}`

  const { data } = await axios.get(apiUrl, {
    ...BASIC_REQUEST_CONFIG,
    params,
  })

  return data
}

export function useFeedbackQuestions() {
  const { isAuthenticated, login } = useAuth()
  const { user } = useUser()
  const { data: locationDetails } = useLocationDetails()
  const dispatch = useDispatch()
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  const feedbackQuestions = useQuery({
    queryKey: ['feedback'],
    queryFn: () =>
      fetchFeedbackQuestions({ persona: user.userData.persona, location_id: locationDetails?.location_id || '' }),
    suspense: true,
  })

  useEffect(() => {
    if (feedbackQuestions.error) {
      dispatch(showNotificationError(true, `Error fetching feedback questions`))
    }
  }, [feedbackQuestions.error, dispatch])

  return feedbackQuestions
}
