import axios from 'axios'
import apiConfig from '../../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../../constants/apiConstants'
import { ILocationsResponse } from '../responses/locations'

export interface LocationsParams {
  locationId: string
}

export const getLocation = (params: LocationsParams): Promise<ILocationsResponse> => {
  let location = params?.locationId?.padStart(4, '0')

  let apiUrl = `${apiConfig.location.url}/locations/${location}?key=${apiConfig.location.key}`

  return axios.get(apiUrl, BASIC_REQUEST_CONFIG).then((response) => response.data)
}
