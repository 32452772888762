import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { isValid } from 'date-fns'
import { useEffect, useState } from 'react'
import { REQUEST_SCREEN_TITLES } from '../../constants/RequestConstants'
import TimePicker from '../common/calendar/TimePicker'

const START_TIME_PARAM_NAME = 'startTime'
const ESTIMATED_ARRIVAL_TIME_PARAM_NAME = 'estimatedArrivalTime'
const HOURS_PARAM_NAME = 'hours'
const MINUTES_PARAM_NAME = 'minutes'
const TYPE_PARAM_NAME = 'type'
const HOUR_VALUES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const MINUTE_VALUES = [0, 15, 30, 45]
const MINUTE_STEP = 15
const MINUTE_VALUES_TIME_OFF = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
]
const MINUTE_STEP_TIME_OFF = 1

const RequestTimeOffRowInput = (props) => {
  const { rowDetail, handleRowDetail, requestTypes, rowId, screenTitle, disableManualInput } = props

  const initialTime = new Date(Math.round(new Date().getTime() / (1000 * 60 * 15)) * (1000 * 60 * 15))
  // const initialArrivalTime = addMinutes(initialTime, 15)

  const [estimatedArrivalTime, setEstimatedArrivalTime] = useState(null)

  useEffect(() => {
    if (!rowDetail?.startTime) {
      handleRowDetail(START_TIME_PARAM_NAME, initialTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createCypressTag = (componentName) => {
    return 'requestTimeOffRowInput_' + componentName + (rowId ? '_' + rowId : '')
  }

  const handleStartTime = (startTime) => {
    handleRowDetail(START_TIME_PARAM_NAME, startTime)
    if (estimatedArrivalTime) {
      handleHoursAndMinutes(startTime, estimatedArrivalTime)
    }
  }

  const handleHours = (hours) => {
    if (rowDetail[MINUTES_PARAM_NAME] === '') handleRowDetail(MINUTES_PARAM_NAME, 0)
    handleRowDetail(HOURS_PARAM_NAME, hours)
  }

  const handleMinutes = (minutes) => {
    if (rowDetail[HOURS_PARAM_NAME] === '') handleRowDetail(HOURS_PARAM_NAME, 0)
    handleRowDetail(MINUTES_PARAM_NAME, minutes)
  }

  const getHoursAndMinutes = (differenceInMinutes) => {
    let totalMinutes = differenceInMinutes
    if (differenceInMinutes <= 0) {
      totalMinutes = differenceInMinutes + 60 * 24
    }
    let minutes = totalMinutes % 60
    let hours = Math.floor(totalMinutes / 60)

    return { hours, minutes }
  }

  const handleHoursAndMinutes = (startTime, arrivalTime) => {
    if (!isValid(startTime) || !isValid(arrivalTime)) return

    let arrivalMinutes = arrivalTime?.getHours() * 60 + arrivalTime?.getMinutes()
    let startMinutes = startTime?.getHours() * 60 + startTime?.getMinutes()

    let differenceInMinutes = arrivalMinutes - startMinutes

    let { hours, minutes } = getHoursAndMinutes(differenceInMinutes)

    handleRowDetail(MINUTES_PARAM_NAME, minutes)
    handleRowDetail(HOURS_PARAM_NAME, hours)
  }

  const handleEstimatedArrivalTime = (arrivalTime) => {
    setEstimatedArrivalTime(arrivalTime)

    if (rowDetail[START_TIME_PARAM_NAME]) {
      handleHoursAndMinutes(rowDetail[START_TIME_PARAM_NAME], arrivalTime)
    }
  }

  const getMinuteStep = () => {
    return screenTitle === REQUEST_SCREEN_TITLES.REQUEST_TIME_OFF ? MINUTE_STEP_TIME_OFF : MINUTE_STEP
  }

  const renderDetail = () => {
    return (
      <>
        {renderStartTimeSection()}
        {renderHoursMinutesSection()}
        {renderTypeSection()}
      </>
    )
  }

  const renderCallInDetail = () => {
    return (
      <>
        {renderStartTimeSection()}
        {renderEstimatedArrivalTimeSection()}
      </>
    )
  }

  const renderStartTimeSection = () => {
    return (
      <TimePicker
        id="startTime"
        label="Start Time"
        defaultValue={rowDetail[START_TIME_PARAM_NAME]}
        onChange={(startTime) => handleStartTime(startTime)}
        disabled={disableManualInput}
        disableOpenPicker={disableManualInput}
        sx={{ display: 'flex', minWidth: 90 }}
        minutesStep={getMinuteStep()}
        timeSteps={{
          hours: 1,
          minutes: getMinuteStep(),
        }}
        slotProps={{
          textField: {
            'data-cy': createCypressTag(START_TIME_PARAM_NAME),
          },
        }}
      />
    )
  }

  const renderEstimatedArrivalTimeSection = () => {
    return (
      <TimePicker
        id={ESTIMATED_ARRIVAL_TIME_PARAM_NAME}
        label="Estimated Arrival Time"
        defaultValue={estimatedArrivalTime}
        onChange={(arrivalTime) => handleEstimatedArrivalTime(arrivalTime)}
        sx={{ minWidth: 90 }}
        minutesStep={MINUTE_STEP}
        slotProps={{
          textField: {
            'data-cy': createCypressTag(ESTIMATED_ARRIVAL_TIME_PARAM_NAME),
          },
        }}
      />
    )
  }

  const renderHoursMinutesSection = () => {
    return (
      <Grid item container wrap="nowrap" justifyContent={'center'}>
        {renderHoursSection()}
        {renderMinutesSection()}
      </Grid>
    )
  }

  const renderHoursSection = () => {
    return (
      <TextField
        sx={{ minWidth: '45px' }}
        data-cy={createCypressTag(HOURS_PARAM_NAME)}
        id="Hours"
        label="Hours"
        select
        value={rowDetail[HOURS_PARAM_NAME]}
        onChange={(event) => handleHours(parseInt(event.target.value))}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disableManualInput}
      >
        {renderMenuItemList(HOUR_VALUES)}
      </TextField>
    )
  }

  const renderMinutesSection = () => {
    return screenTitle === REQUEST_SCREEN_TITLES.REQUEST_TIME_OFF ? (
      <TextField
        sx={{ minWidth: '45px' }}
        data-cy={createCypressTag(MINUTES_PARAM_NAME)}
        id={'Minutes'}
        label="Minutes"
        select
        value={rowDetail[MINUTES_PARAM_NAME]}
        onChange={(event) => handleMinutes(parseInt(event.target.value))}
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          MenuProps: {
            sx: { maxHeight: '510px' },
          },
        }}
        disabled={disableManualInput}
      >
        {renderMenuItemList(MINUTE_VALUES_TIME_OFF)}
      </TextField>
    ) : (
      <TextField
        sx={{ minWidth: '45px' }}
        data-cy={createCypressTag(MINUTES_PARAM_NAME)}
        id={'Minutes'}
        label="Minutes"
        select
        value={rowDetail[MINUTES_PARAM_NAME]}
        onChange={(event) => handleMinutes(parseInt(event.target.value))}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disableManualInput}
      >
        {renderMenuItemList(MINUTE_VALUES)}
      </TextField>
    )
  }

  const renderMenuItemList = (itemArray) => {
    return itemArray.map((item) => {
      return (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      )
    })
  }
  const renderTypeSection = () => {
    const requestTypeList = requestTypes?.map((requestType) => {
      return (
        <MenuItem key={requestType.primary_value} value={requestType.primary_value}>
          {requestType.primary_value}
        </MenuItem>
      )
    })

    return (
      <TextField
        data-cy={createCypressTag(TYPE_PARAM_NAME)}
        id="type"
        select
        label="Type"
        sx={{ marginRight: 2, minWidth: 140 }}
        value={rowDetail[TYPE_PARAM_NAME]}
        onChange={(e) => handleRowDetail(TYPE_PARAM_NAME, e.target.value)}
      >
        {requestTypeList}
      </TextField>
    )
  }

  if (screenTitle === REQUEST_SCREEN_TITLES.NOTIFY_CALL_IN) {
    return renderCallInDetail()
  } else {
    return renderDetail()
  }
}

export default RequestTimeOffRowInput
