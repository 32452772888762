import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Grid, IconButton, Paper, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

const getStyles = (theme) => ({
  weeklyCalendarBarContainerPosition: {
    width: '100%',
  },
  weeklyCalendarBar: {
    background: 'linear-gradient(to bottom, #bdbdbd 0%, #eeeeee 100%)', // theme.palette.tertiary.main,
    borderBottom: `1px solid ${theme.palette.tertiary.light}`,
    textAlign: 'center',
    marginBottom: '5px',
  },
  weeklyCalendar: {
    fontSize: '1em',
    fontWeight: '300',
    color: theme.palette.secondary.dark,
    margin: '0 auto',
    maxWidth: theme.mainScrollContainer.maxWidth,
    display: 'block',
    position: 'relative',
    width: '100%',
    background: theme.palette.tertiary.light,
  },
  labelCls: {
    paddingTop: 1,
  },
})

const CustomCalendarForAvailability = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { weekLabelsArr, currentWeekIndex, onCurrentWeekChange } = props
  const [currentWeekIndexLocal, setCurrentWeekIndexLocal] = useState(currentWeekIndex)
  const [currentWeek, setCurrentWeek] = useState(weekLabelsArr[currentWeekIndex].label)

  const handleRightClick = () => {
    if (currentWeekIndexLocal < weekLabelsArr.length - 1) {
      setCurrentWeek(weekLabelsArr[currentWeekIndexLocal + 1].label)
      setCurrentWeekIndexLocal(currentWeekIndexLocal + 1)
      onCurrentWeekChange(weekLabelsArr[currentWeekIndexLocal + 1])
    }
  }

  const handleLeftClick = () => {
    if (currentWeekIndexLocal > 0) {
      setCurrentWeek(weekLabelsArr[currentWeekIndexLocal - 1].label)
      setCurrentWeekIndexLocal(currentWeekIndexLocal - 1)
      onCurrentWeekChange(weekLabelsArr[currentWeekIndexLocal - 1])
    }
  }

  return (
    <div style={styles.weeklyCalendarBarContainerPosition}>
      <div style={styles.weeklyCalendarBar}>
        <Paper sx={styles.weeklyCalendar} square elevation={1}>
          <Grid container>
            <Grid item xs={3} md={3}>
              <IconButton
                aria-label="previous week"
                disabled={currentWeek === weekLabelsArr[0].label}
                onClick={handleLeftClick}
                tabIndex="0"
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography sx={styles.labelCls}>{currentWeek}</Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <IconButton
                aria-label="next week"
                disabled={currentWeek === weekLabelsArr[weekLabelsArr.length - 1].label}
                onClick={handleRightClick}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  )
}

export default CustomCalendarForAvailability
