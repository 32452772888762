import { Grid } from '@mui/material'
import { Element } from 'react-scroll'
import PunchCorrectionAuditItem from './PunchCorrectionAuditItem'

const PunchCorrectionAuditList = ({ auditList, request_id }) => {
  return (
    <Element name="scroll-to-element" id="scroll-to-element" className="element">
      <Grid container>
        <Grid item xs={12} md={12}>
          {auditList.map((item, index) => {
            return (
              <PunchCorrectionAuditItem
                auditItem={item}
                index={index + 1}
                requestId={request_id}
                id={item.transactional_id}
              />
            )
          })}
        </Grid>
      </Grid>
    </Element>
  )
}

export default PunchCorrectionAuditList
