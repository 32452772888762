import { usePunchCorrectionRequests } from '../../../../../../api/punchcorrection/usePunchCorrectionRequests'
import TableContent from '../../TableContent'
import PunchCorrectionRow from './PunchCorrectionRow'

export default function PunchCorrection() {
  const { data, error, isLoading, isFetching } = usePunchCorrectionRequests()

  return (
    <TableContent
      data={data?.data?.punch_correction_requests}
      totalCount={data?.headers['x-total-count']}
      numberOfRecords={data?.data?.total_punch_correction_requests}
      error={error}
      isLoading={isLoading}
      isFetching={isFetching}
      Row={PunchCorrectionRow}
    />
  )
}
