export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION'
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY'
export const SET_SORT_ORDER = 'SET_SORT_ORDER'
export const SET_SORT_BY = 'SET_SORT_BY'
export const SET_LEADER_CONFIG = 'SET_LEADER_CONFIG'
export const SET_LEADER_DATE = 'SET_LEADER_DATE'
export const TOGGLE_PANEL_EXPAND_COLLAPSE = 'TOGGLE_PANEL_EXPAND_COLLAPSE'
export const RESET_FILTERS = 'RESET_FILTERS'
export const SET_LEADER_FILTER_CONFIG = 'SET_LEADER_FILTER_CONFIG'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_SEARCH_NAME_TEXT = 'SET_SEARCH_NAME_TEXT'
export const SET_PAGE = 'SET_PAGE'
export const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE'
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT'
export const SET_CONTROL_PANEL_SETTINGS = 'SET_CONTROL_PANEL_SETTINGS'
