import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React from 'react'
import { getDateTimeWithNoTimezone } from '../../../../../utils/DateUtil'

const getStyles = (theme) => ({
  requestHeading: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  subText: {
    fontSize: 'medium',
    color: theme.infoMessages.color,
  },
  details: {
    flexDirection: 'column',
    padding: '0px 15px',
    marginBottom: '5px',
  },
  summary: {
    flex: 1,
  },
  requestDetailsExpandedColNames: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  requestDetailsColName: {
    flex: 1,
    fontSize: 'small',
    fontWeight: 'bold',
  },
  requestDetailsExpanded: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  requestDetails: {
    flex: 1,
    fontSize: 'medium',
    color: theme.infoMessages.color,
    paddingTop: '2px',
    paddingBottom: '3px',
  },
  datesExpanded: {
    margin: '5px 0',
  },
  statusTextField: {
    marginLeft: '5px',
  },
  statusListItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  root: {
    '& .MuiFilledInput-inputHiddenLabel': {
      paddingTop: 3,
      paddingBottom: 2,
    },
    '& .MuiInputBase-input': {
      paddingTop: 3,
      paddingBottom: 2,
    },
  },
})

const PunchCorrectionDayWiseDetails = ({ requestDetails, isTimeOffTeamMember }) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const subOutputDateFormat = isTimeOffTeamMember ? 'MM/DD' : 'MM/DD/YY' // date for request information when accordion is expanded
  const startTimeOutputDateFormat = 'hh:mm a' // time for request information when accordion is expanded

  const ariaLabelDate = (punchDate) => {
    var givenDate = moment(punchDate)

    // var day = givenDate.format('dddd')
    var month = givenDate.format('MMMM')
    var date = givenDate.format('D')
    var year = givenDate.format('YYYY')

    return `${month.substring(0, 3)} ${date}, ${year}`
  }

  const renderRequestDetailsHeader = () => {
    return (
      <div
        tabIndex={0}
        aria-label="punch correction request details - table"
        style={styles.requestDetailsExpandedColNames}
      >
        <Typography sx={styles.requestDetailsColName} style={{ flex: 1 }}>
          Date
        </Typography>
        <Typography id="punch_time_header" sx={styles.requestDetailsColName} style={{ flex: 1.75 }}>
          Punch Time
        </Typography>
        <Typography id="type_header" sx={styles.requestDetailsColName} style={{ flex: 1 }}>
          Type
        </Typography>
        <Typography sx={styles.requestDetailsColName} style={{ flex: 0.75 }}>
          Action
        </Typography>
      </div>
    )
  }

  const renderRequestDetail = (detail, index) => {
    let punchDate
    let punchTime

    if (detail.delete_existing_punch) {
      punchDate = moment(detail.existing_punch_date)
      punchTime = moment(getDateTimeWithNoTimezone(detail.existing_punch_time))
    } else {
      punchDate = moment(detail.new_punch_date)
      punchTime = moment(getDateTimeWithNoTimezone(detail.new_punch_time))
    }

    return (
      <div tabIndex="0" key={index} style={styles.requestDetailsExpanded}>
        <Typography
          tabIndex="0"
          role="heading"
          aria-label={`date ${ariaLabelDate(punchDate)}`}
          sx={styles.requestDetails}
          style={{ flex: 0.75 }}
        >
          {punchDate?.format(subOutputDateFormat)}
        </Typography>
        <Typography
          tabIndex="0"
          role="heading"
          aria-labelledby={`punch_time_header punch_time_val_${index}`}
          id={`punch_time_val_${index}`}
          sx={styles.requestDetails}
          style={{ flex: 1 }}
        >
          {punchTime?.format(startTimeOutputDateFormat)}
        </Typography>

        <Typography
          tabIndex="0"
          role="heading"
          aria-labelledby={`type_header type_val_${index}`}
          id={`type_val_${index}`}
          sx={styles.requestDetails}
          style={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          {detail.new_punch_type}
        </Typography>
        <Typography
          tabIndex="0"
          role="heading"
          aria-label={`action ${detail.action?.toLowerCase()}`}
          id="action_type_val"
          sx={styles.requestDetails}
          style={{ flex: 0.5 }}
        >
          {detail.action?.toUpperCase()}
        </Typography>
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderRequestDetailsHeader()}
      <div style={styles.subText}>
        <div style={styles.datesExpanded}>
          {requestDetails.map((detail, index) => renderRequestDetail(detail, index))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PunchCorrectionDayWiseDetails
