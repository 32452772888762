import moment from 'moment'
import { calculateMinutesInSpan, formatMinutesToDisplay, getDateTimeWithNoTimezone } from '../../../utils/DateUtil'
import { convertRequestDetails } from '../../../utils/leaderUtil'
import { processDetailTotals } from '../../../utils/requests'

export function TimeOffApiVO(data) {
  let { totalMinutes, totalPTOMinutes, totalUnpaidMinutes } = processDetailTotals(
    convertRequestDetails(data?.request_details),
  )
  this.requestId = data?.request_id
  this.startDate = data?.start_date
  this.endDate = data?.end_date
  this.lastUpdatedTimestamp = getDateTimeWithNoTimezone(data?.updated_timestamp)
  this.lastUpdatedBy = data?.updated_by
  this.createdTimestamp = getDateTimeWithNoTimezone(data?.created_timestamp)
  this.createdBy = data?.created_by_worker_id
  this.status = data?.status
  this.workerId = data?.worker_details?.worker_id
  this.fullName = data?.worker_details?.full_name
  this.jobClassification = data?.worker_details?.job_classification
  this.schedulingKey = data?.worker_details?.scheduling_key
  this.department = data?.worker_details?.department
  this.statuses = data?.request_details?.map((detail) => detail?.status).join(', ')
  this.types = [...new Set(data?.request_details?.map((detail) => detail?.type))].join(', ')
  this.duration = formatMinutesToDisplay(totalMinutes, 'large')
  this.startTimestamp = getDateTimeWithNoTimezone(data?.request_details[0]?.start_time)
  this.endTimestamp = getDateTimeWithNoTimezone(data?.request_details[data?.total_request_details - 1]?.end_time)
  this.estimatedArrival = getDateTimeWithNoTimezone(data?.request_details[data?.total_request_details - 1]?.end_time)
  this.requestDetails = data?.request_details
  this.comments = data?.comments
  this.totalPTO = formatMinutesToDisplay(totalPTOMinutes, 'large')
  this.totalUnpaid = formatMinutesToDisplay(totalUnpaidMinutes, 'large')
  this.getRequestDetailStartTimestamp = function (detailIndex) {
    return getDateTimeWithNoTimezone(data?.request_details[detailIndex]?.start_time)
  }
  this.getRequestDetailEndTimestamp = function (detailIndex) {
    return getDateTimeWithNoTimezone(data?.request_details[detailIndex]?.end_time)
  }
  this.getRequestDetailHours = function (detailIndex) {
    let totalMinutes = calculateMinutesInSpan(
      moment(data?.request_details[detailIndex]?.start_time),
      moment(data?.request_details[detailIndex]?.end_time),
    )
    return formatMinutesToDisplay(totalMinutes, 'small')
  }
  this.getRequestDetailStatus = function (detailIndex) {
    return data?.request_details[detailIndex]?.status
  }
  this.getRequestDetailType = function (detailIndex) {
    return data?.request_details[detailIndex]?.type
  }

  this.getRequestIdLabel = function () {
    return 'Request Id'
  }
  this.getStartDateLabel = function () {
    return 'Start Date'
  }
  this.getEndDateLabel = function () {
    return 'End Date'
  }
  this.getLastUpdatedTimestampLabel = function () {
    return 'Updated Timestamp'
  }
  this.getLastUpdatedByLabel = function () {
    return 'Last Updated By'
  }
  this.getCreatedTimestampLabel = function () {
    return 'Created Timestamp'
  }
  this.getCreatedByLabel = function () {
    return 'Created By'
  }
  this.getWorkerIdLabel = function () {
    return 'Worker Id'
  }
  this.getFullNameLabel = function () {
    return 'Full Name'
  }
  this.getJobClassificationLabel = function () {
    return 'Job'
  }
  this.getSchedulingKeyLabel = function () {
    return 'Scheduling Key'
  }
  this.getDepartmentLabel = function () {
    return 'Department'
  }
  this.getStatusesLabel = function () {
    return this.types.includes(',') ? 'Statuses' : 'Status'
  }
  this.getTypesLabel = function () {
    return this.types.includes(',') ? 'Types' : 'Type'
  }
  this.getStatusLabel = function () {
    return 'Status'
  }
  this.getTypeLabel = function () {
    return 'Type'
  }
  this.getDurationLabel = function () {
    return 'Duration'
  }
  this.getStartTimestampLabel = function () {
    return 'Start Timestamp'
  }
  this.getEndTimestampLabel = function () {
    return 'End Timestamp'
  }
  this.getEstimatedArrivalLabel = function () {
    return 'Estimated Arrival'
  }
  this.getRequestDetailStartTimestampLabel = function () {
    return 'Start'
  }
  this.getRequestDetailEndTimestampLabel = function () {
    return 'End'
  }
  this.getRequestDetailHoursLabel = function () {
    return 'Duration'
  }
  this.getRequestDetailStatusLabel = function () {
    return 'Status'
  }
  this.getRequestDetailTypeLabel = function () {
    return 'Type'
  }
  this.getRequestDetailsLabel = function () {
    return 'Request Details'
  }
  this.getTotalPTOLabel = function () {
    return 'Total PTO'
  }
  this.getTotalUnpaidLabel = function () {
    return 'Total Unpaid'
  }
  this.getStartDateFormat = function () {
    return 'YYYY-MM-DD'
  }
  this.getEndDateFormat = function () {
    return 'YYYY-MM-DD'
  }
  this.getLastUpdatedTimestampFormat = function () {
    return 'YYYY-MM-DDTHH:mm:ss'
  }
  this.getCreatedTimestampFormat = function () {
    return 'YYYY-MM-DDTHH:mm:ss'
  }
  this.getStartTimestampFormat = function () {
    return 'YYYY-MM-DDTHH:mm:ss'
  }
  this.getEndTimestampFormat = function () {
    return 'YYYY-MM-DDTHH:mm:ss'
  }
  this.getEstimatedArrivalFormat = function () {
    return 'YYYY-MM-DDTHH:mm:ss'
  }
  this.getRequestDetailStartTimestampFormat = function () {
    return 'YYYY-MM-DDTHH:mm:ss'
  }
  this.getRequestDetailEndTimestampFormat = function () {
    return 'YYYY-MM-DDTHH:mm:ss'
  }

  return this
}
