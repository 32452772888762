/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import TimecardWeeklyCalendar from '../../../../Calendar/TimecardWeeklyCalendar'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { getUserAvailability, setAvailabilityWeeklySelectedDate } from '../../../../../store/availability/actionCreator'
import { useDispatch, useSelector } from 'react-redux'
import { addDays, addWeeks, endOfWeek, isAfter, isBefore, parseISO, startOfWeek } from 'date-fns'
import { convertAmPmTimeFormat, getDateOfTodayWithNoTimestamp } from '../../../../../utils/DateUtil'
import { buildWeeklyAvailabilityData } from '../../../../../utils/buildWeeklyAvailabilityData'
import {
  AVAILABILITY_CALENDAR_MAX_FUTURE_WEEKS,
  FIXED_END_DATE_FROM_UKG,
  days,
  months,
} from '../../../../../constants/AvailabilityConstants'
import { padEmpIdWithZeros } from '../../../../../utils/EmployeeId'
import { cloneDeep } from 'lodash'
import { AvailabilityRulesEngine } from '../../../../Availability/AvailabilityRulesEngine'

const getStyles = (theme) => ({
  listContainer: {
    padding: '2px',
    marginTop: '3px',
  },
  rowCls: {
    marginBottom: 0,
    borderBottom: '1px solid #DEDEDE',
    '&:nth-child(1)': {
      borderTop: '1px solid #DEDEDE',
    },
  },
  dateCls: {
    textAlign: 'center',
    height: 50,
    padding: '6px',
    backgroundColor: '#F4F4F4',
    fontSize: theme.typography.pxToRem(14),
  },
  timeContainer: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
  },
  timeCls: {
    padding: '0 0 0 7px',
    display: 'inline-block',
    textAlign: 'center',
  },
  firstColumnHeader: {
    height: 50,
    backgroundColor: '#F4F4F4',
    borderRight: '1px solid #DEDEDE',
  },
  columnContainer: {
    backgroundColor: '#F4F4F4',
    textAlign: 'center',
  },
  columnHeader: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: theme.typography.pxToRem(14),
  },
  columnTitle: {
    padding: '0 0 0 7px',
    display: 'inline-block',
    textAlign: 'center',
    fontWeight: 'bold',
  },
})

const AvailabilityDayWiseDetails = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const {
    requestId,
    workerId,
    startDate,
    status,
    endDate,
    patternType,
    patternName,
    requestDetails,
    setDataForSubmission,
  } = props
  const [userNewAvailability, setUserNewAvailability] = useState({})
  const [newWeeklyAvailabilityData, setNewWeeklyAvailabilityData] = useState({})
  const minDate = addDays(new Date(startDate), 1)

  const maxDate =
    endDate === FIXED_END_DATE_FROM_UKG
      ? endOfWeek(addWeeks(getDateOfTodayWithNoTimestamp(), AVAILABILITY_CALENDAR_MAX_FUTURE_WEEKS - 1))
      : new Date(endDate)

  const userAvailability = useSelector((state) => state.availability.userAvailability)
  const weeklySelectedDate = useSelector((state) => state.availability.weeklySelectedDate)
  const [weeklyAvailabilityData, setWeeklyAvailabilityData] = useState({})
  const dispatch = useDispatch()

  let startDateInLocalTimezone = parseISO(new Date(startDate).toISOString().slice(0, -1))

  useEffect(() => {
    dispatch(setAvailabilityWeeklySelectedDate(new Date(startDate)))
    formatNewAvailability()
    dispatch(getUserAvailability(padEmpIdWithZeros(workerId.toString(), 10)))
  }, [])

  useEffect(() => {
    let weeklySelectedDateInLocalTimezone = parseISO(weeklySelectedDate.toISOString().slice(0, -1))
    if (Object.keys(userAvailability).length > 0) {
      if (userAvailability?.availabilities.length > 0) {
        let userAvailabilities = cloneDeep([...userAvailability.availabilities])
        let linearAvailablities = AvailabilityRulesEngine.process(userAvailabilities)
        let weeklyAvailability = buildWeeklyAvailabilityData(startOfWeek(startDateInLocalTimezone), linearAvailablities)

        setWeeklyAvailabilityData(weeklyAvailability)
      }
    }
    if (Object.keys(userNewAvailability).length > 0) {
      let weeklyAvailability = buildWeeklyAvailabilityData(weeklySelectedDateInLocalTimezone, [userNewAvailability])
      setNewWeeklyAvailabilityData(weeklyAvailability)
      setDataForSubmission(userNewAvailability)
    }
  }, [userAvailability])

  useEffect(() => {
    let weeklySelectedDateInLocalTimezone = parseISO(weeklySelectedDate.toISOString().slice(0, -1))
    if (Object.keys(userAvailability).length > 0) {
      if (userAvailability?.availabilities.length > 0) {
        let userAvailabilities = cloneDeep([...userAvailability.availabilities])
        let linearAvailablities = AvailabilityRulesEngine.process(userAvailabilities)
        let weeklyAvailability = buildWeeklyAvailabilityData(weeklySelectedDateInLocalTimezone, linearAvailablities)
        setWeeklyAvailabilityData(weeklyAvailability)
      }
    }

    if (Object.keys(userNewAvailability).length > 0) {
      let weeklyAvailability = buildWeeklyAvailabilityData(weeklySelectedDateInLocalTimezone, [userNewAvailability])
      setNewWeeklyAvailabilityData(weeklyAvailability)
    }
  }, [weeklySelectedDate])

  const formatNewAvailability = () => {
    let newWeeklyAvaiabilities = {}
    let week_wise_availabilities = {}
    week_wise_availabilities.week_1 = []

    days.forEach((day, index) => {
      let dayObj = {}
      dayObj.day_id = index + 1
      dayObj.day = day
      dayObj.week_number = 1
      dayObj.time_intervals = []

      week_wise_availabilities.week_1.push({ ...dayObj })
    })

    if (requestDetails.some((detail) => detail.week_number === 2)) {
      week_wise_availabilities.week_2 = []
      days.forEach((day, index) => {
        let dayObj = {}
        dayObj.day_id = index + 1
        dayObj.day = day
        dayObj.week_number = 2
        dayObj.time_intervals = []

        week_wise_availabilities.week_2.push({ ...dayObj })
      })
    }

    for (let detail of requestDetails) {
      if (detail.week_number === 1) {
        for (let day of week_wise_availabilities.week_1) {
          if (day.day_id === detail.day_id) {
            let timePeriodObj = {}
            timePeriodObj.day = detail.day_name
            timePeriodObj.level = detail.level_name
            timePeriodObj.start_time = detail.start_time
            timePeriodObj.end_time = detail.end_time
            day.time_intervals.push(timePeriodObj)
          }
        }
      } else if (detail.week_number === 2) {
        for (let day of week_wise_availabilities.week_2) {
          if (day.day_id === detail.day_id) {
            let timePeriodObj = {}
            timePeriodObj.day = detail.day_name
            timePeriodObj.level = detail.level_name
            timePeriodObj.start_time = detail.start_time
            timePeriodObj.end_time = detail.end_time
            day.time_intervals.push(timePeriodObj)
          }
        }
      }
    }

    newWeeklyAvaiabilities.availability_id = requestId
    newWeeklyAvaiabilities.total_week_wise_availabilities = 1
    newWeeklyAvaiabilities.effective_date = startDate
    newWeeklyAvaiabilities.expiration_date = endDate
    newWeeklyAvaiabilities.pattern_name = patternName
    newWeeklyAvaiabilities.pattern_type = patternType
    newWeeklyAvaiabilities.status = status
    newWeeklyAvaiabilities.week_wise_availabilities = week_wise_availabilities

    setUserNewAvailability(newWeeklyAvaiabilities)
  }

  const handleDateChange = (date) => {
    let inCurrentWeek = isSelectedDateInWeekOfPrevSelectedDate(date)

    if (!inCurrentWeek) {
      handleDifferentWeekDateChange(date)
    } else {
      dispatch(setAvailabilityWeeklySelectedDate(date))
    }
  }

  const handleDifferentWeekDateChange = (date) => {
    dispatch(setAvailabilityWeeklySelectedDate(date))
  }

  const isSelectedDateInWeekOfPrevSelectedDate = (date) => {
    return !(isBefore(date, startOfWeek(weeklySelectedDate)) || isAfter(date, endOfWeek(weeklySelectedDate)))
  }

  const viewCalendar = () => {
    let weeklySelectedDateInLocalTimezone = parseISO(weeklySelectedDate.toISOString().slice(0, -1))
    return (
      <TimecardWeeklyCalendar
        id="availability-weekly-calendar"
        selectedDate={weeklySelectedDateInLocalTimezone}
        onChange={(date) => handleDateChange(date)}
        minDate={minDate}
        maxDate={maxDate}
      />
    )
  }

  const printDate = (index) => {
    let weeklySelectedDateInLocalTimezone = parseISO(weeklySelectedDate.toISOString().slice(0, -1))
    let date = addDays(startOfWeek(weeklySelectedDateInLocalTimezone), index).getDate().toString()
    let month = (addDays(startOfWeek(weeklySelectedDateInLocalTimezone), index).getMonth() + 1).toString()
    return `${month.padStart(2, 0)}/${date.padStart(2, 0)}`
  }

  const ariaLabelDate = (index) => {
    let weeklySelectedDateInLocalTimezone = parseISO(weeklySelectedDate.toISOString().slice(0, -1))
    let date = addDays(startOfWeek(weeklySelectedDateInLocalTimezone), index).getDate().toString()
    let month = addDays(startOfWeek(weeklySelectedDateInLocalTimezone), index).getMonth()
    return `${months[month]} ${date}`
  }

  const renderComponent = () => {
    return (
      <React.Fragment>
        <Box
          tabIndex={0}
          role="heading"
          aria-label="comparison table - Current Availability versus New Request"
          sx={styles.rowCls}
        >
          <Grid container>
            <Grid item xs={2} sm={2} md={2} sx={styles.firstColumnHeader} />
            <Grid item xs={5} sm={5} md={5} columnSpacing={1} sx={styles.columnContainer}>
              <div style={styles.columnHeader}>
                <Typography id="caHeader" sx={styles.columnTitle} variant="body2">
                  Current Availability
                </Typography>
              </div>
            </Grid>
            <Grid item xs={5} sm={5} md={5} columnSpacing={1} sx={styles.columnContainer}>
              <div style={styles.columnHeader}>
                <Typography id="nrHeader" sx={styles.columnTitle} variant="body2">
                  New Request
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
        {Object.keys(newWeeklyAvailabilityData).length > 0 &&
          newWeeklyAvailabilityData.pattern.map((newItem, index) => (
            <Box key={index} sx={styles.rowCls}>
              <Grid tabIndex={0} container>
                <Grid item xs={2} sm={2} md={2} sx={styles.dateCls}>
                  <Typography role="heading" aria-label={`${ariaLabelDate(index)}`} variant="body2">
                    {printDate(index)}
                  </Typography>
                  <Typography role="heading" aria-label={days[index]} variant="body2">
                    {newItem.day.toUpperCase() === days[index].toUpperCase()
                      ? newItem.day.substring(0, 3).toUpperCase()
                      : days[index].substring(0, 3).toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={5} sm={5} md={5} columnSpacing={1}>
                  <div style={styles.timeContainer}>
                    {Object.keys(weeklyAvailabilityData).length > 0 &&
                      weeklyAvailabilityData.pattern[index]?.time_intervals?.map((interval, indexKey) => {
                        let timeSelection = `${convertAmPmTimeFormat(interval.start_time)} - ${convertAmPmTimeFormat(
                          interval.end_time,
                        )}`
                        if (interval.level.toUpperCase() === 'AVAILABLE') {
                          return (
                            <Typography
                              id={`caTimeRange_${index}_${indexKey}`}
                              role="heading"
                              aria-labelledby={`caHeader caTimeRange_${index}_${indexKey}`}
                              key={`caTimeRange_${index}_${indexKey}`}
                              sx={styles.timeCls}
                              variant="body2"
                            >
                              {timeSelection}
                            </Typography>
                          )
                        } else {
                          return null
                        }
                      })}
                    {Object.keys(weeklyAvailabilityData).length > 0 &&
                      (weeklyAvailabilityData.pattern[index] === undefined ||
                        weeklyAvailabilityData.pattern[index] === null) && (
                        <Typography role="heading" aria-label={`current availability unavailable`} variant="body2" />
                      )}
                  </div>
                </Grid>
                <Grid item xs={5} sm={5} md={5} columnSpacing={1}>
                  <div style={styles.timeContainer}>
                    {newItem.time_intervals.map((interval, indexKey) => {
                      let timeSelection = `${convertAmPmTimeFormat(interval.start_time)} - ${convertAmPmTimeFormat(
                        interval.end_time,
                      )}`
                      if (interval.level.toUpperCase() === 'AVAILABLE') {
                        return (
                          <Typography
                            id={`nrTimeRange_${index}_${indexKey}`}
                            key={`nrTimeRange_${index}_${indexKey}`}
                            role="heading"
                            aria-labelledby={`nrHeader nrTimeRange_${index}_${indexKey}`}
                            sx={styles.timeCls}
                            variant="body2"
                          >
                            {timeSelection}
                          </Typography>
                        )
                      } else {
                        return null
                      }
                    })}
                    {newItem.time_intervals.length === 0 && (
                      <Typography role="heading" aria-label={`new request unavailable`} variant="body2" />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>
          ))}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {viewCalendar()}
      {renderComponent()}
    </React.Fragment>
  )
}

export default AvailabilityDayWiseDetails
