import { Grid, Typography } from '@mui/material'
import CategorySegmentDetailList from './CategorySegmentDetailList'
import Error from '@mui/icons-material/Error'
import ChipPopover from '../../common/ChipPopover'

const CategorySegmentDetailSection = ({ labelValueList, chipLabel, chipMessage }) => {
  return (
    <Grid container data-cy={'detailPageContent-segment-detail'} sx={{ paddingX: '16px' }}>
      <Grid item xs={12}>
        <Grid container data-cy={'detailPageContent-segment-detail-header'}>
          <Grid item xs={5}>
            <Typography variant={'subtitle1'} component="h3">
              {'Segment Details'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {chipLabel && (
              <ChipPopover
                icon={<Error />}
                label={chipLabel}
                size={'small'}
                color={'primary'}
                variant={'outlined'}
                popOverMessage={chipMessage}
              />
            )}
          </Grid>
        </Grid>
        <CategorySegmentDetailList dataList={labelValueList} />{' '}
      </Grid>
    </Grid>
  )
}

export default CategorySegmentDetailSection
