import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Divider, Grid, Link, ListItem, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React, { useCallback } from 'react'
import DisplayAvailableShift from './DisplayAvailableShift'

const styles = {
  individualShift: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  dateHeading: {
    fontWeight: 'bold',
    fontSize: 'medium',
  },
  expansionPanel: {
    display: 'block',
    padding: '8px 10px 10px',
  },
}

const AvailableShiftsAccordion = (props) => {
  const { scheduleDate, getLocationIds, hasMissedPunch, shifts, expandedList, setExpandedList, panelId } = props

  const handleChange = useCallback(() => {
    setExpandedList((prevList) => {
      let newArr = [...prevList]
      newArr[panelId] = !prevList[panelId]
      return newArr
    })
  }, [panelId, setExpandedList])

  return (
    <>
      <Accordion id={`panel${props.panelId}`} expanded={expandedList[panelId]} onChange={handleChange} square>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography variant="body2" sx={styles.dateHeading}>
            {scheduleDate}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={styles.expansionPanel}>
          {shifts.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Link style={{ textDecoration: 'none' }}>
                  <ListItem sx={styles.individualShift}>
                    <Grid container>
                      <DisplayAvailableShift
                        hasMissedPunch={hasMissedPunch}
                        shift={item}
                        shiftAvailableDate={scheduleDate}
                        getLocationIds={getLocationIds}
                      />
                    </Grid>
                  </ListItem>
                </Link>
                {index !== shifts.length - 1 && <Divider />}
              </React.Fragment>
            )
          })}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default AvailableShiftsAccordion
