import { combineReducers } from 'redux'
import weeklySchedule from './WeeklySchedulePage/reducer'
import auth from './auth'
import availability from './availability/reducer'
import communication from './communication/reducer'
import dailySchedule from './dailySchedulePage/reducer'
import desiredHours from './desiredHours/reducer'
import header from './header/reducer'
import layout from './layout/reducer'
import leader from './leader/reducer'
import leaderViewAutomation from './leaderViewAutomation/reducer'
import leaderViewDirectoryAutomation from './leaderViewDirectoryAutomation/reducer'
import leaderViewMandatoryAutomation from './leaderViewMandatoryAutomation/reducer'
import leaderViewPTORequestsAutomation from './leaderViewPTORequestsAutomation/reducer'
import leaderViewVoluntaryAutomation from './leaderViewVoluntaryAutomation/reducer'
import notification from './notification/reducer'
import punchCorrection from './punchCorrection/reducer'
import pushNotification from './pushNotification/reducer'
import requestTimeOff from './requestTimeOff/reducer'
import requests from './requests/reducer'
import settings from './settings/reducer'
import swapShift from './swapShift/reducer'
import timecardDaily from './timecardDaily/reducer'
import timecardWeekly from './timecardWeekly/reducer'
import tmOptionalAvailability from './tmOptionalAvailability/reducer'
import workerRedemptionCode from './workerRedemptionCode/reducer'

const rootReducer = combineReducers({
  auth,
  layout,
  notification,
  weeklySchedule,
  dailySchedule,
  header,
  tmOptionalAvailability,
  leader,
  leaderViewAutomation,
  leaderViewVoluntaryAutomation,
  leaderViewMandatoryAutomation,
  leaderViewDirectoryAutomation,
  leaderViewPTORequestsAutomation,
  workerRedemptionCode,
  pushNotification,
  timecardWeekly,
  timecardDaily,
  requests,
  requestTimeOff,
  swapShift,
  desiredHours,
  settings,
  availability,
  punchCorrection,
  communication,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
