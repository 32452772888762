import { differenceInMinutes } from 'date-fns'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { storeReviewerCurrentShift } from '../../store/swapShift/actionCreator'
import { MultiClickButton as ReviewSwapShiftButton } from '../../utils/MultiClickButton'

const SWAP_STATUS_OPEN = 'OPEN'

const ReviewSwapRequestsAction = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const reviewClicked = () => {
    const { displaySegment, schedule } = props
    let ownShift = {
      ...displaySegment,
      schedule_date: schedule.schedule_date,
      schedule_start: displaySegment.segment_start,
      schedule_end: displaySegment.segment_end,
      shift_hours: handleHours(displaySegment),
    }

    dispatch(storeReviewerCurrentShift(ownShift))
    takeToSwapRequests()
  }

  const takeToSwapRequests = () => {
    props.handleDailyClick()
    navigate('/reviewswaprequests')
  }

  const handleHours = (shift) => {
    let shiftInHours =
      differenceInMinutes(
        new Date(shift.segment_end.split(' ')[0] + 'T' + shift.segment_end.split(' ')[1]),
        new Date(shift.segment_start.split(' ')[0] + 'T' + shift.segment_start.split(' ')[1]),
      ) / 60
    return Number.parseFloat(shiftInHours).toFixed(2)
  }

  const renderReviewSwapRequestButton = () => {
    const { schedule, counter, displaySegment } = props

    if (schedule.total_display_segments > 0) {
      return <React.Fragment key={counter}>{shiftSwapButtonActions(displaySegment)}</React.Fragment>
    }
  }

  const shiftSwapButtonActions = (displaySegment) => {
    const { styles } = props

    if (displaySegment.swapStatusAsAccepter === 'PAST' || displaySegment.swapStatusAsAccepter !== SWAP_STATUS_OPEN) {
      return null
    } else {
      return (
        <ReviewSwapShiftButton
          ariaLabel={'Review Swap Request'}
          label=""
          handleClick={reviewClicked}
          handleDoubleClick={reviewClicked}
          styles={styles}
          pageName="shiftswapreview"
          data-cy="shiftSwapReviewBtn"
        />
      )
    }
  }

  return <React.Fragment>{renderReviewSwapRequestButton(props)}</React.Fragment>
}

export default ReviewSwapRequestsAction
