import React from 'react'
import FixAPunchAcceptModal from './FixAPunchAcceptModal'

const FixAPunchAcceptGoBack = (props) => {
  const { showFixAPunchDialog, handleGoBackAction, handleAcceptAction } = props

  return (
    <React.Fragment>
      <FixAPunchAcceptModal
        fixAPunchDialogOpen={showFixAPunchDialog}
        handleGoBackAction={handleGoBackAction}
        handleAcceptAction={handleAcceptAction}
        showAccrualMessage={true}
      />
    </React.Fragment>
  )
}

export default FixAPunchAcceptGoBack
