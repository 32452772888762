import React from 'react'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

const DailySchedulePaycodeEdits = (props) => {
  return (
    <>
      <React.Fragment key={props.key}>
        <ListItem>
          <Grid container>
            <Grid item xs={9} md={9}>
              <Typography variant="body2" sx={props.styles.paycodeEdits}>
                {props.element.paycode_name} - {props.element.paycode_start_time}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant="body2" sx={props.styles.paycodeEdits}>
                {props.element.paycode_total} Hrs
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <Divider />
      </React.Fragment>
    </>
  )
}
export default DailySchedulePaycodeEdits
