import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { CircularProgress, Divider, Paper, useTheme } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { endOfWeek, format, getWeek, isAfter, isBefore, startOfWeek, subWeeks } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { months } from '../../constants/AvailabilityConstants'
import { CURRENT_PAY_PERIOD, CUSTOM, PREV_PAY_PERIOD } from '../../constants/PayPeriodMenuItems'
import { MAX_WEEKS_ALLOWED_BACKWARDS } from '../../constants/TimecardConstants'
import {
  ERROR_CODES_SHOW_MESSAGE_TIMECARD,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER_TIMECARD,
  NO_TIMECARD_MESSAGE,
  UNABLE_TO_LOAD_TIMECARDS,
} from '../../constants/errorConstants'
import { setBackToComponent } from '../../store/header/actionCreator'
import { setTimecardDailySelectedDate } from '../../store/timecardDaily/actionCreator'
import {
  getTimecardDataWeekly,
  handleGetWeeklyScheduleData,
  setTimecardWeeklyLoading,
  setTimecardWeeklySelectedDate,
  setTotalScheduledHrsWeeklyLoading,
} from '../../store/timecardWeekly/actionCreator'
import { getDateOfTodayWithNoTimestamp, getNowDateInTimezone } from '../../utils/DateUtil'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { formatErrorCode } from '../../utils/ErrorHandling'
import { VIEW_TIME_CARD } from '../../utils/ScreenName'
import TimecardWeeklyCalendar from '../Calendar/TimecardWeeklyCalendar'
import HeaderTitle from '../Header/HeaderTitle'
import NotAuthorized from '../common/fallback/NotAuthorized'
import DatesOnWeekly from './DatesOnWeekly'
import HoursSummaryWeekly from './HoursSummaryWeekly'
import PayPeriodMenuDialog from './PayPeriodMenu'

const getStyles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  loadingIconContainer: theme.loadingIconContainer,
  highlights: {
    fontWeight: 'bold',
    fontSize: '86%',
    paddingTop: '2px',
  },
  highlightsContainer: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
  },
  highlightsContainerPosition: {
    width: '100%',
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingTop: '0',
    paddingBottom: '0',
  },
  displaySegmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
  },
  startTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
  },
  endTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: '1',
    minHeight: '32px',
    paddingBottom: '.25em',
  },
  jobContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobContainerAtPopup: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobName: {
    paddingTop: '3px',
  },
  iconMargin: {
    marginRight: '.25em',
  },
  indicator: {
    marginTop: '-2px',
    marginLeft: '-15px',
    marginRight: '5px',
    fontSize: '24px',
    color: 'black',
    zIndex: '1',
  },
  jobNameContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
  },
  paycodeEditsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
  },
  paycodeEdits: {
    fontWeight: '300',
  },
  dateHeading: {
    fontWeight: 'bold',
    fontSize: 'medium',
  },
  lastEdit: {
    color: theme.palette.secondary.light,
  },
  dividerBox: {
    flexGrow: '1',
  },
  dividerStyle: {
    border: `1px solid ${theme.palette.tertiary.dark}`,
  },
  expandCollapseButtonsContainer: {
    margin: '0 auto',
    minHeight: '56px',
    maxWidth: '640px',
    width: '100%',
    padding: '0px',
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    alignItems: 'center',
  },
  arrowBtn: {
    paddingRight: '16px',
  },
  coverShiftBtnGrid: {
    display: 'flex',
    flexFlow: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  accordionElement: {
    paddingBottom: '1px',
  },
  loaderStyles: {
    fontSize: 16,
    display: 'flex',
  },
  innerLoaderLabelStyles: {
    paddingLeft: '6px',
    fontColor: '#cc0000',
  },
  individualShift: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  mobileButton: {
    maxWidth: '120px',
  },
  addtionalJobsContainer: {
    paddingTop: '1em',
  },
  menuContainer: {
    fontSize: '1em',
    fontWeight: '300',
    color: theme.palette.secondary.dark,
    margin: '0 auto',
    maxWidth: theme.mainScrollContainer.maxWidth,
    display: 'block',
    position: 'relative',
    width: '100%',
  },
  greyBG: {
    background: theme.palette.tertiary.light,
  },
  iconCorrection: {
    margin: '-2px 0',
    cursor: 'pointer',
  },
  payPeriodButton: {
    fontWeight: 'bold',
  },
  row: {
    margin: 0,
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  col: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
  },
  colStart: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  colCenter: {
    flexGrow: '1',
    maxWidth: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
  },
  colEnd: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  redText: {
    color: '#CC0000',
  },
  boldText: {
    fontWeight: 'bold',
  },
  rightAlign: {
    textAlign: 'right',
  },
  missedPunchAdjHrsStatus: {
    marginTop: '3px',
  },
  datesMarginTopMin: {
    marginTop: '20px',
  },
  datesMarginTopMax: {
    marginTop: '40px',
  },
  noFold: {
    whiteSpace: 'nowrap',
    width: 110,
  },
  textLeft: {
    textAlign: 'left',
  },
  greyText: {
    color: '#777',
  },
  noPaddingTop: {
    padding: '0 10px 10px 10px',
  },
  dialogLg: {
    margin: '10px',
  },
  weeklyCalendarBarContainerPosition: {
    width: '100%',
  },
  weeklyCalendarBar: {
    background: 'linear-gradient(to bottom, #bdbdbd 0%, #eeeeee 100%)', // theme.palette.tertiary.main,
    borderBottom: `1px solid ${theme.palette.tertiary.light}`,
  },
  sectionHeader1: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
  },
  sectionHeader2: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
  },
  sectionHeader3: {
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
  },
  dateCol1: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
  },
  dateCol2: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
  },
  dateCol3: {
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
    '&::after': {
      content: '"  ❯"',
      color: '#999',
    },
  },
  segmentStyles1: {
    color: '#666',
  },
  segmentStyles2: {
    color: '#666',
    textAlign: 'center',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '4px',
  },
  clickableContainer: {
    cursor: 'pointer',
  },
  scheduledHrsLoader: {
    marginLeft: '10px',
  },
  btnStyles: {
    cursor: 'pointer',
  },
  headerStyles: {
    backgroundColor: '#eeeeee',
  },
})

const PAGE_TITLE = 'My Timecard'
const WEEKLY_TIME_CARD_PAGE_PATH = '/team-member/timecardWeekly'

const minDate = startOfWeek(subWeeks(getDateOfTodayWithNoTimestamp(), MAX_WEEKS_ALLOWED_BACKWARDS - 1))
const maxDate = getDateOfTodayWithNoTimestamp()

const weekDisplayFormat = (date) => {
  const startWeek = startOfWeek(date)
  const endWeek = endOfWeek(date)
  const dateFormat = 'MM/dd'

  return format(startWeek, dateFormat) + ' - ' + format(endWeek, dateFormat)
}

const ariaLabelDate = (date) => {
  const startWeek = startOfWeek(date)
  const endWeek = endOfWeek(date)

  let sDate = startWeek.getDate().toString()
  let sMonth = startWeek.getMonth()
  let sYear = startWeek.getFullYear()

  let eDate = endWeek.getDate().toString()
  let eMonth = endWeek.getMonth()
  let eYear = endWeek.getFullYear()

  return `${months[sMonth].substring(0, 3)} ${sDate}, ${sYear} to ${months[eMonth].substring(0, 3)} ${eDate}, ${eYear}`
}

const TimecardWeekly = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const auth = useAuth()
  const screenAccess = useSelector((state) => state.layout.screenAccess)
  const screenAccessLoading = useSelector((state) => state.layout.screenAccessLoading)
  const locationDetailsLoading = useSelector((state) => state.layout.locationLoading)
  const [currentWeek, setCurrentWeek] = useState(weekDisplayFormat(new Date()))
  const [currentWeekAriaLabel, setCurrentWeekAriaLabel] = useState(ariaLabelDate(new Date()))
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [menuValue, setMenuValue] = React.useState(CURRENT_PAY_PERIOD)
  const timecardDataWeekly = useSelector((state) => state.timecardWeekly.data)
  const timecardDataWeeklyLoading = useSelector((state) => state.timecardWeekly.loading)
  const timecardDataWeeklyError = useSelector((state) => state.timecardWeekly.error)
  const timecardWeeklySelectedDate = useSelector((state) => state.timecardWeekly.weeklySelectedDate)
  const timecardDailySelectedDate = useSelector((state) => state.timecardDaily.dailySelectedDate)
  const totalScheduledHrsWeekly = useSelector((state) => state.timecardWeekly.totalScheduledHrs)
  const totalscheduledHrsWeeklyLoading = useSelector((state) => state.timecardWeekly.totalScheduledHrsLoading)
  const totalScheduledHrsWeeklyError = useSelector((state) => state.timecardWeekly.totalScheduledHrsError)
  const [missedPunch, setMissedPunch] = useState(false)
  const backToComponent = useSelector((state) => state.header.backToComponent)
  const locationDetails = useSelector((state) => state.layout.locationDetails)
  const [totalScheduledHrsLoader, setTotalScheduledHrsLoader] = useState(true)

  const dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    const { userInfo } = auth.session
    const { iso_time_zone_code } = locationDetails
    let currentSelectedDate

    if (backToComponent && backToComponent === WEEKLY_TIME_CARD_PAGE_PATH) {
      dispatch(setBackToComponent(null))
      let currentWeekNum = getWeek(new Date())
      let prevWeekNum = currentWeekNum - 1
      currentSelectedDate = timecardDailySelectedDate
      let weekNumForGivenDate = getWeek(currentSelectedDate)

      if (weekNumForGivenDate === currentWeekNum) {
        setMenuValue(CURRENT_PAY_PERIOD)
      } else if (weekNumForGivenDate === prevWeekNum) {
        setMenuValue(PREV_PAY_PERIOD)
      } else {
        setMenuValue(CUSTOM)
      }

      setCurrentWeek(weekDisplayFormat(currentSelectedDate))
      setCurrentWeekAriaLabel(ariaLabelDate(currentSelectedDate))
      handleDateChange(currentSelectedDate)
    } else if (iso_time_zone_code) {
      currentSelectedDate = getNowDateInTimezone(iso_time_zone_code)
    }
    handleDateChange(currentSelectedDate)

    if (userInfo && locationDetails.location_id && Object.keys(userInfo).length > 0) {
      // if (Object.keys(timecardDataWeekly).length === 0) {

      getTimecardWeeklyData(currentSelectedDate)
      // }
      getScheduledHrsWeekly(currentSelectedDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, locationDetails])

  const getTimecardWeeklyData = (date) => {
    const { userInfo } = auth.session
    dispatch(setTimecardWeeklyLoading('Y'))
    dispatch(setTimecardWeeklySelectedDate(date))

    const dateFormat = 'yyyy-MM-dd'
    let startDate = format(startOfWeek(date), dateFormat)
    let endDate = format(endOfWeek(date), dateFormat)

    if (isSelectedDateInCurrentWeek(date)) {
      const { iso_time_zone_code } = locationDetails
      endDate = format(getNowDateInTimezone(iso_time_zone_code), dateFormat)
    }

    dispatch(
      getTimecardDataWeekly(padEmpIdWithZeros(userInfo?.empid, 10), startDate, endDate, locationDetails.location_id),
    )
  }

  const getScheduledHrsWeekly = (date) => {
    const { userInfo } = auth.session
    dispatch(setTotalScheduledHrsWeeklyLoading('Y'))

    dispatch(handleGetWeeklyScheduleData(padEmpIdWithZeros(userInfo?.empid, 10), date, locationDetails.location_id))
  }

  useEffect(() => {
    if (Object.keys(timecardDataWeekly).length > 0) {
      timecardDataWeekly.has_missed_punch ? setMissedPunch(true) : setMissedPunch(false)
    }
  }, [timecardDataWeekly])

  useEffect(() => {
    if (totalscheduledHrsWeeklyLoading === 'N' && totalScheduledHrsWeekly !== null) {
      setTotalScheduledHrsLoader(false)
    } else if (totalscheduledHrsWeeklyLoading === 'Y') {
      setTotalScheduledHrsLoader(true)
    } else if (totalscheduledHrsWeeklyLoading === 'N' && totalScheduledHrsWeeklyError !== null) {
      setTotalScheduledHrsLoader(false)
    }
  }, [totalscheduledHrsWeeklyLoading, totalScheduledHrsWeekly, totalScheduledHrsWeeklyError])

  const handleClickListItem = () => {
    setMenuOpen(true)
  }

  const handleClose = (newValue) => {
    setMenuOpen(false)
    let dt = new Date()
    if (newValue === menuValue) {
      return
    }
    if (newValue) {
      if (newValue !== menuValue) {
        setMenuValue(newValue)
      }
      if (newValue === CURRENT_PAY_PERIOD || newValue === CUSTOM) {
        setCurrentWeek(weekDisplayFormat(dt))
        setCurrentWeekAriaLabel(ariaLabelDate(dt))
      } else if (newValue === PREV_PAY_PERIOD) {
        setCurrentWeek(weekDisplayFormat(dt.setDate(dt.getDate() - 7)))
        setCurrentWeekAriaLabel(ariaLabelDate(dt))
      }
      handleDateChange(dt)
    }
  }

  const DropDownMenu = () => {
    return (
      <React.Fragment>
        <div style={styles.weeklyCalendarBarContainerPosition}>
          <div style={styles.weeklyCalendarBar}>
            <div style={{ ...styles.menuContainer, ...styles.greyBG }}>
              <div style={{ ...styles.row, ...styles.payPeriodButton }}>
                <div
                  style={styles.colStart}
                  role="button"
                  tabIndex={0}
                  aria-labelledby="selected-pay-period"
                  onClick={handleClickListItem}
                  data-cy="payPeriodDropDown"
                >
                  <div style={styles.btnStyles} id="selected-pay-period">
                    {menuValue}
                  </div>
                  <ArrowDropDownIcon style={styles.iconCorrection} />
                </div>
                <div style={styles.colEnd} tabIndex="0">
                  <div role="heading" aria-level="2" aria-label={currentWeekAriaLabel}>
                    {currentWeek}
                  </div>
                </div>
              </div>
              <Divider role="presentation" />
            </div>
            {menuValue === CUSTOM && (
              <TimecardWeeklyCalendar
                id="timecard-weekly-calendar"
                selectedDate={new Date(timecardWeeklySelectedDate)}
                onChange={(date) => handleDateChange(date)}
                minDate={minDate}
                maxDate={maxDate}
                tabIndex={0}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  const handleDateChange = (date) => {
    let inCurrentWeek = isSelectedDateInWeekOfPrevSelectedDate(date)

    if (!inCurrentWeek) {
      handleDifferentWeekDateChange(date)
    } else {
      dispatch(setTimecardWeeklySelectedDate(date))
    }
  }

  const handleDifferentWeekDateChange = (date) => {
    dispatch(setTimecardWeeklySelectedDate(date))
    getTimecardWeeklyData(date)
    getScheduledHrsWeekly(date)
  }

  const isSelectedDateInWeekOfPrevSelectedDate = (date) => {
    return !(
      isBefore(date, startOfWeek(timecardWeeklySelectedDate)) || isAfter(date, endOfWeek(timecardWeeklySelectedDate))
    )
  }

  const isSelectedDateInCurrentWeek = (selectedDate) => {
    let today = new Date()
    return !(isBefore(selectedDate, startOfWeek(today)) || isAfter(selectedDate, endOfWeek(today)))
  }

  const goToDailyView = (date) => {
    dispatch(setBackToComponent(WEEKLY_TIME_CARD_PAGE_PATH))
    dispatch(setTimecardDailySelectedDate(date))
    navigate('/team-member/timecardDaily')
  }

  const viewCalendar = () => {
    return (
      <>
        <DropDownMenu />
        <PayPeriodMenuDialog keepMounted open={menuOpen} onClose={handleClose} value={menuValue} styles={styles} />
      </>
    )
  }

  const renderComponent = () => {
    if (!screenAccessLoading && !locationDetailsLoading) {
      if (!screenAccess.includes(VIEW_TIME_CARD)) {
        return <NotAuthorized />
      } else {
        if (timecardDataWeeklyError) {
          return displayErrors(timecardDataWeeklyError)
        } else if (timecardDataWeekly) {
          if (timecardDataWeekly.total_timecards === 0) {
            return displayInfoMessage(NO_TIMECARD_MESSAGE)
          } else {
            return viewableData()
          }
        }
      }
    }
    return displayEmptyContent()
  }

  const displayErrors = (errorObj) => {
    if (errorObj.message && errorObj.code && ERROR_CODES_SHOW_MESSAGE_TIMECARD.includes(errorObj.code)) {
      return (
        <div style={styles.mainContainerPosition}>
          <p style={styles.errorMessage}>{errorObj.message}</p>
        </div>
      )
    } else {
      return (
        <div style={styles.mainContainerPosition}>
          <p style={styles.errorMessage}>
            {UNABLE_TO_LOAD_TIMECARDS}
            <span style={styles.errorCodeMessage}>
              {formatErrorCode(errorObj, ERROR_CODE_CANNOT_CONNECT_TO_SERVER_TIMECARD)}
            </span>
          </p>
        </div>
      )
    }
  }

  const displayInfoMessage = (message) => {
    return (
      <div style={styles.mainContainerPosition}>
        <p style={styles.infoMessage}>{message}</p>
      </div>
    )
  }

  const displayEmptyContent = () => {
    return <div style={styles.mainContainerPosition} />
  }

  const viewableData = () => {
    return (
      <React.Fragment>
        <div style={styles.mainContainerPosition}>
          <Paper elevation={1} square sx={styles.mainScrollContainer}>
            <HoursSummaryWeekly
              scheduledHrs={totalScheduledHrsWeekly}
              totalScheduledHrsLoader={totalScheduledHrsLoader}
              punchedHrs={timecardDataWeekly.punched_hours ? timecardDataWeekly.punched_hours : '0'}
              paycodeHrs={timecardDataWeekly.paycode_hours}
              totalHrs={timecardDataWeekly.total_hours}
              missedPunch={missedPunch}
            />
            <Divider role="presentation" sx={missedPunch ? styles.datesMarginTopMin : styles.datesMarginTopMax} />
            {Object.keys(timecardDataWeekly).length > 0 && (
              <DatesOnWeekly
                dates={timecardDataWeekly}
                currentWeekAriaLabel={currentWeekAriaLabel}
                styles={styles}
                goToDailyView={(date) => goToDailyView(date)}
              />
            )}
          </Paper>
        </div>
      </React.Fragment>
    )
  }

  const loader = () => {
    return (
      <div style={styles.mainContainerPosition}>
        <div style={styles.loadingIconContainer}>
          <div>
            <CircularProgress size={48} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <HeaderTitle title={PAGE_TITLE} />
      {screenAccess.includes(VIEW_TIME_CARD) && viewCalendar()}
      {timecardDataWeeklyLoading === 'N' ? renderComponent() : loader()}
    </React.Fragment>
  )
}

export default TimecardWeekly
