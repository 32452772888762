import { Grid, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { Gauge } from '@mui/x-charts/Gauge'
import FlipCard from './FlipCard'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(1),
  alignItems: 'center',
  textAlign: 'center',
}))

export default function FeedbackBody({ summaryContent, personas }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={3} marginLeft={3}>
            <Typography variant="h6" component="div">
              Rating
            </Typography>
            <Item>
              {summaryContent ? (
                <Gauge
                  style={{ backgroundColor: 'white' }}
                  width={200}
                  height={150}
                  value={summaryContent?.rating || 1}
                  startAngle={-90}
                  endAngle={90}
                  valueMin={1}
                  valueMax={5}
                  text={({ value, valueMax }) => `${value} / ${valueMax}`}
                />
              ) : (
                <></>
              )}
            </Item>
          </Grid>
          <Grid item xs={8} height={'100%'} marginLeft={6}>
            <Typography variant="h6" component="div">
              Summary
            </Typography>
            <Item>
              {summaryContent ? (
                <Typography
                  sx={{
                    overflow: 'auto',
                    height: '150px',
                    whiteSpace: 'pre-wrap',
                    textAlign: 'left',
                    fontSize: '14px',
                  }}
                >
                  {summaryContent?.response || 'No Response'}
                </Typography>
              ) : (
                <></>
              )}
            </Item>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={1}>
          <Grid item xs={3}>
            <Typography variant="h6" component="div" sx={{ marginTop: 1 }}>
              Sentimental Analysis
            </Typography>
            <Item>
              <FlipCard
                params={summaryContent}
                type={'sentiment_analysis'}
                frontContent={'Flip to see Sentiment Analysis'}
                resetFlip={false}
                personas={personas}
              />
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" component="div" sx={{ marginTop: 1 }}>
              Strengths
            </Typography>
            <Item>
              <FlipCard
                params={summaryContent}
                type={'strengths'}
                frontContent={'Flip to see Strengths'}
                resetFlip={false}
                personas={personas}
              />
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" component="div" sx={{ marginTop: 1 }}>
              Opportunities
            </Typography>
            <Item>
              <FlipCard
                params={summaryContent}
                type={'opportunities'}
                frontContent={'Flip to see Opportunities'}
                resetFlip={false}
                personas={personas}
              />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
