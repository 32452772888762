import HomeIcon from '@mui/icons-material/Home'
import Fab from '@mui/material/Fab'
import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { useRouteError } from 'react-router-dom'
import { useUserFeatures } from '../../../api/userAccess/useUserFeatures'

const styles = {
  body: {
    background: '#ddd',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    left: '0',
    top: '0',
    width: '100%',
  },
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: '600px',
    padding: '20px 30px',
    textAlign: 'center',
  },
  heading: {
    fontWeight: '300',
    fontSize: '34px',
    margin: '0',
    opacity: '.75',
  },
  subheading: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '1.5',
    margin: '1.8em 10px 2em',
    opacity: '.5',
  },
}

const CSC_ERROR_MESSAGE =
  'Oops! Something went wrong, please try again later. If the issue persists please contact the CSC.'

// This Error component is used for fallback error boundaries and location details or user features errors
function Error(props) {
  const { error, userFriendlyMessage } = props

  const { session } = useAuth()
  const { data: userFeatures } = useUserFeatures()
  let routeError = useRouteError()

  const loggingInfo = {
    message: userFriendlyMessage,
    url: window.location.href,
    error: {
      message: error?.message,
      code: error?.code,
      worker_id: session?.userInfo.empid,
      location_id: session?.userInfo.locationid,
      screenAccess: userFeatures?.screenAccess,
      routeError: routeError
        ? JSON.stringify(routeError, Object.getOwnPropertyNames(routeError))
        : 'routeError is undefined - this means error is likely userProfiles or location api failure / network issue',
    },
  }
  const optionalLoggingOptions = {
    level: LogLevel.Error,
  }
  logEvent(loggingInfo, optionalLoggingOptions)

  return (
    <div style={styles.wrapper}>
      <div role="alert" style={styles.content}>
        <div style={styles.heading}>{CSC_ERROR_MESSAGE}</div>
        <div style={styles.subheading}>{`${userFriendlyMessage ? userFriendlyMessage : ''} ${
          error?.code ? `Error code: ${error.code}` : ''
        }`}</div>
        <Fab onClick={() => window.location.replace('/')} title={'Reload'} aria-label={'Reload'} color="primary">
          <HomeIcon />
        </Fab>
      </div>
    </div>
  )
}

export default Error
