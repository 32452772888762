import { Card, Container, Grid, Typography } from '@mui/material'
import ReactBarcode from 'react-barcode'
import HeaderTitle from '../components/Header/HeaderTitle'

export default function Barcode() {
  return (
    <Container sx={{ height: '50%', display: 'flex', alignItems: 'center' }}>
      <Grid container justifyContent={'center'}>
        <Card sx={{ width: '100%', padding: '10px' }}>
          <HeaderTitle title={'My Barcode'} />
          <Grid
            container
            item
            paddingY={'50px'}
            justifyContent={'center'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <TeamMemberBarcode />
          </Grid>
        </Card>
      </Grid>
    </Container>
  )
}

export function TeamMemberBarcode() {
  let encodedEncodedId = localStorage.getItem('my_time_barcode')
  let paddedEmpId = encodedEncodedId ? window.atob(encodedEncodedId) : ''

  return (
    <>
      {paddedEmpId ? (
        <>
          <Typography component="h2">You can use this barcode at the clock!</Typography>
          <ReactBarcode displayValue={false} format="UPC" value={paddedEmpId} />
        </>
      ) : null}
    </>
  )
}
