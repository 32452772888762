import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'

const EXIT_DIALOG_TITLE = 'Are you sure you want to exit?'
const EXIT_DIALOG_TEXT = 'You have unsaved changes to this page. If you exit, you will lose these changes.'

const ExitPromptDialog = ({ madeUpdates }) => {
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(madeUpdates)

  return (
    /*  <Dialog open={showPrompt} keepMounted TransitionComponent={handleTransition}>  To do implement transition*/
    <Dialog open={showPrompt} keepMounted>
      <DialogTitle id="save-dialog-slide-title">{EXIT_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText id="save-dialog-slide-description">{EXIT_DIALOG_TEXT}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={cancelNavigation}>
          NO
        </Button>
        <Button color="primary" data-cy="OptionalAvailabilityPromptContainer_yes" onClick={confirmNavigation}>
          YES
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExitPromptDialog
