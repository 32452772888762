import { CALL_IN_CALL_OFF_TEAM_MEMBER, SCHEDULE_TEAM_MEMBER, SCREEN_NAME_SWAP_SHIFT } from '../utils/ScreenName'

export const pnTypes = [
  {
    title: 'time-off-call-in',
    targetAudience: [CALL_IN_CALL_OFF_TEAM_MEMBER],
  },
  {
    title: 'time-off-call-off',
    targetAudience: [CALL_IN_CALL_OFF_TEAM_MEMBER],
  },
  {
    title: 'shift-swap',
    targetAudience: [SCREEN_NAME_SWAP_SHIFT],
  },
  {
    title: 'schedule-edits',
    targetAudience: [SCHEDULE_TEAM_MEMBER],
  },
]
