export const SET_AUTOMATION_MANDATORY_DATA_TABLE_STATE_TO_DEFAULT =
  'SET_AUTOMATION_MANDATORY_DATA_TABLE_STATE_TO_DEFAULT'
export const SET_AUTOMATION_MANDATORY_DATA_TABLE_FILTERS_TO_DEFAULT =
  'SET_AUTOMATION_MANDATORY_DATA_TABLE_FILTERS_TO_DEFAULT'
export const SET_AUTOMATION_MANDATORY_DATA_TABLE_COLUMNS_TO_DEFAULT =
  'SET_AUTOMATION_MANDATORY_DATA_TABLE_COLUMNS_TO_DEFAULT'
export const GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_SUCCESS =
  'GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_SUCCESS'
export const GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_ERROR =
  'GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_ERROR'
export const SET_AUTOMATION_MANDATORY_DATA_SELECTED_FILTERS = 'SET_AUTOMATION_MANDATORY_DATA_SELECTED_FILTERS'
export const GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_ERROR =
  'GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_ERROR'
export const GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS =
  'GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS'
export const MANDATORY_LEADER_AUTOMATION_DECISION_SUCCESS = 'MANDATORY_LEADER_AUTOMATION_DECISION_SUCCESS'
export const MANDATORY_LEADER_AUTOMATION_DECISION_ERROR = 'MANDATORY_LEADER_AUTOMATION_DECISION_ERROR'
export const SET_AUTOMATION_MANDATORY_DATA_LOADING = 'SET_AUTOMATION_MANDATORY_DATA_LOADING'
export const SET_AUTOMATION_MANDATORY_DATA_SAVING = 'SET_AUTOMATION_MANDATORY_DATA_SAVING'
export const SET_AUTOMATION_MANDATORY_DATA_SEARCH = 'SET_AUTOMATION_MANDATORY_DATA_SEARCH'
export const SET_AUTOMATION_MANDATORY_DATA_SORT = 'SET_AUTOMATION_MANDATORY_DATA_SORT'
export const SET_AUTOMATION_MANDATORY_DATA_PAGE = 'SET_AUTOMATION_MANDATORY_DATA_PAGE'
export const SET_AUTOMATION_MANDATORY_DATA_PER_PAGE = 'SET_AUTOMATION_MANDATORY_DATA_PER_PAGE'
export const MANDATORY_LEADER_AUTOMATION_SET_IS_INPUT_TYPE_TIME = 'MANDATORY_LEADER_AUTOMATION_SET_IS_INPUT_TYPE_TIME'
export const MANDATORY_LEADER_AUTOMATION_SET_SHIFT_PREFERENCE = 'MANDATORY_LEADER_AUTOMATION_SET_SHIFT_PREFERENCE'
export const MANDATORY_LEADER_AUTOMATION_SET_START_TIME = 'MANDATORY_LEADER_AUTOMATION_SET_START_TIME'
export const MANDATORY_LEADER_AUTOMATION_SET_END_TIME = 'MANDATORY_LEADER_AUTOMATION_SET_END_TIME'
export const SET_AUTOMATION_MANDATORY_DATA_COLUMN_VIEW = 'SET_AUTOMATION_MANDATORY_DATA_COLUMN_VIEW'
export const AUTOMATION_MANDATORY_AVAILABILITY_SET_POST_RESPONSE = 'AUTOMATION_MANDATORY_AVAILABILITY_SET_POST_RESPONSE'
export const AUTOMATION_MANDATORY_SHOULD_LOAD_DATA = 'AUTOMATION_MANDATORY_SHOULD_LOAD_DATA'
export const AUTOMATION_MANDATORY_SET_SAVE_DIALOG_OPEN = 'AUTOMATION_MANDATORY_SET_SAVE_DIALOG_OPEN'
export const SET_AUTOMATION_MANDATORY_POST_RESPONSE_INELIGIBILITIES =
  'SET_AUTOMATION_MANDATORY_POST_RESPONSE_INELIGIBILITIES'
export const DOWNLOAD_MANDATORY_CSV_SUCCESS = 'DOWNLOAD_MANDATORY_CSV_SUCCESS'
export const DOWNLOAD_MANDATORY_CSV_ERROR = 'DOWNLOAD_MANDATORY_CSV_ERROR'
