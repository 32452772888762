import { List } from '@mui/material'
import CategoryDetailDataHeader from './CategoryDetailDataHeader'
import CategoryDetailDataList from './CategoryDetailDataList'

const CategoryDetailDataSection = ({ statusIcon, headerPrimary, headerSecondary, labelValueList }) => {
  return (
    // Using List components to match styling in MyTeam row data
    <List component="h3">
      <CategoryDetailDataHeader
        requestStatusIcon={statusIcon}
        primary={headerPrimary}
        secondaryList={headerSecondary}
      />
      <CategoryDetailDataList dataList={labelValueList} />
    </List>
  )
}

export default CategoryDetailDataSection
