import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import InnerLoader from '../../Loader/InnerLoader'
import { createTheme } from '@mui/material/styles'

/**
 *
 * @param {Component} Icon: icon for the card
 * @param {string} title: title of card
 * @param {string} subTitle: text to the side of title.
 * @param {string} isRefetching: isRefetching indicator is only true when we already have data, and query is refetching in background
 * @param {Component} children: children renders all child components under GenericCard
 * @param {Function} cardActions: a function that returns mui CardActions
 * @returns Card with basic header and any actions passed through.
 */
const GenericCard = ({ icon, title, subTitle, isRefetching, children, cardActions }) => {
  const theme = createTheme()
  return (
    <Card data-cy={`${title?.replace(/ /g, '')}_DashboardCard`}>
      <CardContent data-cy={`${title?.replace(/ /g, '')}_CardContent`}>
        <div
          data-cy={`${title?.replace(/ /g, '')}_CardHeader`}
          style={{
            display: 'flex',
            flexGrow: '1',
            gap: 5,
            alignItems: 'center',
            marginBottom: theme.spacing(1),
          }}
        >
          {icon}
          <Typography
            variant="subtitle1"
            component="h3"
            sx={{
              marginLeft: '.4em',
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography variant="subtitle2" component="h4">
              {subTitle}
            </Typography>
          )}
          {isRefetching && (
            <div style={{ marginLeft: '.4em' }}>
              <InnerLoader size={20} />
            </div>
          )}
        </div>
        {children}
      </CardContent>
      {cardActions && cardActions()}
    </Card>
  )
}

export default GenericCard
