import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'
import {
  CLEAR_WITHDRAW_POST,
  GET_AVAILABLE_SHIFT_STATUS_ERROR,
  GET_AVAILABLE_SHIFT_STATUS_SUCCESS,
  GET_SWAP_REQUESTS_ERROR,
  GET_SWAP_REQUESTS_SUCCESS,
  GET_WEEKLY_SCHEDULE_ERROR,
  GET_WEEKLY_SCHEDULE_SUCCESS,
  GIVE_UP_SHIFT_POST_ERROR,
  GIVE_UP_SHIFT_POST_SUCCESS,
  SAVE_SWAP_REQUESTS_DATETIME_WISE_MAP,
  SET_AVAILABLE_SHIFT_STATUS_LOADING,
  SET_SWAP_REQUESTS_LOADING,
  SET_WEEKLY_SCHEDULE_DATA,
  SET_WEEKLY_SCHEDULE_LOADING,
  SET_WEEKLY_SCHEDULE_SELECTED_DATE,
  WITHDRAW_SWAP_SHIFT_REQUEST_ERROR,
  WITHDRAW_SWAP_SHIFT_REQUEST_SUCCESS,
} from './actionType'

export const initialState = {
  data: null,
  availableShiftStatusData: null,
  loading: 'N',
  availableShiftStatusLoading: 'N',
  selectedDate: getDateOfTodayWithNoTimestamp(),
  getScheduleError: null,
  getAvailableShiftStatusError: null,
  postShiftPostResponse: null,
  postShiftPostErrResponse: null,
  buttonFlag: true,
  swapRequestData: null,
  swapRequestDataLoading: 'N',
  swapRequestDataError: null,
  swapRequestsDateTimeWiseMap: null,
  withdrawSwapRequestResponse: null,
  withdrawSwapRequestErrResponse: null,
}

export default function schedule(state = initialState, action = {}) {
  switch (action.type) {
    case SET_WEEKLY_SCHEDULE_DATA: {
      const { record } = action
      return {
        ...state,
        data: record,
      }
    }
    case GET_WEEKLY_SCHEDULE_ERROR: {
      const { record } = action
      return {
        ...state,
        getScheduleError: record.response ? record.response.data : record,
        loading: 'N',
      }
    }
    case GET_WEEKLY_SCHEDULE_SUCCESS: {
      const { record } = action
      return {
        ...state,
        data: record.data,
        loading: 'N',
        getScheduleError: null,
      }
    }
    case SET_WEEKLY_SCHEDULE_LOADING: {
      const { payload } = action
      return {
        ...state,
        loading: payload,
      }
    }
    case GET_AVAILABLE_SHIFT_STATUS_ERROR: {
      const { record } = action
      return {
        ...state,
        getAvailableShiftStatusError: record.response ? record.response.data : record,
        availableShiftStatusLoading: 'N',
      }
    }
    case GET_AVAILABLE_SHIFT_STATUS_SUCCESS: {
      const { record } = action
      return {
        ...state,
        availableShiftStatusData: record.data,
        availableShiftStatusLoading: 'N',
        getAvailableShiftStatusError: null,
      }
    }
    case GET_SWAP_REQUESTS_SUCCESS: {
      const { record } = action
      return {
        ...state,
        swapRequestData: record.data,
        swapRequestDataLoading: 'N',
        swapRequestDataError: null,
      }
    }
    case GET_SWAP_REQUESTS_ERROR: {
      const { record } = action
      return {
        ...state,
        swapRequestData: null,
        swapRequestDataError: record.response ? record.response.data : record,
        swapRequestDataLoading: 'N',
      }
    }
    case SET_SWAP_REQUESTS_LOADING: {
      const { payload } = action
      return {
        ...state,
        nextShiftLoading: payload,
      }
    }
    case SAVE_SWAP_REQUESTS_DATETIME_WISE_MAP: {
      return {
        ...state,
        swapRequestsDateTimeWiseMap: action.swapRequestsDateTimeWiseMap,
      }
    }
    case SET_AVAILABLE_SHIFT_STATUS_LOADING: {
      const { payload } = action
      return {
        ...state,
        availableShiftStatusLoading: payload,
      }
    }
    case SET_WEEKLY_SCHEDULE_SELECTED_DATE: {
      const { date } = action
      return {
        ...state,
        selectedDate: date,
      }
    }
    case GIVE_UP_SHIFT_POST_SUCCESS: {
      const { record } = action

      return {
        ...state,
        postShiftPostResponse: record.data,
        loading: 'N',
        postShiftPostErrResponse: null,
      }
    }
    case GIVE_UP_SHIFT_POST_ERROR: {
      const { record } = action
      return {
        ...state,
        postShiftPostErrResponse: record.response ? record.response.data : record,
        postShiftPostResponse: null,
        loading: 'N',
      }
    }
    case WITHDRAW_SWAP_SHIFT_REQUEST_SUCCESS: {
      const { record } = action

      return {
        ...state,
        withdrawSwapRequestResponse: record.data,
        loading: 'N',
        withdrawSwapRequestErrResponse: null,
      }
    }

    case WITHDRAW_SWAP_SHIFT_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        withdrawSwapRequestErrResponse: record.response ? record.response.data : record,
        withdrawSwapRequestResponse: null,
        loading: 'N',
      }
    }
    case CLEAR_WITHDRAW_POST: {
      return {
        ...state,
        withdrawSwapRequestResponse: null,
        withdrawSwapRequestErrResponse: null,
      }
    }
    default:
      return state
  }
}
