import { Divider, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setUsePTODetails } from '../../../../store/requestTimeOff/actionCreator'
import { createCypressDate } from '../../../../utils/DateUtil'

const renderMenuItemList = (itemArray) => {
  return itemArray?.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    )
  })
}

export default function PTOWithUnpaidRow({ dateKey, index }) {
  const dispatch = useDispatch()
  const detail = useSelector((store) => store.requestTimeOff?.request[dateKey]?.requestDetails[index])
  const requestConfig = useSelector((store) => store.requestTimeOff.requestConfig)

  const onChange = (date, index, field, value) => {
    let newValue = value
    let newField = field === 'hours' ? 'minutes' : field
    if (newField === 'minutes') {
      const currentHours = Math.floor(detail.ptoDetails.minutes / 60)
      const currentMinutes = detail.ptoDetails.minutes % 60
      const newHours = field === 'hours' ? value : currentHours
      const newMinutes = field === 'minutes' ? value : currentMinutes
      newValue = newHours * 60 + newMinutes
    }

    dispatch(setUsePTODetails(date, index, newField, newValue))
  }

  const createCypressTag = (componentName, index) => {
    return `requestTimeOffRowInput_${componentName}_${createCypressDate(new Date(dateKey))}_${index}`
  }

  const hours = Math.floor(detail?.ptoDetails?.minutes / 60)
  const minutes = detail?.ptoDetails?.minutes % 60
  const timeDurationError = detail?.ptoDetails?.error.minutes

  return (
    <Grid
      container
      key={index}
      marginBottom={2}
      marginLeft={1}
      data-cy={`requestTimeOffUsePTODetail_${createCypressDate(new Date(dateKey))}`}
    >
      <Divider orientation="vertical" aria-hidden="true" />
      <Grid container item xs justifyContent={'center'} marginX={2}>
        <Grid container item xs direction={'column'}>
          <Grid item container xs wrap="nowrap">
            <TextField
              sx={{ minWidth: '45px' }}
              id="Hours"
              label="Hours"
              name="hours"
              error={!!timeDurationError}
              select
              value={hours}
              onChange={(event) => onChange(dateKey, index, 'hours', Number(event.target.value))}
              data-cy={createCypressTag('usePTO_hours', index)}
            >
              {renderMenuItemList(requestConfig?.content?.paidHourDropdownValues)}
            </TextField>

            <TextField
              sx={{ minWidth: '45px' }}
              id="Minutes"
              label="Minutes"
              name="minutes"
              error={!!timeDurationError}
              select
              value={minutes}
              onChange={(event) => onChange(dateKey, index, 'minutes', Number(event.target.value))}
              SelectProps={{
                MenuProps: {
                  sx: { maxHeight: '510px' },
                },
              }}
              data-cy={createCypressTag('usePTO_minutes', index)}
            >
              {renderMenuItemList(requestConfig?.content?.paidMinuteDropdownValues)}
            </TextField>
          </Grid>
          <Grid item height={20}>
            {!!timeDurationError && (
              <Typography variant="caption" color={'primary'} data-cy={createCypressTag('usePTO_timeError', index)}>
                {timeDurationError}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs>
          <TextField
            id="type"
            select
            label="Type"
            value={detail?.ptoDetails?.type}
            onChange={(event) => onChange(dateKey, index, 'type', event.target.value)}
            sx={{ minWidth: 80, maxWidth: 250, display: 'flex', flexGrow: 1 }}
            data-cy={createCypressTag('usePTO_type', index)}
          >
            {renderMenuItemList(requestConfig.content?.types?.selectableTypes)}
          </TextField>
        </Grid>
      </Grid>
    </Grid>
  )
}
