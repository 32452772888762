import { useState } from 'react'

export type StarRatingProps = {
  rating: number
  ratingError: boolean
  handleRating: (rating: number) => void
  handleRatingError: (error: boolean) => void
}

export function useStarRating(): StarRatingProps {
  const [rating, setRating] = useState(0)
  const [ratingError, setRatingError] = useState(false)

  const handleRating = (rate: number) => {
    setRatingError(false)
    setRating(rate)
  }

  const handleRatingError = (error: boolean) => {
    setRatingError(error)
  }

  return { rating, ratingError, handleRating, handleRatingError }
}
