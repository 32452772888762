import { Grid, Typography, useTheme } from '@mui/material'
import { convertAmPmTimeFormat, formatDateString, getDateTimeWithNoTimezone } from '../../utils/DateUtil'
import { LEADER_TIMESTAMP_DETAIL_FORMAT } from '../../constants/leaderConstants'
import { isValid } from 'date-fns'
import { months } from '../../constants/AvailabilityConstants'
import PunchCorrectionRequestDetailsTable from '../Requests/PunchCorrection/PunchCorrectionRequestDetailsTable'

const getStyles = (theme) => ({
  auditItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  keyStyles: {
    fontWeight: 'bold',
    minWidth: 100,
    '&::after': {
      content: '" "',
      whiteSpace: 'pre',
    },
  },
  valueStyles: {
    wordBreak: 'break-all',
  },
  auditContainer: {
    marginBottom: '20px',
    padding: '10px',
    border: '1px solid #aeaeae',
    position: 'relative',
    borderRadius: '7px',
  },
  auditItemsContainer: {
    marginTop: '20px',
    width: '100%',
  },
})

const AvailabilityAuditItem = ({ auditItem, requestId, id, index }) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const voiceOverDate = (val) => {
    if (isValid(val)) {
      let date = val.getDate().toString()
      let month = val.getMonth()
      let year = val.getFullYear()
      let time = convertAmPmTimeFormat(`${val.getHours()}:${val.getMinutes()}`)
      return `${months[month].substring(0, 3)} ${date}, ${year} ${time}`
    } else {
      return ''
    }
  }

  return (
    <Grid
      data-cy={`audit_detail_${id}`}
      aria-label={`audit detail for I D: ${index}`}
      container
      sx={styles.auditContainer}
    >
      <Grid item xs={12} sm={12}>
        <div
          aria-labelledby={`audit-status-key-${id} audit-status-val-${id}`}
          style={styles.auditItem}
          data-cy={`audit-detail-dialog-status-${id}`}
          tabIndex={0}
        >
          <Typography id={`audit-status-key-${id}`} variant="body2" sx={styles.keyStyles}>
            {'Status:'}
          </Typography>
          <Typography id={`audit-status-val-${id}`} variant="body2">
            {auditItem.status}
          </Typography>
        </div>
        <div
          aria-labelledby={`audit-loc-key-${id} audit-loc-val-${id}`}
          style={styles.auditItem}
          data-cy={`audit-detail-dialog-location-${id}`}
          tabIndex={0}
        >
          <Typography id={`audit-loc-key-${id}`} variant="body2" sx={styles.keyStyles}>
            {'Location:'}
          </Typography>
          <Typography id={`audit-loc-val-${id}`} variant="body2">
            {auditItem.location_id}
          </Typography>
        </div>
        <div
          aria-labelledby={`audit-updatedby-key-${id} audit-updatedby-val-${id}`}
          style={styles.auditItem}
          data-cy={`audit-detail-dialog-updated-by-${id}`}
          tabIndex={0}
        >
          <Typography id={`audit-updatedby-key-${id}`} variant="body2" sx={styles.keyStyles}>
            {'Updated By:'}
          </Typography>
          <Typography id={`audit-updatedby-val-${id}`} variant="body2" sx={styles.valueStyles}>
            {auditItem.worker_name}
          </Typography>
        </div>
        <div
          aria-labelledby={`audit-updateddate-key-${id} audit-updateddate-val-${id}`}
          style={styles.auditItem}
          data-cy={`audit-detail-dialog-updated-date-${id}`}
          tabIndex={0}
        >
          <Typography id={`audit-updateddate-key-${id}`} variant="body2" sx={styles.keyStyles}>
            {'Updated Date:'}
          </Typography>
          <Typography
            id={`audit-updateddate-val-${id}`}
            variant="body2"
            sx={styles.valueStyles}
            aria-label={voiceOverDate(new Date(getDateTimeWithNoTimezone(auditItem.updated_timestamp)))}
          >
            {formatDateString(
              getDateTimeWithNoTimezone(auditItem.updated_timestamp),
              'YYYY-MM-DDTHH:mm:ss',
              LEADER_TIMESTAMP_DETAIL_FORMAT,
            )}
          </Typography>
        </div>
      </Grid>
      {auditItem?.request_details && auditItem.request_details.length > 0 && (
        <Grid sx={styles.auditItemsContainer}>
          <PunchCorrectionRequestDetailsTable
            requestId={requestId}
            requestDetails={auditItem.request_details}
            isTimeOffTeamMember={false}
            isAudit={true}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default AvailabilityAuditItem
