export const GET_WEEKLY_SCHEDULE_ERROR = 'GET_WEEKLY_SCHEDULE_ERROR'
export const GET_WEEKLY_SCHEDULE_SUCCESS = 'GET_WEEKLY_SCHEDULE_SUCCESS'
export const GET_AVAILABLE_SHIFT_STATUS_ERROR = 'GET_AVAILABLE_SHIFT_STATUS_ERROR'
export const GET_AVAILABLE_SHIFT_STATUS_SUCCESS = 'GET_AVAILABLE_SHIFT_STATUS_SUCCESS'
export const SET_WEEKLY_SCHEDULE_LOADING = 'SET_WEEKLY_SCHEDULE_LOADING'
export const SET_WEEKLY_SCHEDULE_SELECTED_DATE = 'SET_WEEKLY_SCHEDULE_SELECTED_DATE'
export const GIVE_UP_SHIFT_POST_SUCCESS = 'GIVE_UP_SHIFT_POST_SUCCESS'
export const GIVE_UP_SHIFT_POST_ERROR = 'GIVE_UP_SHIFT_POST_ERROR'
export const SET_AVAILABLE_SHIFT_STATUS_LOADING = 'SET_AVAILABLE_SHIFT_STATUS_LOADING'
export const SET_WEEKLY_SCHEDULE_DATA = 'SET_WEEKLY_SCHEDULE_DATA'
export const GET_SWAP_REQUESTS_SUCCESS = 'GET_SWAP_REQUESTS_SUCCESS'
export const GET_SWAP_REQUESTS_ERROR = 'GET_SWAP_REQUESTS_ERROR'
export const SET_SWAP_REQUESTS_LOADING = 'SET_SWAP_REQUESTS_LOADING'
export const SAVE_SWAP_REQUESTS_DATETIME_WISE_MAP = 'SAVE_SWAP_REQUESTS_DATETIME_WISE_MAP'
export const WITHDRAW_SWAP_SHIFT_REQUEST_SUCCESS = 'WITHDRAW_SWAP_SHIFT_REQUEST_SUCCESS'
export const WITHDRAW_SWAP_SHIFT_REQUEST_ERROR = 'WITHDRAW_SWAP_SHIFT_REQUEST_ERROR'
export const CLEAR_WITHDRAW_POST = 'CLEAR_WITHDRAW_POST'
