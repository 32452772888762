/* eslint-disable react-hooks/exhaustive-deps */
import { Navigate } from 'react-router-dom'
import { useUser } from '../../auth/hooks/useUser'

const LeaderRedirect = () => {
  const { user } = useUser()

  const isReadOnlyLeader =
    user.is('punch_correction', 'leader', 'read', true) ||
    user.is('availability', 'leader', 'read', true) ||
    user.is('time_off', 'leader', 'read', true)

  if (isReadOnlyLeader) {
    return <Navigate to={'/leader/myteam'} />
  }
  return <Navigate to={'/leader/dashboard'} />
}

export default LeaderRedirect
