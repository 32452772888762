import { useUserFeatures } from '../../../api/userAccess/useUserFeatures'
import { CALL_IN_LEADER, CALL_OFF_LEADER } from '../../../utils/ScreenName'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import GenericCard from '../../common/cards/GenericCard'
import CallOffAtAGlance from './Categories/CallOff/CallOffAtAGlance'
import CallInAtAGlance from './Categories/CallIn/CallInAtAGlance'

const AtAGlanceCard = () => {
  const { data: userFeatures } = useUserFeatures()
  const screenAccess = userFeatures?.screenAccess
  const atAGlanceFeatures = [CALL_OFF_LEADER, CALL_IN_LEADER]

  return (
    <>
      {atAGlanceFeatures.some((feature) => screenAccess?.includes(feature)) && (
        <GenericCard title="At A Glance" icon={<AssignmentIndOutlinedIcon />}>
          <div style={{ paddingTop: '8px' }}>
            {screenAccess?.includes(CALL_OFF_LEADER) && <CallOffAtAGlance />}
            {screenAccess?.includes(CALL_IN_LEADER) && <CallInAtAGlance />}
          </div>
        </GenericCard>
      )}
    </>
  )
}

export default AtAGlanceCard
