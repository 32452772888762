const PersonIcon = (props) => {
  const { styles } = props
  return (
    <svg
      style={styles.personIconMargin}
      width="18px"
      height="19px"
      viewBox="0 0 18 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group</title>
      <g id="Person_icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group">
          <circle id="Oval" fill="#CC0000" cx="8.5" cy="4.5" r="4.5" />
          <path
            d="M15,11 C16.6568542,11 18,12.3431458 18,14 L18,19 L0,19 L0,14 C-2.02906125e-16,12.3431458 1.34314575,11 3,11 L15,11 Z"
            id="Combined-Shape"
            fill="#CC0000"
          />
          <line x1="3.5" y1="16.5" x2="3.5" y2="18.5" id="Line" stroke="#FFFFFF" strokeLinecap="square" />
          <line x1="14.5" y1="16.5" x2="14.5" y2="18.5" id="Line" stroke="#FFFFFF" strokeLinecap="square" />
        </g>
      </g>
    </svg>
  )
}
export default PersonIcon
