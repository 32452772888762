import { addWeeks, endOfWeek, parseISO, startOfWeek, subWeeks } from 'date-fns'
import { toIsoStringWithoutTime } from '../../utils/DateUtil'
import { FIXED_END_DATE_FROM_UKG } from '../../constants/AvailabilityConstants'

export const AvailabilityRulesEngine = {
  process: (arr) => {
    let arr1 = AvailabilityRulesEngine.orderByUpdatedTime(arr)
    let { onlyForeverAvailabilities, withoutForeverAvailabilities } =
      AvailabilityRulesEngine.extractForeverAvailabilities(arr1)
    let arr2 = AvailabilityRulesEngine.orderByStartDate(withoutForeverAvailabilities)
    let arr3 = AvailabilityRulesEngine.removeDuplicates(arr2)
    let arr4 = AvailabilityRulesEngine.makeItLinear(arr3)
    let arr5 = AvailabilityRulesEngine.makeItLinear(onlyForeverAvailabilities)

    let resultArr = [...arr4, ...arr5]

    return resultArr
  },
  orderByUpdatedTime: (userAvailabilities) => {
    return userAvailabilities.sort((a, b) => {
      return new Date(b.updatedTimestamp) - new Date(a.updatedTimestamp)
    })
  },
  extractForeverAvailabilities: (userAvailabilities) => {
    let onlyForeverAvailabilities = userAvailabilities.filter(
      (item) => item.expiration_date === FIXED_END_DATE_FROM_UKG,
    )
    let withoutForeverAvailabilities = userAvailabilities.filter(
      (item) => item.expiration_date !== FIXED_END_DATE_FROM_UKG,
    )
    return { onlyForeverAvailabilities, withoutForeverAvailabilities }
  },
  orderByStartDate: (userAvailabilities) => {
    return userAvailabilities.sort((a, b) => {
      let thisAvlStartDate = parseISO(new Date(a.effective_date).toISOString().slice(0, -1))
      let nextAvlStartDate = parseISO(new Date(b.effective_date).toISOString().slice(0, -1))
      return thisAvlStartDate - nextAvlStartDate
    })
  },
  removeDuplicates: (userAvailabilities) => {
    let arr = userAvailabilities.reduce((unique, o) => {
      if (
        !unique.some(
          (obj) =>
            o.effective_date === FIXED_END_DATE_FROM_UKG ||
            (obj.effective_date === o.effective_date &&
              obj.expiration_date === o.expiration_date &&
              obj.pattern_type === o.pattern_type),
        )
      ) {
        unique.push(o)
      }
      return unique
    }, [])
    return arr
  },
  makeItLinear: (userAvailabilities) => {
    for (let i = 0; i < userAvailabilities.length - 1; i++) {
      let thisAvlStartDate = parseISO(
        new Date(userAvailabilities[i].effective_date).toISOString().slice(0, -1),
      ).getTime()
      let thisAvlEndDate = parseISO(
        new Date(userAvailabilities[i].expiration_date).toISOString().slice(0, -1),
      ).getTime()
      let nextAvlStartDate = parseISO(
        new Date(userAvailabilities[i + 1].effective_date).toISOString().slice(0, -1),
      ).getTime()
      let nextAvlEndDate = parseISO(
        new Date(userAvailabilities[i + 1].expiration_date).toISOString().slice(0, -1),
      ).getTime()

      if (thisAvlStartDate === nextAvlStartDate && thisAvlEndDate < nextAvlEndDate) {
        let newStartDateNextAvl = startOfWeek(addWeeks(thisAvlEndDate, 1))
        userAvailabilities[i + 1].effective_date = toIsoStringWithoutTime(newStartDateNextAvl)
      } else if (thisAvlStartDate === nextAvlStartDate && thisAvlEndDate > nextAvlEndDate) {
        userAvailabilities.splice(i + 1, 1)
      } else if (
        thisAvlStartDate === nextAvlStartDate &&
        thisAvlEndDate === nextAvlEndDate &&
        userAvailabilities[i].pattern_type.toLowerCase() === 'base'
      ) {
        userAvailabilities.splice(i, 1)
      } else if (
        thisAvlStartDate === nextAvlStartDate &&
        thisAvlEndDate === nextAvlEndDate &&
        userAvailabilities[i].pattern_type.toLowerCase() === 'override'
      ) {
        userAvailabilities.splice(i + 1, 1)
      } else if (thisAvlStartDate < nextAvlStartDate && thisAvlEndDate < nextAvlStartDate) {
        // do nothing
      } else if (thisAvlStartDate < nextAvlStartDate && thisAvlEndDate > nextAvlStartDate) {
        let newEndDateForThisAvl = endOfWeek(subWeeks(nextAvlStartDate, 1))
        userAvailabilities[i].expiration_date = toIsoStringWithoutTime(newEndDateForThisAvl)
        let newStartDateNextAvl = startOfWeek(addWeeks(newEndDateForThisAvl, 1))
        userAvailabilities[i + 1].effective_date = toIsoStringWithoutTime(newStartDateNextAvl)
      } else if (thisAvlStartDate < nextAvlStartDate && thisAvlEndDate === nextAvlEndDate) {
        if (
          userAvailabilities[i].pattern_type.toLowerCase() === 'override' &&
          userAvailabilities[i + 1].pattern_type.toLowerCase() === 'base'
        ) {
          userAvailabilities.splice(i + 1, 1)
        } else if (
          userAvailabilities[i].pattern_type === 'base' &&
          userAvailabilities[i + 1].pattern_type === 'override'
        ) {
          let newEndDateForThisAvl = endOfWeek(subWeeks(nextAvlStartDate, 1))
          userAvailabilities[i].expiration_date = toIsoStringWithoutTime(newEndDateForThisAvl)
          let newStartDateNextAvl = startOfWeek(addWeeks(newEndDateForThisAvl, 1))
          userAvailabilities[i + 1].effective_date = toIsoStringWithoutTime(newStartDateNextAvl)
        } else if (
          userAvailabilities[i].pattern_type === 'override' &&
          userAvailabilities[i + 1].pattern_type === 'override'
        ) {
          if (userAvailabilities[i].updatedTimestamp > userAvailabilities[i + 1].updatedTimestamp) {
            // do nothing
          } else {
            let newEndDateForThisAvl = endOfWeek(subWeeks(nextAvlStartDate, 1))
            userAvailabilities[i].expiration_date = toIsoStringWithoutTime(newEndDateForThisAvl)
            let newStartDateNextAvl = startOfWeek(addWeeks(newEndDateForThisAvl, 1))
            userAvailabilities[i + 1].effective_date = toIsoStringWithoutTime(newStartDateNextAvl)
          }
        }
      }
    }
    return userAvailabilities
  },
}
