const SIGN_IN = 'auth/SIGN_IN'
const SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS'
const SIGN_OUT = 'auth/SIGN_OUT'
const PROMPT_SIGN_IN = 'auth/PROMPT_SIGN_IN'
const DECLINE_SIGN_IN = 'auth/DECLINE_SIGN_IN'

export const signIn = () => ({ type: SIGN_IN })

export const signInSuccess = (payload) => ({ type: SIGN_IN_SUCCESS, payload })

export const signOut = () => ({ type: SIGN_OUT })

export const promptToSignIn = () => ({ type: PROMPT_SIGN_IN })

export const declineSignIn = () => ({ type: DECLINE_SIGN_IN })

const formatAdGroups = (groups = []) => {
  return groups.reduce((result, group) => {
    group.split(',').forEach((attribute) => {
      if (attribute.indexOf('CN=') === 0) result.push(attribute.split('CN=')[1])
    })
    return result
  }, [])
}

export const formatUserInfo = (info = {}) => {
  return {
    email: info.mail,
    firstName: info.firstname,
    lanId: info.samaccountname,
    fullName: `${info.firstname} ${info.lastname}`,
    empid: '00' + info.empid,
    lastName: info.lastname,
    memberOf: formatAdGroups(info.memberof),
    accessToken: info.accessToken,
    locationId: info.locationid,
  }
}

export const initialState = {
  ...formatUserInfo(),
  isAuthorized: false,
  popupCount: 0,
  popupType: null,
  authModalIsShown: false,
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN:
      return {
        ...state,
        popupType: 'login',
        popupCount: state.popupCount + 1,
      }

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        ...formatUserInfo(payload),
        isAuthorized: true,
        authModalIsShown: false,
      }

    case SIGN_OUT:
      return {
        ...state,
        ...formatUserInfo(),
        isAuthorized: false,
        popupType: 'logout',
        popupCount: state.popupCount + 1,
      }

    case PROMPT_SIGN_IN:
      return {
        ...state,
        authModalIsShown: true,
      }

    case DECLINE_SIGN_IN:
      return {
        ...state,
        ...formatUserInfo(),
        isAuthorized: false,
        authModalIsShown: false,
      }

    default:
      return state
  }
}

export default reducer
