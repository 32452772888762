import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LEADER_DATE_HEADER_FORMAT } from '../../../../../../constants/leaderConstants'
import { PUNCH_CORRECTION } from '../../../../../../constants/RequestConstants'
import { setBackToComponent } from '../../../../../../store/header/actionCreator'
import { formatDateString } from '../../../../../../utils/DateUtil'
import RequestStatusIcon from '../../../../../Requests/RequestStatusIcon'
import { getPunchCorrectionApiData } from '../../../../CategoryDetail/PunchCorrectionApiData'
import { MY_TEAM_PAGE_PATH } from '../../../MyTeamPage'

export default function PunchCorrectionRow({ data }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { DataEnum, PunchCorrectionDataMap } = useMemo(() => getPunchCorrectionApiData(), [])

  let formattedStartDate = useMemo(
    () =>
      formatDateString(
        PunchCorrectionDataMap.get(DataEnum.PunchDate).value(data),
        PunchCorrectionDataMap.get(DataEnum.PunchDate).format,
        LEADER_DATE_HEADER_FORMAT,
      ),
    [PunchCorrectionDataMap, DataEnum.PunchDate, data],
  )

  return (
    <ListItem disablePadding disableGutters sx={{ width: '100%', maxWidth: 640 }}>
      <ListItemButton
        sx={{ paddingX: '0px', paddingY: '3px' }}
        onClick={() => {
          dispatch(setBackToComponent(MY_TEAM_PAGE_PATH))
          navigate(`/leader/myteam/${PUNCH_CORRECTION}/${data.request_id}`, { state: { data } })
        }}
        data-cy={`TableContent-row-${data.request_id}`}
      >
        <ListItemIcon sx={{ justifyContent: 'center' }}>
          <RequestStatusIcon
            status={PunchCorrectionDataMap.get(DataEnum.Status).value(data)}
            isTimeOffTeamMember={false}
          />
        </ListItemIcon>
        <ListItemText
          primary={`${formattedStartDate}`}
          secondary={
            <>
              <Typography sx={{ display: 'block' }} component="span" variant="body2" color="palette.primary.dark">
                {PunchCorrectionDataMap.get(DataEnum.FullName).value(data)}
              </Typography>
              <Typography sx={{ display: 'block' }} component="span" variant="body2" color="palette.primary.dark">
                {PunchCorrectionDataMap.get(DataEnum.JobClassification).value(data)}
              </Typography>
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
