import axios from 'axios'

import apiConfig from '../../config/apiConfig'
import {
  CLEAR_PREVIOUS_STATE,
  POST_REQUESTS_DATA_ERROR,
  POST_REQUESTS_DATA_SUCCESS,
  SHOW_INFO_MESSAGE,
  UPDATE_PUNCH_CORRECTION_REQUESTS_DATA_ERROR,
  UPDATE_PUNCH_CORRECTION_REQUESTS_DATA_SUCCESS,
} from './actionType'
import { LOCATION_TYPE_STORE } from '../../constants/locationConstants'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function postRequestsData(punchCorrectionDetails, locType) {
  const requestBody = punchCorrectionDetails
  let apiUrl = ''

  return (dispatch) => {
    if (locType === LOCATION_TYPE_STORE) {
      apiUrl =
        `${apiConfig.punchCorrection.url}` + '/punch_correction_requests' + '?key=' + `${apiConfig.punchCorrection.key}`

      return axios
        .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
        .then((response) => {
          dispatch(handlePostRequestsSuccess(response))
        })
        .catch((err) => {
          dispatch(handlePostRequestsError(err))
        })
    } else {
      apiUrl =
        `${apiConfig.punchCorrection.url}` + '/approve_punch_corrections' + '?key=' + `${apiConfig.punchCorrection.key}`

      return axios
        .put(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
        .then((response) => {
          dispatch(handlePostRequestsSuccess(response))
        })
        .catch((err) => {
          dispatch(handlePostRequestsError(err))
        })
    }
  }
}

function handlePostRequestsSuccess(data) {
  return {
    type: POST_REQUESTS_DATA_SUCCESS,
    data,
  }
}

function handlePostRequestsError(data) {
  return {
    type: POST_REQUESTS_DATA_ERROR,
    data,
  }
}

export function clearPreviousState() {
  return {
    type: CLEAR_PREVIOUS_STATE,
  }
}

export function showInfoMessage(data) {
  return {
    type: SHOW_INFO_MESSAGE,
    data,
  }
}

//UPDATE Punch Correction Status
export function updatePunchCorrectionRequestsData(userPunchCorrectionData) {
  const requestBody = userPunchCorrectionData

  return (dispatch) => {
    let apiUrl =
      `${apiConfig.punchCorrection.url}` + '/update_statuses' + '?key=' + `${apiConfig.storeAvailability.key}`

    return axios
      .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleUpdatePunchCorrectionRequestsSuccess(response))
      })
      .catch((err) => {
        dispatch(handleUpdatePunchCorrectionRequestsError(err))
      })
  }
}

function handleUpdatePunchCorrectionRequestsSuccess(data) {
  return {
    type: UPDATE_PUNCH_CORRECTION_REQUESTS_DATA_SUCCESS,
    data,
  }
}

function handleUpdatePunchCorrectionRequestsError(data) {
  return {
    type: UPDATE_PUNCH_CORRECTION_REQUESTS_DATA_ERROR,
    data,
  }
}
