export const SET_PTO_REQUESTS_DATA_TABLE_COLUMNS_TO_DEFAULT = 'SET_PTO_REQUESTS_DATA_TABLE_COLUMNS_TO_DEFAULT'
export const SET_PTO_REQUESTS_DATA_TABLE_STATE_TO_DEFAULT = 'SET_PTO_REQUESTS_DATA_TABLE_STATE_TO_DEFAULT'
export const SET_PTO_REQUESTS_DATA_LOADING = 'SET_PTO_REQUESTS_DATA_LOADING'
export const SET_PTO_REQUESTS_DATA_TABLE_FILTERS_TO_DEFAULT = 'SET_PTO_REQUESTS_DATA_TABLE_FILTERS_TO_DEFAULT'
export const SET_PTO_REQUESTS_DATA_PER_PAGE = 'SET_PTO_REQUESTS_DATA_PER_PAGE'
export const SET_PTO_REQUESTS_DATA_PAGE = 'SET_PTO_REQUESTS_DATA_PAGE'
export const GET_PTO_REQUESTS_REQUEST_SUCCESS = 'GET_PTO_REQUESTS_REQUEST_SUCCESS'
export const GET_PTO_REQUESTS_REQUEST_ERROR = 'GET_PTO_REQUESTS_REQUEST_ERROR'
export const OPEN_TIME_OFF_DIALOG = 'OPEN_TIME_OFF_DIALOG'
export const CLOSE_TIME_OFF_DIALOG = 'CLOSE_TIME_OFF_DIALOG'
export const SET_PTO_REQUESTS_DATA_SELECTED_FILTERS = 'SET_PTO_REQUESTS_DATA_SELECTED_FILTERS'
export const GET_PTO_REQUESTS_FILTERS_REQUEST_SUCCESS = 'GET_PTO_REQUESTS_FILTERS_REQUEST_SUCCESS'
export const GET_PTO_REQUESTS_FILTERS_REQUEST_ERROR = 'GET_PTO_REQUESTS_FILTERS_REQUEST_ERROR'
export const SET_PTO_REQUESTS_DATA_SEARCH = 'SET_PTO_REQUESTS_DATA_SEARCH'
export const LEADER_TIME_OFF_DECISION_SUCCESS = 'LEADER_TIME_OFF_DECISION_SUCCESS'
export const LEADER_TIME_OFF_DECISION_ERROR = 'LEADER_TIME_OFF_DECISION_ERROR'
export const SET_LEADER_TIME_OFF_DATA_SAVING = 'SET_LEADER_TIME_OFF_DATA_SAVING'
export const SET_LEADER_TIME_OFF_DATA_ERROR = 'SET_LEADER_TIME_OFF_DATA_ERROR'
export const SET_LEADER_TIME_OFF_DATA_RESPONSE = 'SET_LEADER_TIME_OFF_DATA_RESPONSE'
export const SET_PTO_REQUESTS_DATA_SORT = 'SET_PTO_REQUESTS_DATA_SORT'
export const SET_PTO_REQUESTS_DATA_COLUMN_VIEW = 'SET_PTO_REQUESTS_DATA_COLUMN_VIEW'
