import { useEffect, useState } from 'react'
import { FeedbackQuestions, Question, useFeedbackQuestions } from '../../../api/feedback/useFeedbackQuestions'

export interface FeedbackResponse {
  question_id: number
  feedback: string
}

export interface FeedbackProps {
  responses: FeedbackResponse[]
  handleResponse: (id: number, response: string) => void
  data: FeedbackQuestions | undefined
}

export default function useFeedback(): FeedbackProps {
  const [responses, setResponses] = useState<FeedbackResponse[]>([])

  const { data } = useFeedbackQuestions()

  useEffect(() => {
    if (data && data.questions) {
      setResponses(data.questions.map((q: Question) => ({ question_id: q.id, feedback: '' })))
    }
  }, [data])

  const handleResponse = (id: number, feedback: string) => {
    setResponses((prevResponses) => prevResponses.map((f) => (f.question_id === id ? { ...f, feedback: feedback } : f)))
  }

  return { data, responses, handleResponse }
}
