import JobIcon from '@mui/icons-material/Label'
import { Box, Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER_SHIFT_SWAP,
  UNABLE_TO_SAVE,
  WITHDRAW_SHIFT_SWAP_MESSAGE,
} from '../../constants/errorConstants'
import { withdrawSwapRequest } from '../../store/WeeklySchedulePage/actionCreator'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { formatErrorCode } from '../../utils/ErrorHandling'
import DisplaySegmentShiftSwap from '../DisplaySegments/DisplaySegmentShiftSwap'
import ShiftSwapVerticalIcon from './../Icons/ShiftSwapVerticalIcon'

const WithdrawSwapRequestModal = (props) => {
  const { styles, shift } = props

  // const outputDateFormat = 'dddd, MMMM D'
  const dispatch = useDispatch()

  const withdrawSwapRequestResponse = useSelector((state) => state.weeklySchedule.withdrawSwapRequestResponse)
  const withdrawSwapRequestErrResponse = useSelector((state) => state.weeklySchedule.withdrawSwapRequestErrResponse)

  useEffect(() => {
    if (withdrawSwapRequestResponse) {
      dispatch(showNotificationSuccess(true, WITHDRAW_SHIFT_SWAP_MESSAGE))
    } else if (withdrawSwapRequestErrResponse) {
      if (
        withdrawSwapRequestErrResponse.code &&
        withdrawSwapRequestErrResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(withdrawSwapRequestErrResponse.code)
      ) {
        dispatch(showNotificationError(true, withdrawSwapRequestErrResponse.message))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE +
              ' ' +
              formatErrorCode(withdrawSwapRequestErrResponse, ERROR_CODE_CANNOT_CONNECT_TO_SERVER_SHIFT_SWAP),
          ),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawSwapRequestResponse, withdrawSwapRequestErrResponse])

  const handleClick = () => {
    dispatch(withdrawSwapRequest(shift.request_id))

    handleClose()
  }

  const renderJob = (jobs) => {
    let jobList = []
    if (jobs && jobs.length > 0) {
      jobs.forEach((job, elemIndex) => {
        if (elemIndex !== 0) {
          let lastIndex = job.org_path.lastIndexOf('/')
          jobList.push(job.org_path.substr(lastIndex + 1, job.org_path.length))
        }
      })
    }
    if (jobList.length > 0) {
      return (
        <Box sx={styles.addtionalJobsContainer}>
          <Typography variant="body2">This shift contains job transfers:</Typography>
          {jobList.map((job, index) => (
            <Box sx={styles.jobContainerAtPopup} key={index}>
              <JobIcon color="primary" sx={styles.iconMargin} />
              <Typography variant="body2" sx={styles.jobName}>
                {job}
              </Typography>
            </Box>
          ))}
        </Box>
      )
    } else {
      return null
    }
  }

  const handleClose = () => {
    props.handleClose()
  }

  const renderDialogInfoText = () => {
    return (
      <>
        <Typography sx={styles.displaySegmentContainer} variant="body2">
          <span>
            <b>Attention:</b> You are responsible for your current shift until the shift swap request has been accepted.
          </span>
        </Typography>
      </>
    )
  }

  // TODO: remove ternary operator for DialogActions and keep Button and ConfirmGiveUpShift when Retract shift implemented

  if (shift) {
    return (
      <Dialog
        disableEscapeKeyDown
        open={props.open}
        onClose={handleClose}
        aria-label="responsive-dialog-title"
        sx={styles.dialogPaper}
      >
        <DialogTitle sx={styles.titleContainerModal} id="responsive-dialog-title">
          Withdraw Swap Request
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} sm={12} sx={styles.displaySegmentContainerModal}>
            <Typography>Are you sure want to withdraw swap request for these shifts?</Typography>
          </Grid>
          <Box sx={styles.requestContainerModal}>
            <DisplaySegmentShiftSwap shift={shift.requester} ownShift={true} styles={styles} />
          </Box>
          <Box sx={styles.verticalGap}>
            <ShiftSwapVerticalIcon />
          </Box>
          <Box sx={styles.requestContainerModal}>
            <DisplaySegmentShiftSwap shift={shift.accepter} styles={styles} />
          </Box>
          {renderJob(shift.jobs)}
          {renderDialogInfoText()}
        </DialogContent>
        <DialogActions>
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleClick}
              sx={styles.mobileButton}
              data-cy="initiate-swap-Button"
            >
              Withdraw Swap Request
            </Button>
          </>
        </DialogActions>
      </Dialog>
    )
  } else {
    return null
  }
}

export default WithdrawSwapRequestModal
