import axios from 'axios'
import apiConfig from '../../../config/apiConfig'
import { IUserAccess } from '../responses/userAccess'

export interface UserFeaturesQueryParams {
  worker_id: string
}

export const getUserFeaturesV2 = (params: UserFeaturesQueryParams): Promise<IUserAccess> => {
  let apiUrl = `${apiConfig.userAccess.urlv2}` + '/user_features?key=' + `${apiConfig.userAccess.key}`

  return axios.get(apiUrl, { params }).then((response) => response.data)
}
