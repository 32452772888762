import { Grid } from '@mui/material'
import { Comment } from '../../common/Comment'

const CommentBox = ({ comments }) => {
  return (
    <>
      {comments?.map((comment) => (
        <Grid item xs={12}>
          <Comment comment={comment} />
        </Grid>
      ))}
    </>
  )
}

export default CommentBox
