import { useAvailabilityRequests } from '../../../../../../api/availability/useAvailabilityRequests'
import TableContent from '../../TableContent'
import AvailabilityRow from './AvailabilityRow'

export default function Availability() {
  const { data, error, isLoading, isFetching } = useAvailabilityRequests()
  return (
    <TableContent
      data={data?.data?.worker_availability_requests}
      totalCount={data?.headers['x-total-count']}
      numberOfRecords={data?.data?.total_worker_availability_requests}
      error={error}
      isLoading={isLoading}
      isFetching={isFetching}
      Row={AvailabilityRow}
    />
  )
}
