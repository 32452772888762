import { SET_REQUEST_TYPE, SET_REQUESTS_LOADING, SET_STATUS_FILTER } from './actionType'

const initialState = {
  requestType: '',
  isLoading: 'N',
  updateRequestStatusSuccessResponse: null,
  updateRequestStatusErrorResponse: null,
}

export default function requests(state = initialState, action = {}) {
  switch (action.type) {
    case SET_STATUS_FILTER: {
      return {
        ...state,
        selectedStatus: action.data,
      }
    }
    case SET_REQUEST_TYPE: {
      return {
        ...state,
        requestType: action.data,
      }
    }
    case SET_REQUESTS_LOADING: {
      return {
        ...state,
        isLoading: action.data,
      }
    }
    default:
      return state
  }
}
