import MandatoryGroupIcon from '@mui/icons-material/Group'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useWorkerDetails } from '../../api/worker/useWorkerDetails'
import HomePageCard from '../common/HomePageCard'
import InnerLoader from '../Loader/InnerLoader'
import { useTheme } from '@mui/material'

const CSC_ERROR_MESSAGE = '  Please try again later and if the issue persists, contact the CSC.'
const WORKER_ERROR_MESSAGE = 'Unable to load your mandatory groups at this time.' + CSC_ERROR_MESSAGE
const WORKER_ERROR_CODES_SHOW_MESSAGE = ['wfm-6-5', 'wfm-6-11']
const ERROR_CODE_CANNOT_CONNECT_TO_WORKER = 'wfm-6-0'

const NO_MANDATORY_GROUP_API = 'NA'
const NO_MANDATORY_GROUP_DISPLAY = '--'

const getStyles = (theme) => ({
  gridItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '6px',
    marginBottom: '6px',
  },
  startTimeItem: {
    flexGrow: '1',
    paddingTop: '9px',
    minHeight: '32px',
  },
  endTimeItem: {
    flexGrow: '1',
    paddingTop: '7px',
    minHeight: '32px',
  },
})

function MandatoryGroupCard(props) {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { renderHomePageCardError } = props

  const { data, error, isRefetching } = useWorkerDetails()

  const renderMandatoryGroupsData = () => {
    const workerDetails = data.worker_details
    let downGroup = NO_MANDATORY_GROUP_DISPLAY
    let upGroup = NO_MANDATORY_GROUP_DISPLAY
    if (
      workerDetails.length > 0 &&
      workerDetails[0].schedule_down_group !== NO_MANDATORY_GROUP_API &&
      workerDetails[0].schedule_up_group !== NO_MANDATORY_GROUP_API
    ) {
      downGroup = workerDetails[0].schedule_down_group
      upGroup = workerDetails[0].schedule_up_group
    }
    return (
      <Grid container>
        <Grid item xs={6} sm={7}>
          <Typography variant="body2" sx={styles.startTimeItem}>
            <b>Up Group:</b> {upGroup}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={5}>
          <Typography variant="body2" sx={styles.endTimeItem}>
            <b>Down Group:</b> {downGroup}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  /**
   * wondering why we check data first and not isLoading?
   * Check out this blog: https://tkdodo.eu/blog/status-checks-in-react-query
   */
  const renderMandatoryGroupsResponse = () => {
    if (data) return renderMandatoryGroupsData()
    if (error) {
      return renderHomePageCardError(
        error?.response?.data,
        WORKER_ERROR_MESSAGE,
        WORKER_ERROR_CODES_SHOW_MESSAGE,
        ERROR_CODE_CANNOT_CONNECT_TO_WORKER,
      )
    }
    return <InnerLoader size={48} />
  }

  return (
    <Grid item xs={12} md={12} sx={styles.gridItem}>
      <HomePageCard title="Mandatory Groups" icon={<MandatoryGroupIcon />} isRefetching={isRefetching}>
        {renderMandatoryGroupsResponse()}
      </HomePageCard>
    </Grid>
  )
}

export default MandatoryGroupCard
