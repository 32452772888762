import { useContext } from 'react'
import { UserContext, UserContextType } from '../MyTimeAccessProviders'

export const useUser = (): UserContextType => {
  const user = useContext(UserContext)

  if (user === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return user
}
