import { HelpRounded } from '@mui/icons-material'
import { Grid, Paper, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import moment from 'moment'
import { massVacationManager } from '../../../utils/DateUtil'
import { hasPriorityGapsOrZero } from '../../../utils/requests'
import IconButtonPopover from '../../common/IconButtonPopover'

const ANNUAL_VACATION_MESSAGE =
  'Team members in a legacy plan or part time team members may have a different max accrual limit. Please refer to your specific time off program guide.'

export default function MassVacationGuidelines({ massVacationResponse, totalRequests }) {
  const mvManager = massVacationManager()
  const { hasGaps, priorityMessage } = hasPriorityGapsOrZero(massVacationResponse?.requests)

  return (
    <Paper square sx={{ padding: 1 }}>
      <Grid container>
        <Grid item container justifyContent={'center'}>
          <Typography sx={{ fontWeight: 'bold', paddingY: 0.5 }}>
            {massVacationResponse?.message ? massVacationResponse?.message : mvManager.message}
          </Typography>
        </Grid>
        {hasGaps ? (
          <Typography color={'primary'} marginTop={1}>
            {priorityMessage}
          </Typography>
        ) : (
          ''
        )}
        <Grid item container paddingY={1}>
          <Typography color="tertiary.contrastText">
            {massVacationResponse?.message ? '' : mvManager.description}
          </Typography>
        </Grid>
        <Grid item container justifyContent={'space-between'}>
          <Grid item>
            <Typography color="tertiary.contrastText">Seniority Date</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: 'bold' }}>
              {massVacationResponse?.seniority_date
                ? moment(massVacationResponse?.seniority_date).format('ll')
                : 'Unknown'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item sx={{ display: 'flex' }}>
            <Typography color="tertiary.contrastText">Annual Vacation Limit&nbsp;</Typography>
            <IconButtonPopover
              icon={<HelpRounded sx={{ fontSize: 20, color: grey[700] }} />}
              ariaLabel={'Annual limit notice'}
              popOverMessage={ANNUAL_VACATION_MESSAGE}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: 'bold' }}>{massVacationResponse?.annual_vacation_hours} hours</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent={'space-between'}>
          <Grid item>
            <Typography color="tertiary.contrastText">My Mass Vacation Requests</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: 'bold' }}>{totalRequests || 0}/10</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
