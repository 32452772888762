import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { showNotificationError } from '../../store/notification/actionCreator'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { useLocationDetails } from '../location/useLocationDetails'
import { ERROR_CODES_SHOW_MESSAGE } from '../../constants/errorConstants'
import { format, isValid } from 'date-fns'

const getSchedules = async (tmNumber, locationId, startDate, endDate) => {
  let apiUrl =
    `${apiConfig.schedule.url}` +
    '/schedules' +
    '?team_member_number=' +
    tmNumber +
    '&location_id=' +
    locationId +
    '&start_date_time=' +
    startDate +
    '&end_date_time=' +
    endDate +
    '&key=' +
    `${apiConfig.schedule.key}`

  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

export function useSchedules(startDate, endDate) {
  const { session, isAuthenticated, login } = useAuth()
  const dispatch = useDispatch()
  const { data: locationDetails } = useLocationDetails()
  const authenticated = isAuthenticated()
  const startKey = isValid(startDate) ? format(startDate, 'yyyy-MM-dd HH:mm') : ''
  const endKey = isValid(endDate) ? format(endDate, 'yyyy-MM-dd HH:mm') : ''

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: ['schedules', startKey, endKey],
    queryFn: () =>
      getSchedules(
        padEmpIdWithZeros(session?.userInfo?.empid, 10),
        locationDetails?.location_id,
        startDate.toISOString(),
        endDate.toISOString(),
      ),
    staleTime: 1000 * 10,
    enabled: authenticated && !!locationDetails?.location_id && !!startKey && !!endKey,
    keepPreviousData: true,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    onError: (error) => {
      dispatch(
        showNotificationError(
          true,
          ERROR_CODES_SHOW_MESSAGE.includes(error?.response?.code)
            ? error?.response?.message
            : 'Error loading schedule',
        ),
      )
      const loggingInfo = {
        message: 'GET /schedules response has an error.',
        url: window.location.href,
        error: {
          url: error?.config?.url,
          message: error?.response?.message,
          code: error?.response?.code,
          data: error?.response?.data,
          headers: error?.response?.headers,
          status: error?.response?.status,
          statusText: error?.response?.statusText,
          location: locationDetails?.location_id,
        },
      }
      const optionalLoggingOptions = {
        level: LogLevel.Error,
      }
      logEvent(loggingInfo, optionalLoggingOptions)
    },
  })
}
