import { Divider, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import {
  AVAILABILITY,
  CALL_IN_LATE,
  CALL_OFF_ABSENT,
  PUNCH_CORRECTION,
  TIME_OFF,
} from '../../../../constants/RequestConstants'
import ControlPanel from '../ControlPanel/ControlPanel'
import Availability from './Categories/Availability/Availability'
import CallIn from './Categories/CallIn/CallIn'
import CallOff from './Categories/CallOff/CallOff'
import PunchCorrection from './Categories/PunchCorrection/PunchCorrection'
import TimeOff from './Categories/TimeOff/TimeOff'
import PaginationBar from './PaginationBar'
import TableFallback from './TableFallback'

export default function Table() {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{
        width: '100%',
        margin: '0 auto',
        maxWidth: '640px',
        '@media (orientation: landscape) and (max-height: 400px)': {
          maxWidth: '900px',
        },
        height: '100vh',
        backgroundColor: '#fff',
        boxShadow:
          '0px 2px 1px -1px rgb(0, 0, 0, .20), 0px 1px 1px 0px rgb(0, 0, 0, .14), 0px 1px 3px 0px rgb(0, 0, 0, .12)',
        overflow: 'hidden',
      }}
    >
      <Grid
        container
        item
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
        }}
      >
        <ControlPanel />
      </Grid>

      <Grid
        container
        item
        xs
        sx={{
          overflowY: 'auto',
          flexGrow: 1,
        }}
        data-cy="TableContent-table"
      >
        <TableSelected />
      </Grid>

      <Divider aria-hidden="true" />

      <Grid
        container
        item
        sx={{
          position: 'sticky',
          zIndex: 10,
          backgroundColor: '#fff',
        }}
        justifyContent="center"
      >
        <PaginationBar />
      </Grid>
    </Grid>
  )
}

const TableSelected = () => {
  const selectedCategory = useSelector((store) => store.leader.selectedCategory)

  switch (selectedCategory) {
    case CALL_IN_LATE:
      return <CallIn />
    case CALL_OFF_ABSENT:
      return <CallOff />
    case TIME_OFF:
      return <TimeOff />
    case AVAILABILITY:
      return <Availability />
    case PUNCH_CORRECTION:
      return <PunchCorrection />
    default:
      return <TableFallback />
  }
}
