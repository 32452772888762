import { SYSTEM_NAME } from '../constants/RequestConstants'

export const padEmpIdWithZeros = (empId, length) => {
  if (empId === SYSTEM_NAME) return empId
  return empId?.padStart(length, '0')
}

export const getCreatedBy = (createdByWorkerId, loggedInTMId) => {
  if (!createdByWorkerId) return
  if (padEmpIdWithZeros(createdByWorkerId, 10) === padEmpIdWithZeros(loggedInTMId, 10)) return 'You'
  return createdByWorkerId
}
