import { format, setHours, setMinutes } from 'date-fns'
import { TIME_OFF_TYPES } from '../constants/RequestConstants'

const createStartTime = (dateOfRequestString, dateTimeSelectedString, resetTime) => {
  const date = new Date(dateOfRequestString)

  if (resetTime) {
    return format(setHours(setMinutes(date, 0), 0), "yyyy-MM-dd'T'HH:mm")
  }

  const time = new Date(dateTimeSelectedString)
  return format(setHours(setMinutes(date, time.getMinutes()), time.getHours()), "yyyy-MM-dd'T'HH:mm")
}

const processUnpaidDetail = (detail, date, allDayUnpaid) => {
  const minutes = allDayUnpaid ? 24 * 60 : detail.minutes
  const start_time = createStartTime(date, detail.startTime, allDayUnpaid)
  const type = detail.type

  return { minutes, start_time, type }
}

const processPTODetail = (startTime, detail, date) => {
  const start_time = createStartTime(date, startTime, false)

  return { minutes: detail.ptoDetails.minutes, start_time, type: detail.ptoDetails.type }
}

function processDetail(detail, date, allDayUnpaid) {
  const results = []

  if (detail.type === 'Unpaid') {
    const unpaidDetail = processUnpaidDetail(detail, date, allDayUnpaid)
    results.push(unpaidDetail)

    if (detail.usePTO) {
      const ptoDetail = processPTODetail(unpaidDetail.start_time, detail, date)
      results.push(ptoDetail)
    }
  } else {
    const detailCopy = {
      minutes: detail.minutes,
      start_time: createStartTime(date, detail.startTime, false),
      type: detail.type,
    }
    results.push(detailCopy)
  }

  return results
}

export function processDetailTotals(details) {
  const requestDetails = []
  let totalMinutes = 0
  let totalUnpaidMinutes = 0
  let totalPTOMinutes = 0
  let unpaidStartTimes = []
  let ptoStartDatetimeMap = new Map()

  details.forEach((processedDetail) => {
    requestDetails.push(processedDetail)
    totalMinutes += processedDetail.minutes

    if (processedDetail.type === TIME_OFF_TYPES.UNPAID) {
      totalUnpaidMinutes += processedDetail.minutes
      unpaidStartTimes.push(processedDetail.start_time)
    } else if (
      [TIME_OFF_TYPES.PTO_VACATION, TIME_OFF_TYPES.PTO_OTHER, TIME_OFF_TYPES.PTO_SICK].includes(processedDetail.type)
    ) {
      ptoStartDatetimeMap.set(processedDetail.start_time, processedDetail.minutes)
      totalPTOMinutes += processedDetail.minutes
    }
  })

  unpaidStartTimes.forEach((unpaidStart) => {
    let ptoMinutesForUnpaid = ptoStartDatetimeMap.get(unpaidStart)
    if (ptoMinutesForUnpaid) {
      totalMinutes -= ptoMinutesForUnpaid
      totalUnpaidMinutes -= ptoMinutesForUnpaid
    }
  })

  return { requestDetails, totalMinutes, totalUnpaidMinutes, totalPTOMinutes }
}

export function convertTimeOffRequest(request, commentRequiredFor, comment) {
  let totalMinutes = 0
  let totalUnpaidMinutes = 0
  let totalPTOMinutes = 0
  let requestDetails = []
  let dayErrors = new Map()
  let commentError = ''
  let allTypesSelected = []

  for (let date in request) {
    const allDayUnpaid = request[date].allDayUnpaid

    // eslint-disable-next-line no-loop-func
    request[date].requestDetails.forEach((detail) => {
      if (detail.type) {
        allTypesSelected.push(detail.type)
      }

      // Check if usePTO is true but no details provided
      if (detail.usePTO) {
        if (detail.ptoDetails.type) {
          allTypesSelected.push(detail.ptoDetails.type)
        }
        if (!detail.ptoDetails.minutes) {
          dayErrors.set(date, 'Use PTO is selected but no detail hours/minutes.')
          return
        } else if (!detail.ptoDetails.type) {
          dayErrors.set(date, 'Use PTO is selected but no detail type.')
          return
        }
      }

      if (!detail.startTime) {
        dayErrors.set(date, 'Please select a start time.')
        return
      }

      if (!detail.minutes) {
        dayErrors.set(date, 'Please select hours/minutes.')
        return
      }

      if (!detail.type) {
        dayErrors.set(date, 'Please select a type.')
        return
      }

      const processedDetails = processDetail(detail, date, allDayUnpaid)
      const result = processDetailTotals(processedDetails)

      requestDetails = [...requestDetails, ...result.requestDetails]
      totalMinutes += result.totalMinutes
      totalUnpaidMinutes += result.totalUnpaidMinutes
      totalPTOMinutes += result.totalPTOMinutes
    })
  }

  if (commentRequiredFor.some((typeRequiresComment) => allTypesSelected.includes(typeRequiresComment)) && !comment) {
    commentError = 'Comment is required for one or more selected types'
  }

  return {
    totalMinutes,
    totalUnpaidMinutes,
    totalPTOMinutes,
    requestDetails,
    dayErrors,
    commentError,
  }
}

export function hasPriorityGapsOrZero(requests) {
  if (!requests || requests.length === 0) return { hasGaps: false, priorityMessage: '' }

  let hasZeroPriority = false
  let minPriority = Number.MAX_SAFE_INTEGER
  let maxPriority = 0
  const prioritySet = new Set()

  for (const request of requests) {
    if (request.priority === 0) {
      hasZeroPriority = true
      break
    }
    if (request.priority >= 1 && request.priority <= 10) {
      prioritySet.add(request.priority)
      minPriority = Math.min(minPriority, request.priority)
      maxPriority = Math.max(maxPriority, request.priority)
    }
  }

  // Check for gaps in the existing priorities
  let hasGaps = false
  for (let i = minPriority; i <= maxPriority; i++) {
    if (!prioritySet.has(i)) {
      hasGaps = true
      break
    }
  }

  return {
    hasGaps: hasGaps || hasZeroPriority,
    priorityMessage:
      hasGaps || hasZeroPriority
        ? 'One or more of your mass vacation requests has no priority and/or there are gaps in your selected priorities.'
        : '',
  }
}
