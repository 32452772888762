import { InfoOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocationDetails } from '../../../api/location/useLocationDetails'
import { useUpdateRequest } from '../../../api/requests/useUpdateRequest'
import { CATEGORY_FEATURE_MAP, PROCESSED, REQUEST_TYPES, TIME_OFF_TYPES } from '../../../constants/RequestConstants'
import { UPDATE_TO_STATUS_MAP } from '../../../constants/leaderConstants'
import { padEmpIdWithZeros } from '../../../utils/EmployeeId'
import {
  isAnyRequestDetailsFailed,
  isMultipleRequestDetails,
  isRequestDetailsStatusesSame,
} from '../../../utils/leaderUtil'
import RequestStatusIcon from '../../Requests/RequestStatusIcon'
import IconButtonPopover from '../../common/IconButtonPopover'

import { TIME_OFF_PARTIAL_ACTION_LEADER, TIME_OFF_READ_ONLY_LEADER } from '../../../utils/ScreenName'
import { useUserFeatures } from '../../../api/userAccess/useUserFeatures'

const updateToObjProp = 'update_to_status'

export default function TimeOffDetailActionSection({ requestDetails, requestId, selectedCategory, infoMessage }) {
  const finalStateMessage = 'This request has reached its final state and can no longer be updated.'
  const feature = CATEGORY_FEATURE_MAP.get(selectedCategory)

  const requestDetailsWithUpdateTo = () => {
    if (selectedCategory in Object(UPDATE_TO_STATUS_MAP) && Array.isArray(requestDetails)) {
      return isAnyRequestDetailsFailed(requestDetails)
        ? requestDetailsWithFailedUpdateTo()
        : requestDetailsWithNonFailedUpdateTo()
    }
  }

  const requestDetailsWithFailedUpdateTo = () => {
    let requestDetailsCopy = JSON.parse(JSON.stringify(requestDetails))

    requestDetailsCopy?.forEach((segment, i) => {
      if (segment.status in Object(UPDATE_TO_STATUS_MAP[selectedCategory]) && segment.status.startsWith('Failed')) {
        if (segment.type === TIME_OFF_TYPES.UNPAID && segment.status === 'Failed') {
          requestDetailsCopy[i][updateToObjProp] = [PROCESSED] // for stores Unpaid, if failed. It can only go to processed.
        } else {
          requestDetailsCopy[i][updateToObjProp] = UPDATE_TO_STATUS_MAP[selectedCategory][segment.status]
        }
      }
    })

    return requestDetailsCopy
  }

  const requestDetailsWithNonFailedUpdateTo = () => {
    if (isRequestDetailsStatusesSame(requestDetails)) {
      let requestDetailsCopy = JSON.parse(JSON.stringify(requestDetails))
      requestDetailsCopy?.forEach((segment, i) => {
        if (segment.status in Object(UPDATE_TO_STATUS_MAP[selectedCategory])) {
          requestDetailsCopy[i][updateToObjProp] = UPDATE_TO_STATUS_MAP[selectedCategory][segment.status]
        }
      })
      return requestDetailsCopy
    }
  }

  const checkIsNotInEndstate = () => {
    return requestDetailsWithUpdateTo()?.some((detail) => {
      return !!detail[updateToObjProp]
    })
  }

  return (
    <>
      {checkIsNotInEndstate() ? (
        <ActionSection
          requestId={requestId}
          feature={feature}
          requestDetailsWithUpdateTo={requestDetailsWithUpdateTo()}
          infoMessage={infoMessage}
        />
      ) : (
        <Typography sx={{ padding: '8px', paddingBottom: '30px', textAlign: 'center', color: 'tertiary.contrastText' }}>
          {finalStateMessage}
        </Typography>
      )}
    </>
  )
}

function ActionSection({ requestId, feature, requestDetailsWithUpdateTo, infoMessage }) {
  const { mutate, isLoading } = useUpdateRequest(REQUEST_TYPES.time_off.type)
  const { data: locationDetails } = useLocationDetails()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data: userFeatures } = useUserFeatures()
  const screenAccess = userFeatures?.screenAccess

  const [updatedStatuses, setUpdatedStatuses] = useState(new Map())
  // const [comment, setComment] = useState('')
  const [isFormComplete, setIsFormComplete] = useState(false)
  const { empid } = useAuth()?.session?.userInfo || ''

  const [buttonDisabled, setButtonDisabled] = useState(false)

  //
  useEffect(() => {
    if (screenAccess?.includes(TIME_OFF_READ_ONLY_LEADER)) {
      setButtonDisabled(true)
      return
    }

    if (screenAccess?.includes(TIME_OFF_PARTIAL_ACTION_LEADER)) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsFormComplete(
      requestDetailsWithUpdateTo.every((detail) => {
        return detail[updateToObjProp] ? updatedStatuses.has(detail.time_off_request_detail_id) : true
      }),
    )
  }, [updatedStatuses, requestDetailsWithUpdateTo])

  const handleSubmitButton = () => {
    let updatedRequestDetails = []
    requestDetailsWithUpdateTo?.forEach((detail) => {
      if (detail[updateToObjProp]) {
        updatedRequestDetails.push({
          request_detail_id: detail.time_off_request_detail_id,
          status: updatedStatuses.get(detail.time_off_request_detail_id),
        })
      }
    })
    const requestBody = {
      request_id: requestId,
      feature: `${feature}_${locationDetails?.location_type?.toLowerCase()}`,
      request_details: updatedRequestDetails,
      updated_by: padEmpIdWithZeros(empid, 10),
      // comment: comment,
    }

    mutate(requestBody, {
      onSuccess: () => {
        queryClient.invalidateQueries(['timeOffRequests'])
        navigate(-1)
      },
    })
  }

  const renderLoadingSpinner = () => {
    if (isLoading) {
      return <CircularProgress size="2rem" />
    }
  }

  const isUpdateStatusBySegment = () => {
    return isAnyRequestDetailsFailed(requestDetailsWithUpdateTo) && isMultipleRequestDetails(requestDetailsWithUpdateTo)
  }

  const renderUpdateDetailSegmentStatus = () => {
    // eslint-disable-next-line array-callback-return
    return requestDetailsWithUpdateTo?.map((segment, i) => {
      if (segment[updateToObjProp]) {
        if (screenAccess?.includes(TIME_OFF_PARTIAL_ACTION_LEADER) && segment.status === 'Pending') {
          return (
            <ListItemButton disabled={buttonDisabled}>
              <UpdateDetailStatus
                currentStatus={segment.status}
                requestDetailIds={[segment.time_off_request_detail_id]}
                updatedStatuses={updatedStatuses}
                setUpdatedStatuses={setUpdatedStatuses}
                updateToStatusList={segment[updateToObjProp]}
                updateRowMessage={`Update Segment ${i + 1} To:`}
                index={i + 1}
                infoMessage={infoMessage}
                key={i}
              />
            </ListItemButton>
          )
        } else {
          return (
            <ListItemButton disabled={screenAccess?.includes(TIME_OFF_READ_ONLY_LEADER)}>
              <UpdateDetailStatus
                currentStatus={segment.status}
                requestDetailIds={[segment.time_off_request_detail_id]}
                updatedStatuses={updatedStatuses}
                setUpdatedStatuses={setUpdatedStatuses}
                updateToStatusList={segment[updateToObjProp]}
                updateRowMessage={`Update Segment ${i + 1} To:`}
                index={i + 1}
                infoMessage={infoMessage}
                key={i}
              />
            </ListItemButton>
          )
        }
      }
    })
  }

  const renderUpdateDetailStatus = () => {
    if (isRequestDetailsStatusesSame(requestDetailsWithUpdateTo)) {
      let requestDetailIds = requestDetailsWithUpdateTo?.map((segment) => {
        return segment.time_off_request_detail_id
      })
      if (
        screenAccess?.includes(TIME_OFF_PARTIAL_ACTION_LEADER) &&
        requestDetailsWithUpdateTo?.[0]?.status === 'Pending'
      ) {
        return (
          <ListItemButton disabled={buttonDisabled}>
            <UpdateDetailStatus
              currentStatus={requestDetailsWithUpdateTo?.[0]?.status}
              requestDetailIds={requestDetailIds}
              updatedStatuses={updatedStatuses}
              setUpdatedStatuses={setUpdatedStatuses}
              updateToStatusList={requestDetailsWithUpdateTo?.[0]?.[updateToObjProp]}
              updateRowMessage={`Update To:`}
              infoMessage={infoMessage}
              index={1}
            />
          </ListItemButton>
        )
      } else {
        return (
          <ListItemButton disabled={screenAccess?.includes(TIME_OFF_READ_ONLY_LEADER)}>
            <UpdateDetailStatus
              disabled={buttonDisabled}
              currentStatus={requestDetailsWithUpdateTo?.[0]?.status}
              requestDetailIds={requestDetailIds}
              updatedStatuses={updatedStatuses}
              setUpdatedStatuses={setUpdatedStatuses}
              updateToStatusList={requestDetailsWithUpdateTo?.[0]?.[updateToObjProp]}
              updateRowMessage={`Update To:`}
              infoMessage={infoMessage}
              index={1}
            />
          </ListItemButton>
        )
      }
    }
  }

  return (
    <>
      <List
        sx={{ maxHeight: '60%', padding: '10px', overflowY: 'auto' }}
        aria-label={`list of current statuses to update`}
      >
        {isUpdateStatusBySegment() ? renderUpdateDetailSegmentStatus() : renderUpdateDetailStatus()}
      </List>
      <Grid container sx={{ padding: '10px', justifyContent: 'flex-end', columnSpacing: '10px' }}>
        <Grid item>{renderLoadingSpinner()}</Grid>
        <Grid item>
          <Button
            variant="contained"
            disabled={isLoading || !isFormComplete}
            onClick={handleSubmitButton}
            data-cy={'detailPageContent-saveBtn'}
            aria-label={`save these status changes`}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

function UpdateDetailStatus({
  currentStatus,
  requestDetailIds,
  updatedStatuses,
  setUpdatedStatuses,
  updateToStatusList,
  updateRowMessage,
  index,
  infoMessage,
}) {
  return (
    <ListItem
      disableGutters
      data-cy={'detailPageContent-UpdateSegment'}
      key={index}
      secondaryAction={
        <ActionSelector
          currentStatus={currentStatus}
          requestDetailIds={requestDetailIds}
          updatedStatuses={updatedStatuses}
          setUpdatedStatuses={setUpdatedStatuses}
          updateToStatusList={updateToStatusList}
        />
      }
    >
      {infoMessage && (
        <IconButtonPopover
          ariaLabel={'update segment info message'}
          icon={<InfoOutlined sx={{ fontSize: '20px' }} />}
          popOverMessage={infoMessage}
        />
      )}{' '}
      {updateRowMessage}
    </ListItem>
  )
}

function ActionSelector({ currentStatus, requestDetailIds, updatedStatuses, setUpdatedStatuses, updateToStatusList }) {
  const [updateTo, setUpdateTo] = useState('')

  const handleChange = (event) => {
    let updatedStatusesCopy = new Map(updatedStatuses)
    for (const requestDetailId of requestDetailIds) {
      updatedStatusesCopy.set(requestDetailId, event.target.value)
    }
    setUpdatedStatuses(updatedStatusesCopy)
    setUpdateTo(event.target.value)
  }

  return (
    <TextField
      select
      variant="outlined"
      value={updateToStatusList.length > 0 ? updateTo : currentStatus}
      disabled={updateToStatusList.length === 0}
      onChange={handleChange}
      sx={{ minWidth: '135px' }}
      size="small"
      data-cy={'detailPageContent-actionSelector'}
      aria-label={`update current status to`}
    >
      {updateToStatusList.length > 0 ? (
        updateToStatusList?.map((possibleStatus, index) => (
          <MenuItem key={index} value={possibleStatus}>
            <Box display={'flex'} flexDirection={'row'}>
              <RequestStatusIcon status={possibleStatus} isTimeOffTeamMember={false} />
              <Typography paddingLeft={1}>{possibleStatus}</Typography>
            </Box>
          </MenuItem>
        ))
      ) : (
        <MenuItem key={0} value={currentStatus} disabled>
          <Box display={'flex'} flexDirection={'row'}>
            <RequestStatusIcon status={currentStatus} isTimeOffTeamMember={false} />
            <Typography paddingLeft={1}>{currentStatus}</Typography>
          </Box>
        </MenuItem>
      )}
    </TextField>
  )
}
