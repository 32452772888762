import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { REQUEST_STATUS_TYPES_STORES_PUNCH_CORRECTION, SYSTEM_NAME } from '../../../constants/RequestConstants'
//import { getFormattedTimestamp } from '../../../utils/DateUtil'
import { AccordionActions, useTheme, Grid } from '@mui/material'
import { padEmpIdWithZeros } from '../../../utils/EmployeeId'
import RequestStatusIcon from '../RequestStatusIcon'
import PunchCorrectionCancelRequest from './PunchCorrectionCancelRequest'
import PunchCorrectionRequestDetailsTable from './PunchCorrectionRequestDetailsTable'

const getStyles = (theme) => ({
  root: {
    width: '100%',
  },
  requestHeading: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  requestHeadingContainer: {
    flexDirection: 'row',
    marginTop: '5px',
    display: 'flex',
  },
  iconContainer: {
    display: 'flex',
    marginRight: '10px',
  },
  headingColumn: {
    alignSelf: 'center',
  },

  subText: {
    fontSize: 'medium',
    color: theme.infoMessages.color,
    marginBottom: '5px',
  },
  details: {
    flexDirection: 'column',
    padding: '0px 15px',
    marginBottom: '5px',
  },
  summaryMui: {
    padding: '0px 15px',
  },
  summary: {
    flex: 1,
  },
  requestDetailsExpandedHeader: {
    fontSize: 'small',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  expansionPanelActions: {
    padding: '0 15px 15px 0px',
  },
  commentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '7px',
    border: `1px solid ${theme.palette.tertiary.dark}`,
    padding: '3px',
    borderRadius: '5px',
  },
  commentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentColumn: {
    display: 'flex',
  },
  dash: {
    marginLeft: '5px',
    marginRight: '5px',
    color: theme.infoMessages.color,
  },
  smallDetail: {
    fontSize: 'small',
    color: theme.infoMessages.color,
  },
  comment: {
    fontSize: 'medium',
    color: theme.infoMessages.color,
    marginLeft: '2px',
    width: '100%',
    overflowWrap: 'anywhere',
  },
  reasonContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
  },
  reasonTitleCls: {
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    paddingRight: 5,
  },
  reasonValueCls: {
    marginTop: '10px',
    marginBottom: '10px',
    fontSize: '0.875rem',
    textAlign: 'left',
  },
})

const PunchCorrectionRequestAccordion = ({ request, workerId, lastRequestElementRef, isoTimeZone }) => {
  const outputDateFormat = 'MMMM D, YYYY' // date when accordion is collapsed
  const dateTimeFormat = 'MMM DD, YYYY h:mm a' // date and time for last updated request information when accordion is expanded
  const theme = useTheme()
  const styles = getStyles(theme)

  const renderCommentCreatedBy = (createdByWorkerId) => {
    if (workerId === createdByWorkerId) return 'You'
    else if (createdByWorkerId === SYSTEM_NAME) return SYSTEM_NAME
    else return 'Other'
  }

  const renderComments = () => {
    if (request.total_comments > 0) {
      return request.comments.map((commentObj, key) => {
        let comment = commentObj.comment.toLowerCase()
        if (comment.includes('error code:') && comment.includes('error message:')) return null // block failed messages for PTO
        if (comment.includes('review the schedule') && comment.includes('appropriate attendance')) return null // block failed messages for call off
        return (
          <div key={key} style={styles.commentsContainer}>
            <div style={styles.commentRow}>
              <div style={styles.commentColumn}>
                <Typography sx={styles.smallDetail}>
                  {renderCommentCreatedBy(padEmpIdWithZeros(commentObj.created_by_worker_id, 10))}
                </Typography>
              </div>
              <div style={styles.commentColumn}>
                <Typography sx={styles.dash}>-</Typography>
              </div>
              <div style={styles.commentColumn}>
                <Typography sx={styles.smallDetail}>
                  {moment(commentObj.created_timestamp)?.format(dateTimeFormat)}
                </Typography>
              </div>
            </div>
            <div>
              <Typography sx={styles.comment}>{commentObj.comment}</Typography>
            </div>
          </div>
        )
      })
    }
  }

  const renderRequestDates = () => {
    return <Typography sx={styles.requestHeading}>{moment(request.punch_date)?.format(outputDateFormat)}</Typography>
  }

  const renderRequestSummary = () => {
    return (
      <div style={styles.summary}>
        <div style={styles.requestHeadingContainer}>
          <div style={styles.iconContainer}>
            <RequestStatusIcon requestType={'Punch Correction'} status={request.status} isTimeOffTeamMember={false} />
          </div>
          <div style={styles.headingColumn}>{renderRequestDates()}</div>
        </div>
      </div>
    )
  }

  const isRequestEligibleForCancel = () => {
    let isEligibleForCancel = false

    if ([REQUEST_STATUS_TYPES_STORES_PUNCH_CORRECTION.PENDING].includes(request.status)) {
      isEligibleForCancel = true
    } else {
      isEligibleForCancel = false
    }
    return isEligibleForCancel
  }

  return (
    <div ref={lastRequestElementRef} style={styles.root}>
      <Accordion square data-cy={`requestAccordion_${request.request_id}`}>
        <AccordionSummary
          sx={styles.summaryMui}
          expandIcon={<ExpandMoreIcon color={'primary'} />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          {renderRequestSummary()}
        </AccordionSummary>
        <AccordionDetails sx={styles.details}>
          <PunchCorrectionRequestDetailsTable
            requestId={request.request_id}
            requestDetails={request.request_details}
            isTimeOffTeamMember={true}
          />
          {request.total_comments > 0 && (
            <div tabIndex={0}>
              <Typography sx={styles.requestDetailsExpandedHeader}>Comments:</Typography>
              <div style={styles.subText}>{renderComments()}</div>
            </div>
          )}
          {request.reason_for_correction && (
            <Grid container style={styles.reasonContainer}>
              <Grid>
                <Typography style={styles.reasonTitleCls}>{`Reason for correction: `}</Typography>
              </Grid>
              <Grid>
                <Typography style={styles.reasonValueCls}>{`${request.reason_for_correction}`}</Typography>
              </Grid>
            </Grid>
          )}
          <Typography sx={{ ...styles.smallDetail, marginTop: '10px', marginBottom: '10px' }}>
            {`Last updated ${moment.tz(request.updated_timestamp, isoTimeZone).format(dateTimeFormat)}`}
          </Typography>
        </AccordionDetails>
        {isRequestEligibleForCancel() && (
          <AccordionActions sx={styles.expansionPanelActions}>
            <PunchCorrectionCancelRequest request={request} />
          </AccordionActions>
        )}
      </Accordion>
    </div>
  )
}

export default PunchCorrectionRequestAccordion
