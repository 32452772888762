import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

/**
 * Query params used in GET /availability_audits in the availability api (for a given availability request id.
 * returns all the availability audit records in sort order with recent one first).
 *
 * @param requestId Required - String - Availability request id
 */
export function AvailabilityAuditRequestsQueryParams(requestId) {
  this.requestId = requestId

  this.getUrlSearchParams = function () {
    const params = new URLSearchParams()
    params.append('key', `${apiConfig.storeAvailability.key}`)
    if (requestId) params.append('availability_id', requestId)
    return params
  }

  this.hasRequiredParams = function () {
    return !!this.requestId
  }

  this.getQueryKey = function () {
    return ['availabilityAudits', this.requestId]
  }
}

const getAvailabilityAudits = (queryParamsString) => {
  let apiUrl = `${apiConfig.storeAvailability.url}` + `/availability_audits?` + queryParamsString
  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

export function useGetAvailabilityAuditById(requestsQueryParams) {
  const { isAuthenticated, login } = useAuth()
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  let queryParams = new AvailabilityAuditRequestsQueryParams(requestsQueryParams.requestId)

  return useQuery({
    queryKey: queryParams.getQueryKey(),
    queryFn: () => getAvailabilityAudits(queryParams.getUrlSearchParams().toString()),
    enabled: queryParams.hasRequiredParams(),
    keepPreviousData: true,
    staleTime: 1000 * 30,
    onError: (error) => {
      const loggingInfo = {
        message: 'GET /availability_audits response has an error (useGetAvailabilityAuditById).',
        url: window.location.href,
        error: {
          url: error?.config?.url,
          data: error?.response?.data,
          headers: error?.response?.headers,
          status: error?.response?.status,
          statusText: error?.response?.statusText,
          requestsQueryParams: requestsQueryParams,
        },
      }
      const optionalLoggingOptions = {
        level: LogLevel.Error,
      }
      logEvent(loggingInfo, optionalLoggingOptions)
    },
  })
}
