import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'

/**
 * In praxis, Authorization header is now automatically attached to any request going to *.target.com or *.tgt
 * DOCUMENTATION: https://praxis.prod.target.com/guides/authentication
 */
const getWorkerDetails = async (tmNumber, locationId) => {
  let apiUrl =
    `${apiConfig.worker.url}` +
    '/worker_details' +
    '?key=' +
    `${apiConfig.worker.key}` +
    '&worker_ids=' +
    tmNumber +
    '&location_id=' +
    locationId

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  return data
}

export function useWorkerDetails() {
  const { session, isAuthenticated, login } = useAuth()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  return useQuery(
    ['worker'],
    () => getWorkerDetails(padEmpIdWithZeros(session?.userInfo?.empid, 10), session.userInfo.locationid),
    {
      staleTime: 1000 * 30,
      enabled: isAuthenticated(),
    },
  )
}
