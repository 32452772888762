import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { handleSetRequestType } from '../../store/requests/actionCreator'
import HeaderTitle from '../Header/HeaderTitle'
import RequestsBody from './RequestsBody'
import RequestsHeader from './RequestsHeader'

export const NO_ACCESS_MESSAGE =
  'is currently utilized by Target Distribution Centers. Features will be rolled out in phases across the Stores network.'
const PAGE_TITLE = 'My Requests'

const Requests = () => {
  let { requestType } = useParams() // punch_correction || time_off || availability || mass_vacation

  const dispatch = useDispatch()

  const [status, setStatus] = useState('ALL')

  useEffect(() => {
    if (requestType) {
      dispatch(handleSetRequestType(requestType))
    }
  }, [dispatch, requestType])

  const handleStatusChange = (selectedStatus) => {
    setStatus(selectedStatus)
  }

  return (
    <React.Fragment>
      <HeaderTitle title={PAGE_TITLE} />
      <RequestsHeader handleStatusChange={handleStatusChange} status={status} />
      <RequestsBody status={status} />
    </React.Fragment>
  )
}

export default Requests
