import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FIXED_END_DATE_FROM_UKG } from '../../../../../../constants/AvailabilityConstants'
import { AVAILABILITY } from '../../../../../../constants/RequestConstants'
import { LEADER_DATE_HEADER_FORMAT } from '../../../../../../constants/leaderConstants'
import { setBackToComponent } from '../../../../../../store/header/actionCreator'
import { formatDateString } from '../../../../../../utils/DateUtil'
import RequestStatusIcon from '../../../../../Requests/RequestStatusIcon'
import { getAvailabilityApiData } from '../../../../CategoryDetail/AvailabilityApiData'
import { MY_TEAM_PAGE_PATH } from '../../../MyTeamPage'

export default function AvailabilityRow({ data }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { DataEnum, AvailabilityDataMap } = useMemo(() => getAvailabilityApiData(), [])

  let formattedStartDate = useMemo(
    () =>
      formatDateString(
        AvailabilityDataMap.get(DataEnum.StartDate).value(data),
        AvailabilityDataMap.get(DataEnum.StartDate).format,
        LEADER_DATE_HEADER_FORMAT,
      ),
    [AvailabilityDataMap, DataEnum.StartDate, data],
  )
  let formattedEndDate = useMemo(
    () =>
      formatDateString(
        AvailabilityDataMap.get(DataEnum.EndDate).value(data),
        AvailabilityDataMap.get(DataEnum.EndDate).format,
        LEADER_DATE_HEADER_FORMAT,
      ),
    [AvailabilityDataMap, DataEnum.EndDate, data],
  )
  if (data) {
    return (
      <ListItem disablePadding disableGutters sx={{ width: '100%', maxWidth: 640 }}>
        <ListItemButton
          sx={{ paddingX: '0px', paddingY: '3px' }}
          onClick={() => {
            dispatch(setBackToComponent(MY_TEAM_PAGE_PATH))
            navigate(`/leader/myteam/${AVAILABILITY}/${data.request_id}`, { state: { data } })
          }}
          data-cy={`TableContent-row-${data.request_id}`}
        >
          <ListItemIcon sx={{ justifyContent: 'center' }}>
            <RequestStatusIcon
              status={AvailabilityDataMap.get(DataEnum.Status).value(data)}
              isTimeOffTeamMember={false}
            />
          </ListItemIcon>
          <ListItemText
            primary={`${
              AvailabilityDataMap.get(DataEnum.EndDate).value(data) === FIXED_END_DATE_FROM_UKG
                ? formattedStartDate + ' - FOREVER'
                : formattedStartDate + ' - ' + formattedEndDate
            }`}
            secondary={
              <>
                <Typography sx={{ display: 'block' }} component="span" variant="body2" color="palette.primary.dark">
                  {AvailabilityDataMap.get(DataEnum.FullName).value(data)}
                </Typography>
                <Typography sx={{ display: 'block' }} component="span" variant="body2" color="palette.primary.dark">
                  {AvailabilityDataMap.get(DataEnum.JobClassification).value(data)}
                </Typography>
                <Typography sx={{ display: 'block' }} component="span" variant="body2" color="palette.primary.dark">
                  {AvailabilityDataMap.get(DataEnum.PatternType).value(data) + ` Availability `}
                </Typography>
                {/* <Typography sx={{ display: 'block' }} component="span" variant="body2" color="palette.primary.dark">
                {'Est. Arrival ' + formatTime(AvailabilityDataMap.get(DataEnum.EstimatedArrival).value(data))}
              </Typography> */}
              </>
            }
          />
        </ListItemButton>
      </ListItem>
    )
  } else {
    return null
  }
}
