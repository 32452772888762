import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material'

const SkeletonTable = ({ rows = 5, columns = 3 }) => {
  const createSkeletonRows = (rows, columns) => {
    const skeletonRows = []

    for (let i = 0; i < rows; i++) {
      const cells = []

      for (let j = 0; j < columns; j++) {
        cells.push(
          <TableCell key={`cell-${i}-${j}`}>
            <Skeleton variant="text" />
          </TableCell>,
        )
      }

      skeletonRows.push(<TableRow key={`row-${i}`}>{cells}</TableRow>)
    }

    return skeletonRows
  }

  return <TableBody>{createSkeletonRows(rows, columns)}</TableBody>
}

export default SkeletonTable
