import { Button, CircularProgress, Grid, Paper, Typography, useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import { useAuth } from '@praxis/component-auth'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useHistoricalCorrections } from '../../../api/constraints/useHistoricalCorrections'
import { useUserFeatures } from '../../../api/userAccess/useUserFeatures'
import {
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER_PUNCH_CORRECTION,
  SUCCESS_ON_SAVE,
  UNABLE_TO_SAVE,
} from '../../../constants/errorConstants'
import { LEADER_DATE_HEADER_FORMAT } from '../../../constants/leaderConstants'
import { LOCATION_TYPE_STORE } from '../../../constants/locationConstants'
import { showNotificationError, showNotificationSuccess } from '../../../store/notification/actionCreator'
import {
  clearPreviousState,
  postRequestsData,
  updatePunchCorrectionRequestsData,
} from '../../../store/punchCorrection/actionCreator'
import { extractTimeForPCSubmission, formatDateString, isDateBeforeCurrentWeek } from '../../../utils/DateUtil'
import { padEmpIdWithZeros } from '../../../utils/EmployeeId'
import { formatErrorCode } from '../../../utils/ErrorHandling'
import { PUNCH_CORRECTION_PARTIAL_ACTION_LEADER, PUNCH_CORRECTION_READ_ONLY_LEADER } from '../../../utils/ScreenName'
import PunchCorrectionAuditDialog from '../../AuditTrail/PunchCorrectionAuditDialog'
import RequestStatusIcon from '../../Requests/RequestStatusIcon'
import PunchCorrectionDayWiseDetails from './Categories/PunchCorrection/PunchCorrectionDayWiseDetails'
import PunchCorrectionLeaderApproveReject from './Categories/PunchCorrection/PunchCorrectionLeaderApproveReject'
import CategoryDetailDataSection from './CategoryDetailDataSection'
import { getPunchCorrectionApiData } from './PunchCorrectionApiData'

const getStyles = (theme) => ({
  readContentContainer: {
    flex: '1 1 auto',
    overflowY: 'auto',
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
    '@media (orientation: portrait) and (min-width: 640px)': {
      marginTop: '20px',
    },
    '@media (orientation: landscape) and (max-height: 400px)': {
      maxWidth: '900px',
    },
  },
  actionContentContainer: {
    ...theme.mainScrollContainer,
    maxHeight: '20%',
    textAlign: 'right',
    '@media (orientation: portrait) and (min-width: 640px)': {
      marginBottom: '20px',
    },
    '@media (orientation: landscape) and (max-height: 400px)': {
      maxWidth: '900px',
      maxHeight: '50%',
    },
  },
  details: {
    flexDirection: 'column',
    padding: '0px 15px',
    marginBottom: '5px',
  },
  markAsProcessedContainer: {
    textAlign: 'left',
    padding: '10px',
  },
  markAsProcessedBtn: {
    textAlign: 'right',
  },
  markAsProcessedText: {
    margin: '0px 0px 15px 0px',
    color: 'tertiary.contrastText',
  },
  auditLink: {
    fontSize: '0.930rem',
    margin: '0px 0 15px 7px',
    textDecoration: 'underline',
    '&:focus': {
      textDecoration: 'underline',
    },
  },
})

const getHeaderSecondary = (data, DataEnum, PunchCorrectionDataMap) => {
  let fullName = PunchCorrectionDataMap.get(DataEnum.FullName)?.value(data)
  let workerId = PunchCorrectionDataMap.get(DataEnum.WorkerId)?.value(data)

  return [`${fullName} (${workerId})`]
}

const finalStateMessage = 'This request has reached its final state and can no longer be updated.'
const failedStatusMessage =
  'Please take the necessary action in UKG and then update the Failed status below accordingly.'

const FIX_A_PUNCH_UNAVAILABLE_MESSAGE =
  'Punch corrections are unavailable. Please check back on Tuesday after the weekly payroll blackout has lifted.'
const SUPPRESSION_ON = 'ON'
const PUNCH_CORRECTION_REQUEST_ERROR_CODE = 'wfm-19-0'

const ApproveButtonCheckSuppression = (props) => {
  const { data, error } = useHistoricalCorrections()
  const theme = useTheme()

  const renderButton = () => {
    //Example data: {"HISTORICAL_CORRECTIONS_SUPPRESSION":"OFF","TIMECARD_LOCK_DATE":"2023-01-01"}
    //const data = { HISTORICAL_CORRECTIONS_SUPPRESSION: 'ON', TIMECARD_LOCK_DATE: '2023-01-01' }
    return data.HISTORICAL_CORRECTIONS_SUPPRESSION === SUPPRESSION_ON ? (
      <Typography data-cy="approveAPunchSuppressionMessage" sx={{ ...theme.infoMessages, marginBottom: '2em' }}>
        {FIX_A_PUNCH_UNAVAILABLE_MESSAGE}
      </Typography>
    ) : (
      <LeaderBottomSection detailsData={props.detailsData} />
    )
  }

  const renderSavingSpinner = () => {
    return (
      <div style={{ padding: '10px 40px 10px 40px', textAlign: 'right' }}>
        <CircularProgress size={32} />
      </div>
    )
  }
  const renderErrorMessage = () => {
    return (
      <div style={{ padding: '10px 40px 10px 40px', textAlign: 'center', color: theme.errorMessages.color }}>
        <Typography>Error loading Approve button</Typography>
      </div>
    )
  }

  const renderContent = () => {
    if (data) return renderButton()
    if (error) return renderErrorMessage()
    return renderSavingSpinner()
  }

  return <>{renderContent()}</>
}

const ApproveDenySection = (props) => {
  const { punchDate } = props
  const locationDetails = useSelector((state) => state.layout.locationDetails)

  return locationDetails.location_type === LOCATION_TYPE_STORE &&
    isDateBeforeCurrentWeek(punchDate, locationDetails.iso_time_zone_code) ? (
    <ApproveButtonCheckSuppression {...props} />
  ) : (
    <LeaderBottomSection detailsData={props.detailsData} />
  )
}

const LeaderBottomSection = (props) => {
  const dispatch = useDispatch()
  const { session } = useAuth()
  const locationDetails = useSelector((state) => state.layout.locationDetails)
  const { detailsData } = props
  const theme = useTheme()
  const styles = getStyles(theme)

  const { data: userFeatures } = useUserFeatures()
  const screenAccess = userFeatures?.screenAccess
  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    if (screenAccess?.includes(PUNCH_CORRECTION_READ_ONLY_LEADER)) {
      setButtonDisabled(true)
      return
    }
    if (
      screenAccess?.includes(PUNCH_CORRECTION_PARTIAL_ACTION_LEADER) &&
      detailsData.status.toLowerCase() === 'pending'
    ) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderActions = () => {
    if (detailsData.status.toLowerCase() === 'pending') {
      return renderApproveCancel()
    } else if (detailsData.status.toLowerCase() === 'failed') {
      return renderProcessed()
    } else {
      return renderEndStateMessage()
    }
  }

  const renderApproveCancel = () => {
    return (
      <Paper elevation={1} square>
        <PunchCorrectionLeaderApproveReject
          approveClicked={approveClicked}
          denyClicked={denyClicked}
          buttonDisabled={buttonDisabled}
        />
      </Paper>
    )
  }

  const renderEndStateMessage = () => {
    return (
      <>
        <Typography sx={{ padding: '10px', textAlign: 'center', color: 'tertiary.contrastText' }}>
          {finalStateMessage}
        </Typography>
      </>
    )
  }

  const renderProcessed = () => {
    return (
      <Paper elevation={1} square sx={styles.markAsProcessedContainer}>
        <Grid container>
          <Grid>
            <Typography sx={styles.markAsProcessedText}>{failedStatusMessage}</Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={styles.markAsProcessedBtn}>
            <Button
              data-cy="punchCorrectionMarkAsProcessed"
              variant="contained"
              color="primary"
              onClick={processedClicked}
              disabled={screenAccess?.includes(PUNCH_CORRECTION_READ_ONLY_LEADER)}
            >
              Processed
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const approveClicked = () => {
    callApprove('Approved', 9)
  }

  const processedClicked = () => {
    callApi('Processed', 7)
  }

  const denyClicked = () => {
    callApi('Denied', 4)
  }

  const callApprove = (status, statusId) => {
    let finalData = {
      request_id: detailsData.request_id,
      punch_date: detailsData.punch_date,
      worker_id: detailsData.updated_by_worker_id,
      location_id: locationDetails.location_id,
      location_type: locationDetails.location_type,
      reason_for_correction: detailsData.reason_for_correction,
      status: status,
      status_id: statusId,
      status_reason: 'Waiting for approval',
      manager_email: '',
      is_labor_transfer_exist: false,
      updated_by_worker_id: session?.userInfo?.empid,
      created_timestamp: detailsData.created_timestamp,
      request_details: formatData(detailsData.request_details),
    }

    let requestDetailsIds = []
    detailsData.request_details.forEach((detail) => {
      if (detail?.request_detail_id) requestDetailsIds.push(detail.request_detail_id)
    })

    if (requestDetailsIds.length === detailsData.request_details.length) {
      dispatch(postRequestsData(finalData, ''))
    } else {
      dispatch(showNotificationError(true, 'System error occurred. Please refresh the page and try again.'))
    }
  }

  const formatData = (punchCorrectionData) => {
    const finalEntries = []

    punchCorrectionData.forEach((item) => {
      const punchObj = {}
      punchObj.request_detail_id = item.request_detail_id
      if (item.action === 'Add') {
        punchObj.action = item.action.toUpperCase()
        punchObj.new_punch_date = item.new_punch_date
        punchObj.new_punch_time = extractTimeForPCSubmission(item.new_punch_time)
        punchObj.new_punch_type = item.new_punch_type
      } else if (item.action === 'Edit') {
        punchObj.action = item.action.toUpperCase()
        punchObj.existing_punch_date = item.existing_punch_date
        punchObj.existing_punch_time = extractTimeForPCSubmission(item.existing_punch_time)
        punchObj.new_punch_date = item.new_punch_date
        punchObj.new_punch_time = extractTimeForPCSubmission(item.new_punch_time)
        punchObj.new_punch_type = item.new_punch_type
      } else if (item.action === 'Delete') {
        punchObj.action = item.action.toUpperCase()
        punchObj.delete_existing_punch = true
        punchObj.existing_punch_date = item.existing_punch_date
        punchObj.existing_punch_time = extractTimeForPCSubmission(item.existing_punch_time)
        punchObj.new_punch_type = item.new_punch_type
      }
      finalEntries.push(punchObj)
    })

    return finalEntries
  }

  const callApi = (status, statusId) => {
    const requestDetailsIds = []
    detailsData.request_details.forEach((detail) => {
      requestDetailsIds.push(detail.request_detail_id)
    })

    let finalData = {
      request_id: detailsData.request_id,
      request_detail_id: requestDetailsIds,
      location_id: locationDetails.location_id,
      //comment: 'testing...',
      status: status,
      status_id: statusId,
      updated_by_worker_id: padEmpIdWithZeros(session?.userInfo?.empid, 10),
    }

    let requestDetailsIdsNew = []
    detailsData.request_details.forEach((detail) => {
      if (detail?.request_detail_id) requestDetailsIdsNew.push(detail.request_detail_id)
    })

    if (requestDetailsIdsNew.length === detailsData.request_details.length) {
      dispatch(updatePunchCorrectionRequestsData(finalData))
    } else {
      dispatch(showNotificationError(true, 'System error occurred. Please refresh the page and try again.'))
    }
  }

  return <>{renderActions()}</>
}

const PunchCorrectionDetailPage = ({ detailsData, getLabelValueList }) => {
  const { DataEnum, PunchCorrectionDataMap } = useMemo(() => getPunchCorrectionApiData(), [])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const userPunchCorrectionUpdateResponse = useSelector(
    (state) => state.punchCorrection.userPunchCorrectionUpdateResponse,
  )
  const userPunchCorrectionUpdateErrResponse = useSelector(
    (state) => state.punchCorrection.userPunchCorrectionUpdateErrResponse,
  )

  const punchCorrectionPostResponse = useSelector((state) => state.punchCorrection.punchCorrectionPostResponse)
  const punchCorrectionPostErrorResponse = useSelector(
    (state) => state.punchCorrection.punchCorrectionPostErrorResponse,
  )

  const theme = useTheme()
  const styles = getStyles(theme)
  const [openAudit, setOpenAudit] = useState(false)

  /*Handling Approve response */
  useEffect(() => {
    if (punchCorrectionPostResponse) {
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
      dispatch(clearPreviousState())
      navigate('/leader/dashboard')
      queryClient.invalidateQueries(['requests'])
    } else if (punchCorrectionPostErrorResponse) {
      if (
        punchCorrectionPostErrorResponse.code &&
        punchCorrectionPostErrorResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(punchCorrectionPostErrorResponse.code)
      ) {
        dispatch(showNotificationError(false, parseErrorList(punchCorrectionPostErrorResponse)))
      } else {
        dispatch(
          showNotificationError(
            false,
            UNABLE_TO_SAVE +
              ' ' +
              formatErrorCode(punchCorrectionPostErrorResponse, ERROR_CODE_CANNOT_CONNECT_TO_SERVER_PUNCH_CORRECTION),
          ),
        )
      }

      dispatch(clearPreviousState())
      navigate('/leader/myteam')
    }
  }, [punchCorrectionPostResponse, punchCorrectionPostErrorResponse, dispatch, queryClient, navigate])

  const parseErrorList = (errResponse) => {
    let errorMessages = ''
    let message

    if (errResponse.errors && errResponse.errors.length > 0) {
      for (let error of errResponse.errors) {
        errorMessages += '<li>' + error + '</li>'
      }
      message = errResponse.message + '<br /><ul>' + errorMessages + '</ul>'
    } else {
      message = errResponse.message
    }

    return message
  }

  /* Handle Update Sucess and failure case */
  useEffect(() => {
    if (userPunchCorrectionUpdateResponse) {
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
      dispatch(clearPreviousState())
      navigate('/leader/dashboard')
      queryClient.invalidateQueries(['requests'])
    } else if (userPunchCorrectionUpdateErrResponse) {
      if (
        userPunchCorrectionUpdateErrResponse.code &&
        userPunchCorrectionUpdateErrResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(userPunchCorrectionUpdateErrResponse.code)
      ) {
        dispatch(showNotificationError(false, userPunchCorrectionUpdateErrResponse.message))
      } else {
        dispatch(
          showNotificationError(
            false,
            UNABLE_TO_SAVE +
              ' ' +
              formatErrorCode(userPunchCorrectionUpdateErrResponse, PUNCH_CORRECTION_REQUEST_ERROR_CODE),
          ),
        )
      }
      dispatch(clearPreviousState())
      navigate('/leader/myteam')
    }
  }, [userPunchCorrectionUpdateResponse, userPunchCorrectionUpdateErrResponse, dispatch, queryClient, navigate])

  let formattedPunchDate = useMemo(
    () =>
      formatDateString(
        PunchCorrectionDataMap.get(DataEnum.PunchDate).value(detailsData),
        PunchCorrectionDataMap.get(DataEnum.PunchDate).format,
        LEADER_DATE_HEADER_FORMAT,
      ),
    [PunchCorrectionDataMap, DataEnum.PunchDate, detailsData],
  )

  const handleDialogOpen = () => {
    setOpenAudit(true)
  }

  const handleAuditDialogClose = () => {
    setOpenAudit(false)
  }

  return (
    <>
      {openAudit && (
        <PunchCorrectionAuditDialog
          requestId={PunchCorrectionDataMap.get(DataEnum.RequestId).value(detailsData)}
          statusIcon={
            <RequestStatusIcon
              status={PunchCorrectionDataMap.get(DataEnum.Status).value(detailsData)}
              isTimeOffTeamMember={false}
            />
          }
          headerPrimary={formattedPunchDate}
          headerSecondary={getHeaderSecondary(detailsData, DataEnum, PunchCorrectionDataMap)}
          auditDialogOpen={openAudit}
          handleClose={handleAuditDialogClose}
        />
      )}
      <Paper sx={styles.readContentContainer} elevation={1} square data-cy={'detailPageContent-detailPage'}>
        <CategoryDetailDataSection
          statusIcon={
            <RequestStatusIcon
              status={PunchCorrectionDataMap.get(DataEnum.Status).value(detailsData)}
              isTimeOffTeamMember={false}
            />
          }
          headerPrimary={formattedPunchDate}
          headerSecondary={getHeaderSecondary(detailsData, DataEnum, PunchCorrectionDataMap)}
          labelValueList={getLabelValueList(detailsData, DataEnum, PunchCorrectionDataMap)}
        />

        <Button data-cy="view-full-audit-report-btn" sx={{ ...styles.auditLink }} onClick={handleDialogOpen}>
          View full audit report
        </Button>

        <div style={styles.details}>
          <PunchCorrectionDayWiseDetails
            requestDetails={PunchCorrectionDataMap.get(DataEnum.RequestDetails)?.value(detailsData)}
            isTimeOffTeamMember={false}
          />
        </div>
        {/* <CommentSection comments={comments} /> */}
      </Paper>
      <Paper
        sx={styles.actionContentContainer}
        elevation={1}
        square
        component="h3"
        aria-label={'Update status section'}
      >
        <Divider aria-hidden="true" />
        <ApproveDenySection
          detailsData={detailsData}
          punchDate={new Date(PunchCorrectionDataMap.get(DataEnum.PunchDate).value(detailsData))}
        />
      </Paper>
    </>
  )
}

export default PunchCorrectionDetailPage
