//import MenuItem from '@mui/material/MenuItem'
//import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React from 'react'
import { getDateTimeWithNoTimezone } from '../../../utils/DateUtil'
import RequestStatusIcon from '../RequestStatusIcon'

const getStyles = (theme) => ({
  subText: {
    fontSize: 'medium',
    color: theme.infoMessages.color,
  },
  requestDetailsExpandedColNames: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  requestDetailsColName: {
    flex: 1,
    fontSize: 'small',
    fontWeight: 'bold',
  },
  requestDetailsExpanded: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  requestDetails: {
    flex: 1,
    fontSize: 'medium',
    color: theme.infoMessages.color,
    paddingTop: '2px',
    paddingBottom: '3px',
  },
  datesExpanded: {
    margin: '5px 0',
  },
  requestHeading: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  details: {
    flexDirection: 'column',
    padding: '0px 15px',
    marginBottom: '5px',
  },
  summary: {
    flex: 1,
  },
  statusTextField: {
    marginLeft: '5px',
  },
  statusListItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  root: {
    '& .MuiFilledInput-inputHiddenLabel': {
      paddingTop: 3,
      paddingBottom: 2,
    },
    '& .MuiInputBase-input': {
      paddingTop: 3,
      paddingBottom: 2,
    },
  },
  '@media (max-width: 320px)': {
    requestDetails: {
      textAlign: 'center',
    },
  },
  requestDetailsForAudit: {
    flex: 1,
    fontSize: 'smaller',
    color: theme.infoMessages.color,
    paddingTop: '2px',
    paddingBottom: '3px',
  },
})

const PunchCorrectionRequestDetailsTable = ({ requestId, requestDetails, isTimeOffTeamMember, isAudit }) => {
  const subOutputDateFormat = isTimeOffTeamMember || isAudit ? 'MM/DD' : 'MM/DD/YY' // date for request information when accordion is expanded
  const startTimeOutputDateFormat = 'h:mm a' // time for request information when accordion is expanded
  const theme = useTheme()
  const styles = getStyles(theme)

  const renderRequestDetailsHeader = () => {
    return (
      <div tabIndex={0} aria-label="list of request details" style={styles.requestDetailsExpandedColNames}>
        <Typography sx={styles.requestDetailsColName} style={{ flex: 0.75 }}>
          Date
        </Typography>
        <Typography id="punch_time_header" sx={styles.requestDetailsColName} style={{ flex: 1 }}>
          {isAudit ? 'Time' : 'Punch Time'}
        </Typography>
        <Typography id="type_header" sx={styles.requestDetailsColName} style={{ flex: 1.25 }}>
          Type
        </Typography>
        <Typography sx={styles.requestDetailsColName} style={{ flex: 0.5 }}>
          Action
        </Typography>
        <Typography sx={styles.requestDetailsColName} style={{ flex: 0.35 }}>
          Status
        </Typography>
      </div>
    )
  }

  const ariaLabelDate = (punchDate) => {
    var givenDate = moment(punchDate)

    // var day = givenDate.format('dddd')
    var month = givenDate.format('MMMM')
    var date = givenDate.format('D')

    return `${month} ${date}`
  }

  const renderRequestDetail = (detail, index) => {
    let punchDate = ''
    let punchTime = ''
    if (detail.delete_existing_punch) {
      punchDate = moment(detail.existing_punch_date)
      punchTime = moment(getDateTimeWithNoTimezone(detail.existing_punch_time))
    } else {
      punchDate = moment(detail.new_punch_date)
      punchTime = moment(getDateTimeWithNoTimezone(detail.new_punch_time))
    }

    return (
      <div key={index} style={styles.requestDetailsExpanded}>
        <Typography
          tabIndex={0}
          aria-label={`date ${ariaLabelDate(punchDate)}`}
          sx={isAudit ? styles.requestDetailsForAudit : requestDetails}
          style={{ flex: 0.75 }}
        >
          {punchDate?.format(subOutputDateFormat)}
        </Typography>
        <Typography
          tabIndex={0}
          aria-labelledby={`punch_time_header punch_time_val_${requestId}_${index}`}
          id={`punch_time_val_${requestId}_${index}`}
          sx={isAudit ? styles.requestDetailsForAudit : requestDetails}
          style={{ flex: 1 }}
        >
          {punchTime?.format(startTimeOutputDateFormat)}
        </Typography>
        <Typography
          sx={isAudit ? styles.requestDetailsForAudit : requestDetails}
          tabIndex={0}
          aria-labelledby={`type_header type_val_${requestId}_${index}`}
          id={`type_val_${requestId}_${index}`}
          style={{ flex: 1.25, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          {detail.new_punch_type}
        </Typography>
        <Typography
          tabIndex={0}
          aria-label={`action ${detail.action?.toLowerCase()}`}
          sx={isAudit ? styles.requestDetailsForAudit : requestDetails}
          style={{ flex: 0.5 }}
        >
          {detail.action?.toUpperCase()}
        </Typography>
        {renderStatusDetailForTeamMember(detail)}
      </div>
    )
  }

  const renderStatusDetailForTeamMember = (detail, index) => {
    return (
      <div style={{ flex: 0.35 }}>
        <RequestStatusIcon
          key={index}
          requestType={'Punch Correction'}
          status={detail.status}
          isTimeOffTeamMember={false}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderRequestDetailsHeader()}
      <div style={styles.subText}>
        <div style={styles.datesExpanded}>
          {requestDetails.map((detail, index) => renderRequestDetail(detail, index))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PunchCorrectionRequestDetailsTable
