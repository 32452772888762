import { Checkbox, FormControlLabel } from '@mui/material'

const styles = {
  Box: {
    width: 17,
    height: 17,
  },
}

const CheckBoxTME = (props) => {
  const { handleChange, checked, label, indeterminate, disabled } = props

  const onValChange = (event) => {
    handleChange(event.target.checked)
  }

  return (
    <FormControlLabel
      aria-label={label}
      label={label}
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      control={
        <Checkbox
          checked={checked}
          icon={<TmeCheckBoxBorder />}
          checkedIcon={!disabled ? <TmeCheckBoxChecked /> : <TmeCheckBoxDisabled />}
          indeterminateIcon={<TmeCheckBoxIndeterminate />}
          onChange={(event) => onValChange(event)}
          indeterminate={indeterminate}
          disabled={disabled}
        />
      }
    />
  )
}

export const TmeCheckBoxBorder = () => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Rectangle</title>
      <g id="checkboxBorder" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
        <rect id="Rectangle" stroke="#979797" fill="#FFFFFF" x="0.5" y="0.5" style={styles.Box}></rect>
      </g>
    </svg>
  )
}

export const TmeCheckBoxChecked = () => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>checkboxBorder</title>
      <g id="checkboxBorder" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
        <rect id="Rectangle" stroke="#979797" strokeWidth="2" fill="#FFFFFF" x="0.5" y="0.5" style={styles.Box}></rect>
        <line
          x1="4.2"
          y1="10.7"
          x2="6.3"
          y2="12.7"
          id="Line"
          stroke="#CC0000"
          strokeWidth="2.3"
          strokeLinecap="square"
        ></line>
        <line
          x1="6.5"
          y1="12.5"
          x2="13.5"
          y2="5"
          id="Line-2"
          stroke="#CC0000"
          strokeWidth="2.3"
          strokeLinecap="square"
        ></line>
      </g>
    </svg>
  )
}

export const TmeCheckBoxIndeterminate = () => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>checkboxBorder</title>
      <g id="checkboxBorder" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
        <rect id="Rectangle" stroke="#979797" strokeWidth="2" fill="#FFFFFF" x="0.5" y="0.5" style={styles.Box}></rect>
        <line x1="6" y1="9" x2="12" y2="9" id="Line-2" stroke="#CC0000" strokeWidth="2.3" strokeLinecap="square"></line>
      </g>
    </svg>
  )
}

export const TmeCheckBoxDisabled = () => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>checkboxBorder</title>
      <g id="checkboxBorder" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
        <rect id="Rectangle" stroke="#979797" strokeWidth="2" fill="#FFFFFF" x="0.5" y="0.5" style={styles.Box}></rect>
        <line
          x1="4.2"
          y1="10.7"
          x2="6.3"
          y2="12.7"
          id="Line"
          stroke="#999"
          strokeWidth="2.3"
          strokeLinecap="square"
        ></line>
        <line
          x1="6.5"
          y1="12.5"
          x2="13.5"
          y2="5"
          id="Line-2"
          stroke="#999"
          strokeWidth="2.3"
          strokeLinecap="square"
        ></line>
      </g>
    </svg>
  )
}

export default CheckBoxTME
