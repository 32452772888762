import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useCommunication } from '../../../api/userProfiles/useCommunication'
import { showNotificationError } from '../../../store/notification/actionCreator'
import LinearLoader from '../../common/loaders/LinearLoader'
import SkeletonTableBody from '../../common/loaders/SkeletonTableBody'

export default function CommunicationTable({ handleEdit }) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const { data, isFetching, isLoading, isRefetchError } = useCommunication(false, page + 1, rowsPerPage)
  const communications = data?.data
  const dispatch = useDispatch()

  useEffect(() => {
    if (isRefetchError) {
      dispatch(showNotificationError(true, 'Error loading communications'))
    }
  }, [dispatch, isRefetchError])

  return (
    <Paper sx={{ width: '100%', position: 'relative' }}>
      {isFetching ? <LinearLoader /> : null}
      <TableContainer sx={{ maxHeight: '800px' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Locations</TableCell>
              <TableCell>Features</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          {communications ? (
            <TableBody>
              {communications?.communications?.map((communication) => (
                <TableRow key={communication?.id}>
                  <TableCell sx={{ minWidth: '250px' }}>{communication?.title}</TableCell>
                  <TableCell>
                    {communication?.start_timestamp &&
                      format(new Date(communication?.start_timestamp), 'eee M/d/yy h:mm aaa')}
                  </TableCell>
                  <TableCell>
                    {communication?.end_timestamp &&
                      format(new Date(communication?.end_timestamp), 'eee M/d/yy h:mm aaa')}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={communication?.audience_locations.join(', ')}>
                      <Typography variant="body1" fontSize={'14px'} sx={{ maxHeight: '90px', overflow: 'scroll' }}>
                        {communication?.audience_locations.join(', ')}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={communication?.audience_features.join(', ')}>
                      <Typography variant="body1" fontSize={'14px'} sx={{ maxHeight: '90px', overflow: 'scroll' }}>
                        {communication?.audience_features.join(', ')}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={communication?.audience_roles.join(', ')}>
                      <Typography variant="body1" fontSize={'14px'} sx={{ maxHeight: '90px', overflow: 'scroll' }}>
                        {communication?.audience_roles.join(', ')}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={communication.text}>
                      <Typography variant="body1" fontSize={'14px'} sx={{ maxHeight: '90px', overflow: 'scroll' }}>
                        {communication.text}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleEdit(communication)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : isLoading || isFetching ? (
            <SkeletonTableBody columns={8} />
          ) : (
            <></>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={Number(data?.headers?.['x-total-count']) || 0}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
      />
    </Paper>
  )
}
