import { useLocationDetails } from '../../../../../api/location/useLocationDetails'
import { useUserFeatures } from '../../../../../api/userAccess/useUserFeatures'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { getConfigDetails } from '../../../../../constants/leaderConstants'
import { setControlPanelSettings } from '../../../../../store/leader/actionCreator'
import { CATEGORY_FEATURE_MAP, FAILED, PENDING, TIME_OFF } from '../../../../../constants/RequestConstants'
import { Divider } from '@mui/material'
import TotalTimeOffRequestsByStatusButton from '../../TotalTimeOffRequestsByStatusButton'

const TimeOffDailyActions = () => {
  const { data: locationDetails } = useLocationDetails()
  const { data: userFeatures } = useUserFeatures()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { configMap } = useMemo(() => getConfigDetails(locationDetails, userFeatures), [locationDetails, userFeatures])

  const handleTimeOffRequestsButtonClick = (statuses) => {
    let filterOverrides = { statuses: { selectedValues: statuses } }

    dispatch(
      setControlPanelSettings(
        TIME_OFF,
        configMap[TIME_OFF].defaultStartDate,
        configMap[TIME_OFF].defaultEndDate,
        filterOverrides,
        configMap[TIME_OFF].searchText,
        configMap[TIME_OFF].selectedSortByIndex,
        configMap[TIME_OFF].sortOrder,
      ),
    )
    navigate('/leader/myteam')
  }

  return (
    <>
      <Divider aria-hidden="true" />
      <TotalTimeOffRequestsByStatusButton
        status={PENDING}
        startDate={configMap[TIME_OFF].defaultStartDate}
        endDate={configMap[TIME_OFF].defaultEndDate}
        handleButtonClick={() => handleTimeOffRequestsButtonClick(['Pending'])}
        buttonMessage={'Pending Time Off Requests'}
        selectedCategory={CATEGORY_FEATURE_MAP.get(TIME_OFF)}
        categoryName={TIME_OFF}
      />
      <Divider aria-hidden="true" />
      <TotalTimeOffRequestsByStatusButton
        status={FAILED}
        startDate={configMap[TIME_OFF].defaultStartDate}
        endDate={configMap[TIME_OFF].defaultEndDate}
        handleButtonClick={() => handleTimeOffRequestsButtonClick(['Failed', 'Failed Cancelled'])}
        buttonMessage={'Failed Time Off Requests'}
        selectedCategory={CATEGORY_FEATURE_MAP.get(TIME_OFF)}
        categoryName={TIME_OFF}
      />
    </>
  )
}

export default TimeOffDailyActions
