import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const CANCEL_DIALOG_TITLE = 'Alert!'

const AvailabilityAlertModal = ({ alertDialogOpen, handleOkForAlertAction, alertMsg }) => {
  const handleOk = () => {
    handleOkForAlertAction()
  }

  return (
    <Dialog
      data-cy="requestTimeOffCancelDialog"
      open={alertDialogOpen}
      onClose={handleOk}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{CANCEL_DIALOG_TITLE}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alertMsg === 'FormNotStartedErrMsg' && (
            <DialogContentText id="alert-dialog-description">
              No availability selected. You must have at least one day of availability information filled in to proceed.
            </DialogContentText>
          )}
          {alertMsg === 'FormIncompleteErrMsg' && (
            <DialogContentText id="alert-dialog-description">
              You have unresolved errors on the screen. Please resolve before submitting.
            </DialogContentText>
          )}
          {/* {alertMsg === 'Week2DataNotFilledErrMsg' && 
            <DialogContentText id="alert-dialog-description">
              You have no details in week 2. If you really dont need, opt out week 2 option.
            </DialogContentText>
          } */}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <span>
          <Button onClick={handleOk} color="primary" aria-label="No">
            OK
          </Button>
        </span>
      </DialogActions>
    </Dialog>
  )
}

export default AvailabilityAlertModal
