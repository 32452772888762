import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionActions, Grid, useTheme } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  CALL_IN_LATE,
  CALL_IN_OFF_PREFIX,
  CALL_OFF_ABSENT,
  DENIED,
  FAILED,
  FAILED_CANCELLED,
  PROCESSED,
  PROCESSING,
  PTO_PREFIX,
  REQUEST_TYPES,
  REQUEST_TYPE_MASS_VACATION,
  SYSTEM_NAME,
  TIME_OFF,
  TIME_OFF_TYPES,
  UPDATE_TO_CANCELLED_CONFIG,
} from '../../constants/RequestConstants'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import CancelRequest from './CancelRequest'
import RequestDetailsTable from './RequestDetailsTable'
import RequestStatusIcon from './RequestStatusIcon'
import { LOCATION_TYPE_OFFICE, LOCATION_TYPE_RGD } from '../../constants/locationConstants'
import { useUser } from '../../auth/hooks/useUser'

const getStyles = (theme) => ({
  root: {
    width: '100%',
  },
  requestHeading: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  requestHeadingContainer: {
    flexDirection: 'row',
    marginTop: '5px',
    display: 'flex',
  },
  iconContainer: {
    display: 'flex',
    marginRight: '10px',
  },
  headingColumn: {
    alignSelf: 'center',
  },
  subHeading: {
    fontSize: 'small',
    color: theme.infoMessages.color,
  },
  subText: {
    fontSize: 'medium',
    color: theme.infoMessages.color,
    marginBottom: '5px',
  },
  details: {
    flexDirection: 'column',
    padding: '0px 15px',
    marginBottom: '5px',
  },
  summary: {
    flex: 1,
  },
  requestDetailsExpandedColNames: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  requestDetailsColName: {
    flex: 1,
    fontSize: 'small',
    fontWeight: 'bold',
  },
  requestDetailsExpanded: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  requestDetails: {
    flex: 1,
    fontSize: 'medium',
    color: theme.infoMessages.color,
    [theme.breakpoints.down['sm']]: {
      textAlign: 'center',
    },
  },
  requestDetailsExpandedHeader: {
    fontSize: 'small',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  requestDetailNotification: {
    fontSize: 'medium',
    color: theme.palette.primary.main,
    marginTop: '5px',
    marginBottom: '5px',
  },
  datesExpanded: {
    margin: '5px 0',
  },
  expansionPanelActions: {
    padding: '0 15px 15px 0px',
  },

  commentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '7px',
    border: `1px solid ${theme.palette.tertiary.dark}`,
    padding: '3px',
    borderRadius: '5px',
  },
  commentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentColumn: {
    display: 'flex',
  },
  dash: {
    marginLeft: '5px',
    marginRight: '5px',
    color: theme.infoMessages.color,
  },
  smallDetail: {
    fontSize: 'small',
    color: theme.infoMessages.color,
  },
  comment: {
    fontSize: 'medium',
    color: theme.infoMessages.color,
    marginLeft: '2px',
    width: '100%',
    overflowWrap: 'anywhere',
  },
})

const RequestAccordion = ({ request, workerId, lastRequestElementRef, isoTimeZone }) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const outputDateFormat = 'MMM D, YYYY' // date when accordion is collapsed
  const dateTimeFormat = 'MMM DD, YYYY h:mm a' // date and time for last updated request information when accordion is expanded
  const [requestLevelIcons, setRequestLevelIcons] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [uniqueStatusArray, setUniqueStatusArray] = useState([])
  const { locationData } = useUser().user

  useEffect(() => {
    if (request) {
      let statusSet = new Set(
        request.request_details.map((detail) => {
          return detail.status
        }),
      )
      setIsProcessing(false)
      let statusArray = [...statusSet]
      setUniqueStatusArray(statusArray)
      setRequestLevelIcons([statusArray[0]])
      if (statusArray.length > 1 && !statusSet.has(FAILED_CANCELLED)) {
        if (statusSet.has(FAILED)) {
          setIsProcessing(true)
        } else {
          if (statusArray.every((elem) => [PROCESSED, DENIED].includes(elem))) {
            setRequestLevelIcons([PROCESSED, DENIED])
          } else {
            setRequestLevelIcons([PROCESSING])
            setIsProcessing(true)
          }
        }
      }
    }
  }, [request])

  const renderCommentCreatedBy = (createdByWorkerId) => {
    if (workerId === createdByWorkerId) return 'You'
    else if (createdByWorkerId === SYSTEM_NAME) return SYSTEM_NAME
    else return 'Other'
  }

  const renderComments = () => {
    if (request.total_comments > 0) {
      return request.comments.map((commentObj, key) => {
        let comment = commentObj.comment.toLowerCase()
        if (comment.includes('error code:') && comment.includes('error message:')) return null // block failed messages for PTO
        if (comment.includes('review the schedule') && comment.includes('appropriate attendance')) return null // block failed messages for call off
        return (
          <div key={key} style={styles.commentsContainer}>
            <div style={styles.commentRow}>
              <div style={styles.commentColumn}>
                <Typography sx={styles.smallDetail}>
                  {renderCommentCreatedBy(padEmpIdWithZeros(commentObj.created_by_worker_id, 10))}
                </Typography>
              </div>
              <div style={styles.commentColumn}>
                <Typography sx={styles.dash}>-</Typography>
              </div>
              <div style={styles.commentColumn}>
                <Typography sx={styles.smallDetail}>
                  {moment.tz(commentObj.created_timestamp, isoTimeZone)?.format(dateTimeFormat)}
                </Typography>
              </div>
            </div>
            <div>
              <Typography sx={styles.comment}>{commentObj.comment}</Typography>
            </div>
          </div>
        )
      })
    }
  }

  const massVacationSubtitle = () => {
    let isMassVacation = request.request_type === REQUEST_TYPES[REQUEST_TYPE_MASS_VACATION].type

    return isMassVacation ? (
      <Grid item container spacing={1}>
        <Grid item>
          <Typography sx={{ fontSize: 'small' }} color={'tertiary'}>
            From Mass Vacation
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <></>
    )
  }

  const renderRequestDates = () => {
    if (request.start_date === request.end_date) {
      return <Typography sx={styles.requestHeading}>{moment(request.start_date)?.format(outputDateFormat)}</Typography>
    } else {
      return (
        <Typography sx={styles.requestHeading}>
          {`${moment(request.start_date)?.format(outputDateFormat)} - ${moment(request.end_date)?.format(
            outputDateFormat,
          )}`}
        </Typography>
      )
    }
  }

  const hasHoursBeforeShiftViolation = (hoursBeforeShiftConfig) => {
    if (
      hoursBeforeShiftConfig?.locationTypes &&
      hoursBeforeShiftConfig?.value &&
      hoursBeforeShiftConfig.locationTypes.includes(locationData.location_type)
    ) {
      let startDateOfRequest = moment(request?.request_details[0]?.start_time).tz(isoTimeZone)
      let nowDate = moment().tz(isoTimeZone)
      let hoursFromNow = startDateOfRequest.diff(nowDate, 'millisecond') / (1000 * 60 * 60)

      return hoursFromNow <= hoursBeforeShiftConfig.value
    } else {
      return false
    }
  }

  const hasAllowedTypesViolation = (allowedTypes, requestDetails, status) => {
    if (allowedTypes) {
      let typesByStatus = requestDetails.map((detail) => {
        return detail.status === status ? detail.type : null
      })
      return !typesByStatus.some((i) => allowedTypes.indexOf(i) >= 0)
    }
  }

  const hasCancelledConfigViolation = (status, cancelledConfigByCategory, requestDetails) => {
    let config = cancelledConfigByCategory[status]
    return !(
      config &&
      !hasHoursBeforeShiftViolation(config['hoursBeforeShift']) &&
      !hasAllowedTypesViolation(config['allowedTypes'], requestDetails, status)
    )
  }

  const getRequestCategoryType = () => {
    // ALL detail types in a request will be the same category, so only need to use the first detail's type
    let type = request.request_details[0].type
    if (type.startsWith(CALL_IN_OFF_PREFIX)) return CALL_IN_LATE
    if (type.startsWith(CALL_IN_OFF_PREFIX)) return CALL_OFF_ABSENT
    if (type.startsWith(PTO_PREFIX) || type === TIME_OFF_TYPES.UNPAID) return TIME_OFF
  }

  const isRequestEligibleForCancel = () => {
    let category = getRequestCategoryType()
    let cancelledConfigByCategory = UPDATE_TO_CANCELLED_CONFIG[category]

    return !uniqueStatusArray.some((status) =>
      hasCancelledConfigViolation(status, cancelledConfigByCategory, request.request_details),
    )
  }

  return (
    <div ref={lastRequestElementRef} style={styles.root}>
      <Accordion square data-cy={`requestAccordion_${request.request_id}`}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color={'primary'} />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          sx={{ paddingX: 1 }}
        >
          <Grid container item flexShrink={11} alignContent={'center'}>
            {requestLevelIcons.map((detailStatus, i) => {
              return (
                <RequestStatusIcon
                  key={i}
                  requestType={request.request_details[0].type}
                  status={detailStatus}
                  isTimeOffTeamMember={true}
                  showFailed={[LOCATION_TYPE_OFFICE, LOCATION_TYPE_RGD].includes(locationData.location_type)}
                />
              )
            })}
          </Grid>
          <Grid container flexDirection={'column'}>
            <Grid item>{renderRequestDates()}</Grid>
            {massVacationSubtitle()}
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={styles.details}>
          {isProcessing ? (
            <Typography sx={styles.requestDetailNotification}>This request is still processing.</Typography>
          ) : (
            <></>
          )}
          <RequestDetailsTable requestDetails={request.request_details} isTimeOffTeamMember={true} />
          {request.total_comments > 0 && (
            <>
              <Typography sx={styles.requestDetailsExpandedHeader}>Comments:</Typography>
              <div style={styles.subText}>{renderComments()}</div>
            </>
          )}
          <Typography sx={styles.smallDetail} style={{ marginTop: '10px', marginBottom: '10px' }}>
            {`Last updated ${moment.tz(request.updated_timestamp, isoTimeZone).format(dateTimeFormat)}`}
          </Typography>
        </AccordionDetails>
        {uniqueStatusArray.length > 0 && isRequestEligibleForCancel() ? (
          <AccordionActions sx={styles.expansionPanelActions}>
            <CancelRequest request={request} />
          </AccordionActions>
        ) : (
          <></>
        )}
      </Accordion>
    </div>
  )
}

export default RequestAccordion
