export const GET_USER_AVAILABILITY_SUCCESS = 'GET_USER_AVAILABILITY_SUCCESS'
export const GET_USER_AVAILABILITY_ERROR = 'GET_USER_AVAILABILITY_ERROR'
export const SET_AVAILABILITY_WEEKLY_SELECTED_DATE = 'SET_AVAILABILITY_WEEKLY_SELECTED_DATE'
export const SET_AVAILABILITY_WEEKLY_LOADING = 'SET_AVAILABILITY_WEEKLY_LOADING'
export const SET_AVAILABILITY_REQUEST_START_DATE = 'SET_AVAILABILITY_REQUEST_START_DATE'
export const SET_AVAILABILITY_REQUEST_END_DATE = 'SET_AVAILABILITY_REQUEST_END_DATE'
export const SET_AVAILABILITY_REQUEST_TYPE = 'SET_AVAILABILITY_REQUEST_TYPE'
export const SET_AVAILABILITY_PATTERN_ID = 'SET_AVAILABILITY_PATTERN_ID'
export const POST_AVAILABILITY_REQUESTS_DATA_SUCCESS = 'POST_AVAILABILITY_REQUESTS_DATA_SUCCESS'
export const POST_AVAILABILITY_REQUESTS_DATA_ERROR = 'POST_AVAILABILITY_REQUESTS_DATA_ERROR'
export const CLEAR_PREVIOUS_STATE = 'CLEAR_PREVIOUS_STATE'
export const UPDATE_AVAILABILITY_REQUESTS_DATA_SUCCESS = 'UPDATE_AVAILABILITY_REQUESTS_DATA_SUCCESS'
export const UPDATE_AVAILABILITY_REQUESTS_DATA_ERROR = 'UPDATE_AVAILABILITY_REQUESTS_DATA_ERROR'
export const AVAILABILITY_FOR_SINGLE_WEEK = 'AVAILABILITY_FOR_SINGLE_WEEK'
