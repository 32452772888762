import { Grid, Typography } from '@mui/material'
import CommentBox from './CommentBox'
import CommentHistory from './CommentHistoryDialog'

const CommentSection = ({ comments }) => {
  return (
    <Grid container sx={{ paddingX: '16px' }} data-cy={'detailPageContent-comment'}>
      <Grid item>
        <Typography variant={'subtitle1'} component="h3">
          {'Most Recent Comment'}
        </Typography>
      </Grid>
      {comments ? (
        <>
          <Grid item xs={12}>
            <CommentBox comments={[comments[comments?.length - 1]]} />
          </Grid>
          <Grid item xs={12}>
            <CommentHistory comments={comments} />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography sx={{ color: 'tertiary.contrastText' }} variant={'body2'}>
            {'No Comments'}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default CommentSection
