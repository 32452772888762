import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TIME_OFF } from '../../../../../../constants/RequestConstants'
import { LEADER_DATE_HEADER_FORMAT } from '../../../../../../constants/leaderConstants'
import { setBackToComponent } from '../../../../../../store/header/actionCreator'
import { formatDateString, formatMinutesToDisplay, formatTime } from '../../../../../../utils/DateUtil'
import { convertRequestDetails, getRequestStatusIcons } from '../../../../../../utils/leaderUtil'
import { processDetailTotals } from '../../../../../../utils/requests'
import { TimeOffApiVO } from '../../../../CategoryDetail/TimeOffApiVO'
import { MY_TEAM_PAGE_PATH } from '../../../MyTeamPage'

export default function TimeOffRow({ data }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  let dataVO = useMemo(() => new TimeOffApiVO(data), [data])
  let { totalMinutes, totalPTOMinutes, totalUnpaidMinutes } = processDetailTotals(
    convertRequestDetails(dataVO.requestDetails),
  )

  let formattedStartDate = useMemo(
    () => formatDateString(dataVO.startDate, dataVO.getStartDateFormat(), LEADER_DATE_HEADER_FORMAT),
    [dataVO],
  )
  let formattedEndDate = useMemo(
    () => formatDateString(dataVO.endDate, dataVO.getEndDateFormat(), LEADER_DATE_HEADER_FORMAT),
    [dataVO],
  )

  return (
    <ListItem disablePadding disableGutters sx={{ width: '100%', maxWidth: 640 }}>
      <ListItemButton
        sx={{ paddingX: '0px', paddingY: '3px' }}
        onClick={() => {
          dispatch(setBackToComponent(MY_TEAM_PAGE_PATH))
          navigate(`/leader/myteam/${TIME_OFF}/${dataVO.requestId}`, {
            state: { data: { ...data, totalMinutes, totalPTOMinutes, totalUnpaidMinutes } },
          })
        }}
        data-cy={`TableContent-row-${dataVO.requestId}`}
      >
        <ListItemIcon sx={{ justifyContent: 'center' }}>{getRequestStatusIcons(dataVO.requestDetails)}</ListItemIcon>
        <ListItemText
          primary={`${
            formattedStartDate === formattedEndDate ? formattedStartDate : formattedStartDate + ' - ' + formattedEndDate
          }`}
          secondary={
            <>
              <Typography sx={{ display: 'block' }} component="span" variant="body2">
                {dataVO.fullName}
              </Typography>
              <Typography sx={{ display: 'block' }} component="span" variant="body2">
                {dataVO.jobClassification}
              </Typography>
              <Typography sx={{ display: 'block' }} component="span" variant="body2">
                {dataVO.types}
              </Typography>
              <Typography sx={{ display: 'block' }} component="span" variant="body2">
                {`${formatMinutesToDisplay(totalMinutes)} total beginning at ${formatTime(dataVO.startTimestamp)}`}
              </Typography>
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
