import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Divider, ListItemButton, Typography, useTheme } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useUserFeatures } from '../../api/userAccess/useUserFeatures'
import { setAvailabilityWeeklySelectedDate } from '../../store/availability/actionCreator'
import { setBackToComponent } from '../../store/header/actionCreator'
import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'
import {
  MY_AVAIALABILITY_FEATURES,
  UPDATE_AVAILABILITY_TEAM_MEMBER,
  VIEW_AVAILABILITY_TEAM_MEMBER,
} from '../../utils/ScreenName'
import HeaderTitle from '../Header/HeaderTitle'

const PAGE_TITLE = 'My Availability'
const AVAILABILITY_HOME_PAGE = '/team-member/availability'

const availabilityMenu = [
  {
    title: 'View Availability',
    path: '/team-member/availability/view-all',
    secondaryText: 'See the days and times you are available to work.',
    cyId: 'sub-menu-view-availability',
    security: VIEW_AVAILABILITY_TEAM_MEMBER,
  },
  {
    title: 'Update Your Availability',
    path: '/team-member/availability/create-new-availability',
    secondaryText: 'Submit a request to update your availability. It will route to your leader for review.',
    cyId: 'sub-menu-create-new-availability',
    security: UPDATE_AVAILABILITY_TEAM_MEMBER,
  },
  {
    title: 'My Availability Requests',
    path: '/team-member/requests/availability',
    secondaryText: 'View your requests to update your availability.',
    cyId: 'sub-menu-availability-requests',
    security: UPDATE_AVAILABILITY_TEAM_MEMBER,
  },
]

const getStyles = (theme) => ({
  listRoot: {
    padding: 0,
  },
  mainScrollContainer: {
    ...theme.mainScrollContainer,
    overflowY: 'auto',
    height: '100%',
  },
  mainContainerPosition: theme.mainContainerPosition,
  settingsListContainer: {
    margin: '5px',
    position: 'relative',
  },
  settingsListItem: {
    borderBottom: '1px solid #333',
    padding: '10px 0 10px 0',
    color: '#333',
  },
  settingsListItemIcon: {
    paddingTop: '3px',
    paddingBottom: '10px',
  },
  settingsListText: {
    paddingLeft: '10px',
  },
  settingsListItemLink: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.secondary.dark,
  },
  secondaryTextCls: {
    marginTop: '7px',
    fontSize: '0.85rem',
    color: '#777',
  },
})

const AvailabilityHome = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { data: userFeatures } = useUserFeatures()
  const backToComponent = useSelector((state) => state.header.backToComponent)

  const dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    if (backToComponent && backToComponent === AVAILABILITY_HOME_PAGE) {
      dispatch(setBackToComponent(null))
      // dispatch(clearUserPreferencesResponses())
    }
    dispatch(setAvailabilityWeeklySelectedDate(getDateOfTodayWithNoTimestamp()))
  })

  useEffect(() => {
    if (!userFeatures.screenAccess.some((feature) => MY_AVAIALABILITY_FEATURES.includes(feature))) {
      navigate('/unauthorized')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderSettingsList = () => {
    return (
      <div style={styles.settingsListContainer}>
        <SettingsListItem />
        <Divider />
      </div>
    )
  }

  const navigateTo = (path) => {
    dispatch(setBackToComponent(AVAILABILITY_HOME_PAGE))
    navigate(path)
  }

  const SettingsListItem = () => {
    return (
      <List sx={styles.listRoot} role={undefined}>
        {availabilityMenu.map((menuItem, index) => (
          <>
            {userFeatures.screenAccess.includes(menuItem.security) && (
              <ListItemButton
                alignItems="flex"
                key={index}
                onClick={() => navigateTo(menuItem.path)}
                divider={index < availabilityMenu.length - 1}
                data-cy={menuItem.cyId}
              >
                <ListItemText
                  primary={`${menuItem.title}`}
                  secondary={<Typography sx={styles.secondaryTextCls}>{menuItem.secondaryText}</Typography>}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label={`Navigate to ${menuItem.title}`}>
                    <NavigateNextIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            )}
          </>
        ))}
      </List>
    )
  }

  const renderComponent = () => {
    return (
      <div style={styles.mainContainerPosition}>
        <Paper sx={styles.mainScrollContainer}>{renderSettingsList()}</Paper>
      </div>
    )
  }

  return (
    <>
      <HeaderTitle title={PAGE_TITLE} />
      {renderComponent()}
    </>
  )
}

export default AvailabilityHome
