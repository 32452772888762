import { Helmet } from 'react-helmet'
import HeaderTitle from '../Header/HeaderTitle'
import NotFound from '../core/NotFound'

export default function NotFoundPage(props) {
  const { headerTitle } = props
  return (
    <div>
      <HeaderTitle title="Not Found" />
      <Helmet title={headerTitle} />
      <NotFound />
    </div>
  )
}
