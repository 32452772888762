import JobIcon from '@mui/icons-material/Label'
import { Box, Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useAuth } from '@praxis/component-auth'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initiateRequestToSwapRequest } from '../../store/swapShift/actionCreator'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import DisplaySegmentShiftSwap from '../DisplaySegments/DisplaySegmentShiftSwap'
import InnerLoader from '../Loader/InnerLoader'
import ShiftSwapVerticalIcon from './../Icons/ShiftSwapVerticalIcon'

const InitiateSwapRequestModal = (props) => {
  const { styles, shift, selectedShift, resetActions } = props
  const auth = useAuth()
  const { session } = auth
  const { userInfo } = session
  const locationDetails = useSelector((state) => state.layout.locationDetails)
  const [disableActions, setDisableActions] = useState(!resetActions)
  const dispatch = useDispatch()

  useEffect(() => {
    setDisableActions(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetActions])

  const handleClick = () => {
    //make api call and show success/failure notification as a response
    const requestBody = {
      location_id: shift.location,
      accepter: {
        location_id: selectedShift.location,
        org_ids_id: selectedShift.org_ids_id,
        org_path: selectedShift.org_path,
        jobs: selectedShift.jobs,
        shift_date: selectedShift.schedule_date,
        shift_start: selectedShift.shift_start,
        shift_end: selectedShift.shift_end,
        shift_label: selectedShift.shift_label,
        worker_id: selectedShift.team_member_number,
      },
      requester: {
        location_id: shift.location,
        org_ids_id: shift.org_ids_id,
        org_path: shift.job_path,
        jobs: shift.jobs,
        shift_date: shift.schedule_date,
        shift_start: moment.tz(shift.schedule_start, locationDetails.iso_time_zone_code)?.format(),
        shift_end: moment.tz(shift.schedule_end, locationDetails.iso_time_zone_code)?.format(),
        shift_label: shift.shift_label,
        worker_id: padEmpIdWithZeros(userInfo?.empid, 10),
      },
    }

    dispatch(initiateRequestToSwapRequest(requestBody))
    setDisableActions(true)
  }

  const renderJob = (jobs) => {
    let jobList = []
    if (jobs && jobs.length > 0) {
      jobs.forEach((job, elemIndex) => {
        if (elemIndex !== 0) {
          let lastIndex = job.org_path.lastIndexOf('/')
          jobList.push(job.org_path.substr(lastIndex + 1, job.org_path.length))
        }
      })
    }
    if (jobList.length > 0) {
      return (
        <Box sx={styles.addtionalJobsContainer}>
          <Typography variant="body2">This shift contains job transfers:</Typography>
          {jobList.map((job, index) => (
            <Box sx={styles.jobContainerAtPopup} key={index}>
              <JobIcon color="primary" sx={styles.iconMargin} />
              <Typography variant="body2" sx={styles.jobName}>
                {job}
              </Typography>
            </Box>
          ))}
        </Box>
      )
    } else {
      return null
    }
  }

  const handleClose = () => {
    props.handleClose()
  }

  const renderDialogInfoText = () => {
    return (
      <>
        <Typography sx={styles.displaySegmentContainer} variant="body2">
          <span>
            <b>Attention:</b> You are responsible for your current shift until the shift swap request has been accepted.
          </span>
        </Typography>
      </>
    )
  }

  // TODO: remove ternary operator for DialogActions and keep Button and ConfirmGiveUpShift when Retract shift implemented

  if (shift) {
    return (
      <Dialog
        disableEscapeKeyDown
        open={props.open}
        onClose={handleClose}
        aria-label="responsive-dialog-title"
        sx={styles.dialogPaper}
      >
        <DialogTitle sx={styles.titleContainerModal} id="responsive-dialog-title">
          Initiate Swap Request
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} sm={12} sx={styles.displaySegmentContainerModal}>
            <Typography>Are you sure you want to initiate the request to swap these shifts?</Typography>
          </Grid>
          <Box sx={styles.requestContainerModal}>
            <DisplaySegmentShiftSwap shift={shift} ownShift={true} styles={styles} />
          </Box>
          <Box sx={styles.verticalGap}>
            <ShiftSwapVerticalIcon />
          </Box>
          <Box sx={styles.requestContainerModal}>
            <DisplaySegmentShiftSwap shift={selectedShift} styles={styles} />
          </Box>
          {renderJob(selectedShift.jobs)}
          {renderDialogInfoText()}
        </DialogContent>
        <DialogActions>
          <>
            {disableActions && (
              <Box component={'span'} style={styles.loaderStyles}>
                <InnerLoader size={25} />
              </Box>
            )}
            <Button onClick={handleClose} color="primary" disabled={disableActions}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleClick}
              sx={styles.mobileButton}
              disabled={disableActions}
              data-cy="initiate-swap-Button"
            >
              Initiate Swap Request
            </Button>
          </>
        </DialogActions>
      </Dialog>
    )
  } else {
    return null
  }
}

export default InitiateSwapRequestModal
