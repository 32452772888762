import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LEADER_MY_TEAM_TITLE } from '../../../constants/leaderConstants'
import { setBackToComponent } from '../../../store/header/actionCreator'
import { setHeaderTitle } from '../../../store/layout/actionCreator'
import Table from './Table/Table.js'

export const MY_TEAM_PAGE_PATH = '/leader/myteam'

const MyTeamPage = () => {
  const backToComponent = useSelector((state) => state.header.backToComponent)
  const dispatch = useDispatch()

  useEffect(() => {
    if (backToComponent === MY_TEAM_PAGE_PATH) {
      dispatch(setBackToComponent(null))
    }
  }, [dispatch, backToComponent])

  useEffect(() => {
    dispatch(setHeaderTitle(LEADER_MY_TEAM_TITLE))
  }, [dispatch])

  return <Table />
}

export default MyTeamPage
