export const SCHEDULE_TEAM_MEMBER = 'schedule_team_member'
export const VOLUNTARY_AVAILABILITY_TEAM_MEMBER = 'voluntary_availability_team_member'
export const LEGACY_LEADER = 'legacy_leader'
export const DC_LEADER_AUTOMATION_OLD = 'dc_leader_automation'
export const TIME_OFF_LEADER = 'time_off_leader'
export const CALL_IN_LEADER = 'call_in_leader'
export const CALL_OFF_LEADER = 'call_off_leader'
export const AVAILABILITY_LEADER = 'availability_leader'
export const WORKER_REDEMPTION_CODE = 'worker_redemption_code'
export const SCREEN_NAME_VOLUNTARY = 'voluntary'
export const SCREEN_NAME_MANDATORY = 'mandatory'
export const SCREEN_NAME_TM_DIRECTORY = 'tmDirectory'
export const SCREEN_NAME_PTO_REQUEST = 'paidTimeOffRequests'
export const SCREEN_NAME_AVAILABLE_SHIFTS = 'available_shifts_team_member'
export const REQUEST_TO_COVER = 'request_to_cover_team_member'
export const VIEW_TIME_CARD = 'view_timecard_team_member'
export const TIME_OFF_TEAM_MEMBER = 'time_off_team_member'
export const CALL_IN_CALL_OFF_TEAM_MEMBER = 'call_in_call_off_team_member'
export const PUNCH_CORRECTION_TEAM_MEMBER = 'punch_correction_team_member'
export const MASS_VACATION_TEAM_MEMBER = 'mass_vacation_team_member'
export const FEEDBACK_TEAM_MEMBER = 'feedback_team_member'
export const MY_REQUESTS_FEATURES = [
  TIME_OFF_TEAM_MEMBER,
  CALL_IN_CALL_OFF_TEAM_MEMBER,
  MASS_VACATION_TEAM_MEMBER,
  PUNCH_CORRECTION_TEAM_MEMBER,
]
export const SCREEN_NAME_SWAP_SHIFT = 'shift_swap_team_member'
export const DESIRED_HOURS_TEAM_MEMBER = 'desired_hours_team_member'
export const MULTI_LOCATION = 'multi_location_team_member'
export const OPT_IN_OPT_OUT_TEAM_MEMBER = 'opt_in_opt_out_team_member'
export const VIEW_AVAILABILITY_TEAM_MEMBER = 'view_availability_team_member'
export const COMMUNICATIONS_TEAM_MEMBER = 'communications_team_member'
export const COMMUNICATIONS_ADMIN = 'communications_admin'
export const UPDATE_AVAILABILITY_TEAM_MEMBER = 'availability_team_member'
export const MY_AVAIALABILITY_FEATURES = [VIEW_AVAILABILITY_TEAM_MEMBER, UPDATE_AVAILABILITY_TEAM_MEMBER]
export const PUNCH_CORRECTION_LEADER = 'punch_correction_leader'
export const BARCODE_TEAM_MEMBER = 'barcode_team_member'
//Partial Actions
export const AVAILABILITY_PARTIAL_ACTION_LEADER = 'availability_partial_action_leader'
export const PUNCH_CORRECTION_PARTIAL_ACTION_LEADER = 'punch_correction_partial_action_leader'
export const TIME_OFF_PARTIAL_ACTION_LEADER = 'time_off_partial_action_leader'
//Read Only Actions
export const AVAILABILITY_READ_ONLY_LEADER = 'availability_read_only_leader'
export const PUNCH_CORRECTION_READ_ONLY_LEADER = 'punch_correction_read_only_leader'
export const TIME_OFF_READ_ONLY_LEADER = 'time_off_read_only_leader'

export const FEATURE_FEEDBACK = 'feedback'

export const allFeatures = {
  [SCHEDULE_TEAM_MEMBER]: 'Ability to view and modify team member schedules.',
  [VOLUNTARY_AVAILABILITY_TEAM_MEMBER]: 'Ability to set voluntary availability.',
  [LEGACY_LEADER]: 'DC leadership role with various access and permission levels.',
  [TIME_OFF_LEADER]: 'Ability to manage and approve time off requests for team members.',
  [CALL_IN_LEADER]: 'Ability to manage team member call-ins.',
  [CALL_OFF_LEADER]: 'Ability to manage team member call-offs.',
  [WORKER_REDEMPTION_CODE]: 'Ability to redeem worker redemption codes.',
  [SCREEN_NAME_VOLUNTARY]: 'Screen for viewing and managing voluntary availability.',
  [SCREEN_NAME_MANDATORY]: 'Screen for viewing and managing mandatory availability.',
  [SCREEN_NAME_TM_DIRECTORY]: 'Team member directory screen with contact information.',
  [SCREEN_NAME_PTO_REQUEST]: 'Screen for requesting paid time off.',
  [SCREEN_NAME_AVAILABLE_SHIFTS]: 'Screen for viewing and claiming available shifts.',
  [REQUEST_TO_COVER]: 'Ability to post shift to avail shift screen.',
  [VIEW_TIME_CARD]: 'Ability to view team member time cards.',
  [TIME_OFF_TEAM_MEMBER]: 'Ability to request time off.',
  [CALL_IN_CALL_OFF_TEAM_MEMBER]: 'Ability to call in or call off as a team member.',
  [SCREEN_NAME_SWAP_SHIFT]: 'Screen for swapping shifts with another team member.',
  [DESIRED_HOURS_TEAM_MEMBER]: 'Ability to set desired hours and view schedules.',
  [MULTI_LOCATION]: 'Ability for on demand tms to pick up schedules across multiple locations.',
  [OPT_IN_OPT_OUT_TEAM_MEMBER]: 'Ability to opt-in or opt-out of notifications.',
  [VIEW_AVAILABILITY_TEAM_MEMBER]: 'Ability to view own availability.',
  [COMMUNICATIONS_TEAM_MEMBER]: 'Ability to view communications.',
  [COMMUNICATIONS_ADMIN]: 'Ability to create new communications.',
  [UPDATE_AVAILABILITY_TEAM_MEMBER]: 'Ability to update you own availability for store team members.',
  [PUNCH_CORRECTION_TEAM_MEMBER]: 'Ability to view punch correction request.',
  [PUNCH_CORRECTION_LEADER]: 'Ability to manage team member punch corrections.',
  [BARCODE_TEAM_MEMBER]: 'Ability to view and use barcode for punching in/out.',
  [MASS_VACATION_TEAM_MEMBER]: 'Ability to view and submit Mass Vacation requests.',
  [AVAILABILITY_PARTIAL_ACTION_LEADER]:
    'Ability to perform certain actions access on availability leader dash board screen',
  [PUNCH_CORRECTION_PARTIAL_ACTION_LEADER]:
    'Ability to perform certain actions access on punch correction leader dash board screen',
  [TIME_OFF_PARTIAL_ACTION_LEADER]: 'Ability to perform certain actions access on time off leader dash board screen',
  [AVAILABILITY_READ_ONLY_LEADER]: 'Ability to have only read only access on availability leader dash board screen',
  [PUNCH_CORRECTION_READ_ONLY_LEADER]:
    'Ability to have only read only access on punch correction leader dash board screen',
  [TIME_OFF_READ_ONLY_LEADER]: 'Ability to have only read only access on time off leader dash board screen',
  [FEEDBACK_TEAM_MEMBER]: 'Ability to navigate to and submit feedback.',
}
