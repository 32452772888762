import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useUserFeedback } from '../../../api/feedback/useUserFeedback'
import FlipCardContent from './FlipCardContent'

// Flippable card component
export const FlipCard = ({ params, type, frontContent, resetFlip, personas }) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const [doneLoading, setdoneLoading] = useState(false)
  const [cardContent, setCardContent] = useState({ type: '', data: [], backContent: '' })

  const { data, isFetching, isLoading } = useUserFeedback(
    params?.start_date,
    params?.end_date,
    type,
    personas || [],
    isFlipped,
  )

  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      if (type === 'sentiment_analysis') {
        const extractSentimentsMap = extractSentiments(data?.data?.response || '')
        const sentimentArray = []
        let id = 0
        if (extractSentimentsMap) {
          for (const [label, value] of extractSentimentsMap.entries()) {
            sentimentArray.push({
              id,
              value,
              label,
            })
            id++
          }
        }
        setCardContent({
          type: type,
          data: sentimentArray,
        })
      } else if (type === 'strengths' || type === 'opportunities') {
        setCardContent({
          type: type,
          backContent: data?.data?.response,
        })
      }
      setdoneLoading(true)
    } else {
      setdoneLoading(false)
    }
  }, [data, isLoading, isFetching, type])

  const handleFlip = () => {
    setIsFlipped((prevFlip) => !prevFlip)
  }

  const extractSentiments = (inputString) => {
    const sentimentMap = new Map()

    // Define patterns for each sentiment
    const patterns = {
      Negative: /Negative[^%]*?([\d.]+)%/i,
      Positive: /Positive[^%]*?([\d.]+)%/i,
      Neutral: /Neutral[^%]*?([\d.]+)%/i,
    }

    // Extract and store each sentiment value
    for (const [key, pattern] of Object.entries(patterns)) {
      const match = inputString.match(pattern)
      if (match) {
        const value = parseFloat(match[1])
        sentimentMap.set(key, value)
      }
    }

    return sentimentMap
  }

  return (
    <div
      style={{
        minWidth: '100%',
        height: 350,
        perspective: '1000px',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <Grid xs={2} item style={{ height: 'calc(100% - 50px)' }}>
          {isFlipped ? (
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'auto', // Allow scrolling if content exceeds height
              }}
            >
              {doneLoading ? (
                <>
                  <FlipCardContent cardContent={cardContent}></FlipCardContent>
                </>
              ) : (
                <>
                  <CircularProgress />
                  <div>loading</div>
                </>
              )}
            </div>
          ) : (
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'auto',
              }}
            >
              <Typography variant="h5">{frontContent}</Typography>
            </div>
          )}
        </Grid>
        <Grid xs={2} item style={{ position: 'relative', height: '50px' }}>
          <Button onClick={handleFlip}>{isFlipped ? 'Flip Back' : 'Flip'}</Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default FlipCard
