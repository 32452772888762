const RetractTMAvailableShiftIcon = (props) => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Retract Shift</title>
      <desc>Created with Sketch.</desc>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="hourglass_top-24px">
          <circle id="Oval" stroke="#CC0202" fill="#FFFFFF" cx="12.5" cy="12.5" r="12"></circle>
          <rect id="Rectangle" x="9" y="6" width="7" height="13"></rect>
          <path
            d="M9,6 L9.00583333,9.9 L11.3333333,12.5 L9.00583333,15.1065 L9,19 L16,19 L16,15.1 L13.6666667,12.5 L16,9.9065 L16,6 L9,6 Z M14.8333333,15.425 L14.8333333,17.7 L10.1666667,17.7 L10.1666667,15.425 L12.5,12.825 L14.8333333,15.425 Z"
            id="Shape"
            fill="#CC0202"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  )
}
export default RetractTMAvailableShiftIcon
