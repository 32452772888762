import ErrorIcon from '@mui/icons-material/Error'
import { Tooltip, Typography } from '@mui/material'
import update from 'immutability-helper'
import React from 'react'
import {
  DEFAULT_MANDATORY_COLUMN_SORT_BY,
  DEFAULT_MANDATORY_DATA,
  DEFAULT_MANDATORY_DATA_SORT,
  DEFAULT_MANDATORY_DATA_TOTAL_RECORDS,
  DEFAULT_PAGE_NUM,
  DEFAULT_PER_PAGE,
  MANDATORY_COLUMNS_ENUM,
  MANDATORY_COLUMNS_ORDER,
} from '../../components/LeaderViewAutomation/LeaderViewAutomation'
import {
  extractAllRuleNames,
  extractAllShiftSegments,
  extractAttendanceCodes,
  weeklyHours,
} from '../leaderViewVoluntaryAutomation/reducer'
import {
  AUTOMATION_MANDATORY_AVAILABILITY_SET_POST_RESPONSE,
  AUTOMATION_MANDATORY_SET_SAVE_DIALOG_OPEN,
  AUTOMATION_MANDATORY_SHOULD_LOAD_DATA,
  GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_ERROR,
  GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_SUCCESS,
  GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_ERROR,
  GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS,
  MANDATORY_LEADER_AUTOMATION_DECISION_ERROR,
  MANDATORY_LEADER_AUTOMATION_DECISION_SUCCESS,
  MANDATORY_LEADER_AUTOMATION_SET_END_TIME,
  MANDATORY_LEADER_AUTOMATION_SET_IS_INPUT_TYPE_TIME,
  MANDATORY_LEADER_AUTOMATION_SET_SHIFT_PREFERENCE,
  MANDATORY_LEADER_AUTOMATION_SET_START_TIME,
  SET_AUTOMATION_MANDATORY_DATA_COLUMN_VIEW,
  SET_AUTOMATION_MANDATORY_DATA_LOADING,
  SET_AUTOMATION_MANDATORY_DATA_PAGE,
  SET_AUTOMATION_MANDATORY_DATA_PER_PAGE,
  SET_AUTOMATION_MANDATORY_DATA_SAVING,
  SET_AUTOMATION_MANDATORY_DATA_SEARCH,
  SET_AUTOMATION_MANDATORY_DATA_SELECTED_FILTERS,
  SET_AUTOMATION_MANDATORY_DATA_SORT,
  SET_AUTOMATION_MANDATORY_DATA_TABLE_COLUMNS_TO_DEFAULT,
  SET_AUTOMATION_MANDATORY_DATA_TABLE_FILTERS_TO_DEFAULT,
  SET_AUTOMATION_MANDATORY_DATA_TABLE_STATE_TO_DEFAULT,
  SET_AUTOMATION_MANDATORY_POST_RESPONSE_INELIGIBILITIES,
} from './actionType'

/**
 * Build the array of arrays for how many columns are defined in the enum. This is the function where a
 * default value (example - proficient: 'y') could be added.
 */
const buildDefaultSelectedFilters = () => {
  let columns = []

  MANDATORY_COLUMNS_ORDER.forEach(() => {
    let emptyArray = []
    columns.push(emptyArray)
  })

  return columns
}

const getIsVisible = (data, forUpColumn) => {
  return data.feature === 'mandatory_up' ? forUpColumn : !forUpColumn
}

const buildMandatoryDataResponse = (data = {}) => {
  let mandatoryData = data
  mandatoryData.forEach((element) => {
    // copying into different object so teamMember params don't get overwritten
    let copiedTeamMember = JSON.parse(JSON.stringify(element))
    element.total_hours_worked = weeklyHours(copiedTeamMember)
    element.attendance_codes = extractAttendanceCodes(copiedTeamMember)
    element.ineligibilities = extractAllRuleNames(copiedTeamMember)
    element.schedule = extractAllShiftSegments(copiedTeamMember)
  })
  return {
    mandatoryData,
  }
}

const updateColumnView = (state, column, action) => {
  let isViewable
  if (action === 'add') {
    isViewable = true
  }
  if (action === 'remove') {
    isViewable = false
  }

  return update(state, {
    mandatoryColumns: {
      [MANDATORY_COLUMNS_ORDER.indexOf(column)]: {
        options: {
          display: { $set: isViewable },
        },
      },
    },
  })
}

const updateSortColumns = (state, sort, column) => {
  return update(state, {
    mandatoryColumns: {
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.last_up_date)]: {
        options: {
          sortOrder: { $set: column === MANDATORY_COLUMNS_ENUM.last_up_date ? sort.toLowerCase() : 'none' },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.last_down_date)]: {
        options: {
          sortOrder: { $set: column === MANDATORY_COLUMNS_ENUM.last_down_date ? sort.toLowerCase() : 'none' },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.full_name)]: {
        options: {
          sortOrder: { $set: column === MANDATORY_COLUMNS_ENUM.full_name ? sort.toLowerCase() : 'none' },
        },
      },
    },
    mandatoryDataSort: { $set: sort },
    mandatoryColumnSortBy: { $set: column },
  })
}

const updateSelectedFilters = (state, payload) => {
  return update(state, {
    mandatoryColumns: {
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.scheduling_key)]: {
        options: {
          filterList: { $set: payload[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.scheduling_key)] },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.department)]: {
        options: {
          filterList: { $set: payload[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.department)] },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.job_classification)]: {
        options: {
          filterList: { $set: payload[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.job_classification)] },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.proficiency)]: {
        options: {
          filterList: { $set: payload[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.proficiency)] },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.schedule_up_group)]: {
        options: {
          filterList: { $set: payload[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.schedule_up_group)] },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.schedule_down_group)]: {
        options: {
          filterList: { $set: payload[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.schedule_down_group)] },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.mandatory_shift)]: {
        options: {
          filterList: { $set: payload[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.mandatory_shift)] },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.status)]: {
        options: {
          filterList: { $set: payload[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.status)] },
        },
      },
    },
    mandatoryDataSelectedFilters: { $set: payload },
  })
}

const updateMandatoryColumns = (state, data) => {
  let upVisible = getIsVisible(data, true)
  let downVisible = getIsVisible(data, false)

  return update(state, {
    mandatoryColumns: {
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.full_name)]: {
        options: {
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              let ineligibleData =
                tableMeta.rowData[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.ineligibilities)]

              if (ineligibleData) {
                return (
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">
                          This team member is currently violating the following schedule rules:
                        </Typography>
                        <ul>
                          {ineligibleData.map((ineligibility, i) => (
                            <li key={i}>{'- ' + ineligibility}</li>
                          ))}
                        </ul>
                      </React.Fragment>
                    }
                  >
                    <span>
                      {value + ' '}
                      <ErrorIcon style={{ color: '#cc0000', fontSize: 'small' }} />
                    </span>
                  </Tooltip>
                )
              } else {
                return value
              }
            },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.scheduling_key)]: {
        options: {
          filterOptions: {
            names: { $set: data.job_keys },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.department)]: {
        options: {
          filterOptions: {
            names: { $set: data.departments },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.job_classification)]: {
        options: {
          filterOptions: {
            names: { $set: data.jobs },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.proficiency)]: {
        options: {
          filterOptions: {
            names: { $set: data.proficiencies },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.schedule_up_group)]: {
        options: {
          display: { $set: upVisible },
          filter: { $set: upVisible },
          filterOptions: {
            names: { $set: data.schedule_groups_up },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.schedule_down_group)]: {
        options: {
          display: { $set: downVisible },
          filter: { $set: downVisible },
          filterOptions: {
            names: { $set: data.schedule_groups_down },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.mandatory_shift)]: {
        options: {
          filterOptions: {
            names: { $set: data.preferences },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.status)]: {
        options: {
          filterOptions: {
            names: { $set: data.statuses },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.last_up_date)]: {
        options: {
          display: { $set: upVisible },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.last_down_date)]: {
        options: {
          display: { $set: downVisible },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.schedule)]: {
        options: {
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              if (value) {
                return (
                  <ul>
                    {value.map((shiftSegment, index) => (
                      <li style={{ listStyleType: 'none' }} key={index}>
                        {shiftSegment}
                      </li>
                    ))}
                  </ul>
                )
              } else {
                return ''
              }
            },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.attendance_codes)]: {
        options: {
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              if (value) {
                return (
                  <ul>
                    {value.map((attendanceCode, index) => (
                      <li style={{ listStyleType: 'none' }} key={index}>
                        {attendanceCode}
                      </li>
                    ))}
                  </ul>
                )
              } else {
                return ''
              }
            },
          },
        },
      },
      [MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.ineligibilities)]: {
        options: {
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              if (value) {
                return (
                  <ul>
                    {value.map((ineligibility, i) => (
                      <li key={i}>{ineligibility}</li>
                    ))}
                  </ul>
                )
              } else {
                return ''
              }
            },
          },
        },
      },
    },
    mandatoryGetFiltersError: { $set: null },
  })
}

export const initialState = {
  mandatoryData: DEFAULT_MANDATORY_DATA,
  mandatoryDataLoading: false,
  mandatoryDataSaving: false,
  mandatoryDataPage: DEFAULT_PAGE_NUM,
  mandatoryDataPerPage: DEFAULT_PER_PAGE,
  mandatoryPrevRequestedSearch: null,
  mandatoryPrevRequestedSelectedFilters: null,
  mandatoryDataSearch: null,
  mandatoryDataSelectedFilters: buildDefaultSelectedFilters(),
  mandatoryDataSort: DEFAULT_MANDATORY_DATA_SORT,
  mandatoryColumnSortBy: DEFAULT_MANDATORY_COLUMN_SORT_BY,
  mandatoryPostResponse: null,
  mandatoryPostError: null,
  mandatoryGetFiltersError: null,
  mandatoryGetPreferencesError: null,
  mandatorySaveDialogOpen: null,
  isInputTypeTime: false,
  mandatoryPostResponseIneligibilities: null,
  shouldLoadMandatoryData: false,
  upShiftType: 'Up - Full',
  downShiftType: 'Down - Full',
  startTime: new Date().setHours(12, 0, 0, 0),
  endTime: new Date().setHours(12, 0, 0, 0),
  mandatoryColumns: [
    /**
     * Column order matters here. If add/removing or changing order of a
     * column(s) here, also update MANDATORY_COLUMNS_ORDER
     */
    {
      name: MANDATORY_COLUMNS_ORDER[0],
      label: 'Name',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[1],
      label: 'Team Member ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[2],
      label: 'Key',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {
          names: [],
        },
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[3],
      label: 'Department',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[4],
      label: 'Job Classification',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[5],
      label: 'Proficiency',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[6],
      label: 'Up Group',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[7],
      label: 'Down Group',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[8],
      label: 'Last Up Date',
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[9],
      label: 'Last Down Date',
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[10],
      label: 'Mandatory Shift',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[11],
      label: 'Status',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[12],
      label: 'Status Timestamp',
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[13],
      label: 'Schedule',
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[14],
      label: 'Attendance Code',
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[15],
      label: 'Total Weekly Hours',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        hint: 'Actual Hours through yesterday + Future Schedules',
      },
    },
    {
      name: MANDATORY_COLUMNS_ORDER[16],
      label: 'Rule Violations',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ],
}

export default function leaderViewMandatoryAvailability(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AUTOMATION_MANDATORY_DATA_TABLE_STATE_TO_DEFAULT: {
      return {
        ...state,
        mandatoryData: DEFAULT_MANDATORY_DATA,
        mandatoryDataTotalRecords: DEFAULT_MANDATORY_DATA_TOTAL_RECORDS,
      }
    }
    case SET_AUTOMATION_MANDATORY_DATA_TABLE_FILTERS_TO_DEFAULT: {
      return {
        ...state,
        ...updateSelectedFilters(state, buildDefaultSelectedFilters()),
      }
    }
    case SET_AUTOMATION_MANDATORY_DATA_TABLE_COLUMNS_TO_DEFAULT: {
      return {
        ...state,
        mandatoryColumns: initialState.mandatoryColumns,
      }
    }
    case GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_SUCCESS: {
      const { record } = action
      return updateMandatoryColumns(state, record.data)
    }
    case GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        mandatoryGetFiltersError: record.response ? record.response.data : record,
      }
    }
    case SET_AUTOMATION_MANDATORY_DATA_LOADING: {
      const { payload, search, selectedFilters } = action
      return {
        ...state,
        mandatoryDataLoading: payload,
        mandatoryPrevRequestedSearch: search,
        mandatoryPrevRequestedSelectedFilters: selectedFilters,
      }
    }
    case SET_AUTOMATION_MANDATORY_DATA_SAVING: {
      const { isSaving } = action
      return {
        ...state,
        mandatoryDataSaving: isSaving,
      }
    }
    case SET_AUTOMATION_MANDATORY_DATA_SEARCH: {
      const { payload } = action
      return {
        ...state,
        mandatoryDataSearch: payload,
      }
    }
    case SET_AUTOMATION_MANDATORY_DATA_SORT: {
      const { payload, column } = action
      return updateSortColumns(state, payload, column)
    }
    case SET_AUTOMATION_MANDATORY_DATA_PAGE: {
      const { payload } = action
      return {
        ...state,
        mandatoryDataPage: payload,
      }
    }
    case SET_AUTOMATION_MANDATORY_DATA_PER_PAGE: {
      const { payload } = action
      return {
        ...state,
        mandatoryDataPerPage: payload,
      }
    }
    case SET_AUTOMATION_MANDATORY_DATA_SELECTED_FILTERS: {
      const { payload } = action
      return updateSelectedFilters(state, payload)
    }
    case SET_AUTOMATION_MANDATORY_DATA_COLUMN_VIEW: {
      const { column, columnAction } = action
      return updateColumnView(state, column, columnAction)
    }
    case GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS: {
      const { record } = action
      return {
        ...state,
        ...buildMandatoryDataResponse(record.data.mandatory_availability_details),
        ...buildMandatoryPaginationDetailsFromHeaderResponse(record.headers),
        mandatoryGetPreferencesError: null,
        mandatoryPostError: null,
        mandatoryDataLoading: false,
      }
    }
    case GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        mandatoryGetPreferencesError: record.response ? record.response.data : record,
        mandatoryPostError: null,
        mandatoryDataLoading: false,
      }
    }
    case MANDATORY_LEADER_AUTOMATION_DECISION_SUCCESS: {
      const { record } = action
      return {
        ...state,
        mandatoryPostResponse: record && record.data ? record.data : record,
        mandatoryPostError: null,
        mandatoryGetPreferencesError: null,
        mandatoryDataSaving: false,
        mandatorySaveDialogOpen: null,
        mandatoryPostResponseIneligibilities: null,
      }
    }
    case MANDATORY_LEADER_AUTOMATION_DECISION_ERROR: {
      const { record } = action
      return {
        ...state,
        mandatoryPostResponse: null,
        mandatoryPostError: record && record.response ? record.response.data : record,
        mandatoryGetPreferencesError: null,
        mandatoryDataSaving: false,
        mandatorySaveDialogOpen: null,
        mandatoryPostResponseIneligibilities: null,
      }
    }
    case AUTOMATION_MANDATORY_SHOULD_LOAD_DATA: {
      const { shouldLoad } = action
      return {
        ...state,
        shouldLoadMandatoryData: shouldLoad,
      }
    }
    case AUTOMATION_MANDATORY_AVAILABILITY_SET_POST_RESPONSE: {
      const { payload } = action
      return {
        ...state,
        mandatoryPostResponse: payload,
      }
    }
    case SET_AUTOMATION_MANDATORY_POST_RESPONSE_INELIGIBILITIES: {
      const { ineligibilities } = action
      return {
        ...state,
        mandatoryPostResponseIneligibilities: ineligibilities,
      }
    }
    case MANDATORY_LEADER_AUTOMATION_SET_IS_INPUT_TYPE_TIME: {
      const { payload } = action
      return {
        ...state,
        isInputTypeTime: payload,
      }
    }
    case AUTOMATION_MANDATORY_SET_SAVE_DIALOG_OPEN: {
      const { payload } = action
      return {
        ...state,
        mandatorySaveDialogOpen: payload,
      }
    }
    case MANDATORY_LEADER_AUTOMATION_SET_SHIFT_PREFERENCE: {
      const { upShiftType, downShiftType } = action
      return {
        ...state,
        upShiftType: upShiftType,
        downShiftType: downShiftType,
      }
    }
    case MANDATORY_LEADER_AUTOMATION_SET_START_TIME: {
      const { payload } = action
      return {
        ...state,
        startTime: payload,
      }
    }
    case MANDATORY_LEADER_AUTOMATION_SET_END_TIME: {
      const { payload } = action
      return {
        ...state,
        endTime: payload,
      }
    }
    default:
      return state
  }
}

export const buildMandatoryPaginationDetailsFromHeaderResponse = (headers = {}) => {
  return {
    mandatoryDataTotalRecords: headers['x-total-count'],
  }
}
