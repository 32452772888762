export const REQUEST_CADENCE = [
  {
    value: 'Every week',
    label: 'Every week',
  },
  {
    value: 'Every other week',
    label: 'Every other week',
  },
]

export const REQUEST_TYPES = {
  time_off: 'Time Off',
  availability: 'Availability',
}

export const FIXED_END_DATE_FROM_UKG = '3000-01-01'
export const defaultStartTime = new Date().setTime(21600000)
export const defaultEndTime = new Date().setTime(22500000)
export const AVAILABILITY_CALENDAR_MAX_FUTURE_WEEKS = 26

export const PENDING = 'Pending'
export const CANCELLED = 'Cancelled'
export const FAILED = 'Failed'
export const ALL = 'All'
export const APPROVED = 'Approved'

// UI Only
export const PROCESSING = 'Processing'

export const REQUEST_STATUS_TYPES_AVAILABILITY = {
  ALL: ALL,
  PENDING: PENDING,
  FAILED: FAILED,
  CANCELLED: CANCELLED,
  APPROVED: APPROVED,
}

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
