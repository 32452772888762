import { Popover } from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers'
import { endOfWeek, format, isValid, startOfWeek } from 'date-fns'
import { useState } from 'react'
import { months } from '../../../constants/AvailabilityConstants'

function InlineCalendarDatePicker(props) {
  const { id, selectedDate, onChange, minDate, maxDate, dateTextFormat } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const startWeek = startOfWeek(selectedDate)
  const endWeek = endOfWeek(selectedDate)
  const dayDateFormat = 'MM/dd'
  const monthDateFormat = 'MMMM y'
  const open = Boolean(anchorEl)
  const calId = open ? id : undefined
  let formattedDateText = format(selectedDate, monthDateFormat)

  if (dateTextFormat === 'weekSpan') {
    formattedDateText = format(startWeek, dayDateFormat) + ' - ' + format(endWeek, dayDateFormat)
  }

  const ariaLabelDate = () => {
    if (isValid(startWeek) && isValid(endWeek)) {
      let sDate = startWeek.getDate().toString()
      let sMonth = startWeek.getMonth()
      let sYear = startWeek.getFullYear()

      let eDate = endWeek.getDate().toString()
      let eMonth = endWeek.getMonth()
      let eYear = endWeek.getFullYear()

      return `${months[sMonth].substring(0, 3)} ${sDate}, ${sYear} to ${months[eMonth].substring(
        0,
        3,
      )} ${eDate}, ${eYear}`
    } else {
      return ''
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (day) => {
    onChange(day)
    handleClose()
  }

  return (
    <>
      <div
        data-cy={calId}
        tabIndex={0}
        role="button"
        aria-label={ariaLabelDate()}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <span>{formattedDateText}</span>
      </div>
      <Popover
        data-cy="calendarPicker"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DateCalendar
          format="d MMM yyyy"
          value={selectedDate}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Popover>
    </>
  )
}

export default InlineCalendarDatePicker
