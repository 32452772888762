import { CircularProgress, Divider, Typography, useTheme } from '@mui/material'
import React from 'react'
import { formatHours } from '../../utils/formatHours'

const getStyles = (theme) => ({
  root: {
    width: '100%',
  },
  loadingIconContainer: theme.loadingIconContainer,
  highlights: {
    fontWeight: 'bold',
    fontSize: '100%',
  },
  highlightsContainer: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
  },
  highlightsContainerPosition: {
    width: '100%',
  },
  row: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  col: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
  },
  colStart: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
    padding: '10px 8px',
  },
  colCenter: {
    flexGrow: '1',
    maxWidth: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
  },
  colEnd: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'right',
  },
  colEndTextLeft: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  redText: {
    color: '#CC0000',
  },
  rightTextStyles: {
    padding: '10px 10px 10px 15px',
  },
  rightAlign: {
    textAlign: 'right',
  },
  dividerStyle: {
    borderLeft: `1px solid ${theme.palette.tertiary.main}`,
    height: '44px',
    left: '50%',
    marginLeft: '0',
  },
  dividerStyleForMissedPunch: {
    borderLeft: `1px solid ${theme.palette.tertiary.main}`,
    height: '47px',
    left: '50%',
    marginLeft: '0',
  },
  missedPunchAdjHrsStatus: {
    marginTop: '3px',
  },
  emptyDiv: {
    height: '40px',
    width: '100%',
    clear: 'both',
  },
  thickerBorder: {
    height: '2px',
  },
})

const HoursSummaryDaily = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { scheduledHrs, totalScheduledHrsLoader, punchedHrs, paycodeHrs, totalHrs, missedPunch, showHideEmptyDiv } =
    props

  const showTotalScheduledHrs = () => {
    if (totalScheduledHrsLoader) {
      return (
        <>
          {`Scheduled Hrs: `} <CircularProgress size={18} sx={styles.loadingIconContainer} />
        </>
      )
    } else {
      return <>{`Scheduled Hrs: ${formatHours?.format(scheduledHrs)}`}</>
    }
  }

  return (
    <React.Fragment>
      <div style={styles.highlightsContainerPosition}>
        <div style={{ ...styles.row, ...styles.highlightsContainer }}>
          <Typography
            variant="body2"
            tabIndex={0}
            role="heading"
            aria-label={totalScheduledHrsLoader ? '' : 'scheduled hours: ' + scheduledHrs + ' hours'}
            sx={{ ...styles.colStart, ...styles.highlights, ...(missedPunch ? styles.missedPunchAdjHrsStatus : {}) }}
          >
            {showTotalScheduledHrs()}
          </Typography>
          <Divider
            role="presentation"
            sx={missedPunch ? { ...styles.dividerStyleForMissedPunch } : { ...styles.dividerStyle }}
          />
          <Typography
            variant="body2"
            tabIndex={0}
            role="heading"
            aria-label={
              missedPunch
                ? 'punched hours: ' + punchedHrs + ' hours with one or more missed punches'
                : 'punched hours: ' + punchedHrs + ' hours'
            }
            sx={{ ...styles.colEndTextLeft, ...styles.highlights, ...styles.rightTextStyles }}
          >
            {`Punched Hrs: ${formatHours?.format(punchedHrs)}`}
            {missedPunch && <sup style={styles.redText}>*</sup>}
          </Typography>
        </div>
        <div style={{ ...styles.row, ...styles.highlightsContainer }}>
          <Typography
            variant="body2"
            tabIndex={0}
            role="heading"
            aria-label={'other hours: ' + paycodeHrs + ' hours'}
            sx={{ ...styles.colStart, ...styles.highlights, ...(missedPunch ? styles.missedPunchAdjHrsStatus : {}) }}
          >
            {`Other Hrs: ${formatHours?.format(paycodeHrs)}`}
          </Typography>
          <Divider
            role="presentation"
            sx={missedPunch ? { ...styles.dividerStyleForMissedPunch } : { ...styles.dividerStyle }}
          />
          <Typography
            variant="body2"
            tabIndex={0}
            role="heading"
            aria-label={'total hours: ' + totalHrs + ' hours'}
            sx={{ ...styles.colEndTextLeft, ...styles.highlights, ...styles.rightTextStyles }}
          >
            {`Total Hrs: ${formatHours?.format(totalHrs)}`}
          </Typography>
        </div>
        <Divider role="presentation" sx={styles.thickerBorder} />
        {missedPunch && (
          <div
            tabIndex={0}
            role="heading"
            aria-level="2"
            aria-label={'one or more missed punches detected in this day'}
            style={{ ...styles.rightAlign, ...styles.row }}
          >
            <Typography variant="body2" sx={{ ...styles.colEnd, ...styles.redText }}>
              <sup>*</sup> {' Missed Punch(es)'}
            </Typography>
          </div>
        )}
        {showHideEmptyDiv && !missedPunch ? <div style={styles.emptyDiv} /> : null}
      </div>
    </React.Fragment>
  )
}

export default HoursSummaryDaily
