export const SET_AUTOMATION_VOLUNTARY_DATA_SELECTED_FILTERS = 'SET_AUTOMATION_VOLUNTARY_DATA_SELECTED_FILTERS'
export const SET_AUTOMATION_VOLUNTARY_DATA_LOADING = 'SET_AUTOMATION_VOLUNTARY_DATA_LOADING'
export const SET_AUTOMATION_VOLUNTARY_DATA_SEARCH = 'SET_AUTOMATION_VOLUNTARY_DATA_SEARCH'
export const SET_AUTOMATION_VOLUNTARY_DATA_SORT = 'SET_AUTOMATION_VOLUNTARY_DATA_SORT'
export const SET_AUTOMATION_VOLUNTARY_DATA_PAGE = 'SET_AUTOMATION_VOLUNTARY_DATA_PAGE'
export const SET_AUTOMATION_VOLUNTARY_DATA_PER_PAGE = 'SET_AUTOMATION_VOLUNTARY_DATA_PER_PAGE'
export const GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_SUCCESS =
  'GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_SUCCESS'
export const GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_ERROR =
  'GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_ERROR'
export const GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS =
  'GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS'
export const GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_ERROR =
  'GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_ERROR'
export const SET_AUTOMATION_VOLUNTARY_DATA_TABLE_STATE_TO_DEFAULT =
  'SET_AUTOMATION_VOLUNTARY_DATA_TABLE_STATE_TO_DEFAULT'
export const SET_AUTOMATION_VOLUNTARY_DATA_TABLE_FILTERS_TO_DEFAULT =
  'SET_AUTOMATION_VOLUNTARY_DATA_TABLE_FILTERS_TO_DEFAULT'
export const SET_AUTOMATION_VOLUNTARY_DATA_TABLE_COLUMNS_TO_DEFAULT =
  'SET_AUTOMATION_VOLUNTARY_DATA_TABLE_COLUMNS_TO_DEFAULT'
export const LEADER_AUTOMATION_DECISION_SUCCESS = 'LEADER_AUTOMATION_DECISION_SUCCESS'
export const LEADER_AUTOMATION_DECISION_ERROR = 'LEADER_AUTOMATION_DECISION_ERROR'
export const AUTOMATION_VOLUNTARY_AVAILABILITY_SET_POST_RESPONSE = 'AUTOMATION_VOLUNTARY_AVAILABILITY_SET_POST_RESPONSE'
export const AUTOMATION_VOLUNTARY_AVAILABILITY_POST_ERROR = 'AUTOMATION_VOLUNTARY_AVAILABILITY_POST_ERROR'
export const AUTOMATION_VOLUNTARY_SET_SAVE_DIALOG_OPEN = 'AUTOMATION_VOLUNTARY_SET_SAVE_DIALOG_OPEN'
export const AUTOMATION_VOLUNTARY_SET_IS_INPUT_TYPE_TIME = 'AUTOMATION_VOLUNTARY_SET_IS_INPUT_TYPE_TIME'
export const AUTOMATION_VOLUNTARY_SET_DOWN_SHIFT_TYPE = 'AUTOMATION_VOLUNTARY_SET_DOWN_SHIFT_TYPE'
export const AUTOMATION_VOLUNTARY_SET_START_TIME = 'AUTOMATION_VOLUNTARY_SET_START_TIME'
export const AUTOMATION_VOLUNTARY_SET_END_TIME = 'AUTOMATION_VOLUNTARY_SET_END_TIME'
export const AUTOMATION_VOLUNTARY_SET_DATA_COLUMN_VIEW = 'AUTOMATION_VOLUNTARY_SET_DATA_COLUMN_VIEW'
export const SET_AUTOMATION_VOLUNTARY_DATA_SAVING = 'SET_AUTOMATION_VOLUNTARY_DATA_SAVING'
export const SET_AUTOMATION_VOLUNTARY_POST_RESPONSE_INELIGIBILITIES =
  'SET_AUTOMATION_VOLUNTARY_POST_RESPONSE_INELIGIBILITIES'
export const AUTOMATION_VOLUNTARY_SHOULD_LOAD_DATA = 'AUTOMATION_VOLUNTARY_SHOULD_LOAD_DATA'
export const DOWNLOAD_CSV_SUCCESS = 'DOWNLOAD_CSV_SUCCESS'
export const DOWNLOAD_CSV_ERROR = 'DOWNLOAD_CSV_ERROR'
