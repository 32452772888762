import { Button, Link, useTheme } from '@mui/material'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useTimecardWeekly } from '../../api/timecard/useTimecardWeekly'
import { getNowDateInTimezone, getStartEndDatesOfWeek } from '../../utils/DateUtil'
import { formatHours } from '../../utils/formatHours'
import TimecardIcon from '../Icons/TimecardIcon'
import InnerLoader from '../Loader/InnerLoader'
import HomePageCard from '../common/HomePageCard'

const CSC_ERROR_MESSAGE = '  Please try again later and if the issue persists, contact the CSC.'
const TIMECARD_ERROR_MESSAGE = 'Unable to load your timecard details at this time.' + CSC_ERROR_MESSAGE
const TIMECARD_ERROR_CODES_SHOW_MESSAGE = ['wfm-2-5', 'wfm-2-11', 'wfm-13-5', 'wfm-13-11']
const ERROR_CODE_CANNOT_CONNECT_TO_TIMECARD = 'wfm-13-0'

const getStyles = (theme) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  cardTitleContainer: {
    display: 'flex',
    flexGrow: '1',
  },
  cardTitle: {
    marginLeft: '.4em',
    fontWeight: 'bold',
  },
  refetchingContainer: {
    marginLeft: '.4em',
  },
  gridItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '6px',
    marginBottom: '6px',
  },
  weeklyTimecardLeft: {
    alignSelf: 'center',
  },
  weeklyTimecardRight: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  redText: {
    color: '#cc0000',
  },
  alignStart: {
    alignSelf: 'flex-start',
  },
})

function TimecardWeeklyStatusCard(props) {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { renderHomePageCardError, locationTimezone } = props
  const nowDateInTimezone = getNowDateInTimezone(locationTimezone)
  const { data, error, isRefetching } = useTimecardWeekly(nowDateInTimezone)

  let navigate = useNavigate()

  const handleLink = () => {
    navigate('/team-member/timecardWeekly')
  }

  const renderTimecardWeeklyButton = () => {
    return (
      <CardActions>
        <Link to="/team-member/timecardWeekly" style={{ textDecoration: 'none' }}>
          <Button color="primary" variant="contained" size="small" data-cy="viewTimecardBtn" onClick={handleLink}>
            View Timecard
          </Button>
        </Link>
      </CardActions>
    )
  }

  const renderTimecardWeeklyData = () => {
    let totalHrs = data.total_hours
    let missedPunchMsg = data.has_missed_punch ? '* Missed Punch(es)' : null

    const { startDate, endDate } = getStartEndDatesOfWeek(nowDateInTimezone, 'MMM D')

    return (
      <Grid container>
        <Grid item xs={7} sx={styles.weeklyTimecardLeft}>
          <Typography variant="h6">{startDate + ' - ' + endDate}</Typography>
        </Grid>
        <Grid item xs={5} sx={styles.weeklyTimecardRight}>
          <Typography variant="body2" sx={styles.alignStart}>
            {`Total Hours: ${formatHours?.format(totalHrs)}`}
            {missedPunchMsg && <sup style={styles.redText}>*</sup>}
          </Typography>
          {missedPunchMsg && (
            <Typography variant="body2" sx={{ ...styles.alignStart, ...styles.redText }}>
              {missedPunchMsg}
            </Typography>
          )}
        </Grid>
      </Grid>
    )
  }

  /**
   * wondering why we check data first and not isLoading?
   * Check out this blog: https://tkdodo.eu/blog/status-checks-in-react-query
   */
  const renderTimecardWeeklyResponse = () => {
    if (data) return renderTimecardWeeklyData()
    if (error) {
      return renderHomePageCardError(
        error?.response?.data,
        TIMECARD_ERROR_MESSAGE,
        TIMECARD_ERROR_CODES_SHOW_MESSAGE,
        ERROR_CODE_CANNOT_CONNECT_TO_TIMECARD,
      )
    }
    return <InnerLoader size={48} />
  }

  return (
    <Grid item xs={12} md={12} sx={styles.gridItem} data-cy="viewTimecardCard">
      <HomePageCard
        title="Timecard"
        icon={<TimecardIcon color={'#000000'} />}
        isRefetching={isRefetching}
        cardActions={data && renderTimecardWeeklyButton}
      >
        {renderTimecardWeeklyResponse()}
      </HomePageCard>
    </Grid>
  )
}

export default TimecardWeeklyStatusCard
