import JobIcon from '@mui/icons-material/Label'
import { Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { differenceInMinutes } from 'date-fns'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setBackToComponent } from '../../store/header/actionCreator'
import { clearShiftResults, storeRequesterCurrentShift } from '../../store/swapShift/actionCreator'
import { MultiClickButton as SearchShiftsButton } from '../../utils/MultiClickButton'
import { truncateString } from '../../utils/strings'
import DisplaySegmentShiftSwap from '../DisplaySegments/DisplaySegmentShiftSwap'

const WEEKLY_SCHEDULE_PAGE_PATH = '/team-member/schedule'

const SearchSwapShiftsModal = (props, { disableBackdropClick, disableEscapeKeyDown }) => {
  const { styles, shift, schedule } = props
  const outputDateFormat = 'dddd, MMMM D'
  const [processIndicator, setProcessIndicator] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const postShiftPostResponse = useSelector((state) => state.weeklySchedule.postShiftPostResponse)
  const postShiftPostErrResponse = useSelector((state) => state.weeklySchedule.postShiftPostErrResponse)

  useEffect(() => {
    setProcessIndicator(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postShiftPostResponse, postShiftPostErrResponse])

  const handleSubmit = () => {
    const { shift, schedule } = props
    let ownShift = {
      ...shift,
      schedule_date: schedule.schedule_date,
      schedule_start: shift.segment_start,
      schedule_end: shift.segment_end,
      org_path: shift.job_path,
      jobs: shift.jobs.length > 0 ? formatJobs(shift.jobs) : shift.jobs,
      shift_hours: handleHours(shift),
    }
    setProcessIndicator(true)
    dispatch(setBackToComponent(WEEKLY_SCHEDULE_PAGE_PATH))
    dispatch(storeRequesterCurrentShift(ownShift))

    dispatch(clearShiftResults())
    takeToSwapSearchPage()
  }

  const formatJobs = (jobs) => {
    let jobList = []

    jobs.forEach((job) => {
      job.org_path = job.job_path
      jobList.push(job)
    })
    return jobList
  }

  const takeToSwapSearchPage = () => {
    navigate('/team-member/swapsearchresults')
    props.disableCoverShiftButton()
    handleClose()
  }

  const renderJob = (jobs) => {
    const { styles } = props
    return (
      <React.Fragment>
        {jobs.map((job, index) => (
          <div style={styles.jobContainerAtPopup} key={index}>
            <JobIcon color="primary" sx={styles.iconMargin} />
            <Typography variant="body2" sx={styles.jobName}>
              {job}
            </Typography>
          </div>
        ))}
      </React.Fragment>
    )
  }

  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof onClose === 'function') {
      props.handleClose()
    }
    props.handleClose()
  }

  // TODO: implement when rtc cancel posted shift is enabled 'Retract Shift'
  const handleDialogTitle = () => {
    return 'Search Shifts for Swap'
    // shift.status !== 'PENDING' ? 'Search Swap options' : 'Swap request initiated'
  }

  const handleHours = (shift) => {
    let shiftInHours =
      differenceInMinutes(
        new Date(shift.segment_end.split(' ')[0] + 'T' + shift.segment_end.split(' ')[1]),
        new Date(shift.segment_start.split(' ')[0] + 'T' + shift.segment_start.split(' ')[1]),
      ) / 60
    return Number.parseFloat(shiftInHours).toFixed(2)
  }

  const ShiftLabel = () => {
    const { shift } = props
    if (shift.shift_label) {
      let shiftLabel = truncateString(shift.shift_label, 3)

      return (
        <Typography sx={styles.shiftLabelContainerInModal}>
          <div style={styles.shiftLabel}>
            <span>{`${shiftLabel}`}</span>
          </div>
        </Typography>
      )
    } else {
      return null
    }
  }

  // TODO: remove ternary operator for DialogActions and keep Button and ConfirmGiveUpShift when Retract shift implemented
  return (
    <Dialog
      disableEscapeKeyDown
      open={props.open}
      onClose={(event, reason) => {
        handleClose(event, reason)
      }}
      aria-label="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{handleDialogTitle()}</DialogTitle>
      <DialogContent>
        <Grid item xs={12} sm={12} sx={styles.displaySegmentContainer}>
          <Typography>
            Searching swap options for your <b>{handleHours(shift)} hour</b> shift on
            <b> {moment(schedule.schedule_date)?.format(outputDateFormat)}</b>
          </Typography>
        </Grid>
        <DisplaySegmentShiftSwap shift={shift} styles={styles} />
        <ShiftLabel />
        {shift.jobList && shift.jobList.length > 0 && (
          <div style={styles.addtionalJobsContainer}>
            <Typography variant="body2">This shift contains job transfers:</Typography>
            {renderJob(shift.jobList)}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <SearchShiftsButton
            label={'Search'}
            handleClick={handleSubmit}
            handleDoubleClick={handleSubmit}
            processIndicator={processIndicator}
            styles={styles}
            pageName="searchSwapShifts"
          />
        </>
      </DialogActions>
    </Dialog>
  )
}

export default SearchSwapShiftsModal
