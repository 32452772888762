import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const RedirectNavigation = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/leaderView/' + props.screenName)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  props.toggleRedirect(false)
}
