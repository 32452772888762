import {
  CLEAR_PREVIOUS_STATE,
  DESIRED_HOURS_POST_REQUEST_ERROR,
  DESIRED_HOURS_POST_REQUEST_SUCCESS,
  SET_DESIRED_HOURS_LOADING,
} from './actionType'

export const initialState = {
  isLoading: false,
  error: null,
  data: null,
}

export default function desiredHours(state = initialState, action = {}) {
  switch (action.type) {
    case DESIRED_HOURS_POST_REQUEST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        isLoading: false,
        data: payload.data,
      }
    }
    case DESIRED_HOURS_POST_REQUEST_ERROR: {
      const { payload } = action
      let error
      if (payload.response) {
        error = payload?.response?.data
      } else {
        error = payload.message
      }
      return {
        ...state,
        isLoading: false,
        error: error,
      }
    }
    case SET_DESIRED_HOURS_LOADING: {
      const { payload } = action
      return {
        ...state,
        isLoading: payload,
      }
    }
    case CLEAR_PREVIOUS_STATE: {
      return {
        ...state,
        data: null,
        error: null,
      }
    }
    default:
      return state
  }
}
