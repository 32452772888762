import JobIcon from '@mui/icons-material/Label'
import LocationIcon from '@mui/icons-material/Place'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React from 'react'
import Box from '@mui/material/Box'

const DisplayPostShiftSegment = (props) => {
  const { styles, shift } = props
  const inputTimeFormat = 'YYYY-MM-DD hh:mm:ss'
  const outputTimeFormat = 'hh:mmA'

  const renderLocation = (location) => {
    const { styles } = props

    if (location) {
      return (
        <React.Fragment>
          <LocationIcon color="primary" sx={styles.iconMargin} />
          <Typography variant="body2" sx={styles.locationName}>
            {location}
          </Typography>
        </React.Fragment>
      )
    }
  }

  const renderJob = (jobName) => {
    if (jobName) {
      return (
        <React.Fragment>
          <JobIcon color="primary" sx={styles.iconMargin} />
          <Typography variant="body2" sx={styles.jobName}>
            {jobName}
          </Typography>
        </React.Fragment>
      )
    }
  }

  return (
    <Grid container>
      <Grid item xs={6} sm={6} sx={styles.displaySegmentContainer}>
        <Typography variant="body2" sx={styles.startTimeItem} aria-label={'Start'}>
          {moment(shift.segment_start, inputTimeFormat)?.format(outputTimeFormat)}
        </Typography>
        <Typography variant="body2" sx={styles.endTimeItem} aria-label={'End'}>
          {moment(shift.segment_end, inputTimeFormat)?.format(outputTimeFormat)}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} sx={styles.displaySegmentContainer}>
        <Box sx={styles.locationContainer} aria-label={'Location'}>
          {renderLocation(shift.location)}
        </Box>
        <Box sx={styles.jobContainer} aria-label={'Job'}>
          {renderJob(shift.job_name)}
        </Box>
      </Grid>
    </Grid>
  )
}

export default DisplayPostShiftSegment
