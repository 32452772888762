import { RULES_VIOLATION_ERR_MSG } from '../constants/errorConstants'

/**
 * Creates a printable string containing the current error code. If error is null, then returns null.
 * If error is not null but code is, then set the error code to defaultErrorCode.
 * Return Example: "(error code: 3-0)"
 */
export const formatErrorCode = (error, defaultErrorCode) => {
  let errorCode = null

  if (error) {
    if (error.code) {
      errorCode = ' (error code: ' + error.code + ')'
    } else {
      errorCode = ' (error code: ' + defaultErrorCode + ')'
    }
  }
  return errorCode
}

export const parseRuleViolation = (errResponse) => {
  let errorMessages = ''
  let message

  if (errResponse.errors && errResponse.errors.length > 0) {
    for (let error of errResponse.errors) {
      errorMessages += '<li>' + error + '</li>'
    }
    message = RULES_VIOLATION_ERR_MSG + '<br /><ul>' + errorMessages + '</ul>'
  } else {
    message = RULES_VIOLATION_ERR_MSG
  }

  return message
}
