import {
  RESET_APPLE_IOS_DOWNLOAD_PAGE,
  SET_IS_ADDITIONAL_DEVICE_STEPS_DIALOG_OPEN,
  SET_IS_ISSUE_CODE_DIALOG_OPEN,
  SET_IS_REDEEM_CODE_DIALOG_OPEN,
  SET_SCREEN_CONTENT,
  SET_WORKER_REDEMPTION_CODE_LOADING,
  WORKER_REDEMPTION_CODE_GET_ERROR,
  WORKER_REDEMPTION_CODE_GET_SUCCESS,
} from './actionType'

export const screenContentState = { LANDING: 'landing', FIRST: 'first', RETURNING: 'returning' }
export const initialState = {
  data: null,
  loading: false,
  getError: null,
  screenContent: screenContentState.LANDING,
  isIssueCodeDialogOpen: false,
  isRedeemCodeDialogOpen: false,
  isAdditionalDeviceStepsDialogOpen: false,
}

export default function workerRedemptionCode(state = initialState, action = {}) {
  switch (action.type) {
    case WORKER_REDEMPTION_CODE_GET_ERROR: {
      const { record } = action
      return {
        ...state,
        getError: record.response ? record.response.data : record,
        loading: false,
      }
    }
    case WORKER_REDEMPTION_CODE_GET_SUCCESS: {
      const { record } = action
      return {
        ...state,
        data: record.data,
        loading: false,
        getError: null,
      }
    }
    case SET_WORKER_REDEMPTION_CODE_LOADING: {
      const { payload } = action
      return {
        ...state,
        loading: payload,
      }
    }
    case SET_SCREEN_CONTENT: {
      const { payload } = action
      return {
        ...state,
        screenContent: payload,
      }
    }
    case SET_IS_ISSUE_CODE_DIALOG_OPEN: {
      const { isOpen } = action
      return {
        ...state,
        isIssueCodeDialogOpen: isOpen,
      }
    }
    case SET_IS_REDEEM_CODE_DIALOG_OPEN: {
      const { isOpen } = action
      return {
        ...state,
        isRedeemCodeDialogOpen: isOpen,
      }
    }
    case SET_IS_ADDITIONAL_DEVICE_STEPS_DIALOG_OPEN: {
      const { isOpen } = action
      return {
        ...state,
        isAdditionalDeviceStepsDialogOpen: isOpen,
      }
    }
    case RESET_APPLE_IOS_DOWNLOAD_PAGE: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
