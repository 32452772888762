const SwapRequestInitatedIcon = (props) => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Withdraw Swap Request</title>
      <defs>
        <path
          d="M3.76999794,0.751286391 C2.35543003,0.778556028 1.42094577,0.943938118 0.966545152,1.24743266 C0.512144538,1.5509272 0.189962821,1.97444455 0,2.51798472 L0,15.5022566 L0,18.067268 C1.09728339,18.7832779 1.97345569,19.2253037 2.62851689,19.3933453 C3.2835781,19.5613869 4.32176456,19.7636051 5.74307628,20 L4.76685656,18.067268 C3.25779206,15.963321 2.69292087,14.8810445 3.07224301,14.8204384 C3.45156514,14.7598324 4.01643633,14.3196645 4.76685656,13.4999347 L7.12883893,10.9935443 L9.08244382,10.9935443 L10.3471684,10.3756432 L12.7229692,7.84327465 L14.2237533,9.50225663 L14.2237533,0.751286391 L3.76999794,0.751286391 Z"
          id="path-1"
        ></path>
      </defs>
      <g id="Shift-Swap-InProgress-icon-Copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(-0.000000, 0.000000)">
          <circle id="Oval" stroke="#CB0202" cx="12.5" cy="12.5" r="12"></circle>
          <g id="Hour-Glass" transform="translate(4.625204, 3.131839)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Mask"></g>
            <g mask="url(#mask-2)">
              <g transform="translate(1.722969, 2.517985)">
                <polygon
                  id="Path"
                  stroke="#CB0202"
                  strokeWidth="1"
                  fill="none"
                  points="10.8117395 1.11022302e-13 10.8117395 3.19820886 6.798 7.491 10.8117395 11.7832243 10.8117395 14.9814332 -1.13686838e-13 14.9814332 -1.13686838e-13 11.7832243 4.012 7.491 -1.13686838e-13 3.19820886 -1.13686838e-13 1.11022302e-13"
                ></polygon>
                <polygon
                  id="Path-3"
                  stroke="none"
                  fill="#CC0000"
                  fillRule="evenodd"
                  points="1.6127752 2.71013537 9.33173531 2.71013537 5.61344205 5.98427191"
                ></polygon>
                <polygon
                  id="Path-4"
                  stroke="none"
                  fill="#F09494"
                  fillRule="evenodd"
                  points="2.28248714 12.4551756 2.04702875 12.6032677 2.04702875 13.6035919 8.62419925 13.6035919 8.62419925 12.6032677 5.335614 8.98143316"
                ></polygon>
              </g>
            </g>
          </g>
          <g id="Swap" transform="translate(8.669964, 12.634096)" fill="#CC0000">
            <polygon
              id="Path"
              points="2.36363636 3.17835517 7.30839103 3.17835517 7.30839103 0 13 4.72727273 7.30839103 4.72727273 2.36363636 4.72727273"
            ></polygon>
            <polygon
              id="Path"
              transform="translate(5.318182, 8.272727) rotate(-180.000000) translate(-5.318182, -8.272727) "
              points="0 9.19410462 4.94475467 9.19410462 4.94475467 5.90909091 10.6363636 10.6363636 4.94475467 10.6363636 0 10.6363636"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default SwapRequestInitatedIcon
