import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'

const getCommunication = async (locationId, workerId, isForPopup, page = 1, perPage = 25) => {
  let apiUrl =
    `${apiConfig.userProfiles.url}` +
    '/communications' +
    '?worker_id=' +
    workerId +
    '&location_id=' +
    locationId +
    '&page=' +
    page +
    '&per_page=' +
    perPage +
    '&key=' +
    `${apiConfig.userProfiles.key}`

  const result = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  if (isForPopup) {
    return getUnviewedCommunications(result?.data)
  }

  return result
}

const getUnviewedCommunications = (data) => {
  let savedViewedCommunications = JSON.parse(localStorage.getItem('viewed_communications'))
  if (!savedViewedCommunications) return data

  let unviewedCommunications = data?.communications?.reduce((acc, communication) => {
    let doesExist = savedViewedCommunications.some((savedCom) => {
      return savedCom.id === communication.id && savedCom.updated_timestamp === communication.updated_timestamp
    })

    if (!doesExist) {
      acc.unshift(communication)
    }

    return acc
  }, [])

  return { ...data, communications: unviewedCommunications, total_communications: unviewedCommunications?.length }
}

export function useCommunication(isForPopup, page, perPage) {
  const { session, isAuthenticated, login } = useAuth()
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: ['communications', isForPopup, page, perPage],
    queryFn: () =>
      getCommunication(
        session?.userInfo?.locationid,
        padEmpIdWithZeros(session?.userInfo?.empid, 10),
        isForPopup,
        page,
        perPage,
      ),
    enabled: authenticated,
    retry: isForPopup ? false : 1,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    refetchInterval: isForPopup ? 1000 * 60 : false,
    keepPreviousData: true,
  })
}
