import React, { useEffect, useState } from 'react'
import { MultiClickButton as SwapShiftMessageButton } from '../../utils/MultiClickButton'
import { useSelector } from 'react-redux'
import ShiftSwapMessageModal from './ShiftSwapMessageModal'

const CLOSED_BUT_NOT_SWAPPED = 'CLOSED_BUT_NOT_SWAPPED'
const FAILED_DUE_TO_RULE_VIOLATION = 'FAILED_DUE_TO_RULE_VIOLATION'
const FAILED_DUE_TO_NETWORK_ISSUE = 'FAILED_DUE_TO_NETWORK_ISSUE'

const SwapShiftsMessgeAction = (props) => {
  const [open, setOpen] = useState(false)
  const [processIndicator, setProcessIndicator] = useState(false)

  const postShiftPostResponse = useSelector((state) => state.weeklySchedule.postShiftPostResponse)
  const postShiftPostErrResponse = useSelector((state) => state.weeklySchedule.postShiftPostErrResponse)

  useEffect(() => {
    setProcessIndicator(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postShiftPostResponse, postShiftPostErrResponse])

  const disableCoverShiftButton = () => {
    setProcessIndicator(true)
  }

  const findOpenRequest = (requestsArr) => {
    if (requestsArr && requestsArr.length > 0) {
      const openShifts = requestsArr.filter(
        (item) =>
          (item.status.toLowerCase() === 'closed' && !item.is_shift_swapped) || item.status.toLowerCase() === 'failed',
      )
      return openShifts[0]
    } else {
      return null
    }
  }

  const renderSwapShiftButton = () => {
    const { styles, counter, displaySegment } = props
    const selectedShift = findOpenRequest(displaySegment.swapshiftRequest)

    let message = ''
    if (displaySegment.swapStatusAsRequester === CLOSED_BUT_NOT_SWAPPED) {
      message = 'Your swap request was NOT accepted. Please try re-initiate swap request with other shift options.'
    } else if (displaySegment.swapStatusAsRequester === FAILED_DUE_TO_RULE_VIOLATION) {
      message = 'Your swap request has been failed due to rule violation.'
    } else if (displaySegment.swapStatusAsRequester === FAILED_DUE_TO_NETWORK_ISSUE) {
      message = 'Your swap request has been failed due to network issues. Please try again.'
    }

    return (
      <React.Fragment key={counter}>
        <ShiftSwapMessageModal
          open={open}
          handleClose={() => handleClose()}
          styles={styles}
          shift={selectedShift}
          schedule={props.schedule}
          message={message}
          disableCoverShiftButton={() => disableCoverShiftButton()}
        />

        {shiftSwapButtonActions(displaySegment)}
      </React.Fragment>
    )
  }

  const shiftSwapButtonActions = (displaySegment) => {
    const { styles } = props
    if (displaySegment.swapStatusAsRequester === 'PAST' || !displaySegment.swapStatusAsRequester) {
      return null
    } else {
      return (
        <SwapShiftMessageButton
          ariaLabel={'Swap'}
          label=""
          handleClick={handleGiveUpShiftClick}
          handleDoubleClick={handleGiveUpShiftClick}
          processIndicator={processIndicator}
          styles={styles}
          pageName="shift-swap-message"
          data-cy="shiftSwapMessageBtn"
        />
      )
    }
  }

  const handleGiveUpShiftClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return <React.Fragment>{renderSwapShiftButton(props)}</React.Fragment>
}

export default SwapShiftsMessgeAction
