import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import InnerLoader from '../Loader/InnerLoader'
import { useTheme } from '@mui/material'

const getStyles = (theme) => ({
  cardTitleContainer: {
    display: 'flex',
    flexGrow: '1',
    gap: 5,
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  cardTitle: {
    marginLeft: '.4em',
    fontWeight: 'bold',
  },
  refetchingContainer: {
    marginLeft: '.4em',
  },
})

/**
 *
 * @param {Component} Icon: icon for the card
 * @param {string} title: title of card
 * @param {string} subTitle: text to the side of title.
 * @param {string} isRefetching: isRefetching indicator is only true when we already have data, and query is refetching in background
 * @param {} children: children renders all child components under HomePageCard
 * @param {() => CardActions} cardActions: a function that returns mui CardActions
 * @returns Card with basic header and any actions passed through.
 */
const HomePageCard = ({ icon, title, subTitle, isRefetching, children, cardActions, cardProps }) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  return (
    <Card sx={cardProps}>
      <CardContent>
        <div style={styles.cardTitleContainer}>
          {icon}
          <Typography variant="subtitle1" component="h3" sx={styles.cardTitle}>
            {title}
          </Typography>
          {subTitle && (
            <Typography variant="subtitle2" component="h4">
              {subTitle}
            </Typography>
          )}
          {isRefetching && (
            <div style={styles.refetchingContainer}>
              <InnerLoader size={20} />
            </div>
          )}
        </div>
        {children}
      </CardContent>
      {cardActions && cardActions()}
    </Card>
  )
}

export default HomePageCard
