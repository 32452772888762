import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker'
import { renderMultiSectionDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers'

const thisSlotProps = {
  textField: (record) => ({
    ...record,
    InputProps: {
      ...record.inputProps,
    },
  }),
  toolbar: {
    hidden: true,
  },
  actionBar: {
    actions: ['accept'],
  },
  mobilePaper: {
    sx: {
      minWidth: '0px !important',
    },
  },
}

const TimePicker = (props) => {
  return (
    <MuiTimePicker
      {...props}
      skipDisabled
      viewRenderers={{
        hours: renderMultiSectionDigitalClockTimeView,
        minutes: renderMultiSectionDigitalClockTimeView,
      }}
      slotProps={{ ...thisSlotProps, ...props.slotProps }}
    />
  )
}
export default TimePicker
