import { Typography } from '@mui/material'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart'

export const FLipCardContent = ({ cardContent }) => {
  return (
    <>
      {(cardContent.type === 'strengths' || cardContent.type === 'opportunities') && (
        <Typography
          sx={{
            overflow: 'auto',
            maxHeight: '100%',
            whiteSpace: 'pre-wrap',
            textAlign: 'left',
            fontSize: '14px',
          }}
        >
          {cardContent.backContent}
        </Typography>
      )}
      {cardContent.type === 'sentiment_analysis' && (
        <>
          <div style={{ backgroundColor: 'white' }}>
            <PieChart
              style={{ backgroundColor: 'white' }}
              colors={['#EA4335', '#1E976F', '#FEC282']}
              series={[
                {
                  arcLabel: (item) => `${item.value}%`,
                  arcLabelMinAngle: 45,
                  data: cardContent?.data,
                  arcLabelsTextColor: 'white',
                  arcLabelsTextStyle: {
                    fontWeight: 'bold',
                  },
                  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: '#E0E0E0',
                  fontWeight: 'bold',
                  textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                },
              }}
              width={400}
              height={200}
            />
          </div>
        </>
      )}
    </>
  )
}

export default FLipCardContent
