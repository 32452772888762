import { useTheme } from '@mui/material'
import { useSwapShiftRequests } from '../../api/swapShifts/useSwapShiftRequests'
import InnerLoader from '../Loader/InnerLoader'

import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import HomePageCard from '../common/HomePageCard'
import ShiftSwapCalendarIcon from '../Icons/ShiftSwapCalendarIcon'
import SwapShiftStatus from './SwapShiftStatus'

const SWAP_SHIFT_ERROR_MESSAGE = 'Unable to load shift swap dashboard at this time.'
const SWAP_SHIFT_ERROR_CODES_SHOW_MESSAGE = ['wfm-17-5', 'wfm-17-11']
const ERROR_CODE_CANNOT_CONNECT_TO_SWAP_SHIFT = 'wfm-17-0'

const getStyles = (theme) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  gridItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '6px',
    marginBottom: '6px',
  },
  nextAvailableShift: {
    marginRight: theme.spacing(1),
  },
  nextAvailableWeek: {
    marginLeft: theme.spacing(1),
  },
  multiShiftLabel: {
    border: '1px solid #333',
    color: '#333',
    padding: '1px 5px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    backgroundColor: '#fff',
    boxShadow: '4px -3px #fff, 5px -4px #000, 3px -4px #000, 5px -2px #000',
  },
  shiftSwapWeekTitle: {
    textAlign: 'center',
  },
})

const SwapShiftsCard = (props) => {
  const { renderHomePageCardError } = props

  const { isRefetching, data, error } = useSwapShiftRequests()
  const theme = useTheme()
  const styles = getStyles(theme)

  const renderSwapShiftsData = () => {
    return (
      <Grid container>
        <Grid item xs>
          <SwapShiftStatus
            styles={styles}
            requestCount={data.total_shift_swap_request_raised}
            message={'request(s) you initated for swapping'}
          />
        </Grid>
        <Divider className="" orientation="vertical" variant="middle" flexItem />
        <Grid item xs>
          <SwapShiftStatus
            styles={styles}
            requestCount={data.total_shift_swap_request_received}
            message={'request(s) awaiting to be reviewed'}
          />
        </Grid>
      </Grid>
    )
  }

  /**
   * wondering why we check data first and not isLoading?
   * Check out this blog: https://tkdodo.eu/blog/status-checks-in-react-query
   */
  const renderShiftSwapResponse = () => {
    if (data) return renderSwapShiftsData()
    if (error) {
      return renderHomePageCardError(
        error?.response?.data,
        SWAP_SHIFT_ERROR_MESSAGE,
        SWAP_SHIFT_ERROR_CODES_SHOW_MESSAGE,
        ERROR_CODE_CANNOT_CONNECT_TO_SWAP_SHIFT,
      )
    }
    return <InnerLoader size={48} />
  }

  return (
    <Grid item xs={12} md={12} sx={styles.gridItem}>
      <HomePageCard title="Swap Shifts" icon={<ShiftSwapCalendarIcon />} isRefetching={isRefetching}>
        {renderShiftSwapResponse()}
      </HomePageCard>
    </Grid>
  )
}

export default SwapShiftsCard
