const GROUP_ONE = [82, 223, 2546]

const GROUP_TWO = [864, 1311, 1895, 2199, 2586, 2780, 2877]

const GROUP_THREE = [
  755, 962, 964, 1015, 1022, 1104, 1107, 1226, 1824, 1932, 2111, 2168, 2785, 961, 1080, 1504, 1794, 1826, 1872, 1892,
  2069, 2721, 2784, 2873, 3241, 3255,
]

const GROUP_FOUR = [796, 982]

const GROUP_FIVE = [749, 970, 1197, 1394, 2056, 2171, 2333, 2387]

const GROUP_SIX = [2429, 2725, 1066, 1953, 1982, 2495]

const GROUP_SEVEN = [647, 688, 845, 874, 897, 898, 1294, 1795, 1966, 2364]

const GROUP_EIGHT = [95, 1797, 2342, 2409]

const GROUP_NINE = [355, 531, 1487, 1543, 1756, 1757, 1759, 1821, 1842, 2043]

const GROUP_TEN = [2410, 2697, 2888]

const GROUP_ELEVEN = [1016, 1017, 1018, 1019, 1041, 1049, 1225, 1931, 1968, 2337, 2436, 2478]

export const getMultiLocationGroup = (homeLocation) => {
  if (typeof homeLocation === 'string') {
    homeLocation = parseInt(homeLocation)
  }

  if (GROUP_ONE.includes(homeLocation)) return createLocationObjectsArray(GROUP_ONE, homeLocation)
  else if (GROUP_TWO.includes(homeLocation)) {
    return createLocationObjectsArray([...GROUP_TWO, ...GROUP_ONE], homeLocation)
  } else if (GROUP_THREE.includes(homeLocation)) return createLocationObjectsArray(GROUP_THREE, homeLocation)
  else if (GROUP_FOUR.includes(homeLocation)) return createLocationObjectsArray(GROUP_FOUR, homeLocation)
  else if (GROUP_FIVE.includes(homeLocation)) {
    return createLocationObjectsArray([...GROUP_FIVE, ...GROUP_FOUR], homeLocation)
  } else if (GROUP_SIX.includes(homeLocation)) return createLocationObjectsArray(GROUP_SIX, homeLocation)
  else if (GROUP_SEVEN.includes(homeLocation)) return createLocationObjectsArray(GROUP_SEVEN, homeLocation)
  else if (GROUP_EIGHT.includes(homeLocation)) return createLocationObjectsArray(GROUP_EIGHT, homeLocation)
  else if (GROUP_NINE.includes(homeLocation)) return createLocationObjectsArray(GROUP_NINE, homeLocation)
  else if (GROUP_TEN.includes(homeLocation)) return createLocationObjectsArray(GROUP_TEN, homeLocation)
  else if (GROUP_ELEVEN.includes(homeLocation)) return createLocationObjectsArray(GROUP_ELEVEN, homeLocation)
  else return defaultLocationObject(homeLocation)
}

const createLocationObjectsArray = (locationGroup, homeLocation) => {
  return [
    MULTI_LOCATION_PILOT_VALUES[homeLocation],
    ...locationGroup
      .filter((location) => location !== homeLocation)
      .map((location) => MULTI_LOCATION_PILOT_VALUES[location])
      .sort((a, b) => a.store_name.localeCompare(b.store_name)),
  ]
}

const defaultLocationObject = (homeLocation) => {
  return [
    {
      location: homeLocation,
      store_name: 'Home Location',
      state: '',
      address: '',
      city: '',
      phone_number: '',
    },
  ]
}

export const MULTI_LOCATION_PILOT_VALUES = {
  82: {
    location: 82,
    store_name: 'Waukesha',
    state: 'WI',
    address: '2401 Kossow Rd',
    city: 'Waukesha',
    phone_number: '262-784-8646',
  },
  223: {
    location: 223,
    store_name: 'Milwaukee Chase',
    state: 'WI',
    address: '2950 S Chase Ave',
    city: 'Milwaukee',
    phone_number: '414-483-2000',
  },
  864: {
    location: 864,
    store_name: 'Delafield',
    state: 'WI',
    address: '2553 Sun Valley Dr',
    city: 'Delafield',
    phone_number: '262-646-5200',
  },
  1311: {
    location: 1311,
    store_name: 'New Berlin',
    state: 'WI',
    address: '4798 S Moorland Rd',
    city: 'New Berlin',
    phone_number: '262-207-0013',
  },
  1895: {
    location: 1895,
    store_name: 'West Milwaukee',
    state: 'WI',
    address: '1501 Miller Park Way',
    city: 'West Milwaukee',
    phone_number: '414-203-0106',
  },
  2199: {
    location: 2199,
    store_name: 'West Allis',
    state: 'WI',
    address: '2600 S 108th St',
    city: 'West Allis',
    phone_number: '414-545-8000',
  },
  2546: {
    location: 2546,
    store_name: 'Waukesha South',
    state: 'WI',
    address: '1250 W Sunset Dr',
    city: 'Waukesha',
    phone_number: '262-832-1272',
  },
  2586: {
    location: 2586,
    store_name: 'Wauwatosa',
    state: 'WI',
    address: '3900 N 124th St',
    city: 'Wauwatosa',
    phone_number: '414-847-1100',
  },
  2780: {
    location: 2780,
    store_name: 'Brookfield',
    state: 'WI',
    address: '12725 W Bluemound Rd',
    city: 'Brookfield',
    phone_number: '262-395-1625',
  },
  2877: {
    location: 2877,
    store_name: 'Glendale North Shore',
    state: 'WI',
    address: '5701 N Lydell Ave',
    city: 'Glendale',
    phone_number: '414-516-1236',
  },
  755: {
    location: 755,
    store_name: 'Fayetteville',
    state: 'NC',
    address: '2056 Skibo Rd',
    city: 'Fayetteville',
    phone_number: '910-860-4400',
  },
  962: {
    location: 962,
    store_name: 'Rocky Mount',
    state: 'NC',
    address: '731 Sutters Creek Blvd',
    city: 'Rocky Mount',
    phone_number: '252-443-1195',
  },
  964: {
    location: 964,
    store_name: 'Goldsboro',
    state: 'NC',
    address: '1204 Sunburst Dr',
    city: 'Goldsboro',
    phone_number: '919-778-2150',
  },
  1015: {
    location: 1015,
    store_name: 'New Bern',
    state: 'NC',
    address: '3410 Dr Martin Luther King Jr Blvd',
    city: 'New Bern',
    phone_number: '252-638-2954',
  },
  1022: {
    location: 1022,
    store_name: 'Greenville',
    state: 'NC',
    address: '3040 S Evans St',
    city: 'Greenville',
    phone_number: '252-355-8020',
  },
  1104: {
    location: 1104,
    store_name: 'Garner',
    state: 'NC',
    address: '2700 Timber Dr',
    city: 'Garner',
    phone_number: '919-772-9065',
  },
  1107: {
    location: 1107,
    store_name: 'Wilmington',
    state: 'NC',
    address: '4711 New Centre Dr',
    city: 'Wilmington',
    phone_number: '910-395-5057',
  },
  1226: {
    location: 1226,
    store_name: 'Jacksonville',
    state: 'NC',
    address: '1395D Western Blvd',
    city: 'Jacksonville',
    phone_number: '910-937-6400',
  },
  1824: {
    location: 1824,
    store_name: 'White Oak Garner',
    state: 'NC',
    address: '1040 Timber Dr E',
    city: 'Garner',
    phone_number: '919-661-2017',
  },
  1932: {
    location: 1932,
    store_name: 'Apex',
    state: 'NC',
    address: '1201 Beaver Creek Commons Dr',
    city: 'Apex',
    phone_number: '919-372-1405',
  },
  2111: {
    location: 2111,
    store_name: 'Knightdale',
    state: 'NC',
    address: '1000 Shoppes at Midway Dr',
    city: 'Knightdale',
    phone_number: '919-388-6100',
  },
  2168: {
    location: 2168,
    store_name: 'Wilson',
    state: 'NC',
    address: '3401 Raleigh Road Pkwy W',
    city: 'Wilson',
    phone_number: '252-265-4500',
  },
  2785: {
    location: 2785,
    store_name: 'Holly Springs',
    state: 'NC',
    address: '100 Grand Hill Place',
    city: 'Holly Springs',
    phone_number: '919-762-3149',
  },
  961: {
    location: 961,
    store_name: 'Cary',
    state: 'NC',
    address: '2021 Walnut St',
    city: 'Cary',
    phone_number: '919-233-4451',
  },
  1080: {
    location: 1080,
    store_name: 'Raleigh Hwy 70',
    state: 'NC',
    address: '4841 Grove Barton Rd',
    city: 'Raleigh',
    phone_number: '919-785-1803',
  },
  1504: {
    location: 1504,
    store_name: 'Wake Forest',
    state: 'NC',
    address: '12000 Retail Dr',
    city: 'Wake Forest',
    phone_number: '919-761-1001',
  },
  1794: {
    location: 1794,
    store_name: 'Raleigh NW',
    state: 'NC',
    address: '8651 Brier Creek Pkwy',
    city: 'Raleigh',
    phone_number: '919-765-0005',
  },
  1826: {
    location: 1826,
    store_name: 'Raleigh NE',
    state: 'NC',
    address: '7900 Old Wake Forest Rd',
    city: 'Raleigh',
    phone_number: '919-790-1024',
  },
  1872: {
    location: 1872,
    store_name: 'Durham',
    state: 'NC',
    address: '4037 Durham Chapel Hill Blvd',
    city: 'Durham',
    phone_number: '919-765-0008',
  },
  1892: {
    location: 1892,
    store_name: 'Raleigh Central',
    state: 'NC',
    address: '4191 The Circle at North Hills St',
    city: 'Raleigh',
    phone_number: '919-781-9848',
  },
  2069: {
    location: 2069,
    store_name: 'Durham SE',
    state: 'NC',
    address: '8210 Renaissance Pkwy',
    city: 'Durham',
    phone_number: '919-425-0001',
  },
  2721: {
    location: 2721,
    store_name: 'Morrisville',
    state: 'NC',
    address: '3001 Market Center Dr',
    city: 'Morrisville',
    phone_number: '919-379-2180',
  },
  2784: {
    location: 2784,
    store_name: 'Cary West',
    state: 'NC',
    address: "7129 O'Kelly Chapel Road",
    city: 'Cary',
    phone_number: '919-674-2214',
  },
  2873: {
    location: 2873,
    store_name: 'Raleigh Six Forks Rd',
    state: 'NC',
    address: '8701 Six Forks Rd',
    city: 'Raleigh',
    phone_number: '919-326-0118',
  },
  3241: {
    location: 3241,
    store_name: 'UNC Franklin St',
    state: 'NC',
    address: '143 W Franklin St, Ste 120',
    city: 'Chapel Hill',
    phone_number: '919-590-4925',
  },
  3255: {
    location: 3255,
    store_name: 'Raleigh NC State Hillsborough St',
    state: 'NC',
    address: '2512 Hillsborough St',
    city: 'Raleigh',
    phone_number: '919-376-2740',
  },
  2429: {
    location: 2429,
    store_name: 'New Braunfels',
    state: 'TX',
    address: '135 Creekside Way',
    city: 'New Braunfels',
    phone_number: '830-608-3280',
  },
  2725: {
    location: 2725,
    store_name: 'Kyle',
    state: 'TX',
    address: '5188 Kyle Center Dr',
    city: 'Kyle',
    phone_number: '512-268-7550',
  },
  95: {
    location: 95,
    store_name: 'Austin North',
    state: 'TX',
    address: '8601 Research Blvd',
    city: 'Austin',
    phone_number: '512-837-5163',
  },
  1066: {
    location: 1066,
    store_name: 'Round Rock',
    state: 'TX',
    address: '121 Louis Henna Blvd',
    city: 'Round Rock',
    phone_number: '512-246-7677',
  },
  1797: {
    location: 1797,
    store_name: 'Austin Lakeline Mall Dr',
    state: 'TX',
    address: '10900 Lakeline Mall Dr',
    city: 'Austin',
    phone_number: '512-651-3376',
  },
  1953: {
    location: 1953,
    store_name: 'Four Points',
    state: 'TX',
    address: '11220 FM 2222',
    city: 'Austin',
    phone_number: '512-651-0086',
  },
  1982: {
    location: 1982,
    store_name: 'Georgetown',
    state: 'TX',
    address: '1021 W University Ste B3',
    city: 'Georgetown',
    phone_number: '512-869-3163',
  },
  2342: {
    location: 2342,
    store_name: 'Cedar Park',
    state: 'TX',
    address: '1101 C-Bar Ranch Trl Lot #2',
    city: 'Cedar Park',
    phone_number: '512-456-2933',
  },
  2409: {
    location: 2409,
    store_name: 'Austin Arboretum',
    state: 'TX',
    address: '10107 Research Blvd',
    city: 'Austin',
    phone_number: '512-687-1315',
  },
  2495: {
    location: 2495,
    store_name: 'Pflugerville',
    state: 'TX',
    address: '18700 Limestone Commercial Dr',
    city: 'Pflugerville',
    phone_number: '512-687-2064',
  },
  647: {
    location: 647,
    store_name: 'Altamonte Springs',
    state: 'FL',
    address: '886 W State Road 436',
    city: 'Altamonte Springs',
    phone_number: '407-788-7377',
  },
  688: {
    location: 688,
    store_name: 'Daytona Beach',
    state: 'FL',
    address: '2380 W International Speedway Blvd',
    city: 'Daytona Beach',
    phone_number: '386-257-4778',
  },
  845: {
    location: 845,
    store_name: 'Lake Mary',
    state: 'FL',
    address: '4155 W Lake Mary Blvd',
    city: 'Lake Mary',
    phone_number: '407-330-9330',
  },
  874: {
    location: 874,
    store_name: 'Orange City',
    state: 'FL',
    address: '2575 Enterprise Rd',
    city: 'Orange City',
    phone_number: '386-774-5574',
  },
  897: {
    location: 897,
    store_name: 'Oviedo',
    state: 'FL',
    address: '820 Oviedo Mall Blvd',
    city: 'Oviedo',
    phone_number: '407-366-5900',
  },
  898: {
    location: 898,
    store_name: 'Casselberry',
    state: 'FL',
    address: '4410 S Highway 17 92',
    city: 'Casselberry',
    phone_number: '407-830-7979',
  },
  1294: {
    location: 1294,
    store_name: 'St Augustine',
    state: 'FL',
    address: '1440 US Hwy 1 S',
    city: 'Saint Augustine',
    phone_number: '904-810-2336',
  },
  1795: {
    location: 1795,
    store_name: 'Port Orange',
    state: 'FL',
    address: '1771 Dunlawton Ave',
    city: 'Port Orange',
    phone_number: '386-322-1348',
  },
  1966: {
    location: 1966,
    store_name: 'Sanford',
    state: 'FL',
    address: '1201 WP Ball Blvd',
    city: 'Sanford',
    phone_number: '407-547-0007',
  },
  2364: {
    location: 2364,
    store_name: 'Palm Coast',
    state: 'FL',
    address: '5100 E Highway 100',
    city: 'Palm Coast',
    phone_number: '386-313-3951',
  },
  749: {
    location: 749,
    store_name: 'Johns Creek State St and 141',
    state: 'GA',
    address: '5950 State Bridge Rd',
    city: 'Johns Creek',
    phone_number: '770-476-5548',
  },
  796: {
    location: 796,
    store_name: 'Gainesville',
    state: 'GA',
    address: '514 Shallowford Rd NW',
    city: 'Gainesville',
    phone_number: '770-532-6670',
  },
  970: {
    location: 970,
    store_name: 'Alpharetta',
    state: 'GA',
    address: '6000 N Point Pkwy',
    city: 'Alpharetta',
    phone_number: '770-664-5395',
  },
  982: {
    location: 982,
    store_name: 'Peachtree Corners',
    state: 'GA',
    address: '3200 Holcomb Bridge Rd',
    city: 'Peachtree Corners',
    phone_number: '770-849-0885',
  },
  1197: {
    location: 1197,
    store_name: 'Buckhead',
    state: 'GA',
    address: '3535 Peachtree Rd NE',
    city: 'Atlanta',
    phone_number: '404-237-9494',
  },
  1394: {
    location: 1394,
    store_name: 'Cumming',
    state: 'GA',
    address: '1525 Market Place Blvd',
    city: 'Cumming',
    phone_number: '678-455-9618',
  },
  2056: {
    location: 2056,
    store_name: 'Suwanee Peachtree Pkwy',
    state: 'GA',
    address: '2625 Peachtree Pkwy',
    city: 'Suwanee',
    phone_number: '678-513-8013',
  },
  2171: {
    location: 2171,
    store_name: 'Buckhead South',
    state: 'GA',
    address: '2539 Piedmont Rd NE',
    city: 'Atlanta',
    phone_number: '404-720-1081',
  },
  2333: {
    location: 2333,
    store_name: 'Sandy Springs Prado',
    state: 'GA',
    address: '5570 Roswell Rd',
    city: 'Sandy Springs',
    phone_number: '678-704-8120',
  },
  2387: {
    location: 2387,
    store_name: 'Flowery Branch',
    state: 'GA',
    address: '5865 Spout Springs Rd',
    city: 'Flowery Branch',
    phone_number: '770-967-1205',
  },
  355: {
    location: 355,
    store_name: 'Topeka',
    state: 'KS',
    address: '2120 SW Wanamaker Rd',
    city: 'Topeka',
    phone_number: '785-273-9841',
  },
  531: {
    location: 531,
    store_name: 'Lawrence',
    state: 'KS',
    address: '3201 S Iowa St',
    city: 'Lawrence',
    phone_number: '785-832-0660',
  },
  1487: {
    location: 1487,
    store_name: 'Mission',
    state: 'KS',
    address: '6100 Broadmoor St',
    city: 'Mission',
    phone_number: '913-262-2434',
  },
  1543: {
    location: 1543,
    store_name: 'Olathe South',
    state: 'KS',
    address: '20255 W 154th St',
    city: 'Olathe',
    phone_number: '913-782-5975',
  },
  1756: {
    location: 1756,
    store_name: 'Olathe',
    state: 'KS',
    address: '15345 W 119th St',
    city: 'Olathe',
    phone_number: '913-393-4400',
  },
  1757: {
    location: 1757,
    store_name: 'Overland Park',
    state: 'KS',
    address: '12200 Blue Valley Pkwy',
    city: 'Overland Park',
    phone_number: '913-327-7770',
  },
  1759: {
    location: 1759,
    store_name: 'Shawnee',
    state: 'KS',
    address: '15700 Shawnee Mission Pkwy',
    city: 'Shawnee',
    phone_number: '913-962-8222',
  },
  1821: {
    location: 1821,
    store_name: 'Manhattan',
    state: 'KS',
    address: '800 Commons pl',
    city: 'Manhattan',
    phone_number: '785-770-3643',
  },
  1842: {
    location: 1842,
    store_name: 'Overland Park South',
    state: 'KS',
    address: '8101 W 151st',
    city: 'Overland Park',
    phone_number: '913-905-0419',
  },
  2043: {
    location: 2043,
    store_name: 'Overland Park West',
    state: 'KS',
    address: '11501 W 97th St',
    city: 'Overland Park',
    phone_number: '913-905-0285',
  },
  2410: {
    location: 2410,
    store_name: 'Oahu-Honolulu Salt Lake',
    state: 'HI',
    address: '4380 Lawehana St',
    city: 'Honolulu',
    phone_number: '808-441-3118',
  },
  2697: {
    location: 2697,
    store_name: 'Oahu-Kailua',
    state: 'HI',
    address: '345 Hahani st',
    city: 'Kailua',
    phone_number: '808-489-9319',
  },
  2888: {
    location: 2888,
    store_name: 'Oahu Windward Mall',
    state: 'HI',
    address: '46-056 Kamehameha Hwy',
    city: 'Kaneohe',
    phone_number: '808-664-4082',
  },
  1016: {
    location: 1016,
    store_name: 'Colonial Heights',
    state: 'VA',
    address: '721 Southpark Blvd',
    city: 'Colonial Heights',
    phone_number: '804-520-0190',
  },
  1017: {
    location: 1017,
    store_name: 'Chester',
    state: 'VA',
    address: '2530 Weir Rd',
    city: 'Chester',
    phone_number: '804-768-9974',
  },
  1018: {
    location: 1018,
    store_name: 'Richmond Central',
    state: 'VA',
    address: '5401 W Broad St',
    city: 'Richmond',
    phone_number: '804-285-3492',
  },
  1019: {
    location: 1019,
    store_name: 'Virginia Center Commons',
    state: 'VA',
    address: '10100 Brook Rd',
    city: 'Glen Allen',
    phone_number: '804-262-2390',
  },
  1041: {
    location: 1041,
    store_name: 'Richmond Midlothian Tpke',
    state: 'VA',
    address: '11301 Midlothian Tpke',
    city: 'Richmond',
    phone_number: '804-897-5711',
  },
  1049: {
    location: 1049,
    store_name: 'Glen Allen Broad St',
    state: 'VA',
    address: '11290 W Broad St',
    city: 'Glen Allen',
    phone_number: '804-360-8900',
  },
  1225: {
    location: 1225,
    store_name: 'Richmond Brandermill',
    state: 'VA',
    address: '4601 Commonwealth Centre Pkwy',
    city: 'Midlothian',
    phone_number: '804-639-0580',
  },
  1931: {
    location: 1931,
    store_name: 'Forest Hill',
    state: 'VA',
    address: '7107 Forest Hill Ave',
    city: 'Richmond',
    phone_number: '804-330-7365',
  },
  1968: {
    location: 1968,
    store_name: 'Mechanicsville',
    state: 'VA',
    address: '7235 Bell Creek Rd',
    city: 'Mechanicsville',
    phone_number: '804-559-5802',
  },
  2337: {
    location: 2337,
    store_name: 'Henrico Staples Mill Rd',
    state: 'VA',
    address: '9001 Staples Mill Rd',
    city: 'Henrico',
    phone_number: '804-672-5347',
  },
  2436: {
    location: 2436,
    store_name: 'Richmond East',
    state: 'VA',
    address: '4521 S Labumum Ave',
    city: 'Richmond',
    phone_number: '804-836-1860',
  },
  2478: {
    location: 2478,
    store_name: 'Midlothian Perimeter Dr',
    state: 'VA',
    address: '201 Perimeter Dr',
    city: 'Midlothian',
    phone_number: '804-419-8140',
  },
}
