import {
  GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_ERROR,
  GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_SUCCESS,
  LEADER_AUTOMATION_LOCATION_ERROR,
  LEADER_AUTOMATION_LOCATION_SUCCESS,
  LEADER_AUTOMATION_SET_LOCATION_LOADING,
  LEADER_AUTOMATION_SET_NAVIGATE_TO_NEXT_LOCATION,
  LEADER_AUTOMATION_SET_NEXT_LOCATION,
  SET_LEADER_AUTOMATION_EXIT_DIALOG_OPEN,
  SET_LEADER_VIEW_AUTOMATION_PTO_REQUESTS_SELECTED_END_DATE,
  SET_LEADER_VIEW_AUTOMATION_SCREEN_NAME,
  SET_LEADER_VIEW_AUTOMATION_SELECTED_DATE,
  SET_LEADER_VIEW_AUTOMATION_SELECTED_LOCATION_ID,
  SET_LEADER_VIEW_AUTOMATION_UP_DOWN,
  SET_LEADER_VIEW_DESIRED_LOCATION_ID,
  SET_LEADER_VIEW_DESIRED_SCREEN_NAME,
  SET_LEADER_VIEW_DESIRED_SELECTED_DATE,
  SET_LEADER_VIEW_DIALOG_CHANGED_PAGE,
  SET_LEADER_VIEW_DISPLAY_LOADING_ICON,
  SET_LEADER_VIEW_POP_UP_TEXT_DATE_CHANGE,
  SET_LEADER_VIEW_POP_UP_TEXT_EXIT,
  SET_LEADER_VIEW_POP_UP_TEXT_LOCATION_CHANGE,
  SET_LEADER_VIEW_POP_UP_TEXT_SCREEN_CHANGE,
  SET_LEADER_VIEW_REFRESH_TABLE,
  SET_LEADER_VIEW_UNSAVED_CHANGES,
} from './actionType'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function setScreenName(text) {
  return {
    type: SET_LEADER_VIEW_AUTOMATION_SCREEN_NAME,
    payload: text,
  }
}

export function setSelectedLocationId(text) {
  return {
    type: SET_LEADER_VIEW_AUTOMATION_SELECTED_LOCATION_ID,
    payload: text,
  }
}

export function setSelectedDate(text) {
  return {
    type: SET_LEADER_VIEW_AUTOMATION_SELECTED_DATE,
    payload: text,
  }
}

export function setUpDownState(upDown) {
  return {
    type: SET_LEADER_VIEW_AUTOMATION_UP_DOWN,
    payload: upDown,
  }
}

export function loadGetLocationsByWorkerIdRequest(workerId) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.userAccess.url}` +
      '/management_scopes' +
      '?worker_id=' +
      workerId +
      '&key=' +
      `${apiConfig.userAccess.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetLocationsByWorkerIdRequestSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetLocationsByWorkerIdRequestError(response))
      })
  }
}

export function handleGetLocationsByWorkerIdRequestError(record) {
  return {
    type: GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_ERROR,
    record,
  }
}

export function handleGetLocationsByWorkerIdRequestSuccess(record) {
  return {
    type: GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_SUCCESS,
    record,
  }
}

export function setExitLeaderDialogOpen(isOpen) {
  return {
    type: SET_LEADER_AUTOMATION_EXIT_DIALOG_OPEN,
    payload: isOpen,
  }
}

export function setNextLeaderLocation(location) {
  return {
    type: LEADER_AUTOMATION_SET_NEXT_LOCATION,
    payload: location,
  }
}

export function setNavigateToNextLeaderLocation(navigate) {
  return {
    type: LEADER_AUTOMATION_SET_NAVIGATE_TO_NEXT_LOCATION,
    payload: navigate,
  }
}

export function getLocationDetails(locationId) {
  return (dispatch) => {
    let apiUrl = `${apiConfig.location.url}` + '/locations/' + locationId + '?key=' + `${apiConfig.location.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetLocationDetailsSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetLocationDetailsError(response))
      })
  }
}

export function setLocationLoading(loadingState) {
  return {
    type: LEADER_AUTOMATION_SET_LOCATION_LOADING,
    payload: loadingState,
  }
}

function handleGetLocationDetailsError(record) {
  return {
    type: LEADER_AUTOMATION_LOCATION_ERROR,
    record,
  }
}

function handleGetLocationDetailsSuccess(record) {
  return {
    type: LEADER_AUTOMATION_LOCATION_SUCCESS,
    record,
  }
}

export function setDesiredScreenName(payload) {
  return {
    type: SET_LEADER_VIEW_DESIRED_SCREEN_NAME,
    payload: payload,
  }
}

export function setDialogChangedPage(payload) {
  return {
    type: SET_LEADER_VIEW_DIALOG_CHANGED_PAGE,
    payload: payload,
  }
}

export function setAreUnsavedChanges(payload) {
  return {
    type: SET_LEADER_VIEW_UNSAVED_CHANGES,
    payload: payload,
  }
}

export function setDesiredSelectedDate(payload) {
  return {
    type: SET_LEADER_VIEW_DESIRED_SELECTED_DATE,
    payload: payload,
  }
}

export function setDesiredLocationID(payload) {
  return {
    type: SET_LEADER_VIEW_DESIRED_LOCATION_ID,
    payload: payload,
  }
}

export function setPopUpTextToChangeLocation(payload) {
  return {
    type: SET_LEADER_VIEW_POP_UP_TEXT_LOCATION_CHANGE,
    payload: payload,
  }
}

export function setPopUpTextToExit(payload) {
  return {
    type: SET_LEADER_VIEW_POP_UP_TEXT_EXIT,
    payload: payload,
  }
}

export function setPopUpTextToChangeRadioView(payload) {
  return {
    type: SET_LEADER_VIEW_POP_UP_TEXT_SCREEN_CHANGE,
    payload: payload,
  }
}

export function setPopUpTextToChangeDate(payload) {
  return {
    type: SET_LEADER_VIEW_POP_UP_TEXT_DATE_CHANGE,
    payload: payload,
  }
}

export function setDisplayLoadingIcon(payload) {
  return {
    type: SET_LEADER_VIEW_DISPLAY_LOADING_ICON,
    payload: payload,
  }
}

export function setRefreshTable(payload) {
  return {
    type: SET_LEADER_VIEW_REFRESH_TABLE,
    payload: payload,
  }
}

export function setSelectedEndDate(text) {
  return {
    type: SET_LEADER_VIEW_AUTOMATION_PTO_REQUESTS_SELECTED_END_DATE,
    payload: text,
  }
}
