const DateTimeIcon = (props) => {
  return (
    <svg width="24px" height="25px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>DateAndTime</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="6" height="3"></rect>
        <rect id="path-3" x="0" y="0" width="6" height="3"></rect>
      </defs>
      <g id="DateAndTime" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Date" transform="translate(1.000000, 0.500000)">
          <line
            x1="9.5"
            y1="21"
            x2="0.5"
            y2="21"
            id="Line"
            stroke="#6D6D6D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="0.5"
            y1="21"
            x2="0.5"
            y2="3"
            id="Line-2"
            stroke="#6D6D6D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="0.5"
            y1="3"
            x2="2.2"
            y2="3"
            id="Line-3"
            stroke="#6D6D6D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="5.7"
            y1="3"
            x2="13.2"
            y2="3"
            id="Line-5"
            stroke="#6D6D6D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="16.7"
            y1="3"
            x2="18.5"
            y2="3"
            id="Line-6"
            stroke="#6D6D6D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="18.5"
            y1="3"
            x2="18.5"
            y2="11"
            id="Line-4"
            stroke="#6D6D6D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line x1="4" y1="0" x2="4" y2="3" id="Line-7" stroke="#6D6D6D" strokeLinecap="round"></line>
          <line x1="15" y1="0" x2="15" y2="3" id="Line-7" stroke="#6D6D6D" strokeLinecap="round"></line>
          <g
            id="Oval"
            transform="translate(4.000000, 4.500000) rotate(179.000000) translate(-4.000000, -4.500000) translate(1.000000, 3.000000)"
          >
            <mask id="mask-2" fill="white">
              <use xlink="#path-1"></use>
            </mask>
            <g id="Mask"></g>
            <circle stroke="#6D6D6D" mask="url(#mask-2)" cx="3" cy="3" r="1.5"></circle>
          </g>
          <g
            id="Oval"
            transform="translate(15.000000, 4.500000) rotate(179.000000) translate(-15.000000, -4.500000) translate(12.000000, 3.000000)"
          >
            <mask id="mask-4" fill="white">
              <use xlink="#path-3"></use>
            </mask>
            <g id="Mask"></g>
            <circle stroke="#6D6D6D" mask="url(#mask-4)" cx="3" cy="3" r="1.5"></circle>
          </g>
          <rect id="Rectangle" fill="#6D6D6D" x="3" y="9.5" width="2.5" height="2.5"></rect>
          <rect id="Rectangle" stroke="#6D6D6D" fill="#6D6D6D" x="8.5" y="10" width="1.5" height="1.5"></rect>
          <rect id="Rectangle" stroke="#6D6D6D" fill="#6D6D6D" x="8.5" y="16" width="1.5" height="1.5"></rect>
          <rect id="Rectangle" stroke="#6D6D6D" fill="#6D6D6D" x="13.5" y="10" width="1.5" height="1.5"></rect>
          <rect id="Rectangle" fill="#6D6D6D" x="3" y="15.5" width="2.5" height="2.5"></rect>
          <line x1="0.5" y1="7" x2="18.5" y2="7" id="Line-8" stroke="#6D6D6D" strokeLinecap="square"></line>
          <rect id="Rectangle" fill="#6D6D6D" x="0" y="4.5" width="19" height="3"></rect>
          <rect id="Rectangle" fill="#6D6D6D" x="5" y="3.5" width="8.6" height="3"></rect>
          <rect id="Rectangle" stroke="#6D6D6D" fill="#D8D8D8" x="16.5" y="4" width="1" height="1"></rect>
          <rect id="Rectangle" stroke="#6D6D6D" fill="#D8D8D8" x="1.5" y="4" width="1" height="1"></rect>
        </g>
        <g id="Time" transform="translate(13.000000, 14.000000)" stroke="#6D6D6D">
          <circle id="Oval" strokeWidth="1.3" cx="5.5" cy="5.5" r="4.85"></circle>
          <line x1="5.9" y1="6" x2="5.9" y2="3" id="Line-7" strokeLinecap="round"></line>
          <line x1="6" y1="6" x2="4" y2="8" id="Line-7" strokeLinecap="round"></line>
        </g>
      </g>
    </svg>
  )
}
export default DateTimeIcon
