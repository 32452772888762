import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { convertToLocalDateAndTime } from '../../utils/DateUtil'
import { AccordionActions, useTheme } from '@mui/material'
import { FIXED_END_DATE_FROM_UKG, REQUEST_STATUS_TYPES_AVAILABILITY } from '../../constants/AvailabilityConstants'
import AvailabilityDetailsTable from './Availability/AvailabilityDetailsTable'
import AvailabilityCancelRequest from './AvailabilityCancelRequest'
import RequestStatusIcon from './RequestStatusIcon'

const getStyles = (theme) => ({
  root: {
    width: '100%',
  },
  requestHeading: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  requestHeadingContainer: {
    flexDirection: 'row',
    marginTop: '5px',
    display: 'flex',
  },
  iconContainer: {
    display: 'flex',
    marginRight: '10px',
  },
  headingColumn: {
    alignSelf: 'center',
  },
  details: {
    flexDirection: 'column',
    padding: '0px 15px',
    marginBottom: '5px',
  },
  summaryMui: {
    padding: '0px 15px',
  },
  summary: {
    flex: 1,
  },
  expansionPanelActions: {
    padding: '0 15px 15px 0px',
  },
  smallDetail: {
    fontSize: 'small',
    color: theme.infoMessages.color,
  },
})

const AvailabilityRequestAccordion = ({ request, workerId, lastRequestElementRef, isoTimeZone }) => {
  const outputDateFormat = 'MMMM D, YYYY' // date when accordion is collapsed
  const theme = useTheme()
  const styles = getStyles(theme)

  const renderRequestDates = () => {
    if (request.start_date === request.end_date) {
      return <Typography sx={styles.requestHeading}>{moment(request.start_date)?.format(outputDateFormat)}</Typography>
    } else {
      if (request.end_date === FIXED_END_DATE_FROM_UKG) {
        return (
          <Typography sx={styles.requestHeading}>
            {`${moment(request.start_date)?.format(outputDateFormat)} - FOREVER`}
          </Typography>
        )
      } else {
        return (
          <Typography sx={styles.requestHeading}>
            {`${moment(request.start_date)?.format(outputDateFormat)} - ${moment(request.end_date)?.format(
              outputDateFormat,
            )}`}
          </Typography>
        )
      }
    }
  }

  const renderRequestSummary = () => {
    return (
      <div style={styles.summary}>
        <div style={styles.requestHeadingContainer}>
          <div style={styles.iconContainer}>
            <RequestStatusIcon
              requestType={'Core Availability'}
              status={request.status_name}
              isTimeOffTeamMember={false}
            />
          </div>
          <div style={styles.headingColumn}>{renderRequestDates()}</div>
        </div>
      </div>
    )
  }

  const isRequestEligibleForCancel = () => {
    let isEligibleForCancel = false

    if ([REQUEST_STATUS_TYPES_AVAILABILITY.PENDING].includes(request.status_name)) {
      isEligibleForCancel = true
    } else {
      isEligibleForCancel = false
    }
    return isEligibleForCancel
  }

  return (
    <div ref={lastRequestElementRef} style={styles.root}>
      <Accordion square data-cy={`requestAccordion_${request.request_id}`}>
        <AccordionSummary
          sx={styles.summaryMui}
          expandIcon={<ExpandMoreIcon color={'primary'} />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          {renderRequestSummary()}
        </AccordionSummary>
        <AccordionDetails sx={styles.details}>
          <AvailabilityDetailsTable requestDetails={request.request_details} startDayOfRequest={request.start_date} />
          <Typography sx={styles.smallDetail} style={{ marginTop: '10px', marginBottom: '10px' }}>
            {`Last updated ${convertToLocalDateAndTime(request.updated_timestamp)}`}
          </Typography>
        </AccordionDetails>
        {isRequestEligibleForCancel() && (
          <AccordionActions sx={styles.expansionPanelActions}>
            <AvailabilityCancelRequest request={request} />
          </AccordionActions>
        )}
      </Accordion>
    </div>
  )
}

export default AvailabilityRequestAccordion
