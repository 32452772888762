import { MenuItem } from '@mui/material'
import ShiftSwapIcon from '../Icons/ShiftSwapIcon'
import SwapRequestInitatedIcon from '../Icons/SwapRequestInitatedIcon'

const SWAP_STATUS_OPEN = 'OPEN'

const WITHDRAW_TEXT = 'Withdraw Swap Request'
const SEARCH_TEXT = 'Search Shifts To Swap'

const styles = {
  menuIcon: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  menuText: {
    marginLeft: '5px',
  },
}

const SearchSwapShiftsAction = (props) => {
  const { displaySegment, handleSearchShiftsToSwapClick, handleWithdrawSwapRequestClick, menuKey } = props

  const handleMenuItemClick = () => {
    return displaySegment.swapStatusAsRequester === SWAP_STATUS_OPEN
      ? handleWithdrawSwapRequestClick()
      : handleSearchShiftsToSwapClick()
  }

  const renderItemContent = () => {
    // show withdraw icon when request is open
    if (displaySegment.swapStatusAsRequester === SWAP_STATUS_OPEN) {
      return (
        <>
          <div style={styles.menuIcon}>
            <SwapRequestInitatedIcon />
          </div>
          <div style={styles.menuText}>{WITHDRAW_TEXT}</div>
        </>
      )
    } else {
      // show swap icon for all other reasons
      return (
        <>
          <div style={styles.menuIcon}>
            <ShiftSwapIcon />
          </div>
          <div style={styles.menuText}>{SEARCH_TEXT}</div>
        </>
      )
    }
  }

  return (
    <MenuItem key={menuKey} value={'search-swap-shift-action'} onClick={handleMenuItemClick}>
      {renderItemContent()}
    </MenuItem>
  )
}

export default SearchSwapShiftsAction
