import { addDays, addMonths, startOfWeek, startOfYear, subDays, subWeeks, subYears } from 'date-fns'
import { DATE_TIME_FORMATTING, getDateOfTodayWithNoTimestamp } from '../utils/DateUtil'
import { DC_LEADER_AUTOMATION_OLD, LEGACY_LEADER } from '../utils/ScreenName'
import {
  ACKNOWLEDGED,
  AVAILABILITY,
  CALL_IN_LATE,
  CALL_OFF_ABSENT,
  CANCELLED,
  DENIED,
  FAILED,
  FAILED_CANCELLED,
  PENDING,
  PROCESSED,
  PUNCH_CORRECTION,
  SCHEDULED,
  TIME_OFF,
  WAITLISTED,
} from './RequestConstants'
import { LOCATION_TYPE_DC, LOCATION_TYPE_OFFICE, LOCATION_TYPE_STORE } from './locationConstants'

export const LEADER_DASHBOARD_TITLE = 'Dashboard'
export const LEADER_MY_TEAM_TITLE = 'My Team'

export const CATEGORY_FEATURE_MAP = new Map([
  [TIME_OFF, 'paid_time_off'],
  [CALL_IN_LATE, 'call_in'],
  [CALL_OFF_ABSENT, 'call_off'],
  [AVAILABILITY, 'availability'],
  [PUNCH_CORRECTION, 'punch_correction'],
])

export const EXCLUDED_LEADER_FEATURES = [LEGACY_LEADER, DC_LEADER_AUTOMATION_OLD]

const DEFAULT_FEATURE_SPECIFIC_CONFIG = {
  [TIME_OFF]: {
    sortableColumns: [
      { columnName: 'name', displayName: 'Name' },
      { columnName: 'created_timestamp', displayName: 'Created Timestamp' },
    ],
    sortOrder: 'ASC',
    selectedSortByIndex: 1,
    minDate: startOfYear(subYears(getDateOfTodayWithNoTimestamp(), 1)),
    maxDate: addMonths(getDateOfTodayWithNoTimestamp(), 6),
    defaultStartDate: subDays(getDateOfTodayWithNoTimestamp(), 30),
    defaultEndDate: addMonths(getDateOfTodayWithNoTimestamp(), 6),
    maxDaysInRange: 365,
    searchText: '',
    page: 1,
    rowsPerPage: 25,
    totalCount: 0,
    filters: { statuses: { selectedValues: [PENDING, FAILED, FAILED_CANCELLED] } },
  },
  [CALL_IN_LATE]: {
    sortableColumns: [
      { columnName: 'name', displayName: 'Name' },
      { columnName: 'created_timestamp', displayName: 'Created Timestamp' },
    ],
    sortOrder: 'DESC',
    selectedSortByIndex: 1,
    minDate: startOfYear(subYears(getDateOfTodayWithNoTimestamp(), 1)),
    maxDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    defaultStartDate: getDateOfTodayWithNoTimestamp(),
    defaultEndDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    maxDaysInRange: 365,
    searchText: '',
    page: 1,
    rowsPerPage: 25,
    totalCount: 0,
  },
  [CALL_OFF_ABSENT]: {
    sortableColumns: [
      { columnName: 'name', displayName: 'Name' },
      { columnName: 'created_timestamp', displayName: 'Created Timestamp' },
    ],
    sortOrder: 'DESC',
    selectedSortByIndex: 1,
    minDate: startOfYear(subYears(getDateOfTodayWithNoTimestamp(), 1)),
    maxDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    defaultStartDate: getDateOfTodayWithNoTimestamp(),
    defaultEndDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    maxDaysInRange: 365,
    searchText: '',
    page: 1,
    rowsPerPage: 25,
    totalCount: 0,
  },
  [AVAILABILITY]: {
    sortableColumns: [
      { columnName: 'name', displayName: 'Name' },
      { columnName: 'created_timestamp', displayName: 'Created Timestamp' },
    ],
    sortOrder: 'ASC',
    selectedSortByIndex: 1,
    minDate: startOfYear(subYears(getDateOfTodayWithNoTimestamp(), 1)),
    maxDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    defaultStartDate: startOfWeek(subWeeks(getDateOfTodayWithNoTimestamp(), 13)),
    defaultEndDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    maxDaysInRange: 365,
    searchText: '',
    page: 1,
    rowsPerPage: 25,
    totalCount: 0,
  },
  [PUNCH_CORRECTION]: {
    sortableColumns: [
      { columnName: 'name', displayName: 'Name' },
      { columnName: 'created_timestamp', displayName: 'Created Timestamp' },
    ],
    sortOrder: 'ASC',
    selectedSortByIndex: 1,
    minDate: startOfYear(subYears(getDateOfTodayWithNoTimestamp(), 1)),
    maxDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    defaultStartDate: startOfWeek(subWeeks(getDateOfTodayWithNoTimestamp(), 13)),
    defaultEndDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    maxDaysInRange: 365,
    searchText: '',
    page: 1,
    rowsPerPage: 25,
    totalCount: 0,
  },
}

const unknownCategoryDefault = {
  sortableColumns: [],
  defaultStartDate: null,
  defaultEndDate: null,
  searchText: '',
  page: 1,
  rowsPerPage: 25,
  totalCount: 0,
}

const isLeaderFeature = (name) => {
  return !EXCLUDED_LEADER_FEATURES.includes(name) && name.includes('leader')
}

export const getConfigDetails = (locationDetails, userFeatures) => {
  let configMap = {}
  let selectableCategories = []
  locationDetails &&
    userFeatures?.features.forEach((feature) => {
      if (!isLeaderFeature(feature.name)) {
        return
      }
      // add feature to config
      selectableCategories.push(feature.display_name)
      configMap[feature.display_name] = getConfiguration(
        locationDetails.location_type,
        feature,
        userFeatures.user_group,
      )
    })
  return { selectableCategories, configMap }
}

// ================================ TIME_OFF OVERRIDES ================================
const STORE_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_TIME_OFF = {
  HR: {},
  LEADER: {},
}

const DC_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_TIME_OFF = {
  HR: {},
  LEADER: {},
}

// ================================ CALL_IN_LATE OVERRIDES ================================
const STORE_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_CALL_IN_LATE = {
  HR: {},
  LEADER: {},
}

const DC_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_CALL_IN_LATE = {
  HR: {},
  LEADER: {},
}

// ================================ CALL_OFF_ABSENT OVERRIDES ================================
const STORE_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_CALL_OFF_ABSENT = {
  HR: {},
  LEADER: {},
}

const DC_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_CALL_OFF_ABSENT = {
  HR: {},
  LEADER: {},
}

const STORE_LEADER_ROLES = ['store_manager']
// TODO: ADMIN DEFAULTS - default admin to store HR for now.
const STORE_HR_ROLES = ['store_non_exempt_hr_manager', 'admin']
const DC_LEADER_ROLES = ['dc_manager']
const DC_HR_ROLES = ['dc_non_exempt_manager']
// returning HR or LEADER based on role
function getSimplifiedRole(role) {
  if (STORE_LEADER_ROLES.includes(role) || DC_LEADER_ROLES.includes(role)) return 'LEADER'
  if (STORE_HR_ROLES.includes(role) || DC_HR_ROLES.includes(role)) return 'HR'
  return null
}

function getConfiguration(locationType, feature, role) {
  let defaultConfig = DEFAULT_FEATURE_SPECIFIC_CONFIG[feature.display_name]
  if (!defaultConfig) {
    defaultConfig = { [feature.display_name]: unknownCategoryDefault }
  }

  let configFeatureName = feature.name.substring(0, feature.name.lastIndexOf('_'))

  defaultConfig.configFeatureName = configFeatureName
  let defaultConfigOverride = {}

  // check role here and set to HR or LEADER
  let simplifiedRole = getSimplifiedRole(role)

  // TODO: ADMIN DEFAULTS - if hq persona, make them store location type for now
  if (locationType === LOCATION_TYPE_OFFICE) {
    locationType = LOCATION_TYPE_STORE
  }

  // if user is not hr or leader for store or dc, just used default config
  if (simplifiedRole === null) {
    return defaultConfig
  }

  // Set the default configuration based on the provided locationType and role
  switch (locationType) {
    case LOCATION_TYPE_STORE:
      switch (feature.display_name) {
        case TIME_OFF:
          defaultConfigOverride = STORE_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_TIME_OFF[simplifiedRole]
          break
        case CALL_IN_LATE:
          defaultConfigOverride = STORE_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_CALL_IN_LATE[simplifiedRole]
          break
        case CALL_OFF_ABSENT:
          defaultConfigOverride = STORE_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_CALL_OFF_ABSENT[simplifiedRole]
          break
        default:
      }
      break
    case LOCATION_TYPE_DC:
      switch (feature.display_name) {
        case TIME_OFF:
          defaultConfigOverride = DC_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_TIME_OFF[simplifiedRole]
          break
        case CALL_IN_LATE:
          defaultConfigOverride = DC_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_CALL_IN_LATE[simplifiedRole]
          break
        case CALL_OFF_ABSENT:
          defaultConfigOverride = DC_ROLE_SPECIFIC_CONFIG_OVERRIDE_FOR_CALL_OFF_ABSENT[simplifiedRole]
          break
        default:
      }
      break
    default:
      break
  }

  return { ...defaultConfig, ...defaultConfigOverride }
}

// NEW LEADER CONFIGURATION
export const UPDATE_TO_STATUS_MAP = {
  [CALL_IN_LATE]: {
    [PENDING]: [ACKNOWLEDGED],
    [FAILED]: [ACKNOWLEDGED],
  },
  [CALL_OFF_ABSENT]: {
    [PENDING]: [ACKNOWLEDGED],
    [FAILED]: [ACKNOWLEDGED],
  },
  [TIME_OFF]: {
    [PENDING]: [SCHEDULED, DENIED],
    [DENIED]: [SCHEDULED],
    [FAILED]: [DENIED, PROCESSED],
    [FAILED_CANCELLED]: [CANCELLED],
  },
}

// LEGACY LEADER CONFIGURATION
export const STATUS_MAP_BY_REQUEST_TYPE_GROUP = {
  PTO_VACATION_AUTOMATION: {
    [PENDING]: [SCHEDULED, DENIED, WAITLISTED],
    [SCHEDULED]: [DENIED, WAITLISTED],
    [DENIED]: [SCHEDULED, WAITLISTED],
    [WAITLISTED]: [SCHEDULED, DENIED],
    [ACKNOWLEDGED]: [SCHEDULED, DENIED, WAITLISTED, PENDING, PROCESSED],
    [FAILED]: [DENIED, PROCESSED],
  },
  PTO_OTHER: {
    [PENDING]: [SCHEDULED, DENIED, WAITLISTED],
    [SCHEDULED]: [DENIED, WAITLISTED, PROCESSED],
    [DENIED]: [SCHEDULED, WAITLISTED],
    [WAITLISTED]: [SCHEDULED, DENIED],
    [PROCESSED]: [SCHEDULED, DENIED, WAITLISTED],
    [ACKNOWLEDGED]: [SCHEDULED, DENIED, WAITLISTED, PENDING, PROCESSED],
  },
  // automation & non-automation
  CALL_IN_OFF: {
    [PENDING]: [ACKNOWLEDGED],
    [FAILED]: [ACKNOWLEDGED],
  },
}

export const LEADER_DATE_HEADER_FORMAT = DATE_TIME_FORMATTING.smallDate
export const LEADER_TIMESTAMP_DETAIL_FORMAT = DATE_TIME_FORMATTING.smallDateTime
