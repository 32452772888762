import {
  OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_ERROR,
  OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_SUCCESS,
  OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_ERROR,
  OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_SUCCESS,
  OPTIONAL_AVAILABILITY_SET_CANCEL_DIALOG_OPEN,
  OPTIONAL_AVAILABILITY_SET_DIFFERENT_WEEK_DIALOG_OPEN,
  OPTIONAL_AVAILABILITY_SET_EXIT_DIALOG_OPEN,
  OPTIONAL_AVAILABILITY_SET_EXPAND_COLLAPSE,
  OPTIONAL_AVAILABILITY_SET_NAVIGATE_TO_NEXT_LOCATION,
  OPTIONAL_AVAILABILITY_SET_NEXT_LOCATION,
  OPTIONAL_AVAILABILITY_SET_POST_RESPONSE,
  OPTIONAL_AVAILABILITY_SET_SAVE_DIALOG_OPEN,
  OPTIONAL_AVAILABILITY_SET_SESSION_DATA,
  OPTIONAL_AVAILABILITY_SET_TM_LOADING,
  OPTIONAL_AVAILABILITY_SET_UPDATED_SESSION_DATA,
  SET_PERSISTED_DATA,
  SET_TM_AVAILABILITY_POST_ERROR,
  SET_TM_AVAILABILITY_SELECTED_DATE,
} from './actionType'
import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'

export const initialState = {
  loading: 'N',
  expandPanel: {
    panel0: false,
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
  },
  persistedData: null,
  sessionData: null,
  updatedSessionData: null,
  saveDialogOpen: false,
  cancelDialogOpen: false,
  exitDialogOpen: false,
  differentWeekDialogOpen: false,
  nextLocation: null,
  navigateToNextLocation: false,
  postResponse: null,
  postError: null,
  getError: null,
  selectedDate: getDateOfTodayWithNoTimestamp(),
}

export default function tmOptionalAvailability(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PERSISTED_DATA: {
      const { record } = action
      return {
        ...state,
        persistedData: record,
      }
    }
    case OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        getError: record.response ? record.response.data : record,
        postError: null,
        loading: 'N',
      }
    }
    case OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_SUCCESS: {
      const { record } = action
      return {
        ...state,
        persistedData: record.data,
        loading: 'N',
        postError: null,
        getError: null,
      }
    }
    case OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        postError: record.response ? record.response.data : record,
        getError: null,
        loading: 'N',
      }
    }
    case OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_SUCCESS: {
      const { record } = action
      return {
        ...state,
        postResponse: record.data,
        loading: 'N',
        postError: null,
        getError: null,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_POST_RESPONSE: {
      const { payload } = action
      return {
        ...state,
        postResponse: payload,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_TM_LOADING: {
      const { payload } = action
      return {
        ...state,
        loading: payload,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_SAVE_DIALOG_OPEN: {
      const { payload } = action
      return {
        ...state,
        saveDialogOpen: payload,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_CANCEL_DIALOG_OPEN: {
      const { payload } = action
      return {
        ...state,
        cancelDialogOpen: payload,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_EXIT_DIALOG_OPEN: {
      const { payload } = action
      return {
        ...state,
        exitDialogOpen: payload,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_DIFFERENT_WEEK_DIALOG_OPEN: {
      const { payload } = action
      return {
        ...state,
        differentWeekDialogOpen: payload,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_NEXT_LOCATION: {
      const { payload } = action
      return {
        ...state,
        nextLocation: payload,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_NAVIGATE_TO_NEXT_LOCATION: {
      const { payload } = action
      return {
        ...state,
        navigateToNextLocation: payload,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_EXPAND_COLLAPSE: {
      const { payload } = action
      return {
        ...state,
        expandPanel: payload,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_SESSION_DATA: {
      const { payload } = action
      return {
        ...state,
        sessionData: payload,
      }
    }
    case OPTIONAL_AVAILABILITY_SET_UPDATED_SESSION_DATA: {
      const { payload } = action
      return {
        ...state,
        updatedSessionData: payload,
      }
    }
    case SET_TM_AVAILABILITY_SELECTED_DATE: {
      const { date } = action
      return {
        ...state,
        selectedDate: date,
      }
    }
    case SET_TM_AVAILABILITY_POST_ERROR: {
      const { error } = action
      return {
        ...state,
        postError: error,
      }
    }
    default:
      return state
  }
}
