const HeartIconCircle = (props) => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Review Request</title>
      <g id="Heart-Icon-Circle-with-arrows--1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Heart-icon">
          <circle id="Oval" stroke="#CC0000" cx="12.5" cy="12.5" r="12"></circle>
          <path
            d="M19.9375,7.23731164 C19.24123,6.71209333 18.70998,6.35792666 18.34375,6.17481164 C17.97752,5.99169662 17.1378022,6.10651117 15.8245968,6.51925531 C14.9439683,6.77569228 14.2584845,7.11843421 13.7681452,7.54748111 C13.2778058,7.97652801 12.7636929,8.66201189 12.2258065,9.60393273 C11.4303961,8.57829942 10.7449122,7.89281555 10.1693548,7.54748111 C9.59379745,7.20214668 8.56557164,6.85940475 7.08467742,6.51925531 C6.05996083,6.80077951 5.37447695,7.14352144 5.02822581,7.54748111 C4.68197466,7.95144079 4.33923272,8.80829563 4,10.1180456 C4.16836615,11.0821366 4.33973712,11.7676204 4.5141129,12.1744972 C4.68848868,12.5813741 5.03123062,13.095487 5.54233871,13.716836 L8,15.5 L11,17.5 C11,15.5 11,14.5 11,14.5 C11,14.5 12.6666667,14.5 16,14.5 L16,10 L19.5,13 C20.5,11.5002777 21,10.4647982 21,9.89356164 C21,9.32232508 20.6458333,8.43690841 19.9375,7.23731164 Z"
            id="Path-2"
            fill="#CC0000"
          ></path>
        </g>
        <g id="arrows" transform="translate(10.500000, 13.000000)" fill="#CC0000">
          <polygon
            id="Path"
            points="2.18181818 2.93386631 6.74620711 2.93386631 6.74620711 0 12 4.36363636 6.74620711 4.36363636 2.18181818 4.36363636"
          ></polygon>
          <polygon
            id="Path"
            transform="translate(4.909091, 7.636364) rotate(-180.000000) translate(-4.909091, -7.636364) "
            points="0 8.4868658 4.56438893 8.4868658 4.56438893 5.45454545 9.81818182 9.81818182 4.56438893 9.81818182 0 9.81818182"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}
export default HeartIconCircle
