import {
  CLEAR_PREVIOUS_STATE,
  POST_REQUESTS_DATA_ERROR,
  POST_REQUESTS_DATA_SUCCESS,
  SHOW_INFO_MESSAGE,
  UPDATE_PUNCH_CORRECTION_REQUESTS_DATA_ERROR,
  UPDATE_PUNCH_CORRECTION_REQUESTS_DATA_SUCCESS,
} from './actionType'

const initialState = {
  requests_details: [],
  punchCorrectionPostResponse: null,
  punchCorrectionPostErrorResponse: null,
  messageObj: { showInfoMessage: false, date: '' },
  userPunchCorrectionUpdateResponse: null,
  userPunchCorrectionUpdateErrResponse: null,
}

export default function punchCorrection(state = initialState, action = {}) {
  switch (action.type) {
    case POST_REQUESTS_DATA_SUCCESS: {
      return {
        ...state,
        punchCorrectionPostResponse: action.data,
      }
    }
    case POST_REQUESTS_DATA_ERROR: {
      let error
      if (action.data.response) {
        error = action.data.response.data
      } else {
        error = action.data.message
      }
      return {
        ...state,
        punchCorrectionPostErrorResponse: error,
      }
    }
    case CLEAR_PREVIOUS_STATE: {
      return {
        ...state,
        punchCorrectionPostResponse: null,
        punchCorrectionPostErrorResponse: null,
        userPunchCorrectionUpdateResponse: null,
        userPunchCorrectionUpdateErrResponse: null,
      }
    }
    case SHOW_INFO_MESSAGE: {
      return {
        ...state,
        messageObj: action.data,
      }
    }

    case UPDATE_PUNCH_CORRECTION_REQUESTS_DATA_SUCCESS: {
      return {
        ...state,
        userPunchCorrectionUpdateResponse: action.data,
      }
    }
    case UPDATE_PUNCH_CORRECTION_REQUESTS_DATA_ERROR: {
      let error
      if (action.data.response) {
        error = action.data.response.data
      } else {
        error = action.data.message
      }
      return {
        ...state,
        userPunchCorrectionUpdateErrResponse: error,
      }
    }

    default:
      return state
  }
}
