import { Divider, Grid, ListItem, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { formatHours } from '../../utils/formatHours'
import { hrsToHours } from '../../utils/strings'

const DatesOnWeekly = (props) => {
  const { dates, currentWeekAriaLabel, styles } = props

  const handleClick = (date) => {
    props.goToDailyView(date)
  }
  return (
    <>
      <Header currentWeekAriaLabel={currentWeekAriaLabel} styles={styles}></Header>
      <Divider role="presentation" />
      {dates.timecards.map((item, index) => (
        <Datecomponent
          id={`date_component_${index}`}
          timecard={item}
          styles={styles}
          key={index}
          handleClick={(date) => handleClick(date)}
        />
      ))}
    </>
  )
}

const Header = (props) => {
  const { currentWeekAriaLabel, styles } = props
  return (
    <ListItem
      tabIndex={0}
      aria-label={`weekly timecard report starting from ${currentWeekAriaLabel}`}
      sx={styles.headerStyles}
    >
      <Grid container>
        <Grid item xs={3} md={4}>
          <Typography variant="body2" sx={{ ...styles.sectionHeader1, ...styles.boldText }}>
            Day
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography variant="body2" sx={{ ...styles.sectionHeader2, ...styles.boldText }}>
            Punch In - Out
          </Typography>
        </Grid>
        <Grid item xs={3} md={4}>
          <Typography variant="body2" sx={{ ...styles.sectionHeader3, ...styles.boldText }}>
            Total Hours
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  )
}

const Datecomponent = (props) => {
  const { id, styles, timecard, handleClick } = props
  const inputDateFormat = 'YYYY-MM-DD'
  const outputDateFormat = 'ddd MMM DD'
  const inputTimeFormat = 'YYYY-MM-DD hh:mm:ss'
  const outputTimeFormat = 'hh:mmA'

  let scheduleDate = moment(timecard.date, moment.HTML5_FMT.DATE)
  let startPunchTime
  let endPunchTime
  let punchPeriod = ''
  let paycodeArr = []
  let payCodes = ''

  if (!timecard.has_missed_punch && timecard.total_punch_segments > 0) {
    startPunchTime = moment(timecard.punch_segments[0].start_punch, inputTimeFormat)?.format(outputTimeFormat)
    endPunchTime = moment(
      timecard.punch_segments[timecard.punch_segments.length - 1].end_punch,
      inputTimeFormat,
    )?.format(outputTimeFormat)
    punchPeriod = `${startPunchTime} - ${endPunchTime}`
  }
  if (timecard.pay_code && timecard.pay_code.length > 0) {
    for (let payCode of timecard.pay_code) {
      paycodeArr.push(payCode.pay_code_name)
    }
    payCodes = paycodeArr.join(', ')
  }

  const ariaLabelDate = () => {
    var givenDate = moment(timecard.date)

    var day = givenDate.format('dddd')
    var month = givenDate.format('MMMM')
    var date = givenDate.format('D')

    return `${day}, ${month} ${date}`
  }

  const generateAriaLabel = () => {
    if (!timecard.has_missed_punch && timecard.total_punch_segments > 0) {
      return `${ariaLabelDate()}, punched in at ${startPunchTime} and punched out at ${endPunchTime}, total hours: ${
        timecard.total_hours
      } ${payCodes ? ', paycode: ' + hrsToHours(payCodes) : ''}`
    } else if (!timecard.has_missed_punch && timecard.total_punch_segments === 0) {
      return `${ariaLabelDate()}, no punches, total hours: ${timecard.total_hours} ${
        payCodes ? ', paycode: ' + hrsToHours(payCodes) : ''
      }`
    } else if (timecard.has_missed_punch) {
      return `${ariaLabelDate()}, missed punch found, total hours: ${timecard.total_hours} ${
        payCodes ? ', paycode: ' + hrsToHours(payCodes) : ''
      }`
    } else {
      return `${ariaLabelDate()} ${payCodes ? ', paycode: ' + hrsToHours(payCodes) : ''}`
    }
  }

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        md={12}
        container
        onClick={() => handleClick(scheduleDate.toDate())}
        sx={styles.clickableContainer}
        role="button"
        tabIndex={0}
        aria-label={generateAriaLabel()}
      >
        <ListItem data-cy={id}>
          <Grid container>
            <Grid item xs={3} md={4}>
              <Typography variant="body2" sx={{ ...styles.dateCol1, ...styles.boldText }}>
                {moment(timecard.date, inputDateFormat)?.format(outputDateFormat)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="body2" sx={{ ...styles.dateCol2, ...styles.boldText }}>
                {punchPeriod}
              </Typography>
            </Grid>
            <Grid item xs={3} md={4}>
              <Typography variant="body2" sx={{ ...styles.dateCol3, ...styles.boldText }}>
                {`${formatHours?.format(timecard.total_hours)}`}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <Grid container>
          <Grid item xs={12} md={12}>
            {timecard.has_missed_punch && (
              <ListItem>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2" sx={styles.redText}>
                    Missed Punch
                  </Typography>
                </Grid>
              </ListItem>
            )}
            {payCodes && (
              <ListItem>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2" sx={{ ...styles.segmentStyles1, ...styles.greyText }}>
                    {payCodes}
                  </Typography>
                </Grid>
              </ListItem>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Divider role="presentation" />
    </React.Fragment>
  )
}

export default DatesOnWeekly
