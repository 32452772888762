import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'

const ADD_ROW = 'Add row'
const REMOVE_ROW = 'Remove row'

const MAX_ROWS_PER_DAY = 4
let MIN_ROWS_PER_DAY

const MoreActionsMenu = (props) => {
  const { dayPunches, rowDetail } = props
  const [currentMenuOptions, setCurrentMenuOptions] = useState(null)
  const [anchorElMoreOptions, setAnchorElMoreOptions] = useState(null)
  const openMoreOptions = Boolean(anchorElMoreOptions)

  if (!rowDetail['punchInTime'] && !rowDetail['punchOutTime']) {
    MIN_ROWS_PER_DAY = 1
  } else {
    MIN_ROWS_PER_DAY = 0
  }

  const renderMenuItems = () => {
    let moreOptions = getMenuOptionsArray()
    if (JSON.stringify(moreOptions) !== JSON.stringify(currentMenuOptions)) {
      setCurrentMenuOptions(moreOptions)
    }

    return moreOptions.map((option, index) => (
      <MenuItem key={index} value={option} onClick={(event) => handleMenuItemClick(event, index)}>
        {option}
      </MenuItem>
    ))
  }

  const handleMenuItemClick = (event, index) => {
    let selectedMenuOption = currentMenuOptions[index]
    if (selectedMenuOption === ADD_ROW) {
      props.addPunch()
    } else if (selectedMenuOption === REMOVE_ROW) {
      props.removePunch()
    }

    setAnchorElMoreOptions(null)
  }

  const handleOpenMoreOptions = (event) => {
    setAnchorElMoreOptions(event.currentTarget)
  }

  const handleCloseMoreOptions = () => {
    setAnchorElMoreOptions(null)
  }

  const getMenuOptionsArray = () => {
    let moreOptions = []

    if (dayPunches.length < MAX_ROWS_PER_DAY) {
      moreOptions.push(ADD_ROW)
    }

    if (dayPunches.length > MIN_ROWS_PER_DAY) {
      moreOptions.push(REMOVE_ROW)
    }

    return moreOptions
  }

  return (
    <React.Fragment>
      <IconButton
        // data-cy={`requestTimeOffDetail_rowsButton_${requestDate}_${requestDetailPerDateIndex}`}
        aria-label="more row options"
        aria-controls="long-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleOpenMoreOptions}
        size="large"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        // data-cy={`requestTimeOffDetail_rowsMenu_${requestDate}_${requestDetailPerDateIndex}`}
        id="long-menu"
        anchorEl={anchorElMoreOptions}
        keepMounted
        open={openMoreOptions}
        onClose={handleCloseMoreOptions}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        {renderMenuItems()}
      </Menu>
    </React.Fragment>
  )
}

export default MoreActionsMenu
