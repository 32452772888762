import {
  ACCESS_SUCCESS,
  GET_USER_DETAILS,
  LOCATION_SUCCESS,
  SET_COMMUNICATION_OPEN,
  SET_CONFIRM,
  SET_HEADER_TITLE,
  SHOW_AUTH_POPUP,
} from './actionType'

export const initialState = {
  openPopup: 0,
  popupType: '',
  headerTitle: '',
  screenAccessLoading: true,
  screenAccess: [],
  locationLoading: null,
  locationDetails: {
    location_number: null, // example 501,
    location_type: null, // example 'OFFICE',
    state_code: null, // example 'MN',
    time_zone_code: null, // example 'CST',
    is_daylight_savings_time_recognized: null, // example true,
    iso_time_zone_code: null, // example 'Pacific/Norfolk',
  },
  locationDetailsGetError: null,
  screenAccessError: null,
  firstName: null,
  userDetails: null,
  userGroup: null,
  confirm: {
    prompt: '',
    isOpen: false,
    proceed: null,
    cancel: null,
  },
  isCommunicationOpen: false,
}

// const features = [
//   'schedule_team_member',
//   'voluntary_availability_team_member',
//   'worker_redemption_code',
//   'legacy_leader',
//   'available_shifts_team_member',
//   'request_to_cover_team_member',
//   'time_off_team_member',
//   'time_off_leader',
//   'view_timecard_team_member',
//   'time_off_team_member',
//   'call_in_call_off_team_member',
//   'shift_swap_team_member',
//   'desired_hours',
// ]

export default function layoutReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_AUTH_POPUP: {
      const { payload } = action
      return {
        ...state,
        openPopup: state.openPopup + 1,
        popupType: payload,
      }
    }
    case SET_HEADER_TITLE: {
      const { payload } = action
      return {
        ...state,
        headerTitle: payload,
      }
    }
    case ACCESS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        screenAccess: data.screenAccess,
        firstName: data.first_name,
        userGroup: data.user_group,
        screenAccessError: null,
        screenAccessLoading: false,
      }
    }
    case LOCATION_SUCCESS: {
      const { data } = action
      return {
        ...state,
        locationDetails: data,
        locationDetailsGetError: null,
        locationLoading: false,
      }
    }
    case GET_USER_DETAILS: {
      const { userObj } = action
      return {
        ...state,
        userDetails: userObj,
      }
    }
    case SET_CONFIRM: {
      const { payload } = action
      return {
        ...state,
        confirm: {
          ...state.confirm,
          ...payload,
        },
      }
    }
    case SET_COMMUNICATION_OPEN: {
      const { payload } = action
      return {
        ...state,
        isCommunicationOpen: payload,
      }
    }
    default:
      return state
  }
}
