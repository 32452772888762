// UserContext.tsx
import { useAuth } from '@praxis/component-auth'
import Firefly from 'firefly-sdk-ts'
import { useCallback, useEffect } from 'react'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import * as fireflyHelper from '../../utils/firefly-helper'
const internalappConfig = require('../../config/firefly-internal-app-config')

const useFireflyAdoption = () => {
  const { session } = useAuth()

  const appEnv = process.env.REACT_APP_ENV

  const triggerFireflyEvent = useCallback(() => {
    const internalappEventManager = Firefly.getEventManager(internalappConfig)
    internalappEventManager.addTaskInitEvent({
      application: {
        id: 'c3f4123af9fb2019-05-01T15:10:28.785Z',
        name: 'TMEAdoption',
      },
      guest: {
        eventType: 'pageload',
      },
      event: {
        type: 'pageload',
        name: 'home page load',
      },
      employee: {
        loginId: padEmpIdWithZeros(session?.userInfo?.empid, 10),
      },
      user: { customDimension1: session?.userInfo?.locationid },
      task: { name: '20210714-test-1' },
    })
  }, [session?.userInfo?.empid, session?.userInfo?.locationid])

  useEffect(() => {
    //capture the  firefly events when are in prod
    if (appEnv === 'prod' && session) {
      fireflyHelper.initializeFireflySDK()

      //Firefly Code starts here
      Firefly.setGlobalDataLayer(
        {
          view: {
            globalName: 'app page',
            name: 'app page',
            type: 'app page',
          },
        },
        internalappConfig.persistMethod,
      )
      //Firefly Code ends here
      triggerFireflyEvent()
    }
  }, [appEnv, session, triggerFireflyEvent])
}

export default useFireflyAdoption
