import { SvgIconComponent } from '@mui/icons-material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import MenuIcon from '@mui/icons-material/Menu'
import { Button } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import { useAuth } from '@praxis/component-auth'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { useUser } from '../../auth/hooks/useUser'
import { useRouteMatch } from '../../hooks/useRouteMatch'
import { setBackToComponent } from '../../store/header/actionCreator'
import TargetLogo from '../Images/TargetLogo'
import { featureConfigManager } from './layoutConfig'

const drawerWidth = 250

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  backgroundColor: '#212121',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}))
/**
 * Main component for the header and side navigation
 * @returns The AppBar header along with the side navigation drawer.
 */
export default function NavigationLayout() {
  const auth = useAuth()

  const [sideNavOpen, setSideNavOpen] = React.useState(false)
  const backToComponent = useSelector((state: any) => state.header.backToComponent)
  const headerTitle = useSelector((state: any) => state.layout.headerTitle)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!auth.isAuthenticated()) {
      auth.login({ redirect: window.location.href })
    }

    if (backToComponent === window.location.pathname) {
      dispatch(setBackToComponent(null))
    }
  }, [dispatch, auth, backToComponent])

  const handleClick = () => {
    dispatch(setBackToComponent(null))
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setSideNavOpen(open)
  }

  const handleHeaderButton = () => {
    return backToComponent && !(backToComponent === window.location.pathname) ? (
      <Link
        to={backToComponent}
        style={{ textDecoration: 'none' }}
        aria-label={`go back to ${backToComponent}`}
        onClick={handleClick}
      >
        <IconButton
          aria-label="Back"
          size="large"
          edge="start"
          data-cy={'backBtn'}
          sx={{ mr: 2, color: 'white', ...(sideNavOpen && { display: 'none' }) }}
        >
          <ArrowBack />
        </IconButton>
      </Link>
    ) : (
      <IconButton
        color="inherit"
        aria-label="open drawer"
        size="large"
        onClick={toggleDrawer(true)}
        edge="start"
        data-cy={'menuButton'}
        sx={{ mr: 2, ...(sideNavOpen && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
    )
  }

  return (
    <>
      <AppBar position="static" color="secondary" sx={{ backgroundColor: 'secondary.main' }}>
        <Toolbar>
          {handleHeaderButton()}
          <Link data-cy="targetLogoButton" to="/" aria-label="Go to home page">
            <TargetLogo />
          </Link>
          <Typography variant="h6" component="h1" sx={{ marginLeft: 1, flexGrow: 1, fontWeight: 'bold' }}>
            myTime
          </Typography>
          <Typography variant="h6" component="h2" sx={{ flexGrow: 12, textAlign: 'right', fontSize: '100%' }}>
            {headerTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <SwipeableTemporaryDrawer toggleDrawer={toggleDrawer} sideNavOpen={sideNavOpen} />
    </>
  )
}

interface ListItemLinkProps {
  Icon?: SvgIconComponent | React.FC<any>
  primary: string
  to: string
}

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />
})

function ListItemLink(props: ListItemLinkProps) {
  const { Icon, primary, to } = props
  const routeMatch = useRouteMatch([to], to === '/' ? true : false)
  let homeMatch = to === '/' && window.location.pathname === '/team-member/home'
  let currentTab = routeMatch?.pattern?.path || homeMatch

  return (
    <Box key={primary} component={'li'} sx={{ ...(currentTab && { backgroundColor: 'tertiary.light' }) }}>
      <ListItemButton component={Link} to={to} data-cy={'menuItem-' + primary}>
        {Icon ? (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        ) : null}
        <ListItemText primary={primary} />
      </ListItemButton>
    </Box>
  )
}

export function SwipeableTemporaryDrawer({ toggleDrawer, sideNavOpen }: any) {
  const { logout } = useAuth()
  const theme = useTheme()
  const { user } = useUser()

  const list = () => {
    const features = featureConfigManager(user)
    return (
      <Box
        sx={{ width: drawerWidth, marginBottom: '30px' }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List disablePadding>
          {features.map((feature, index) => (
            <ListItemLink key={`${feature.to}_${index}`} to={feature.to} primary={feature.label} Icon={feature.Icon} />
          ))}
        </List>
      </Box>
    )
  }

  return (
    <div>
      <React.Fragment key={'left'}>
        <SwipeableDrawer
          swipeAreaWidth={15}
          anchor={'left'}
          open={sideNavOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <DrawerHeader sx={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <Button variant="contained" onClick={logout}>
              Logout
            </Button>
          </DrawerHeader>
          {user && list()}
          <Box
            sx={{
              color: theme.typography.body1.color,
              backgroundColor: 'white',
              fontSize: theme.typography.caption.fontSize,
              display: 'flex',
              flexWrap: 'wrap',
              position: 'fixed',
              bottom: 0,
              paddingBottom: 1,
              listStyle: 'none',
              alignItems: 'center',
              justifyContent: 'center',
              width: drawerWidth,
            }}
          >
            <li> ©Target Corporation</li>
            &nbsp;
            <li>|</li>
            &nbsp;
            <li>
              <Link to="privacypolicy">
                <Typography sx={{ color: theme.typography.caption.color, fontSize: '0.75rem' }}>
                  Privacy Policy
                </Typography>
              </Link>
            </li>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  )
}
