import { Typography } from '@mui/material'
import { TIME_OFF } from '../../../../../constants/RequestConstants'
import { LEADER_DATE_HEADER_FORMAT, LEADER_TIMESTAMP_DETAIL_FORMAT } from '../../../../../constants/leaderConstants'
import { formatDateRangeString, formatDateString } from '../../../../../utils/DateUtil'
import {
  getLabelValueObj,
  getRequestStatusIcons,
  isAnyRequestDetailsFailed,
  isMultipleRequestDetails,
} from '../../../../../utils/leaderUtil'
import TimeOffDetailPage from '../../TimeOffDetailPage'

const getStatusIcon = (dataVO) => {
  return getRequestStatusIcons(dataVO?.requestDetails)
}

const getHeaderPrimary = (dataVO) => {
  return formatDateRangeString(dataVO.startDate, dataVO.endDate, dataVO.getStartDateFormat(), LEADER_DATE_HEADER_FORMAT)
}

const getHeaderSecondary = (dataVO) => {
  return [`${dataVO.fullName} (${dataVO.workerId})`]
}

const getLabelValueList = (dataVO) => {
  return [
    getLabelValueObj(dataVO.getStatusesLabel(), dataVO.statuses),
    getLabelValueObj(dataVO.getDurationLabel(), dataVO.duration),
    getLabelValueObj(
      dataVO.getStartTimestampLabel(),
      formatDateString(dataVO.startTimestamp, dataVO.getStartTimestampFormat(), LEADER_TIMESTAMP_DETAIL_FORMAT),
    ),
    getLabelValueObj(
      dataVO.getEndTimestampLabel(),
      formatDateString(dataVO.endTimestamp, dataVO.getEndTimestampFormat(), LEADER_TIMESTAMP_DETAIL_FORMAT),
    ),
    getLabelValueObj(dataVO.getTypesLabel(), dataVO.types),
    getLabelValueObj(dataVO.getJobClassificationLabel(), dataVO.jobClassification),
    getLabelValueObj(
      dataVO.getCreatedTimestampLabel(),
      formatDateString(dataVO.createdTimestamp, dataVO.getCreatedTimestampFormat(), LEADER_TIMESTAMP_DETAIL_FORMAT),
    ),
    getLabelValueObj(
      dataVO.getLastUpdatedTimestampLabel(),
      formatDateString(
        dataVO.lastUpdatedTimestamp,
        dataVO.getLastUpdatedTimestampFormat(),
        LEADER_TIMESTAMP_DETAIL_FORMAT,
      ),
    ),
    getLabelValueObj(dataVO.getTotalPTOLabel(), dataVO.totalPTO),
    getLabelValueObj(dataVO.getTotalUnpaidLabel(), dataVO.totalUnpaid),
  ]
}

const getSegmentLabelValueList = (dataVO) => {
  let segmentLabelValueList = []
  dataVO.requestDetails?.forEach((detail, i) => {
    segmentLabelValueList.push([
      getLabelValueObj(
        dataVO.getRequestDetailStartTimestampLabel(),
        formatDateString(
          dataVO.getRequestDetailStartTimestamp(i),
          dataVO.getRequestDetailStartTimestampFormat(),
          LEADER_TIMESTAMP_DETAIL_FORMAT,
        ),
      ),
      getLabelValueObj(dataVO.getRequestDetailStatusLabel(), dataVO.getRequestDetailStatus(i)),
      getLabelValueObj(dataVO.getRequestDetailHoursLabel(), dataVO.getRequestDetailHours(i)),
      getLabelValueObj(dataVO.getRequestDetailTypeLabel(), dataVO.getRequestDetailType(i)),
    ])
  })
  return segmentLabelValueList
}

const oneOrMoreSegmentsMessage =
  'One or more request segments has failed to process completely and requires manual action.'
const thisSegmentMessage = 'This request segment has failed to process completely and requires manual action.'
const thisRequestMessage = 'This request has failed to process completely and requires manual action.'
const necessaryActionMessage = "Please take the necessary action on the team member's timecard or schedule and then "
const updateThisFailedStatusMessage = 'update this Failed status accordingly.'
const updateEachFailedStatusMessage = 'update each Failed status below accordingly.'

const getActionSectionInfoMessage = (dataVO) => {
  if (isAnyRequestDetailsFailed(dataVO.requestDetails)) {
    return (
      <>
        <Typography sx={{ p: 2 }}>
          {isMultipleRequestDetails(dataVO.requestDetails) ? thisSegmentMessage : thisRequestMessage}
        </Typography>
        <Typography sx={{ paddingX: 2, paddingBottom: 2 }}>
          {necessaryActionMessage}
          {updateThisFailedStatusMessage}
        </Typography>
      </>
    )
  }
}

const getSegmentSectionInfoMessage = (dataVO) => {
  if (isAnyRequestDetailsFailed(dataVO.requestDetails) && isMultipleRequestDetails(dataVO.requestDetails)) {
    return (
      <>
        <Typography sx={{ p: 2 }}>{oneOrMoreSegmentsMessage}</Typography>
        <Typography sx={{ paddingX: 2, paddingBottom: 2 }}>
          {necessaryActionMessage}
          {updateEachFailedStatusMessage}
        </Typography>
      </>
    )
  }
}

const getSegmentSectionInfoLabel = (dataVO) => {
  if (isAnyRequestDetailsFailed(dataVO.requestDetails) && isMultipleRequestDetails(dataVO.requestDetails)) {
    return 'Error Processing Request'
  }
}

const TimeOffDetail = ({ dataVO }) => {
  return (
    <TimeOffDetailPage
      comments={dataVO.comments}
      requestId={dataVO.requestId}
      requestDetails={dataVO.requestDetails}
      statusIcon={getStatusIcon(dataVO)}
      headerPrimary={getHeaderPrimary(dataVO)}
      headerSecondary={getHeaderSecondary(dataVO)}
      category={TIME_OFF}
      labelValueList={getLabelValueList(dataVO)}
      segmentLabelValueList={getSegmentLabelValueList(dataVO)}
      segmentSectionInfoLabel={getSegmentSectionInfoLabel(dataVO)}
      segmentSectionInfoMessage={getSegmentSectionInfoMessage(dataVO)}
      actionSectionInfoMessage={getActionSectionInfoMessage(dataVO)}
    />
  )
}

export default TimeOffDetail
