import { Button, Grid, TextField, useTheme } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  REQUEST_TYPES,
  REQUEST_TYPE_AVAILABILITY,
  REQUEST_TYPE_MASS_VACATION,
  REQUEST_TYPE_PUNCH_CORRECTION,
  REQUEST_TYPE_TIME_OFF,
  requestStatusTypes,
} from '../../constants/RequestConstants'
import { LOCATION_TYPE_OFFICE, LOCATION_TYPE_RGD } from '../../constants/locationConstants'
import { setBackToComponent } from '../../store/header/actionCreator'
import { showNotificationError } from '../../store/notification/actionCreator'
import { massVacationManager } from '../../utils/DateUtil'
import RequestStatusIcon from './RequestStatusIcon'
import RequestTypeDialog from './RequestTypeDialog'
import { useUser } from '../../auth/hooks/useUser'

const RequestsHeader = (props) => {
  const { handleStatusChange, status } = props
  const theme = useTheme()
  const { userData, locationData } = useUser().user
  const [newRequestDialogOpen, setNewRequestDialogOpen] = useState(false)
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const requestType = useSelector((state) => state.requests.requestType)
  const mvManager = massVacationManager()
  const queryClient = useQueryClient()

  const handleTypeChange = (event) => {
    navigate(`/team-member/requests/${event.target.value}`)
  }

  const handleStatusFilter = (event) => {
    handleStatusChange(event.target.value)
  }

  const handleNewRequestClick = () => {
    if (requestType === REQUEST_TYPE_AVAILABILITY) {
      dispatch(setBackToComponent('/team-member/requests/availability'))
      navigate('/team-member/availability/create-new-availability')
    } else if (requestType === REQUEST_TYPE_PUNCH_CORRECTION) {
      dispatch(setBackToComponent('/team-member/requests/punch_correction'))
      navigate('/team-member/timecardWeekly')
    } else if (requestType === REQUEST_TYPE_MASS_VACATION) {
      let massVacationData = queryClient.getQueryData(['requests', { requestType: REQUEST_TYPE_MASS_VACATION }], {
        exact: false,
      })
      if (massVacationData.pages[0]?.total_requests === 10) {
        dispatch(
          showNotificationError(
            true,
            'You have reached the maximum of 10 mass vacation reqeusts allowed. You must remove a prior mass vacation request to proceed.',
          ),
        )
      } else if (massVacationData.pages[0]?.is_disabled) {
        dispatch(showNotificationError(true, massVacationData.pages[0]?.message))
      } else {
        dispatch(setBackToComponent('team-member/requests/mass_vacation'))
        navigate('team-member/requests/mass_vacation/create-mass-vacation', {
          state: { data: { shiftLengthMinutes: parseInt(massVacationData.pages[0]?.shift_length_minutes) || 0 } },
        })
      }
    } else {
      setNewRequestDialogOpen(true)
    }
  }

  const handleRequestTypeDialogClickCancel = () => {
    setNewRequestDialogOpen(false)
  }

  const renderStatusFilters = () => {
    const statusTypes = requestStatusTypes[locationData.location_type][requestType]?.statuses
    if (statusTypes) {
      // do not show team member Failed status in certain scenarios
      if (
        (requestType === REQUEST_TYPE_TIME_OFF &&
          ![LOCATION_TYPE_OFFICE, LOCATION_TYPE_RGD].includes(locationData.location_type)) ||
        requestType === REQUEST_TYPE_AVAILABILITY
      ) {
        delete statusTypes.FAILED
      }

      return Object.entries(statusTypes).map((sType, i) => {
        return (
          <MenuItem key={i} value={sType[0]}>
            <Grid sx={{ display: 'flex', alignItems: 'flex-end', overflow: 'hidden' }}>
              {sType[1] !== 'All' && <RequestStatusIcon status={sType[1]} />}
              &nbsp;{sType[1]}
            </Grid>
          </MenuItem>
        )
      })
    }
  }

  const renderTimeOffType = () => {
    let typeNameIndex = 0
    let accessIndex = 1
    const requestTypeMenuItems = Object.entries(REQUEST_TYPES).map((rType, i) => {
      if (rType[typeNameIndex] === REQUEST_TYPE_MASS_VACATION && mvManager.hideForTM) {
        return null
      }
      if (rType[accessIndex].access.some((feature) => userData.features?.[feature]?.['team_member'])) {
        return (
          <MenuItem key={i} value={rType[typeNameIndex]}>
            <Grid sx={{ overflow: 'hidden' }}>{rType[accessIndex].type}</Grid>
          </MenuItem>
        )
      } else {
        return null
      }
    })

    return (
      <TextField
        sx={{ minWidth: '60px', maxWidth: { xs: '120px', sm: '150px' } }}
        select
        label="Type"
        id="select-label-id-type"
        value={requestType}
        onChange={handleTypeChange}
      >
        {requestTypeMenuItems}
      </TextField>
    )
  }

  const renderStatus = () => {
    return (
      <TextField
        fullWidth
        sx={{ maxWidth: { xs: '110px', sm: '200px' } }}
        select
        data-cy={'changeStatusFilter'}
        id="select-label-id-status"
        value={status}
        onChange={handleStatusFilter}
        label="Status"
      >
        {requestType ? renderStatusFilters() : <></>}
      </TextField>
    )
  }

  const renderNewRequest = () => {
    let disabled = requestType === 'mass_vacation' && mvManager.submitDisabled
    return (
      <Button color="primary" variant="contained" size="small" disabled={disabled} onClick={handleNewRequestClick}>
        New Request
      </Button>
    )
  }

  return (
    <>
      <Paper
        sx={{
          margin: '0 auto',
          maxWidth: theme.mainScrollContainer.maxWidth,
          width: '100%',
          height: 'auto',
          marginTop: {
            md: '20px',
          },
        }}
        square
      >
        <Grid container wrap="nowrap" padding={1} justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>{renderTimeOffType()}</Grid>
          {requestType ? (
            <>
              {requestType !== REQUEST_TYPE_MASS_VACATION ? <Grid item>{renderStatus()}</Grid> : <></>}
              <Grid item alignSelf={'center'}>
                {renderNewRequest()}
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Paper>
      {newRequestDialogOpen ? <RequestTypeDialog handleClickCancel={handleRequestTypeDialogClickCancel} /> : null}
    </>
  )
}

export default RequestsHeader
