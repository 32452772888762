import CloseIcon from '@mui/icons-material/Close'
import { Grid, Popover, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSortBy, setSortOrder } from '../../../../store/leader/actionCreator'
import SwapVertIcon from '../../../Icons/SwapVertIcon'

const getStyles = (theme) => ({
  popoverRoot: {
    '& .MuiPopover-paper': {
      padding: theme.spacing(1),
    },
  },
})

const SortBy = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const [anchorEl, setAnchorEl] = useState(null)

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'))

  const open = Boolean(anchorEl)

  const dispatch = useDispatch()
  const selectedCategory = useSelector((state) => state.leader.selectedCategory)
  const selectedSortByIndex = useSelector((state) => state.leader[selectedCategory]?.selectedSortByIndex)
  const sortOrder = useSelector((state) => state.leader[selectedCategory]?.sortOrder)
  const sortableColumns = useSelector((state) => state.leader[selectedCategory]?.sortableColumns)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSortOrder = (event) => {
    dispatch(setSortOrder(event.target.value))
  }

  const handleSortBy = (event) => {
    dispatch(setSortBy(event.target.value))
    handleClose()
  }

  return (
    <>
      <Grid container alignItems={'center'}>
        <IconButton size={'small'} onClick={handleClick} data-cy={'sortByBtn'} aria-label={'sort'}>
          <SwapVertIcon sortOrder={sortOrder} />
        </IconButton>
        {isDesktop && selectedSortByIndex !== undefined ? (
          <Typography variant="subtitle2" component="p">
            &nbsp;{sortableColumns[selectedSortByIndex]?.display_name}
          </Typography>
        ) : null}
      </Grid>
      <Popover
        sx={styles.popoverRoot}
        data-cy="sortModal"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {selectedCategory && sortableColumns ? (
          <Grid container direction="column" justifyContent="center" spacing={1}>
            <Grid item>
              <FormLabel id="sort-order-label">Sort Order</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={sortOrder || ''}
              >
                <FormControlLabel
                  value="ASC"
                  control={<Radio />}
                  label="ascending"
                  onClick={handleSortOrder}
                  data-cy={'sortASC'}
                />
                <FormControlLabel
                  value="DESC"
                  control={<Radio />}
                  label="descending"
                  onClick={handleSortOrder}
                  data-cy={'sortDESC'}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                labelid="select-sort-by"
                id="sort-by"
                value={selectedSortByIndex}
                label="Sort By"
                onChange={handleSortBy}
                fullWidth
                data-cy={`sortBySelect`}
              >
                {sortableColumns.map((option, index) => (
                  <MenuItem key={option.columnName} value={index} data-cy={`sortBy${option.columnName}`}>
                    {option.displayName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container direction="row" alignItems={'center'} spacing={1}>
              <Grid item>
                <Typography label="Please select a category">Please select a category</Typography>
              </Grid>
              <Grid item>
                <IconButton size={'small'} aria-label={'Close'} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        )}
      </Popover>
    </>
  )
}

export default SortBy
