import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useState } from 'react'
import { formatMinutesToDisplay, getDateRangeAsStringFromDates } from '../../../utils/DateUtil'

const SUBMIT_DIALOG_TITLE = 'Are you sure you want to submit?'

const SAVE_DIALOG_TEXT = 'I confirm that what I am submitting is accurate.'

const REMINDER_MESSAGE = (
  <span>
    <span style={{ fontWeight: '600', color: 'black' }}> {'Note: '} </span>
    {'Ensure you have the time accrued.'}
  </span>
)

const SubmitModal = ({
  submitDialogOpen,
  handleYesForSubmitAction,
  handleNoForSubmitAction,
  totalMinutes,
  totalPTOMinutes,
  totalUnpaidMinutes,
  startDate,
  endDate,
  showAccrualMessage,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleYes = () => {
    if (buttonDisabled) return
    setButtonDisabled(true)
    handleYesForSubmitAction()
    setTimeout(() => {
      setButtonDisabled(false)
    }, 1000)
  }

  const handleNo = () => {
    handleNoForSubmitAction()
  }

  return (
    <Dialog
      data-cy="requestTimeOffSubmitDialog"
      open={submitDialogOpen}
      aria-describedby="save-dialog-content"
      aria-labelledby="save-dialog-title"
      aria-modal={true}
      keepMounted
    >
      {totalMinutes ? (
        <>
          <DialogTitle id="save-dialog-title">{SUBMIT_DIALOG_TITLE}</DialogTitle>
          <DialogContent
            id="save-dialog-content"
            aria-label={`Requesting a total of ${formatMinutesToDisplay(
              totalMinutes,
              'large',
            )} on ${getDateRangeAsStringFromDates(startDate, endDate)}`}
          >
            <DialogContentText id="save-dialog-length-title">
              {'Requesting a total of'}{' '}
              <span style={{ fontWeight: '600', color: 'black' }}>{`${formatMinutesToDisplay(
                totalMinutes,
                'large',
              )}`}</span>
              {' on'}
            </DialogContentText>
            <DialogContentText id="save-dialog-dates" fontWeight={'600'} color={'textPrimary'}>
              {getDateRangeAsStringFromDates(startDate, endDate)}
            </DialogContentText>
            {totalPTOMinutes ? (
              <>
                <br />
                <DialogContentText id="save-dialog-pto">{'Total Paid Time Off (PTO)'}</DialogContentText>
                <DialogContentText id="save-dialog-pto-title" fontWeight={'600'} color={'textPrimary'}>
                  {`${formatMinutesToDisplay(totalPTOMinutes, 'large')}`}
                </DialogContentText>
              </>
            ) : (
              <></>
            )}
            {totalUnpaidMinutes ? (
              <>
                <br />
                <DialogContentText id="save-dialog-unpaid">{'Total Unpaid'}</DialogContentText>
                <DialogContentText id="save-dialog-unpaid-title" fontWeight={'600'} color={'textPrimary'}>
                  {`${formatMinutesToDisplay(totalUnpaidMinutes, 'large')}`}
                </DialogContentText>
              </>
            ) : (
              <></>
            )}

            <DialogContentText id="save-dialog-description" marginTop={'20px'}>
              {SAVE_DIALOG_TEXT}
            </DialogContentText>
            {showAccrualMessage ? (
              <DialogContentText id="save-dialog-reminder">{REMINDER_MESSAGE}</DialogContentText>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNo} color="primary">
              NO
            </Button>
            <Button onClick={handleYes} color="primary" variant="contained" aria-label="Yes" disabled={buttonDisabled}>
              YES
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent id="save-dialog-content" aria-label={`Please select more than 0 minutes`}></DialogContent>
          <DialogTitle id="save-dialog-no-hours">{'Please select more than 0 minutes'}</DialogTitle>
          <DialogActions>
            <Button onClick={handleNo} color="primary">
              OK
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default SubmitModal
