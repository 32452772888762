import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AvailabilityCancelModal from '../../Leader/CategoryDetail/Categories/Availability/AvailabilityCancelModal'
import AvailabilitySubmitModal from './AvailabilitySubmitModal'
import AvailabilityAlertModal from './AvailabilityAlertModal'
import { clearPreviousState } from '../../../store/availability/actionCreator'
import { showNotificationError } from '../../../store/notification/actionCreator'

const styles = {
  bottomButtons: {
    margin: '10px',
  },
  subTitle: {
    fontWeight: 600,
  },
  voluntarySelection: {
    marginTop: '20px',
  },
}

const AvailabilitySubmitCancel = (props) => {
  const { showCancelDialog, readyToSubmit, submitClicked, submitBtnDisabled, alertMsg } = props

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [alertDialogOpen, setAlertDialogOpen] = useState(false)
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleYesForCancelAction = () => {
    setCancelDialogOpen(false)
    dispatch(clearPreviousState())
    navigate(-1)
  }

  const handleNoForCancelAction = () => {
    setCancelDialogOpen(false)
  }

  const handleYesForSubmitAction = () => {
    setSubmitDialogOpen(false)
    submitClicked()
  }

  const handleNoForSubmitAction = () => {
    setSubmitDialogOpen(false)
  }

  const handleOkForAlertAction = () => {
    setAlertDialogOpen(false)
  }

  const handleCancel = () => {
    if (showCancelDialog) {
      setCancelDialogOpen(true)
    } else {
      dispatch(clearPreviousState())
      navigate(-1)
    }
  }

  const handleSubmit = () => {
    if (readyToSubmit) {
      setSubmitDialogOpen(true)
    } else {
      if (alertMsg === 'FormNotStartedErrMsg') {
        dispatch(
          showNotificationError(
            true,
            'No availability selected. You must have at least one day of availability information filled in to proceed.',
          ),
        )
        return
      } else if (alertMsg === 'FormIncompleteErrMsg') {
        dispatch(
          showNotificationError(true, 'You have unresolved errors on the screen. Please resolve before submitting.'),
        )
        return
      }
    }
  }

  return (
    <React.Fragment>
      <Button data-cy="availabilityCancel" sx={styles.bottomButtons} color="primary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        data-cy="availabilitySubmit"
        sx={styles.bottomButtons}
        variant="contained"
        size="small"
        color="primary"
        onClick={handleSubmit}
        disabled={submitBtnDisabled}
      >
        Submit
      </Button>
      <AvailabilityCancelModal
        cancelDialogOpen={cancelDialogOpen}
        handleYesForCancelAction={handleYesForCancelAction}
        handleNoForCancelAction={handleNoForCancelAction}
      />
      <AvailabilityAlertModal
        alertDialogOpen={alertDialogOpen}
        handleOkForAlertAction={handleOkForAlertAction}
        alertMsg={alertMsg}
      />
      <AvailabilitySubmitModal
        submitDialogOpen={submitDialogOpen}
        handleYesForSubmitAction={handleYesForSubmitAction}
        handleNoForSubmitAction={handleNoForSubmitAction}
      />
    </React.Fragment>
  )
}

export default AvailabilitySubmitCancel
