import { Button, Grid, Link, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const getStyles = (theme) => ({
  nxtAvlTotal: {
    marginTop: '8px',
  },
  nxtAvlBtnCls: {
    marginTop: '20px',
  },
  dailyLink: {
    color: theme.palette.secondary.dark,
    textDecoration: 'none',
  },
})

const NextAvailableWeek = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { weekStart, weekEnd, avlShiftWeekCount } = props
  let navigate = useNavigate()

  const handleLink = () => {
    navigate('/availableShifts?expand=all')
  }

  const outputDateFormat = 'MMM D'
  return (
    <center>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" component="p">
            {moment(weekStart)?.format(outputDateFormat) + ' - ' + moment(weekEnd)?.format(outputDateFormat)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={styles.nxtAvlTotal}>
          <Typography variant="body2">{`${avlShiftWeekCount} available shifts`}</Typography>
        </Grid>
        {avlShiftWeekCount ? (
          <Grid item xs={12} md={12} sx={styles.nxtAvlBtnCls}>
            <Link to="/team-member/dailySchedulePage/schedule" style={{ textDecoration: 'none' }}>
              <Button color="primary" variant="contained" size="small" onClick={() => handleLink()}>
                Available Shifts
              </Button>
            </Link>
          </Grid>
        ) : null}
      </Grid>
    </center>
  )
}

export default NextAvailableWeek
