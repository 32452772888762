import ChevronRight from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton'
import { isAfter } from 'date-fns'
import useClickPreventionOnDoubleClick from '../../hooks/use-click-prevention-on-double-click'

const CutomisedHookButton = ({ styles, maxDate, endWeek, onClick, onDoubleClick }) => {
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick)

  return (
    <IconButton
      sx={styles.icon}
      data-cy="weekForwardBtn"
      disabled={maxDate && !isAfter(maxDate, endWeek)}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      aria-label={'Go To Next Week'}
      size="large"
      tabIndex={0}
    >
      <ChevronRight />
    </IconButton>
  )
}

const NextWeekButton = (props) => (
  <CutomisedHookButton
    onDoubleClick={props.handleDoubleClick}
    onClick={props.handleSubmit}
    maxDate={props.maxDate}
    endWeek={props.endWeek}
    styles={props.styles}
  />
)

export default NextWeekButton
