import { Button, Card, Container, Grid, Typography } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { useEffect } from 'react'
import { TeamMemberBarcode } from './Barcode'

function isBarcodeExpired() {
  let expiration = localStorage.getItem('my_time_barcode_exp')
  if (expiration > Date.now()) {
    return false
  } else {
    localStorage.removeItem('my_time_barcode')
    localStorage.removeItem('my_time_barcode_exp')
    return true
  }
}

export default function LogIn() {
  const { login } = useAuth()

  useEffect(() => {
    if (!localStorage.getItem('barcode_team_member')) {
      login({ redirect: window.location.href })
    }
  }, [login])

  return (
    localStorage.getItem('barcode_team_member') && (
      <LogInContainer>
        <Card sx={{ width: '100%', padding: '10px' }}>
          <Grid container sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <WelcomeHeader />
            <BarcodeSection />
            <LoginButton onClick={() => login({ redirect: window.location.href })} />
          </Grid>
        </Card>
      </LogInContainer>
    )
  )
}

const LogInContainer = ({ children }) => (
  <Container sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
    <Grid container sx={{ justifyContent: 'center' }}>
      {children}
    </Grid>
  </Container>
)

const WelcomeHeader = () => (
  <Grid item>
    <Typography component="h1" variant="h1" sx={{ fontSize: '24px' }}>
      Welcome to myTime for Target!
    </Typography>
  </Grid>
)

const BarcodeSection = () => {
  if (!isBarcodeExpired()) {
    return (
      <>
        <Grid
          container
          item
          sx={{
            paddingY: '50px',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TeamMemberBarcode />
        </Grid>
        <Grid item sx={{ paddingBottom: '10px' }}>
          <Typography component="h2">Please login to access additional features.</Typography>
        </Grid>
      </>
    )
  } else {
    return (
      <Typography component="h2" sx={{ marginTop: '20px', marginBottom: '10px' }}>
        Login to refresh your barcode
      </Typography>
    )
  }
}

const LoginButton = ({ onClick }) => (
  <Button
    data-testid="loginButton"
    aria-label="Login"
    variant="contained"
    type="primary"
    sx={{ width: '200px', marginBottom: '10px' }}
    onClick={onClick}
  >
    Log In
  </Button>
)
