import { IconButton, List, ListItemButton, ListItemText, useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import React, { useState } from 'react'
import { CURRENT_PAY_PERIOD, CUSTOM, PREV_PAY_PERIOD } from '../../constants/PayPeriodMenuItems'
import CloseIcon from '@mui/icons-material/Close'

const getStyles = (theme) => ({
  dialogRoot: {
    padding: '45px 24px 16px 24px',
  },
  btnStyles: {
    cursor: 'pointer',
  },
})

const options = [
  { id: 1, menuLabel: PREV_PAY_PERIOD },
  { id: 2, menuLabel: CURRENT_PAY_PERIOD },
  { id: 3, menuLabel: CUSTOM },
]

const PayPeriodMenuDialog = (props) => {
  const { onClose, value: valueProp, open, ...other } = props
  const [value, setValue] = React.useState(valueProp)
  const [selected, setSelected] = useState(null)
  const theme = useTheme()
  const styles = getStyles(theme)

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp)
      for (let option of options) {
        if (option.menuLabel === valueProp) {
          setSelected(option.id)
          break
        }
      }
    }
  }, [valueProp, open])

  React.useEffect(() => {
    onClose(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (option) => {
    setSelected(option.id)
    setValue(option.menuLabel)
  }

  return (
    <div>
      <Dialog
        role="dialog"
        fullWidth={true}
        open={open}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            onClose(null)
          }
        }}
        {...other}
      >
        <IconButton
          aria-label="close"
          onClick={(e) => onClose(null)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers={true} sx={styles.dialogRoot}>
          <List component="nav" aria-label="pay period menu" role="menu">
            {options.map((option, index) => (
              <ListItemButton
                data-cy={`payPeriodDropDownListItem_${option.id}`}
                divider={index < options.length - 1}
                onClick={() => handleChange(option)}
                selected={selected === option.id}
                key={index}
                role="menuitem"
              >
                <ListItemText sx={styles.btnStyles} primary={option.menuLabel} />
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PayPeriodMenuDialog
