import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import CategoryDetailDataHeader from '../Leader/CategoryDetail/CategoryDetailDataHeader'
import { memo, useEffect, useState } from 'react'
import LinearLoader from '../common/loaders/LinearLoader'
import { useGetPunchCorrectionAuditById } from '../../api/punchcorrection/useGetPunchCorrectionAuditById'
import PunchCorrectionAuditList from './PunchCorrectionAuditList'

const DIALOG_TITLE = 'Punch Correction - Audit Trail'

const getStyles = () => ({
  dialogPaper: {
    margin: '-15px',
  },
  dialogContent: {
    padding: '0px 24px 0px 24px',
  },
  closeBtnCls: {
    padding: '0px 24px 20px 24px',
  },
  auditTextCls: {
    color: '#cc0000',
    padding: '5px 0 15px 15px',
  },
  noAuditInfoCls: {
    color: '#333',
    padding: '0 0 15px 15px',
  },
})

const PunchCorrectionAuditDialog = ({
  requestId,
  statusIcon,
  headerPrimary,
  headerSecondary,
  auditDialogOpen,
  handleClose,
}) => {
  const styles = getStyles()
  const {
    data: auditResponseData,
    error: auditResposeError,
    isLoading,
    isFetching,
  } = useGetPunchCorrectionAuditById({ requestId })
  const [auditData, setAuditData] = useState({})

  useEffect(() => {
    if (auditResponseData?.data) {
      setAuditData(auditResponseData.data)
    }
  }, [auditResponseData])

  return (
    <Dialog
      role="dialog"
      data-cy="Punch-Correction-AuditDialog"
      open={auditDialogOpen}
      aria-labelledby="Punch-Correction-audit-dialog-title"
      aria-modal={true}
      keepMounted={false}
      fullWidth
      sx={styles.dialogPaper}
    >
      <DialogTitle id="Punch-Correction-audit-dialog-title" data-cy="Punch-Correction-audit-dialog-title">
        {DIALOG_TITLE}
      </DialogTitle>
      <section>
        <DialogContent sx={styles.dialogContent}>
          <CategoryDetailDataHeader
            requestStatusIcon={statusIcon}
            primary={headerPrimary}
            secondaryList={headerSecondary}
            purpose="audit"
          />
          {isFetching || isLoading ? <LinearLoader /> : null}
          {auditResposeError?.response?.data && (
            <Typography variant="body2" sx={styles.auditTextCls}>
              Error loading audit trail. Please try after sometime.
            </Typography>
          )}
          {auditData?.total_punch_correction_requests === 0 && (
            <Typography variant="body2" sx={styles.noAuditInfoCls}>
              No audit information available.
            </Typography>
          )}
          {auditData?.total_punch_correction_requests > 0 && (
            <PunchCorrectionAuditList auditList={auditData.punch_correction_audits} request_id={auditData.request_id} />
          )}
        </DialogContent>
      </section>
      <DialogActions sx={styles.closeBtnCls}>
        <Button
          data-cy="audit-dialog-close-btn"
          onClick={handleClose}
          color="primary"
          variant="contained"
          aria-label="Yes"
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(PunchCorrectionAuditDialog)
