import { CircularProgress, useTheme } from '@mui/material'

const Loader = (props) => {
  const theme = useTheme()

  const { size } = props
  return (
    <div style={theme.mainContainerPosition}>
      <div style={theme.loadingIconContainer}>
        <CircularProgress size={size} />
      </div>
    </div>
  )
}

export default Loader
