import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import { getUserFeaturesV2 } from './api/access'

export function useUserAccess() {
  const { session, isAuthenticated } = useAuth()

  const authenticated = isAuthenticated()

  const res = useQuery({
    queryKey: ['features-v2'],
    queryFn: () => getUserFeaturesV2({ worker_id: session?.userInfo?.empid }),
    staleTime: 1000 * 60 * 5,
    enabled: authenticated && !!session,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    keepPreviousData: true,
  })

  return res
}
