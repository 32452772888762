import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { withAuth } from '@praxis/component-auth'
import {
  addDays,
  differenceInMinutes,
  endOfWeek,
  format,
  getDay,
  isAfter,
  isBefore,
  parse,
  startOfWeek,
} from 'date-fns'
import { get } from 'lodash'
import moment from 'moment'
import { object, shape } from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Element, scroller } from 'react-scroll'
import { bindActionCreators } from 'redux'
import praxisTheme from '../../config/themeConfig'
import { SUCCESS_ON_SAVE, UNABLE_TO_SAVE } from '../../constants/errorConstants'
import {
  clearWithdrawPostRequests,
  handleGetAvailableShiftStatus,
  handleGetSwapRequests,
  handleGetWeeklyScheduleData,
  saveSwapRequestsDateTimeWiseMap,
  setAvailableShiftStatusLoading,
  setLoading,
  setWeeklyScheduleSelectedDate,
} from '../../store/WeeklySchedulePage/actionCreator'
import { setDailyScheduleSelectedDate } from '../../store/dailySchedulePage/actionCreator'
import { setBackToComponent } from '../../store/header/actionCreator'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { clearAccepterRequests, clearInitiatePostRequests } from '../../store/swapShift/actionCreator'
import {
  getDateOfTodayWithNoTimestamp,
  getNowDateInTimezone,
  getNowDateTimeInTimezone,
  isDateAfterNumberOfWeeksFromToday,
  isDateBeforeNumberOfWeeksFromToday,
} from '../../utils/DateUtil'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { formatErrorCode } from '../../utils/ErrorHandling'
import { REQUEST_TO_COVER, SCHEDULE_TEAM_MEMBER, SCREEN_NAME_SWAP_SHIFT } from '../../utils/ScreenName'
import WeeklyCalendar from '../Calendar/WeeklyCalendar'
import DisplaySegments from '../DisplaySegments/DisplaySegments'
import HeaderTitle from '../Header/HeaderTitle'
import SwapShiftsMessgeAction from '../SwapShift/SwapShiftsMessgeAction'
import NotAuthorized from '../common/fallback/NotAuthorized'

const styles = {
  mainScrollContainer: praxisTheme.mainScrollContainer,
  mainContainerPosition: praxisTheme.mainContainerPosition,
  infoMessage: praxisTheme.infoMessages,
  infoMessageDetail: praxisTheme.infoMessageDetail,
  errorCodeMessage: praxisTheme.errorCodeMessages,
  errorMessage: praxisTheme.errorMessages,
  loadingIconContainer: praxisTheme.loadingIconContainer,
  highlights: {
    fontWeight: 'bold',
    fontSize: '105%',
    paddingTop: '2px',
  },
  highlightsContainer: {
    textAlign: 'center',
    borderBottom: `4px solid ${praxisTheme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
  },
  highlightsContainerPosition: {
    width: '100%',
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingTop: '0',
    paddingBottom: '0',
  },
  displaySegmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
    justifyContent: 'center',
  },
  displaySegmentContainerModal: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1em',
    justifyContent: 'center',
  },
  displaySegmentContainerSwap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0.2em',
    justifyContent: 'center',
  },
  titleContainerModal: {
    padding: '16px 24px 5px',
  },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '.5em',
    justifyContent: 'right',
    alignItems: 'flex-end',
  },
  padLeftForIcons: {
    paddingLeft: '2.4em',
  },
  startTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
    color: '#000000',
  },
  endTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
    color: '#000000',
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: '1',
    minHeight: '32px',
    paddingBottom: '.25em',
  },
  locationName: {
    paddingTop: '3px',
    color: '#000000',
  },
  jobContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobContainerAtPopup: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobName: {
    paddingTop: '3px',
    color: '#000000',
  },
  iconMargin: {
    marginRight: '.25em',
  },
  personIconMargin: {
    marginRight: '.6em',
  },
  indicator: {
    marginTop: '-2px',
    marginLeft: '-15px',
    marginRight: '5px',
    fontSize: '24px',
    color: 'black',
    zIndex: '1',
  },
  jobNameContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
  },
  paycodeEditsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
  },
  paycodeEdits: {
    fontWeight: '300',
  },
  dateHeading: {
    fontWeight: 'bold',
    fontSize: 'medium',
    color: '#000000',
  },
  lastEdit: {
    color: praxisTheme.palette.secondary.light,
  },
  dividerBox: {
    width: '100%',
    marginTop: '10px',
  },
  postShiftButton: {
    minWidth: '50px',
  },
  tableList: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  tableItem: {
    display: 'table-row',
  },
  listItemDiv: {
    position: 'relative',
  },
  loaderStyles: {
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
  },
  innerLoaderLabelStyles: {
    paddingLeft: '6px',
    fontColor: '#cc0000',
    color: '#CC0000',
  },
  addtionalJobsContainer: {
    paddingTop: '1em',
  },
  dateContainer: {
    display: 'flex',
  },
  shiftLabelContainer: {
    marginTop: '1px',
    marginLeft: '12px',
    display: 'inline-Block',
  },
  shiftLabelContainerInModal: {
    marginTop: '1px',
    display: 'inline-Block',
  },
  additionalShiftLabels: {
    margin: '2px 6px',
  },
  shiftLabel: {
    border: '1px solid #333',
    color: '#333',
    padding: '1px 5px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
  },
  verticalGap: {
    padding: '10px 5px',
    textAlign: 'center',
    width: '90%',
  },
  pb10: {
    paddingBottom: '10px',
  },
  preInfoText: {
    color: '#cc0000',
    fontStyle: 'italic',
    marginTop: '10px',
  },
  requestContainerModal: {
    margin: '-4px',
    padding: '5px 10px 0 10px',
    border: '1px solid #aeaeae',
    borderRadius: 7,
    position: 'relative',
  },
  marginTop1: {
    marginTop: '1em',
  },
  dateHeadingSwap: {
    fontWeight: 'bold',
    fontSize: '0.9em',
    color: '#000000',
  },
  shiftSwapMsgIconStyle: {
    padding: 0,
    margin: '0 3px 0 6px',
  },
}

const WEEKLY_SCHEDULE_PAGE_PATH = '/team-member/schedule'
const LOAD_ERROR_MESSAGE =
  'Unable to load your schedule at this time.  Please try again later and if the issue persists, contact the CSC.'
const ERROR_CODES_SHOW_MESSAGE = ['wfm-1-5', 'wfm-2-5', 'wfm-1-11', 'wfm-2-11', 'wfm-14-5', 'wfm-14-11']
const ERROR_CODE_CANNOT_CONNECT_TO_SERVER = 'wfm-2-0'
const ERROR_CODE_CANNOT_CONNECT_TO_LOCATION = ' (error code: wfm-10-0)'
const LOCATION_API_STORE_LOCATION_TYPE = 'STR'
const DATE_RANGE_PAST_WEEKS = 13
const DATE_RANGE_STORE_FUTURE_WEEKS = 26 // 6 months
const DATE_RANGE_DC_FUTURE_WEEKS = 4
const DATE_BEFORE_RANGE_MESSAGE = 'Schedules are only available 13 weeks in the past.'
const DATE_AFTER_RANGE_DC_MESSAGE = 'Schedules are only available 4 weeks into the future.'
const DATE_AFTER_RANGE_STORE_MESSAGE = 'Schedules are only available 6 months into the future.'
const ELIGIBLE_TO_POST = 'ELIGIBLE_TO_POST'
const ACCEPTED = 'ACCEPTED'
const PENDING = 'PENDING'
const PAST = 'PAST'

const SWAP_STATUS_OPEN = 'OPEN'
const ELIGIBLE_TO_SWAP = 'ELIGIBLE_TO_SWAP'
const CLOSED_AND_SWAPPED = 'CLOSED_AND_SWAPPED'
const CLOSED_BUT_NOT_SWAPPED = 'CLOSED_BUT_NOT_SWAPPED'
const FAILED_DUE_TO_RULE_VIOLATION = 'FAILED_DUE_TO_RULE_VIOLATION'
const FAILED_DUE_TO_NETWORK_ISSUE = 'FAILED_DUE_TO_NETWORK_ISSUE'
const WITHDRAWN = 'WITHDRAWN'

class WeeklySchedulePage extends React.Component {
  static propTypes = {
    classes: object,
    layoutActions: shape({
      setLoading: 'N',
    }),
  }

  constructor(props) {
    super(props)
    this.state = {
      dateBeforeRange: false,
      dateAfterRange: false,
    }
  }

  componentDidMount() {
    const { iso_time_zone_code } = this.props.locationDetails
    let currentSelectedDate

    if (this.props.backToComponent && this.props.backToComponent === WEEKLY_SCHEDULE_PAGE_PATH) {
      this.props.setBackToComponent(null)
      currentSelectedDate = this.props.dailyScheduleSelectedDate
    } else if (iso_time_zone_code) {
      currentSelectedDate = getNowDateInTimezone(iso_time_zone_code)
    }

    if (currentSelectedDate) {
      this.setSelectedDate(currentSelectedDate)
    }

    if (this.props.userDetails) {
      if (this.props.screenAccess.includes(SCHEDULE_TEAM_MEMBER) && currentSelectedDate) {
        this.getWeeklyScheduleData(currentSelectedDate, this.props.userDetails)
      }
    }
    this.props.clearInitiatePostRequests()
    this.props.clearWithdrawPostRequests()
    this.props.clearAccepterRequests()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      userDetails,
      auth,
      screenAccess,
      selectedDate,
      locationDetails,
      postShiftPostErrResponse,
      withdrawSwapRequestErrResponse,
    } = this.props
    if (!auth.isAuthenticated()) {
      auth.login({ redirect: window.location.href })
    }
    const { userInfo } = auth.session

    const dateFormat = 'yyyy-MM-dd'
    let startDate = format(startOfWeek(selectedDate), dateFormat)
    let endDate = format(endOfWeek(selectedDate), dateFormat)

    let currentSelectedDate
    if (prevProps.locationDetails.iso_time_zone_code !== locationDetails.iso_time_zone_code) {
      currentSelectedDate = getNowDateInTimezone(locationDetails.iso_time_zone_code)
      this.setSelectedDate(currentSelectedDate)
    } else {
      currentSelectedDate = selectedDate
    }

    if (
      (prevProps.screenAccess !== screenAccess ||
        prevProps.locationDetails.iso_time_zone_code !== locationDetails.iso_time_zone_code) &&
      screenAccess.includes(SCHEDULE_TEAM_MEMBER) &&
      locationDetails.iso_time_zone_code
    ) {
      if (prevProps.userDetails !== userDetails || Object.keys(userDetails).length > 0) {
        this.getWeeklyScheduleData(currentSelectedDate, userDetails)
      }
    }

    if (this.props.withdrawSwapRequestResponse) {
      if (this.props.screenAccess.includes(SCREEN_NAME_SWAP_SHIFT)) {
        this.props.handleGetSwapRequests(padEmpIdWithZeros(userInfo?.empid, 10), startDate, endDate)
      }
    }

    if (prevProps.requestToSwapPostResponse) {
      if (this.props.screenAccess.includes(SCREEN_NAME_SWAP_SHIFT)) {
        this.props.handleGetSwapRequests(padEmpIdWithZeros(userInfo?.empid, 10), startDate, endDate)
      }
    }

    if (this.props.postShiftPostResponse && prevProps.postShiftPostResponse !== this.props.postShiftPostResponse) {
      this.props.showNotificationSuccess(true, SUCCESS_ON_SAVE)
      if (this.props.screenAccess.includes(REQUEST_TO_COVER)) {
        this.props.setAvailableShiftStatusLoading('Y')
        this.props.handleGetAvailableShiftStatus(
          padEmpIdWithZeros(userInfo?.empid, 10),
          selectedDate,
          this.props.locationDetails,
        )
      }
    }

    if (postShiftPostErrResponse && prevProps.postShiftPostErrResponse !== this.props.postShiftPostErrResponse) {
      if (
        postShiftPostErrResponse.message &&
        postShiftPostErrResponse.code &&
        ERROR_CODES_SHOW_MESSAGE.includes(postShiftPostErrResponse.code)
      ) {
        this.props.showNotificationError(true, postShiftPostErrResponse.message)
      } else {
        this.props.showNotificationError(
          true,
          UNABLE_TO_SAVE + ' ' + formatErrorCode(postShiftPostErrResponse, ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
        )
      }
    }

    if (
      withdrawSwapRequestErrResponse &&
      prevProps.withdrawSwapRequestErrResponse !== this.props.withdrawSwapRequestErrResponse
    ) {
      if (
        withdrawSwapRequestErrResponse.message &&
        withdrawSwapRequestErrResponse.code &&
        ERROR_CODES_SHOW_MESSAGE.includes(withdrawSwapRequestErrResponse.code)
      ) {
        this.props.showNotificationError(true, withdrawSwapRequestErrResponse.message)
      } else {
        this.props.showNotificationError(
          true,
          UNABLE_TO_SAVE + ' ' + formatErrorCode(withdrawSwapRequestErrResponse, ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
        )
      }
    }

    if (this.props.loading === 'N' && this.props.data !== null) {
      scroller.scrollTo(getDay(currentSelectedDate).toString(), {
        duration: 400,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scrollableContainer',
        offset: 0,
      })
    }

    this.props.clearInitiatePostRequests()
    this.props.clearWithdrawPostRequests()
    this.props.clearAccepterRequests()
  }

  handleLink = (date) => {
    this.props.setBackToComponent(WEEKLY_SCHEDULE_PAGE_PATH)
    this.props.setDailyScheduleSelectedDate(date)
  }

  setSelectedDate = (date) => {
    this.props.setWeeklyScheduleSelectedDate(date)
  }

  handleDateChange = (date) => {
    let inCurrentWeek = this.isSelectedDateInWeekOfPrevSelectedDate(date)
    if (!inCurrentWeek) {
      this.getWeeklyScheduleData(date, this.props.userDetails)
    }
    this.setSelectedDate(date)
  }

  isSelectedDateInWeekOfPrevSelectedDate = (selectedDate) => {
    return !(
      isBefore(selectedDate, startOfWeek(this.props.selectedDate)) ||
      isAfter(selectedDate, endOfWeek(this.props.selectedDate))
    )
  }

  isSelectedDateInCurrentScheduleDataWeek = () => {
    if (this.props.data && this.props.data.schedules) {
      let firstDayOfWeek = parse(this.props.data.schedules[0].schedule_date, 'yyyy-MM-dd', new Date())
      let lastDayOfWeek = parse(this.props.data.schedules[6].schedule_date, 'yyyy-MM-dd', new Date())
      if (!(isBefore(this.props.selectedDate, firstDayOfWeek) || isAfter(this.props.selectedDate, lastDayOfWeek))) {
        return true
      }
    }
    return false
  }

  isSelectedDateBeforeValidRange = (selectedDate) => {
    const { locationDetails } = this.props
    return isDateBeforeNumberOfWeeksFromToday(selectedDate, DATE_RANGE_PAST_WEEKS, locationDetails.iso_time_zone_code)
  }

  isSelectedDateAfterValidRange = (selectedDate) => {
    const { locationDetails } = this.props
    let numberOfWeeks =
      locationDetails.location_type === LOCATION_API_STORE_LOCATION_TYPE
        ? DATE_RANGE_STORE_FUTURE_WEEKS
        : DATE_RANGE_DC_FUTURE_WEEKS
    return isDateAfterNumberOfWeeksFromToday(selectedDate, numberOfWeeks, locationDetails.iso_time_zone_code)
  }

  /**
   * When calling the weekly schedule api need to make sure a rtc team member can see the rtc button and a dc
   * team member can't.
   */
  getWeeklyScheduleData = async (selectedDate, userObject) => {
    const dateFormat = 'yyyy-MM-dd'
    let startDate = format(startOfWeek(selectedDate), dateFormat)
    let endDate = format(endOfWeek(selectedDate), dateFormat)

    if (this.isSelectedDateBeforeValidRange(selectedDate)) {
      this.setState({
        dateBeforeRange: true,
        dateAfterRange: false,
      })
    } else if (this.isSelectedDateAfterValidRange(selectedDate)) {
      this.setState({
        dateAfterRange: true,
        dateBeforeRange: false,
      })
    } else {
      this.setState({
        dateBeforeRange: false,
        dateAfterRange: false,
      })
      this.props.setLoading('Y')
      this.props.handleGetWeeklyScheduleData(
        padEmpIdWithZeros(userObject.empid, 10),
        selectedDate,
        this.props.locationDetails.location_id,
      )
      if (this.props.screenAccess.includes(REQUEST_TO_COVER)) {
        this.props.setAvailableShiftStatusLoading('Y')
        this.props.handleGetAvailableShiftStatus(
          padEmpIdWithZeros(userObject.empid, 10),
          selectedDate,
          this.props.locationDetails,
        )
      }
      if (this.props.screenAccess.includes(SCREEN_NAME_SWAP_SHIFT)) {
        this.props.handleGetSwapRequests(padEmpIdWithZeros(userObject.empid, 10), startDate, endDate)
      }
    }
  }

  mergeAvailableShiftStatusWithDisplaySegments(scheduleData, availableShiftData, swapRequestData, locationDetails) {
    const isoTimeZoneCode = locationDetails.iso_time_zone_code
    let tmAvailableShiftMap
    let swapRequestsRaisedMap
    let swapRequestsReceivedMap

    if (availableShiftData || swapRequestData) {
      if (availableShiftData?.team_member_available_shifts) {
        tmAvailableShiftMap = this.mapAvailableShiftsResponse(availableShiftData.team_member_available_shifts)
      }

      if (swapRequestData?.shift_swap_request_raised) {
        swapRequestsRaisedMap = this.mapShiftSwapRequestResponse(swapRequestData.shift_swap_request_raised, 'requester')
      }

      if (swapRequestData?.shift_swap_request_received) {
        swapRequestsReceivedMap = this.mapShiftSwapRequestResponse(
          swapRequestData.shift_swap_request_received,
          'accepter',
        )
      }
      let mappedData = { tmAvailableShiftMap, swapRequestsRaisedMap, swapRequestsReceivedMap }

      if (swapRequestData?.shift_swap_request_received) {
        let swapRequestsReceivedDateTimeWiseMap = this.makeDateTimeWiseMapSwapRequestsReceived(
          swapRequestData.shift_swap_request_received,
        )
        this.props.saveSwapRequestsDateTimeWiseMap(swapRequestsReceivedDateTimeWiseMap)
      }

      return this.addFeaturesToDisplaySegments(scheduleData, mappedData, swapRequestData, isoTimeZoneCode)
    } else {
      return scheduleData
    }
  }

  makeDateTimeWiseMapSwapRequestsReceived = (data) => {
    return data.reduce(function (r, a) {
      let reviewerOwnShiftStartDateTime = moment(a['accepter'].shift_start.slice(0, 19))?.format('YYYY-MM-DD HH:mm:ss')
      r[reviewerOwnShiftStartDateTime] = r[reviewerOwnShiftStartDateTime] || []
      if (a['status'].toLowerCase() === 'open') {
        r[reviewerOwnShiftStartDateTime].push(a['requester'])
      }
      return r
    }, Object.create(null))
  }

  /**
   * mapping the segment start to an object of rtc status information, request id and assigned to worker id
   * @param statusResponse
   * @returns {Map<datetime string, {...statusResponse}>}
   */
  mapAvailableShiftsResponse(statusResponse) {
    let tmAvailableShiftMap = new Map()
    statusResponse.forEach((teamMemberAvailableShift) => {
      let tmAvailableShiftStartDateTime = moment(teamMemberAvailableShift.segment_start.slice(0, 19))?.format(
        'YYYY-MM-DD HH:mm:ss',
      )
      tmAvailableShiftMap.set(tmAvailableShiftStartDateTime, { ...teamMemberAvailableShift })
    })
    return tmAvailableShiftMap
  }

  /**
   * mapping the segment start to an object of rtc status information, request id and assigned to worker id
   * @param shiftSwapResponse
   * @returns {Map<datetime string, {...shiftSwapResponse}>}
   */
  mapShiftSwapRequestResponse(shiftSwapResponse, role) {
    return shiftSwapResponse.reduce(function (r, a) {
      let reviewerOwnShiftStartDateTime = moment(a[role].shift_start.slice(0, 19))?.format('YYYY-MM-DD HH:mm:ss')
      r[reviewerOwnShiftStartDateTime] = r[reviewerOwnShiftStartDateTime] || []
      r[reviewerOwnShiftStartDateTime].push(a)
      return r
    }, Object.create(null))
  }

  getStatusForDisplaySegment(
    currentTeamMemberWorkerId,
    currentScheduleDateOrDisplaySegmentDate,
    tmAvailableShiftStartDate,
    tmAvailableShift,
    isCheckingIndividualDisplaySegments,
    isShiftInPast,
  ) {
    let isCoveringShift = false
    let isShiftBeingCovered = false
    let status = PAST

    if (!isShiftInPast) {
      // if team member has a related rtc/available shift on the given day or given display segment
      if (currentScheduleDateOrDisplaySegmentDate === tmAvailableShiftStartDate) {
        // if team members shift has been picked up by another team member
        if (
          tmAvailableShift.status === ACCEPTED &&
          currentTeamMemberWorkerId === tmAvailableShift.requester_worker_id
        ) {
          isShiftBeingCovered = !!isCheckingIndividualDisplaySegments
        }
        // if team member has picked up another team members shift
        if (
          tmAvailableShift.status === ACCEPTED &&
          currentTeamMemberWorkerId === tmAvailableShift.assigned_to_worker_id
        ) {
          // if checking individual display segment, that means the display segment exists and ACCEPTED rtc's for
          //  assigned to workers should be ignored because schedule has been populated.
          if (isCheckingIndividualDisplaySegments) {
            isCoveringShift = false
            status = ELIGIBLE_TO_POST
          } else {
            isCoveringShift = true
          }
        }
        // if status pending just set status to pending so we show retract shift
        if (tmAvailableShift.status === PENDING) {
          status = PENDING
        }
      } else if (this.props.availableShiftStatusData) {
        // At this point, the availableShift api returned response without error, no pending rtc matches this shift,
        // and this shift is not in past, so this shift is "eligible to post".
        status = ELIGIBLE_TO_POST
      }
    }

    return { isCoveringShift, isShiftBeingCovered, status }
  }

  getSwapStatusAsRequester(
    currentScheduleDateOrShiftDate,
    swapShiftAsRequesterStartDateTime,
    swapShiftRequest,
    isShiftInPast,
    isShiftAfter7Days,
  ) {
    let isShiftBeingSwappedAsRequester = false
    let swapStatusAsRequester = PAST

    if (!isShiftInPast && !isShiftAfter7Days) {
      // if this shift is related swap shift request
      if (currentScheduleDateOrShiftDate === swapShiftAsRequesterStartDateTime) {
        // if swap shift request has been accepted by another team member, and it is successfully saved in UKG,
        // then show message to current team member until schedule cache refresh
        if (swapShiftRequest.status.toLowerCase() === 'closed' && swapShiftRequest.is_shift_swapped) {
          isShiftBeingSwappedAsRequester = true
          swapStatusAsRequester = CLOSED_AND_SWAPPED
        }

        // if swap shift request declined by reviewer (or accepting another shift request)
        if (swapShiftRequest.status.toLowerCase() === 'closed' && !swapShiftRequest.is_shift_swapped) {
          swapStatusAsRequester = CLOSED_BUT_NOT_SWAPPED
        }

        // if swap shift request failed due to rule violation
        if (swapShiftRequest.status.toLowerCase() === 'failed' && swapShiftRequest.is_rule_violated) {
          swapStatusAsRequester = FAILED_DUE_TO_RULE_VIOLATION
        }

        // if swap shift request failed due to network issue
        if (swapShiftRequest.status.toLowerCase() === 'failed' && !swapShiftRequest.is_rule_violated) {
          swapStatusAsRequester = FAILED_DUE_TO_NETWORK_ISSUE
        }

        // if status is withdrawn, then we can give option to re-initiate fresh swap request
        if (swapShiftRequest.status.toLowerCase() === 'withdrawn') {
          swapStatusAsRequester = WITHDRAWN
        }
        // if status is open, then we can show the withdrawal option
        if (swapShiftRequest.status.toLowerCase() === 'open') {
          swapStatusAsRequester = SWAP_STATUS_OPEN
        }
      } else {
        // if there is no swap shift request related to this shift
        // and this shift is not in the past, tm can start initiate swap request
        swapStatusAsRequester = ELIGIBLE_TO_SWAP
      }
    }
    return { isShiftBeingSwappedAsRequester, swapStatusAsRequester }
  }

  getSwapStatusAsAccepter(
    currentScheduleDateOrShiftDate,
    swapShiftAsAccepterStartDateTime,
    swapShiftRequest,
    isShiftInPast,
    isShiftAfter7Days,
  ) {
    let swapStatusAsAccepter = PAST
    let isShiftBeingSwappedAsAccepter = false

    if (!isShiftInPast && !isShiftAfter7Days) {
      // if team member has a related swap shift on the given day or given display segment
      if (currentScheduleDateOrShiftDate === swapShiftAsAccepterStartDateTime) {
        // if status pending just set status to pending, so we show retract shift
        if (swapShiftRequest.status.toLowerCase() === 'open') {
          swapStatusAsAccepter = SWAP_STATUS_OPEN
        }

        // if swap shift request has been accepted by another team member, and it is successfully saved in UKG,
        // then show message to current team member until schedule cache refresh
        if (swapShiftRequest.status.toLowerCase() === 'closed' && swapShiftRequest.is_shift_swapped) {
          // isShiftBeingCovered = !!isCheckingIndividualDisplaySegments
          isShiftBeingSwappedAsAccepter = true
          swapStatusAsAccepter = CLOSED_AND_SWAPPED
        }
      }
    }

    return { swapStatusAsAccepter, isShiftBeingSwappedAsAccepter }
  }

  /**
   * Method will attach rtc info to display segments in the weekly response in order to have and display needed
   * tmAvailable shifts information. will set status and request_id to undefined if no tmAvailable shift for that segment.
   * will set status to 'PAST' if shift is within 2 hours (120 min) of current datetime in locations timezone.
   *
   * @param weeklyData: response from get weekly schedule
   * @param mapped:
   * @param swapRequestData: {"2020-07-24 11:00:00" => {status, request_id, assigned_to_worker_id ... etc}}"
   * @param isoTimeZoneCode: time zone of location: 'America/Chicago'
   * @returns weeklyData with TmAvailableShift information
   */
  addFeaturesToDisplaySegments(weeklyData, mapped, swapRequestData, isoTimeZoneCode) {
    const { tmAvailableShiftMap, swapRequestsRaisedMap, swapRequestsReceivedMap } = mapped
    const currentTeamMemberWorkerId = weeklyData.team_member_number
    let mergedWeeklyData = JSON.parse(JSON.stringify(weeklyData))

    // loop through each schedule in the week
    mergedWeeklyData.schedules.forEach((schedule) => {
      // if team member has a schedule on the given day
      let scheduleDate = schedule.schedule_date
      if (schedule.total_display_segments > 0) {
        // loop through each display segment
        schedule.display_segments.forEach((displaySegment) => {
          // set jobs list
          if (displaySegment.jobs && displaySegment.jobs.length > 0) {
            displaySegment.jobList = []
            displaySegment.jobs.forEach((job, elemIndex) => {
              if (elemIndex !== 0) {
                let lastIndex = job.job_path.lastIndexOf('/')
                displaySegment.jobList.push(job.job_path.substr(lastIndex + 1, job.job_path.length))
              }
            })
          }

          // get difference between now and segment start
          let isShiftInPast =
            differenceInMinutes(
              new Date(displaySegment.segment_start.split(' ')[0] + 'T' + displaySegment.segment_start.split(' ')[1]),
              getNowDateTimeInTimezone(isoTimeZoneCode),
            ) < 120

          //Is shift falls after today + 6 days period, then that shift is NOT considered for swap
          let isShiftAfter7Days = new Date(scheduleDate) > addDays(getDateOfTodayWithNoTimestamp(), 7)

          // see if we have a corresponding tm available shift for this display segment
          let tmAvailableShift = tmAvailableShiftMap ? tmAvailableShiftMap.get(displaySegment.segment_start) : null
          // we have a match and set var to represent shift start date
          let tmAvailableShiftStartDateTime = tmAvailableShift ? displaySegment.segment_start : undefined

          // see if we have a corresponding swap shift request raised for this display segment
          let swapRequestRaised = swapRequestsRaisedMap ? swapRequestsRaisedMap[displaySegment.segment_start] : null

          let swapShiftAsRequesterStartDateTime = swapRequestRaised ? displaySegment.segment_start : undefined

          if (swapRequestRaised && swapRequestRaised.length > 0) {
            displaySegment.swapStatusAsRequester = ELIGIBLE_TO_SWAP
            displaySegment.hasHistoryAsRequester = false
            for (let requestRaised of swapRequestRaised) {
              if (displaySegment.segment_start === swapShiftAsRequesterStartDateTime) {
                if (requestRaised.status.toLowerCase() === 'closed' && requestRaised.is_shift_swapped) {
                  displaySegment.hasHistoryAsRequester = this.checkRequestHistory(
                    requestRaised.request_id,
                    displaySegment.segment_start,
                    swapRequestData.shift_swap_request_raised,
                    'requester',
                  )
                }
                const { swapStatusAsRequester } = this.getSwapStatusAsRequester(
                  displaySegment.segment_start,
                  swapShiftAsRequesterStartDateTime,
                  requestRaised,
                  isShiftInPast,
                  isShiftAfter7Days,
                )
                displaySegment.swapStatusAsRequester = swapStatusAsRequester
              }
            }
          } else {
            const { isShiftBeingSwappedAsRequester, swapStatusAsRequester } = this.getSwapStatusAsRequester(
              displaySegment.segment_start,
              swapShiftAsRequesterStartDateTime,
              swapRequestRaised,
              isShiftInPast,
              isShiftAfter7Days,
            )

            displaySegment.isShiftBeingSwappedAsRequester = isShiftBeingSwappedAsRequester
            displaySegment.swapStatusAsRequester = swapStatusAsRequester
          }

          // see if we have a corresponding swap shift request received for this display segment
          let swapRequestReceived = swapRequestsReceivedMap
            ? swapRequestsReceivedMap[displaySegment.segment_start]
            : null

          let swapShiftAsAccepterStartDateTime = swapRequestReceived ? displaySegment.segment_start : undefined

          if (swapRequestReceived && swapRequestReceived.length > 0) {
            displaySegment.hasHistoryAsAccepter = false
            for (let requestReceived of swapRequestReceived) {
              if (displaySegment.segment_start === swapShiftAsAccepterStartDateTime) {
                if (requestReceived.status.toLowerCase() === 'closed' && requestReceived.is_shift_swapped) {
                  displaySegment.hasHistoryAsAccepter = this.checkRequestHistory(
                    requestReceived.request_id,
                    displaySegment.segment_start,
                    swapRequestData.shift_swap_request_received,
                    'accepter',
                  )
                }

                let { swapStatusAsAccepter } = this.getSwapStatusAsAccepter(
                  displaySegment.segment_start,
                  swapShiftAsAccepterStartDateTime,
                  requestReceived,
                  isShiftInPast,
                  isShiftAfter7Days,
                )
                displaySegment.swapStatusAsAccepter = swapStatusAsAccepter
              }
            }
          }

          displaySegment.swapshiftRequest = swapRequestRaised

          // get status vars for display segment
          const { isCoveringShift, isShiftBeingCovered, status } = this.getStatusForDisplaySegment(
            currentTeamMemberWorkerId,
            displaySegment.segment_start,
            tmAvailableShiftStartDateTime,
            tmAvailableShift,
            true,
            isShiftInPast,
          )

          // set statuses and flags
          displaySegment.isCoveringShift = isCoveringShift
          displaySegment.isShiftBeingCovered = isShiftBeingCovered
          displaySegment.status = status
          if (tmAvailableShift) {
            displaySegment.request_id = tmAvailableShift.request_id
          }
        })
      } else {
        // tm does not have a schedule on the given day
        // loop through any tm available shifts they have been involved in this week.
        if (tmAvailableShiftMap && tmAvailableShiftMap.length > 0) {
          tmAvailableShiftMap.forEach((tmAvailableShift, tmAvailableShiftStartDateTime) => {
            const tmAvailableShiftStartDate = moment(tmAvailableShiftStartDateTime)?.format('YYYY-MM-DD').toString()

            // get difference between now and rtc segment start
            let isShiftInPast =
              differenceInMinutes(new Date(tmAvailableShiftStartDateTime), getNowDateTimeInTimezone(isoTimeZoneCode)) <
              120

            // check current tm schedule against tmAvailableShifts they are involved in this week
            const { isCoveringShift, isShiftBeingCovered, status } = this.getStatusForDisplaySegment(
              currentTeamMemberWorkerId,
              schedule.schedule_date,
              tmAvailableShiftStartDate,
              tmAvailableShift,
              false,
              isShiftInPast,
            )

            // only schedules display segments that have a flag to show should be assigned a display segment.
            if (isCoveringShift || isShiftBeingCovered) {
              schedule.display_segments = [
                {
                  isCoveringShift,
                  isShiftBeingCovered,
                  status,
                  segment_start: tmAvailableShift.segment_start,
                  segment_end: tmAvailableShift.segment_end,
                },
              ]
            }
          })
        }
      }
    })

    return mergedWeeklyData
  }

  checkRequestHistory = (requestId, displaySegmentStart, swapRequestData, role) => {
    let roleInverse = role === 'requester' ? 'accepter' : 'requester'
    for (let request of swapRequestData) {
      if (
        request.request_id > requestId &&
        request.status.toLowerCase() === 'closed' &&
        request.is_shift_swapped &&
        displaySegmentStart === moment(request[roleInverse].shift_start.slice(0, 19))?.format('YYYY-MM-DD HH:mm:ss')
      ) {
        return true
      }
    }
  }

  renderScheduleList = () => {
    const { data, availableShiftStatusData, swapRequestData, locationDetails } = this.props
    const outputDateFormat = 'dddd, MMMM D'
    let list = []

    let mergedData = this.mergeAvailableShiftStatusWithDisplaySegments(
      data,
      availableShiftStatusData,
      swapRequestData,
      locationDetails,
    )

    for (let i = 0; i < 7; i++) {
      let scheduleDay = mergedData.schedules[i]
      let scheduleDate = moment(scheduleDay.schedule_date, moment.HTML5_FMT.DATE)

      list.push(
        <Element
          name={i.toString()}
          key={i}
          id={i.toString()}
          aria-label={`schedule for ${scheduleDay.schedule_date} with ${scheduleDay.total_display_segments} shifts`}
        >
          <ListItem data-cy={`weeklySchedListItem${scheduleDate}`}>
            <Grid container>
              <Grid item xs={9} md={9}>
                <Link
                  to="/team-member/dailySchedulePage/schedule"
                  style={{ textDecoration: 'none' }}
                  onClick={() => this.handleLink(scheduleDate.toDate())}
                  sx={styles.dateContainer}
                  aria-hidden={true}
                >
                  <Typography variant="body2" sx={styles.dateHeading} data-cy="nextSchedDisplaySegDateOnWeekly">
                    {scheduleDate?.format(outputDateFormat)}
                  </Typography>
                </Link>
              </Grid>
              {this.props.screenAccess.includes(SCREEN_NAME_SWAP_SHIFT) && (
                <Grid item xs={3} md={3}>
                  <Grid container direction="row-reverse">
                    {this.renderShiftSwapMsgIcons(scheduleDay, i)}
                  </Grid>
                </Grid>
              )}
              <DisplaySegments
                schedule={scheduleDay}
                handleDailyClick={() => this.handleLink(scheduleDate.toDate())}
                id={i}
                showPostShiftButton={this.props.screenAccess.includes(REQUEST_TO_COVER)}
                showSearchSwapButton={this.props.screenAccess.includes(SCREEN_NAME_SWAP_SHIFT)}
                locationId={locationDetails?.location_id}
              />
            </Grid>
          </ListItem>
          <Divider aria-hidden={true} />
        </Element>,
      )
    }
    return list
  }

  renderShiftSwapMsgIcons = (schedule, id) => {
    if (get(schedule, 'display_segments')) {
      return schedule.display_segments.map((element, index) => {
        return this.renderShiftSwapMsgIcon(schedule, element, id + '_' + String(index))
      })
    }
  }

  renderShiftSwapMsgIcon = (schedule, displaySegment, counter) => {
    if (displaySegment) {
      return (
        (displaySegment.swapStatusAsRequester === CLOSED_BUT_NOT_SWAPPED ||
          displaySegment.swapStatusAsRequester === FAILED_DUE_TO_RULE_VIOLATION ||
          displaySegment.swapStatusAsRequester === FAILED_DUE_TO_NETWORK_ISSUE) && (
          <>
            <Grid item xs={4} md={4} data-cy="shiftSwapMessageButton" sx={styles.shiftSwapMsgIconStyle}>
              <SwapShiftsMessgeAction
                schedule={schedule}
                styles={styles}
                counter={counter}
                displaySegment={displaySegment}
              />
            </Grid>
          </>
        )
      )
    }
  }

  render() {
    const {
      data,
      selectedDate,
      getScheduleError,
      screenAccessLoading,
      locationDetailsLoading,
      locationDetailsGetError,
    } = this.props

    let ViewableContent = null
    let ViewableCalendar = null

    if (!screenAccessLoading && !locationDetailsLoading) {
      if (!this.props.screenAccess.includes(SCHEDULE_TEAM_MEMBER)) {
        ViewableContent = <NotAuthorized />
      } else if (locationDetailsGetError) {
        ViewableContent = (
          <Box sx={styles.mainContainerPosition}>
            <Box component={'p'} sx={styles.errorMessage}>
              {LOAD_ERROR_MESSAGE}
              <Box component={'span'} sx={styles.errorCodeMessage}>
                {ERROR_CODE_CANNOT_CONNECT_TO_LOCATION}
              </Box>
            </Box>
          </Box>
        )
      } else {
        ViewableCalendar = (
          <WeeklyCalendar
            id="WeeklySchedulePage"
            selectedDate={selectedDate}
            onChange={this.handleDateChange.bind(this)}
          />
        )
        if (this.props.loading === 'Y') {
          ViewableContent = (
            <Box sx={styles.mainContainerPosition}>
              <Box sx={styles.loadingIconContainer}>
                <CircularProgress size={48} />
              </Box>
            </Box>
          )
        } else if (this.state.dateBeforeRange) {
          ViewableContent = (
            <Box sx={styles.mainContainerPosition}>
              <Box component={'p'} sx={styles.infoMessage}>
                {DATE_BEFORE_RANGE_MESSAGE}
              </Box>
            </Box>
          )
        } else if (this.state.dateAfterRange) {
          let maxDaysErrorMessage = DATE_AFTER_RANGE_DC_MESSAGE
          if (
            this.props.locationDetails !== undefined &&
            this.props.locationDetails.location_type === LOCATION_API_STORE_LOCATION_TYPE
          ) {
            maxDaysErrorMessage = DATE_AFTER_RANGE_STORE_MESSAGE
          }
          ViewableContent = (
            <Box sx={styles.mainContainerPosition}>
              <Box component={'p'} sx={styles.infoMessage}>
                {maxDaysErrorMessage}
              </Box>
            </Box>
          )
        } else if (getScheduleError) {
          if (
            getScheduleError.message &&
            getScheduleError.code &&
            ERROR_CODES_SHOW_MESSAGE.includes(getScheduleError.code)
          ) {
            ViewableContent = (
              <Box sx={styles.mainContainerPosition}>
                <Box component={'p'} sx={styles.errorMessage}>
                  {getScheduleError.message}
                </Box>
              </Box>
            )
          } else {
            ViewableContent = (
              <Box sx={styles.mainContainerPosition}>
                <Box component={'p'} sx={styles.errorMessage}>
                  {LOAD_ERROR_MESSAGE}
                  <Box component={'span'} sx={styles.errorCodeMessage}>
                    {formatErrorCode(getScheduleError, ERROR_CODE_CANNOT_CONNECT_TO_SERVER)}
                  </Box>
                </Box>
              </Box>
            )
          }
        } else if (data && data.team_member_number) {
          ViewableContent = (
            <React.Fragment>
              <Box sx={styles.highlightsContainerPosition}>
                <Paper elevation={1} square sx={styles.highlightsContainer}>
                  <Typography variant="body2" sx={styles.highlights} data-cy="weeklyTotalHrs">
                    Weekly Scheduled Hours: {data.total_hours}
                  </Typography>
                </Paper>
              </Box>
              <Box sx={styles.mainContainerPosition} id="scrollableContainer" data-cy="scrollableContainer_weekly">
                <Paper sx={styles.mainScrollContainer} elevation={1} square>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <List sx={styles.list} aria-label={'your weekly schedule'}>
                        {this.renderScheduleList()}
                      </List>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </React.Fragment>
          )
        } else if (data && data.message) {
          ViewableContent = (
            <Box sx={styles.mainContainerPosition}>
              <Box component={'p'} sx={styles.errorMessage}>
                {data.message}
              </Box>
            </Box>
          )
        } else if (data == null) {
          ViewableContent = null
        } else {
          ViewableContent = (
            <Box sx={styles.mainContainerPosition}>
              <Box component={'p'} sx={styles.errorMessage}>
                Unable to load your schedule at this time. Please try again later and if the issue persists, contact the
                CSC.
              </Box>
            </Box>
          )
        }
      }
    }
    return (
      <React.Fragment>
        <HeaderTitle title="My Schedule" />
        {ViewableCalendar}
        {ViewableContent}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleGetWeeklyScheduleData,
      handleGetAvailableShiftStatus,
      setLoading,
      setAvailableShiftStatusLoading,
      setWeeklyScheduleSelectedDate,
      setDailyScheduleSelectedDate,
      setBackToComponent,
      showNotificationSuccess,
      showNotificationError,
      handleGetSwapRequests,
      saveSwapRequestsDateTimeWiseMap,
      clearInitiatePostRequests,
      clearWithdrawPostRequests,
      clearAccepterRequests,
    },
    dispatch,
  )

const mapStateToProps = (state) => {
  return {
    availableShiftStatusData: state.weeklySchedule.availableShiftStatusData,
    availableShiftStatusLoading: state.weeklySchedule.loading,
    getAvailableShiftStatusError: state.weeklySchedule.getScheduleError,
    data: state.weeklySchedule.data,
    loading: state.weeklySchedule.loading,
    getScheduleError: state.weeklySchedule.getScheduleError,
    selectedDate: state.weeklySchedule.selectedDate,
    postShiftPostResponse: state.weeklySchedule.postShiftPostResponse,
    postShiftPostErrResponse: state.weeklySchedule.postShiftPostErrResponse,
    dailyScheduleSelectedDate: state.dailySchedule.selectedDate,
    backToComponent: state.header.backToComponent,
    screenAccess: state.layout.screenAccess,
    screenAccessLoading: state.layout.screenAccessLoading,
    locationDetails: state.layout.locationDetails,
    locationDetailsLoading: state.layout.locationLoading,
    locationDetailsGetError: state.layout.locationDetailsGetError,
    userDetails: state.layout.userDetails,
    buttonFlag: state.weeklySchedule.buttonFlag,
    swapRequestData: state.weeklySchedule.swapRequestData,
    withdrawSwapRequestResponse: state.weeklySchedule.withdrawSwapRequestResponse,
    withdrawSwapRequestErrResponse: state.weeklySchedule.withdrawSwapRequestErrResponse,
    requestToSwapPostResponse: state.swapShift.requestToSwapPostResponse,
    requestToSwapErrResponse: state.swapShift.requestToSwapErrResponse,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth()(WeeklySchedulePage))
