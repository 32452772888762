import { CircularProgress, useTheme } from '@mui/material'

const InnerLoader = (props) => {
  const theme = useTheme()

  const { size } = props
  return (
    <div style={theme.loadingIconContainer}>
      <CircularProgress size={size} />
    </div>
  )
}

export default InnerLoader
