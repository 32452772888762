import { Paper, useTheme } from '@mui/material'
import { REQUEST_SCREEN_TITLES } from '../../../constants/RequestConstants'
import HeaderTitle from '../../Header/HeaderTitle'
import MainLayout, { MainContentDiv } from '../../common/layout/Layouts'
import RequestContent from './RequestContent'
import RequestFooter from './RequestFooter'
import RequestHeader from './RequestHeader'

export default function TimeOffRequest() {
  const theme = useTheme()

  return (
    <>
      <HeaderTitle title={REQUEST_SCREEN_TITLES.REQUEST_TIME_OFF} />
      <MainContentDiv>
        <Paper square elevation={1} sx={{ borderBottom: `1px solid ${theme.palette.tertiary.main}` }}>
          <RequestHeader />
        </Paper>
      </MainContentDiv>
      <MainLayout>
        <RequestContent />
      </MainLayout>
      <MainContentDiv container sx={{ borderTop: `1px solid ${theme.palette.tertiary.main}` }}>
        <Paper
          square
          elevation={1}
          sx={{ display: 'flex', width: '100%', height: '100%', padding: '10px', justifyContent: 'right' }}
        >
          <RequestFooter />
        </Paper>
      </MainContentDiv>
    </>
  )
}
