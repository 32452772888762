import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import RequestCancelModal from '../RequestCancelModal'
import SubmitCallOffModal from './SubmitCallOffModal'

const RequestCallOffSubmitCancel = (props) => {
  const {
    showCancelDialog,
    disableSubmit,
    setNavigateToMyRequests,
    handleSubmitRequest,
    selectedDate,
    callInCallOffDetail,
  } = props

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
  const [startDateTime, setStartDateTime] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (!disableSubmit) {
      let requestDate = new Date(selectedDate)
      let startDate = new Date(
        requestDate.getFullYear(),
        requestDate.getMonth(),
        requestDate.getDate(),
        callInCallOffDetail.startTime.getHours(),
        callInCallOffDetail.startTime.getMinutes(),
      )

      setStartDateTime(startDate)
    }
  }, [disableSubmit, callInCallOffDetail, selectedDate])

  const handleCancel = () => {
    if (showCancelDialog) {
      setCancelDialogOpen(true)
    } else {
      setNavigateToMyRequests(true)
    }
  }

  const handleYesForSubmitAction = () => {
    setSubmitDialogOpen(false)
    handleSubmitRequest()
  }

  const handleNoForSubmitAction = () => {
    setSubmitDialogOpen(false)
  }

  const handleYesForCancelAction = () => {
    setCancelDialogOpen(false)
    navigate(-1)
  }

  const handleNoForCancelAction = () => {
    setCancelDialogOpen(false)
  }

  return (
    <React.Fragment>
      <Button data-cy="requestTimeOffCancel" sx={{ margin: '10px' }} onClick={handleCancel} color="primary">
        Cancel
      </Button>
      <Button
        data-cy="requestTimeOffSubmit"
        sx={{ margin: '10px' }}
        variant="contained"
        disabled={disableSubmit}
        onClick={() => {
          setSubmitDialogOpen(true)
        }}
        color="primary"
      >
        Submit
      </Button>

      {cancelDialogOpen && (
        <RequestCancelModal
          cancelDialogOpen={cancelDialogOpen}
          handleYesForCancelAction={handleYesForCancelAction}
          handleNoForCancelAction={handleNoForCancelAction}
        />
      )}
      {submitDialogOpen && (
        <SubmitCallOffModal
          submitDialogOpen={submitDialogOpen}
          handleYesForSubmitAction={handleYesForSubmitAction}
          handleNoForSubmitAction={handleNoForSubmitAction}
          type={callInCallOffDetail.type}
          startDateTime={startDateTime}
        />
      )}
    </React.Fragment>
  )
}

export default RequestCallOffSubmitCancel
