interface TimecardIconProps {
  color?: string
}

const TimecardIcon: React.FC<TimecardIconProps> = ({ color = '#666' }) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>time-sheet-icon-source</title>
      <desc>Created with Sketch.</desc>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(0.500000, 3.000000)" stroke={color}>
          <line
            x1="10.75"
            y1="-6.75"
            x2="10.75"
            y2="8.75"
            id="Line"
            strokeWidth="6"
            strokeLinecap="round"
            transform="translate(10.750000, 1.000000) rotate(90.000000) translate(-10.750000, -1.000000) "
          ></line>
          <line
            x1="10.75"
            y1="-8.25"
            x2="10.75"
            y2="12.25"
            id="Line"
            strokeWidth="4"
            transform="translate(10.750000, 2.000000) rotate(90.000000) translate(-10.750000, -2.000000) "
          ></line>
          <line x1="1" y1="1" x2="1" y2="17" id="Line" strokeWidth="2" strokeLinecap="round"></line>
          <line x1="20.5" y1="1" x2="20.5" y2="8" id="Line" strokeWidth="2" strokeLinecap="round"></line>
          <line
            x1="5.5"
            y1="12.5"
            x2="5.5"
            y2="21.5"
            id="Line"
            strokeWidth="2"
            strokeLinecap="round"
            transform="translate(5.500000, 17.000000) rotate(90.000000) translate(-5.500000, -17.000000) "
          ></line>
          <line
            x1="5.5"
            y1="6"
            x2="6"
            y2="8.5"
            id="Line"
            strokeLinecap="round"
            transform="translate(5.500000, 7.500000) rotate(101.309932) translate(-5.500000, -7.500000) "
          ></line>
          <line
            x1="14.0717854"
            y1="11.9759237"
            x2="12.3990856"
            y2="3.61242466"
            id="Line"
            strokeLinecap="round"
            transform="translate(13.235435, 7.794174) rotate(101.309932) translate(-13.235435, -7.794174) "
          ></line>
          <line
            x1="5.5"
            y1="10"
            x2="6"
            y2="12.5"
            id="Line"
            strokeLinecap="round"
            transform="translate(5.500000, 11.500000) rotate(101.309932) translate(-5.500000, -11.500000) "
          ></line>
          <line
            x1="10.782466"
            y1="13.2793269"
            x2="10.188405"
            y2="10.3090215"
            id="Line"
            strokeLinecap="round"
            transform="translate(10.485435, 11.794174) rotate(101.309932) translate(-10.485435, -11.794174) "
          ></line>
          <circle id="Oval" strokeWidth="1.3" cx="18" cy="15.5" r="4.85"></circle>
          <line x1="18.4" y1="16" x2="18.4" y2="13" id="Line-7" strokeLinecap="round"></line>
          <line x1="18.5" y1="16" x2="16.5" y2="18" id="Line-7" strokeLinecap="round"></line>
        </g>
      </g>
    </svg>
  )
}
export default TimecardIcon
