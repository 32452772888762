import { MenuItem } from '@mui/material'
import PostShiftIcon from '../Icons/PostShiftIcon'
import RetractTMAvailableShiftIcon from '../Icons/RetractTMAvailableShiftIcon'

const POST_TEXT = 'Post Shift'
const RETRACT_TEXT = 'Retract Shift'

const styles = {
  menuIcon: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  menuText: {
    marginLeft: '5px',
  },
}

const PostShiftAction = (props) => {
  const { displaySegment, handlePostShiftActionClick, menuKey } = props
  const renderItemContent = () => {
    // return <Button>Just to check 1 {displaySegment.status}</Button>
    if (displaySegment.status === 'PENDING') {
      return (
        <>
          <div style={styles.menuIcon}>
            <RetractTMAvailableShiftIcon />
          </div>
          <div style={styles.menuText}>{RETRACT_TEXT}</div>
        </>
      )
    } else {
      return (
        <>
          <div style={styles.menuIcon}>
            <PostShiftIcon />
          </div>
          <div style={styles.menuText}>{POST_TEXT}</div>
        </>
      )
    }
  }

  return (
    <MenuItem key={menuKey} value={'post-shift-action'} onClick={handlePostShiftActionClick}>
      {renderItemContent()}
    </MenuItem>
  )
}

export default PostShiftAction
