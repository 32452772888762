import { addWeeks, startOfWeek } from 'date-fns'
import { isScheduleGeneratedFor2WeeksFromNow } from '../../utils/AvailabilityUtils'
import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'
import {
  AVAILABILITY_FOR_SINGLE_WEEK,
  CLEAR_PREVIOUS_STATE,
  GET_USER_AVAILABILITY_ERROR,
  GET_USER_AVAILABILITY_SUCCESS,
  POST_AVAILABILITY_REQUESTS_DATA_ERROR,
  POST_AVAILABILITY_REQUESTS_DATA_SUCCESS,
  SET_AVAILABILITY_PATTERN_ID,
  SET_AVAILABILITY_REQUEST_END_DATE,
  SET_AVAILABILITY_REQUEST_START_DATE,
  SET_AVAILABILITY_REQUEST_TYPE,
  SET_AVAILABILITY_WEEKLY_LOADING,
  SET_AVAILABILITY_WEEKLY_SELECTED_DATE,
  UPDATE_AVAILABILITY_REQUESTS_DATA_ERROR,
  UPDATE_AVAILABILITY_REQUESTS_DATA_SUCCESS,
} from './actionTypes'

const initialState = {
  userAvailability: {},
  weeklySelectedDate: getDateOfTodayWithNoTimestamp(),
  availabilityReqStartDate: isScheduleGeneratedFor2WeeksFromNow
    ? addWeeks(startOfWeek(getDateOfTodayWithNoTimestamp()), 3)
    : addWeeks(startOfWeek(getDateOfTodayWithNoTimestamp()), 2),
  availabilityReqEndDate: null,
  availabilityReqType: null,
  availabilityPatternId: 1,
  userAvailabilityError: null,
  userAvailabilityLoading: 'N',
  userAvailabilityPostResponse: null,
  userAvailabilityPostErrResponse: null,
  userAvailabilityUpdateResponse: null,
  userAvailabilityUpdateErrResponse: null,
  singleWeekAvailability: false,
}

export default function avaiilability(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_AVAILABILITY_SUCCESS: {
      const { data } = action
      return {
        ...state,
        userAvailability: data.data,
        userAvailabilityLoading: 'N',
        userAvailabilityError: null,
      }
    }
    case GET_USER_AVAILABILITY_ERROR: {
      const { data } = action
      return {
        ...state,
        userAvailability: {},
        userAvailabilityError: data?.response ? data.response.data : data,
        userAvailabilityLoading: 'N',
      }
    }
    case POST_AVAILABILITY_REQUESTS_DATA_SUCCESS: {
      return {
        ...state,
        userAvailabilityPostResponse: action.data,
      }
    }
    case POST_AVAILABILITY_REQUESTS_DATA_ERROR: {
      let error
      if (action.data.response) {
        error = action.data.response.data
      } else {
        error = action.data.message
      }
      return {
        ...state,
        userAvailabilityPostErrResponse: error,
      }
    }
    case UPDATE_AVAILABILITY_REQUESTS_DATA_SUCCESS: {
      return {
        ...state,
        userAvailabilityUpdateResponse: action.data,
      }
    }
    case UPDATE_AVAILABILITY_REQUESTS_DATA_ERROR: {
      let error
      if (action.data.response) {
        error = action.data.response.data
      } else {
        error = action.data.message
      }
      return {
        ...state,
        userAvailabilityUpdateErrResponse: error,
      }
    }
    case SET_AVAILABILITY_WEEKLY_LOADING: {
      const { payload } = action
      return {
        ...state,
        userAvailabilityLoading: payload,
      }
    }
    case SET_AVAILABILITY_WEEKLY_SELECTED_DATE: {
      const { date } = action
      return {
        ...state,
        weeklySelectedDate: date,
      }
    }
    case SET_AVAILABILITY_REQUEST_START_DATE: {
      const { date } = action
      return {
        ...state,
        availabilityReqStartDate: date,
      }
    }
    case SET_AVAILABILITY_REQUEST_END_DATE: {
      const { date } = action
      return {
        ...state,
        availabilityReqEndDate: date,
      }
    }
    case SET_AVAILABILITY_REQUEST_TYPE: {
      const { reqType } = action
      return {
        ...state,
        availabilityReqType: reqType,
      }
    }
    case SET_AVAILABILITY_PATTERN_ID: {
      const { patternId } = action
      return {
        ...state,
        availabilityPatternId: patternId,
      }
    }
    case AVAILABILITY_FOR_SINGLE_WEEK: {
      const { boo } = action
      return {
        ...state,
        singleWeekAvailability: boo,
      }
    }
    case CLEAR_PREVIOUS_STATE: {
      return {
        ...state,
        availabilityReqEndDate: null,
        availabilityReqType: null,
        availabilityPatternId: 1,
        userAvailabilityError: null,
        userAvailabilityLoading: 'N',
        userAvailabilityPostResponse: null,
        userAvailabilityPostErrResponse: null,
        userAvailabilityUpdateResponse: null,
        userAvailabilityUpdateErrResponse: null,
        availabilityReqStartDate: isScheduleGeneratedFor2WeeksFromNow
          ? addWeeks(startOfWeek(getDateOfTodayWithNoTimestamp()), 3)
          : addWeeks(startOfWeek(getDateOfTodayWithNoTimestamp()), 2),
        singleWeekAvailability: false,
      }
    }
    default:
      return state
  }
}
