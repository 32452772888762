import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useState } from 'react'

const SAVE_DIALOG_TITLE = 'Are you sure you want to submit ?'

const SAVE_DIALOG_TEXT =
  'I acknowledge that the information in the punch correction accurately reflects the hours I worked on the date submitted.'

const REMINDER_MESSAGE = (
  <span>
    {
      'Failure to use the time clock or Timestamp, or falsification of punch in, punch out, or meal period times on this punch correction could result in disciplinary action up to and including termination of my employment.'
    }
  </span>
)

const LEADER_MESSAGE = <span>{'Your leader will be notified with the details of your punch correction.'}</span>

const styles = () => ({
  subTitle: {
    fontWeight: 600,
  },
  voluntarySelection: {
    marginTop: '20px',
  },
})

const PunchCorrectionSubmitModal = ({ submitDialogOpen, handleYesForSubmitAction, handleNoForSubmitAction }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleYes = () => {
    if (buttonDisabled) return
    setButtonDisabled(true)
    handleYesForSubmitAction()
    setTimeout(() => {
      setButtonDisabled(false)
    }, 1000)
  }

  const handleNo = () => {
    handleNoForSubmitAction()
  }

  return (
    <Dialog
      role="dialog"
      data-cy="requestPunchCorrectionDialog"
      open={submitDialogOpen}
      aria-labelledby="pc-submit-dialog-title"
      aria-describedby="pc-submit-dialog-desc_1 pc-submit-dialog-desc_2 pc-submit-dialog-desc_3 "
      aria-modal={true}
      keepMounted
    >
      <DialogTitle id="pc-submit-dialog-title">{SAVE_DIALOG_TITLE}</DialogTitle>
      <section tabIndex={0}>
        <DialogContent>
          <DialogContentText id="pc-submit-dialog-desc_1" sx={styles.voluntarySelection}>
            {SAVE_DIALOG_TEXT}
          </DialogContentText>
          <DialogContentText id="pc-submit-dialog-desc_2" sx={styles.voluntarySelection}>
            {REMINDER_MESSAGE}
          </DialogContentText>
          <DialogContentText id="pc-submit-dialog-desc_3" sx={styles.voluntarySelection}>
            {LEADER_MESSAGE}
          </DialogContentText>
        </DialogContent>
      </section>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          NO
        </Button>
        <Button onClick={handleYes} color="primary" variant="contained" aria-label="Yes">
          YES
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PunchCorrectionSubmitModal
