import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

/**
 * In new praxis, Authorization is now automatically attached to any request going to *.target.com or *.tgt
 * DOCUMENTATION: https://praxis.prod.target.com/guides/authentication
 */
const getNextSchedule = async (tmNumber, locationId) => {
  let apiUrl =
    `${apiConfig.schedule.url}` +
    '/next_schedules' +
    '?team_member_number=' +
    tmNumber +
    '&location_id=' +
    locationId +
    '&key=' +
    `${apiConfig.schedule.key}`

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  return data
}

export function useNextSchedule() {
  const { session, isAuthenticated, login } = useAuth()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  return useQuery(
    ['nextSchedule'],
    () => getNextSchedule(padEmpIdWithZeros(session?.userInfo?.empid, 10), session.userInfo.locationid),
    {
      staleTime: 1000 * 30, // 30 seconds of stale time
      enabled: isAuthenticated(),
    },
  )
}
