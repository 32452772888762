const ShiftSwapIcon = (props) => {
  return (
    <svg width="15px" height="18px" viewBox="0 0 15 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Shift_Swap_Icon</title>
      <g id="swap_vertical" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Swap-icon"
          transform="translate(7.500000, 9.000000) rotate(-90.000000) translate(-7.500000, -9.000000) translate(-1.500000, 1.500000)"
          fill="#A4A4A4"
        >
          <polygon
            id="Path"
            points="3.3495687 5 10.0622773 5 10.0622773 0.958004055 17.7888714 6.96979827 10.0622773 6.96979827 3.3495687 6.96979827"
          ></polygon>
          <polygon
            id="Path"
            transform="translate(7.569220, 11.165454) rotate(-180.000000) translate(-7.569220, -11.165454) "
            points="0.349568701 12.2935364 7.06227729 12.2935364 7.06227729 8.27155071 14.7888714 14.0593571 7.06227729 14.0593571 0.349568701 14.0593571"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}
export default ShiftSwapIcon
