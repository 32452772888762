import {
  GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_ERROR,
  GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_SUCCESS,
  LEADER_AUTOMATION_LOCATION_ERROR,
  LEADER_AUTOMATION_LOCATION_SUCCESS,
  LEADER_AUTOMATION_SET_LOCATION_LOADING,
  LEADER_AUTOMATION_SET_NAVIGATE_TO_NEXT_LOCATION,
  LEADER_AUTOMATION_SET_NEXT_LOCATION,
  SET_LEADER_AUTOMATION_EXIT_DIALOG_OPEN,
  SET_LEADER_AUTOMATION_FOOTER_CHANGE_DIALOG_OPEN,
  SET_LEADER_VIEW_AUTOMATION_PTO_REQUESTS_SELECTED_END_DATE,
  SET_LEADER_VIEW_AUTOMATION_SCREEN_NAME,
  SET_LEADER_VIEW_AUTOMATION_SELECTED_DATE,
  SET_LEADER_VIEW_AUTOMATION_SELECTED_LOCATION_ID,
  SET_LEADER_VIEW_AUTOMATION_UP_DOWN,
  SET_LEADER_VIEW_DESIRED_LOCATION_ID,
  SET_LEADER_VIEW_DESIRED_SCREEN_NAME,
  SET_LEADER_VIEW_DESIRED_SELECTED_DATE,
  SET_LEADER_VIEW_DIALOG_CHANGED_PAGE,
  SET_LEADER_VIEW_DISPLAY_LOADING_ICON,
  SET_LEADER_VIEW_POP_UP_TEXT_DATE_CHANGE,
  SET_LEADER_VIEW_POP_UP_TEXT_EXIT,
  SET_LEADER_VIEW_POP_UP_TEXT_LOCATION_CHANGE,
  SET_LEADER_VIEW_POP_UP_TEXT_SCREEN_CHANGE,
  SET_LEADER_VIEW_REFRESH_TABLE,
  SET_LEADER_VIEW_UNSAVED_CHANGES,
} from './actionType'

export const initialState = {
  displayLoadingIcon: false,
  locations: [],
  selectedLocationId: '',
  selectedDate: null,
  selectedEndDate: null,
  screenName: '',
  upDownState: 'up',
  getError: null,
  exitLeaderDialogOpen: false,
  footerChangeDialog: false,
  nextLeaderLocation: null,
  navigateToNextLeaderLocation: false,
  locationLoading: null,
  locationDetails: {
    location_number: null, // example 501,
    location_type: null, // example 'OFFICE',
    state_code: null, // example 'MN',
    time_zone_code: null, // example 'CST',
    is_daylight_savings_time_recognized: null, // example true,
    iso_time_zone_code: null, // example 'Pacific/Norfolk',
  },
  locationDetailsGetError: null,
  desiredScreenName: null,
  dialogChangedPage: false,
  thereAreUnsavedChanges: null,
  desiredSelectedDate: null,
  desiredLocationID: null,
  popUpTextToChangeLocation: false,
  popUpTextToExit: false,
  popUpTextToChangeRadioView: false,
  popUpTextToChangeDate: false,
  /**
   * A process flag to keep track of when to unmount and remount the mui-datatables
   *   Possible values: null, 'prework', 'refresh', 'postwork'
   */
  refreshTable: null,
}

export default function leaderView(state = initialState, action = {}) {
  switch (action.type) {
    case SET_LEADER_VIEW_AUTOMATION_SCREEN_NAME: {
      const { payload } = action
      return {
        ...state,
        screenName: payload,
      }
    }
    case SET_LEADER_VIEW_AUTOMATION_SELECTED_LOCATION_ID: {
      const { payload } = action
      return {
        ...state,
        selectedLocationId: payload,
      }
    }
    case SET_LEADER_VIEW_AUTOMATION_SELECTED_DATE: {
      const { payload } = action
      return {
        ...state,
        selectedDate: payload,
      }
    }
    case SET_LEADER_VIEW_AUTOMATION_PTO_REQUESTS_SELECTED_END_DATE: {
      const { payload } = action
      return {
        ...state,
        selectedEndDate: payload,
      }
    }
    case SET_LEADER_VIEW_AUTOMATION_UP_DOWN: {
      const { payload } = action
      return {
        ...state,
        upDownState: payload,
      }
    }
    case GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        getError: record.response ? record.response.data : record,
      }
    }
    case GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_SUCCESS: {
      const { record } = action
      return {
        ...state,
        locations: record.data.management_scopes.map((a) => a.location_number),
        getError: null,
      }
    }
    case SET_LEADER_AUTOMATION_EXIT_DIALOG_OPEN: {
      const { payload } = action
      return {
        ...state,
        exitLeaderDialogOpen: payload,
      }
    }
    case SET_LEADER_AUTOMATION_FOOTER_CHANGE_DIALOG_OPEN: {
      const { payload } = action
      return {
        ...state,
        footerChangeDialog: payload,
      }
    }
    case LEADER_AUTOMATION_SET_NAVIGATE_TO_NEXT_LOCATION: {
      const { payload } = action
      return {
        ...state,
        navigateToNextLeaderLocation: payload,
      }
    }
    case LEADER_AUTOMATION_SET_NEXT_LOCATION: {
      const { payload } = action
      return {
        ...state,
        nextLeaderLocation: payload,
      }
    }
    case LEADER_AUTOMATION_SET_LOCATION_LOADING: {
      const { payload } = action
      return {
        ...state,
        locationLoading: payload,
      }
    }
    case LEADER_AUTOMATION_LOCATION_ERROR: {
      const { record } = action
      return {
        ...state,
        locationDetailsGetError: record.response ? record.response.data : record,
        locationLoading: false,
      }
    }
    case LEADER_AUTOMATION_LOCATION_SUCCESS: {
      const { record } = action
      return {
        ...state,
        locationDetails: record.data,
        locationDetailsGetError: null,
        locationLoading: false,
      }
    }
    case SET_LEADER_VIEW_DESIRED_SCREEN_NAME: {
      const { payload } = action
      return {
        ...state,
        desiredScreenName: payload,
      }
    }
    case SET_LEADER_VIEW_DIALOG_CHANGED_PAGE: {
      const { payload } = action
      return {
        ...state,
        dialogChangedPage: payload,
      }
    }
    case SET_LEADER_VIEW_UNSAVED_CHANGES: {
      const { payload } = action
      return {
        ...state,
        thereAreUnsavedChanges: payload,
      }
    }
    case SET_LEADER_VIEW_DESIRED_SELECTED_DATE: {
      const { payload } = action
      return {
        ...state,
        desiredSelectedDate: payload,
      }
    }
    case SET_LEADER_VIEW_DESIRED_LOCATION_ID: {
      const { payload } = action
      return {
        ...state,
        desiredLocationID: payload,
      }
    }
    case SET_LEADER_VIEW_POP_UP_TEXT_LOCATION_CHANGE: {
      const { payload } = action
      return {
        ...state,
        popUpTextToChangeLocation: payload,
      }
    }
    case SET_LEADER_VIEW_POP_UP_TEXT_EXIT: {
      const { payload } = action
      return {
        ...state,
        popUpTextToExit: payload,
      }
    }
    case SET_LEADER_VIEW_POP_UP_TEXT_SCREEN_CHANGE: {
      const { payload } = action
      return {
        ...state,
        popUpTextToChangeRadioView: payload,
      }
    }
    case SET_LEADER_VIEW_POP_UP_TEXT_DATE_CHANGE: {
      const { payload } = action
      return {
        ...state,
        popUpTextToChangeDate: payload,
      }
    }
    case SET_LEADER_VIEW_DISPLAY_LOADING_ICON: {
      const { payload } = action
      return {
        ...state,
        displayLoadingIcon: payload,
      }
    }
    case SET_LEADER_VIEW_REFRESH_TABLE: {
      const { payload } = action
      return {
        ...state,
        refreshTable: payload,
      }
    }
    default:
      return state
  }
}
