/* eslint-disable react-hooks/exhaustive-deps */

import { IconButton, InputAdornment } from '@mui/material'
import Grid from '@mui/material/Grid'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import { renderMultiSectionDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers'
import 'date-fns'
import { addDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'
import DateTimeIcon from '../Icons/DateTimeIcon'

const DateTimePickerInDialogTME = (props) => {
  const { dateTime, label, readonly, handleTimeChange } = props
  const [selectedDate, setSelectedDate] = useState(dateTime)
  const minDate = getDateOfTodayWithNoTimestamp()
  const maxDate = addDays(getDateOfTodayWithNoTimestamp(), 6)

  useEffect(() => {}, [])

  useEffect(() => {
    handleTimeChange(selectedDate)
  }, [selectedDate])

  const handleChange = (dateTime) => {
    setSelectedDate(dateTime)
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <MobileDateTimePicker
          fullWidth
          margin="normal"
          id="date-time-picker-dialog"
          label={label}
          value={selectedDate}
          onChange={handleChange}
          KeyboardButtonProps={{
            'aria-label': 'change date and time',
          }}
          disabled={readonly}
          minDate={minDate}
          maxDate={maxDate}
          ampmInClock={true}
          viewRenderers={{
            hours: renderMultiSectionDigitalClockTimeView,
            minutes: renderMultiSectionDigitalClockTimeView,
          }}
          slotProps={{
            textField: (props) => ({
              ...props,
              sx: { width: '100%' },
              InputProps: {
                ...props.inputProps,
                disableUnderline: false,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="large">
                      <DateTimeIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }),
            toolbar: {
              hidden: true,
            },
            dialog: { sx: { '& .MuiPickersToolbar-penIconButton': { display: 'none' } } },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default DateTimePickerInDialogTME
