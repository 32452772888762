import { Box, Paper, useTheme } from '@mui/material'
import { addDays, addWeeks, endOfWeek, format, isSameDay, isSameMonth, startOfWeek, subWeeks } from 'date-fns'
import InlineCalendarDatePicker from '../common/calendar/PopoverInlineCalendarPicker'
import NextWeekButton from './NextWeekButton'
import PrevWeekButton from './PrevWeekButton'

const getStyles = (theme) => ({
  icon: {
    outline: 'none',
  },
  row: {
    margin: '0',
    padding: '0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  rowMiddle: {
    alignItems: 'center',
  },
  col: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
  },
  colStart: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  colCenter: {
    flexGrow: '1',
    maxWidth: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      transition: '.25s ease-out',
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  colEnd: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  weeklyCalendarBarContainerPosition: {
    width: '100%',
  },
  weeklyCalendarBar: {
    background: 'linear-gradient(to bottom, #bdbdbd 0%, #eeeeee 100%)', // theme.palette.tertiary.main,
    borderBottom: `1px solid ${theme.palette.tertiary.light}`,
  },
  weeklyCalendar: {
    fontSize: '1em',
    fontWeight: '300',
    color: theme.palette.secondary.dark,
    margin: '0 auto',
    maxWidth: theme.mainScrollContainer.maxWidth,
    display: 'block',
    position: 'relative',
    width: '100%',
    background: theme.palette.tertiary.light,
  },
  calendarHeader: {
    alignItems: 'center',
    display: 'flex',
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '115%',
    padding: '.0 1.5em',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.tertiary.dark,
  },
  calendarBodyCol: {
    flexGrow: '1',
  },
  calendarBodyCell: {
    flexGrow: '1',
    height: '3em',
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '300',
    outline: 'none',
    flexFlow: 'column wrap',
    '&:hover': {
      transition: '.25s ease-out',
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  calendarBodySelected: {
    flexGrow: '1',
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    flexFlow: 'column wrap',
    background: theme.palette.tertiary.dark,
    fontWeight: '999',
  },
  calendarBodyRow: {
    margin: '0',
    padding: '0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    lineHeight: '1.5',
  },
  calendarBodyCellDay: {
    fontSize: '60%',
  },
  calendarBodyCellNumber: {
    fontSize: '100%',
  },
  calendarBodyOtherMonth: {
    flexGrow: '1',
    position: 'relative',
    height: '3em',
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '300',
    fontSize: '100%',
    outline: 'none',
    flexFlow: 'column wrap',
    color: theme.palette.tertiary.dark,
    '&:hover': {
      transition: '.25s ease-out',
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  picker: {
    visibility: 'hidden',
    height: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  futureDate: {
    pointerEvents: 'none',
    opacity: 0.7,
  },
})

const WeeklyCalendar = (props) => {
  const { isTimecardDaily, onChange, selectedDate } = props

  const theme = useTheme()
  const styles = getStyles(theme)

  const getDateClassName = (day) => {
    let sameDay = isSameDay(day, selectedDate)
    let sameMonth = isSameMonth(day, selectedDate)

    let today = new Date()
    if (isTimecardDaily && day > today) {
      return { ...styles.calendarBodyOtherMonth, ...styles.futureDate }
    } else if (sameDay) {
      return styles.calendarBodySelected
    } else if (!sameDay && sameMonth) {
      return styles.calendarBodyCell
    } else if (!sameDay && !sameMonth) {
      return styles.calendarBodyOtherMonth
    }
  }

  const renderHeader = () => {
    const startWeek = startOfWeek(selectedDate)
    const endWeek = endOfWeek(selectedDate)

    return (
      <Box sx={styles.calendarHeader}>
        <Box sx={styles.colStart}>
          <PrevWeekButton
            {...props}
            styles={styles}
            startWeek={startWeek}
            handleDoubleClick={prevWeek}
            handleSubmit={prevWeek}
          />
        </Box>
        <InlineCalendarDatePicker {...props} />
        <Box sx={styles.colEnd}>
          <NextWeekButton
            {...props}
            styles={styles}
            endWeek={endWeek}
            handleDoubleClick={nextWeek}
            handleSubmit={nextWeek}
          />
        </Box>
      </Box>
    )
  }

  const renderCells = () => {
    const startDate = startOfWeek(selectedDate)
    const endDate = endOfWeek(selectedDate)

    const dateNameFormat = 'EEEEE'
    const dateFormat = 'd'
    const rows = []

    let days = []
    let day = startDate
    let formattedDateName = ''
    let formattedDate = ''

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDateName = format(day, dateNameFormat)
        formattedDate = format(day, dateFormat)
        let formattedDayOfWeek = day.toLocaleDateString('en-US', {
          weekday: 'long',
        })

        const cloneDay = day
        let divStyle = getDateClassName(day)
        days.push(
          <Box
            sx={divStyle}
            key={day}
            role="button"
            tabIndex="0"
            onClick={() => onDateClick(cloneDay)}
            data-cy={`dailyScedDate_${cloneDay.getDate()}`}
            aria-label={`scroll to ${formattedDayOfWeek}`}
          >
            <Box component={'span'} style={styles.calendarBodyCellDay}>
              {formattedDateName}
            </Box>
            <Box component={'span'} style={styles.calendarBodyCellNumber}>
              {formattedDate}
            </Box>
          </Box>,
        )
        day = addDays(day, 1)
      }
      rows.push(
        <Box sx={styles.calendarBodyRow} key={day}>
          {days}
        </Box>,
      )
      days = []
    }
    return (
      <Box sx={styles.body} aria-hidden={true}>
        {rows}
      </Box>
    )
  }

  const onDateClick = (day) => {
    let today = new Date()
    if (isTimecardDaily && day > today) {
      // do nothing
    } else {
      onChange(day)
    }
  }

  const nextWeek = () => {
    let today = new Date()
    let newDate = addWeeks(selectedDate, 1)
    if (isTimecardDaily && newDate > today) {
      onChange(today)
    } else {
      onChange(newDate)
    }
  }

  const prevWeek = () => {
    let newDate = subWeeks(selectedDate, 1)
    onChange(newDate)
  }

  return (
    <Box sx={styles.weeklyCalendarBarContainerPosition}>
      <Box sx={styles.weeklyCalendarBar}>
        <Paper sx={styles.weeklyCalendar} square elevation={1}>
          {renderHeader()}
          {renderCells()}
        </Paper>
      </Box>
    </Box>
  )
}

export default WeeklyCalendar
