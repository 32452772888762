import { CircularProgress, useTheme } from '@mui/material'

const getStyles = (theme) => ({
  loadingText: {
    color: theme.palette.tertiary.contrastText,
    paddingTop: '12px',
    fontSize: '14px',
  },
  loadingIconContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
})

const InnerLoaderWithText = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { size, loadingText } = props
  return (
    <div style={styles.loadingIconContainer}>
      <CircularProgress size={size} />
      <span style={styles.loadingText}>{loadingText}</span>
    </div>
  )
}

export default InnerLoaderWithText
