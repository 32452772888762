import { Grid, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { addDays, parseISO } from 'date-fns'
import React from 'react'
import { months } from '../../../constants/AvailabilityConstants'
import { convertAmPmTimeFormat } from '../../../utils/DateUtil'

const getStyles = (theme) => ({
  requestHeading: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  subText: {
    fontSize: 'medium',
    color: theme.infoMessages.color,
  },
  details: {
    flexDirection: 'column',
    padding: '0px 15px',
    marginBottom: '5px',
  },
  summary: {
    flex: 1,
  },
  requestDetailsExpandedColNames: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  requestDetailsColName: {
    flex: 1,
    fontSize: 'small',
    fontWeight: 'bold',
  },
  requestDetailsExpanded: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  requestDetails: {
    flex: 1,
    fontSize: 'medium',
    color: theme.infoMessages.color,
    paddingTop: '2px',
    paddingBottom: '3px',
  },
  datesExpanded: {
    margin: '5px 0',
  },
  statusTextField: {
    marginLeft: '5px',
  },
  statusListItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  root: {
    '& .MuiFilledInput-inputHiddenLabel': {
      paddingTop: 3,
      paddingBottom: 2,
    },
    '& .MuiInputBase-input': {
      paddingTop: 3,
      paddingBottom: 2,
    },
  },
  weekBorder: {
    marginBottom: 10,
    width: '99%',
    height: 5,
    borderBottom: '1px dashed #666666',
  },
})

const AvailabilityDetailsTable = ({ requestDetails, startDayOfRequest }) => {
  let localStartDate = parseISO(new Date(startDayOfRequest).toISOString().slice(0, -1))

  const theme = useTheme()
  const styles = getStyles(theme)

  const days = [
    { day: 'Sunday', details: [], weekNumber: 1 },
    { day: 'Monday', details: [], weekNumber: 1 },
    { day: 'Tuesday', details: [], weekNumber: 1 },
    { day: 'Wednesday', details: [], weekNumber: 1 },
    { day: 'Thursday', details: [], weekNumber: 1 },
    { day: 'Friday', details: [], weekNumber: 1 },
    { day: 'Saturday', details: [], weekNumber: 1 },

    { day: 'Sunday', details: [], weekNumber: 2 },
    { day: 'Monday', details: [], weekNumber: 2 },
    { day: 'Tuesday', details: [], weekNumber: 2 },
    { day: 'Wednesday', details: [], weekNumber: 2 },
    { day: 'Thursday', details: [], weekNumber: 2 },
    { day: 'Friday', details: [], weekNumber: 2 },
    { day: 'Saturday', details: [], weekNumber: 2 },
  ]

  let isWeek2Available = false
  for (let detail of requestDetails) {
    if (detail.week_number === 2) {
      isWeek2Available = true
      break
    }
  }
  if (!isWeek2Available) {
    days.splice(7, 7)
  }
  for (let detail of requestDetails) {
    for (let day of days) {
      if (detail.day_name === day.day && detail.week_number === day.weekNumber) {
        detail.uniqueID = `${detail.availability_request_detail_id}_${detail.week_number}`
        day.details.push(detail)
      }
    }
  }

  const printDate = (index) => {
    let date = addDays(localStartDate, index).getDate().toString()
    let month = (addDays(localStartDate, index).getMonth() + 1).toString()
    return `${month.padStart(2, 0)}/${date.padStart(2, 0)}`
  }

  const ariaLabelDate = (index) => {
    let date = addDays(localStartDate, index).getDate().toString()
    let month = addDays(localStartDate, index).getMonth()
    return `${months[month]} ${date}`
  }

  const renderRequestDetailsHeader = () => {
    return (
      <Grid item xs={12} md={12} sx={styles.requestDetailsExpanded}>
        <Grid item xs={4} md={4}>
          <Typography sx={styles.requestDetailsColName}>Day</Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <Typography id="sTimeHeader" sx={styles.requestDetailsColName}>
            Start Time
          </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <Typography id="eTimeHeader" sx={styles.requestDetailsColName}>
            End Time
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const renderRequestDetails = () => {
    return (
      <React.Fragment>
        {days.map((day, index) => {
          let date = printDate(index)
          return (
            <React.Fragment key={index}>
              {index === 7 && (
                <Grid item xs={12} md={12}>
                  <div style={styles.weekBorder} />
                </Grid>
              )}
              {day.details.length > 0 &&
                day.details.map((detail, rIndex) => {
                  return (
                    <Grid tabIndex={0} item xs={12} md={12} key={rIndex} sx={styles.requestDetailsExpanded}>
                      <Grid item xs={4} md={4}>
                        {rIndex === 0 && (
                          <Typography
                            role="heading"
                            aria-label={`${day.day}, ${ariaLabelDate(index)}`}
                            variant="body2"
                          >{`${day.day.substring(0, 3)}, ${date}`}</Typography>
                        )}
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Typography
                          id={`sTime_${detail.uniqueID}_${index}_${rIndex}`}
                          role="heading"
                          aria-labelledby={`sTimeHeader sTime_${detail.uniqueID}_${index}_${rIndex}`}
                          sx={styles.requestDetails}
                        >
                          {convertAmPmTimeFormat(detail.start_time)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Typography
                          id={`eTime_${detail.uniqueID}_${index}_${rIndex}`}
                          role="heading"
                          aria-labelledby={`eTimeHeader eTime_${detail.uniqueID}_${index}_${rIndex}`}
                          sx={styles.requestDetails}
                        >
                          {convertAmPmTimeFormat(detail.end_time)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                })}
              {day.details.length === 0 && (
                <Grid tabIndex={0} item xs={12} md={12} sx={styles.requestDetailsExpanded}>
                  <Grid item xs={4} md={4}>
                    <Typography
                      role="heading"
                      aria-label={`${day.day} ${ariaLabelDate(index)}`}
                      variant="body2"
                    >{`${day.day.substring(0, 3)}, ${date}`}</Typography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Typography sx={styles.requestDetails}>Unavailable</Typography>
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div style={styles.subText}>
        <div style={styles.datesExpanded}>
          <Grid container>
            {renderRequestDetailsHeader()}
            {renderRequestDetails()}
          </Grid>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AvailabilityDetailsTable
