import { useAuth } from '@praxis/component-auth'
import { useMutation } from '@tanstack/react-query'
import axios, { AxiosPromise, AxiosResponse } from 'axios'
import { format } from 'date-fns'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { IUserFeedback } from './responses/userFeedback'
import { useUser } from '../../auth/hooks/useUser'
interface GetFeedbackParams {
  workerId: string
  startDate: string
  endDate: string
  module: string
  personas: Array<String>
}
const getFeedback = (params: GetFeedbackParams): AxiosPromise<AxiosResponse<IUserFeedback>> => {
  let start = format(new Date(params.startDate), 'yyyy-MM-dd')
  let end = format(new Date(params.endDate), 'yyyy-MM-dd')

  let apiUrl =
    `${apiConfig.feedback.url}` +
    '/data' +
    '?worker_id=' +
    params.workerId +
    '&start_date=' +
    start +
    '&end_date=' +
    end +
    '&module=' +
    params.module +
    '&personas=' +
    params.personas +
    '&key=' +
    `${apiConfig.feedback.key}`

  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

export function useUserFeedbackMutation() {
  const { isAuthenticated, login } = useAuth()
  const authenticated = isAuthenticated()
  const { userData } = useUser().user

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useMutation({
    mutationFn: (args: any) => getFeedback({ workerId: userData.worker_id, ...args }),
  })
}
