import { MenuItem, Skeleton, TextField } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useManagementScopes } from '../../api/userAccess/useManagementScopes'
import { setSelectedLocation } from '../../store/leader/actionCreator'

const LocationSelector = () => {
  const dispatch = useDispatch()
  const { session } = useAuth()
  const homeLocationString = session?.userInfo?.locationid.toString()
  const selectedLocation = useSelector((state) => state.leader.selectedLocation)
  const { data: managementScopes, error, isLoading } = useManagementScopes()

  useEffect(() => {
    if (!selectedLocation && homeLocationString) {
      dispatch(setSelectedLocation(homeLocationString))
    }
  }, [dispatch, homeLocationString, selectedLocation])

  const handleSelectNewLocation = (event) => {
    dispatch(setSelectedLocation(event.target.value))
  }

  const renderTextField = (locationsList) => {
    return (
      <TextField
        id="location"
        select
        label="Location"
        aria-label={'Select location'}
        value={selectedLocation}
        onChange={handleSelectNewLocation}
        data-cy="locationSelector"
      >
        {locationsList.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  const renderSkeleton = () => {
    return <Skeleton height={72} width={72} />
  }

  const renderLocationSelectorContents = () => {
    if (isLoading) {
      return renderSkeleton()
    } else if (managementScopes?.data?.management_scopes) {
      let locations = managementScopes.data.management_scopes.map((a) => a.location_number)
      if (!locations.includes(homeLocationString)) locations.push(homeLocationString)
      return renderTextField(locations)
    } else if (error) {
      return renderTextField([homeLocationString])
    }
  }

  return <>{renderLocationSelectorContents()}</>
}

export default LocationSelector
