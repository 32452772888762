import { Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ACCEPTED_SHIFT_SWAP_MESSAGE,
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER_SHIFT_SWAP,
  RULES_VIOLATION_ERROR,
  RULES_VIOLATION_ERR_MSG,
  RULES_VIOLATION_ERR_MSG_FOR_SWAP,
  UNABLE_TO_SAVE,
} from '../../constants/errorConstants'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { formatErrorCode } from '../../utils/ErrorHandling'
import { SCREEN_NAME_SWAP_SHIFT } from '../../utils/ScreenName'
import DisplaySegmentShiftSwap from '../DisplaySegments/DisplaySegmentShiftSwap'
import HeaderTitle from '../Header/HeaderTitle'
import AcceptSwapRequestModal from './AcceptSwapRequestModal'

const PAGE_TITLE = 'Review Swap Requests'

const getStyles = (theme) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  loadingIconContainer: theme.loadingIconContainer,
  highlights: {
    fontWeight: 'bold',
    fontSize: '105%',
    paddingTop: '2px',
  },
  highlightsContainer: {
    textAlign: 'center',
    borderBottom: `4px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
  },
  highlightsContainerPosition: {
    width: '100%',
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingTop: '0',
    paddingBottom: '0',
  },
  displaySegmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1em',
    justifyContent: 'center',
  },
  displaySegmentContainerModal: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1em',
    justifyContent: 'center',
  },
  displaySegmentContainerSwap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0.2em',
    justifyContent: 'center',
  },
  titleContainerModal: {
    padding: '16px 24px 5px',
  },
  shiftSwapButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  padLeftForIcons: {
    paddingLeft: '2.4em',
  },
  startTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
  },
  endTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: '1',
    minHeight: '32px',
    paddingBottom: '.25em',
  },
  jobContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobContainerAtPopup: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobName: {
    paddingTop: '3px',
  },
  iconMargin: {
    marginRight: '.25em',
  },
  personIconMargin: {
    marginRight: '.6em',
  },
  indicator: {
    marginTop: '-2px',
    marginLeft: '-15px',
    marginRight: '5px',
    fontSize: '24px',
    color: 'black',
    zIndex: '1',
  },
  jobNameContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
  },
  paycodeEditsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
  },
  paycodeEdits: {
    fontWeight: '300',
  },
  dateHeading: {
    fontWeight: 'bold',
    fontSize: '0.9em',
  },
  lastEdit: {
    color: theme.palette.secondary.light,
  },
  dividerBox: {
    width: '100%',
    marginTop: '10px',
  },
  postShiftButton: {
    minWidth: '50px',
  },
  tableList: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  tableItem: {
    display: 'table-row',
  },
  listItemDiv: {
    position: 'relative',
  },
  loaderStyles: {
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
  },
  innerLoaderLabelStyles: {
    paddingLeft: '6px',
    fontColor: '#cc0000',
    color: '#CC0000',
  },
  addtionalJobsContainer: {
    paddingTop: '1em',
  },
  dateContainer: {
    display: 'flex',
  },
  shiftLabelContainer: {
    marginTop: -3,
    marginLeft: '12px',
    display: 'inline-Block',
  },
  shiftLabelContainerInModal: {
    marginTop: '1px',
    display: 'inline-Block',
  },
  additionalShiftLabels: {
    margin: '2px 6px',
  },
  shiftLabel: {
    border: '1px solid #333',
    color: '#333',
    padding: '1px 5px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
  },
  ownShiftContainer: {
    margin: '10px',
    padding: '10px',
    border: '1px solid #aeaeae',
    position: 'relative',
  },
  requestContainer: {
    margin: '10px',
    padding: '10px',
    border: '1px solid #aeaeae',
    borderRadius: 7,
    position: 'relative',
    cursor: 'pointer',
  },
  requestContainerModal: {
    margin: '-4px',
    padding: '5px 10px 0 10px',
    border: '1px solid #aeaeae',
    borderRadius: '7px',
    position: 'relative',
  },
  likesContainer: {
    display: 'flex',
    position: 'absolute',
    right: 10,
    bottom: 10,
    '& span': {
      paddingLeft: '4px',
      fontSize: '0.9em',
    },
  },
  shiftCountMessageContainer: {
    padding: '10px',
    fontStyle: 'italic',
  },
  verticalGap: {
    padding: '10px 5px',
    textAlign: 'center',
    width: '90%',
  },
  pb10: {
    paddingBottom: '10px',
  },
  gradiantBG: {
    background: 'linear-gradient(to bottom, #bdbdbd 0%, #eeeeee 100%)',
    borderBottom: '1px solid #EEEEEE',
    height: 100,
    position: 'absolute',
    zIndex: 100,
  },
  shiftForeGround: {
    position: 'relative',
  },
  dialogPaper: {
    margin: '5px',
  },
  dateHeadingSwap: {
    fontWeight: 'bold',
    fontSize: '0.9em',
    color: '#000000',
  },
})

const ReviewSwapRequests = () => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const screenAccess = useSelector((state) => state.layout.screenAccess)
  const [temploading, setTemploading] = useState(true)
  const [open, setOpen] = useState(false)
  const [selectedShift, setSelectedShift] = useState({})
  const swapRequestsDateTimeWiseMap = useSelector((state) => state.weeklySchedule.swapRequestsDateTimeWiseMap)
  const [requestCount, calcRequestCount] = useState(0)
  const currentShiftForReviewer = useSelector((state) => state.swapShift.currentShiftForReviewer)
  const accepterSwapPostResponse = useSelector((state) => state.swapShift.accepterSwapPostResponse)
  const accepterSwapErrResponse = useSelector((state) => state.swapShift.accepterSwapErrResponse)
  const [resetActions, makeResetActions] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (accepterSwapPostResponse) {
      handleClose()
      if (!accepterSwapPostResponse.is_rule_violated) {
        dispatch(showNotificationSuccess(true, ACCEPTED_SHIFT_SWAP_MESSAGE))
        navigate('/team-member/schedule')
      } else if (accepterSwapPostResponse.is_rule_violated) {
        //Show which  rule is voilated
        dispatch(showNotificationError(true, parseRuleViolationForSwap(accepterSwapPostResponse)))
      }
    } else if (accepterSwapErrResponse) {
      handleClose()
      if (
        accepterSwapErrResponse.code &&
        accepterSwapErrResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(accepterSwapErrResponse.code)
      ) {
        dispatch(showNotificationError(true, accepterSwapErrResponse.message))
      } else if (accepterSwapErrResponse.code && RULES_VIOLATION_ERROR.includes(accepterSwapErrResponse.code)) {
        dispatch(showNotificationError(true, parseRuleViolation(accepterSwapErrResponse)))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE +
              ' ' +
              formatErrorCode(accepterSwapErrResponse, ERROR_CODE_CANNOT_CONNECT_TO_SERVER_SHIFT_SWAP),
          ),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accepterSwapPostResponse, accepterSwapErrResponse])

  useEffect(() => {
    if (swapRequestsDateTimeWiseMap && currentShiftForReviewer) {
      calcRequestCount(swapRequestsDateTimeWiseMap[currentShiftForReviewer.schedule_start].length)
      setTemploading(false)
    } else if (swapRequestsDateTimeWiseMap === null) {
      calcRequestCount(0)
      navigate('/team-member/schedule')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapRequestsDateTimeWiseMap, currentShiftForReviewer])

  const parseRuleViolation = (errResponse) => {
    let errorMessages = ''
    let message

    if (errResponse.errors && errResponse.errors.length > 0) {
      for (let error of errResponse.errors) {
        errorMessages += '<li>' + error + '</li>'
      }
      message = RULES_VIOLATION_ERR_MSG + '<br /><ul>' + errorMessages + '</ul>'
    } else {
      message = RULES_VIOLATION_ERR_MSG
    }

    return message
  }

  const parseRuleViolationForSwap = (accepterSwapPostResponse) => {
    let ruleViolationsRequesterArr = accepterSwapPostResponse['requester'].rule_violations
    let ruleViolationsAccepterArr = accepterSwapPostResponse['accepter'].rule_violations
    let errMsgRequester = ''
    let errMsgAccepter = ''
    let message = ''

    if (ruleViolationsRequesterArr && ruleViolationsRequesterArr.length > 0) {
      for (let ruleViolation of ruleViolationsRequesterArr) {
        errMsgRequester += '<li>' + ruleViolation.rule_violation_text + '</li>'
      }
      message = 'Requester:' + '<br /><ul>' + errMsgRequester + '</ul><br />'
    }

    if (ruleViolationsAccepterArr && ruleViolationsAccepterArr.length > 0) {
      for (let ruleViolation of ruleViolationsAccepterArr) {
        errMsgAccepter += '<li>' + ruleViolation.rule_violation_text + '</li>'
      }
      message = message + 'Accepter:' + '<br /><ul>' + errMsgAccepter + '</ul>'
    }

    return RULES_VIOLATION_ERR_MSG_FOR_SWAP + '<br />' + message
  }

  const openAcceptSwapDialog = (selectedShift) => {
    setSelectedShift(selectedShift)
    makeResetActions(true)
    setOpen(true)
  }

  const handleClose = () => {
    makeResetActions(false)
    setOpen(false)
  }

  const renderComponent = () => {
    return (
      <>
        <AcceptSwapRequestModal
          open={open}
          handleClose={() => handleClose()}
          styles={styles}
          shift={currentShiftForReviewer}
          selectedShift={selectedShift}
          resetActions={resetActions}
        />
        {renderOwnShift()}
      </>
    )
  }

  const renderOwnShift = () => {
    return (
      <div style={{ ...styles.mainContainerPosition, ...styles.shiftForeGround }}>
        <div style={styles.gradiantBG} />
        <div style={styles.mainScrollContainer}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <div style={styles.ownShiftContainer}>
                <DisplaySegmentShiftSwap
                  shift={currentShiftForReviewer}
                  styles={styles}
                  data-cy="displaySegmentForShiftSwap"
                  showPostShiftButton={false}
                  flow="acceptor"
                  totalRequests={requestCount}
                />
              </div>
            </Grid>
          </Grid>
          {requestCount > 0 && renderShiftCountMessage()}
          {renderSwapRequests()}
        </div>
      </div>
    )
  }

  const renderShiftCountMessage = () => {
    return (
      <div style={styles.shiftCountMessageContainer}>
        <Typography variant="body2">{`You have received ${requestCount} swap request(s). Please pick any one to review.`}</Typography>
      </div>
    )
  }

  const renderSwapRequests = () => {
    return swapRequestsDateTimeWiseMap[currentShiftForReviewer.schedule_start].map((request, index) => (
      <div style={styles.requestContainer} data-cy={`swap-request-id-${index}`} key={index}>
        <DisplaySegmentShiftSwap
          shift={request}
          onClickShift={() => openAcceptSwapDialog(request)}
          styles={styles}
          key={index}
        />
      </div>
    ))
  }

  return (
    <React.Fragment>
      <HeaderTitle title={PAGE_TITLE} />
      {screenAccess.includes(SCREEN_NAME_SWAP_SHIFT) && !temploading && renderComponent()}
    </React.Fragment>
  )
}

export default ReviewSwapRequests
