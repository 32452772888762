import { areIntervalsOverlapping, getDay, intervalToDuration } from 'date-fns'
import { getDateOfTodayWithNoTimestamp } from './DateUtil'

// below line of code will return true if today is Tuesday or later.
// It returns false if today is Sunday or Monday
export const isScheduleGeneratedFor2WeeksFromNow = getDay(getDateOfTodayWithNoTimestamp()) >= 2 ? true : false

export const checkIfEndTimeSmaller = (startTime, endTime) => {
  let sTime = startTime
  let eTime = endTime === '00:00' ? '24:00' : endTime

  if (sTime === '' || eTime === '') {
    return false
  } else if (Date.parse('01/01/2001 ' + sTime) > Date.parse('01/01/2001 ' + eTime)) {
    return true
  }
}

export const checkOverlapping = (s1Time, e1Time, s2Time, e2Time) => {
  let overlap = false

  if (
    s1Time !== '' &&
    e1Time !== '' &&
    s2Time !== '' &&
    e2Time !== '' &&
    hmsToSeconds(s1Time) < hmsToSeconds(e1Time) &&
    hmsToSeconds(s2Time) < hmsToSeconds(e2Time)
  ) {
    overlap = areIntervalsOverlapping(
      {
        start: new Date(2001, 2, 1, s1Time.split(':')[0], s1Time.split(':')[1], 0),
        end: new Date(2001, 2, 1, e1Time.split(':')[0], e1Time.split(':')[1], 0),
      },
      {
        start: new Date(2001, 2, 1, s2Time.split(':')[0], s2Time.split(':')[1], 0),
        end: new Date(2001, 2, 1, e2Time.split(':')[0], e2Time.split(':')[1], 0),
      },
      { inclusive: true },
    )
  } else {
    overlap = false
  }
  return overlap
}

export const checkLessThanMinHrs = (startTime, endTime) => {
  let sTime = startTime
  let eTime = endTime === '00:00' ? '24:00' : endTime

  if (sTime !== '' && eTime !== '') {
    const timeGap = intervalToDuration({
      start: new Date(2001, 2, 1, sTime.split(':')[0], sTime.split(':')[1], 0),
      end: new Date(2001, 2, 1, eTime.split(':')[0], eTime.split(':')[1], 0),
    })

    if (
      sTime !== '00:00' &&
      eTime !== '23:59' &&
      eTime !== '24:00' &&
      timeGap.years === 0 &&
      timeGap.months === 0 &&
      timeGap.days === 0 &&
      timeGap.hours < 2
    ) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

function hmsToSeconds(time) {
  var b = time.split(':')
  return b[0] * 3600 + b[1] * 60 + (+b[2] || 0)
}
