import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import React from 'react'

const CustomFooter = () => {
  return (
    <TableFooter>
      <TableRow>
        <TableCell align="right">
          There are changes pending. Please save your changes before navigating to a different page.
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}

export default CustomFooter
