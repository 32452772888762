import { Grid, TextField, Typography } from '@mui/material'
import { isValid, parseISO } from 'date-fns'
import moment from 'moment'
import { toIsoStringWithoutTime } from '../../../utils/DateUtil'
import MoreActionsMenu from './MoreActionsMenu'
import { checkOvernight } from './PunchCorrectionUtils'
import TimePicker from '../../common/calendar/TimePicker'
import { useUser } from '../../../auth/hooks/useUser'

const styles = {
  timePicker: {
    display: 'flex',
    // minWidth: 90,
    padding: '2px',
    '& div': {
      width: '75%',
    },
  },
  rowGridGrey: {
    padding: '10px',
    border: '1px solid #999',
    borderRadius: '4px',
  },
  rowGridRed: {
    padding: '10px',
    border: '1px solid #cc0000',
    borderRadius: '4px',
  },
  errorMsg: {
    color: '#cc0000',
    paddingTop: '10px',
    fontSize: '0.85rem',
  },
  infoMsg: {
    color: '#333',
    paddingTop: '10px',
    fontSize: '0.85rem',
  },
}

const RequestPunchCorrectionRowInput = (props) => {
  const { rowDetail, dayPunches, punchInTimeChanged, punchOutTimeChanged, rowId, removePunch } = props
  const { locationData } = useUser().user

  /* const [selectedPunchInTime, setSelectedPunchInTime] = useState(
    rowDetail['punchInTime'] ? rowDetail['punchInTime'] : null
  )
  const [selectedPunchOutTime, setSelectedPunchOutTime] = useState(
    rowDetail['punchOutTime'] ? rowDetail['punchOutTime'] : null
  ) */

  const createCypressTag = (componentName) => {
    return 'requestPunchCorrectionRowInput_' + componentName + (rowId ? '_' + rowId : '')
  }

  const handlePunchInTimeChange = (timePickerTime) => {
    let time = resetDateToTimecardDate(timePickerTime, 'inTime')
    // setSelectedPunchInTime(time)
    punchInTimeChanged(time)
  }

  /*  const handlePunchInTimeAccept = () => {
    punchInTimeChanged(selectedPunchInTime)
  } */

  const handlePunchOutTimeChange = (timePickerTime) => {
    let time = resetDateToTimecardDate(timePickerTime, 'outTime')
    // setSelectedPunchOutTime(time)
    punchOutTimeChanged(time)
  }

  /* const handlePunchOutTimeAccept = () => {
    punchOutTimeChanged(selectedPunchOutTime)
  } */

  const resetDateToTimecardDate = (timePickerTime, punchType) => {
    if (isValid(timePickerTime)) {
      let punchTimeFromTimecard = punchType === 'inTime' ? rowDetail['punchInTime'] : rowDetail['punchOutTime']

      let timecardDate = punchTimeFromTimecard
        ? punchTimeFromTimecard
        : punchType === 'outTime'
          ? findPunchOutDate(rowDetail['punchInTime'], rowDetail['punchOutTime'])
          : parseISO(new Date(rowDetail['punchInDate']).toISOString().slice(0, -1))

      let timecardDateWithTimeZone = moment(timecardDate)

      //Do not change the order
      timePickerTime.setYear(timecardDateWithTimeZone.year())
      timePickerTime.setMonth(timecardDateWithTimeZone.month())
      timePickerTime.setDate(timecardDateWithTimeZone.date())

      return timePickerTime
    } else {
      return null
    }
  }

  const findPunchOutDate = (startTime, endTime) => {
    let punchOutDate = rowDetail['punchInTime'] || moment.tz(rowDetail['punchInDate'], locationData.iso_time_zone_code)

    let isOvernight = checkOvernight(startTime, endTime)
    if (isOvernight) {
      punchOutDate = rowDetail['nextDay']
    }

    return punchOutDate
  }

  const removeThisPunch = () => {
    removePunch(rowId)
  }

  const renderContent = () => {
    let showRedBorder = rowDetail['isPunchOutSmaller'] || rowDetail['isOverlapped']
    return (
      <Grid
        container
        // alignItems="flex-end"
        //data-cy={`requestPunchCorrectionDetail_${requestDate}_${requestDetailPerDateIndex}`}
        sx={showRedBorder ? styles.rowGridRed : styles.rowGridGrey}
        justifyContent="space-between"
        // wrap="nowrap"
      >
        <Grid item xs={5} md={5}>
          <TimePicker
            id="punchInTime"
            label="Punch In"
            defaultValue={rowDetail['punchInTime'] ? rowDetail['punchInTime'] : null}
            onChange={handlePunchInTimeChange}
            disabled={!rowDetail['isStartPunchEditable']}
            disableOpenPicker={!rowDetail['isStartPunchEditable']}
            timeSteps={{ hours: 1, minutes: 1 }}
            sx={styles.timePicker}
            renderInput={(params) => (
              <TextField
                {...params}
                data-cy={createCypressTag('punchInTime')}
                inputProps={{
                  ...params.inputProps,
                  pattern: '[a-zA-Z0-9_.-]',
                  type: 'text',
                  // inputmode: 'visible-password',
                  placeholder: 'hh:mm am or pm',
                  'aria-label':
                    'punch in, Enter time in 12 hours format. For example, zero nine zero zero p for 09:00 pm',
                  disabled: false,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={5} md={5}>
          <TimePicker
            id="punchOutTime"
            label="Punch Out"
            defaultValue={rowDetail['punchOutTime'] ? rowDetail['punchOutTime'] : null}
            onChange={handlePunchOutTimeChange}
            disabled={!rowDetail['isEndPunchEditable']}
            disableOpenPicker={!rowDetail['isEndPunchEditable']}
            timeSteps={{ hours: 1, minutes: 1 }}
            sx={styles.timePicker}
            renderInput={(params) => (
              <TextField
                {...params}
                data-cy={createCypressTag('punchOutTime')}
                type="text"
                inputProps={{
                  ...params.inputProps,
                  pattern: '[a-zA-Z0-9_.-]',
                  type: 'text',
                  // inputmode: 'visible-password',
                  placeholder: 'hh:mm am or pm',
                  'aria-label':
                    'punch out, Enter time in 12 hours format. For example, zero nine zero zero p for 09:00 pm',
                  disabled: false,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <MoreActionsMenu
            dayPunches={dayPunches}
            rowDetail={rowDetail}
            addPunch={props.addPunch}
            removePunch={removeThisPunch}
          />
        </Grid>
        {rowDetail['isPunchOutSmaller'] && (
          <Grid item xs={12} sm={12}>
            <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
              Punch Out should be greater than Punch In.
            </Typography>
          </Grid>
        )}
        {rowDetail['isOverlapped'] && (
          <Grid item xs={12} sm={12}>
            <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
              Time intervals are overlapping with each other.
            </Typography>
          </Grid>
        )}
        {rowDetail['isOvernight'] && (
          <Grid item xs={12} sm={12}>
            <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.infoMsg}>
              Note: Punch out time will occur on {toIsoStringWithoutTime(rowDetail['nextDay'])}.
            </Typography>
          </Grid>
        )}
        {!rowDetail['punchInTime'] && !rowDetail['punchOutTime'] && (
          <Grid item xs={12} sm={12}>
            <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
              Please fill in the punch details.
            </Typography>
          </Grid>
        )}
        {rowDetail['punchOutTime'] && !rowDetail['punchInTime'] && (
          <Grid item xs={12} sm={12}>
            <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
              Punch In cannot be empty.
            </Typography>
          </Grid>
        )}
        {rowDetail['punchInTime'] && !rowDetail['punchOutTime'] && (
          <Grid item xs={12} sm={12}>
            <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.infoMsg}>
              Punch out is empty. Confirm before submitting.
            </Typography>
          </Grid>
        )}
        {rowDetail['isPunchInFuture'] && (
          <Grid item xs={12} sm={12}>
            <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
              Punch In can not be in future.
            </Typography>
          </Grid>
        )}
        {rowDetail['isPunchOutFuture'] && (
          <Grid item xs={12} sm={12}>
            <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
              Punch Out can not be in future.
            </Typography>
          </Grid>
        )}
      </Grid>
    )
  }

  return renderContent()
}

export default RequestPunchCorrectionRowInput
