const ShiftSwapCalendarIcon = () => {
  return (
    <svg width="25px" height="26px" viewBox="0 0 25 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Shift Swap Dashboard</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="6" height="3"></rect>
        <rect id="path-3" x="0" y="0" width="6" height="3"></rect>
      </defs>
      <g id="New-Shift-Swap" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <line
          x1="10.5"
          y1="21.5"
          x2="1.5"
          y2="21.5"
          id="Line"
          stroke="#000000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <line
          x1="1.5"
          y1="21.5"
          x2="1.5"
          y2="3.5"
          id="Line-2"
          stroke="#000000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <line
          x1="1.5"
          y1="3.5"
          x2="3.2"
          y2="3.5"
          id="Line-3"
          stroke="#000000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <line
          x1="6.7"
          y1="3.5"
          x2="14.2"
          y2="3.5"
          id="Line-5"
          stroke="#000000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <line
          x1="17.7"
          y1="3.5"
          x2="19.5"
          y2="3.5"
          id="Line-6"
          stroke="#000000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <line
          x1="19.5"
          y1="3.5"
          x2="19.5"
          y2="11.5"
          id="Line-4"
          stroke="#000000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <line x1="5" y1="0.5" x2="5" y2="3.5" id="Line-7" stroke="#000000" strokeLinecap="round"></line>
        <line x1="16" y1="0.5" x2="16" y2="3.5" id="Line-7" stroke="#000000" strokeLinecap="round"></line>
        <g
          id="Oval"
          transform="translate(5.000000, 5.000000) rotate(179.000000) translate(-5.000000, -5.000000) translate(2.000000, 3.500000)"
        >
          <mask id="mask-2" fill="white">
            <use xlink="#path-1"></use>
          </mask>
          <g id="Mask"></g>
          <circle stroke="#000000" mask="url(#mask-2)" cx="3" cy="3" r="1.5"></circle>
        </g>
        <g
          id="Oval"
          transform="translate(16.000000, 5.000000) rotate(179.000000) translate(-16.000000, -5.000000) translate(13.000000, 3.500000)"
        >
          <mask id="mask-4" fill="white">
            <use xlink="#path-3"></use>
          </mask>
          <g id="Mask"></g>
          <circle stroke="#000000" mask="url(#mask-4)" cx="3" cy="3" r="1.5"></circle>
        </g>
        <rect id="Rectangle" fill="#000000" x="4" y="11" width="2.5" height="2.5"></rect>
        <rect id="Rectangle" fill="#000000" x="14" y="11" width="2.5" height="2.5"></rect>
        <rect id="Rectangle" fill="#000000" x="9" y="11" width="2.5" height="2.5"></rect>
        <rect id="Rectangle" fill="#000000" x="9" y="16" width="2.5" height="2.5"></rect>
        <rect id="Rectangle" fill="#000000" x="4" y="16" width="2.5" height="2.5"></rect>
        <line x1="1.5" y1="7.5" x2="19.5" y2="7.5" id="Line-8" stroke="#000000" strokeLinecap="square"></line>
        <rect id="Rectangle" fill="#000000" x="1" y="5" width="19" height="3"></rect>
        <rect id="Rectangle" fill="#000000" x="6" y="4" width="8.6" height="3"></rect>
        <rect id="Rectangle" stroke="#000000" fill="#D8D8D8" x="17.5" y="4.5" width="1" height="1"></rect>
        <rect id="Rectangle" stroke="#000000" fill="#D8D8D8" x="2.5" y="4.5" width="1" height="1"></rect>
        <g id="Group" transform="translate(11.000000, 13.000000)" fill="#000000">
          <g id="Swap" transform="translate(0.669964, 0.634096)">
            <polygon
              id="Path"
              points="2.36363636 3.17835517 7.30839103 3.17835517 7.30839103 0 13 4.72727273 7.30839103 4.72727273 2.36363636 4.72727273"
            ></polygon>
            <polygon
              id="Path"
              transform="translate(5.318182, 9.272727) rotate(-180.000000) translate(-5.318182, -9.272727) "
              points="0 10.1941046 4.94475467 10.1941046 4.94475467 6.90909091 10.6363636 11.6363636 4.94475467 11.6363636 0 11.6363636"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ShiftSwapCalendarIcon
