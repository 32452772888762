import { useUserFeatures } from '../../../api/userAccess/useUserFeatures'
import {
  AVAILABILITY_LEADER,
  TIME_OFF_LEADER,
  PUNCH_CORRECTION_LEADER,
  AVAILABILITY_PARTIAL_ACTION_LEADER,
  PUNCH_CORRECTION_PARTIAL_ACTION_LEADER,
  TIME_OFF_PARTIAL_ACTION_LEADER,
  AVAILABILITY_READ_ONLY_LEADER,
  PUNCH_CORRECTION_READ_ONLY_LEADER,
  TIME_OFF_READ_ONLY_LEADER,
} from '../../../utils/ScreenName'
import PendingActionsIcon from '../../Icons/PendingActionsIcon'
import GenericCard from '../../common/cards/GenericCard'
import AvailabilityDailyActions from './Categories/Availability/AvailabilityDailyActions'
import TimeOffDailyActions from './Categories/TimeOff/TimeOffDailyActions'
import PunchCorrectionDailyActions from './Categories/PunchCorrection/PunchCorrectionDailyActions'
import AvailabilityPatialLeaderDailyActions from './Categories/Availability/AvailabilityPatialLeaderDailyActions'
import PunchCorrectionPartialLeaderDailyActions from './Categories/PunchCorrection/PunchCorrectionPartialLeaderDailyActions'
import TimeOffPartialLeaderDailyActions from './Categories/TimeOff/TimeOffPartialLeaderDailyActions'

const DailyActionsCard = () => {
  const { data: userFeatures } = useUserFeatures()
  const screenAccess = userFeatures?.screenAccess
  const dailyActionFeatures = [
    AVAILABILITY_LEADER,
    TIME_OFF_LEADER,
    PUNCH_CORRECTION_LEADER,
    AVAILABILITY_PARTIAL_ACTION_LEADER,
    PUNCH_CORRECTION_PARTIAL_ACTION_LEADER,
    TIME_OFF_PARTIAL_ACTION_LEADER,
    AVAILABILITY_READ_ONLY_LEADER,
    PUNCH_CORRECTION_READ_ONLY_LEADER,
    TIME_OFF_READ_ONLY_LEADER,
  ]

  return (
    <>
      {dailyActionFeatures.some((feature) => screenAccess?.includes(feature)) && (
        <GenericCard title="Daily Actions" icon={<PendingActionsIcon />}>
          <div style={{ paddingTop: '8px' }}>
            {screenAccess?.includes(AVAILABILITY_LEADER) && <AvailabilityDailyActions />}
            {screenAccess?.includes(PUNCH_CORRECTION_LEADER) && <PunchCorrectionDailyActions />}
            {screenAccess?.includes(TIME_OFF_LEADER) && <TimeOffDailyActions />}
            {screenAccess?.includes(AVAILABILITY_PARTIAL_ACTION_LEADER) && <AvailabilityPatialLeaderDailyActions />}
            {screenAccess?.includes(PUNCH_CORRECTION_PARTIAL_ACTION_LEADER) && (
              <PunchCorrectionPartialLeaderDailyActions />
            )}
            {screenAccess?.includes(TIME_OFF_PARTIAL_ACTION_LEADER) && <TimeOffPartialLeaderDailyActions />}
            {screenAccess?.includes(AVAILABILITY_READ_ONLY_LEADER) && <AvailabilityPatialLeaderDailyActions />}
            {screenAccess?.includes(PUNCH_CORRECTION_READ_ONLY_LEADER) && <PunchCorrectionPartialLeaderDailyActions />}
            {screenAccess?.includes(TIME_OFF_READ_ONLY_LEADER) && <TimeOffPartialLeaderDailyActions />}
          </div>
        </GenericCard>
      )}
    </>
  )
}

export default DailyActionsCard
