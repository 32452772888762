import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CALL_IN_LATE } from '../../../../../../constants/RequestConstants'
import { LEADER_DATE_HEADER_FORMAT } from '../../../../../../constants/leaderConstants'
import { setBackToComponent } from '../../../../../../store/header/actionCreator'
import { formatDateString, formatTime } from '../../../../../../utils/DateUtil'
import RequestStatusIcon from '../../../../../Requests/RequestStatusIcon'
import { getTimeOffApiData } from '../../../../CategoryDetail/TimeOffApiData'
import { MY_TEAM_PAGE_PATH } from '../../../MyTeamPage'

export default function CallInRow({ data }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { DataEnum, TimeOffDataMap } = useMemo(() => getTimeOffApiData(), [])

  let formattedStartDate = useMemo(
    () =>
      formatDateString(
        TimeOffDataMap.get(DataEnum.StartDate).value(data),
        TimeOffDataMap.get(DataEnum.StartDate).format,
        LEADER_DATE_HEADER_FORMAT,
      ),
    [TimeOffDataMap, DataEnum.StartDate, data],
  )
  let formattedEndDate = useMemo(
    () =>
      formatDateString(
        TimeOffDataMap.get(DataEnum.EndDate).value(data),
        TimeOffDataMap.get(DataEnum.EndDate).format,
        LEADER_DATE_HEADER_FORMAT,
      ),
    [TimeOffDataMap, DataEnum.EndDate, data],
  )

  return (
    <ListItem disablePadding disableGutters sx={{ width: '100%', maxWidth: 640 }}>
      <ListItemButton
        sx={{ paddingX: '0px', paddingY: '3px' }}
        onClick={() => {
          dispatch(setBackToComponent(MY_TEAM_PAGE_PATH))
          navigate(`/leader/myteam/${CALL_IN_LATE}/${data.request_id}`, { state: { data } })
        }}
        data-cy={`TableContent-row-${data.request_id}`}
      >
        <ListItemIcon sx={{ justifyContent: 'center' }}>
          <RequestStatusIcon status={TimeOffDataMap.get(DataEnum.Status).value(data)} isTimeOffTeamMember={false} />
        </ListItemIcon>
        <ListItemText
          primary={`${
            formattedStartDate === formattedEndDate ? formattedStartDate : formattedStartDate + ' - ' + formattedEndDate
          }`}
          secondary={
            <>
              <Typography sx={{ display: 'block' }} component="span" variant="body2" color="palette.primary.dark">
                {TimeOffDataMap.get(DataEnum.FullName).value(data)}
              </Typography>
              <Typography sx={{ display: 'block' }} component="span" variant="body2" color="palette.primary.dark">
                {TimeOffDataMap.get(DataEnum.JobClassification).value(data)}
              </Typography>
              <Typography sx={{ display: 'block' }} component="span" variant="body2" color="palette.primary.dark">
                {TimeOffDataMap.get(DataEnum.Types).value(data)}
              </Typography>
              <Typography sx={{ display: 'block' }} component="span" variant="body2" color="palette.primary.dark">
                {'Est. Arrival ' + formatTime(TimeOffDataMap.get(DataEnum.EstimatedArrival).value(data))}
              </Typography>
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
