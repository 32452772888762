import moment from 'moment'
import { calculateMinutesInSpan, getDateTimeWithNoTimezone } from '../../../utils/DateUtil'

const DataEnum = {
  RequestId: 'requestId',
  PunchDate: 'punchDate',
  EndDate: 'endDate',
  LastUpdatedTimestamp: 'lastUpdatedTimestamp',
  LastUpdatedBy: 'lastUpdatedBy',
  CreatedTimestamp: 'createdTimestamp',
  CreatedBy: 'createdBy',
  Status: 'status',
  WorkerId: 'workerId',
  FullName: 'fullName',
  JobClassification: 'jobClassification',
  SchedulingKey: 'schedulingKey',
  Department: 'department',
  Statuses: 'statuses', //derived
  Types: 'types', //derived
  Duration: 'duration', //derived
  StartTimestamp: 'startTimestamp', //derived
  EndTimestamp: 'endTimestamp', //derived
  EstimatedArrival: 'estimatedArrival', //derived
  RequestDetailStartTimestamp: 'requestDetailStartTimestamp',
  RequestDetailEndTimestamp: 'requestDetailEndTimestamp',
  RequestDetailHours: 'requestDetailHours', //derived
  RequestDetailStatus: 'requestDetailStatus',
  RequestDetailType: 'requestDetailType',
  RequestDetails: 'requestDetails',
  ReasonForCorrection: 'reasonForCorrection',
}

const getRequestId = (data) => {
  return data?.request_id
}

const getPunchDate = (data) => {
  return data?.punch_date
}

const getEndDate = (data) => {
  return data?.end_date
}

const getLastUpdatedTimestamp = (data) => {
  return getDateTimeWithNoTimezone(data?.updated_timestamp)
}

const getLastUpdatedBy = (data) => {
  return data?.updated_worker_details?.short_name
}

const getCreatedTimestamp = (data) => {
  return getDateTimeWithNoTimezone(data?.created_timestamp)
}

const getCreatedBy = (data) => {
  return data?.created_by_worker_id
}

const getStatus = (data) => {
  return data?.status
}

const getWorkerId = (data) => {
  return data?.worker_details?.worker_id
}

const getFullName = (data) => {
  return data?.worker_details?.full_name
}

const getJobClassification = (data) => {
  return data?.worker_details?.job_classification
}

const getSchedulingKey = (data) => {
  return data?.worker_details?.scheduling_key
}

const getDepartment = (data) => {
  return data?.worker_details?.department
}

const getStatuses = (data) => {
  return data?.request_details?.map((detail) => detail?.status).join(', ')
}

const getTypes = (data) => {
  return data?.request_details?.map((detail) => detail?.new_punch_type).join(', ')
}

const getDuration = (data) => {
  let totalMinutes = data?.request_details
    ?.map((detail) => calculateMinutesInSpan(moment(detail.start_time), moment(detail.end_time)))
    .reduce((prev, next) => prev + next)
  return Math.floor(totalMinutes / 60) + ' hours ' + (totalMinutes % 60) + ' minutes '
}

const getStartTimestamp = (data) => {
  return getDateTimeWithNoTimezone(data?.request_details[0]?.start_time)
}

const getEndTimestamp = (data) => {
  return getDateTimeWithNoTimezone(data?.request_details[data?.total_request_details - 1]?.end_time)
}

const getRequestDetailStartTimestamp = (data, index) => {
  return getDateTimeWithNoTimezone(data?.request_details[index]?.start_time)
}

const getRequestDetailEndTimestamp = (data, index) => {
  return getDateTimeWithNoTimezone(data?.request_details[index]?.end_time)
}

const getRequestDetailHours = (data, index) => {
  let totalMinutes = calculateMinutesInSpan(
    moment(data?.request_details[index]?.start_time),
    moment(data?.request_details[index]?.end_time),
  )
  return totalMinutes / 60 + ' hours ' + (totalMinutes % 60) + ' minutes '
}

const getRequestDetailStatus = (data, index) => {
  return data?.request_details[index]?.status
}

const getRequestDetailType = (data, index) => {
  return data?.request_details[index]?.new_punch_type
}

const getRequestDetails = (data) => {
  return data?.request_details
}

const getReasonForCorrection = (data) => {
  return data?.reason_for_correction
}

const PunchCorrectionDataMap = new Map([
  [DataEnum.RequestId, { label: 'Request Id', value: getRequestId }],
  [DataEnum.PunchDate, { label: 'Punch Date', value: getPunchDate, format: 'YYYY-MM-DD' }],
  [DataEnum.EndDate, { label: 'End Date', value: getEndDate, format: 'YYYY-MM-DD' }],
  [
    DataEnum.LastUpdatedTimestamp,
    { label: 'Updated Timestamp', value: getLastUpdatedTimestamp, format: 'YYYY-MM-DDTHH:mm:ss' },
  ],
  [DataEnum.LastUpdatedBy, { label: 'Last Updated By', value: getLastUpdatedBy }],
  [
    DataEnum.CreatedTimestamp,
    { label: 'Created Timestamp', value: getCreatedTimestamp, format: 'YYYY-MM-DDTHH:mm:ss' },
  ],
  [DataEnum.CreatedBy, { label: 'Created By', value: getCreatedBy }],
  [DataEnum.WorkerId, { label: 'Worker Id', value: getWorkerId }],
  [DataEnum.FullName, { label: 'Full Name', value: getFullName }],
  [DataEnum.JobClassification, { label: 'Job', value: getJobClassification }],
  [DataEnum.SchedulingKey, { label: 'Scheduling Key', value: getSchedulingKey }],
  [DataEnum.Department, { label: 'Department', value: getDepartment }],
  [DataEnum.Statuses, { label: 'Statuses', value: getStatuses }],
  [DataEnum.Status, { label: 'Status', value: getStatus }],
  [DataEnum.Types, { label: 'Type', value: getTypes }],
  [DataEnum.Duration, { label: 'Duration', value: getDuration }],
  [DataEnum.StartTimestamp, { label: 'Start Timestamp', value: getStartTimestamp, format: 'YYYY-MM-DDTHH:mm:ss' }],
  [DataEnum.EndTimestamp, { label: 'End Timestamp', value: getEndTimestamp, format: 'YYYY-MM-DDTHH:mm:ss' }],
  [DataEnum.EstimatedArrival, { label: 'Estimated Arrival', value: getEndTimestamp, format: 'YYYY-MM-DDTHH:mm:ss' }],
  [
    DataEnum.RequestDetailStartTimestamp,
    { label: 'Request Detail start Timestamp', value: getRequestDetailStartTimestamp, format: 'YYYY-MM-DDTHH:mm:ss' },
  ],
  [
    DataEnum.RequestDetailEndTimestamp,
    { label: 'Request Detail End Timestamp', value: getRequestDetailEndTimestamp, format: 'YYYY-MM-DDTHH:mm:ss' },
  ],
  [DataEnum.RequestDetailHours, { label: 'Request detail Duration', value: getRequestDetailHours }],
  [DataEnum.RequestDetailStatus, { label: 'Request detail Status', value: getRequestDetailStatus }],
  [DataEnum.RequestDetailType, { label: 'Request detail Type', value: getRequestDetailType }],
  [DataEnum.RequestDetails, { label: 'Request Details', value: getRequestDetails }],
  [DataEnum.ReasonForCorrection, { label: 'Reason for correction', value: getReasonForCorrection }],
])

export const getPunchCorrectionApiData = () => {
  return { DataEnum, PunchCorrectionDataMap }
}
