import HomeIcon from '@mui/icons-material/Home'
import { Fab, useTheme } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import React from 'react'

function PrivacyPolicyPage() {
  const theme = useTheme()

  return (
    <React.Fragment>
      <div style={theme.mainContainerPosition}>
        <center>
          <Card sx={theme.mainScrollContainer}>
            <CardContent>
              <Typography variant="headline" component="h3">
                myTime Privacy Notice
              </Typography>
              <Typography variant="body2" style={{ marginTop: '10px' }}>
                This application is for Target Team Members to view and update information related to their schedules.
                It collects and uses your login information for authentication purposes and your device information
                (e.g. device model, operating system version, device date and time, unique device identifiers, mobile
                network information) for analysis of application usage. The application will send you push notifications
                related to your schedule. You may opt-out of push notifications by adjusting the permissions in your
                mobile device. All information may be collected, used and shared for the stated purposes as well as
                other business-related purposes such as internal operations and legal compliance.
              </Typography>
              <Typography variant="body2" style={{ marginTop: '10px' }}>
                California Residents: For state-specific information, please see the California Employment and
                Independent Contractor Privacy Notice at:
              </Typography>
              <div>https://corporate.target.com/about/contact-help#privacy</div>

              <Fab
                onClick={() => window.location.replace(window.location.origin)}
                title={'Reload'}
                aria-label={'Reload'}
                color="primary"
                style={{ marginTop: '10px' }}
              >
                <HomeIcon />
              </Fab>
            </CardContent>
          </Card>
        </center>
      </div>
    </React.Fragment>
  )
}

export default PrivacyPolicyPage
