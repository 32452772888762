import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

/**
 * In new praxis, Authorization is now automatically attached to any request going to *.target.com or *.tgt
 * DOCUMENTATION: https://praxis.prod.target.com/guides/authentication
 */
const getReasons = async () => {
  let apiUrl =
    `${apiConfig.configuration.url}` +
    '/configurations' +
    '?configuration_functionality=punch_correction' +
    '&configuration_type=reasons' +
    '&key=' +
    `${apiConfig.configuration.key}`

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  return data
}

//TODO: get startDate and ednDate from redux state?
export function useReasonsConfig() {
  const { isAuthenticated, login } = useAuth()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }
  return useQuery(['getReasons', {}], () => getReasons(), {
    staleTime: 1000 * 30,
    enabled: isAuthenticated(),
  })
}
