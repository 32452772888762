import { formatDateRangeString, formatDateString } from '../../../../../utils/DateUtil'
import { LEADER_DATE_HEADER_FORMAT, LEADER_TIMESTAMP_DETAIL_FORMAT } from '../../../../../constants/leaderConstants'
import { CALL_IN_LATE } from '../../../../../constants/RequestConstants'
import TimeOffDetailPage from '../../TimeOffDetailPage'
import { getLabelValueObj, getRequestStatusIcons } from '../../../../../utils/leaderUtil'

const getStatusIcon = (dataVO) => {
  return getRequestStatusIcons(dataVO?.requestDetails)
}

const getHeaderPrimary = (dataVO) => {
  return formatDateRangeString(dataVO.startDate, dataVO.endDate, dataVO.getStartDateFormat(), LEADER_DATE_HEADER_FORMAT)
}

const getHeaderSecondary = (dataVO) => {
  return [`${dataVO.fullName} (${dataVO.workerId})`]
}

const getLabelValueList = (dataVO) => {
  return [
    getLabelValueObj(dataVO.getStatusesLabel(), dataVO.statuses),
    getLabelValueObj(dataVO.getDurationLabel(), dataVO.duration),
    getLabelValueObj(
      dataVO.getStartTimestampLabel(),
      formatDateString(dataVO.startTimestamp, dataVO.getStartTimestampFormat(), LEADER_TIMESTAMP_DETAIL_FORMAT),
    ),
    getLabelValueObj(
      dataVO.getEstimatedArrivalLabel(),
      formatDateString(dataVO.estimatedArrival, dataVO.getEstimatedArrivalFormat(), LEADER_TIMESTAMP_DETAIL_FORMAT),
    ),
    getLabelValueObj(dataVO.getTypesLabel(), dataVO.types),
    getLabelValueObj(dataVO.getJobClassificationLabel(), dataVO.jobClassification),
    getLabelValueObj(
      dataVO.getCreatedTimestampLabel(),
      formatDateString(dataVO.createdTimestamp, dataVO.getCreatedTimestampFormat(), LEADER_TIMESTAMP_DETAIL_FORMAT),
    ),
    getLabelValueObj(
      dataVO.getLastUpdatedTimestampLabel(),
      formatDateString(
        dataVO.lastUpdatedTimestamp,
        dataVO.getLastUpdatedTimestampFormat(),
        LEADER_TIMESTAMP_DETAIL_FORMAT,
      ),
    ),
  ]
}

const CallInDetail = ({ dataVO }) => {
  return (
    <TimeOffDetailPage
      comments={dataVO.comments}
      requestId={dataVO.requestId}
      requestDetails={dataVO.requestDetails}
      statusIcon={getStatusIcon(dataVO)}
      headerPrimary={getHeaderPrimary(dataVO)}
      headerSecondary={getHeaderSecondary(dataVO)}
      category={CALL_IN_LATE}
      labelValueList={getLabelValueList(dataVO)}
    />
  )
}

export default CallInDetail
