export const formatHours = {
  format(num) {
    if (String(num).indexOf('.') > -1) {
      return `${this.prefixZeros(String(num).split('.')[0], 2)}.${this.sufixZeros(String(num).split('.')[1], 2)}`
    } else {
      return `${this.prefixZeros(num, 2)}.00`
    }
  },

  prefixZeros(num, pad) {
    return String(num).padStart(pad, 0)
  },
  sufixZeros(num, pad) {
    return String(num).padEnd(pad, 0)
  },
}
