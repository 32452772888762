/* * * * * * * * * * * * * * * *
Finally the custom hook is cooked here for use of handling onClick(), onDoubleClick() events using Cancelleble Promise wrapper, delay wrapper, promise api calls to update, remove, clear promises.

This is used in Confirm Shift in Avaible shifts modal window, Weekly Calendar prev, next weeks.
* * * * * * * * * * * * * * * */
import useCancellablePromises from './use-cancellable-promises'
import CancellablePromise, { delay } from '../utils/CancelablePromiseWithDelay'

const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
  const api = useCancellablePromises()

  const handleClick = () => {
    api.clearPendingPromises()
    const waitForClick = CancellablePromise(delay(300))
    api.appendPendingPromise(waitForClick)

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick)
        onClick()
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick)
        if (!errorInfo.isCanceled) {
          throw errorInfo.error
        }
      })
  }

  const handleDoubleClick = () => {
    api.clearPendingPromises()
    onDoubleClick()
  }

  return [handleClick, handleDoubleClick]
}

export default useClickPreventionOnDoubleClick
