/* * * * * * * * * * * * * * * *
Setting up functions like api calls to do certian activities like appending, removing, clearing promises
import { useRef } from 'react'
* * * * * * * * * * * * * * * */

import { useRef } from 'react'

const useCancellablePromises = () => {
  const pendingPromises = useRef([])

  const appendPendingPromise = (promise) => (pendingPromises.current = [...pendingPromises.current, promise])

  const removePendingPromise = (promise) =>
    (pendingPromises.current = pendingPromises.current.filter((p) => p !== promise))

  const clearPendingPromises = () => pendingPromises.current.map((p) => p.cancel())

  return {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  }
}

export default useCancellablePromises
