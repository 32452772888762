import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { togglePanelExpandCollapse } from '../../../../store/leader/actionCreator'

const ExpandCollapsePannelButton = () => {
  const dispatch = useDispatch()
  const isPanelExpanded = useSelector((state) => state.leader.isPanelExpanded)
  const expandCollapseAriaLabel = 'my team control panel inputs section'
  const handleExpandCollapse = () => {
    dispatch(togglePanelExpandCollapse())
  }

  return (
    <Grid item>
      <IconButton
        data-cy={'controlPanelExpandCollapseButton'}
        onClick={handleExpandCollapse}
        aria-label={`${isPanelExpanded ? 'collapse' : 'expand'} ${expandCollapseAriaLabel}`}
      >
        {isPanelExpanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    </Grid>
  )
}

export default ExpandCollapsePannelButton
