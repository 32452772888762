import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setConfirm } from '../store/layout/actionCreator'

export default function useConfirm(title, confirmText, rejectText) {
  const dispatch = useDispatch()
  const confirm = useSelector((state) => state.layout.confirm)

  const [needsCleanup, setNeedsCleanup] = useState(false)
  const isConfirmed = (prompt) => {
    setNeedsCleanup(true)
    const promise = new Promise((resolve, reject) => {
      dispatch(
        setConfirm({
          prompt,
          isOpen: true,
          proceed: resolve,
          cancel: reject,
          title: title,
          confirmText: confirmText,
          rejectText: rejectText,
        }),
      )
    })
    return promise.then(
      () => {
        dispatch(setConfirm({ ...confirm, isOpen: false }))
        return true
      },
      () => {
        dispatch(setConfirm({ ...confirm, isOpen: false }))
        return false
      },
    )
  }

  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel()
      }
    }
  }, [confirm, needsCleanup])

  return {
    ...confirm,
    isConfirmed,
  }
}
