import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { format } from 'date-fns'
import { useState } from 'react'
import { TIME_OFF_TYPES } from '../../../constants/RequestConstants'
import InnerLoader from '../../Loader/InnerLoader'

const SAVE_DIALOG_TITLE = 'Are you sure you want to save?'

const SAVE_DIALOG_TEXT = 'Punching in is still required when starting the shift.'

const SubmitCallInModal = ({
  submitDialogOpen,
  handleYesForSubmitAction,
  handleNoForSubmitAction,
  startTime,
  estimatedArrivalTime,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleYes = () => {
    if (buttonDisabled) return
    setButtonDisabled(true)
    handleYesForSubmitAction()
    setTimeout(() => {
      setButtonDisabled(false)
    }, 1000)
  }

  const handleNo = () => {
    handleNoForSubmitAction()
  }

  return (
    <Dialog
      data-cy="requestTimeOffSubmitDialog"
      aria-labelledby="alert-dialog-title"
      open={submitDialogOpen}
      aria-describedby="save-dialog-content"
      aria-modal={true}
      keepMounted
    >
      <DialogTitle id="alert-dialog-title">{SAVE_DIALOG_TITLE}</DialogTitle>
      <DialogContent id="save-dialog-content">
        <DialogContentText color={'textPrimary'}>
          You are submitting a <strong>{TIME_OFF_TYPES.CALL_IN_LATE}</strong> notification for your shift that starts at{' '}
          {format(startTime, 'h:mma')} on {format(startTime, 'MMMM d, yyyy')}.
        </DialogContentText>
        <DialogContentText color={'textPrimary'}>
          Your estimated arrival time is {format(estimatedArrivalTime, 'h:mma')} on{' '}
          {format(estimatedArrivalTime, 'MMMM d, yyyy')}.
        </DialogContentText>
        <DialogContentText id="save-dialog-notice" sx={{ marginTop: '20px' }}>
          {SAVE_DIALOG_TEXT}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          NO
        </Button>
        <Button onClick={handleYes} color="primary" variant="contained" aria-label="Yes" disabled={buttonDisabled}>
          {buttonDisabled ? <InnerLoader size={25} /> : 'YES'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubmitCallInModal
