export const SET_AUTOMATION_DIRECTORY_DATA_SELECTED_FILTERS = 'SET_AUTOMATION_DIRECTORY_DATA_SELECTED_FILTERS'
export const SET_AUTOMATION_DIRECTORY_DATA_LOADING = 'SET_AUTOMATION_DIRECTORY_DATA_LOADING'
export const SET_AUTOMATION_DIRECTORY_DATA_SAVING = 'SET_AUTOMATION_DIRECTORY_DATA_SAVING'
export const SET_AUTOMATION_DIRECTORY_DATA_SEARCH = 'SET_AUTOMATION_DIRECTORY_DATA_SEARCH'
export const SET_AUTOMATION_DIRECTORY_DATA_SORT = 'SET_AUTOMATION_DIRECTORY_DATA_SORT'
export const SET_AUTOMATION_DIRECTORY_DATA_PER_PAGE = 'SET_AUTOMATION_DIRECTORY_DATA_PER_PAGE'
export const SET_AUTOMATION_DIRECTORY_DATA_PAGE = 'SET_AUTOMATION_DIRECTORY_DATA_PAGE'
export const GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_ERROR =
  'GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_ERROR'
export const GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_SUCCESS =
  'GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_SUCCESS'
export const GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_ERROR =
  'GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_ERROR'
export const GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_SUCCESS =
  'GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_SUCCESS'
export const SET_AUTOMATION_DIRECTORY_DATA_TABLE_STATE_TO_DEFAULT =
  'SET_AUTOMATION_DIRECTORY_DATA_TABLE_STATE_TO_DEFAULT'
export const SET_AUTOMATION_DIRECTORY_DATA_TABLE_FILTERS_TO_DEFAULT =
  'SET_AUTOMATION_DIRECTORY_DATA_TABLE_FILTERS_TO_DEFAULT'
export const SET_AUTOMATION_DIRECTORY_DATA_TABLE_COLUMNS_TO_DEFAULT =
  'SET_AUTOMATION_DIRECTORY_DATA_TABLE_COLUMNS_TO_DEFAULT'
export const ADD_AUTOMATION_DIRECTORY_SESSION_DATA = 'ADD_AUTOMATION_DIRECTORY_SESSION_DATA'
export const AUTOMATION_DIRECTORY_HANDLE_POST_SUCCESS = 'AUTOMATION_DIRECTORY_HANDLE_POST_SUCCESS'
export const AUTOMATION_DIRECTORY_HANDLE_POST_ERROR = 'AUTOMATION_DIRECTORY_HANDLE_POST_ERROR'
export const AUTOMATION_DIRECTORY_SET_POST_RESPONSE = 'AUTOMATION_DIRECTORY_SET_POST_RESPONSE'
export const ADD_SELECTED_AUTOMATION_DIRECTORY_INDEXES = 'ADD_SELECTED_AUTOMATION_DIRECTORY_INDEXES'
export const REMOVE_SELECTED_AUTOMATION_DIRECTORY_INDEX = 'REMOVE_SELECTED_AUTOMATION_DIRECTORY_INDEX'
export const SET_AUTOMATION_DIRECTORY_POST_ERROR = 'SET_AUTOMATION_DIRECTORY_POST_ERROR'
export const DOWNLOAD_AUTOMATION_DIRECTORY_CSV_SUCCESS = 'DOWNLOAD_AUTOMATION_DIRECTORY_CSV_SUCCESS'
export const DOWNLOAD_AUTOMATION_DIRECTORY_CSV_ERROR = 'DOWNLOAD_AUTOMATION_DIRECTORY_CSV_ERROR'
export const AUTOMATION_DIRECTORY_SET_SAVE_DIALOG_OPEN = 'AUTOMATION_DIRECTORY_SET_SAVE_DIALOG_OPEN'
export const AUTOMATION_DIRECTORY_SET_DATA_COLUMN_VIEW = 'AUTOMATION_DIRECTORY_SET_DATA_COLUMN_VIEW'
export const GET_LEADER_AUTOMATION_PTO_REQUESTS_FILTERS_REQUEST_SUCCESS =
  'GET_LEADER_AUTOMATION_PTO_REQUESTS_FILTERS_REQUEST_SUCCESS'
export const MAKE_SCREEN_UNEDITABLE_FOR_ERLR = 'MAKE_SCREEN_UNEDITABLE_FOR_ERLR'
