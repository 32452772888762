import { Divider, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { formatHours } from '../../utils/formatHours'
import MissedPunchIcon from '../Icons/MissedPunchIcon'

const PunchesDaily = (props) => {
  const { punchSegments, styles, tmNotClockedIn } = props

  return (
    <React.Fragment>
      <Divider aria-hidden="true" />
      <div
        style={{ ...styles.paddedContainer, ...styles.headerStyles }}
        tabIndex={0}
        aria-label="punch details - table"
      >
        <Grid item xs={12} md={12} container>
          <Grid container>
            <Grid item xs={4} md={5}>
              <Typography variant="body2" sx={styles.sectionHeader1}>
                Punches
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant="body2" sx={styles.sectionHeader2}>
                In
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant="body2" sx={styles.sectionHeader2}>
                Out
              </Typography>
            </Grid>
            <Grid item xs={2} md={1}>
              <Typography variant="body2" sx={styles.sectionHeader2}>
                Hrs
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider aria-hidden="true" />
      <div style={styles.paddedContainer}>
        {punchSegments.map((punchSegment, index) => (
          <DailyPunchSegments punchSegment={punchSegment} styles={styles} key={index} />
        ))}
      </div>
      <Divider aria-hidden="true" />
      {!tmNotClockedIn ? <div style={styles.emptyDiv} /> : null}
    </React.Fragment>
  )
}

const DailyPunchSegments = (props) => {
  const { punchSegment, styles } = props
  const inputTimeFormat = 'YYYY-MM-DDThh:mm:ss'
  const outputTimeFormat = 'hh:mmA'

  const generateAriaLabel = () => {
    let job = punchSegment.job ? punchSegment.job : ''
    let startPunch = punchSegment.start_punch
      ? 'punched-in time: ' + moment(punchSegment.start_punch, inputTimeFormat)?.format(outputTimeFormat)
      : 'missed in-punch'
    let endPunch = punchSegment.end_punch
      ? 'punched-out time: ' + moment(punchSegment.end_punch, inputTimeFormat)?.format(outputTimeFormat)
      : 'missed out-punch'
    let totalHours = punchSegment.worked_hours
      ? 'total hours' + punchSegment.worked_hours + ' hours'
      : 'total hours zero hours'
    return `${job}, ${startPunch}, ${endPunch}, ${totalHours}`
  }

  return (
    <React.Fragment>
      <Grid tabIndex="0" role="heading" item xs={12} md={12} aria-label={generateAriaLabel()}>
        <Grid container>
          <Grid item xs={4} md={5} sx={styles.gridContainer}>
            <Typography variant="body2" sx={styles.segmentStyles1}>
              {punchSegment.job ? punchSegment.job : ' '}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3} sx={styles.gridContainer}>
            <Typography
              variant="body2"
              sx={{
                ...styles.segmentStyles2,
                ...styles.padTopCorrection,
                ...(punchSegment.start_punch ? {} : styles.paddingTop3),
              }}
            >
              {punchSegment.start_punch ? (
                moment(punchSegment.start_punch, inputTimeFormat)?.format(outputTimeFormat)
              ) : (
                <MissedPunchIcon punchType="in" />
              )}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3} sx={styles.gridContainer}>
            <Typography
              variant="body2"
              sx={{
                ...styles.segmentStyles2,
                ...styles.padTopCorrection,
                ...(punchSegment.end_punch ? {} : styles.paddingTop3),
              }}
            >
              {punchSegment.end_punch ? (
                moment(punchSegment.end_punch, inputTimeFormat)?.format(outputTimeFormat)
              ) : (
                <MissedPunchIcon punchType="out" />
              )}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} sx={styles.gridContainer}>
            <Typography variant="body2" sx={styles.segmentStyles2}>
              {formatHours?.format(punchSegment.worked_hours)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default PunchesDaily
