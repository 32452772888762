import ChevronLeft from '@mui/icons-material/ChevronLeft'
import IconButton from '@mui/material/IconButton'
import { isBefore } from 'date-fns'
import useClickPreventionOnDoubleClick from '../../hooks/use-click-prevention-on-double-click'

const CutomisedHookButton = ({ styles, minDate, startWeek, onClick, onDoubleClick }) => {
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick)

  return (
    <IconButton
      sx={styles.icon}
      data-cy="weekBackBtn"
      disabled={minDate && !isBefore(minDate, startWeek)}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      aria-label={'Go To Previous Week'}
      size="large"
      tabIndex={0}
    >
      <ChevronLeft />
    </IconButton>
  )
}

const PrevWeekButton = (props) => (
  <CutomisedHookButton
    onDoubleClick={props.handleDoubleClick}
    onClick={props.handleSubmit}
    minDate={props.minDate}
    startWeek={props.startWeek}
    styles={props.styles}
  />
)

export default PrevWeekButton
