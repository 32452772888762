import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { addDays, format } from 'date-fns'
import apiConfig from '../../config/apiConfig'
import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

const getSwapRequests = async (tmNumber, startDate, endDate) => {
  let apiUrl =
    `${apiConfig.shiftswap.url}` +
    '/shift_swap_requests' +
    '?worker_id=' +
    tmNumber +
    '&start_date=' +
    startDate +
    '&end_date=' +
    endDate +
    '&key=' +
    `${apiConfig.shiftswap.key}`

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  if (data.total_shift_swap_request_received > 0) {
    let shift_swap_request_received = data.shift_swap_request_received
    for (let element of shift_swap_request_received) {
      element['requester']['request_id'] = element.request_id
    }
  }

  return data
}

export function useSwapShiftRequests() {
  const { session, isAuthenticated, login } = useAuth()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  const todayDate = getDateOfTodayWithNoTimestamp()
  const dateFormat = 'yyyy-MM-dd'
  let startDate = format(todayDate, dateFormat)
  let endDate = format(addDays(todayDate, 6), dateFormat)

  return useQuery(
    ['swapShiftRequests', {}],
    () => getSwapRequests(padEmpIdWithZeros(session?.userInfo?.empid, 10), startDate, endDate),
    {
      staleTime: 1000 * 30, // 30 seconds of stale time
      enabled: isAuthenticated(),
    },
  )
}
