import { Grid, MenuItem, TextField, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeValue, setDayError } from '../../../../store/requestTimeOff/actionCreator'
import PTOCheckbox from '../../PTOCheckbox'
import { MoreRowActions } from '../RequestRow'
import PTOWithUnpaidRow from './PTOWithUnpaidRow'
import TimePicker from '../../../common/calendar/TimePicker'

export function TimeOffUnpaidRow({ dateKey }) {
  const dispatch = useDispatch()
  const day = useSelector((store) => store.requestTimeOff?.request[dateKey])
  const requestConfig = useSelector((store) => store.requestTimeOff.requestConfig)
  const formattedRowDate = format(new Date(dateKey), 'eeee, MMMM d')

  useEffect(() => {
    // check if daily limit of PTO exceeds configured maxPaidPerDay
    if (day?.requestDetails.length > 1 && !day.error) {
      const totalPaidMinutes = day?.requestDetails.reduce((agg, currentDetail) => {
        if (currentDetail?.usePTO) {
          return (agg += currentDetail?.ptoDetails?.minutes || 0)
        }
        return agg
      }, 0)
      if (totalPaidMinutes > requestConfig.content.maxPaidPerDay) {
        dispatch(
          setDayError(
            dateKey,
            `Request exceeds the daily max PTO limit of ${requestConfig.content.maxPaidPerDay / 60} hrs`,
          ),
        )
      }
    }
  }, [day, requestConfig.content.maxPaidPerDay, dateKey, dispatch])

  const onChange = (date, index, field, value) => {
    if (field === 'hours' || field === 'minutes') {
      const currentHours = Math.floor(day.requestDetails[index].minutes / 60)
      const currentMinutes = day.requestDetails[index].minutes % 60
      const newHours = field === 'hours' ? value : currentHours
      const newMinutes = field === 'minutes' ? value : currentMinutes
      const totalMinutes = newHours * 60 + newMinutes
      dispatch(changeValue(date, index, 'minutes', totalMinutes))
    } else {
      dispatch(changeValue(date, index, field, value))
    }
  }

  const renderMenuItemList = (itemArray) => {
    return itemArray?.map((item) => {
      return (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      )
    })
  }

  const createCypressTag = (componentName, index) => {
    return `requestTimeOffRowInput_${componentName}_${formattedRowDate}_${index}`
  }

  return day.requestDetails.map((rowDetail, index) => {
    const hours = Math.floor(rowDetail.minutes / 60)
    const minutes = rowDetail.minutes % 60
    const timeDurationError = rowDetail?.error?.minutes

    return (
      <Grid
        container
        justifyContent={'space-between'}
        key={index}
        paddingLeft={0.5}
        data-cy={`requestTimeOffDetail_${formattedRowDate}_${index}`}
      >
        {day?.allDayUnpaid ? (
          <></>
        ) : (
          <>
            <Grid item xs={4} paddingBottom={1}>
              <TimePicker
                id="startTime"
                label="Start Time"
                defaultValue={rowDetail.startTime}
                onChange={(startTime) => onChange(day.date, index, 'startTime', startTime)}
                minutesStep={requestConfig.content.unpaidStartTimeMinutesStep}
                slotProps={{
                  textField: {
                    error: !!rowDetail?.error?.startTime,
                    helperText: rowDetail?.error?.startTime,
                    name: 'startTime',
                    'data-cy': createCypressTag('startTime', index),
                  },
                }}
              />
            </Grid>
            <Grid container item xs={4} justifyContent={'center'}>
              <Grid container direction={'column'} marginLeft={2}>
                <Grid item container xs wrap="nowrap">
                  <TextField
                    sx={{ minWidth: '45px' }}
                    id="hours"
                    label="Hours"
                    name="hours"
                    error={!!timeDurationError}
                    select
                    value={hours}
                    onChange={(event) => onChange(day.date, index, 'hours', Number(event.target.value))}
                    data-cy={createCypressTag('hours', index)}
                  >
                    {renderMenuItemList(requestConfig?.content?.unpaidHourDropdownValues)}
                  </TextField>
                  <TextField
                    sx={{ minWidth: '45px' }}
                    id="Minutes"
                    label="Minutes"
                    name="minutes"
                    error={!!timeDurationError}
                    select
                    value={minutes}
                    onChange={(event) => onChange(day.date, index, 'minutes', Number(event.target.value))}
                    data-cy={createCypressTag('minutes', index)}
                  >
                    {renderMenuItemList(requestConfig?.content?.unpaidMinuteDropdownValues)}
                  </TextField>
                </Grid>
                <Grid item>
                  {!!timeDurationError && (
                    <Typography variant="caption" color={'primary'} data-cy={createCypressTag('timeError', index)}>
                      {timeDurationError}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container justifyContent={'right'} alignItems={'center'} xs={1.5}>
              <MoreRowActions
                numberOfDetailRows={day?.requestDetails?.length}
                dateKey={dateKey}
                rowDetailIndex={index}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} data-cy={createCypressTag('ptoCheckbox', index)}>
          <PTOCheckbox dateKey={dateKey} index={index} />
        </Grid>
        {rowDetail?.usePTO ? <PTOWithUnpaidRow dateKey={dateKey} index={index} withUnpaid={true} /> : <></>}
      </Grid>
    )
  })
}
