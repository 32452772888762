import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDateRangeAsStringFromDates } from '../../../utils/DateUtil'
import RequestCancelModal from '../RequestCancelModal'
import RequestSubmitModal from '../RequestSubmitModal'

const RequestPaidTimeOffSubmitCancel = (props) => {
  const {
    showCancelDialog,
    disableSubmit,
    setNavigateToMyRequests,
    handleSubmitRequest,
    requestedHours,
    requestedMinutes,
    selectedStartDate,
    selectedEndDate,
  } = props

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
  const [requestDateRange, setRequestedDateRange] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      setRequestedDateRange(getDateRangeAsStringFromDates(selectedStartDate, selectedEndDate))
    }
  }, [selectedStartDate, selectedEndDate])

  const handleCancel = () => {
    if (showCancelDialog) {
      setCancelDialogOpen(true)
    } else {
      setNavigateToMyRequests(true)
    }
  }

  const handleYesForSubmitAction = () => {
    setSubmitDialogOpen(false)
    handleSubmitRequest()
  }

  const handleNoForSubmitAction = () => {
    setSubmitDialogOpen(false)
  }

  const handleYesForCancelAction = () => {
    setCancelDialogOpen(false)
    navigate(-1)
  }

  const handleNoForCancelAction = () => {
    setCancelDialogOpen(false)
  }

  return (
    <React.Fragment>
      <Button data-cy="requestTimeOffCancel" sx={{ margin: '10px' }} onClick={handleCancel} color="primary">
        Cancel
      </Button>
      <Button
        data-cy="requestTimeOffSubmit"
        sx={{ margin: '10px' }}
        variant="contained"
        disabled={disableSubmit}
        onClick={() => {
          setSubmitDialogOpen(true)
        }}
        color="primary"
      >
        Submit
      </Button>

      <RequestCancelModal
        cancelDialogOpen={cancelDialogOpen}
        handleYesForCancelAction={handleYesForCancelAction}
        handleNoForCancelAction={handleNoForCancelAction}
      />
      <RequestSubmitModal
        submitDialogOpen={submitDialogOpen}
        handleYesForSubmitAction={handleYesForSubmitAction}
        handleNoForSubmitAction={handleNoForSubmitAction}
        requestedHours={requestedHours}
        requestedMinutes={requestedMinutes}
        requestDateRange={requestDateRange}
        showAccrualMessage={true}
      />
    </React.Fragment>
  )
}

export default RequestPaidTimeOffSubmitCancel
