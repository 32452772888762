import { useAuth } from '@praxis/component-auth'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { FeedbackResponse } from '../../components/Feedback/hooks/useFeedback'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

const postFeedbackResponse = async (newFeedback: {
  worker_id: string
  location_id: string
  persona: string
  rating: number
  responses: FeedbackResponse[]
}) => {
  let apiUrl = `${apiConfig.feedback.url}/question_responses?key=${apiConfig.location.key}`

  return await axios.post(apiUrl, newFeedback, BASIC_REQUEST_CONFIG)
}

export function usePostFeedbackResponse() {
  const { isAuthenticated, login } = useAuth()
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useMutation(postFeedbackResponse)
}
