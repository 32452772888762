import { createRoot } from 'react-dom/client'
import App from './components/App'

const rootEl = document.getElementById('root')
const root = createRoot(rootEl)

root.render(<App />)

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default
    root.render(<NextApp />, rootEl)
  })
}
