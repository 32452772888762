import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { useState } from 'react'
import CommentBox from './CommentBox'

const CommentHistoryDialog = ({ comments, open, closeDialog }) => {
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="comment-history-dialog"
      data-cy={'detailPageContent-commentHistory'}
    >
      <DialogTitle id="comment-history-dialog-title">Comment History</DialogTitle>
      <DialogContent>
        <CommentBox comments={comments} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" size="small" onClick={closeDialog}>
          EXIT
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CommentHistory = ({ comments }) => {
  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Button onClick={openDialog}>{`View Comment History (${comments.length} total)`}</Button>
      </Grid>
      <CommentHistoryDialog comments={comments} open={open} closeDialog={closeDialog} />
    </>
  )
}

export default CommentHistory
