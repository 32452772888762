import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { Link } from 'react-router-dom'

import AvailabilityIcon from '@mui/icons-material/EventAvailable'
import HomeIcon from '@mui/icons-material/Home'
import PersonAdd from '@mui/icons-material/PersonAdd'
import ScheduleIcon from '@mui/icons-material/Today'
import { useTheme } from '@mui/material'
import MyProtectedElement from '../../auth/MyProtectedElement'
import { useRouteMatch } from '../../hooks/useRouteMatch'
import CustomAvailableShiftsIcon from '../Icons/CustomAvailableShiftsIcon'
import TimecardIcon from '../Icons/TimecardIcon'

const getStyles = (theme) => ({
  bottomNavigationRoot: {
    height: 'auto',
    backgroundColor: theme.palette.tertiary.light,
    flex: '0 0 auto',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '10px',
    },
  },
  bottomNavigationActionRoot: {
    minWidth: 'auto',
    padding: '12px 2px',
  },
})

const BottomNavigationBar = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const pathMap = [
    '/team-member/home',
    '/team-member/schedule',
    '/team-member/tmOptionalAvailability',
    '/team-member/availableShifts',
    '/team-member/timecardWeekly',
    '/team-member/requests',
  ]
  const routeMatch = useRouteMatch(pathMap)
  const currentTab = routeMatch?.pattern?.path

  return (
    <BottomNavigation sx={styles.bottomNavigationRoot} value={currentTab} aria-label="Bottom Navigation Bar">
      <MyProtectedElement allowed={['schedule', 'timecard']} renderUnauthorized={() => <></>}>
        <BottomNavigationAction
          sx={styles.bottomNavigationActionRoot}
          component={Link}
          to={pathMap[0]}
          data-cy="homeBtnBottomNav"
          icon={<HomeIcon color={currentTab !== pathMap[0] ? 'inherit' : 'primary'} />}
          aria-selected={currentTab === pathMap[0]}
          aria-label={'Home'}
        />
      </MyProtectedElement>
      <MyProtectedElement allowed={['schedule']} renderUnauthorized={() => <></>}>
        <BottomNavigationAction
          sx={styles.bottomNavigationActionRoot}
          component={Link}
          to={pathMap[1]}
          data-cy="scheduleBtnBottomNav"
          icon={<ScheduleIcon color={currentTab !== pathMap[1] ? 'inherit' : 'primary'} />}
          aria-selected={currentTab === pathMap[1]}
          aria-label={'My Schedule'}
        />
      </MyProtectedElement>
      <MyProtectedElement allowed={['open_shifts', 'request_to_cover']} renderUnauthorized={() => <></>}>
        <BottomNavigationAction
          sx={styles.bottomNavigationActionRoot}
          component={Link}
          to={pathMap[3]}
          data-cy="avlShiftsBtnBottomNav"
          icon={<CustomAvailableShiftsIcon color={currentTab !== pathMap[3] ? '#666' : '#cc0000'} />}
          aria-selected={currentTab === pathMap[3]}
          aria-label={'My Available Shifts'}
        />
      </MyProtectedElement>
      <MyProtectedElement allowed={['voluntary_availability']} renderUnauthorized={() => <></>}>
        <BottomNavigationAction
          sx={styles.bottomNavigationActionRoot}
          component={Link}
          to={pathMap[2]}
          data-cy="voluntaryBtnBottomNav"
          icon={<AvailabilityIcon color={currentTab !== pathMap[2] ? 'inherit' : 'primary'} />}
          aria-selected={pathMap === pathMap[2]}
          aria-label={'My Availability'}
        />
      </MyProtectedElement>
      <MyProtectedElement allowed={['timecard']} renderUnauthorized={() => <></>}>
        <BottomNavigationAction
          sx={styles.bottomNavigationActionRoot}
          component={Link}
          to={pathMap[4]}
          data-cy="timecardsBtnBottomNav"
          icon={<TimecardIcon color={currentTab !== pathMap[4] ? '#666' : '#cc0000'} />}
          aria-selected={currentTab === pathMap[4]}
          aria-label={'My Timecard'}
        />
      </MyProtectedElement>
      <MyProtectedElement
        allowed={['time_off', 'call_in', 'call_off', 'availability', 'punch_correction']}
        renderUnauthorized={() => <></>}
        checkCompensable={false}
      >
        <BottomNavigationAction
          sx={styles.bottomNavigationActionRoot}
          component={Link}
          to={pathMap[5]}
          data-cy="requestsBtnBottomNav"
          icon={<PersonAdd color={currentTab !== pathMap[5] ? 'inherit' : 'primary'} />}
          aria-selected={currentTab === pathMap[5]}
          aria-label={'My Requests'}
        />
      </MyProtectedElement>
    </BottomNavigation>
  )
}

export default BottomNavigationBar
