import { Box, Grid, Rating, Typography } from '@mui/material'
import { OmitMultiple } from '../../utils/typescript'
import { StarRatingProps } from './hooks/useStarRating'

type AdditionalStarRatingProps = {
  title: string
}

// Use intersection type to combine them
type CombinedStarRatingProps = StarRatingProps & AdditionalStarRatingProps

const StarRating = ({
  rating,
  handleRating,
  ratingError,
  title,
}: Omit<CombinedStarRatingProps, 'handleRatingError'>) => {
  return (
    <Grid container item xs={12} alignItems="center" direction={'column'}>
      <Typography variant="h3" fontSize={16} paddingBottom={1}>
        {title}
      </Typography>
      <Stars rating={rating} handleRating={handleRating} />
      {ratingError && (
        <Typography marginBottom={2} color={'primary'}>
          Please select a star rating.
        </Typography>
      )}
    </Grid>
  )
}

type StarsProps = OmitMultiple<StarRatingProps, 'ratingError' | 'handleRatingError'>

const Stars: React.FC<StarsProps> = ({ rating, handleRating }) => {
  const handleRatingChange = (event: React.ChangeEvent<{}>, newValue: number | null) => {
    handleRating(newValue ?? rating)
  }

  return (
    <Box component="fieldset" marginBottom={1} borderColor="transparent">
      <Rating name="rating" value={rating} onChange={handleRatingChange} />
    </Box>
  )
}

export default StarRating
