import Fade from '@mui/material/Fade'
import Snackbar from '@mui/material/Snackbar'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from '../../store/notification/actionCreator'
import { useTheme } from '@mui/material'

const getStyles = (theme) => ({
  container: {
    position: 'absolute',
    margin: '0 auto',
    maxWidth: '640px',
    bottom: '50px !important',
    [theme.breakpoints.down('md')]: {
      bottom: '60px !important',
    },
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#ffffff',
  },
  closeButton: {
    color: '#ffffff',
  },
})

const Notifications = () => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const notificationIsShown = useSelector((state) => state.notification.isShown)
  const notificationMessage = useSelector((state) => state.notification.message)
  const dispatch = useDispatch()

  const handleRequestClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(showNotification(false))
  }

  return (
    <Snackbar
      data-cy="notification"
      open={notificationIsShown}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={<span style={styles.message}>{notificationMessage}</span>}
      onClose={handleRequestClose}
      autoHideDuration={null}
      TransitionComponent={Fade}
      sx={styles.container}
    />
  )
}

export default Notifications
