import PersonAdd from '@mui/icons-material/PersonAdd'
import { MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const styles = {
  menuIcon: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  menuText: {
    marginLeft: '5px',
  },
}

const ITEM_TEXT = 'Call In - Late'

const CallInAction = (props) => {
  const { handleCallInClose, menuKey, scheduleDate, displaySegment } = props
  const navigate = useNavigate()

  const handleCallInClick = () => {
    navigate('/team-member/requests/time_off/callin', {
      state: {
        segmentStart: displaySegment.segment_start,
        segmentEnd: displaySegment.segment_end,
        scheduleDate: scheduleDate,
      },
    })
    handleCallInClose(null)
  }

  return (
    <MenuItem key={menuKey} value={ITEM_TEXT} onClick={handleCallInClick}>
      <div style={styles.menuIcon}>
        <PersonAdd color="primary" />
      </div>
      <div style={styles.menuText}>{ITEM_TEXT}</div>
    </MenuItem>
  )
}
export default CallInAction
