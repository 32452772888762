import Preview from '@mui/icons-material/Preview'
import { Button } from '@mui/material'
import { useState } from 'react'
import CommunicationDialog from '../../common/dialog/CommunicationDialog'

export default function CommunicationDialogPreview({ communication }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleClickOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <div>
      <Button variant="outlined" aria-label="Preview" color="primary" type="button" onClick={handleClickOpen}>
        <Preview sx={{ marginRight: '5px' }} />
        Preview
      </Button>
      <CommunicationDialog
        handleBack={handleClose}
        handleClose={handleClose}
        handleNext={handleClose}
        activeStep={0}
        data={{ total_communications: 1, communications: [communication] }}
        isOpen={isOpen}
      />
    </div>
  )
}
