import { Info } from '@mui/icons-material'
import { Box, useTheme } from '@mui/material'
import HeaderTitle from '../Header/HeaderTitle'

const getStyles = (theme) => ({
  mainContainerPosition: theme.mainContainerPosition,
  infoMessage: {
    ...theme.infoMessages,
    padding: '0px 16px 0px 16px',
  },
  infoIcon: {
    marginRight: '4px',
    fontSize: '12px',
  },
})

const LeaderNotPunchedIn = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  return (
    <Box sx={styles.mainContainerPosition}>
      <HeaderTitle title={'Leader'} />
      <Box component={'p'} sx={styles.infoMessage}>
        <Info sx={styles.infoIcon} />
        You must be on the clock to access Leader View. If you need to access this screen, please clock in to enable
        Leader View.
      </Box>
    </Box>
  )
}

export default LeaderNotPunchedIn
