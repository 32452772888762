export const truncateString = (str, num) => (str.length > num ? str.slice(0, num) + '...' : str)

export function toTitleCase(str) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export function toSnakeCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2') // Add underscore before each capital letter followed by a lowercase letter
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2') // Add underscore before a capital letter that is followed by a lowercase letter and comes after a sequence of capital letters
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .toLowerCase() // Convert the entire string to lowercase
}

export function locationIsValid(location) {
  return /^([T,t]?\d{1,5})$/.test(location)
}

export function hrsToHours(str) {
  return str.replace(/ hrs/gi, ' Hours')
}

export function pluralize(number, type, capitalize = false) {
  let result = number === 1 ? `${number} ${type}` : `${number} ${type}s`
  if (capitalize) {
    result = result.charAt(0).toUpperCase() + result.slice(1)
  }
  return result
}

export function getOrdinalNumber(number) {
  let suffix = 'th'
  if (number % 100 < 11 || number % 100 > 13) {
    switch (number % 10) {
      case 1:
        suffix = 'st'
        break
      case 2:
        suffix = 'nd'
        break
      case 3:
        suffix = 'rd'
        break
      default:
        break
    }
  }
  return `${number}${suffix}`
}

export const charRegex = /^[0-9A-Za-z.,!/_\-wáÁéÉíÍóÓúÚñÑ ]*$/
export const emojiRegex = /^\p{Emoji}$/u
