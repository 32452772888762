import { IconButton, Typography } from '@mui/material'
import Popover from '@mui/material/Popover'
import { useState } from 'react'

export default function IconButtonPopover({ icon, ariaLabel, popOverMessage }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        sx={{ padding: 0, paddingRight: '5px', color: 'primary.dark' }}
        onClick={handlePopoverOpen}
        aria-label={ariaLabel}
      >
        {icon}
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ m: 1 }}>{popOverMessage}</Typography>
      </Popover>
    </>
  )
}
