import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import {
  CLOSE_TIME_OFF_DIALOG,
  GET_PTO_REQUESTS_FILTERS_REQUEST_ERROR,
  GET_PTO_REQUESTS_FILTERS_REQUEST_SUCCESS,
  GET_PTO_REQUESTS_REQUEST_ERROR,
  GET_PTO_REQUESTS_REQUEST_SUCCESS,
  LEADER_TIME_OFF_DECISION_ERROR,
  LEADER_TIME_OFF_DECISION_SUCCESS,
  OPEN_TIME_OFF_DIALOG,
  SET_LEADER_TIME_OFF_DATA_ERROR,
  SET_LEADER_TIME_OFF_DATA_RESPONSE,
  SET_LEADER_TIME_OFF_DATA_SAVING,
  SET_PTO_REQUESTS_DATA_COLUMN_VIEW,
  SET_PTO_REQUESTS_DATA_LOADING,
  SET_PTO_REQUESTS_DATA_PAGE,
  SET_PTO_REQUESTS_DATA_PER_PAGE,
  SET_PTO_REQUESTS_DATA_SEARCH,
  SET_PTO_REQUESTS_DATA_SELECTED_FILTERS,
  SET_PTO_REQUESTS_DATA_SORT,
  SET_PTO_REQUESTS_DATA_TABLE_COLUMNS_TO_DEFAULT,
  SET_PTO_REQUESTS_DATA_TABLE_FILTERS_TO_DEFAULT,
  SET_PTO_REQUESTS_DATA_TABLE_STATE_TO_DEFAULT,
} from './actionType'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function setPTORequestsDataTableColumnsToDefault() {
  return {
    type: SET_PTO_REQUESTS_DATA_TABLE_COLUMNS_TO_DEFAULT,
  }
}

export function setPTORequestsDataTableStateToDefault() {
  return {
    type: SET_PTO_REQUESTS_DATA_TABLE_STATE_TO_DEFAULT,
  }
}

export function setPTORequestsDataLoading(loading, lastSearchRequest, lastSelectedFilters) {
  return {
    type: SET_PTO_REQUESTS_DATA_LOADING,
    payload: loading,
    search: lastSearchRequest,
    selectedFilters: lastSelectedFilters,
  }
}

export function setPTORequestsDataTableFiltersToDefault(userGroup) {
  return {
    type: SET_PTO_REQUESTS_DATA_TABLE_FILTERS_TO_DEFAULT,
    userGroup: userGroup,
  }
}

export function setPTORequestsDataPerPage(perPage) {
  return {
    type: SET_PTO_REQUESTS_DATA_PER_PAGE,
    payload: perPage,
  }
}

export function setPTORequestsDataPage(page) {
  return {
    type: SET_PTO_REQUESTS_DATA_PAGE,
    payload: page,
  }
}

export function loadGetWorkersPTORequestsRequest(query_params) {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {}, // this is an issue with axios. empty data needed so Content-Type isn't removed from request
  }
  return (dispatch) => {
    let apiUrl = `${apiConfig.timeOff.url}` + `/leader_time_off_decisions/` + query_params
    return axios
      .get(apiUrl, requestConfig)
      .then((response) => {
        dispatch(handleGetPTORequestsSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetPTORequestsError(response))
      })
  }
}

export function handleGetPTORequestsSuccess(record) {
  return {
    type: GET_PTO_REQUESTS_REQUEST_SUCCESS,
    record,
  }
}

export function handleGetPTORequestsError(record) {
  return {
    type: GET_PTO_REQUESTS_REQUEST_ERROR,
    record,
  }
}

export function openTimeOffRequestDialog(record) {
  return {
    type: OPEN_TIME_OFF_DIALOG,
    record,
  }
}

export function closeTimeOffRequestDialog() {
  return {
    type: CLOSE_TIME_OFF_DIALOG,
  }
}

export function setPTORequestsDataSelectedFilters(filters) {
  return {
    type: SET_PTO_REQUESTS_DATA_SELECTED_FILTERS,
    payload: filters,
  }
}

export function setPTORequestsDataSort(sort, sortByColumn) {
  return {
    type: SET_PTO_REQUESTS_DATA_SORT,
    payload: sort,
    column: sortByColumn,
  }
}

export function setPTORequestsColumnView(column, columnAction) {
  return {
    type: SET_PTO_REQUESTS_DATA_COLUMN_VIEW,
    column: column,
    columnAction: columnAction,
  }
}

export function loadGetLeaderPTORequestFilters(locationId) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.configuration.url}` +
      '/team_member_enablement_filters' +
      '?location_id=' +
      locationId +
      '&feature=time_off' +
      '&location_type=DC' +
      '&key=' +
      `${apiConfig.configuration.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetLeaderPTORequestsFiltersSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetLeaderPTORequestsFiltersError(response))
      })
  }
}

export function handleGetLeaderPTORequestsFiltersSuccess(record) {
  return {
    type: GET_PTO_REQUESTS_FILTERS_REQUEST_SUCCESS,
    record,
  }
}

export function handleGetLeaderPTORequestsFiltersError(record) {
  return {
    type: GET_PTO_REQUESTS_FILTERS_REQUEST_ERROR,
    record,
  }
}

export function setPTORequestsDataSearch(search) {
  return {
    type: SET_PTO_REQUESTS_DATA_SEARCH,
    payload: search,
  }
}

export function updateLeaderTimeOffDecisions(body) {
  return (dispatch) => {
    let apiUrl = `${apiConfig.timeOff.url}` + '/time_off_request/statuses?key=' + `${apiConfig.availability.key}`

    return axios
      .post(apiUrl, body, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleUpdateLeaderTimeOffDecisionsSuccess(response))
        dispatch(closeTimeOffRequestDialog())
      })
      .catch((response) => {
        dispatch(handleUpdateLeaderTimeOffDecisionsError(response))
        dispatch(closeTimeOffRequestDialog())
      })
  }
}

function handleUpdateLeaderTimeOffDecisionsSuccess(record) {
  return {
    type: LEADER_TIME_OFF_DECISION_SUCCESS,
    record,
  }
}

function handleUpdateLeaderTimeOffDecisionsError(record) {
  return {
    type: LEADER_TIME_OFF_DECISION_ERROR,
    record,
  }
}

export function setTimeOffRequestDataSaving(isSaving) {
  return {
    type: SET_LEADER_TIME_OFF_DATA_SAVING,
    isSaving,
  }
}

export function setTimeOffRequestDataError(error) {
  return {
    type: SET_LEADER_TIME_OFF_DATA_ERROR,
    error,
  }
}

export function setTimeOffRequestDataResponse(response) {
  return {
    type: SET_LEADER_TIME_OFF_DATA_RESPONSE,
    response,
  }
}
