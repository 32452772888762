import PersonAdd from '@mui/icons-material/PersonAdd'
import { MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const styles = {
  menuIcon: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  menuText: {
    marginLeft: '5px',
  },
}

const ITEM_TEXT = 'Call Off - Absent'
const CallOffAction = (props) => {
  const { handleCallOffClose, menuKey, scheduleDate, displaySegment } = props
  const navigate = useNavigate()

  const handleCallOffClick = () => {
    navigate('/team-member/requests/time_off/calloff', {
      state: {
        segmentStart: displaySegment.segment_start,
        segmentEnd: displaySegment.segment_end,
        scheduleDate: scheduleDate,
      },
    })
    handleCallOffClose(null)
  }

  return (
    <MenuItem key={menuKey} value={ITEM_TEXT} onClick={handleCallOffClick}>
      <div style={styles.menuIcon}>
        <PersonAdd color="primary" />
      </div>
      <div style={styles.menuText}>{ITEM_TEXT}</div>
    </MenuItem>
  )
}
export default CallOffAction
