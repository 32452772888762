import {
  DAILY_SCHEDULE_GET_ERROR,
  DAILY_SCHEDULE_GET_SUCCESS,
  SET_DAILY_SCHEDULE_LOADING,
  SET_DAILY_SCHEDULE_SELECTED_DATE,
} from './actionType'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { format } from 'date-fns'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function handleDailyScheduleGetData(tmNumber, selectedDate, locationId) {
  const dateFormat = 'yyyy-MM-dd'
  let scheduleDate = format(selectedDate, dateFormat)

  return (dispatch) => {
    let apiUrl =
      `${apiConfig.schedule.url}` +
      '/daily_schedules' +
      '?team_member_number=' +
      tmNumber +
      '&schedule_date=' +
      scheduleDate +
      '&location_id=' +
      locationId +
      '&key=' +
      `${apiConfig.schedule.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleSuccess(response))
      })
      .catch((response) => {
        dispatch(handleError(response))
      })
  }
}

export function handleError(record) {
  return {
    type: DAILY_SCHEDULE_GET_ERROR,
    record,
  }
}

export function handleSuccess(record) {
  return {
    type: DAILY_SCHEDULE_GET_SUCCESS,
    record,
  }
}

export function setLoading(text) {
  return {
    type: SET_DAILY_SCHEDULE_LOADING,
    payload: text,
  }
}

export function setDailyScheduleSelectedDate(date) {
  return {
    type: SET_DAILY_SCHEDULE_SELECTED_DATE,
    date,
  }
}
