import { Paper, useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import CategoryDetailDataSection from './CategoryDetailDataSection'
import CommentSection from './CommentSection'
import TimeOffDetailActionSection from './TimeOffDetailActionSection'
import CategorySegmentDetailSection from './CategorySegmentDetailSection'
import { isMultipleRequestDetails } from '../../../utils/leaderUtil'

const getStyles = (theme) => ({
  readContentContainer: {
    flex: '1 1 auto',
    overflowY: 'auto',
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
    '@media (orientation: portrait) and (min-width: 640px)': {
      marginTop: '20px',
    },
    '@media (orientation: landscape) and (max-height: 400px)': {
      maxWidth: '900px',
    },
  },
  actionContentContainer: {
    ...theme.mainScrollContainer,
    maxHeight: '20%',
    '@media (orientation: portrait) and (min-width: 640px)': {
      marginBottom: '20px',
    },
    '@media (orientation: landscape) and (max-height: 400px)': {
      maxWidth: '900px',
      maxHeight: '50%',
    },
  },
})

const TimeOffDetailPage = ({
  requestId,
  requestDetails,
  comments,
  category,
  statusIcon,
  headerPrimary,
  headerSecondary,
  labelValueList,
  segmentLabelValueList,
  segmentSectionInfoLabel,
  segmentSectionInfoMessage,
  actionSectionInfoMessage,
}) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  return (
    <>
      <Paper sx={styles.readContentContainer} elevation={1} square data-cy={'detailPageContent-detailPage'}>
        <CategoryDetailDataSection
          statusIcon={statusIcon}
          headerPrimary={headerPrimary}
          headerSecondary={headerSecondary}
          labelValueList={labelValueList}
        />
        {isMultipleRequestDetails(requestDetails) && segmentLabelValueList && (
          <CategorySegmentDetailSection
            labelValueList={segmentLabelValueList}
            chipLabel={segmentSectionInfoLabel}
            chipMessage={segmentSectionInfoMessage}
          />
        )}
        <CommentSection comments={comments} />
      </Paper>
      <Paper
        sx={styles.actionContentContainer}
        elevation={1}
        square
        component="h3"
        aria-label={'Update status section'}
      >
        <Divider aria-hidden="true" />
        <TimeOffDetailActionSection
          requestDetails={requestDetails}
          requestId={requestId}
          selectedCategory={category}
          infoMessage={actionSectionInfoMessage}
        />
      </Paper>
    </>
  )
}

export default TimeOffDetailPage
