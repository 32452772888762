import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { format } from 'date-fns'
import apiConfig from '../../config/apiConfig'
import { getStartEndDatesOfWeek, isDateInCurrentWeek } from '../../utils/DateUtil'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

/**
 * In new praxis, Authorization is now automatically attached to any request going to *.target.com or *.tgt
 * DOCUMENTATION: https://praxis.prod.target.com/guides/authentication
 */
const getTimecardWeekly = async (tmNumber, locationId, selectedDate) => {
  let { startDate, endDate } = getStartEndDatesOfWeek(selectedDate)

  if (isDateInCurrentWeek(selectedDate)) {
    endDate = format(selectedDate, 'yyyy-MM-dd')
  }

  let apiUrl =
    `${apiConfig.timecards.url}` +
    '/timecard_weekly_view' +
    '?end_date=' +
    endDate +
    '&location_id=' +
    locationId +
    '&start_date=' +
    startDate +
    '&worker_id=' +
    tmNumber +
    '&key=' +
    `${apiConfig.timecards.key}`

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  return data
}

//TODO: get startDate and ednDate from redux state?
export function useTimecardWeekly(selectedDate) {
  const { session, isAuthenticated, login } = useAuth()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }
  return useQuery(
    ['timecardWeekly', { selectedDate }],
    () => getTimecardWeekly(padEmpIdWithZeros(session?.userInfo?.empid, 10), session.userInfo.locationid, selectedDate),
    {
      staleTime: 1000 * 30,
      enabled: !!selectedDate && isAuthenticated(),
    },
  )
}
