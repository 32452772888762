import { addDays, areIntervalsOverlapping, parseISO } from 'date-fns'
import { extractTime, toIsoStringWithoutTime } from '../../../utils/DateUtil'

const NO_CHANGES_DETECTED_ERROR_MESSAGE =
  'No changes detected. You must have at least one punch correction to submit the request.'
const INCOMPLETE_PUNCHES_ERROR_MESSAGE = 'You have unresolved errors on the screen. Please resolve before submitting.'

export const validateOverlaps = (dayPunches) => {
  let copyOfDayPunches = [...dayPunches]

  let newArr = orderByPunchInTime(copyOfDayPunches)
  for (let i = 0; i < newArr.length; i++) {
    newArr[i].isOverlapped = false
  }
  for (let i = 0; i < newArr.length; i++) {
    if (i < newArr.length - 1) {
      if (checkOverlapping(newArr[i], newArr[i + 1])) {
        newArr[i].isOverlapped = newArr[i + 1].isOverlapped = true
        newArr[i].status = newArr[i + 1].status = true
      }
    }
  }
}

export const orderByPunchInTime = (punches) => {
  return punches.sort((a, b) => {
    let thisPunchInTime = parseISO(new Date(a.punchInTime).toISOString().slice(0, -1))
    let nextPunchInTime = parseISO(new Date(b.punchInTime).toISOString().slice(0, -1))
    return thisPunchInTime - nextPunchInTime
  })
}

export const checkOverlapping = (thisPunchSegment, nextPunchSegment) => {
  let overlap = false
  let thisSegment = { ...thisPunchSegment }
  let nextSegment = { ...nextPunchSegment }

  let noMissedPunchFound =
    thisSegment.punchInTime !== null &&
    thisSegment.punchOutTime !== null &&
    nextSegment.punchInTime !== null &&
    nextSegment.punchOutTime !== null

  if (noMissedPunchFound) {
    if (
      thisSegment.existingPunchOutTime === null &&
      thisSegment.punchInTime.getHours() >= 12 &&
      thisSegment.punchOutTime.getHours() < 12
    ) {
      thisSegment.punchOutTime = addDays(new Date(thisSegment.punchOutTime), 1)
    }
    if (
      nextSegment.existingPunchOutTime === null &&
      nextSegment.punchInTime.getHours() >= 12 &&
      nextSegment.punchOutTime.getHours() < 12
    ) {
      nextSegment.punchOutTime = addDays(new Date(nextSegment.punchOutTime), 1)
    }
  }

  if (
    noMissedPunchFound &&
    thisSegment.punchInTime <= thisSegment.punchOutTime &&
    nextSegment.punchInTime <= nextSegment.punchOutTime
  ) {
    overlap = areIntervalsOverlapping(
      {
        start: thisSegment.punchInTime,
        end: thisSegment.punchOutTime,
      },
      {
        start: nextSegment.punchInTime,
        end: nextSegment.punchOutTime,
      },
      { inclusive: false },
    )
  } else {
    overlap = false
  }
  return overlap
}

export const checkOvernight = (startTime, endTime) => {
  if (startTime && endTime) {
    if (startTime.getHours() >= 12 && endTime.getHours() < 12) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const checkIfPunchOutTimeSmaller = (startTime, endTime) => {
  let sTime = startTime ? new Date(startTime) : null
  let eTime = endTime ? new Date(endTime) : null

  if (sTime && eTime) {
    // if punch-in is after noon and punch-out is fore noon, then
    // force the punch-out to be in next day
    if (sTime.getHours() >= 12 && endTime.getHours() < 12) {
      eTime = addDays(new Date(endTime), 1)
    }

    if (sTime.getTime() >= eTime.getTime()) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const checkPunchInFuture = (punchInTime) => {
  //compare the punchInTime with system time
  if (punchInTime) {
    const d = new Date()
    let currentTime = d.getTime()
    if (punchInTime.getTime() > currentTime) {
      return true
    } else {
      return false
    }
  }
}

export const checkPunchOutFuture = (punchOutTime) => {
  //compare the punchOutTime with system time
  if (punchOutTime) {
    const d = new Date()
    let currentTime = d.getTime()
    if (punchOutTime.getTime() > currentTime) {
      return true
    } else {
      return false
    }
  }
}

export const formatDataBeforeSubmit = (dayPunches, deletedPunches) => {
  const dayPunchesData = [...dayPunches, ...deletedPunches]
  const allData = []
  for (let dayPunch of dayPunchesData) {
    const punchInObj = {}
    const punchOutObj = {}

    let newPunchOutTime = new Date(dayPunch.punchOutTime)
    let punchInAction = dayPunch.action
    let punchOutAction = dayPunch.action

    if (dayPunch.punchInTime && dayPunch.punchInTime.getHours() >= 12 && newPunchOutTime.getHours() < 12) {
      newPunchOutTime = newPunchOutTime.setDate(dayPunch.nextDay.getDate())
      newPunchOutTime = new Date(newPunchOutTime).setMonth(new Date(dayPunch.nextDay).getMonth())
      newPunchOutTime = new Date(newPunchOutTime)
    }

    if (dayPunch.action === 'DELETE') {
      punchInObj.delete_existing_punch = true
      punchOutObj.delete_existing_punch = true
      punchInObj.existing_punch_date = dayPunch.punchInDate
      punchOutObj.existing_punch_date = dayPunch.punchOutDate
      punchInObj.punched_location = dayPunch.punchedLocation
      punchOutObj.punched_location = dayPunch.punchedLocation
      punchInObj.existing_punch_time =
        dayPunch.existingPunchInTime !== null ? extractTime(dayPunch.existingPunchInTime) : null

      punchOutObj.existing_punch_time =
        dayPunch.existingPunchOutTime !== null ? extractTime(dayPunch.existingPunchOutTime) : null
    }

    if (dayPunch.action === 'ADD' && dayPunch.punchOutTime === null) {
      punchInObj.new_punch_date = dayPunch.punchInDate
      punchInObj.new_punch_time = extractTime(dayPunch.punchInTime)
      punchInObj.punched_location = dayPunch.punchedLocation
    } else if (dayPunch.action === 'ADD' && dayPunch.punchInTime === null) {
      punchOutObj.new_punch_date = dayPunch.punchOutDate
      punchOutObj.new_punch_time = extractTime(newPunchOutTime)
      punchOutObj.punched_location = dayPunch.punchedLocation
    } else if (dayPunch.action === 'ADD') {
      punchInObj.new_punch_date = dayPunch.punchInDate
      punchInObj.new_punch_time = extractTime(dayPunch.punchInTime)
      punchInObj.punched_location = dayPunch.punchedLocation

      punchOutObj.new_punch_date = toIsoStringWithoutTime(newPunchOutTime)
      punchOutObj.new_punch_time = extractTime(newPunchOutTime)
      punchOutObj.punched_location = dayPunch.punchedLocation
    }

    if (dayPunch.action === null) {
      if (dayPunch.existingPunchInTime && dayPunch.existingPunchInTime.getTime() !== dayPunch.punchInTime.getTime()) {
        punchInAction = 'EDIT'
        punchInObj.existing_punch_date = dayPunch.punchInDate
        punchInObj.existing_punch_time = extractTime(dayPunch.existingPunchInTime)
        punchInObj.new_punch_date = dayPunch.punchInDate
        punchInObj.new_punch_time = extractTime(dayPunch.punchInTime)
        punchInObj.punched_location = dayPunch.punchedLocation

        if (dayPunch.existingPunchOutTime && dayPunch.existingPunchOutTime.getTime() !== newPunchOutTime.getTime()) {
          punchOutAction = 'EDIT'
          punchOutObj.existing_punch_date = dayPunch.punchOutDate
          punchOutObj.existing_punch_time = extractTime(dayPunch.existingPunchOutTime)
          punchOutObj.new_punch_date = toIsoStringWithoutTime(newPunchOutTime)
          punchOutObj.new_punch_time = extractTime(newPunchOutTime)
          punchOutObj.punched_location = dayPunch.punchedLocation
        }
      }
      if (
        dayPunch.existingPunchOutTime &&
        dayPunch.existingPunchOutTime.getTime() !== dayPunch.punchOutTime.getTime()
      ) {
        punchOutAction = 'EDIT'
        punchOutObj.existing_punch_date = dayPunch.punchOutDate
        punchOutObj.existing_punch_time = extractTime(dayPunch.existingPunchOutTime)
        punchOutObj.new_punch_date = toIsoStringWithoutTime(newPunchOutTime)
        punchOutObj.new_punch_time = extractTime(newPunchOutTime)
        punchOutObj.punched_location = dayPunch.punchedLocation
      }
      if (dayPunch.existingPunchInTime === null && dayPunch.punchInTime !== null) {
        punchInAction = 'ADD'
        punchInObj.new_punch_date = dayPunch.punchInDate
        punchInObj.new_punch_time = extractTime(dayPunch.punchInTime)
        punchInObj.punched_location = dayPunch.punchedLocation
      }
      if (dayPunch.existingPunchOutTime === null && dayPunch.punchOutTime !== null) {
        punchOutAction = 'ADD'
        punchOutObj.new_punch_date = toIsoStringWithoutTime(newPunchOutTime)
        punchOutObj.new_punch_time = extractTime(newPunchOutTime)
        punchOutObj.punched_location = dayPunch.punchedLocation
      }
    }

    punchInObj.action = punchInAction
    punchInObj.new_punch_type = 'PUNCH-IN'
    allData.push(punchInObj)

    if (dayPunch.punchOutTime !== null) {
      punchOutObj.action = punchOutAction
      punchOutObj.new_punch_type = 'PUNCH-OUT'
      allData.push(punchOutObj)
    }
  }

  //Removing unmodified existing punch
  let deletedEntries = []
  let addedEntries = []
  let finalEntries = []

  allData.forEach((item) => {
    if (item.action === 'DELETE') {
      if (item.existing_punch_time !== null) {
        deletedEntries.push(item)
      }
    } else if (item.action === 'ADD' || item.action === 'EDIT') {
      addedEntries.push(item)
    }
  })

  deletedEntries.forEach((d_item) => {
    let initialCount = addedEntries.length
    addedEntries = addedEntries.filter((a_item) => d_item.existing_punch_time !== a_item.new_punch_time)

    if (initialCount === addedEntries.length) {
      finalEntries.push(d_item)
    }
  })

  finalEntries = [...finalEntries, ...addedEntries]

  //let copyOfDayPunches1 = finalEntries.filter((item) => item.action !== null)

  return finalEntries
}

export const findInvalidPunches = (dayPunches, timeCardDataDaily, reason) => {
  let copyOfDayPunches = [...dayPunches]
  let punchesFromTimecard = timeCardDataDaily.timecards[0]?.punch_segments
  let matchCounter = 0

  let isModified = false
  let hasOverlap = false
  let hasSmallerPunchOut = false
  let isPunchInFuture = false
  let isPunchOutFuture = false
  let hasMissedPunch = false
  // let hasBothInOutMissed = false

  if (copyOfDayPunches.some((punch) => punch.isOverlapped)) {
    hasOverlap = true
  }

  if (copyOfDayPunches.some((punch) => punch.isPunchOutSmaller)) {
    hasSmallerPunchOut = true
  }

  if (copyOfDayPunches.some((punch) => punch.isPunchInFuture)) {
    isPunchInFuture = true
  }

  if (copyOfDayPunches.some((punch) => punch.isPunchOutFuture)) {
    isPunchOutFuture = true
  }

  hasMissedPunch = copyOfDayPunches.some((punch) => punch.punchInTime === null)

  //hasMissedPunch = copyOfDayPunches.some((punch) => punch.punchInTime === null || punch.punchOutTime === null)

  if (punchesFromTimecard.length === 0) {
    isModified = copyOfDayPunches.some((punch) => punch.punchInTime !== null || punch.punchOutTime !== null)
  }

  if (punchesFromTimecard.length > 0 && punchesFromTimecard.length === copyOfDayPunches.length) {
    for (let punchCard of copyOfDayPunches) {
      let existingPunchInTime = punchCard.existingPunchInTime?.getTime()
      let punchInTime = punchCard.punchInTime?.getTime()
      let existingPunchOutTime = punchCard.existingPunchOutTime?.getTime()
      let punchOutTime = punchCard.punchOutTime?.getTime()

      if (existingPunchInTime === punchInTime && existingPunchOutTime === punchOutTime) {
        matchCounter++
      }
    }
    if (copyOfDayPunches.length === matchCounter) {
      isModified = false
    } else {
      isModified = true
    }
  }

  if (punchesFromTimecard.length > 0 && punchesFromTimecard.length !== copyOfDayPunches.length) {
    isModified = true
  }

  if (!isModified) {
    return { status: false, message: NO_CHANGES_DETECTED_ERROR_MESSAGE }
  } else if (
    hasMissedPunch ||
    hasOverlap ||
    hasSmallerPunchOut ||
    isPunchInFuture ||
    isPunchOutFuture ||
    reason === ''
  ) {
    return { status: false, message: INCOMPLETE_PUNCHES_ERROR_MESSAGE }
  } else {
    return { status: true, message: '', reason: reason }
  }
}
