import CloudDownload from '@mui/icons-material/CloudDownload'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { format } from 'date-fns'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  SCREEN_NAME_MANDATORY,
  SCREEN_NAME_PTO_REQUEST,
  SCREEN_NAME_TM_DIRECTORY,
  SCREEN_NAME_VOLUNTARY,
} from '../../utils/ScreenName'
import Search from '../../utils/Search'
import {
  DIRECTORY_COLUMNS_ENUM,
  DIRECTORY_COLUMNS_ORDER,
  MANDATORY_COLUMNS_ENUM,
  MANDATORY_COLUMNS_ORDER,
  VOLUNTARY_COLUMNS_ENUM,
  VOLUNTARY_COLUMNS_ORDER,
} from './LeaderViewAutomation'
import { useTheme } from '@mui/material'
import { showNotification, showNotificationError } from '../../store/notification/actionCreator'
import { downloadVoluntaryCSV } from '../../store/leaderViewVoluntaryAutomation/actionCreator'
import { downloadDirectoryCSV } from '../../store/leaderViewDirectoryAutomation/actionCreator'
import { downloadMandatoryCSV } from '../../store/leaderViewMandatoryAutomation/actionCreator'

const getStyles = (theme) => ({
  downloadIcon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
})

const FILTER_NOT_ON_SCREEN = []

const CustomToolBar = (props) => {
  const { filters, date, screen, locationId, onSearch, searchText, searchAutoFocus } = props

  const dispatch = useDispatch()
  const theme = useTheme()
  const styles = getStyles(theme)

  const handleCsvClick = () => {
    dispatch(showNotification(true, 'Downloading export file'))

    let selectedDate = new Date(date)
    let response

    if (screen === SCREEN_NAME_VOLUNTARY) {
      response = dispatch(
        downloadVoluntaryCSV(
          buildQueryParams(
            locationId,
            selectedDate,
            filters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.scheduling_key)],
            filters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.department)],
            filters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.job)],
            filters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.proficient)],
            filters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.voluntary_preference)],
            filters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.status)],
            FILTER_NOT_ON_SCREEN,
            FILTER_NOT_ON_SCREEN,
          ),
        ),
      )
    } else if (screen === SCREEN_NAME_MANDATORY) {
      response = dispatch(
        downloadMandatoryCSV(
          buildQueryParams(
            locationId,
            selectedDate,
            filters[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.scheduling_key)],
            filters[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.department)],
            filters[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.job_classification)],
            filters[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.proficiency)],
            filters[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.mandatory_shift)],
            filters[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.status)],
            filters[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.schedule_up_group)],
            filters[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.schedule_down_group)],
          ),
        ),
      )
    } else if (screen === SCREEN_NAME_TM_DIRECTORY) {
      response = dispatch(
        downloadDirectoryCSV(
          buildQueryParams(
            locationId,
            null,
            filters[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.scheduling_key)],
            filters[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.department)],
            filters[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.job_classification)],
            filters[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.proficient)],
            FILTER_NOT_ON_SCREEN,
            FILTER_NOT_ON_SCREEN,
            filters[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_up_group)],
            filters[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_down_group)],
          ),
        ),
      )
    } else {
      dispatch(showNotificationError(true, 'Download option not available for this table.'))
      return
    }

    response.then((res) => {
      if (res.record.status === 200) {
        dispatch(showNotification(false))
        let filename = getFileName(res.record.headers)
        downloadCsv(filename, res.record.data)
      } else if (res.record.response.status >= 300) {
        dispatch(showNotification(false))
        dispatch(showNotificationError(true, 'Download failed. Please try again.'))
      }
    })
  }

  const buildQueryParams = (
    locationId,
    date,
    keys,
    departments,
    jobs,
    proficiencies,
    preferences,
    statuses,
    upGroups,
    downGroups,
  ) => {
    let queryParams = '?location_id=' + locationId

    if (screen !== SCREEN_NAME_TM_DIRECTORY) {
      let scheduleDate = format(date, 'yyyy-MM-dd')
      queryParams = queryParams.concat('&schedule_date=' + scheduleDate)
    }

    if (keys.length > 0) {
      queryParams = queryParams.concat('&scheduling_keys=' + encodeURIComponent(keys))
    }

    if (departments.length > 0) {
      queryParams = queryParams.concat('&departments=' + encodeURIComponent(departments))
    }

    if (jobs.length > 0) {
      queryParams = queryParams.concat('&job_classifications=' + encodeURIComponent(jobs))
    }

    if (proficiencies.length > 0) {
      queryParams = queryParams.concat('&proficiencies=' + encodeURIComponent(proficiencies))
    }

    if (statuses.length > 0) {
      queryParams = queryParams.concat('&statuses=' + encodeURIComponent(statuses))
    }

    if (preferences.length > 0) {
      if (screen === SCREEN_NAME_VOLUNTARY) {
        queryParams = queryParams.concat('&voluntary_preferences=')
      } else if (screen === SCREEN_NAME_MANDATORY) {
        queryParams = queryParams.concat('&mandatoryShifts=')
      }
      queryParams = queryParams.concat(encodeURIComponent(preferences))
    }

    if (upGroups.length > 0) {
      queryParams = queryParams.concat('&schedule_up_groups=' + encodeURIComponent(upGroups))
    }

    if (downGroups.length > 0) {
      queryParams = queryParams.concat('&schedule_down_groups=' + encodeURIComponent(downGroups))
    }

    return queryParams
  }

  const getFileName = (headers) => {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    let matches = filenameRegex.exec(headers['content-disposition'])
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '')
    } else {
      return 'Scheduling_Decision.csv'
    }
  }

  const downloadCsv = (fileName, fileContent) => {
    const blob = new Blob([fileContent], { type: 'text/csv' })
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
      const dataURI = `data:text/csv;charset=utf-8,${fileContent}`

      const URL = window.URL || window.webkitURL
      const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.setAttribute('href', downloadURI)
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const renderCsv = (screen) => {
    if (screen === SCREEN_NAME_PTO_REQUEST) return

    return (
      <Tooltip title={'Download CSV'}>
        <IconButton onClick={handleCsvClick} size="large">
          <CloudDownload sx={styles.downloadIcon} />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <React.Fragment>
      {renderCsv(screen)}
      <Search
        defaultValue={searchText}
        onSearch={onSearch}
        searchAutoFocus={searchAutoFocus}
        textInputWidth={100}
        tooltipTitle={'Search by name'}
      />
    </React.Fragment>
  )
}

export default CustomToolBar
