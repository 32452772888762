import UnApproveIcon from '@mui/icons-material/Close'
import ScheduleIcon from '@mui/icons-material/Today'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Slide,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import Box from '@mui/material/Box'
import { DesktopTimePicker } from '@mui/x-date-pickers'
import { addDays, format } from 'date-fns'
import moment from 'moment'
import { useAuth } from '@praxis/component-auth'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDownShiftType,
  setEndTime,
  setIsInputTypeTime,
  setSaveVoluntaryDialogOpen,
  setShouldLoadVoluntaryData,
  setStartTime,
  setVoluntaryDataSaving,
  setVoluntaryPostResponse,
  setVoluntaryPostResponseIneligibilities,
  updateVoluntaryLeaderDecisions,
} from '../../store/leaderViewVoluntaryAutomation/actionCreator'
import { toIsoStringWithoutTime } from '../../utils/DateUtil'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import DatePicker from '../common/calendar/DatePicker'
import {
  getAboutToSaveText,
  getHowSaveAffectsVoluntaryText,
  getIfChooseYesText,
  getPart1MessageArray,
  getPart1Title,
  getPart2MessageArray,
  getPart2Title,
  render2PartDialogContent,
  renderDivider,
  renderRuleViolationPanels,
  renderUnableToSaveDownActionFromNoSchedule,
  renderUnableToSaveFromStatusDialogContent,
} from './SaveDialogAutomation'

const SAVE_DIALOG_TITLE = 'Are you sure you want to save?'

const SCHEDULE = 'schedule'
const UN_SCHEDULE = 'unSchedule'

const LEADER_AVAILABILITY_STATUS_SCHEDULED = 'Scheduled'
const LEADER_AVAILABILITY_STATUS_SUBMITTED = 'Submitted'
const PAYCODE_ACTION_CREATE = 'create'
const SHIFT_TYPE_FULL = 'full'
const SHIFT_SEGMENT_TYPE_REGULAR = 'Regular'
const ISO_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const UP_DOWN_STATE_DOWN = 'down'
const UP_DOWN_STATE_UP = 'up'

const getStyles = (theme) => ({
  muiTableIconButton: {
    marginRight: '1.5em',
    top: '50%',
    display: 'inline-block',
    position: 'relative',
  },
  textFieldDropDown: {
    width: '10em',
    marginRight: '1.5em',
    position: 'relative',
  },
  textField: {
    marginRight: '1.5em',
    width: '9em',
  },
  textFieldStartDate: {
    marginRight: '1.5em',
    width: '11em',
  },
  inputSwitch: {
    marginRight: '1.5em',
  },
  inputContainer: {
    width: '38.5em',
    display: 'flex',
    alignItems: 'center',
  },
  toolbarRightContainer: {
    alignContent: 'center',
    display: 'flex',
  },
  rulesViolatedTitle: {
    paddingLeft: '24px',
    paddingTop: '16px',
    color: theme.palette.primary.dark,
  },
  savingText: {
    color: theme.palette.tertiary.contrastText,
    paddingTop: '8px',
    fontSize: '12px',
  },
  loadingIconContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const VoluntaryCustomToolbarAutomation = (props) => {
  const { selectedDate, upDownState, selectedRows, selectedLocationId } = props
  const { session } = useAuth()
  const dispatch = useDispatch()
  const theme = useTheme()
  const styles = getStyles(theme)
  const [startDate, setStartDate] = useState(format(new Date(selectedDate), 'yyyy-MM-dd'))
  const [startDateError, setStartDateError] = useState(false)
  const voluntaryData = useSelector((state) => state.leaderViewVoluntaryAutomation.voluntaryData)
  const voluntarySaveDialogOpen = useSelector((state) => state.leaderViewVoluntaryAutomation.voluntarySaveDialogOpen)
  const isInputTypeTime = useSelector((state) => state.leaderViewVoluntaryAutomation.isInputTypeTime)
  const downShiftType = useSelector((state) => state.leaderViewVoluntaryAutomation.downShiftType)
  const startTime = useSelector((state) => state.leaderViewVoluntaryAutomation.startTime)
  const endTime = useSelector((state) => state.leaderViewVoluntaryAutomation.endTime)
  const voluntaryPostResponseIneligibilities = useSelector(
    (state) => state.leaderViewVoluntaryAutomation.voluntaryPostResponseIneligibilities,
  )
  const voluntaryDataSaving = useSelector((state) => state.leaderViewVoluntaryAutomation.voluntaryDataSaving)
  const locationDetails = useSelector((state) => state.leaderViewAutomation.locationDetails)

  const handleOverrideSchedule = () => {
    sendRequest(LEADER_AVAILABILITY_STATUS_SCHEDULED)
  }

  const handleSchedule = () => {
    sendRequest(LEADER_AVAILABILITY_STATUS_SCHEDULED)
  }

  const handleUnSchedule = () => {
    sendRequest(LEADER_AVAILABILITY_STATUS_SUBMITTED)
  }

  const handleScheduleButton = () => {
    handleOpeningSaveDialog(SCHEDULE)
  }

  const handleUnScheduleButton = () => {
    handleOpeningSaveDialog(UN_SCHEDULE)
  }

  const handleOpeningSaveDialog = (saveButtonClicked) => {
    dispatch(setSaveVoluntaryDialogOpen(saveButtonClicked))
  }

  const handleClosingSaveDialog = () => {
    dispatch(setSaveVoluntaryDialogOpen(null))
    if (voluntaryPostResponseIneligibilities) {
      dispatch(setVoluntaryPostResponse(null))
      dispatch(setVoluntaryPostResponseIneligibilities(null))
      dispatch(setShouldLoadVoluntaryData(true))
    }
  }

  const handleSaveDialogYesButton = () => {
    if (voluntarySaveDialogOpen === SCHEDULE) {
      if (voluntaryPostResponseIneligibilities) {
        handleOverrideSchedule()
      } else {
        handleSchedule()
      }
    } else if (voluntarySaveDialogOpen === UN_SCHEDULE) {
      handleUnSchedule()
    }
  }

  const handleInputType = (isInputTypeTime) => {
    dispatch(setIsInputTypeTime(isInputTypeTime))
  }

  const handleDownShiftType = (shiftType) => {
    dispatch(setDownShiftType(shiftType))
  }

  const handleStartDate = (startDate) => {
    setStartDateError(!startDate)
    setStartDate(moment(startDate).format('YYYY-MM-DD'))
  }

  const handleStartTime = (startTime) => {
    dispatch(setStartTime(startTime))
  }

  const handleEndTime = (endTime) => {
    dispatch(setEndTime(endTime))
  }

  const sendRequest = (submissionStatus) => {
    let requestBody = buildBody(submissionStatus)
    dispatch(updateVoluntaryLeaderDecisions(requestBody))
    dispatch(setVoluntaryDataSaving(true))
  }

  const buildBody = (availabilityStatus) => {
    let requestTeamMemberList = []
    selectedRows.data.forEach((teamMember) => {
      if (
        !voluntaryPostResponseIneligibilities ||
        voluntaryPostResponseIneligibilities.has(voluntaryData[teamMember.index].worker_id)
      ) {
        requestTeamMemberList.push(buildDecisionDetail(voluntaryData[teamMember.index]))
      }
    })

    let paycode = null
    let shiftSegment = null
    if (availabilityStatus === LEADER_AVAILABILITY_STATUS_SCHEDULED) {
      paycode = buildPaycode()
      shiftSegment = buildShiftSegment()
    }

    let validateRules = false

    if (upDownState === 'up' && !voluntaryPostResponseIneligibilities) {
      validateRules = true
    }

    let requestBody = {
      location_id: selectedLocationId,
      is_rule_validation_applicable: validateRules,
      requester_id: padEmpIdWithZeros(session?.userInfo?.empid, 10),
      voluntary_type: upDownState,
      leader_availability_status: availabilityStatus,
      schedule_date: toIsoStringWithoutTime(selectedDate),
      paycode: paycode,
      shift_segment: shiftSegment,
      total_voluntary_availability_requests: requestTeamMemberList.length,
      voluntary_availability_requests: requestTeamMemberList,
    }

    // removing JSON values that are null from request
    if (!requestBody.paycode) {
      delete requestBody.paycode
    }

    if (!requestBody.shift_segment) {
      delete requestBody.shift_segment
    }

    return requestBody
  }

  const buildDecisionDetail = (teamMember) => {
    return {
      voluntary_availability_request_id: teamMember.voluntary_availability_request_id,
      voluntary_request_status: teamMember.status,
      status_timestamp: teamMember.status_timestamp,
      last_updated_timestamp: teamMember.last_updated_timestamp,
      has_schedule: teamMember.schedule.length ? 'true' : 'false',
      early_in_rounding_rule: teamMember.early_in_rounding_rule,
      worker_id: teamMember.worker_id,
    }
  }

  const buildPaycode = () => {
    // pay code is only valid in down requests
    if (upDownState === UP_DOWN_STATE_UP) {
      return null
    }

    // type can be string or a double, based on the isInputTypeTime value
    let type
    let start
    if (isInputTypeTime) {
      start = moment.tz(startDate + ' ' + moment(startTime).format('HH:mm'), locationDetails.iso_time_zone_code)
      let end = moment.tz(startDate + ' ' + moment(endTime).format('HH:mm'), locationDetails.iso_time_zone_code)

      if (start.isAfter(end)) {
        end.add(1, 'days')
      }

      let duration = moment.duration(end.diff(start))
      let hoursDifference = parseFloat(duration.asHours())

      type = hoursDifference.toString()
      start = start?.format(ISO_DATE_FORMAT)
    } else {
      // currently only DOWN - Full in drop down. logic will need to be added if this has changed
      type = SHIFT_TYPE_FULL
      start = null
    }

    // pay code action is always create
    return {
      action: PAYCODE_ACTION_CREATE,
      shift_type: type,
      start_timestamp: start,
    }
  }

  const buildShiftSegment = () => {
    // shift segment is only valid in up requests
    if (upDownState === UP_DOWN_STATE_DOWN) {
      return null
    }

    const segmentStart = moment.tz(
      startDate + ' ' + moment(startTime).format('HH:mm'),
      locationDetails.iso_time_zone_code,
    )
    const segmentEnd = moment.tz(startDate + ' ' + moment(endTime).format('HH:mm'), locationDetails.iso_time_zone_code)

    // if the hour selection of end time is before the start, its assumed to be overnight
    if (segmentEnd.hour() <= segmentStart.hour()) {
      segmentEnd.add(1, 'days')
    }

    // for now - regular is the only shift type option
    return {
      shift_segment_start: segmentStart?.format(ISO_DATE_FORMAT),
      shift_segment_end: segmentEnd?.format(ISO_DATE_FORMAT),
      shift_segment_type: SHIFT_SEGMENT_TYPE_REGULAR,
    }
  }

  const renderInput = () => {
    let inputSwitch
    let shiftTypeSelect
    let startTime
    let endTime

    if (upDownState === UP_DOWN_STATE_DOWN) {
      inputSwitch = renderSwitch()
      shiftTypeSelect = renderShiftTypeSelect()
    }

    startTime = renderStartTime()
    endTime = renderEndTime()

    return (
      <React.Fragment>
        <Box sx={upDownState === UP_DOWN_STATE_DOWN ? styles.inputContainer : null}>
          {shiftTypeSelect}
          {inputSwitch}
          {renderStartDate()}
          {startTime}
          {endTime}
        </Box>
      </React.Fragment>
    )
  }

  const renderSwitch = () => {
    return (
      <Switch
        size="medium"
        checked={isInputTypeTime}
        onChange={(event) => handleInputType(event.target.checked)}
        color="default"
        sx={styles.inputSwitch}
      />
    )
  }

  const renderShiftTypeSelect = () => {
    let downShiftTypes = ['Down - Full']
    return (
      <TextField
        id="voluntary_shift_type_select"
        label="Shift Type"
        select
        // index={tableMeta.columnIndex}
        onChange={(event) => handleDownShiftType(event.target.value)}
        value={downShiftType}
        sx={styles.textFieldDropDown}
        disabled={upDownState === 'down' ? isInputTypeTime : false}
        color="primary"
      >
        {downShiftTypes.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  const renderStartDate = () => {
    if (upDownState === UP_DOWN_STATE_UP) {
      return <></>
    }
    return (
      <DatePicker
        label="Start Date"
        defaultDate={selectedDate}
        minDate={new Date(selectedDate)}
        maxDate={addDays(new Date(selectedDate), 1)}
        handleDateChange={handleStartDate}
        disabled={upDownState === 'down' ? !isInputTypeTime : false}
        textFieldSx={styles.textFieldStartDate}
      />
    )
  }

  const renderStartTime = () => {
    return (
      <Tooltip title={'Start time for proposed shift'}>
        <DesktopTimePicker
          id="startTime"
          label="Start Time"
          value={startTime}
          onChange={(startTime) => handleStartTime(startTime)}
          minutesStep={5}
          closeOnSelect={true}
          disabled={upDownState === UP_DOWN_STATE_DOWN ? !isInputTypeTime : false}
          slotProps={{
            textField: (props) => ({
              ...props,
              id: 'startTime',
              name: 'startTime',
              sx: styles.textField,
              'data-cy': 'mandatoryStartTime',
            }),
          }}
        />
      </Tooltip>
    )
  }

  const renderEndTime = () => {
    return (
      <Tooltip title={'End time for proposed shift'}>
        <DesktopTimePicker
          id="endTime"
          label="End Time"
          value={endTime}
          onChange={(endTime) => handleEndTime(endTime)}
          minutesStep={5}
          closeOnSelect={true}
          slotProps={{
            textField: (props) => ({
              ...props,
              id: 'endTime',
              name: 'endTime',
              sx: styles.textField,
              'data-cy': 'mandatoryEndTime',
            }),
          }}
        />
      </Tooltip>
    )
  }

  const renderUnScheduleButton = () => {
    return (
      <Tooltip title={'Un-Schedule'}>
        <IconButton sx={styles.iconButton} onClick={handleUnScheduleButton}>
          <UnApproveIcon color="primary" />
        </IconButton>
      </Tooltip>
    )
  }

  const renderScheduleButton = () => {
    return (
      <Tooltip title={'Schedule'}>
        <IconButton sx={styles.muiTableIconButton} disabled={startDateError} onClick={handleScheduleButton}>
          <ScheduleIcon color={startDateError ? 'disabled' : 'primary'} />
        </IconButton>
      </Tooltip>
    )
  }

  const renderScheduleDialogContent = () => {
    const outputTimeFormat = 'hh:mmA'
    const outputDateFormat = 'dddd, MMMM D'
    let selectedDateString = moment(selectedDate)?.format(outputDateFormat)
    let shiftStartDate = moment(startDate)?.format(outputDateFormat)
    let startTimeString = moment(startTime)?.format(outputTimeFormat)
    let endTimeString = moment(endTime)?.format(outputTimeFormat)

    let scheduleText1 = getAboutToSaveText('voluntary', upDownState, selectedRows.data.length, selectedLocationId)
    let scheduleText2 = getHowSaveAffectsVoluntaryText(
      upDownState,
      selectedLocationId,
      selectedDateString,
      startTimeString,
      endTimeString,
      isInputTypeTime,
      downShiftType,
      shiftStartDate,
    )

    return (
      <React.Fragment>
        <DialogTitle id="save-dialog-slide-title">{SAVE_DIALOG_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>{scheduleText1}</DialogContentText>
          <DialogContentText gutterBottom>{scheduleText2}</DialogContentText>
        </DialogContent>
      </React.Fragment>
    )
  }

  const renderScheduleOverrideDialogContent = () => {
    const outputTimeFormat = 'hh:mmA'
    const outputDateFormat = 'dddd, MMMM D'
    let selectedDateString = moment(selectedDate)?.format(outputDateFormat)
    let shiftStartDate = moment(startDate)?.format(outputDateFormat)
    let startTimeString = moment(startTime)?.format(outputTimeFormat)
    let endTimeString = moment(endTime)?.format(outputTimeFormat)

    let workerIdToNameMap = new Map()
    selectedRows.data.forEach((row) => {
      let rowIndex = row.index
      workerIdToNameMap.set(voluntaryData[rowIndex].worker_id, voluntaryData[rowIndex].full_name)
    })
    let totalRuleViolatedWorkers = voluntaryPostResponseIneligibilities.size
    let totalSelected = selectedRows.data.length
    let totalSaved = totalSelected - voluntaryPostResponseIneligibilities.size

    let part1Title = getPart1Title(totalSaved, totalSelected)

    let part1MessageArray = getPart1MessageArray(totalRuleViolatedWorkers)

    let part2Title = getPart2Title(totalRuleViolatedWorkers)

    let part2Message1 = getAboutToSaveText('voluntary', upDownState, totalRuleViolatedWorkers, selectedLocationId)
    let part2Message2 = getHowSaveAffectsVoluntaryText(
      upDownState,
      selectedLocationId,
      selectedDateString,
      startTimeString,
      endTimeString,
      isInputTypeTime,
      downShiftType,
      shiftStartDate,
    )
    let part2Message3 = getIfChooseYesText(totalRuleViolatedWorkers)

    let part2MessageArray = getPart2MessageArray(part2Message1, part2Message2, part2Message3)

    let expandPanelsTitle = (
      <Typography variant="h6" component="p" sx={styles.rulesViolatedTitle}>
        Rules Violated:
      </Typography>
    )
    let expandPanels = renderRuleViolationPanels(voluntaryPostResponseIneligibilities, workerIdToNameMap)

    return render2PartDialogContent(
      part1Title,
      part1MessageArray,
      part2Title,
      part2MessageArray,
      expandPanelsTitle,
      expandPanels,
    )
  }

  const renderButtonsOrSpinnerDialog = () => {
    let spinner
    let buttons
    if (voluntaryDataSaving) {
      spinner = renderSpinner()
    } else {
      buttons = (
        <React.Fragment>
          <Button onClick={handleClosingSaveDialog} color="primary">
            NO
          </Button>
          <Button onClick={handleSaveDialogYesButton} color="primary">
            YES
          </Button>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <DialogContent sx={styles.loadingIconContainer}>{spinner}</DialogContent>
        <DialogActions>{buttons}</DialogActions>
      </React.Fragment>
    )
  }

  const renderSpinner = () => {
    return (
      <React.Fragment>
        <CircularProgress size={36} /> <span style={styles.savingText}>SAVING</span>
      </React.Fragment>
    )
  }

  const renderUnScheduleDialogContent = () => {
    const outputDateFormat = 'dddd, MMMM D'

    let scheduleText2 =
      'This action will only change the status column. You will need to manually update these schedules in myTime/Kronos.'

    let scheduleText1 =
      'You are about to un-schedule ' +
      selectedRows.data.length +
      ' team member(s) for location ' +
      selectedLocationId +
      ' on ' +
      moment(selectedDate)?.format(outputDateFormat) +
      '.'

    return (
      <React.Fragment>
        <DialogTitle id="save-dialog-slide-title">{SAVE_DIALOG_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>{scheduleText1}</DialogContentText>
          <DialogContentText gutterBottom>{scheduleText2}</DialogContentText>
        </DialogContent>
      </React.Fragment>
    )
  }

  const getNamesWithIncompatibleShiftTypes = () => {
    let failedTmNames = []
    selectedRows.data.forEach((value) => {
      if (
        voluntaryData[value.index].voluntary_availability_preference !== 'Down - Any' &&
        voluntaryData[value.index].voluntary_availability_preference !== downShiftType
      ) {
        failedTmNames.push(voluntaryData[value.index].full_name)
      }
    })
    return failedTmNames
  }

  const getNamesWithIncompatibleStatus = () => {
    let failedTmNames = []
    selectedRows.data.forEach((value) => {
      if (voluntaryData[value.index].status === 'Scheduled') {
        failedTmNames.push(voluntaryData[value.index].full_name)
      }
    })
    return failedTmNames
  }

  const getNamesWithNoSchedules = () => {
    let failedTmNames = []
    selectedRows.data.forEach((value) => {
      if (!voluntaryData[value.index].schedule.length) {
        failedTmNames.push(voluntaryData[value.index].full_name)
      }
    })
    return failedTmNames
  }

  const renderUnableToSaveFromShiftTypeDialogContent = (incompatibleShiftTypeTmLength) => {
    if (incompatibleShiftTypeTmLength) {
      return (
        <DialogContent>
          <DialogContentText gutterBottom>
            {'You are attempting to save a voluntary down decision by Shift Type, and there are ' +
              incompatibleShiftTypeTmLength +
              ' checked rows that have a Volunta' +
              'ry Preference that does not match the selected Shift Type: '}
            <b>{downShiftType}</b>
          </DialogContentText>
          <DialogContentText gutterBottom>
            {'You must uncheck these unmatched rows or make a voluntary down decision by start & end time. '}
          </DialogContentText>
          <Typography color="textSecondary" paragraph={true}>
            <br />
            {"Note: When making a voluntary down decision by Shift Type, it's best to filter by " +
              'Voluntary Preference to ensure that you only select rows that match.'}
          </Typography>
          <Typography color="textSecondary" paragraph={true}>
            {'Note: A Voluntary Preference of: '}
            <b>{'Down - Any'}</b>
            {' will match any Shift Type selected.'}
          </Typography>
        </DialogContent>
      )
    }
  }

  const renderUnableToSaveDialog = (incompatibleShiftTypeTmNames, incompatibleStatusTmNames, noScheduleTmNames) => {
    const shiftTypeTmLength = incompatibleShiftTypeTmNames.length
    const statusTmLength = incompatibleStatusTmNames.length
    const noScheduleTmLength = noScheduleTmNames.length

    return (
      <Dialog
        open={!!voluntarySaveDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClosingSaveDialog(event, reason)
          }
        }}
      >
        <DialogTitle id="failed-validation-dialog-slide-title">{'Unable to save'}</DialogTitle>
        {renderUnableToSaveFromShiftTypeDialogContent(shiftTypeTmLength)}
        {shiftTypeTmLength && statusTmLength ? renderDivider() : null}
        {renderUnableToSaveFromStatusDialogContent('voluntary', statusTmLength)}
        {(shiftTypeTmLength || statusTmLength) && noScheduleTmLength ? renderDivider() : null}
        {renderUnableToSaveDownActionFromNoSchedule('voluntary', noScheduleTmLength)}
        <DialogActions>
          <Button onClick={handleClosingSaveDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderConfirmSaveDialog = () => {
    let dialogOpenFunction

    if (voluntarySaveDialogOpen === SCHEDULE) {
      if (voluntaryPostResponseIneligibilities) {
        dialogOpenFunction = renderScheduleOverrideDialogContent
      } else {
        dialogOpenFunction = renderScheduleDialogContent
      }
    } else if (voluntarySaveDialogOpen === UN_SCHEDULE) {
      dialogOpenFunction = renderUnScheduleDialogContent
    }

    return (
      <Dialog
        open={!!voluntarySaveDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClosingSaveDialog(event, reason)
          }
        }}
      >
        {dialogOpenFunction()}
        {renderButtonsOrSpinnerDialog()}
      </Dialog>
    )
  }

  const renderSaveDialog = () => {
    let unableToSaveDialog
    if (upDownState === 'down' && voluntarySaveDialogOpen === SCHEDULE) {
      let incompatibleShiftTypeTmNames = []

      if (!isInputTypeTime) {
        incompatibleShiftTypeTmNames = getNamesWithIncompatibleShiftTypes()
      }
      let incompatibleStatusTmNames = getNamesWithIncompatibleStatus()
      let noScheduleTmNames = getNamesWithNoSchedules()

      if (incompatibleShiftTypeTmNames.length || incompatibleStatusTmNames.length || noScheduleTmNames.length) {
        unableToSaveDialog = renderUnableToSaveDialog(
          incompatibleShiftTypeTmNames,
          incompatibleStatusTmNames,
          noScheduleTmNames,
        )
      }
    }

    return unableToSaveDialog ? unableToSaveDialog : renderConfirmSaveDialog()
  }

  return (
    <Box sx={styles.toolbarRightContainer}>
      {renderInput()}
      {renderUnScheduleButton()}
      {renderScheduleButton()}
      {voluntarySaveDialogOpen ? renderSaveDialog() : null}
    </Box>
  )
}

export default VoluntaryCustomToolbarAutomation
