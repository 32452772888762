import { formatDateRangeString, formatDateString } from '../../../../../utils/DateUtil'
import { LEADER_DATE_HEADER_FORMAT, LEADER_TIMESTAMP_DETAIL_FORMAT } from '../../../../../constants/leaderConstants'
import { CALL_OFF_ABSENT } from '../../../../../constants/RequestConstants'
import TimeOffDetailPage from '../../TimeOffDetailPage'
import { getLabelValueObj, getRequestStatusIcons, isAnyRequestDetailsFailed } from '../../../../../utils/leaderUtil'
import { Typography } from '@mui/material'

const getStatusIcon = (dataVO) => {
  return getRequestStatusIcons(dataVO?.requestDetails)
}

const getHeaderPrimary = (dataVO) => {
  return formatDateRangeString(dataVO.startDate, dataVO.endDate, dataVO.getStartDateFormat(), LEADER_DATE_HEADER_FORMAT)
}

const getHeaderSecondary = (dataVO) => {
  return [`${dataVO.fullName} (${dataVO.workerId})`]
}

const getLabelValueList = (dataVO) => {
  return [
    getLabelValueObj(dataVO.getStatusesLabel(), dataVO.statuses),
    getLabelValueObj(dataVO.getDurationLabel(), dataVO.duration),
    getLabelValueObj(
      dataVO.getStartTimestampLabel(),
      formatDateString(dataVO.startTimestamp, dataVO.getStartTimestampFormat(), LEADER_TIMESTAMP_DETAIL_FORMAT),
    ),
    getLabelValueObj(
      dataVO.getEndTimestampLabel(),
      formatDateString(dataVO.endTimestamp, dataVO.getEndTimestampFormat(), LEADER_TIMESTAMP_DETAIL_FORMAT),
    ),
    getLabelValueObj(dataVO.getTypesLabel(), dataVO.types),
    getLabelValueObj(dataVO.getJobClassificationLabel(), dataVO.jobClassification),
    getLabelValueObj(
      dataVO.getCreatedTimestampLabel(),
      formatDateString(dataVO.createdTimestamp, dataVO.getCreatedTimestampFormat(), LEADER_TIMESTAMP_DETAIL_FORMAT),
    ),
    getLabelValueObj(
      dataVO.getLastUpdatedTimestampLabel(),
      formatDateString(
        dataVO.lastUpdatedTimestamp,
        dataVO.getLastUpdatedTimestampFormat(),
        LEADER_TIMESTAMP_DETAIL_FORMAT,
      ),
    ),
  ]
}

const thisCallOffMessage = 'This call off absent has failed to process completely and requires manual action.'
const necessaryActionMessage =
  "Please take the necessary action on the team member's timecard or schedule and then update this Failed status accordingly."

const getActionSectionInfoMessage = (dataVO) => {
  if (isAnyRequestDetailsFailed(dataVO.requestDetails)) {
    return (
      <>
        <Typography sx={{ p: 2 }}>{thisCallOffMessage}</Typography>
        <Typography sx={{ paddingX: 2, paddingBottom: 2 }}>{necessaryActionMessage}</Typography>
      </>
    )
  }
}

const CallOffDetail = ({ dataVO }) => {
  return (
    <TimeOffDetailPage
      comments={dataVO.comments}
      requestId={dataVO.requestId}
      requestDetails={dataVO.requestDetails}
      statusIcon={getStatusIcon(dataVO)}
      headerPrimary={getHeaderPrimary(dataVO)}
      headerSecondary={getHeaderSecondary(dataVO)}
      category={CALL_OFF_ABSENT}
      labelValueList={getLabelValueList(dataVO)}
      actionSectionInfoMessage={getActionSectionInfoMessage(dataVO)}
    />
  )
}

export default CallOffDetail
