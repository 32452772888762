import JobIcon from '@mui/icons-material/Label'
import LocationIcon from '@mui/icons-material/Place'
import Typography from '@mui/material/Typography'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import moment from 'moment'
import { Grid } from '@mui/material'

const styles = {
  timeItem: {
    flexGrow: '1',
    paddingTop: '5px',
    paddingLeft: '5px',
    minHeight: '32px',
  },
  name: {
    paddingBottom: '5px',
    paddingLeft: '5px',
  },
}

const ShiftCard = (props) => {
  const { scheduleDate, displaySegment } = props

  const renderShiftDate = () => {
    const outputDateFormat = 'ddd, MMM D'
    const inputTimeFormat = 'YYYY-MM-DD hh:mm:ss'
    const outputTimeFormat = 'h:mmA'
    let segmentStartTime = moment(displaySegment.segment_start, inputTimeFormat)?.format(outputTimeFormat)
    let segmentEndTime = moment(displaySegment.segment_end, inputTimeFormat)?.format(outputTimeFormat)

    if (scheduleDate) {
      return (
        <>
          <Grid item xs={5}>
            <Typography variant="body1" sx={styles.name}>
              {moment(scheduleDate)?.format(outputDateFormat)}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <AccessTimeFilledIcon color="primary" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={styles.timeItem}>
              {segmentStartTime}-{segmentEndTime}
            </Typography>
          </Grid>
        </>
      )
    }
  }

  const renderLocation = () => {
    let location = displaySegment.location
    if (location) {
      return (
        <>
          <Grid item xs={1}>
            <LocationIcon color="primary" />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" sx={styles.name}>
              {location}
              {props.locationId && parseInt(props.locationId) !== parseInt(location) ? '*' : null}
            </Typography>
          </Grid>
        </>
      )
    }
  }

  const renderJob = () => {
    let jobName = displaySegment.job_name
    let totalJobs = displaySegment.total_jobs
    if (jobName && totalJobs != null) {
      return (
        <>
          <Grid item xs={1}>
            <JobIcon color="primary" />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" sx={styles.name}>
              {jobName}
            </Typography>
          </Grid>
        </>
      )
    }
  }
  return (
    <>
      <Grid container alignItems="center">
        {renderShiftDate()}
        {renderLocation()}
        {renderJob()}
      </Grid>
    </>
  )
}
export default ShiftCard
