/* eslint-disable no-dupe-keys */
import { createTheme } from '@mui/material/styles'

export const targetBlack = {
  light: '#424242',
  main: '#212121',
  dark: '#000000',
  contrastText: '#fff !important',
}

export const targetRed = {
  light: '#F44336',
  main: '#cc0000',
  dark: '#B71C1C',
  contrastText: '#fff',
}

export const targetGrey = {
  light: '#EEEEEE',
  main: '#E0E0E0',
  dark: '#BDBDBD',
  contrastText: '#616161',
}

export const praxisTheme = createTheme({
  palette: {
    primary: targetRed,
    secondary: targetBlack,
    tertiary: targetGrey,
  },
  // Messages
  errorMessages: {
    marginTop: '2em',
    textAlign: 'center',
    color: targetRed.main,
    fontSize: 'medium',
  },
  warningMessages: {
    marginTop: '2em',
    textAlign: 'center',
    color: targetRed.light,
    fontSize: 'medium',
  },
  infoMessages: {
    marginTop: '2em',
    textAlign: 'center',
    color: targetGrey.contrastText,
    fontSize: 'medium',
    fontWeight: 'bold',
  },
  infoMessageDetail: {
    padding: '20px',
    textAlign: 'center',
    color: targetGrey.contrastText,
    fontSize: 'small',
  },
  errorCodeMessages: {
    marginTop: '2em',
    textAlign: 'center',
    color: targetGrey.contrastText,
    fontSize: 'small',
    fontWeight: 'bold',
  },
  // Component layout
  mainScrollContainer: {
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
  },
  mainContainerPosition: {
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  typography: {
    useNextVariants: true,
  },
  loadingIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  muiTableIconButton: {
    marginRight: '24px',
    top: '50%',
    display: 'inline-block',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
  muiTableIcon: {
    color: targetRed.main,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 415,
      md: 640,
      lg: 1024,
      xl: 1200,
      mobile: 0,
      desktop: 1024,
    },
  },
  // Materail-ui component overrides
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        size: 'small',
      },
    },
    MUIDataTable: {
      styleOverrides: {
        responsiveBase: {
          '@media (min-height: 1081px)': {
            maxHeight: '930px !important',
          },
          '@media (max-height: 1080px)': {
            maxHeight: '600px !important',
          },
        },
      },
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      },
    },
  },
})

export default praxisTheme
