import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import NotificationError from '../Notifications/NotificationError'
import Notifications from '../Notifications/Notifications'
import NotificationSuccess from '../Notifications/NotificationSuccess'

import MyProtectedElement from '../../auth/MyProtectedElement'
import useCommunicationData from '../../hooks/useCommunicationData'
import '../../styles/index.css'
import CommunicationDialog from '../common/dialog/CommunicationDialog'
import ConfirmDialog from '../common/dialog/ConfirmDialog'
import { useDeviceRegistration } from './hooks/useDeviceRegistration'
import NavigationLayout from './NavigationLayout'

export const Layout = () => {
  useDeviceRegistration()

  return (
    <>
      <Helmet defaultTitle="myTime" titleTemplate="%s" />
      <NavigationLayout />
      <Outlet />
      <ConfirmDialog />
      <MyProtectedElement
        component={CommunicationDialogContainer}
        allowed={['communication']}
        renderUnauthorized={() => <></>}
      />
      <NotificationSuccess />
      <Notifications />
      <NotificationError />
    </>
  )
}

function CommunicationDialogContainer() {
  const communicationProps = useCommunicationData(true)
  const isOpen = useSelector((store: any) => store.layout.isCommunicationOpen)

  return <CommunicationDialog {...communicationProps} isOpen={isOpen} />
}

export default Layout
