import Clear from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { useState } from 'react'
import { useTheme } from '@mui/material'

const getStyles = (theme) => ({
  searchButton: {
    '&:disabled': {
      color: theme.palette.text.secondary,
      paddingRight: '4px',
    },
  },
  clearButton: {
    padding: '4px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
})
const Search = (props) => {
  const { defaultValue, onSearch, textInputWidth, tooltipTitle, searchAutoFocus } = props
  const theme = useTheme()
  const styles = getStyles(theme)
  const [searchText, setSearchText] = useState(defaultValue)

  const handleTextChange = (event) => {
    let validatedText = event.target.value.replace(/[^\w-']+/g, '')
    setSearchText(validatedText)
    onSearch(validatedText)
  }

  const handleSearchClearClick = () => {
    setSearchText('')
    onSearch('')
  }

  const renderClear = (searchText) => {
    return searchText ? <Clear fontSize="small" /> : null
  }

  return (
    <>
      <IconButton disabled sx={styles.searchButton} aria-label="Search" size="large">
        <SearchIcon />
      </IconButton>
      <Tooltip title={tooltipTitle}>
        <TextField
          sx={{ width: searchText ? textInputWidth - 20 : textInputWidth }}
          autoFocus={searchAutoFocus}
          value={searchText || ''}
          onChange={handleTextChange}
        />
      </Tooltip>
      <IconButton sx={styles.clearButton} onClick={handleSearchClearClick} aria-label="Clear" size="large">
        {renderClear(searchText)}
      </IconButton>
    </>
  )
}

export default Search
