import { isValid, parseISO } from 'date-fns'
import { FIXED_END_DATE_FROM_UKG, months } from '../../../../../constants/AvailabilityConstants'
import { CALL_IN_LATE } from '../../../../../constants/RequestConstants'
import { LEADER_TIMESTAMP_DETAIL_FORMAT } from '../../../../../constants/leaderConstants'
import { convertAmPmTimeFormat, formatDateString } from '../../../../../utils/DateUtil'
import AvailabilityDetailPage from '../../AvailabilityDetailPage'

const getLabelValueList = (data, dEnum, map) => {
  const getLabelValueObj = (label, value, voiceOverTxt) => {
    return { label: label, value: value, voiceOverTxt: voiceOverTxt }
  }

  const voiceOverDate = (val) => {
    if (isValid(val)) {
      let date = val.getDate().toString()
      let month = val.getMonth()
      let year = val.getFullYear()
      let time = convertAmPmTimeFormat(`${val.getHours()}:${val.getMinutes()}`)
      return `${months[month].substring(0, 3)} ${date}, ${year} ${time}`
    } else {
      return ''
    }
  }

  return [
    getLabelValueObj(map.get(dEnum.Status)?.label, map.get(dEnum.Status)?.value(data)),
    getLabelValueObj(
      map.get(dEnum.StartDate)?.label,
      formatDateString(
        map.get(dEnum.StartDate)?.value(data),
        map.get(dEnum.StartDate)?.format,
        LEADER_TIMESTAMP_DETAIL_FORMAT,
      ),
      voiceOverDate(parseISO(new Date(map.get(dEnum.StartDate)?.value(data)).toISOString().slice(0, -1))),
    ),
    getLabelValueObj(
      map.get(dEnum.EndDate)?.label,
      map.get(dEnum.EndDate)?.value(data) === FIXED_END_DATE_FROM_UKG
        ? 'FOREVER'
        : formatDateString(
            map.get(dEnum.EndDate)?.value(data),
            map.get(dEnum.EndDate)?.format,
            LEADER_TIMESTAMP_DETAIL_FORMAT,
          ),
      map.get(dEnum.EndDate)?.value(data) === FIXED_END_DATE_FROM_UKG
        ? 'FOREVER'
        : voiceOverDate(parseISO(new Date(map.get(dEnum.EndDate)?.value(data)).toISOString().slice(0, -1))),
    ),
    getLabelValueObj(map.get(dEnum.PatternType)?.label, map.get(dEnum.PatternType)?.value(data) + ` Availability `, ''),
    getLabelValueObj(map.get(dEnum.JobClassification)?.label, map.get(dEnum.JobClassification)?.value(data), ''),
    getLabelValueObj(
      map.get(dEnum.CreatedTimestamp)?.label,
      formatDateString(
        map.get(dEnum.CreatedTimestamp)?.value(data),
        map.get(dEnum.CreatedTimestamp)?.format,
        LEADER_TIMESTAMP_DETAIL_FORMAT,
      ),
      voiceOverDate(new Date(map.get(dEnum.CreatedTimestamp)?.value(data))),
    ),
    getLabelValueObj(map.get(dEnum.LastUpdatedBy)?.label, map.get(dEnum.LastUpdatedBy)?.value(data)),
    getLabelValueObj(
      map.get(dEnum.LastUpdatedTimestamp)?.label,
      formatDateString(
        map.get(dEnum.LastUpdatedTimestamp)?.value(data),
        map.get(dEnum.LastUpdatedTimestamp)?.format,
        LEADER_TIMESTAMP_DETAIL_FORMAT,
      ),
      voiceOverDate(new Date(map.get(dEnum.LastUpdatedTimestamp)?.value(data))),
    ),
  ]
}

const AvailabilityDetail = ({ data }) => {
  return <AvailabilityDetailPage data={data} category={CALL_IN_LATE} getLabelValueList={getLabelValueList} />
}

export default AvailabilityDetail
