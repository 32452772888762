import update from 'immutability-helper'
import {
  DEFAULT_DIRECTORY_DATA,
  DEFAULT_DIRECTORY_DATA_SORT,
  DEFAULT_DIRECTORY_DATA_TOTAL_RECORDS,
  DEFAULT_PAGE_NUM,
  DEFAULT_PER_PAGE,
  DIRECTORY_COLUMNS_ENUM,
  DIRECTORY_COLUMNS_ORDER,
} from '../../components/LeaderViewAutomation/LeaderViewAutomation'
import {
  ADD_AUTOMATION_DIRECTORY_SESSION_DATA,
  ADD_SELECTED_AUTOMATION_DIRECTORY_INDEXES,
  AUTOMATION_DIRECTORY_HANDLE_POST_ERROR,
  AUTOMATION_DIRECTORY_HANDLE_POST_SUCCESS,
  AUTOMATION_DIRECTORY_SET_DATA_COLUMN_VIEW,
  AUTOMATION_DIRECTORY_SET_POST_RESPONSE,
  AUTOMATION_DIRECTORY_SET_SAVE_DIALOG_OPEN,
  GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_ERROR,
  GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_SUCCESS,
  GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_ERROR,
  GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_SUCCESS,
  MAKE_SCREEN_UNEDITABLE_FOR_ERLR,
  REMOVE_SELECTED_AUTOMATION_DIRECTORY_INDEX,
  SET_AUTOMATION_DIRECTORY_DATA_LOADING,
  SET_AUTOMATION_DIRECTORY_DATA_PAGE,
  SET_AUTOMATION_DIRECTORY_DATA_PER_PAGE,
  SET_AUTOMATION_DIRECTORY_DATA_SAVING,
  SET_AUTOMATION_DIRECTORY_DATA_SEARCH,
  SET_AUTOMATION_DIRECTORY_DATA_SELECTED_FILTERS,
  SET_AUTOMATION_DIRECTORY_DATA_SORT,
  SET_AUTOMATION_DIRECTORY_DATA_TABLE_COLUMNS_TO_DEFAULT,
  SET_AUTOMATION_DIRECTORY_DATA_TABLE_FILTERS_TO_DEFAULT,
  SET_AUTOMATION_DIRECTORY_DATA_TABLE_STATE_TO_DEFAULT,
  SET_AUTOMATION_DIRECTORY_POST_ERROR,
} from './actionType'

import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

const formatDirectoryData = (data = {}) => {
  return data.map((element) => {
    return {
      name: element.last_name + ', ' + element.first_name,
      scheduling_key: element.scheduling_key,
      department: element.department,
      job_classification: element.job_classification,
      proficient: element.proficient,
      schedule_up_group: element.schedule_up_group,
      schedule_down_group: element.schedule_down_group,
      worker_id: element.worker_id,
    }
  })
}

/**
 * Build the array of arrays for how many columns are defined in the enum. This is the function where a
 * default value (example - proficient: 'y') could be added.
 */
const buildDefaultSelectedFilters = () => {
  let columns = []

  DIRECTORY_COLUMNS_ORDER.forEach(() => {
    let emptyArray = []
    columns.push(emptyArray)
  })
  return columns
}

const buildPaginationDetailsFromHeaderResponse = (headers = {}) => {
  return {
    directoryDataTotalRecords: headers['x-total-count'],
  }
}

const updateSelectedFilters = (state, payload) => {
  return update(state, {
    directoryColumns: {
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.scheduling_key)]: {
        options: {
          filterList: { $set: payload[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.scheduling_key)] },
        },
      },
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.department)]: {
        options: {
          filterList: { $set: payload[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.department)] },
        },
      },
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.job_classification)]: {
        options: {
          filterList: { $set: payload[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.job_classification)] },
        },
      },
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.proficient)]: {
        options: {
          filterList: { $set: payload[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.proficient)] },
        },
      },
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_up_group)]: {
        options: {
          filterList: { $set: payload[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_up_group)] },
        },
      },
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_down_group)]: {
        options: {
          filterList: { $set: payload[DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_down_group)] },
        },
      },
    },
    directoryDataSelectedFilters: { $set: payload },
  })
}

const updateDirectoryColumns = (state, record) => {
  return update(state, {
    directoryColumns: {
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.scheduling_key)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.job_keys },
          },
        },
      },
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.department)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.departments },
          },
        },
      },
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.job_classification)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.jobs },
          },
        },
      },
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.proficient)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.proficiencies },
          },
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              return (
                <TextField
                  disabled={state.makeScreenUneditableForERLR}
                  id="proficiency_select"
                  select
                  index={tableMeta.columnIndex}
                  onChange={(event) => updateValue(event.target.value)}
                  value={value}
                  margin="normal"
                >
                  {record.data.proficiencies.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              )
            },
          },
        },
      },
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_up_group)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.schedule_groups_up },
          },
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              return (
                <TextField
                  id="down_group_select"
                  disabled={state.makeScreenUneditableForERLR}
                  select
                  index={tableMeta.columnIndex}
                  onChange={(event) => updateValue(event.target.value)}
                  value={value}
                  margin="normal"
                >
                  {record.data.schedule_groups_up.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              )
            },
          },
        },
      },
      [DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_down_group)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.schedule_groups_down },
          },
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              return (
                <TextField
                  id="down_group_select"
                  disabled={state.makeScreenUneditableForERLR}
                  select
                  index={tableMeta.columnIndex}
                  onChange={(event) => updateValue(event.target.value)}
                  value={value}
                  margin="normal"
                >
                  {record.data.schedule_groups_down.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              )
            },
          },
        },
      },
    },
    directoryFiltersGetError: { $set: null },
  })
}

const updateColumnView = (state, column, action) => {
  let isViewable
  if (action === 'add') {
    isViewable = true
  }
  if (action === 'remove') {
    isViewable = false
  }

  return update(state, {
    directoryColumns: {
      [DIRECTORY_COLUMNS_ORDER.indexOf(column)]: {
        options: {
          display: { $set: isViewable },
        },
      },
    },
  })
}

export const initialState = {
  directoryFiltersGetError: null,
  directoryGetError: null,
  directoryPostError: null,
  postResponse: null,
  directoryDataSelectedFilters: buildDefaultSelectedFilters(),
  directoryPrevRequestedSelectedFilters: null,
  directoryData: DEFAULT_DIRECTORY_DATA,
  directorySessionData: DEFAULT_DIRECTORY_DATA,
  directoryPrevRequestedSearch: null,
  directoryDataSearch: null,
  directoryDataLoading: false,
  makeScreenUneditableForERLR: true,
  directoryDataSaving: false,
  directoryDataSort: DEFAULT_DIRECTORY_DATA_SORT,
  directoryDataTotalRecords: DEFAULT_DIRECTORY_DATA_TOTAL_RECORDS,
  directoryDataPage: DEFAULT_PAGE_NUM,
  directoryDataPerPage: DEFAULT_PER_PAGE,
  selectedDirectoryIndexes: [],
  saveDialogOpen: false,
  exitDirectoryDialogOpen: false,
  cancelDirectoryDialogOpen: false,
  directoryNavigateToNextLocation: false,
  directoryColumns: [
    /**
     * Column order matters here. Using DIRECTORY_COLUMNS_ORDER indexes to maintain order. If add/removing or changing
     * order of a column(s) here, also update DIRECTORY_COLUMNS_ORDER
     */
    {
      name: DIRECTORY_COLUMNS_ORDER[0],
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: DIRECTORY_COLUMNS_ORDER[1],
      label: 'Team Member ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: DIRECTORY_COLUMNS_ORDER[2],
      label: 'Key',
      options: {
        filter: true,
        filterList: [],
        filterType: 'multiselect',
        filterOptions: {
          names: [],
        },
        sort: false,
        searchable: false,
      },
    },
    {
      name: DIRECTORY_COLUMNS_ORDER[3],
      label: 'Department',
      options: {
        filter: true,
        filterList: [],
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: DIRECTORY_COLUMNS_ORDER[4],
      label: 'Job Classification',
      options: {
        filter: true,
        filterList: [],
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: DIRECTORY_COLUMNS_ORDER[5],
      label: 'Proficiency',
      options: {
        filter: true,
        filterList: [],
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: DIRECTORY_COLUMNS_ORDER[6],
      label: 'Up Group',
      options: {
        filter: true,
        filterList: [],
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: DIRECTORY_COLUMNS_ORDER[7],
      label: 'Down Group',
      options: {
        filter: true,
        filterList: [],
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
  ],
}

export default function leaderViewDirectory(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AUTOMATION_DIRECTORY_DATA_SELECTED_FILTERS: {
      const { payload } = action
      return updateSelectedFilters(state, payload)
    }
    case AUTOMATION_DIRECTORY_SET_DATA_COLUMN_VIEW: {
      const { column, columnAction } = action
      return updateColumnView(state, column, columnAction)
    }
    case SET_AUTOMATION_DIRECTORY_DATA_LOADING: {
      const { payload, search, selectedFilters } = action
      return {
        ...state,
        directoryDataLoading: payload,
        directoryPrevRequestedSearch: search,
        directoryPrevRequestedSelectedFilters: selectedFilters,
      }
    }
    case SET_AUTOMATION_DIRECTORY_DATA_SAVING: {
      const { isSaving } = action
      return {
        ...state,
        directoryDataSaving: isSaving,
      }
    }
    case MAKE_SCREEN_UNEDITABLE_FOR_ERLR: {
      const { editable } = action
      return {
        ...state,
        makeScreenUneditableForERLR: editable,
      }
    }
    case SET_AUTOMATION_DIRECTORY_DATA_SEARCH: {
      const { payload } = action
      return {
        ...state,
        directoryDataSearch: payload,
      }
    }
    case SET_AUTOMATION_DIRECTORY_DATA_SORT: {
      const { payload } = action
      return {
        ...state,
        directoryDataSort: payload,
      }
    }
    case SET_AUTOMATION_DIRECTORY_DATA_PAGE: {
      const { payload } = action
      return {
        ...state,
        directoryDataPage: payload,
      }
    }
    case SET_AUTOMATION_DIRECTORY_DATA_PER_PAGE: {
      const { payload } = action
      return {
        ...state,
        directoryDataPerPage: payload,
      }
    }
    case GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        directoryFiltersGetError: record.response ? record.response.data : record,
      }
    }
    case GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_SUCCESS: {
      const { record } = action
      return updateDirectoryColumns(state, record)
    }
    case GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        directoryGetError: record.response ? record.response.data : record,
        directoryPostError: null,
        directoryDataLoading: false,
      }
    }
    case GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_SUCCESS: {
      const { record } = action
      const formattedData = formatDirectoryData(record.data.worker_details)
      return {
        ...state,
        directoryData: formattedData,
        directorySessionData: formattedData.slice(0), //clone data
        selectedDirectoryIndexes: [],
        ...buildPaginationDetailsFromHeaderResponse(record.headers),
        directoryPostError: null,
        directoryGetError: null,
        directoryDataLoading: false,
      }
    }
    case SET_AUTOMATION_DIRECTORY_DATA_TABLE_STATE_TO_DEFAULT: {
      return {
        ...state,
        directoryData: DEFAULT_DIRECTORY_DATA,
        directorySessionData: DEFAULT_DIRECTORY_DATA,
        directoryDataTotalRecords: DEFAULT_DIRECTORY_DATA_TOTAL_RECORDS,
        selectedDirectoryIndexes: [],
      }
    }
    case SET_AUTOMATION_DIRECTORY_DATA_TABLE_FILTERS_TO_DEFAULT: {
      return {
        ...state,
        ...updateSelectedFilters(state, buildDefaultSelectedFilters()),
      }
    }
    case SET_AUTOMATION_DIRECTORY_DATA_TABLE_COLUMNS_TO_DEFAULT: {
      return {
        ...state,
        directoryColumns: initialState.directoryColumns,
      }
    }
    case AUTOMATION_DIRECTORY_HANDLE_POST_ERROR: {
      const { record } = action
      return {
        ...state,
        directoryPostError: record.response ? record.response.data : record,
        directoryGetError: null,
        directoryDataSaving: false,
      }
    }
    case AUTOMATION_DIRECTORY_HANDLE_POST_SUCCESS: {
      const { record } = action
      return {
        ...state,
        postResponse: record.data,
        selectedDirectoryIndexes: [],
        directoryPostError: null,
        directoryGetError: null,
        directoryDataSaving: false,
      }
    }
    case SET_AUTOMATION_DIRECTORY_POST_ERROR: {
      const { error } = action
      return {
        ...state,
        directoryPostError: error,
      }
    }
    case AUTOMATION_DIRECTORY_SET_POST_RESPONSE: {
      const { payload } = action
      return {
        ...state,
        postResponse: payload,
      }
    }
    case ADD_AUTOMATION_DIRECTORY_SESSION_DATA: {
      const { payload } = action
      return {
        ...state,
        directorySessionData: state.directorySessionData.map((element, index) => {
          if (index === payload.row) {
            return {
              ...element,
              [payload.col]: payload.newVal,
            }
          }
          return element
        }),
      }
    }
    case ADD_SELECTED_AUTOMATION_DIRECTORY_INDEXES: {
      const { payload } = action
      return {
        ...state,
        selectedDirectoryIndexes: [...state.selectedDirectoryIndexes, payload],
      }
    }
    case REMOVE_SELECTED_AUTOMATION_DIRECTORY_INDEX: {
      const { payload } = action
      return {
        ...state,
        selectedDirectoryIndexes: state.selectedDirectoryIndexes.filter((element) => element !== payload),
      }
    }
    case AUTOMATION_DIRECTORY_SET_SAVE_DIALOG_OPEN: {
      const { payload } = action
      return {
        ...state,
        saveDialogOpen: payload,
      }
    }
    default:
      return state
  }
}
