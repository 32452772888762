import { Paper, useTheme } from '@mui/material'
import { addWeeks, endOfWeek, startOfWeek, subWeeks } from 'date-fns'
import InlineCalendarDatePicker from '../common/calendar/PopoverInlineCalendarPicker'
import NextWeekButton from './NextWeekButton'
import PrevWeekButton from './PrevWeekButton'

const getStyles = (theme) => ({
  colStart: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  colEnd: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  weeklyCalendar: {
    fontSize: '1em',
    fontWeight: '300',
    color: theme.palette.secondary.dark,
    margin: '0 auto',
    maxWidth: theme.mainScrollContainer.maxWidth,
    display: 'block',
    position: 'relative',
    width: '100%',
    background: theme.palette.tertiary.light,
  },
  calendarHeader: {
    alignItems: 'center',
    display: 'flex',
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '115%',
    padding: '.0 1.5em',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.tertiary.dark,
  },
  icon: {
    outline: 'none',
  },
})

const TimecardWeeklyCalendar = (props) => {
  const { onChange, selectedDate } = props
  const theme = useTheme()
  const styles = getStyles(theme)

  const renderHeader = () => {
    const startWeek = startOfWeek(selectedDate)
    const endWeek = endOfWeek(selectedDate)

    return (
      <div style={styles.calendarHeader}>
        <div style={styles.colStart}>
          <PrevWeekButton
            {...props}
            styles={styles}
            startWeek={startWeek}
            handleDoubleClick={prevWeek}
            handleSubmit={prevWeek}
          />
        </div>
        <InlineCalendarDatePicker {...props} dateTextFormat={'weekSpan'} />
        <div style={styles.colEnd}>
          <NextWeekButton
            {...props}
            styles={styles}
            endWeek={endWeek}
            handleDoubleClick={nextWeek}
            handleSubmit={nextWeek}
          />
        </div>
      </div>
    )
  }

  const nextWeek = () => {
    let newDate = addWeeks(selectedDate, 1)
    onChange(newDate)
  }

  const prevWeek = () => {
    let newDate = subWeeks(selectedDate, 1)
    onChange(newDate)
  }

  return (
    <div>
      <Paper sx={styles.weeklyCalendar} square elevation={1}>
        {renderHeader()}
      </Paper>
    </div>
  )
}

export default TimecardWeeklyCalendar
