import { useTimeOffRequests } from '../../../../../../api/requests/useTimeOffRequests'
import TableContent from '../../TableContent'
import TimeOffRow from './TimeOffRow'

export default function TimeOff() {
  const { data, isLoading, error, isFetching } = useTimeOffRequests()

  return (
    <TableContent
      data={data?.data?.worker_time_off_requests}
      totalCount={data?.headers['x-total-count']}
      numberOfRecords={data?.data?.total_worker_time_off_requests}
      error={error}
      isLoading={isLoading}
      isFetching={isFetching}
      Row={TimeOffRow}
    />
  )
}
