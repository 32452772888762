export const SET_PERSISTED_DATA = 'SET_PERSISTED_DATA'
export const OPTIONAL_AVAILABILITY_SET_TM_LOADING = 'OPTIONAL_AVAILABILITY_SET_TM_LOADING'
export const OPTIONAL_AVAILABILITY_SET_EXPAND_COLLAPSE = 'OPTIONAL_AVAILABILITY_SET_EXPAND_COLLAPSE'
export const OPTIONAL_AVAILABILITY_SET_SESSION_DATA = 'OPTIONAL_AVAILABILITY_SET_SESSION_DATA'
export const OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_ERROR = 'OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_ERROR'
export const OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_SUCCESS = 'OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_SUCCESS'
export const OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_ERROR = 'OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_ERROR'
export const OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_SUCCESS = 'OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_SUCCESS'
export const OPTIONAL_AVAILABILITY_SET_POST_RESPONSE = 'OPTIONAL_AVAILABILITY_SET_POST_RESPONSE'
export const OPTIONAL_AVAILABILITY_SET_SAVE_DIALOG_OPEN = 'OPTIONAL_AVAILABILITY_SET_SAVE_DIALOG_OPEN'
export const OPTIONAL_AVAILABILITY_SET_CANCEL_DIALOG_OPEN = 'OPTIONAL_AVAILABILITY_SET_CANCEL_DIALOG_OPEN'
export const OPTIONAL_AVAILABILITY_SET_EXIT_DIALOG_OPEN = 'OPTIONAL_AVAILABILITY_SET_EXIT_DIALOG_OPEN'
export const OPTIONAL_AVAILABILITY_SET_DIFFERENT_WEEK_DIALOG_OPEN =
  'OPTIONAL_AVAILABILITY_SET_DIFFERENT_WEEK_DIALOG_OPEN'
export const OPTIONAL_AVAILABILITY_SET_UPDATED_SESSION_DATA = 'OPTIONAL_AVAILABILITY_SET_UPDATED_SESSION_DATA'
export const OPTIONAL_AVAILABILITY_SET_NEXT_LOCATION = 'OPTIONAL_AVAILABILITY_SET_NEXT_LOCATION'
export const OPTIONAL_AVAILABILITY_SET_NAVIGATE_TO_NEXT_LOCATION = 'OPTIONAL_AVAILABILITY_SET_NAVIGATE_TO_NEXT_LOCATION'
export const SET_TM_AVAILABILITY_SELECTED_DATE = 'SET_TM_AVAILABILITY_SELECTED_DATE'
export const SET_TM_AVAILABILITY_POST_ERROR = 'SET_TM_AVAILABILITY_POST_ERROR'
