import * as React from 'react'
import { useState } from 'react'
import Popover from '@mui/material/Popover'
import Chip from '@mui/material/Chip'

export default function ChipPopover({ icon, size, color, variant, label, popOverMessage }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Chip icon={icon} label={label} size={size} color={color} variant={variant} onClick={handlePopoverOpen} />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {popOverMessage}
      </Popover>
    </>
  )
}
