/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { getDay, isAfter, isBefore, isValid } from 'date-fns'
import { toIsoStringWithoutTime } from '../../../utils/DateUtil'

const styles = {
  errorMsg: {
    color: '#cc0000',
    padding: 0,
    fontSize: '0.85rem',
  },
}

let endDateForLabel = ''

const AvailabilityDatePicker = ({
  name,
  label,
  defaultDate,
  minDate,
  maxDate,
  defaultEndDate,
  handleDateChange,
  disableSpecificDates,
  hasError,
}) => {
  const [value, setValue] = useState(defaultDate)
  const [isStartDate, setIsStartDate] = useState({
    invalid: false,
    afterMax: false,
    beforeMin: false,
    notSelectable: false,
  })
  const [isEndDate, setIsEndDate] = useState({
    invalid: false,
    afterMax: false,
    beforeMin: false,
    notSelectable: false,
  })

  useEffect(() => {
    setValue(defaultDate)
    processDateValidation(defaultDate)
  }, [defaultDate])

  useEffect(() => {
    if (
      isStartDate.invalid ||
      isStartDate.beforeMin ||
      isStartDate.afterMax ||
      isStartDate.notSelectable ||
      isEndDate.invalid ||
      isEndDate.beforeMin ||
      isEndDate.afterMax ||
      isEndDate.notSelectable
    ) {
      hasError(true)
    } else {
      hasError(false)
    }
  }, [isStartDate, isEndDate])

  const handleOnChange = (newValue) => {
    let day = label.split(' ')[1]
    if (isValid(newValue)) {
      setValue(newValue)
      handleDateChange(newValue)
      processDateValidation(newValue)
    } else {
      if (day === 'Sunday') {
        setIsStartDate({ ...isStartDate, invalid: true })
      } else {
        setIsEndDate({ ...isEndDate, invalid: true })
      }
    }
  }

  const processDateValidation = (newValue) => {
    let day = label.split(' ')[1]
    if (day === 'Sunday') {
      setIsStartDate({
        ...isStartDate,
        invalid: false,
        beforeMin: isBefore(newValue, minDate),
        afterMax: isAfter(newValue, maxDate),
        notSelectable: getDay(newValue) !== 0,
      })
    } else {
      setIsEndDate({
        ...isEndDate,
        invalid: false,
        beforeMin: findBeforeMinAndErrorLabel(newValue),
        afterMax: isAfter(newValue, maxDate),
        notSelectable: getDay(newValue) !== 6,
      })
    }
  }

  const findBeforeMinAndErrorLabel = (newValue) => {
    if (defaultEndDate && isBefore(newValue, defaultEndDate)) {
      endDateForLabel = toIsoStringWithoutTime(defaultEndDate)
      return true
    } else if (isBefore(newValue, minDate)) {
      endDateForLabel = toIsoStringWithoutTime(minDate)
      return true
    } else {
      endDateForLabel = toIsoStringWithoutTime(minDate)
      return false
    }
  }

  return (
    <>
      <MuiDatePicker
        label={label}
        defaultValue={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleOnChange}
        shouldDisableDate={disableSpecificDates}
        slotProps={{
          textField: (props) => ({
            ...props,
            InputProps: {
              ...props.inputProps,
              'aria-label': label.split(' ')[1] === 'Sunday' ? 'start date' : 'end date',
              disabled: false,
            },
            'data-cy': name,
          }),
        }}
      />
      {isStartDate.invalid && (
        <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
          Start Date is invalid.
        </Typography>
      )}
      {isEndDate.invalid && (
        <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
          End Date is invalid.
        </Typography>
      )}
      {!isStartDate.invalid && isStartDate.beforeMin && (
        <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
          Start Date should not be before {toIsoStringWithoutTime(minDate)}
        </Typography>
      )}
      {!isEndDate.invalid && isEndDate.beforeMin && (
        <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
          End Date should not be before {endDateForLabel}
        </Typography>
      )}
      {!isStartDate.invalid && isStartDate.afterMax && (
        <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
          Start Date should not be after {toIsoStringWithoutTime(maxDate)}
        </Typography>
      )}
      {!isEndDate.invalid && isEndDate.afterMax && (
        <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
          End Date should not be after {toIsoStringWithoutTime(maxDate)}
        </Typography>
      )}
      {!isStartDate.invalid && isStartDate.notSelectable && (
        <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
          Start Date should be on {label.split(' ')[1]}
        </Typography>
      )}
      {!isEndDate.invalid && isEndDate.notSelectable && (
        <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
          End Date should be on {label.split(' ')[1]}
        </Typography>
      )}
    </>
  )
}

export default AvailabilityDatePicker
