import { Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { RULES_VIOLATION_ERR_MSG_FOR_SWAP } from '../../constants/errorConstants'
import DisplaySegmentShiftSwap from '../DisplaySegments/DisplaySegmentShiftSwap'

const ShiftSwapMessageModal = (props) => {
  const { styles, shift } = props

  const handleClose = () => {
    props.handleClose()
  }

  const renderDialogInfoText = () => {
    let ruleViolation = ''

    if (props.shift.is_rule_violated) {
      ruleViolation = parseRuleViolationForSwap(props.shift)
    }
    return (
      <>
        <Typography sx={{ ...styles.displaySegmentContainer, ...styles.marginTop1 }} variant="body2">
          <span style={styles.preInfoText}>{props.message}</span>
          <br />
          <div dangerouslySetInnerHTML={{ __html: ruleViolation }} />
        </Typography>
      </>
    )
  }

  const parseRuleViolationForSwap = (accepterSwapPostResponse) => {
    let ruleViolationsRequesterArr = accepterSwapPostResponse['requester'].rule_violations
    let ruleViolationsAccepterArr = accepterSwapPostResponse['accepter'].rule_violations
    let errMsgRequester = ''
    let errMsgAccepter = ''
    let message = ''

    if (ruleViolationsRequesterArr && ruleViolationsRequesterArr.length > 0) {
      for (let ruleViolation of ruleViolationsRequesterArr) {
        errMsgRequester += '<li>' + ruleViolation.rule_violation_text + '</li>'
      }
      message = '<b>Requester:</b>' + '<br /><ul>' + errMsgRequester + '</ul><br />'
    }

    if (ruleViolationsAccepterArr && ruleViolationsAccepterArr.length > 0) {
      for (let ruleViolation of ruleViolationsAccepterArr) {
        errMsgAccepter += '<li>' + ruleViolation.rule_violation_text + '</li>'
      }
      message = message + '<b>Accepter:</b>' + '<br /><ul>' + errMsgAccepter + '</ul>'
    }

    return '<b>' + RULES_VIOLATION_ERR_MSG_FOR_SWAP + '</b><br /><br />' + message
  }

  // TODO: remove ternary operator for DialogActions and keep Button and ConfirmGiveUpShift when Retract shift implemented

  if (shift) {
    return (
      <Dialog
        disableEscapeKeyDown
        open={props.open}
        onClose={handleClose}
        aria-label="responsive-dialog-title"
        PaperProps={{
          sx: { margin: '5px' },
        }}
      >
        <DialogTitle sx={styles.titleContainerModal} id="responsive-dialog-title">
          Message
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} sm={12} sx={styles.displaySegmentContainerModal}>
            <Typography>
              You got this message based on your recent shift swap activity related to your below shift.
            </Typography>
          </Grid>
          <div style={styles.requestContainerModal}>
            <DisplaySegmentShiftSwap shift={shift.requester} ownShift={true} styles={styles} />
          </div>
          {renderDialogInfoText()}
        </DialogContent>
        <DialogActions>
          <>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleClose}
              sx={styles.mobileButton}
              data-cy="initiate-swap-Button"
            >
              Close
            </Button>
          </>
        </DialogActions>
      </Dialog>
    )
  } else {
    return null
  }
}

export default ShiftSwapMessageModal
