import { Autocomplete, Skeleton, TextField } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useManagementScopes } from '../../api/userAccess/useManagementScopes'
import { setSelectedLocation } from '../../store/leader/actionCreator'

const InputLocationSelector = () => {
  const dispatch = useDispatch()
  const { session } = useAuth()
  const homeLocationString = session?.userInfo?.locationid.toString()
  const selectedLocation = useSelector((state) => state.leader.selectedLocation)
  const { data: managementScopes, error, isLoading } = useManagementScopes()

  useEffect(() => {
    if (!selectedLocation && homeLocationString) {
      dispatch(setSelectedLocation(homeLocationString))
    }
  }, [dispatch, homeLocationString, selectedLocation])

  const handleSelectNewLocation = (event, value) => {
    dispatch(setSelectedLocation(value))
  }

  const renderTextField = (locationsList) => {
    return (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        aria-label={'Select location'}
        defaultValue={selectedLocation}
        onChange={handleSelectNewLocation}
        options={locationsList}
        sx={{ width: 100 }}
        renderInput={(params) => <TextField {...params} label="Location" />}
      />
    )
  }

  const renderSkeleton = () => {
    return <Skeleton height={72} width={72} />
  }

  const renderLocationSelectorContents = () => {
    if (isLoading) {
      return renderSkeleton()
    } else if (managementScopes?.data?.management_scopes) {
      let locations = managementScopes.data.management_scopes.map((a) => a.location_number)
      if (!locations.includes(homeLocationString)) locations.push(homeLocationString)
      return renderTextField(locations)
    } else if (error) {
      return renderTextField([homeLocationString])
    }
  }

  return <>{renderLocationSelectorContents()}</>
}

export default InputLocationSelector
