import { Grid } from '@mui/material'
import { Element } from 'react-scroll'
import AvailabilityAuditItem from './AvailabilityAuditItem'

const AvailabilityAuditList = ({ auditData }) => {
  return (
    <Element name="scroll-to-element" id="scroll-to-element" className="element">
      <Grid container>
        <Grid item xs={12} md={12}>
          {auditData.map((item, index) => {
            return (
              <AvailabilityAuditItem
                auditItem={item}
                index={index + 1}
                requestId={item.availability_id}
                id={`${item.availability_id}_${item.audit_id}`}
              />
            )
          })}
        </Grid>
      </Grid>
    </Element>
  )
}

export default AvailabilityAuditList
