import { Grid, Paper, styled } from '@mui/material'

import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { useLeaderFilterConfiguration } from '../../../api/configuration/useConfiguration'
import { useLocationDetails } from '../../../api/location/useLocationDetails'
import { useUserFeatures } from '../../../api/userAccess/useUserFeatures'
import { PUNCH_CORRECTION } from '../../../constants/RequestConstants'
import { getConfigDetails } from '../../../constants/leaderConstants'
import { setControlPanelSettings, setLeaderConfig } from '../../../store/leader/actionCreator'
import { OFFICE_MANAGER_USER_GROUP } from '../../../utils/roles'
import HeaderTitle from '../../Header/HeaderTitle'
import BottomNavigationBar from '../../HomePage/BottomNavigationBar'
import LeaderNavigationBar from '../../Leader/LeaderNavigationBar'
import Admindropdown from '../../admin/AdminSelect'
import { QueryBoundaries } from '../wrappers/QueryBoundaries'

export const MainScrollPaper = styled(Paper)(({ theme }) => ({
  ...theme.mainScrollContainer,
  height: '100%',
}))

export const MainScrollContainer = styled(Grid)(({ theme }) => ({
  ...theme.mainScrollContainer,
  [theme.breakpoints.up('md')]: {
    marginBottom: '20px',
  },
}))

export const MainContentDiv = styled(Grid)(({ theme }) => ({
  ...theme.mainScrollContainer,
}))

export const MainContainerDiv = styled(Grid)(({ theme }) => ({
  ...theme.mainContainerPosition,
}))

export const TeamMemberContainer = styled(Grid)(({ theme }) => ({
  ...theme.mainContainerPosition,
  ...theme.mainScrollContainer,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const LeaderContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  maxWidth: '100%',
  ...theme.mainScrollContainer,
  ...theme.mainContainerPosition,
}))

export const AdminContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  height: 'calc(100% - 64px)',
  display: 'flex',
  flexFlow: 'column',
  maxWidth: '100%',
}))

// could add some type of metrics wrapper here too or at a higher level
export function TeamMemberLayout() {
  return (
    <>
      <TeamMemberContainer>
        <Outlet />
      </TeamMemberContainer>
      <BottomNavigationBar />
    </>
  )
}

export function LeaderLayout() {
  const dispatch = useDispatch()

  const { data: locationDetails } = useLocationDetails()
  const { data: userFeatures } = useUserFeatures()

  const config = useMemo(() => getConfigDetails(locationDetails, userFeatures), [locationDetails, userFeatures])

  useEffect(() => {
    if (locationDetails && userFeatures) {
      dispatch(setLeaderConfig(config))
    }
  }, [locationDetails, userFeatures, dispatch, config])

  useEffect(() => {
    if (userFeatures?.user_group === OFFICE_MANAGER_USER_GROUP) {
      let filterOverrides = { statuses: { selectedValues: [] } }

      dispatch(
        setControlPanelSettings(
          PUNCH_CORRECTION,
          config.configMap[PUNCH_CORRECTION].defaultStartDate,
          config.configMap[PUNCH_CORRECTION].defaultEndDate,
          filterOverrides,
          config.configMap[PUNCH_CORRECTION].searchText,
          config.configMap[PUNCH_CORRECTION].selectedSortByIndex,
          config.configMap[PUNCH_CORRECTION].sortOrder,
        ),
      )
    }
  }, [config.configMap, dispatch, userFeatures?.user_group])

  // will fetch and set filter data.
  useLeaderFilterConfiguration()

  return (
    <>
      <LeaderContainer>
        <Outlet />
      </LeaderContainer>
      <LeaderNavigationBar />
    </>
  )
}

export function AdminLayout() {
  return (
    <AdminContainer>
      <HeaderTitle title={'Admin'} />
      <Admindropdown />
      <QueryBoundaries>
        <Outlet />
      </QueryBoundaries>
    </AdminContainer>
  )
}

export default function MainLayout({ children }) {
  return (
    <MainContainerDiv>
      <MainScrollPaper square>{children}</MainScrollPaper>
    </MainContainerDiv>
  )
}
