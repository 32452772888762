import { Info } from '@mui/icons-material'
import { Backdrop, Box, CircularProgress, Grid, useTheme } from '@mui/material'
import { useState } from 'react'
import { usePunchedInStatus } from '../api/timecard/useTimeCardDaily'
import { useUserFeatures } from '../api/userAccess/useUserFeatures'
import { NON_EXEMPT_USER_GROUPS } from '../constants/userConstants'

const getStyles = (theme) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  gridItem: {
    margin: theme.spacing(1),
  },
  infoIcon: {
    marginRight: '4px',
    fontSize: '12px',
  },
  infoMessage: {
    ...theme.infoMessages,
    padding: '0px 36px 0px 36px',
  },
})

export default function Survey() {
  const theme = useTheme()
  const styles = getStyles(theme)
  const [isSurveyLoading, setIsSurveyLoading] = useState(false)
  const { punchedInStatus, isFetching } = usePunchedInStatus(NON_EXEMPT_USER_GROUPS)
  const { data: userFeatures } = useUserFeatures()

  const renderNotPunchedIn = () => {
    return (
      <Box sx={styles.mainContainerPosition}>
        <Grid container item xs={11.5} md={11.5} sx={styles.gridItem} minHeight={'25px'}>
          <Box component={'p'} sx={styles.infoMessage}>
            <Info sx={styles.infoIcon} />
            You must be on the clock to access this survey. If you need to access this screen, please clock in.
          </Box>
        </Grid>
      </Box>
    )
  }

  const renderSurvey = () => {
    return (
      <iframe
        id="sus-survey"
        width="auto"
        height="100%"
        title="Sus Survey"
        src={'https://survey.medallia.com/?target-sus&product=target_sus_product_mytime_for_target'}
        onLoad={() => setIsSurveyLoading(false)}
        onLoadStart={() => setIsSurveyLoading(true)}
      ></iframe>
    )
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff' }} open={isFetching || isSurveyLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!punchedInStatus?.isPunchedIn && NON_EXEMPT_USER_GROUPS.includes(userFeatures?.user_group)
        ? renderNotPunchedIn()
        : renderSurvey()}
    </>
  )
}
