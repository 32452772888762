import { useAuth } from '@praxis/component-auth'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { REQUEST_TYPES } from '../../constants/RequestConstants'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import {
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
  SUCCESS_ON_SAVE,
  UNABLE_TO_SAVE,
} from '../../constants/errorConstants'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { formatErrorCode } from '../../utils/ErrorHandling'

const updateRequest = async (requestType, body) => {
  let apiUrl = ''

  const timeOffApiUrl = `${apiConfig.timeOff.url}` + '/time_off_request/statuses?key=' + `${apiConfig.timeOff.key}`
  if (requestType === REQUEST_TYPES.time_off.type) {
    apiUrl = timeOffApiUrl
  }
  const { data } = await axios.post(apiUrl, body, BASIC_REQUEST_CONFIG)

  return data
}

/**
 * this hook returns a react query hook that has function mutate in it. use mutate(request) to make the POST api call
 * @param locationIds query key options to be invalidated
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
export const useUpdateRequest = (requestType) => {
  const { isAuthenticated, login } = useAuth()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  const dispatch = useDispatch()

  return useMutation((body) => updateRequest(requestType, body), {
    onSuccess: () => {
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
    },
    onError: (error) => {
      if (ERROR_CODES_SHOW_MESSAGE.includes(error?.response?.data.code)) {
        dispatch(showNotificationError(true, error?.response?.data.message))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE + ' ' + formatErrorCode(error?.response?.data, ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
          ),
        )
      }
    },
  })
}
