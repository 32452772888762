const ShiftSwapIcon = (props) => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Swap shift</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle id="Oval" fill="#CB0202" cx="12.5" cy="12.5" r="12.5"></circle>
        <polygon
          id="Path"
          fill="#FFFFFF"
          points="7.07905534 9.66741259 13.7773506 9.66741259 13.7773506 5.63409552 21.4873543 11.6329814 13.7773506 11.6329814 7.07905534 11.6329814"
        ></polygon>
        <polygon
          id="Path"
          fill="#FFFFFF"
          transform="translate(10.874114, 16.832633) rotate(-180.000000) translate(-10.874114, -16.832633) "
          points="3.66996443 17.9582938 10.3682597 17.9582938 10.3682597 13.944944 18.0782634 19.720323 10.3682597 19.720323 3.66996443 19.720323"
        ></polygon>
      </g>
    </svg>
  )
}
export default ShiftSwapIcon
