import TollTwoToneIcon from '@mui/icons-material/TollTwoTone'
import { differenceInMinutes } from 'date-fns'
import moment from 'moment'
import RequestStatusIcon from '../components/Requests/RequestStatusIcon'
import { calculateMinutesInSpan } from './DateUtil'

export const getLabelValueObj = (label, value) => {
  return { label: label, value: value }
}

export const isRequestDetailsStatusesSame = (details) => {
  let statuses = details?.map((detail) => {
    return detail.status
  })
  return statuses?.length === 1 || (statuses?.length > 1 && new Set(statuses).size === 1)
}

export const isAnyRequestDetailsFailed = (details) => {
  return details?.some((detail) => detail.status.startsWith('Failed'))
}

export const isMultipleRequestDetails = (details) => {
  return details?.length > 1
}

export const getShiftDuration = (details) => {
  let totalMinutes = details
    ?.map((detail) => calculateMinutesInSpan(moment(detail.start_time), moment(detail.end_time)))
    .reduce((prev, next) => prev + next)
  return totalMinutes / 60
}

export const getRequestStatusIcons = (details) => {
  let requestIcons = details?.map((detail, i) => {
    return <RequestStatusIcon key={i} requestType={detail.type} status={detail.status} isTimeOffTeamMember={false} />
  })
  return isMultipleRequestDetails(details) && !isRequestDetailsStatusesSame(details) ? (
    <TollTwoToneIcon />
  ) : (
    requestIcons?.[0]
  )
}

export const convertRequestDetails = (requestDetails) => {
  return requestDetails.map((requestDetail) => {
    let minutes = differenceInMinutes(new Date(requestDetail.end_time), new Date(requestDetail.start_time))
    return { start_time: requestDetail.start_time, minutes, type: requestDetail.type }
  })
}
