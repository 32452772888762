import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { format } from 'date-fns'
import { useState } from 'react'
import InnerLoader from '../../Loader/InnerLoader'

const SAVE_DIALOG_TITLE = 'Are you sure you want to save?'

const SubmitCallOffModal = ({
  submitDialogOpen,
  handleYesForSubmitAction,
  handleNoForSubmitAction,
  startDateTime,
  type,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleYes = () => {
    if (buttonDisabled) return
    setButtonDisabled(true)
    handleYesForSubmitAction()
    setTimeout(() => {
      setButtonDisabled(false)
    }, 1000)
  }

  const handleNo = () => {
    handleNoForSubmitAction()
  }

  return (
    <Dialog
      data-cy="requestTimeOffSubmitDialog"
      aria-labelledby="alert-dialog-title"
      open={submitDialogOpen}
      aria-describedby="save-dialog-content"
      aria-modal={true}
      keepMounted
    >
      <DialogTitle id="alert-dialog-title">{SAVE_DIALOG_TITLE}</DialogTitle>
      <DialogContent id="save-dialog-content">
        <DialogContentText color={'textPrimary'}>
          You are submitting a <strong>{type}</strong> notification for your shift that starts at{' '}
          {format(startDateTime, 'h:mma')} on {format(startDateTime, 'MMMM d, yyyy')}.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          NO
        </Button>
        <Button onClick={handleYes} color="primary" variant="contained" aria-label="Yes" disabled={buttonDisabled}>
          {buttonDisabled ? <InnerLoader size={25} /> : 'YES'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubmitCallOffModal
