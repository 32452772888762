import GroupIcon from '@mui/icons-material/Group'
import SpeedIcon from '@mui/icons-material/Speed'
import { BottomNavigation, BottomNavigationAction, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useUser } from '../../auth/hooks/useUser'
import { useRouteMatch } from '../../hooks/useRouteMatch'

const getStyles = (theme) => ({
  bottomNavigationRoot: {
    backgroundColor: theme.palette.tertiary.light,
    flex: '0 0 auto',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '10px',
    },
  },
  disabledStateStyles: {
    color: '#b6b8bd',
  },
})

const LeaderNavigationBar = () => {
  const selectedCategory = useSelector((state) => state.leader.selectedCategory)

  const routeMatch = useRouteMatch(['/leader/dashboard', '/leader/myteam'])
  const currentTab = routeMatch?.pattern?.path

  const { user } = useUser()

  const { id } = useParams()
  const theme = useTheme()
  const styles = getStyles(theme)

  const readOnlyLeader =
    user.is('punch_correction', 'leader', 'read', true) ||
    user.is('time_off', 'leader', 'read', true) ||
    user.is('availability', 'leader', 'read', true)

  // don't render bottom nav on a leader category detail page
  if (id) {
    return <></>
  }

  return (
    <BottomNavigation showLabels sx={styles.bottomNavigationRoot} value={currentTab}>
      <BottomNavigationAction
        disabled={readOnlyLeader}
        label="Dashboard"
        value="/leader/dashboard"
        icon={<SpeedIcon />}
        data-cy="dashboardBtnBottomNav"
        component={Link}
        to="dashboard"
        sx={readOnlyLeader ? styles.disabledStateStyles : null}
      />
      <BottomNavigationAction
        label="My Team"
        value={`/leader/myteam`}
        icon={<GroupIcon />}
        data-cy="myTeamBtnBottomNav"
        component={Link}
        to={`/leader/myteam${selectedCategory ? '/' + selectedCategory : ''}`}
      />
    </BottomNavigation>
  )
}

export default LeaderNavigationBar
