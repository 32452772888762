import jwt_decode from 'jwt-decode'
import {
  ACCESS_SUCCESS,
  GET_USER_DETAILS,
  LOCATION_SUCCESS,
  SET_COMMUNICATION_OPEN,
  SET_CONFIRM,
  SET_HEADER_TITLE,
  SHOW_AUTH_POPUP,
} from './actionType'

export function showAuthPopup(popupType) {
  return {
    type: SHOW_AUTH_POPUP,
    payload: popupType,
  }
}

export function setHeaderTitle(headerTitle) {
  return {
    type: SET_HEADER_TITLE,
    payload: headerTitle,
  }
}

export function handleGetScreenAccessSuccess(data) {
  return {
    type: ACCESS_SUCCESS,
    data,
  }
}

// TODO: cleanup, kept for non functional components that still use location details. glocation details are fetched at App layer through useLocationDetails and through a dispatch will persist in redux
export function handleGetLocationDetailsSuccess(data) {
  return {
    type: LOCATION_SUCCESS,
    data,
  }
}

// TODO: cleanup, kept this for components using it, not needed as session from auth has this information readily available
export function getUserDetails(userInfo) {
  return (dispatch) => {
    const idToken = localStorage.getItem('id_token') || localStorage.getItem('id_token_cypress') // currently cypress don't create id token, so we should pass one.
    let decoded = jwt_decode(idToken)
    const userObj = {}
    userObj.empid = userInfo.empid || decoded.empid
    userObj.locationid = userInfo.locationid || decoded.locationid

    dispatch(handleGetUserDetailsSuccess(userObj))
  }
}

export function handleGetUserDetailsSuccess(userObj) {
  return {
    type: GET_USER_DETAILS,
    userObj,
  }
}

export function setConfirm(confirmObject) {
  return {
    type: SET_CONFIRM,
    payload: confirmObject,
  }
}

export function setCommunicationOpen(payload) {
  return {
    type: SET_COMMUNICATION_OPEN,
    payload,
  }
}
