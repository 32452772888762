import JobIcon from '@mui/icons-material/Label'
import { Typography, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import { MultiClickButton as ConfirmShiftButton } from '../../utils/MultiClickButton'
import DisplayAvailableShiftSegment from './DisplayAvailableShiftSegment'

const getStyles = (theme) => ({
  mobileButton: {
    maxWidth: 120,
  },
  addtionalJobsContainer: {
    paddingTop: '1em',
  },
  displaySegmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
  },
  jobContainerAtPopup: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  iconMargin: {
    marginRight: '.25em',
  },
  jobName: {
    paddingTop: '3px',
  },
  locationNotice: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
})

const ConfirmCoverShiftModal = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { open, selectedShift, shiftAvailableDate, handleClose, handleSubmit, isHomeLocation } = props

  const renderJob = (jobs) => {
    return (
      <React.Fragment>
        {jobs.map((job, index) => (
          <div style={styles.jobContainerAtPopup} key={index}>
            <JobIcon color="primary" sx={styles.iconMargin} />
            <Typography component={'span'} variant="body2" sx={styles.jobName}>
              {job}
            </Typography>
          </div>
        ))}
      </React.Fragment>
    )
  }

  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason)
          }
        }}
      >
        <DialogTitle id="responsive-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent sx={styles.displaySegmentContainer}>
          {!isHomeLocation ? (
            <Typography sx={styles.locationNotice} variant="subtitle1" component={'h2'} color={'primary'}>
              Note: shift is not at your home location
            </Typography>
          ) : (
            <></>
          )}
          <Typography variant="body2" component={'h2'}>
            Picking up <b> {selectedShift.shift_hours} hour</b> shift on <b> {shiftAvailableDate}</b>
          </Typography>
          <DisplayAvailableShiftSegment shift={selectedShift} renderedOn="modal" isHomeLocation={isHomeLocation} />
          {selectedShift.jobList && selectedShift.jobList.length > 0 && (
            <div style={styles.addtionalJobsContainer}>
              <Typography variant="body2">This shift contains job transfers:</Typography>
              {renderJob(selectedShift.jobList)}
            </div>
          )}
          <Typography variant="body2">
            Note: Ensure you are fully trained for the job before picking up a shift. Schedules/Available shifts may
            take up to 3 minutes to refresh.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ConfirmShiftButton
            label="Confirm"
            handleClick={handleSubmit}
            handleDoubleClick={handleSubmit}
            processIndicator={false}
            styles={styles}
            pageName="coverShift"
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmCoverShiftModal
