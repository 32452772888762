import { useAuth } from '@praxis/component-auth'
import { useCallback, useEffect } from 'react'
import { useUser } from '../../../auth/hooks/useUser'

// Issue: unlikely, but might catch a device that is not a myDevice
const isMyDevice = /\sTC\d{2}/

export const useBarcodeAccess = () => {
  const { user } = useUser()
  const { session } = useAuth()

  function setBarcodeScreenAccess() {
    localStorage.setItem('barcode_team_member', 'true')
  }

  const setBarcodeData = useCallback(() => {
    // save tm id in format needed for barcode
    let paddedEmpId = session?.userInfo?.empid.padStart(11, '480')
    localStorage.setItem('my_time_barcode', window.btoa(paddedEmpId))

    // save/update expiration timestamp in ms since if we get here we are logged in
    const expiration = Date.now() + 90 * 86400000 // 86400000 // 86,400,000ms per day
    localStorage.setItem('my_time_barcode_exp', expiration.toString())
  }, [session?.userInfo?.empid])

  useEffect(() => {
    if (user) {
      if (user.is('barcode', 'team_member') && !isMyDevice.test(navigator.userAgent)) {
        setBarcodeScreenAccess()
        setBarcodeData()
      } else {
        localStorage.removeItem('barcode_team_member')
        localStorage.removeItem('my_time_barcode')
        localStorage.removeItem('my_time_barcode_exp')
      }
    }
  }, [session, user, setBarcodeData])
}
