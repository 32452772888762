import JobIcon from '@mui/icons-material/Label'
import LocationIcon from '@mui/icons-material/Place'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { truncateString } from '../../utils/strings'
import ReviewSwapRequestsAction from '../SwapShift/ReviewSwapRequestsAction'
import ManageShift from './ManageShift'

const getStyles = (theme) => ({
  startTimeItem: {
    flexGrow: '1',
    paddingTop: '9px',
    minHeight: '32px',
  },
  endTimeItem: {
    flexGrow: '1',
    paddingTop: '7px',
    minHeight: '32px',
  },
  locationContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '6px',
  },
  locationName: {
    paddingTop: '3px',
    color: theme.palette.secondary.dark,
  },
  jobContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobName: {
    paddingTop: '3px',
  },
  paycodeEditsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1em',
  },
  paycodeEdits: {
    fontWeight: '400',
  },
  iconMargin: {
    marginRight: '.25em',
  },
  personIconMargin: {
    marginRight: '.6em',
  },
  addtionalJobsContainer: {
    paddingTop: '1em',
  },
  indicator: {
    marginTop: '-2px',
    marginLeft: '-15px',
    marginRight: '5px',
    fontSize: '24px',
    color: 'black',
    zIndex: '1',
  },
  displaySegmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
    justifyContent: 'center',
  },
  dateContainer: {
    display: 'flex',
  },
  shiftLabelContainer: {
    marginTop: 6,
    marginLeft: 12,
    display: 'inline-Block',
  },
  additionalShiftLabels: {
    margin: '6px 6px',
  },
  shiftLabel: {
    border: '1px solid #333',
    color: '#333',
    padding: '1px 5px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    backgroundColor: '#fff',
  },
  multiShiftLabel: {
    border: '1px solid #333',
    color: '#333',
    padding: '1px 5px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    backgroundColor: '#fff',
    boxShadow: '4px -3px #fff, 5px -4px #000, 3px -4px #000, 5px -2px #000',
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingTop: '0',
    paddingBottom: '0',
  },
  teamMemberAvailableShiftButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  postShiftButton: {
    minWidth: '50px',
  },
  dailyLink: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.secondary.dark,
  },
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  description: {
    margin: theme.spacing(1),
    fontSize: 'medium',
    fontWeight: 'bold',
    paddingTop: '.5em',
  },
  gridItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '6px',
    marginBottom: '6px',
  },
  nextAvailableShiftCard: {
    marginRight: theme.spacing(1),
  },
  nextAvailableWeekCard: {
    marginLeft: theme.spacing(1),
  },
  weeklyTimecardLeft: {
    marginLeft: 0,
    marginRight: 0,
  },
  weeklyTimecardRight: {
    marginLeft: 0,
    marginRight: 0,
    maxWidth: '54%',
  },
  loadingIconContainer: theme.loadingIconContainer,
  homePageCardContainer: {
    display: 'flex',
    flexGrow: '1',
    gap: 5,
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  homePageCardTitle: {
    fontWeight: 'bold',
  },
  displaySegmentContainerModal: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1em',
    justifyContent: 'center',
  },
  displaySegmentContainerSwap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0.2em',
    justifyContent: 'center',
  },
  nxtAvlTotal: {
    marginTop: '8px',
  },
  nxtAvlBtnCls: {
    marginTop: '20px',
  },
  dividerStyle: {
    border: '1px solid #AFAFAF',
  },
  redText: {
    color: '#cc0000',
  },
  boldText: {
    fontWeight: 'bold',
  },

  shiftSwapWeekTitle: {
    textAlign: 'center',
  },
  tableItem: {
    display: 'table-row',
  },
  dialogPaper: {
    margin: '5px',
  },
  titleContainerModal: {
    padding: '16px 24px 5px',
  },
  requestContainerModal: {
    margin: '-4px',
    padding: '5px 10px 0 10px',
    border: '1px solid #aeaeae',
    borderRadius: '7px',
    position: 'relative',
  },
  verticalGap: {
    padding: '10px 5px',
    textAlign: 'center',
    width: '90%',
  },
})

const DisplaySegments = (props) => {
  const { schedule, segments, handleDailyClick, showPostShiftButton, showSearchSwapButton } = props

  const theme = useTheme()
  const styles = getStyles(theme)

  const renderNextShiftDate = (scheduleDate) => {
    const outputDateFormat = 'dddd, MMMM D'
    if (scheduleDate) {
      return (
        <div style={styles.dateContainer}>
          <Typography variant="h6" data-cy="nextSchedDisplaySegDate">
            {moment(scheduleDate)?.format(outputDateFormat)}
          </Typography>
          {schedule.total_display_segments > 0 && displayShiftLabels()}
        </div>
      )
    }
  }

  const displayShiftLabels = () => {
    let uniqueShiftLabels = new Set()
    for (let segment of segments) {
      if (segment.shift_label) {
        uniqueShiftLabels.add(segment.shift_label)
      }
    }
    let shiftLabels = Array.from(uniqueShiftLabels)

    for (let i = 0; i < shiftLabels.length; i++) {
      shiftLabels[i] = truncateString(shiftLabels[i], 3)
    }

    if (!shiftLabels || shiftLabels.length === 0) {
      return null
    } else if (shiftLabels.length === 1) {
      return (
        <Typography sx={styles.shiftLabelContainer}>
          <span style={styles.shiftLabel}>{`${shiftLabels[0]}`}</span>
        </Typography>
      )
    } else if (shiftLabels.length > 1) {
      return (
        <React.Fragment>
          <Typography sx={styles.shiftLabelContainer}>
            <div style={styles.shiftLabel}>
              <span>{`${shiftLabels[0]}`}</span>
            </div>
          </Typography>
          <Typography sx={styles.additionalShiftLabels}>
            <span>{` +${shiftLabels.length - 1}`}</span>
          </Typography>
        </React.Fragment>
      )
    }
  }

  const renderLocation = (location) => {
    if (location) {
      return (
        <React.Fragment>
          <LocationIcon color="primary" sx={styles.iconMargin} />
          <Typography variant="body2" sx={styles.locationName} data-cy="nextSchedDisplaySegLoc">
            {location}
            {props.locationId && parseInt(props.locationId) !== parseInt(location) ? '*' : null}
          </Typography>
        </React.Fragment>
      )
    }
  }

  const renderJob = (jobName, totalJobs) => {
    if (jobName && totalJobs != null) {
      return (
        <React.Fragment>
          <JobIcon color="primary" sx={styles.iconMargin} />
          {totalJobs > 1 ? renderIndicator() : null}
          <Typography variant="body2" sx={styles.jobName}>
            {jobName}
          </Typography>
        </React.Fragment>
      )
    }
  }

  const renderIndicator = () => {
    return <div style={styles.indicator}>*</div>
  }

  const renderNextShiftDisplaySegment = (schedule, displaySegment, counter) => {
    const inputTimeFormat = 'YYYY-MM-DD hh:mm:ss'
    const outputTimeFormat = 'hh:mmA'

    if (displaySegment) {
      let segmentStartTime = moment(displaySegment.segment_start, inputTimeFormat)?.format(outputTimeFormat)
      let segmentEndTime = moment(displaySegment.segment_end, inputTimeFormat)?.format(outputTimeFormat)
      let scheduleDate = moment(schedule?.schedule_date, moment.HTML5_FMT.DATE)

      let jobs = ''
      if (displaySegment.total_jobs > 0) {
        jobs = displaySegment.job_name
        if (displaySegment.total_jobs > 1) {
          jobs = 'multiple jobs'
        }
      }
      return (
        <Grid container key={counter} alignItems={'center'}>
          <Grid item xs={9}>
            <Link
              to={'/team-member/dailySchedulePage/schedule'}
              onClick={handleDailyClick}
              role="link"
              tabIndex="0"
              style={{ textDecoration: 'none', display: 'flex', flexDirection: 'row', color: '#000000' }}
              aria-label={`${jobs} shift from ${segmentStartTime} to ${segmentEndTime} at location ${
                displaySegment.location
              } on ${scheduleDate?.format('dddd, MMMM D')}. Click to view daily view`}
              sx={styles.dailyLink}
            >
              <Grid container aria-hidden={true}>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={styles.startTimeItem} data-cy="nextSchedDisplaySegStartTime">
                    {segmentStartTime}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div style={styles.locationContainer}>{renderLocation(displaySegment.location)}</div>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={styles.endTimeItem} data-cy="nextSchedDisplaySegEndTime">
                    {segmentEndTime}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div style={styles.jobContainer}>{renderJob(displaySegment.job_name, displaySegment.total_jobs)}</div>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid item xs={3}>
            <ManageShift
              showPostShiftButton={showPostShiftButton}
              showSearchSwapButton={showSearchSwapButton}
              schedule={schedule}
              counter={counter}
              displaySegment={displaySegment}
              handleDailyClick={handleDailyClick}
              styles={styles}
            />
            <ReviewSwapRequestsAction
              schedule={schedule}
              styles={styles}
              counter={counter}
              displaySegment={displaySegment}
              handleDailyClick={handleDailyClick}
            />
          </Grid>
          {displaySegment.isShiftBeingCovered && (
            <Typography variant="body2" sx={styles.preInfoText} data-cy="isShiftBeingCovered">
              This shift is in the process of being covered.
            </Typography>
          )}
          {displaySegment.isCoveringShift && (
            <Typography variant="body2" sx={styles.preInfoText} data-cy="isCoveringShift">
              The shift you have picked up will appear here shortly.
            </Typography>
          )}
        </Grid>
      )
    }
  }

  const renderNextShiftDisplaySegments = (schedule, id) => {
    if (get(schedule, 'display_segments')) {
      return schedule.display_segments.map((element, index) => {
        return renderNextShiftDisplaySegment(schedule, element, id + '_' + String(index))
      })
    }
  }

  const renderPayCodeEdits = (schedule) => {
    if (get(schedule, 'paycode_edits')) {
      return (
        <Grid item xs={12} md={12} sx={styles.paycodeEditsContainer}>
          {renderPayCodeEditItems(schedule.paycode_edits)}
        </Grid>
      )
    } else {
      return null
    }
  }

  const renderPayCodeEditItems = (payCodeEdits) => {
    let list = []

    Array.from(payCodeEdits).forEach(function (element, i) {
      list.push(
        <Grid container key={i}>
          <Grid item xs={6} md={7}>
            <Typography variant="body2" sx={styles.paycodeEdits} data-cy="nextSchedDisplaySegPayCodePart1">
              {`${element.paycode_name} - ${element.paycode_start_time}`}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5} pl={6}>
            <Typography variant="body2" sx={styles.paycodeEdits} data-cy="nextSchedDisplaySegPayCodePart2">
              {element.paycode_total} Hrs
            </Typography>
          </Grid>
        </Grid>,
      )
    })
    return list
  }

  return (
    <>
      {renderNextShiftDate(props.scheduleDate)}
      {renderNextShiftDisplaySegments(props.schedule, props.id)}
      {renderPayCodeEdits(props.schedule)}
    </>
  )
}

export default DisplaySegments
