import { SET_COMMUNICATION_FORM_VALUES } from './actionType'

const helperDefaultMessage =
  'Starting <b>10/10/2023</b> you will have access to this feature.<br><br>Navigate to the <b>feature</b> page to try it out!<br><br><hr><sub><em><b>Note: </b>You still need leader approval</em></sub>'

const defaultCommunicationFormValues = {
  id: null,
  title: 'New Communication',
  start_timestamp: '',
  end_timestamp: '',
  audience_locations: [],
  audience_features: [],
  audience_roles: [],
  text: helperDefaultMessage,
}

const initialInputSelectValues = {
  audience_features: '',
  audience_roles: '',
}

export const initialState = {
  communicationFormValues: defaultCommunicationFormValues,
  currentCommunicationInitialValues: defaultCommunicationFormValues,
  inputSelectValues: initialInputSelectValues,
}

export default function communicationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_COMMUNICATION_FORM_VALUES: {
      const { payload } = action
      return {
        ...state,
        communicationFormValues: payload,
      }
    }
    default:
      return state
  }
}
