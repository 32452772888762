import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { handleGetScreenAccessSuccess } from '../../store/layout/actionCreator'
import { showNotificationError } from '../../store/notification/actionCreator'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'

/**
 * In praxis, Authorization header is now automatically attached to any request going to *.target.com or *.tgt
 * DOCUMENTATION: https://praxis.prod.target.com/guides/authentication
 */
const getUserFeatures = async (workerId, locationId) => {
  let apiUrl =
    `${apiConfig.userAccess.url}` +
    '/user_features' +
    '?worker_id=' +
    workerId +
    '&location_id=' +
    locationId +
    '&key=' +
    `${apiConfig.userAccess.key}`

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  let list = []
  Array.from(data.features).forEach((feature) => {
    list.push(feature.name)
  })

  data.screenAccess = list

  return data
}

export function useUserFeatures() {
  const { session, isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const authenticated = isAuthenticated()

  const userFeatures = useQuery(
    ['userFeatures'],
    () => getUserFeatures(padEmpIdWithZeros(session?.userInfo?.empid, 10), session?.userInfo.locationid),
    {
      staleTime: 1000 * 60 * 10, // 10 min
      enabled: authenticated,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
      keepPreviousData: true,
      onSuccess: (data) => {
        // TODO: cleanup and transition to not use redux for this data
        dispatch(handleGetScreenAccessSuccess(data))
      },
      // 🚀 only server errors will go to the Error Boundary
      useErrorBoundary: (error) => error.response?.status >= 500,
    },
  )

  useEffect(() => {
    if (userFeatures.error) {
      dispatch(showNotificationError(true, `Error loading user details`))
    }
  }, [userFeatures.error, dispatch])

  return userFeatures
}
