interface ListItem {
  updated_timestamp: string
  [key: string]: any // Allows for other parameters
}

export function removeOldestElement(list: ListItem[]): ListItem[] {
  if (list.length === 0) return list

  // Sort the list by updated_timestamp in ascending order
  list.sort((a, b) => new Date(a.updated_timestamp).getTime() - new Date(b.updated_timestamp).getTime())

  // Remove the first element (oldest)
  list.shift()

  return list
}
