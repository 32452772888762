import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'

const SAVE_DIALOG_TEXT = 'Submitting a punch correction is a paid activity and must be submitted while on the clock.'

const REMINDER_MESSAGE = (
  <span>
    {
      'As a reminder, you may be subject to corrective action, up to and including termination, if you work when you are not clocked in. By clicking Accept, you acknowledge that you are clocked in and not on a rest or meal break.'
    }
  </span>
)

const styles = {
  subTitle: {
    fontWeight: 600,
  },
  voluntarySelection: {
    marginTop: '20px',
  },
}

const FixAPunchAcceptModal = ({ fixAPunchDialogOpen, handleGoBackAction, handleAcceptAction }) => {
  const handleAccept = () => {
    handleAcceptAction()
  }

  const handleGoBack = () => {
    handleGoBackAction()
  }

  return (
    <Dialog
      open={fixAPunchDialogOpen}
      data-cy="requestTimeOffSubmitDialog"
      role="dialog"
      aria-label="Requesting a Punch Correction"
      // aria-labelledby="request-a-pc-dialog-title"
      aria-describedby="request-a-pc-dialog-desc-1 request-a-pc-dialog-desc-2"
      aria-modal={true}
      keepMounted
    >
      {/* <DialogTitle id="request-a-pc-dialog-title">Requesting a Punch Correction</DialogTitle> */}
      <section tabIndex={0}>
        <DialogContent>
          <DialogContentText id="request-a-pc-dialog-desc-1" sx={styles.voluntarySelection}>
            {SAVE_DIALOG_TEXT}
          </DialogContentText>
          <DialogContentText id="request-a-pc-dialog-desc-2" sx={styles.voluntarySelection}>
            {REMINDER_MESSAGE}
          </DialogContentText>
        </DialogContent>
      </section>
      <DialogActions>
        <Button onClick={handleGoBack} color="primary">
          Go Back
        </Button>
        <Button onClick={handleAccept} color="primary" variant="contained">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FixAPunchAcceptModal
