import Typography from '@mui/material/Typography'
import React from 'react'
import RequestTimeOffDetail from './RequestPaidTimeOffDetail'

const RequestPaidTimeOffDetailsByDate = (props) => {
  const { requestDate, date, requestDetailPerDate, handleRequestDetailPerDate, messagesPerDate } = props

  const handleRowDetail = (requestDetailPerDateIndex, rowDetail) => {
    requestDetailPerDate[requestDetailPerDateIndex] = rowDetail
    handleRequestDetailPerDate(date, requestDetailPerDate)
  }

  const handleAddRow = (requestDetailPerDateIndex) => {
    let newRequestDetailPerDate = []
    requestDetailPerDate.forEach((element, index) => {
      newRequestDetailPerDate.push(element)
      if (index === requestDetailPerDateIndex) {
        let initialSubRow = {
          type: '',
          startTime: null,
          hours: '',
          minutes: '',
        }
        newRequestDetailPerDate.push(initialSubRow)
      }
    })
    handleRequestDetailPerDate(date, newRequestDetailPerDate)
  }

  const handleRemoveRow = (requestDetailPerDateIndex) => {
    let newRequestDetailPerDate = []
    requestDetailPerDate.forEach((element, index) => {
      if (requestDetailPerDate.length === 1 || index !== requestDetailPerDateIndex) {
        newRequestDetailPerDate.push(element)
      }
    })
    handleRequestDetailPerDate(date, newRequestDetailPerDate)
  }

  const renderRequestTimeOffDetailList = () => {
    let list = []
    for (
      let requestDetailPerDateIndex = 0;
      requestDetailPerDateIndex < requestDetailPerDate.length;
      requestDetailPerDateIndex++
    ) {
      list.push(
        <RequestTimeOffDetail
          key={requestDetailPerDateIndex}
          data-cy={`RequestTimeOffDetail_${date}_${requestDetailPerDateIndex}`}
          requestDetailPerDateIndex={requestDetailPerDateIndex}
          requestDetailPerDateLength={requestDetailPerDate.length}
          requestDate={requestDate}
          rowDetail={requestDetailPerDate[requestDetailPerDateIndex]}
          rowDetailError={messagesPerDate.rowLevelError.get(requestDetailPerDateIndex)}
          rowDetailInfo={messagesPerDate.rowLevelInfo.get(requestDetailPerDateIndex)}
          handleRowDetail={(requestDetailPerDateIndex, rowDetail) =>
            handleRowDetail(requestDetailPerDateIndex, rowDetail)
          }
          handleAddRow={(requestDetailPerDateIndex) => handleAddRow(requestDetailPerDateIndex)}
          handleRemoveRow={(requestDetailPerDateIndex) => handleRemoveRow(requestDetailPerDateIndex)}
        />,
      )
    }

    return list
  }

  const renderErrorMessages = () => {
    // messagesPerDate.dateLevel is a set, so converting to an array because idk
    // how to iterate a set while returning jsx
    const messagesPerDateArr = Array.from(messagesPerDate.dateLevel)

    return messagesPerDateArr.map((message, index) => (
      <Typography
        data-cy={`requestTimeOffDetail_error_message_${requestDate}`}
        color={'primary'}
        key={index}
        value={message}
      >
        {message}
      </Typography>
    ))
  }

  return (
    <React.Fragment>
      <Typography
        variant="body2"
        sx={{ fontWeight: 'bold', fontSize: 'medium' }}
        data-cy={`nextSchedDisplaySegDateOnWeekly_${requestDate}`}
      >
        {requestDate}
      </Typography>
      {renderErrorMessages()}
      {renderRequestTimeOffDetailList()}
    </React.Fragment>
  )
}

export default RequestPaidTimeOffDetailsByDate
