interface CustomAvailableShiftsIconProps {
  color?: string
}

const CustomAvailableShiftsIcon: React.FC<CustomAvailableShiftsIconProps> = ({ color = '#666' }) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Available Shifts</title>
      <g id="Option-1.3.1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ICONS" transform="translate(-96.000000, -18.000000)">
          <g id="custom_date_range" transform="translate(96.000000, 16.000000)">
            <path
              d="M8,12 L6,12 L6,14 L8,14 L8,12 Z M12,12 L10,12 L10,14 L12,14 L12,12 Z M16,12 L14,12 L14,14 L16,14 L16,12 Z M20,11 L17,11 L17,8 L14,8 L14,5 L7,5 L7,3 L5,3 L5,5 L4,5 C2.89,5 2.01,5.9 2.01,7 L2,21 C2,22.1 2.89,23 4,23 L18,23 C19.1,23 20,22.1 20,21 L20,11 Z M18,21 L4,21 L4,10 L17,10 L17,11 L18,11 L18,21 Z M20,7.00347464 L20,9.99555247 C20,9.99555247 18.01,10.0055594 18,9.99555247 L18,7.00347464 L15,7.00347464 C15,7.00347464 15.01,5.01209173 15,5.00208478 L18,5.00208478 L18,2 L20,2 L20,5.00208478 L23,5.00208478 L23,7.00347464 L20,7.00347464 Z"
              id="Shape"
              fill={color}
              fillRule="nonzero"
            ></path>
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default CustomAvailableShiftsIcon
