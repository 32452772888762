import ScheduleIcon from '@mui/icons-material/Today'
import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import { withAuth } from '@praxis/component-auth'
import { Link } from 'react-router-dom'
import { useNextSchedule } from '../../api/schedule/useNextSchedule'
import DisplaySegments from '../DisplaySegments/DisplaySegments'
import InnerLoader from '../Loader/InnerLoader'
import HomePageCard from '../common/HomePageCard'

const CSC_ERROR_MESSAGE = '  Please try again later and if the issue persists, contact the CSC.'
const SCHED_ERROR_MESSAGE = 'Unable to load your schedule at this time.' + CSC_ERROR_MESSAGE
const SCHED_ERROR_CODES_SHOW_MESSAGE = ['wfm-1-5', 'wfm-2-5', 'wfm-1-11', 'wfm-2-11']
const ERROR_CODE_CANNOT_CONNECT_TO_SCHEDULE = 'wfm-2-0'

const getStyles = (theme) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  cardTitleContainer: {
    display: 'flex',
    flexGrow: '1',
  },
  cardTitle: {
    marginLeft: '.4em',
    fontWeight: 'bold',
  },
  refetchingContainer: {
    marginLeft: '.4em',
  },
  gridItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '6px',
    marginBottom: '6px',
  },
})

function NextShiftCard(props) {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { handleLink, renderHomePageCardError } = props

  const { data, isLoading, isError, error, isRefetching } = useNextSchedule()

  const renderNextShiftButton = () => {
    if (data.schedule.total_display_segments && data.schedule.schedule_date) {
      return (
        <CardActions>
          <Link to="/team-member/dailySchedulePage/schedule" style={{ textDecoration: 'none' }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              data-cy="viewSched"
              onClick={() => handleLink(new Date(data.schedule.schedule_date + 'T00:00:00'))}
            >
              View Schedule
            </Button>
          </Link>
        </CardActions>
      )
    }
  }

  const renderNextSchedules = () => {
    return (
      <Grid container>
        <DisplaySegments
          scheduleDate={data.schedule.schedule_date}
          schedule={data.schedule}
          segments={data.schedule.display_segments}
          data-cy="nextSchedule"
          handleDailyClick={() => handleLink(new Date(data.schedule.schedule_date + 'T00:00:00'))}
          showPostShiftButton={false}
        />
      </Grid>
    )
  }

  /**
   * wondering why we check data first and not isLoading?
   * Check out this blog: https://tkdodo.eu/blog/status-checks-in-react-query
   */
  const renderNextShiftResponse = () => {
    if (data) return renderNextSchedules()
    if (isError) {
      return renderHomePageCardError(
        error?.response?.data,
        SCHED_ERROR_MESSAGE,
        SCHED_ERROR_CODES_SHOW_MESSAGE,
        ERROR_CODE_CANNOT_CONNECT_TO_SCHEDULE,
      )
    }
    return <InnerLoader size={48} />
  }

  return (
    <Grid item xs={12} md={12} sx={styles.gridItem}>
      <HomePageCard
        title="Next Shift"
        icon={<ScheduleIcon />}
        isRefetching={isRefetching}
        cardActions={!isLoading && !isError ? renderNextShiftButton : null}
      >
        {renderNextShiftResponse()}
      </HomePageCard>
    </Grid>
  )
}

export default withAuth()(NextShiftCard)
