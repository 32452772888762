import { Box, Grid } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import moment from 'moment'
import { useLocationDetails } from '../../api/location/useLocationDetails'
import { getCreatedBy } from '../../utils/EmployeeId'

export function Comment({ comment, whiteText = false }) {
  const { session } = useAuth()
  const { data: locationDetails } = useLocationDetails()

  if (!comment) return
  return (
    <Box
      sx={[
        {
          display: 'block',
          border: 1,
          borderRadius: 1,
          padding: 1,
          marginTop: 1,
          minWidth: '230px',
        },
        !whiteText && { borderColor: 'tertiary.dark' },
      ]}
    >
      <Grid item sx={[{ fontSize: 'small', paddingBottom: 1 }, !whiteText && { color: 'tertiary.contrastText' }]}>
        {`${getCreatedBy(comment.created_by_worker_id, session?.userInfo?.empid)} - ${moment
          .tz(comment.created_timestamp, locationDetails.iso_time_zone_code)
          ?.format('lll')}`}
      </Grid>
      <Grid item sx={{ fontSize: 'medium', paddingBottom: 1 }}>
        {comment.comment}
      </Grid>
    </Box>
  )
}
