import { addWeeks, endOfWeek, startOfWeek } from 'date-fns'
import { getDateOfTodayWithNoTimestamp } from './DateUtil'
import { AVAILABILITY_CALENDAR_MAX_FUTURE_WEEKS, FIXED_END_DATE_FROM_UKG } from '../constants/AvailabilityConstants'

export const buildWeeklyAvailabilityData = (selectedDate, userAvailabilities) => {
  if (checkIfDateFitIntoAnyRange(selectedDate, userAvailabilities)) {
    for (let availability of userAvailabilities) {
      let findRange = findDateInGivenRange(selectedDate, availability.effective_date, availability.expiration_date)
      if (findRange) {
        let dataForTheWeek = {}
        dataForTheWeek.type = availability.pattern_type
        dataForTheWeek.id = availability.availability_id
        dataForTheWeek.name = availability.pattern_name
        dataForTheWeek.startDate = availability.effective_date
        dataForTheWeek.endDate = availability.expiration_date
        dataForTheWeek.rotationCount = Object.keys(availability.week_wise_availabilities).length
        if (dataForTheWeek.rotationCount === 1) {
          dataForTheWeek.pattern = availability.week_wise_availabilities.week_1
          dataForTheWeek.currentRotaionWeek = 1
        } else {
          let remaining =
            (currentWeekNumberFromStartDate(availability.effective_date, selectedDate) - 1) %
            dataForTheWeek.rotationCount
          dataForTheWeek.pattern = availability.week_wise_availabilities[`week_${remaining + 1}`]
          dataForTheWeek.currentRotaionWeek = remaining + 1
        }
        return dataForTheWeek
      }
    }
  } else {
    let dataForTheWeek = {}
    dataForTheWeek.pattern = []
    return dataForTheWeek
  }
}

const checkIfDateFitIntoAnyRange = (selectedDate, userAvailabilities) => {
  return userAvailabilities.some((availability) => {
    return findDateInGivenRange(selectedDate, availability.effective_date, availability.expiration_date)
  })
}

const currentWeekNumberFromStartDate = (startDate, checkDate) => {
  let year = startDate.split('-')[0]
  let month = startDate.split('-')[1]
  let date = startDate.split('-')[2]
  // let startDateObj = new Date(startDate)
  let weekStartOfStartDate = startOfWeek(new Date(year, month - 1, date))
  let weekStartOfCheckDate = startOfWeek(checkDate)

  // calculating number of days from start date till the given date
  let numberOfDays = Math.floor((weekStartOfCheckDate - weekStartOfStartDate) / (24 * 60 * 60 * 1000))

  // adding 1 since to given date and returns value starting from 0
  return Math.ceil((weekStartOfCheckDate.getDay() + 1 + numberOfDays) / 7)
}

const findDateInGivenRange = (selectedDate, startDate, endDate) => {
  const d = Date.parse(selectedDate)
  const start = Date.parse(startDate)
  const end =
    endDate === FIXED_END_DATE_FROM_UKG
      ? Date.parse(
          endOfWeek(addWeeks(startOfWeek(getDateOfTodayWithNoTimestamp()), AVAILABILITY_CALENDAR_MAX_FUTURE_WEEKS - 1)),
        )
      : Date.parse(endDate)
  return (
    formatDateWithNoTimestamp(new Date(d)).valueOf() >= formatDateWithNoTimestamp(new Date(start)).valueOf() &&
    formatDateWithNoTimestamp(new Date(d)).valueOf() <= formatDateWithNoTimestamp(new Date(end)).valueOf()
  )
}

const formatDateWithNoTimestamp = (d) => {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate())
}

export const buildAvailabilityMenuItems = (userAvailabilities) => {
  let availabilityMenuItems = []
  userAvailabilities.forEach((availability, index) => {
    let expirationDate =
      availability.expiration_date === FIXED_END_DATE_FROM_UKG ? 'FOREVER' : availability.expiration_date
    let itemDetail = {}
    itemDetail.type = availability.pattern_type
    itemDetail.id = `${availability.pattern_id}_${index}`
    itemDetail.name = availability.availability_name
    itemDetail.startDate = availability.effective_date
    itemDetail.endDate = expirationDate
    availabilityMenuItems.push(itemDetail)
  })
  return availabilityMenuItems
}

export const buildIndividualWeeklyAvailabilityData = (selectedDate, userAvailabilities) => {
  for (let availability of userAvailabilities) {
    let findRange = findDateInGivenRange(selectedDate, availability.effective_date, availability.expiration_date)
    if (findRange) {
      let dataForTheWeek = {}
      dataForTheWeek.type = availability.pattern_type
      dataForTheWeek.id = availability.pattern_id
      dataForTheWeek.name = availability.pattern_name
      dataForTheWeek.startDate = availability.effective_date
      dataForTheWeek.endDate = availability.expiration_date
      dataForTheWeek.rotationCount = Object.keys(availability.week_wise_availabilities).length
      if (dataForTheWeek.rotationCount === 1) {
        dataForTheWeek.pattern = availability.week_wise_availabilities.week_1
        dataForTheWeek.currentRotaionWeek = 1
      } else {
        let remaining =
          (currentWeekNumberFromStartDate(availability.effective_date, selectedDate) - 1) % dataForTheWeek.rotationCount
        dataForTheWeek.pattern = availability.week_wise_availabilities[`week_${remaining + 1}`]
        dataForTheWeek.currentRotaionWeek = remaining + 1
      }
      return dataForTheWeek
    }
  }
}
