import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { groupBy } from 'lodash'
import apiConfig from '../../config/apiConfig'
import {
  AVAILABLE_SHIFT_TYPE_ALL,
  AVAILABLE_SHIFT_TYPE_OPEN_SHIFT,
  AVAILABLE_SHIFT_TYPE_REQUEST_TO_COVER,
} from '../../constants/availableShiftConstants'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { REQUEST_TO_COVER, SCREEN_NAME_AVAILABLE_SHIFTS } from '../../utils/ScreenName'
import { useUserFeatures } from '../userAccess/useUserFeatures'

const getAvailableShiftType = (screenAccess) => {
  let availableShiftType
  if (screenAccess?.includes(SCREEN_NAME_AVAILABLE_SHIFTS) && !screenAccess?.includes(REQUEST_TO_COVER)) {
    availableShiftType = AVAILABLE_SHIFT_TYPE_OPEN_SHIFT
  } else if (!screenAccess?.includes(SCREEN_NAME_AVAILABLE_SHIFTS) && screenAccess?.includes(REQUEST_TO_COVER)) {
    availableShiftType = AVAILABLE_SHIFT_TYPE_REQUEST_TO_COVER
  } else if (screenAccess?.includes(SCREEN_NAME_AVAILABLE_SHIFTS) && screenAccess?.includes(REQUEST_TO_COVER)) {
    availableShiftType = AVAILABLE_SHIFT_TYPE_ALL
  }
  return availableShiftType
}

/**
 * In new praxis, Authorization is now automatically attached to any request going to *.target.com or *.tgt
 * DOCUMENTATION: https://praxis.prod.target.com/guides/authentication
 */
const getAvailableShifts = async (tmNumber, locationIds, startDate, endDate, pageOrigin, availableShiftType) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Page-Origin': pageOrigin,
    },
  }

  let apiUrl =
    `${apiConfig.availableShifts.url}` +
    '/available_shifts' +
    '?worker_id=' +
    tmNumber +
    '&location_ids=' +
    locationIds +
    '&start_date=' +
    startDate +
    '&end_date=' +
    endDate +
    '&page_origin=' +
    pageOrigin +
    (availableShiftType ? `&available_shift_type=${availableShiftType}` : '') +
    '&key=' +
    `${apiConfig.availableShifts.key}`

  const { data } = await axios.get(apiUrl, requestConfig)

  for (let shift of data.available_shifts) {
    shift.shift_date_with_no_time = shift.shift_start.split('T')[0]
    if (shift.jobs && shift.jobs.length > 0) {
      shift.jobList = []
      shift.jobs.forEach((job, elemIndex) => {
        if (elemIndex !== 0) {
          let lastIndex = job.lastIndexOf('/')
          shift.jobList.push(job.substr(lastIndex + 1, job.length))
        }
      })
    }
  }

  data.available_shifts_by_date = groupBy(data.available_shifts, 'shift_date_with_no_time')

  return data
}

/**
 * This hook provides caller with status and data objects to use when rendering available shifts data.
 * @param {String} pageOrigin e.g. 'AVAILABLE_SHIFTS'
 * @param {String} startDate e.g. 2021-10-17
 * @param {String} endDate e.g. 2021-10-23
 * @param {Array} locationIds e.g. [1234, 2345]
 * @returns {UseQueryResults} with state information about the request.
 */
export function useAvailableShifts(pageOrigin, startDate, endDate, locationIds = []) {
  const { session, isAuthenticated, login } = useAuth()
  const { data: userFeatures } = useUserFeatures()

  let availableShiftType = userFeatures?.screenAccess ? getAvailableShiftType(userFeatures.screenAccess) : null

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  if (locationIds.length === 0 && pageOrigin === 'HOME_PAGE') {
    locationIds.push(session.userInfo.locationid)
  }

  let locationsToSend = locationIds.sort().map((location) => {
    return location.toString().padStart(4, '0')
  })

  return useQuery(
    ['availableShifts', { startDate, endDate, locationsToSend }], // query key: this is what identifies the query. changing this will add a new key with its own cached data.
    () =>
      getAvailableShifts(
        padEmpIdWithZeros(session?.userInfo?.empid, 10),
        locationsToSend,
        startDate,
        endDate,
        pageOrigin,
        availableShiftType,
      ),
    {
      // ******* OPTIONS **********
      staleTime: 1000 * 60, // how long until data is considered stale. Will refetch in background when called again if data is stale.
      keepPreviousData: true, // keeps previous data around until new data is fetched and replaces old data.
      enabled: locationIds.length !== 0 && isAuthenticated(), // The query will not execute if false

      // cacheTime: 1000 * 60 * 5, // how long the cached data response stays in memory before garbage collected.
      // retry: 3, // if api call fails, will retry 3 times. can also be set to false and true. if true, query will retry indefinitely
      // retryDelay: 1000, // how long to wait before retry. can also be function for exponential or linear delay. e.e. attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)

      // queries will refresh more during development due to things like this. I believe most or all of these are defaulted to true.
      // refetchOnMount: true,
      // refetchOnWindowFocus: true,
      // refetchOnReconnect: true,

      // refetchInterval: 1000 * 10, // with this we can do polling data. refresh every 10 seconds in this case.

      // onSuccess: (data) => {
      //   I run if query succeeds
      // },
      // onError: (error) => {
      //   I run if query fails
      // },
      // onSettled: (data, error) => {
      //   I run after query succeeds or fails
      // },

      // AND MORE!
    },
  )
}

/**
 * ********* IMPORTANT DEFAULTS FOR REACT QUERY ***********
 * see: https://react-query.tanstack.com/guides/important-defaults
 *
 * useQuery or useInfiniteQuery consider cached data as stale. can change with staleTime option.
 *  - Stale queries are refetched automatically in the background when:
 *    - New instances of the query mountThe window is refocused
 *    - The network is reconnected.
 *    - The query is optionally configured with a refetch interval.
 */
