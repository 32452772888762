import { KeyboardArrowDown } from '@mui/icons-material'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const options = [
  { value: 'communication', label: 'Communication', pathname: '/admin/communication' },
  { value: 'feedback-analysis', label: 'Feedback Analysis', pathname: '/admin/feedback-analysis' },
]

export default function AdminDropdown() {
  const selected =
    window.location.pathname === options[0].pathname
      ? options[0].value
      : window.location.pathname === options[1].pathname
        ? options[1].value
        : 'Select'
  const [selectedOption, setSelectedOption] = useState(selected)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (value: any) => {
    if (typeof value !== 'string') {
      setAnchorEl(null)
      return
    }
    setSelectedOption(value)
    navigate(`/admin/${value}`)
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        <Typography fontSize={30} fontWeight={300}>
          {selectedOption}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => {
                return handleClose(option.value)
              }}
            >
              <Typography fontSize={15} fontWeight={300}>
                {option.label}
              </Typography>
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
