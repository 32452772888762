import { CircularProgress, useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { useQueryClient } from '@tanstack/react-query'
import { addDays } from 'date-fns'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { CALL_OFF_TYPE_CONFIG, REQUEST_SCREEN_TITLES, REQUEST_TYPES } from '../../../constants/RequestConstants'
import { ERROR_CODES_SHOW_MESSAGE, SUCCESS_ON_SAVE, UNABLE_TO_SAVE } from '../../../constants/errorConstants'
import { LOCATION_TYPE_STORE } from '../../../constants/locationConstants'
import { showNotificationError, showNotificationSuccess } from '../../../store/notification/actionCreator'
import { clearPreviousState, postRequestsData } from '../../../store/requestTimeOff/actionCreator'
import { getNowDateInTimezone } from '../../../utils/DateUtil'
import { formatErrorCode } from '../../../utils/ErrorHandling'
import { CALL_IN_CALL_OFF_TEAM_MEMBER } from '../../../utils/ScreenName'
import HeaderTitle from '../../Header/HeaderTitle'
import NotAuthorized from '../../common/fallback/NotAuthorized'
import RequestCommentForm from '../RequestCommentForm'
import RequestCallOffDetail from './RequestCallOffDetail'
import RequestCallInOffSubmitCancel from './RequestCallOffSubmitCancel'
import { useUser } from '../../../auth/hooks/useUser'

const getStyles = (theme) => ({
  mainContainerPosition: theme.mainContainerPosition,

  loadingIconContainer: theme.loadingIconContainer,
  datePickerContainer: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  timeOffRequestScrollableContainer: {
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  bottomSectionContainer: {
    textAlign: 'right',
    borderTop: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
  },
  typeTextField: {
    display: 'flex',
    width: '250px',
    alignSelf: 'center',
    marginBottom: '10px',
  },
})

// const TOP_MESSAGE = 'Reminder: All time off requests have to be scheduled by a leader'
const MY_REQUESTS_PAGE_PATH = '/team-member/requests/time_off'
export const COMMENT_MAX_LENGTH = 280
const TIME_OFF_ERROR_CODE = 'wfm-15-0'
export const COMMENT = 'Comment'
const COMMENT_REQUIRED = 'Comment Required *'
const MAX_TOTAL_MINUTES = 720
const MIN_TOTAL_MINUTES = 15
const GREATER_THAN_12_HOURS_MESSAGE = 'The total time must not be greater than 12 hours'
const NO_HOURS_MESSAGE = 'The total time must be greater than 0'

// added inline string to replace the need for this variable --> leaving in for documentation purposes
// const COMMENT_REQUIRED_MESSAGE = 'Note: Request types "other" require a comment describing the purpose of the request'

const RequestCallOff = (props) => {
  const location = useLocation()
  const theme = useTheme()
  const styles = getStyles(theme)
  const dispatch = useDispatch()
  const { user } = useUser()
  const { locationData } = user
  const [formIncomplete, setFormIncomplete] = useState(true)
  const [selectableDatesMap, setSelectableDatesMap] = useState(new Map())
  const [selectedDate, setSelectedDate] = useState(null)
  const [comment, setComment] = useState('')
  const [callInCallOffDetail, setCallInCallOffDetail] = useState({
    type: '',
    startTime: null,
    hours: '',
    minutes: '',
    config: null,
  })
  const [rowDetailError, setRowDetailError] = useState(null)

  const [navigateToMyRequests, setNavigateToMyRequests] = useState(false)
  const navigate = useNavigate()

  const screenAccess = useSelector((state) => state.layout.screenAccess)
  const screenAccessLoading = useSelector((state) => state.layout.screenAccessLoading)
  const locationLoading = useSelector((state) => state.layout.locationLoading)

  const timeOffPostResponse = useSelector((state) => state.requestTimeOff.timeOffPostResponse)
  const timeOffPostErrorResponse = useSelector((state) => state.requestTimeOff.timeOffPostErrorResponse)
  const queryClient = useQueryClient()

  const initSelectableDatesMap = () => {
    const outputDateFormat = 'dddd, MMMM D YYYY'
    let todayDate = getNowDateInTimezone(locationData.iso_time_zone_code)
    let tomorrowDate = addDays(todayDate, 1)
    let todayDateFormatted = moment(todayDate, moment.HTML5_FMT.DATE)?.format(outputDateFormat)
    let tomorrowDateFormatted = moment(tomorrowDate, moment.HTML5_FMT.DATE)?.format(outputDateFormat)
    let newMap = new Map()
    newMap.set(todayDateFormatted, todayDate)
    newMap.set(tomorrowDateFormatted, tomorrowDate)

    setSelectableDatesMap(newMap)
  }

  // if navigating directly from a display segment, we pass through state with useNavigate. we grab that data and autofill if it is there.
  useEffect(() => {
    if (location?.state?.scheduleDate) {
      const { scheduleDate, segmentStart, segmentEnd } = location.state
      const inputDateTimeFormat = 'YYYY-MM-DD hh:mm:ss'
      const outputDateFormat = 'dddd, MMMM D YYYY'

      let startTime = moment(segmentStart, inputDateTimeFormat)
      let endTime = moment(segmentEnd, inputDateTimeFormat)
      let duration = moment.duration(endTime.diff(startTime))
      let shiftTotalMinutes = duration.asMinutes()
      let hours = Math.floor(shiftTotalMinutes / 60)
      let minutes = shiftTotalMinutes % 60

      setSelectedDate(moment(scheduleDate, moment.HTML5_FMT.DATE)?.format(outputDateFormat))
      setCallInCallOffDetail({
        type: '',
        startTime: startTime.toDate(),
        hours: hours,
        minutes: minutes,
        config: null,
      })
    }
  }, [location])

  useEffect(() => {
    if (timeOffPostResponse) {
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
      dispatch(clearPreviousState())
      queryClient.invalidateQueries(['requests'], { requestType: REQUEST_TYPES.time_off })
      navigate(-1)
    } else if (timeOffPostErrorResponse) {
      if (
        timeOffPostErrorResponse.code &&
        timeOffPostErrorResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(timeOffPostErrorResponse.code)
      ) {
        dispatch(showNotificationError(true, timeOffPostErrorResponse.message))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE + ' ' + formatErrorCode(timeOffPostErrorResponse, TIME_OFF_ERROR_CODE),
          ),
        )
      }
      dispatch(clearPreviousState())
    }
  }, [timeOffPostResponse, timeOffPostErrorResponse, dispatch, navigate, queryClient])

  useEffect(() => {
    const isCommentDone = callInCallOffDetail?.config?.comment.required ? /\S/.test(comment) : true
    if (
      callInCallOffDetail.startTime !== null &&
      callInCallOffDetail.hours !== '' &&
      callInCallOffDetail.minutes !== '' &&
      callInCallOffDetail.type !== '' &&
      isCommentDone
    ) {
      setFormIncomplete(false)
    } else setFormIncomplete(true)

    if (callInCallOffDetail.hours !== '' && callInCallOffDetail.minutes !== '') {
      let totalMinutes = callInCallOffDetail.hours * 60 + callInCallOffDetail.minutes

      if (totalMinutes > MAX_TOTAL_MINUTES) {
        setRowDetailError(GREATER_THAN_12_HOURS_MESSAGE)
        setFormIncomplete(true)
      } else if (totalMinutes < MIN_TOTAL_MINUTES) {
        setRowDetailError(NO_HOURS_MESSAGE)
        setFormIncomplete(true)
      } else {
        setRowDetailError(null)
      }
    }
  }, [comment, callInCallOffDetail])

  useEffect(() => {
    if (callInCallOffDetail.type && locationData.location_type) {
      setCallInCallOffDetail((prevState) => {
        return {
          ...prevState,
          config: CALL_OFF_TYPE_CONFIG[locationData.location_type][callInCallOffDetail.type],
        }
      })
    }
  }, [callInCallOffDetail.type, locationData.location_type])

  useEffect(() => {
    if (!callInCallOffDetail?.config?.comment.visible) {
      setComment('') // reset comment if type changes to one that can not have a comment.
    }
  }, [callInCallOffDetail.config])

  const handleDateClick = (date) => {
    setSelectedDate(date)
  }

  const handleCallInCallOffDetail = (key, value) => {
    setCallInCallOffDetail((prevState) => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  const renderDatePickerSection = () => {
    if (locationData.iso_time_zone_code) {
      if (selectableDatesMap.size === 0) {
        initSelectableDatesMap()
      }
      let formattedDates = [...selectableDatesMap.keys()]
      let menuItems = formattedDates.map((formattedDate) => {
        return (
          <MenuItem key={formattedDate} value={formattedDate}>
            {formattedDate}
          </MenuItem>
        )
      })
      return (
        <Paper sx={styles.datePickerContainer} elevation={1} square>
          <TextField
            data-cy={`requestCallInCallOff_dateSelector`}
            id="date"
            select
            label="Date"
            aria-label="Select date for Call Off Absent request"
            sx={styles.typeTextField}
            value={selectedDate == null ? '' : selectedDate}
            onChange={(e) => handleDateClick(e.target.value)}
            margin="normal"
            disabled={locationData.location_type === LOCATION_TYPE_STORE}
          >
            {menuItems}
          </TextField>
        </Paper>
      )
    }
  }

  const renderCommentForm = () => {
    return callInCallOffDetail?.config?.comment?.visible ? (
      <RequestCommentForm
        setComment={setComment}
        label={callInCallOffDetail?.config?.comment?.required ? COMMENT_REQUIRED : COMMENT}
        maxLength={COMMENT_MAX_LENGTH}
        value={comment}
      />
    ) : null
  }

  const renderScrollableContent = () => {
    return (
      <Paper
        sx={styles.timeOffRequestScrollableContainer}
        id="scrollableContainer"
        data-cy="scrollableContainer_weekly"
        elevation={1}
        square
      >
        {selectedDate ? (
          <>
            <RequestCallOffDetail
              requestDate={selectableDatesMap.get(selectedDate)}
              rowDetail={callInCallOffDetail}
              handleRowDetail={handleCallInCallOffDetail}
              rowDetailError={rowDetailError}
            />
            <Divider />
            {renderCommentForm()}
          </>
        ) : null}
      </Paper>
    )
  }

  const handleSubmitRequest = () => {
    let requestDetailMap = new Map()
    requestDetailMap.set(selectedDate, [callInCallOffDetail])
    dispatch(postRequestsData(user, requestDetailMap, comment))
  }

  const renderBottomButtons = () => {
    return (
      <Paper sx={styles.bottomSectionContainer} elevation={1} square>
        <RequestCallInOffSubmitCancel
          showCancelDialog={selectedDate !== null}
          disableSubmit={formIncomplete}
          setNavigateToMyRequests={setNavigateToMyRequests}
          handleSubmitRequest={handleSubmitRequest}
          selectedDate={selectedDate}
          callInCallOffDetail={callInCallOffDetail}
        />
      </Paper>
    )
  }

  const renderLoader = () => {
    return (
      <div style={styles.mainContainerPosition}>
        <div style={styles.loadingIconContainer}>
          <CircularProgress size={48} />
        </div>
      </div>
    )
  }

  const renderAllRequestCallInOffContent = () => {
    if (!screenAccessLoading && !locationLoading) {
      if (!screenAccess.includes(CALL_IN_CALL_OFF_TEAM_MEMBER)) {
        return <NotAuthorized />
      } else {
        if (navigateToMyRequests) {
          dispatch(clearPreviousState())
          return <Navigate to={MY_REQUESTS_PAGE_PATH} />
        } else {
          return (
            <React.Fragment>
              {renderDatePickerSection()}
              {renderScrollableContent()}
              {renderBottomButtons()}
            </React.Fragment>
          )
        }
      }
    } else {
      return renderLoader()
    }
  }

  return (
    <React.Fragment>
      <HeaderTitle title={REQUEST_SCREEN_TITLES.NOTIFY_CALL_OFF} />
      {renderAllRequestCallInOffContent()}
    </React.Fragment>
  )
}

export default RequestCallOff
