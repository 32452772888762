import { Grid, Paper, useTheme } from '@mui/material'
import { useIsFetching } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import LinearLoader from '../../../common/loaders/LinearLoader'
import CollapsedControlPanel from './CollapsedControlPanel'
import ExpandedControlPanel from './ExpandedControlPanel'

const getStyles = (theme) => ({
  panelRoot: {
    backgroundColor: theme.palette.tertiary.light,
    padding: '10px',
    paddingTop: '15px',
  },
})

const ControlPanel = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const isPanelExpanded = useSelector((state) => state.leader.isPanelExpanded)
  // If any backround fetching is occuring (here because if this is in TableContent.js
  //  then it does not show when user is scrolled down)
  const isFetching = useIsFetching()

  return (
    <Grid width={'100%'} position={'relative'}>
      <Paper square sx={styles.panelRoot} data-cy="myTeamPanel">
        {isPanelExpanded ? <ExpandedControlPanel /> : <CollapsedControlPanel />}
      </Paper>
      {isFetching ? <LinearLoader /> : null}
    </Grid>
  )
}

export default ControlPanel
