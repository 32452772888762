import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast'
import LocationIcon from '@mui/icons-material/Place'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { withAuth } from '@praxis/component-auth'
import moment from 'moment'
import { object, shape } from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router'
import { bindActionCreators } from 'redux'
import praxisTheme from '../../config/themeConfig'
import {
  handleDailyScheduleGetData,
  setDailyScheduleSelectedDate,
  setLoading,
} from '../../store/dailySchedulePage/actionCreator'
import { handleUserActions } from '../../store/pushNotification/actionCreator'
import {
  getNowDateInTimezone,
  isDateAfterNumberOfWeeksFromToday,
  isDateBeforeNumberOfWeeksFromToday,
} from '../../utils/DateUtil'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { formatErrorCode } from '../../utils/ErrorHandling'
import { SCHEDULE_TEAM_MEMBER } from '../../utils/ScreenName'
import WeeklyCalendar from '../Calendar/WeeklyCalendar'
import HeaderTitle from '../Header/HeaderTitle'
import NotAuthorized from '../common/fallback/NotAuthorized'
import DailySchedulePaycodeEdits from './DailySchedulePaycodeEdits'
import DailyScheduleShiftSegments from './DailyScheduleShiftSegments'

const styles = {
  mainScrollContainer: praxisTheme.mainScrollContainer,
  mainContainerPosition: praxisTheme.mainContainerPosition,
  infoMessage: praxisTheme.infoMessages,
  infoMessageDetail: praxisTheme.infoMessageDetail,
  errorCodeMessage: praxisTheme.errorCodeMessages,
  errorMessage: praxisTheme.errorMessages,
  loadingIconContainer: praxisTheme.loadingIconContainer,
  highlightsTimes: {
    fontWeight: 'bold',
    fontSize: '105%',
    paddingTop: '2px',
  },
  highlightsHours: {
    fontWeight: 'bold',
    fontSize: '105%',
    paddingTop: '2px',
    textAlign: 'right',
  },
  highlightsContainerPosition: {
    width: '100%',
  },
  highlightsContainer: {
    borderBottom: `4px solid ${praxisTheme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
    '@media (max-width: 336px)': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  scheduleItemContainer: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
    fontSize: 'medium',
  },
  gridItemContainer: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
    textAlign: 'right',
  },
  typoContainer: {
    flexGrow: '1',
    paddingTop: '9px',
    minHeight: '32px',
    marginLeft: '3px',
  },
  paycodeEdits: {
    fontWeight: '300',
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: '1',
    minHeight: '32px',
    paddingBottom: '.25em',
  },
  breakContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: '1',
    minHeight: '32px',
    paddingBottom: '.25em',
  },
  shiftLabelContainer: {
    marginTop: 1,
    marginLeft: 12,
    display: 'inline-Block',
  },
  additionalShiftLabels: {
    margin: '2px 6px',
  },
  shiftLabel: {
    border: '1px solid #333',
    color: '#333',
    padding: '1px 5px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
  },
}
const LOAD_ERROR_MESSAGE =
  'Unable to load your schedule at this time.  Please try again later and if the issue persists, contact the CSC.'
const ERROR_CODES_SHOW_MESSAGE = ['wfm-1-5', 'wfm-2-5', 'wfm-1-11', 'wfm-2-11']
const ERROR_CODE_CANNOT_CONNECT_TO_SERVER = 'wfm-2-0'
const ERROR_CODE_CANNOT_CONNECT_TO_LOCATION = ' (error code: wfm-10-0)'
const LOCATION_API_STORE_LOCATION_TYPE = 'STR'
const DATE_RANGE_PAST_WEEKS = 13
const DATE_RANGE_STORE_FUTURE_WEEKS = 26 // 6 months
const DATE_RANGE_DC_FUTURE_WEEKS = 4
const DATE_BEFORE_RANGE_MESSAGE = 'Schedules are only available 13 weeks in the past.'
const DATE_AFTER_RANGE_MESSAGE_DC = 'Schedules are only available 4 weeks into the future.'
const DATE_AFTER_RANGE_MESSAGE_STORE = 'Schedules are only available 6 months into the future.'

class DailySchedulePage extends React.Component {
  static propTypes = {
    classes: object,
    layoutActions: shape({
      setLoading: 'N',
    }),
  }

  constructor(props) {
    super(props)
    this.state = {
      dateBeforeRange: false,
      dateAfterRange: false,
    }
  }

  dateIsValid = (date) => {
    return !Number.isNaN(new Date(date).getTime())
  }

  componentWillMount() {
    let search = window.location.search //this.props.location.search
    const params = new URLSearchParams(search)
    let pnDate = params.get('pndate')
    //pnDate = pnDate.replace('/[/]/g', '-')
    const { locationDetails, selectedDate } = this.props
    let currentSelectedDate = selectedDate

    if (pnDate) {
      // if (this.dateIsValid(pnDate)) {
      currentSelectedDate = new Date(pnDate + ' 00:00:00')
      //}
    } else if (locationDetails.iso_time_zone_code && !selectedDate) {
      currentSelectedDate = getNowDateInTimezone(locationDetails.iso_time_zone_code)
    }

    if (currentSelectedDate && currentSelectedDate !== selectedDate) {
      this.props.setDailyScheduleSelectedDate(currentSelectedDate)
    }

    if (this.props.userDetails) {
      if (this.props.screenAccess.includes(SCHEDULE_TEAM_MEMBER) && currentSelectedDate) {
        this.handleDailyScheduleGetData(currentSelectedDate, this.props.userDetails)
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { auth, screenAccess, selectedDate, locationDetails } = this.props
    if (!auth.isAuthenticated()) {
      auth.login({ redirect: window.location.href })
    }

    let currentSelectedDate

    if (prevProps.locationDetails.iso_time_zone_code !== locationDetails.iso_time_zone_code && !selectedDate) {
      currentSelectedDate = getNowDateInTimezone(locationDetails.iso_time_zone_code)
      this.props.setDailyScheduleSelectedDate(currentSelectedDate)
    } else {
      currentSelectedDate = selectedDate
    }

    if (
      (prevProps.screenAccess !== screenAccess ||
        prevProps.locationDetails.iso_time_zone_code !== locationDetails.iso_time_zone_code) &&
      screenAccess.includes(SCHEDULE_TEAM_MEMBER) &&
      locationDetails.iso_time_zone_code &&
      currentSelectedDate
    ) {
      if (prevProps.userDetails !== this.props.userDetails || Object.keys(this.props.userDetails).length > 0) {
        this.handleDailyScheduleGetData(currentSelectedDate, this.props.userDetails)
      }
    }
  }

  componentDidMount() {
    if (Object.keys(this.props.userDetails).length > 0) {
      let search = window.location.search //this.props.location.search
      const params = new URLSearchParams(search)
      this.handlePushNotificationAcknowledgement(params)
    }
  }

  isSelectedDateBeforeValidRange = (selectedDate) => {
    const { locationDetails } = this.props
    return isDateBeforeNumberOfWeeksFromToday(selectedDate, DATE_RANGE_PAST_WEEKS, locationDetails.iso_time_zone_code)
  }

  isSelectedDateAfterValidRange = (selectedDate) => {
    const { locationDetails } = this.props
    let numberOfWeeks =
      locationDetails.location_type === LOCATION_API_STORE_LOCATION_TYPE
        ? DATE_RANGE_STORE_FUTURE_WEEKS
        : DATE_RANGE_DC_FUTURE_WEEKS
    return isDateAfterNumberOfWeeksFromToday(selectedDate, numberOfWeeks, locationDetails.iso_time_zone_code)
  }

  handlePushNotificationAcknowledgement = (params) => {
    const { userInfo } = this.props.auth.session

    // Push Notification - Capture the user action using acknowledgement service - START

    const deviceToken = params.get('device_token') // device_token from myTime App
    const deviceUuid = params.get('device_uuid') // device_uuid from myTime App
    const deviceType = params.get('device_type') // device_type from myTime App
    const deviceVersion = params.get('device_version') // device_version from myTime App
    const deviceOsVersion = params.get('device_os_version') // device_os_version from myTime App
    const deviceStatus = params.get('device_status') // device_status from myTime App
    const pnDate = params.get('pndate') // pndate from myTime App
    const pnId = params.get('pnid') // push notification id from myTime App

    if (deviceToken && deviceUuid && deviceType && deviceVersion && deviceOsVersion && deviceStatus && pnDate) {
      const actionName = 'READ'
      const actionType = 'Schedule'

      this.props.handleUserActions(
        actionName,
        actionType,
        padEmpIdWithZeros(userInfo?.empid, 10),
        pnId,
        deviceType,
        deviceVersion,
        deviceOsVersion,
      )
    }
    // Push Notification - Capture the user action using acknowledgement service - END
  }

  handleDateChange = (date) => {
    this.handleDailyScheduleGetData(date, this.props.userDetails)
    this.props.setDailyScheduleSelectedDate(date)
  }

  handleDailyScheduleGetData = (selectedDate, userObject) => {
    if (this.isSelectedDateBeforeValidRange(selectedDate)) {
      this.setState({
        dateBeforeRange: true,
        dateAfterRange: false,
      })
    } else if (this.isSelectedDateAfterValidRange(selectedDate)) {
      this.setState({
        dateAfterRange: true,
        dateBeforeRange: false,
      })
    } else {
      this.setState({
        dateBeforeRange: false,
        dateAfterRange: false,
      })
      this.props.setLoading('Y')
      this.props.handleDailyScheduleGetData(
        padEmpIdWithZeros(userObject.empid, 10),
        selectedDate,
        this.props.locationDetails.location_id,
      )
    }
  }

  createPaycodeEdits = (data) => {
    let list = []

    if ('paycode_edits' in data) {
      if (data.total_paycode_edits > 0) {
        list.push(
          <Grid item xs={12} md={12} sx={styles.gridContainer}>
            {this.createPaycodeEditItems(data.paycode_edits)}
          </Grid>,
        )
      }
    }
    return list
  }

  createPaycodeEditItems = (paycodeEdits) => {
    let list = []

    Array.from(paycodeEdits).forEach(function (element, i) {
      list.push(
        <>
          <DailySchedulePaycodeEdits element={element} key={i} styles={styles} />
        </>,
      )
    })
    return list
  }

  createShiftSegments = (data) => {
    const { locationDetails } = this.props
    let viewableLocationJob = null
    let list = []

    if ('shift_segments' in data) {
      Array.from(data.shift_segments).forEach(function (element, i) {
        let viewableSegmentType = (
          <Box sx={styles.breakContainer}>
            {element.shift_segment_type === 'Break' ? (
              <FreeBreakfastIcon color="primary" />
            ) : (
              <RestaurantIcon color="primary" />
            )}

            <Typography variant="body2" sx={styles.typoContainer}>
              {' '}
              {element.shift_segment_type}{' '}
            </Typography>
          </Box>
        )

        let locationId
        let jobName = ''
        if (element.org_structure !== undefined) {
          locationId = element.org_structure.location
          jobName = element.org_structure.job
        } else if (element.labor_account !== undefined) {
          locationId = element.labor_account.location
        }

        if (locationId) {
          viewableLocationJob = (
            <Box sx={styles.locationContainer}>
              <LocationIcon color="primary" />
              <Typography variant="body2">
                {locationId}
                {locationDetails?.location_id && parseInt(locationDetails.location_id) !== parseInt(locationId)
                  ? '*'
                  : null}
                {jobName === '' ? '' : ' -  ' + jobName}
              </Typography>
            </Box>
          )
        }

        list.push(
          <DailyScheduleShiftSegments
            element={element}
            key={i}
            styles={styles}
            viewableLocationJob={viewableLocationJob}
            viewableSegmentType={viewableSegmentType}
          />,
        )
      })
    }
    return list
  }

  createSchedule = () => {
    const { data } = this.props

    return (
      <Grid container>
        {this.createShiftSegments(data)}
        {this.createPaycodeEdits(data)}
      </Grid>
    )
  }

  createHeaderInfo = () => {
    const { data } = this.props
    const inputTimeFormat = 'hh:mm:ss'
    const outputTimeFormat = 'hh:mmA'
    let viewableSchedule

    if ('schedule_start' in data) {
      viewableSchedule = (
        <Typography variant="body2" sx={styles.highlightsTimes} data-cy={`dailySchedShiftTime_${data.schedule_date}`}>
          {moment(data.schedule_start, inputTimeFormat)?.format(outputTimeFormat)} -{' '}
          {moment(data.schedule_end, inputTimeFormat)?.format(outputTimeFormat)}
        </Typography>
      )
    } else {
      viewableSchedule = (
        <Typography variant="body2" sx={styles.highlightsTimes} data-cy={`dailySchedNoShift_${data.schedule_date}`}>
          No Schedule
        </Typography>
      )
    }
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={9} md={9} sx={styles.gridContainer}>
            {viewableSchedule}
          </Grid>
          <Grid item xs={3} md={3} sx={styles.gridContainer}>
            <Typography
              variant="body2"
              sx={styles.highlightsHours}
              data-cy={`dailySchedTotalHrs_${data.schedule_date}`}
            >
              {data.total_hours} Hrs
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    const { data, selectedDate, getError, locationDetailsLoading, locationDetailsGetError } = this.props
    let viewableData = null
    let viewableCalendar = null

    if (!this.props.screenAccessLoading && !locationDetailsLoading) {
      if (!this.props.screenAccess.includes(SCHEDULE_TEAM_MEMBER)) {
        viewableData = <NotAuthorized />
      } else if (locationDetailsGetError) {
        viewableData = (
          <Box sx={styles.mainContainerPosition}>
            <Box component={'p'} sx={styles.errorMessage}>
              {LOAD_ERROR_MESSAGE}
              <Box component={'span'} sx={styles.errorCodeMessage}>
                {ERROR_CODE_CANNOT_CONNECT_TO_LOCATION}
              </Box>
            </Box>
          </Box>
        )
      } else {
        if (selectedDate) {
          viewableCalendar = (
            <WeeklyCalendar
              id="DailySchedulePage"
              selectedDate={selectedDate}
              onChange={this.handleDateChange.bind(this)}
            />
          )
        }
        if (this.props.loading === 'Y') {
          viewableData = (
            <Box sx={styles.mainContainerPosition}>
              <Box sx={styles.loadingIconContainer}>
                <CircularProgress size={48} />
              </Box>
            </Box>
          )
        } else if (this.state.dateBeforeRange) {
          viewableData = (
            <Box sx={styles.mainContainerPosition}>
              <Box component={'p'} sx={styles.infoMessage}>
                {DATE_BEFORE_RANGE_MESSAGE}
              </Box>
            </Box>
          )
        } else if (this.state.dateAfterRange) {
          let errorMessage =
            this.props.locationDetails.location_type === 'STR'
              ? DATE_AFTER_RANGE_MESSAGE_STORE
              : DATE_AFTER_RANGE_MESSAGE_DC
          viewableData = (
            <Box sx={styles.mainContainerPosition}>
              <Box component={'p'} sx={styles.infoMessage}>
                {errorMessage}
              </Box>
            </Box>
          )
        } else if (getError) {
          if (getError.message && getError.code && ERROR_CODES_SHOW_MESSAGE.includes(getError.code)) {
            viewableData = (
              <Box sx={styles.mainContainerPosition}>
                <Box component={'p'} sx={styles.errorMessage}>
                  {getError.message}
                </Box>
              </Box>
            )
          } else {
            viewableData = (
              <Box sx={styles.mainContainerPosition}>
                <Box component={'p'} sx={styles.errorMessage}>
                  {LOAD_ERROR_MESSAGE}
                  <Box component={'span'} sx={styles.errorCodeMessage}>
                    {formatErrorCode(getError, ERROR_CODE_CANNOT_CONNECT_TO_SERVER)}
                  </Box>
                </Box>
              </Box>
            )
          }
        } else if (data && data.message) {
          viewableData = (
            <Box sx={styles.mainContainerPosition}>
              {
                <Box component={'p'} sx={styles.errorMessage}>
                  {data.message}
                </Box>
              }
            </Box>
          )
        } else if (data && data.schedule_date) {
          viewableData = (
            <React.Fragment>
              <Box sx={styles.highlightsContainerPosition}>
                <Paper elevation={1} square sx={styles.highlightsContainer}>
                  {this.createHeaderInfo()}
                </Paper>
              </Box>
              <Box sx={styles.mainContainerPosition} id="scrollableContainer">
                <Paper sx={styles.mainScrollContainer} elevation={1} square>
                  {this.createSchedule()}
                </Paper>
              </Box>
            </React.Fragment>
          )
        } else if (data == null) {
          viewableData = null
        } else {
          viewableData = (
            <Box sx={styles.mainContainerPosition}>
              {
                <Box component={'p'} sx={styles.errorMessage}>
                  Unable to load your schedule at this time. Please try again later and if the issue persists, contact
                  the CSC.
                </Box>
              }
            </Box>
          )
        }
      }
    }

    return (
      <React.Fragment>
        <HeaderTitle title="My Schedule Details" />
        {viewableCalendar}
        {viewableData}
        <Outlet />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleDailyScheduleGetData,
      setLoading,
      setDailyScheduleSelectedDate,
      handleUserActions,
    },
    dispatch,
  )

const mapStateToProps = (state) => {
  return {
    data: state.dailySchedule.data,
    loading: state.dailySchedule.loading,
    selectedDate: state.dailySchedule.selectedDate,
    getError: state.dailySchedule.getError,
    screenAccess: state.layout.screenAccess,
    screenAccessLoading: state.layout.screenAccessLoading,
    locationDetails: state.layout.locationDetails,
    locationDetailsLoading: state.layout.locationLoading,
    locationDetailsGetError: state.layout.locationDetailsGetError,
    userDetails: state.layout.userDetails,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth()(DailySchedulePage))
