import { Button, CircularProgress, Grid, MenuItem, Paper, TextField, Typography, useTheme } from '@mui/material'
import List from '@mui/material/List'
import { useQueryClient } from '@tanstack/react-query'
import { addDays, parseISO } from 'date-fns'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useReasonsConfig } from '../../../api/configuration/useReasonsConfig'
import {
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER_PUNCH_CORRECTION,
  SUCCESS_ON_SAVE,
  UNABLE_TO_SAVE,
} from '../../../constants/errorConstants'
import { showNotificationError, showNotificationSuccess } from '../../../store/notification/actionCreator'
import { clearPreviousState, postRequestsData } from '../../../store/punchCorrection/actionCreator'
import { convertToLocalTimeForPucnCorrections } from '../../../utils/DateUtil'
import { formatErrorCode } from '../../../utils/ErrorHandling'
import HeaderTitle from '../../Header/HeaderTitle'
import PunchCorrectionSubmitCancel from '../../Timecard/PunchCorrection/PunchCorrectionSubmitCancel'
import {
  checkIfPunchOutTimeSmaller,
  checkOvernight,
  checkPunchInFuture,
  checkPunchOutFuture,
  findInvalidPunches,
  formatDataBeforeSubmit,
  validateOverlaps,
} from './PunchCorrectionUtils'
import RequestPunchCorrectionRowInput from './RequestPunchCorrectionRowInput'
import { useUser } from '../../../auth/hooks/useUser'

const getStyles = (theme) => ({
  bottomSectionContainer: {
    textAlign: 'right',
    borderTop: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
  },
  datePickerContainer: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    padding: '15px',
    //maxWidth: '640px',
    backgroundColor: theme.palette.tertiary.main,
    width: '100%',
    '& p': {
      fontWeight: 700,
    },
  },
  mainScrollContainer: {
    ...theme.mainScrollContainer,
    overflowY: 'auto',
    height: '100%',
  },
  mainContainerPosition: theme.mainContainerPosition,
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    padding: '10px',
  },
  stickyHeader: {
    margin: '0 auto',
    maxWidth: theme.mainScrollContainer.maxWidth,
    width: '100%',
    height: 'auto',
  },
  '@media (min-width: 640px)': {
    stickyHeader: {
      marginTop: '20px',
    },
  },
  errorMsg: {
    color: '#cc0000',
    paddingLeft: 6,
    fontSize: '0.85rem',
  },
})

const NO_CHANGES_DETECTED_ERROR_MESSAGE =
  'No changes detected. You must have at least one punch correction to submit the request.'

const RequestPunchCorrection = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const PAGE_TITLE = 'Fix A Punch'
  const { data } = useReasonsConfig()
  const dispatch = useDispatch()
  const timeCardDataDaily = useSelector((state) => state.timecardDaily.data)
  const { userData, locationData } = useUser().user
  const punchCorrectionPostResponse = useSelector((state) => state.punchCorrection.punchCorrectionPostResponse)
  const punchCorrectionPostErrorResponse = useSelector(
    (state) => state.punchCorrection.punchCorrectionPostErrorResponse,
  )
  const queryClient = useQueryClient()

  const [dayPunches, setDayPunches] = useState([])
  const [deletedPunches, setDeletedPunches] = useState([])
  const [isFormReadyToSubmit, setIsFormReadyToSubmit] = useState({
    status: true,
    message: '',
    reason: '',
  })
  const [hasChanges, setHasChanges] = useState(false)
  const [showAddPunchBtn, setShowAddPunchBtn] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState('')

  function InitiateNewPunchRow() {
    const obj = {
      // punchDate: null,
      punchInDate: null,
      punchOutDate: null,
      punchInTime: null,
      punchOutTime: null,
      action: null,
      existingPunchInTime: null,
      existingPunchOutTime: null,
      newPunchType: '',
      isOverlapped: false,
      isPunchOutSmaller: false,
      isOvernight: false,
      hasMissedPunch: false,
      isStartPunchEditable: true,
      isEndPunchEditable: true,
      isPunchInFuture: false,
      isPunchOutFuture: false,
      punchedLocation: '',
    }

    return obj
  }

  useEffect(() => {
    if (Object.keys(timeCardDataDaily).length > 0) {
      let tempPunchesArr = []
      let punchSegmentLen = timeCardDataDaily.timecards[0]?.punch_segments.length
      if (punchSegmentLen === 0) {
        addPunch()
      } else if (punchSegmentLen > 0) {
        for (let i = 0; i < punchSegmentLen; i++) {
          let newPunchRow = new InitiateNewPunchRow()
          let punchSegment = timeCardDataDaily.timecards[0].punch_segments[i]

          let startPunch = punchSegment.start_punch
            ? convertToLocalTimeForPucnCorrections(punchSegment.start_punch)
            : null
          let endPunch = punchSegment.end_punch ? convertToLocalTimeForPucnCorrections(punchSegment.end_punch) : null

          // newPunchRow.punchDate = timeCardDataDaily.timecards[0].date
          newPunchRow.punchInDate = punchSegment.start_punch?.split('T')[0] || timeCardDataDaily.timecards[0].date
          newPunchRow.punchOutDate = punchSegment.end_punch?.split('T')[0] || timeCardDataDaily.timecards[0].date
          newPunchRow.existingPunchInTime = startPunch
          newPunchRow.existingPunchOutTime = endPunch
          newPunchRow.punchInTime = startPunch
          newPunchRow.punchOutTime = endPunch
          /* newPunchRow.isOvernight =
            startPunch && startPunch.getDate() > new Date(timeCardDataDaily.timecards[0].date).getDate() ? true : false */
          newPunchRow.isOvernight = checkOvernight(startPunch, endPunch)
          newPunchRow.nextDay = addDays(
            parseISO(new Date(timeCardDataDaily.timecards[0].date).toISOString().slice(0, -1)),
            1,
          )

          newPunchRow.hasMissedPunch = timeCardDataDaily.timecards[0].has_missed_punch
          newPunchRow.isStartPunchEditable = Object.prototype.hasOwnProperty.call(
            punchSegment,
            'is_editable_start_punch',
          )
            ? punchSegment.is_editable_start_punch
            : true
          newPunchRow.isEndPunchEditable = Object.prototype.hasOwnProperty.call(punchSegment, 'is_editable_end_punch')
            ? punchSegment.is_editable_end_punch
            : true
          newPunchRow.punchedLocation = punchSegment.punched_location
          newPunchRow.rowId = i + 1

          tempPunchesArr.push(newPunchRow)
        }
        setDayPunches(tempPunchesArr)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCardDataDaily])

  useEffect(() => {
    if (Object.keys(timeCardDataDaily).length > 0) {
      validateOverlaps(dayPunches)
      setIsFormReadyToSubmit(findInvalidPunches(dayPunches, timeCardDataDaily, reason))
    }
  }, [dayPunches, timeCardDataDaily, reason])

  useEffect(() => {
    if (isFormReadyToSubmit.status) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
  }, [isFormReadyToSubmit])

  useEffect(() => {
    if (punchCorrectionPostResponse) {
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
      dispatch(clearPreviousState())
      //Show Message to Team Member if Success
      // let showMessageObj = { showMessage: true, punchDate: timeCardDataDaily.timecards[0].date }
      // dispatch(showInfoMessage(showMessageObj))

      //hide loading
      setLoading(false)

      let arr = []
      if (localStorage.getItem('latestPCDates')) {
        arr = JSON.parse(localStorage.getItem('latestPCDates'))
      }
      arr.unshift({ date: timeCardDataDaily.date, lastUpdated: new Date() })
      localStorage.setItem('latestPCDates', JSON.stringify(arr))

      navigate('/team-member/timecardDaily')
    } else if (punchCorrectionPostErrorResponse) {
      if (
        punchCorrectionPostErrorResponse.code &&
        punchCorrectionPostErrorResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(punchCorrectionPostErrorResponse.code)
      ) {
        dispatch(showNotificationError(true, parseErrorList(punchCorrectionPostErrorResponse)))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE +
              ' ' +
              formatErrorCode(punchCorrectionPostErrorResponse, ERROR_CODE_CANNOT_CONNECT_TO_SERVER_PUNCH_CORRECTION),
          ),
        )
      }
      //hide loading
      setLoading(false)
      dispatch(clearPreviousState())
      navigate('/team-member/timecardDaily')
      //make showMessage false if fails
      // let showMessageObj = { showMessage: false, punchDate: timeCardDataDaily.timecards[0].date }
      // dispatch(showInfoMessage(showMessageObj))
    }
  }, [
    punchCorrectionPostResponse,
    punchCorrectionPostErrorResponse,
    dispatch,
    queryClient,
    navigate,
    timeCardDataDaily,
  ])

  const parseErrorList = (errResponse) => {
    let errorMessages = ''
    let message

    if (errResponse.errors && errResponse.errors.length > 0) {
      for (let error of errResponse.errors) {
        errorMessages += '<li>' + error + '</li>'
      }
      message = errResponse.message + '<br /><ul>' + errorMessages + '</ul>'
    } else {
      message = errResponse.message
    }

    return message
  }

  const punchInTimeChanged = (val, id) => {
    let copyOfDayPunches = [...dayPunches]

    for (let punchSegment of copyOfDayPunches) {
      if (punchSegment.rowId === id) {
        let isPunchOutSmaller = val ? checkIfPunchOutTimeSmaller(val, punchSegment.punchOutTime) : null
        let isOvernight = val ? checkOvernight(val, punchSegment.punchOutTime) : null
        let isPunchInFuture = val ? checkPunchInFuture(val) : null
        punchSegment.punchInTime = val
        if (isPunchOutSmaller) {
          punchSegment.isPunchOutSmaller = true
        } else {
          punchSegment.isPunchOutSmaller = false
        }
        if (isOvernight) {
          punchSegment.isOvernight = true
        } else {
          punchSegment.isOvernight = false
        }
        if (isPunchInFuture) {
          punchSegment.isPunchInFuture = true
        } else {
          punchSegment.isPunchInFuture = false
        }
      }
    }

    setDayPunches(copyOfDayPunches)
  }

  const punchOutTimeChanged = (val, id) => {
    let copyOfDayPunches = [...dayPunches]

    for (let punchSegment of copyOfDayPunches) {
      if (punchSegment.rowId === id) {
        let isPunchOutSmaller = val ? checkIfPunchOutTimeSmaller(punchSegment.punchInTime, val) : null
        let isOvernight = val ? checkOvernight(punchSegment.punchInTime, val) : null
        let isPunchOutFuture = val ? checkPunchOutFuture(val) : null
        punchSegment.punchOutTime = val
        if (isPunchOutSmaller) {
          punchSegment.isPunchOutSmaller = true
        } else {
          punchSegment.isPunchOutSmaller = false
        }
        if (isOvernight) {
          punchSegment.isOvernight = true
        } else {
          punchSegment.isOvernight = false
        }
        if (isPunchOutFuture) {
          punchSegment.isPunchOutFuture = true
        } else {
          punchSegment.isPunchOutFuture = false
        }
      }
    }

    setDayPunches(copyOfDayPunches)
  }

  const addPunch = () => {
    let copyOfDayPunches = [...dayPunches]
    let newPunchRow = new InitiateNewPunchRow()
    // newPunchRow.punchDate = timeCardDataDaily.timecards[0].date
    newPunchRow.punchInDate = timeCardDataDaily.timecards[0].date
    newPunchRow.punchOutDate = timeCardDataDaily.timecards[0].date
    newPunchRow.nextDay = addDays(parseISO(new Date(timeCardDataDaily.timecards[0].date).toISOString().slice(0, -1)), 1)
    newPunchRow.action = 'ADD'
    newPunchRow.rowId = copyOfDayPunches.length > 0 ? Math.max(...copyOfDayPunches.map((o) => o.rowId)) + 1 : 1
    copyOfDayPunches.push(newPunchRow)
    setDayPunches(copyOfDayPunches)
  }

  const removePunch = (id) => {
    let copyOfDayPunches = [...dayPunches]
    let tempDeletedPunches = [...deletedPunches]

    let index = copyOfDayPunches.findIndex((p) => p.rowId === id)
    if (index > -1) {
      if (copyOfDayPunches[index].existingPunchInTime || copyOfDayPunches[index].existingPunchOutTime) {
        copyOfDayPunches[index].action = 'DELETE'
        tempDeletedPunches.push(copyOfDayPunches[index])
        setDeletedPunches(tempDeletedPunches)
      }
    }

    copyOfDayPunches.splice(index, 1)
    setDayPunches(copyOfDayPunches)

    if (copyOfDayPunches.length === 0) {
      setShowAddPunchBtn(true)
      // let showMessageObj = { showMessage: false, punchDate: timeCardDataDaily.timecards[0].date }
      // dispatch(showInfoMessage(showMessageObj))
    } else {
      setShowAddPunchBtn(false)
    }
  }

  const handleSubmitRequest = () => {
    let requestDetails = formatDataBeforeSubmit(dayPunches, deletedPunches)
    if (requestDetails.length > 0 && reason !== '') {
      setLoading(true)
      let finalData = {
        worker_id: userData.worker_id,
        location_id: locationData.location_id,
        location_type: locationData.location_type,
        is_labor_transfer_exist: false,
        punch_date: timeCardDataDaily.timecards[0].date,
        reason_for_correction: reason,
        request_details: requestDetails,
      }

      dispatch(postRequestsData(finalData, locationData.location_type))
    } else {
      dispatch(showNotificationError(true, NO_CHANGES_DETECTED_ERROR_MESSAGE))
    }
  }

  const handleAddPunchClick = () => {
    setShowAddPunchBtn(false)
    addPunch()
  }

  const renderTopMessage = () => {
    const outputDateFormat = 'dddd, MMMM D YYYY'
    let selectedDate = moment(timeCardDataDaily.timecards[0].date, moment.HTML5_FMT.DATE)?.format(outputDateFormat)
    return (
      <React.Fragment>
        <Paper
          tabIndex={0}
          role="heading"
          aria-label={`you are making punch correction for ${selectedDate}`}
          sx={styles.datePickerContainer}
          elevation={1}
          square
        >
          <Typography>{selectedDate}</Typography>
        </Paper>
        {showAddPunchBtn && (
          <Paper id="scrollableContainer" data-cy="scrollableContainer_weekly" elevation={1} square>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Button data-cy="requestTimeOffCancel" color="primary" onClick={handleAddPunchClick}>
                  Add Punch
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
      </React.Fragment>
    )
  }

  const handleReasonChange = (event) => {
    setReason(event.target.value)
  }

  const renderReasons = () => {
    const requestTypeMenuItems = data?.configuration_values.map((element, index) => {
      return (
        <MenuItem key={index} value={element.primary_value}>
          <Grid sx={{ overflow: 'hidden' }}>{element.primary_value}</Grid>
        </MenuItem>
      )
    })

    return (
      <TextField
        sx={{ minWidth: '350px', maxWidth: '400px' }}
        select
        label="Reason"
        id="select-label-id-reason"
        //value={requestType}
        onChange={handleReasonChange}
      >
        {requestTypeMenuItems}
      </TextField>
    )
  }

  const renderScrollableContent = () => {
    return (
      <div style={styles.mainContainerPosition}>
        <Paper style={styles.mainScrollContainer}>
          {renderTopMessage()}
          <div style={styles.stickyHeader} square>
            <Grid container wrap="nowrap" padding={1} justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>{renderReasons()}</Grid>
            </Grid>
            {reason === '' && (
              <Grid item xs={12} sm={12}>
                <Typography variant="caption" display="block" gutterBottom style={styles.errorMsg}>
                  Reason can not be empty.
                </Typography>
              </Grid>
            )}
          </div>
          <div id="scrollableContainer" data-cy="scrollableContainer_weekly" elevation={1} square>
            {dayPunches.map((punchSegment, index) => (
              <List
                data-id={punchSegment.rowId}
                sx={styles.list}
                key={punchSegment.rowId}
                data-cy="requestPunchCorrectionList"
              >
                <RequestPunchCorrectionRowInput
                  rowDetail={punchSegment}
                  addPunch={addPunch}
                  removePunch={(id) => removePunch(id)}
                  punchInTimeChanged={(val) => punchInTimeChanged(val, punchSegment.rowId)}
                  punchOutTimeChanged={(val) => punchOutTimeChanged(val, punchSegment.rowId)}
                  rowId={punchSegment.rowId}
                  dayPunches={dayPunches}
                />
              </List>
            ))}
          </div>
        </Paper>
      </div>
    )
  }

  const setNavigateToDailyScreen = () => {
    navigate('/team-member/timecardDaily')
  }

  const renderBottomButtons = () => {
    return (
      <React.Fragment>
        <Paper sx={styles.bottomSectionContainer} elevation={1} square>
          <PunchCorrectionSubmitCancel
            showCancelDialog={hasChanges}
            isFormReadyToSubmit={isFormReadyToSubmit}
            setNavigateToDailyScreen={() => setNavigateToDailyScreen()}
            handleSubmitRequest={() => handleSubmitRequest()}
          />
        </Paper>
      </React.Fragment>
    )
  }

  const loader = (size = 24) => {
    if (loading) {
      return (
        <div style={{ padding: '10px 40px 10px 40px', textAlign: 'right' }}>
          <CircularProgress size={size} />
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      {Object.keys(timeCardDataDaily).length > 0 ? (
        <>
          <HeaderTitle title={PAGE_TITLE} />
          {renderScrollableContent()}
          {renderBottomButtons()}
          {loader()}
        </>
      ) : (
        navigate('/team-member/timecardDaily')
      )}
    </React.Fragment>
  )
}

export default RequestPunchCorrection
