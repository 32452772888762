import ErrorIcon from '@mui/icons-material/Error'
import { Tooltip, Typography } from '@mui/material'
import update from 'immutability-helper'
import React from 'react'
import {
  DEFAULT_PAGE_NUM,
  DEFAULT_PER_PAGE,
  DEFAULT_VOLUNTARY_DATA,
  DEFAULT_VOLUNTARY_DATA_SORT,
  DEFAULT_VOLUNTARY_DATA_TOTAL_RECORDS,
  VOLUNTARY_COLUMNS_ENUM,
  VOLUNTARY_COLUMNS_ORDER,
} from '../../components/LeaderViewAutomation/LeaderViewAutomation'
import {
  AUTOMATION_VOLUNTARY_AVAILABILITY_POST_ERROR,
  AUTOMATION_VOLUNTARY_AVAILABILITY_SET_POST_RESPONSE,
  AUTOMATION_VOLUNTARY_SET_DATA_COLUMN_VIEW,
  AUTOMATION_VOLUNTARY_SET_DOWN_SHIFT_TYPE,
  AUTOMATION_VOLUNTARY_SET_END_TIME,
  AUTOMATION_VOLUNTARY_SET_IS_INPUT_TYPE_TIME,
  AUTOMATION_VOLUNTARY_SET_SAVE_DIALOG_OPEN,
  AUTOMATION_VOLUNTARY_SET_START_TIME,
  AUTOMATION_VOLUNTARY_SHOULD_LOAD_DATA,
  GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_ERROR,
  GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_SUCCESS,
  GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_ERROR,
  GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS,
  LEADER_AUTOMATION_DECISION_ERROR,
  LEADER_AUTOMATION_DECISION_SUCCESS,
  SET_AUTOMATION_VOLUNTARY_DATA_LOADING,
  SET_AUTOMATION_VOLUNTARY_DATA_PAGE,
  SET_AUTOMATION_VOLUNTARY_DATA_PER_PAGE,
  SET_AUTOMATION_VOLUNTARY_DATA_SAVING,
  SET_AUTOMATION_VOLUNTARY_DATA_SEARCH,
  SET_AUTOMATION_VOLUNTARY_DATA_SELECTED_FILTERS,
  SET_AUTOMATION_VOLUNTARY_DATA_SORT,
  SET_AUTOMATION_VOLUNTARY_DATA_TABLE_COLUMNS_TO_DEFAULT,
  SET_AUTOMATION_VOLUNTARY_DATA_TABLE_FILTERS_TO_DEFAULT,
  SET_AUTOMATION_VOLUNTARY_DATA_TABLE_STATE_TO_DEFAULT,
  SET_AUTOMATION_VOLUNTARY_POST_RESPONSE_INELIGIBILITIES,
} from './actionType'

const buildPaginationDetailsFromHeaderResponse = (headers = {}) => {
  return {
    voluntaryDataTotalRecords: headers['x-total-count'],
  }
}

/**
 * Build the array of arrays for how many columns are defined in the enum. This is the function where a
 * default value (example - proficient: 'y') could be added.
 */
const buildDefaultSelectedFilters = () => {
  let columns = []

  VOLUNTARY_COLUMNS_ORDER.forEach(() => {
    let emptyArray = []
    columns.push(emptyArray)
  })

  return columns
}

const buildVoluntaryDataResponse = (data = {}) => {
  let voluntaryData = data

  voluntaryData.forEach((teamMember) => {
    // copying into different object so teamMember params don't get overwritten
    let copiedTeamMember = JSON.parse(JSON.stringify(teamMember))
    teamMember.total_hours_worked = weeklyHours(copiedTeamMember)
    teamMember.attendance_codes = extractAttendanceCodes(copiedTeamMember)
    teamMember.ineligibilities = extractAllRuleNames(copiedTeamMember)
    teamMember.schedule = extractAllShiftSegments(copiedTeamMember)
  })

  return {
    voluntaryData,
  }
}

/**
 * Also used in store/leaderViewMandatoryAutomation/reducer.js
 * @param teamMember
 * @returns {string}
 */
export function weeklyHours(teamMember) {
  return teamMember.schedule
    ? teamMember.schedule.has_missed_punch
      ? teamMember.weekly_total_hours + ' Hrs (MP)'
      : teamMember.weekly_total_hours + ' Hrs'
    : ''
}

/**
 * Also used in store/leaderViewMandatoryAutomation/reducer.js
 * @param teamMember
 * @returns {Array}
 */
export function extractAllShiftSegments(teamMember) {
  let schedule = []
  if (teamMember.schedule && teamMember.schedule.total_display_segments > 0) {
    let isTransfer = teamMember.schedule.has_transfer
    teamMember.schedule.display_segment.forEach((scheduleSegment) => {
      schedule.push(scheduleSegment.segment_start + ' - ' + scheduleSegment.segment_end)
    })

    if (isTransfer) {
      schedule.push('(Transfer)')
    }
  }
  return schedule
}

/**
 * Also used in store/leaderViewMandatoryAutomation/reducer.js
 * @param teamMember
 * @returns {*}
 */
export function extractAllRuleNames(teamMember) {
  let ruleName = []

  if (teamMember.total_ineligibilities > 0) {
    teamMember.ineligibilities.forEach((ineligibility) => {
      ruleName.push(ineligibility.eligibility_name)
    })
  }

  return ruleName.length ? ruleName : ''
}

export function extractAttendanceCodes(teamMember) {
  let payCodes = []
  if (teamMember.schedule && teamMember.schedule.total_paycode_edits > 0) {
    teamMember.schedule.paycode_edits.forEach((paycode) => {
      payCodes.push(paycode.paycode_name + ' ' + paycode.paycode_start_time + ' (' + paycode.paycode_total + ' hrs)')
    })
  }

  return payCodes
}

const updateSelectedFilters = (state, selectedFilters) => {
  return update(state, {
    voluntaryColumns: {
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.scheduling_key)]: {
        options: {
          filterList: { $set: selectedFilters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.scheduling_key)] },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.department)]: {
        options: {
          filterList: { $set: selectedFilters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.department)] },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.job)]: {
        options: {
          filterList: { $set: selectedFilters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.job)] },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.proficient)]: {
        options: {
          filterList: { $set: selectedFilters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.proficient)] },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.voluntary_preference)]: {
        options: {
          filterList: {
            $set: selectedFilters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.voluntary_preference)],
          },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.status)]: {
        options: {
          filterList: { $set: selectedFilters[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.status)] },
        },
      },
    },
    voluntaryDataSelectedFilters: { $set: selectedFilters },
  })
}

const updateTmActionSortColumns = (state, sort) => {
  return update(state, {
    voluntaryColumns: {
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.last_updated_timestamp)]: {
        options: {
          sortOrder: { $set: sort.toLowerCase() },
        },
      },
    },
    voluntaryDataSort: { $set: sort },
  })
}

const updateColumnView = (state, column, action) => {
  let isViewable
  if (action === 'add') {
    isViewable = true
  }
  if (action === 'remove') {
    isViewable = false
  }

  return update(state, {
    voluntaryColumns: {
      [VOLUNTARY_COLUMNS_ORDER.indexOf(column)]: {
        options: {
          display: { $set: isViewable },
        },
      },
    },
  })
}

const updateVoluntaryColumns = (state, data) => {
  return update(state, {
    voluntaryColumns: {
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.full_name)]: {
        options: {
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              let ineligibleData =
                tableMeta.rowData[VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.ineligibilities)]

              if (ineligibleData) {
                return (
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">
                          This team member is currently violating the following schedule rules:
                        </Typography>
                        <ul>
                          {ineligibleData.map((ineligibility, i) => (
                            <li key={i}>{'- ' + ineligibility}</li>
                          ))}
                        </ul>
                      </React.Fragment>
                    }
                  >
                    <span>
                      {value + ' '}
                      <ErrorIcon style={{ color: '#cc0000', fontSize: 'small' }} />
                    </span>
                  </Tooltip>
                )
              } else {
                return value
              }
            },
          },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.scheduling_key)]: {
        options: {
          filterOptions: {
            names: { $set: data.job_keys },
          },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.department)]: {
        options: {
          filterOptions: {
            names: { $set: data.departments },
          },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.job)]: {
        options: {
          filterOptions: {
            names: { $set: data.jobs },
          },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.proficient)]: {
        options: {
          filterOptions: {
            names: { $set: data.proficiencies },
          },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.voluntary_preference)]: {
        options: {
          filterOptions: {
            names: { $set: data.preferences },
          },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.status)]: {
        options: {
          filterOptions: {
            names: { $set: data.statuses },
          },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.schedule)]: {
        options: {
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              if (value) {
                return (
                  <ul>
                    {value.map((shiftSegment, index) => (
                      <li style={{ listStyleType: 'none' }} key={index}>
                        {shiftSegment}
                      </li>
                    ))}
                  </ul>
                )
              } else {
                return ''
              }
            },
          },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.attendance_codes)]: {
        options: {
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              if (value) {
                return (
                  <ul>
                    {value.map((attendanceCode, index) => (
                      <li style={{ listStyleType: 'none' }} key={index}>
                        {attendanceCode}
                      </li>
                    ))}
                  </ul>
                )
              } else {
                return ''
              }
            },
          },
        },
      },
      [VOLUNTARY_COLUMNS_ORDER.indexOf(VOLUNTARY_COLUMNS_ENUM.ineligibilities)]: {
        options: {
          customBodyRender: {
            $set: (value, tableMeta, updateValue) => {
              if (value) {
                return (
                  <ul>
                    {value.map((ineligibility, i) => (
                      <li key={i}>{ineligibility}</li>
                    ))}
                  </ul>
                )
              } else {
                return ''
              }
            },
          },
        },
      },
    },
    voluntaryFiltersGetError: { $set: null },
  })
}

export const initialState = {
  workersAvailabilityPreferences: null,
  voluntaryFiltersGetError: null,
  voluntaryGetError: null,
  voluntaryPostError: null,
  voluntaryPostResponse: null,
  voluntaryPostResponseIneligibilities: null,
  voluntaryDataSelectedFilters: buildDefaultSelectedFilters(),
  voluntaryData: DEFAULT_VOLUNTARY_DATA,
  voluntaryDataLoading: false,
  voluntaryDataSaving: false,
  voluntaryPrevRequestedSearch: null,
  voluntaryPrevRequestedSelectedFilters: null,
  voluntaryDataSearch: null,
  voluntaryDataSort: DEFAULT_VOLUNTARY_DATA_SORT,
  voluntaryDataTotalRecords: DEFAULT_VOLUNTARY_DATA_TOTAL_RECORDS,
  voluntaryDataPage: DEFAULT_PAGE_NUM,
  voluntaryDataPerPage: DEFAULT_PER_PAGE,
  voluntarySaveDialogOpen: null,
  isInputTypeTime: false,
  downShiftType: 'Down - Full',
  startTime: new Date().setHours(12, 0, 0, 0),
  endTime: new Date().setHours(12, 0, 0, 0),
  shouldLoadVoluntaryData: false,
  voluntaryColumns: [
    /**
     * Column order matters here. If add/removing or changing order of a
     * column(s) here, also update VOLUNTARY_COLUMNS_ORDER
     */
    {
      name: VOLUNTARY_COLUMNS_ORDER[0],
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[1],
      label: 'Team Member ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[2],
      label: 'Key',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {
          names: [],
        },
        sort: false,
        searchable: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[3],
      label: 'Department',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[4],
      label: 'Job Classification',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[5],
      label: 'Proficiency',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[6],
      label: 'Voluntary Preference',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[7],
      label: 'TM Action Timestamp',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        searchable: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[8],
      label: 'Status',
      options: {
        filter: true,
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[9],
      label: 'Status Timestamp',
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[10],
      label: 'Schedule',
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[11],
      label: 'Attendance Code',
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[12],
      label: 'Total Weekly Hours',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        hint: 'Actual Hours through yesterday + Future Schedules',
      },
    },
    {
      name: VOLUNTARY_COLUMNS_ORDER[13],
      label: 'Rule Violations',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ],
}

export default function leaderViewAvailability(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AUTOMATION_VOLUNTARY_DATA_SELECTED_FILTERS: {
      const { payload } = action
      return updateSelectedFilters(state, payload)
    }
    case AUTOMATION_VOLUNTARY_SET_DATA_COLUMN_VIEW: {
      const { column, columnAction } = action
      return updateColumnView(state, column, columnAction)
    }
    case SET_AUTOMATION_VOLUNTARY_DATA_LOADING: {
      const { payload, search, selectedFilters } = action
      return {
        ...state,
        voluntaryDataLoading: payload,
        voluntaryPrevRequestedSearch: search,
        voluntaryPrevRequestedSelectedFilters: selectedFilters,
      }
    }
    case SET_AUTOMATION_VOLUNTARY_DATA_SAVING: {
      const { isSaving } = action
      return {
        ...state,
        voluntaryDataSaving: isSaving,
      }
    }
    case SET_AUTOMATION_VOLUNTARY_POST_RESPONSE_INELIGIBILITIES: {
      const { ineligibilities } = action
      return {
        ...state,
        voluntaryPostResponseIneligibilities: ineligibilities,
      }
    }
    case SET_AUTOMATION_VOLUNTARY_DATA_SEARCH: {
      const { payload } = action
      return {
        ...state,
        voluntaryDataSearch: payload,
      }
    }
    case SET_AUTOMATION_VOLUNTARY_DATA_SORT: {
      const { payload } = action
      return updateTmActionSortColumns(state, payload)
    }
    case SET_AUTOMATION_VOLUNTARY_DATA_PAGE: {
      const { payload } = action
      return {
        ...state,
        voluntaryDataPage: payload,
      }
    }
    case SET_AUTOMATION_VOLUNTARY_DATA_PER_PAGE: {
      const { payload } = action
      return {
        ...state,
        voluntaryDataPerPage: payload,
      }
    }
    case GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        voluntaryFiltersGetError: record.response ? record.response.data : record,
      }
    }
    case GET_AUTOMATION_LEADER_AVAILABILITY_FILTERS_REQUEST_SUCCESS: {
      const { record } = action
      return updateVoluntaryColumns(state, record.data)
    }
    case GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        voluntaryGetError: record.response ? record.response.data : record,
        voluntaryPostError: null,
        voluntaryDataLoading: false,
      }
    }
    case GET_WORKERS_AUTOMATION_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS: {
      const { record } = action
      return {
        ...state,
        ...buildVoluntaryDataResponse(record.data.workers_availability_preferences),
        ...buildPaginationDetailsFromHeaderResponse(record.headers),
        voluntaryPostError: null,
        voluntaryGetError: null,
        voluntaryDataLoading: false,
      }
    }
    case SET_AUTOMATION_VOLUNTARY_DATA_TABLE_STATE_TO_DEFAULT: {
      return {
        ...state,
        voluntaryData: DEFAULT_VOLUNTARY_DATA,
        voluntaryDataTotalRecords: DEFAULT_VOLUNTARY_DATA_TOTAL_RECORDS,
      }
    }
    case SET_AUTOMATION_VOLUNTARY_DATA_TABLE_FILTERS_TO_DEFAULT: {
      return {
        ...state,
        ...updateSelectedFilters(state, buildDefaultSelectedFilters()),
      }
    }
    case SET_AUTOMATION_VOLUNTARY_DATA_TABLE_COLUMNS_TO_DEFAULT: {
      return {
        ...state,
        voluntaryColumns: initialState.voluntaryColumns,
      }
    }
    case LEADER_AUTOMATION_DECISION_SUCCESS: {
      const { record } = action
      return {
        ...state,
        voluntaryPostResponse: record.data,
        voluntaryPostError: null,
        voluntaryGetError: null,
        voluntaryDataSaving: false,
        voluntarySaveDialogOpen: null,
        voluntaryPostResponseIneligibilities: null,
      }
    }
    case LEADER_AUTOMATION_DECISION_ERROR: {
      const { record } = action
      return {
        ...state,
        voluntaryPostError: record.response ? record.response.data : record,
        voluntaryGetError: null,
        voluntaryDataSaving: false,
        voluntarySaveDialogOpen: null,
        voluntaryPostResponseIneligibilities: null,
      }
    }
    case AUTOMATION_VOLUNTARY_AVAILABILITY_SET_POST_RESPONSE: {
      const { payload } = action
      return {
        ...state,
        voluntaryPostResponse: payload,
      }
    }
    case AUTOMATION_VOLUNTARY_AVAILABILITY_POST_ERROR: {
      const { error } = action
      return {
        ...state,
        voluntaryPostError: error,
        disableSaveButtons: false,
      }
    }
    case AUTOMATION_VOLUNTARY_SET_SAVE_DIALOG_OPEN: {
      const { payload } = action
      return {
        ...state,
        voluntarySaveDialogOpen: payload,
      }
    }
    case AUTOMATION_VOLUNTARY_SET_IS_INPUT_TYPE_TIME: {
      const { payload } = action
      return {
        ...state,
        isInputTypeTime: payload,
      }
    }
    case AUTOMATION_VOLUNTARY_SET_DOWN_SHIFT_TYPE: {
      const { payload } = action
      return {
        ...state,
        downShiftType: payload,
      }
    }
    case AUTOMATION_VOLUNTARY_SET_START_TIME: {
      const { payload } = action
      return {
        ...state,
        startTime: payload,
      }
    }
    case AUTOMATION_VOLUNTARY_SET_END_TIME: {
      const { payload } = action
      return {
        ...state,
        endTime: payload,
      }
    }
    case AUTOMATION_VOLUNTARY_SHOULD_LOAD_DATA: {
      const { shouldLoad } = action
      return {
        ...state,
        shouldLoadVoluntaryData: shouldLoad,
      }
    }

    default:
      return state
  }
}
