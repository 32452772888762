import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { addDays, isAfter, isBefore, isSameDay, subDays } from 'date-fns'
import { useEffect, useState } from 'react'

function CustomDay(props: PickersDayProps<Date> & { selectedDates?: Date[] }) {
  const { selectedDates = [], day, outsideCurrentMonth, ...other } = props

  const isSelected = selectedDates.some((selectedDate) => isSameDay(selectedDate, day))

  return <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} selected={isSelected} />
}

interface DateCalendarUserSelectionProps {
  onDatesChange: (dates: Date[]) => void
  minDate?: Date
  maxDate?: Date
  initialValue?: Date[]
  disabled?: boolean
  maxDaysInRange?: number
}

export default function DateCalendarUserSelection({
  onDatesChange,
  minDate,
  maxDate,
  initialValue,
  disabled,
  maxDaysInRange,
}: DateCalendarUserSelectionProps) {
  const [selectedDates, setSelectedDates] = useState<Date[]>(initialValue || [])
  const [defaultMonth, setDefaultMonth] = useState<Date | null | undefined>(minDate)
  const [minSelectableDate, setMinSelectableDate] = useState<Date | null>()
  const [maxSelectableDate, setMaxSelectableDate] = useState<Date | null>()

  useEffect(() => {
    if (initialValue) {
      setSelectedDates(initialValue)
      setDefaultMonth(initialValue.length > 0 ? initialValue[0] : minDate)
    }
  }, [initialValue, minDate])

  useEffect(() => {
    if (selectedDates.length > 0 && maxDaysInRange) {
      const validSelectedDates = selectedDates.filter((date) => !isNaN(date.getTime())) // Filter out invalid dates

      if (validSelectedDates.length > 0) {
        const earliestDate = new Date(Math.min(...validSelectedDates.map((date) => date.getTime())))
        const latestDate = new Date(Math.max(...validSelectedDates.map((date) => date.getTime())))

        const earliestSelectable = subDays(latestDate, maxDaysInRange - 1)
        const latestSelectable = addDays(earliestDate, maxDaysInRange - 1)

        const calculatedMinSelectable =
          minDate && isAfter(earliestSelectable, minDate) ? earliestSelectable : minDate || earliestSelectable
        const calculatedMaxSelectable =
          maxDate && isBefore(latestSelectable, maxDate) ? latestSelectable : maxDate || latestSelectable

        setMinSelectableDate(calculatedMinSelectable)
        setMaxSelectableDate(calculatedMaxSelectable)
      }
    } else {
      setMinSelectableDate(minDate)
      setMaxSelectableDate(maxDate)
    }
  }, [maxDate, maxDaysInRange, minDate, selectedDates])

  const handleDaySelect = (date: Date | null) => {
    if (!date) return

    setSelectedDates((prevSelectedDates) => {
      // Check if the date is already selected
      const isSelected = prevSelectedDates.some((selectedDate) => isSameDay(selectedDate, date))

      let newSelectedDates = isSelected
        ? prevSelectedDates.filter((selectedDate) => !isSameDay(selectedDate, date))
        : [...prevSelectedDates, date]

      // Call the callback to update the parent component
      onDatesChange(newSelectedDates)
      return newSelectedDates
    })
  }

  return (
    <DateCalendar
      renderLoading={() => <DayCalendarSkeleton />}
      onChange={handleDaySelect}
      minDate={minSelectableDate || minDate}
      maxDate={maxSelectableDate || maxDate}
      value={defaultMonth}
      disabled={disabled}
      slots={{
        day: CustomDay,
      }}
      slotProps={{
        day: {
          selectedDates,
        } as any,
      }}
    />
  )
}
