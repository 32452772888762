import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const CANCEL_DIALOG_TITLE = 'Are you sure you want to cancel?'
const CANCEL_DIALOG_TEXT = 'You have unsaved changes to this page. If you cancel, you will lose these changes.'

const PunchCorrectionCancelModal = ({ cancelDialogOpen, handleYesForCancelAction, handleNoForCancelAction }) => {
  const handleYes = () => {
    handleYesForCancelAction()
  }

  const handleNo = () => {
    handleNoForCancelAction()
  }

  return (
    <Dialog
      role="dialog"
      data-cy="punchCorrectionCancelDialog"
      open={cancelDialogOpen}
      onClose={handleNo}
      keepMounted
      aria-labelledby="pc-cancel-dialog-title"
      aria-describedby="pc-cancel-dialog-description"
      tabIndex="0"
    >
      <DialogTitle id="pc-cancel-dialog-title">{CANCEL_DIALOG_TITLE}</DialogTitle>

      <DialogContent id="pc-cancel-dialog-description">
        <DialogContentText>{CANCEL_DIALOG_TEXT}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <span>
          <Button onClick={handleNo} color="primary" aria-label="No">
            NO
          </Button>

          <Button onClick={handleYes} color="primary" aria-label="Yes">
            YES
          </Button>
        </span>
      </DialogActions>
    </Dialog>
  )
}

export default PunchCorrectionCancelModal
