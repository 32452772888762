import Clear from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress, InputAdornment } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { useRef, useState } from 'react'
import { useDebounce } from '../../hooks/useDebounce'

const AdornmentState = {
  Search: 'search',
  Spin: 'spin',
  Close: 'close',
}

const getAdornmentState = (value, isDebouncing) => {
  if (isDebouncing) return AdornmentState.Spin
  if (value) return AdornmentState.Close
  return AdornmentState.Search
}

const SearchInputAdornment = ({ adornmentState, onClick }) => {
  if (adornmentState === AdornmentState.Search) {
    return (
      <IconButton
        disabled
        aria-hidden={true}
        size="large"
        sx={{
          padding: '4px',
          marginRight: '-8px',
          '&:disabled': {
            color: 'tertiary.contrastText',
          },
        }}
      >
        <SearchIcon />
      </IconButton>
    )
  }
  if (adornmentState === AdornmentState.Spin) {
    return (
      <IconButton
        disabled
        aria-label="Typing indicator"
        data-cy="searchNameTypingSpinner"
        size="large"
        sx={{
          padding: '4px',
          paddingLeft: '6px',
          marginRight: '-2px',
        }}
      >
        <CircularProgress size="1rem" />
      </IconButton>
    )
  }
  if (adornmentState === AdornmentState.Close) {
    return (
      <IconButton
        sx={{
          padding: '4px',
          marginRight: '-4px',
          '&:hover': {
            color: 'primary.main',
          },
        }}
        onClick={onClick}
        aria-label="Clear search text field"
        data-cy="searchNameClear"
        size="large"
      >
        <Clear fontSize="small" />
      </IconButton>
    )
  }
}

const DelayedSearch = (props) => {
  const {
    tooltipTitle,
    delay = 1250,
    id,
    label,
    ariaLabel,
    dataCy,
    searchAutoFocus,
    defaultValue,
    onSearch,
    // eslint-disable-next-line
    validationPattern = /^[0-9A-Za-z.,!\/_\-wáÁéÉíÍóÓúÚñÑ ]*$/,
  } = props
  const [value, setValue] = useState(defaultValue)
  const [isDebouncing, setIsDebouncing] = useState(false)
  const ref = useRef(null)

  const onChange = () => {
    onSearch(value)
    setIsDebouncing(false)
  }

  const handleClearButtonPress = () => {
    setValue('')
    onSearch('')
    ref.current.focus()
  }

  const debouncedOnChange = useDebounce(onChange, delay)
  return (
    <Tooltip title={tooltipTitle}>
      <TextField
        id={id}
        inputRef={ref}
        label={label}
        aria-label={ariaLabel}
        data-cy={dataCy}
        autoFocus={searchAutoFocus}
        fullWidth
        value={value}
        onChange={(e) => {
          if (validationPattern.test(e.target.value)) {
            debouncedOnChange()
            setValue(e.target.value)
            setIsDebouncing(true)
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchInputAdornment
                adornmentState={getAdornmentState(value, isDebouncing)}
                onClick={handleClearButtonPress}
              />
            </InputAdornment>
          ),
        }}
      />
    </Tooltip>
  )
}
export default DelayedSearch
