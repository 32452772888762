import React from 'react'
import { Button } from '@mui/material'

const styles = {
  bottomButtons: {
    margin: '10px',
  },
  subTitle: {
    fontWeight: 600,
  },
  voluntarySelection: {
    marginTop: '20px',
  },
}

const PunchCorrectionLeaderApproveReject = (props) => {
  const { approveClicked, denyClicked, buttonDisabled } = props

  return (
    <React.Fragment>
      <Button
        data-cy="punchCorrectionCancel"
        sx={styles.bottomButtons}
        color="primary"
        onClick={denyClicked}
        disabled={buttonDisabled}
      >
        Deny
      </Button>
      <Button
        data-cy="punchCorrectionSubmit"
        sx={styles.bottomButtons}
        variant="contained"
        // disabled
        color="primary"
        onClick={approveClicked}
        disabled={buttonDisabled}
      >
        Approve
      </Button>
    </React.Fragment>
  )
}

export default PunchCorrectionLeaderApproveReject
