import { Grid, ListItem, Typography } from '@mui/material'

const CategoryDetailDataRow = ({ label, value, voiceOverTxt }) => {
  return (
    <ListItem tabIndex={0} sx={{ paddingY: '2px' }}>
      <Grid container>
        <Grid item xs={5}>
          <Typography role="heading" aria-label={label} variant="body2" color="tertiary.contrastText">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
            role="heading"
            aria-label={voiceOverTxt}
            sx={{ display: 'block' }}
            component="span"
            variant="body2"
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  )
}

const CategoryDetailDataList = ({ dataList }) => {
  return (
    <>
      {dataList?.map((data, i) => {
        return (
          <CategoryDetailDataRow
            label={data?.label}
            value={data?.value}
            voiceOverTxt={data?.voiceOverTxt ? data.voiceOverTxt : ''}
            key={i}
          />
        )
      })}
    </>
  )
}

export default CategoryDetailDataList
