import Firefly from 'firefly-sdk-ts'
import * as log from 'loglevel'
const internalappConfig = require('../config/firefly-internal-app-config.json')
const internalappLogConfig = require('../config/firefly-internal-app-log-config')

export function initializeFireflySDK() {
  Firefly.setLoggingLevel(log.levels.TRACE)

  log.debug('Loading configuration ... ')

  Firefly.registerEventManagerWithConfig(internalappConfig)

  Firefly.registerEventManager(
    internalappLogConfig.eventManagerKey,
    internalappLogConfig.apiKey,
    internalappLogConfig.persistMethod,
    internalappLogConfig.retries,
    internalappLogConfig.schemaId,
    internalappLogConfig.url,
    //apiConfig.firefly.url
  )
}
