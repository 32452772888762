import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import React from 'react'

export const renderRuleViolationPanelWorkerListItem = (workerId, workerName) => {
  return (
    <ListItem>
      <Typography variant="body2">{workerId + ' - ' + workerName}</Typography>
    </ListItem>
  )
}

export const renderRuleViolationPanel = (rule, workerIds, workerIdtoNameMap) => {
  return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">{rule}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {workerIds.map((workerId) => {
              return renderRuleViolationPanelWorkerListItem(workerId, workerIdtoNameMap.get(workerId))
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )
}

export const renderRuleViolationPanels = (postResponseIneligibilities, workerIdToNameMap) => {
  let ruleToWorkersMap = convertIneligibilitiesForRender(postResponseIneligibilities)
  let keys = [...ruleToWorkersMap.keys()]
  return (
    <React.Fragment>
      {keys.map((element) => {
        return renderRuleViolationPanel(element, ruleToWorkersMap.get(element), workerIdToNameMap)
      })}
    </React.Fragment>
  )
}

export const render2PartDialogContent = (
  part1Title,
  part1MessageArray,
  part2Title,
  part2MessageArray,
  expandPanelsTitle,
  expandPanels,
) => {
  return (
    <React.Fragment>
      <DialogTitle id="save-dialog-part1-title">{part1Title}</DialogTitle>
      <DialogContent>
        {part1MessageArray.map((message) => {
          return <DialogContentText gutterBottom>{message}</DialogContentText>
        })}
      </DialogContent>
      {expandPanelsTitle}
      {expandPanels}
      <br />
      <Divider />
      <DialogTitle id="save-dialog-part2-title">{part2Title}</DialogTitle>
      <DialogContent>
        {part2MessageArray.map((message) => {
          return <DialogContentText gutterBottom>{message}</DialogContentText>
        })}
        <br />
      </DialogContent>
    </React.Fragment>
  )
}

export const convertIneligibilitiesForRender = (ineligibilities) => {
  let ruleToWorkerMap = new Map()

  for (const [k, v] of ineligibilities.entries()) {
    v.forEach((ineligibility) => {
      if (ruleToWorkerMap.has(ineligibility.eligibility_name)) {
        let workerIdList = ruleToWorkerMap.get(ineligibility.eligibility_name)
        workerIdList.push(k)
      } else {
        let workerIdList = [k]
        ruleToWorkerMap.set(ineligibility.eligibility_name, workerIdList)
      }
    })
  }
  return ruleToWorkerMap
}

export const getPart1Title = (totalSaved, totalSelected) => {
  return (
    'Scheduling decision actions resulted in ' +
    totalSaved +
    ' out of ' +
    totalSelected +
    ' team member(s) saved successfully'
  )
}

export const getPart2Title = (totalRuleViolatedWorkers) => {
  return (
    'Do you still want to save all ' +
    totalRuleViolatedWorkers +
    ' team member(s) that were blocked because of rule violations?'
  )
}

export const getPart1MessageArray = (totalRuleViolatedWorkers) => {
  let part1MessageArray = []
  part1MessageArray.push(
    'Your scheduling decision action resulted in ' +
      totalRuleViolatedWorkers +
      ' team member(s) being blocked from saving because they would be violating schedule rules as a result of this schedule action.',
  )
  return part1MessageArray
}

export const getPart2MessageArray = (scheduleText1, scheduleText2, scheduleText3) => {
  let part2MessageArray = []
  part2MessageArray.push(scheduleText1)
  part2MessageArray.push(scheduleText2)
  part2MessageArray.push(scheduleText3)

  return part2MessageArray
}

export const getAboutToSaveText = (screenName, upDownState, totalRuleViolatedWorkers, selectedLocationId) => {
  return (
    'You are about to save a ' +
    screenName +
    ' ' +
    upDownState +
    ' decision affecting ' +
    totalRuleViolatedWorkers +
    ' team member(s) for location ' +
    selectedLocationId +
    '.'
  )
}

export const getHowSaveAffectsVoluntaryText = (
  upDownState,
  selectedLocationId,
  selectedDate,
  startTime,
  endTime,
  isInputTypeTime,
  shiftType,
  shiftStartDate,
) => {
  let text
  if (upDownState === 'up') {
    text =
      'This decision will remove any existing shift on ' +
      selectedDate +
      ' and apply a shift starting on ' +
      shiftStartDate +
      ' from ' +
      startTime +
      ' to ' +
      endTime +
      '.'
  } else {
    if (isInputTypeTime) {
      text =
        'This decision will apply an attendance code on ' +
        shiftStartDate +
        ' from ' +
        startTime +
        ' to ' +
        endTime +
        '.'
    } else {
      text =
        'This decision will apply an attendance code on ' + selectedDate + ' with a shift type of ' + shiftType + '.'
    }
  }
  return text
}

export const getHowSaveAffectsMandatoryText = (
  upDownState,
  selectedLocationId,
  selectedDate,
  startTime,
  endTime,
  shiftStartDate,
) => {
  let text
  if (upDownState === 'up') {
    text =
      'This decision will remove any existing shift on ' +
      selectedDate +
      ' and apply a shift starting on ' +
      shiftStartDate +
      ' from ' +
      startTime +
      ' to ' +
      endTime +
      '.'
  } else {
    text =
      'This decision will apply an attendance code on ' + shiftStartDate + ' from ' + startTime + ' to ' + endTime + '.'
  }
  return text
}

export const getIfChooseYesText = (totalRuleViolatedWorkers) => {
  return (
    'If you choose yes, there will be ' +
    totalRuleViolatedWorkers +
    ' team member(s) that will be violating schedule rules after saving.'
  )
}

export const renderUnableToSaveFromStatusDialogContent = (screenName, incompatibleStatusTmLength) => {
  if (incompatibleStatusTmLength) {
    return (
      <DialogContent>
        <DialogContentText gutterBottom>
          {'You are attempting to perform a ' +
            screenName +
            ' down schedule action, and there are ' +
            incompatibleStatusTmLength +
            ' checked rows that already have a Status of: '}
          <b>Scheduled</b>
        </DialogContentText>
        <DialogContentText gutterBottom>
          {'You must uncheck these rows or change these statuses from Scheduled via the Un-Schedule button.'}
        </DialogContentText>
      </DialogContent>
    )
  }
}

export const renderUnableToSaveDownActionFromNoSchedule = (screenName, noScheduleTmLength) => {
  if (noScheduleTmLength) {
    return (
      <DialogContent>
        <DialogContentText gutterBottom>
          {'You are attempting to perform a ' +
            screenName +
            ' down schedule action, and there are ' +
            noScheduleTmLength +
            ' checked rows that currently have no schedule.'}
        </DialogContentText>
      </DialogContent>
    )
  }
}

export const renderDivider = () => {
  return (
    <React.Fragment>
      <Divider />
      <br />
    </React.Fragment>
  )
}
