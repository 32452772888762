import JobIcon from '@mui/icons-material/Label'
import LocationIcon from '@mui/icons-material/Place'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { differenceInMinutes } from 'date-fns'
import moment from 'moment'
import React from 'react'
import { truncateString } from '../../utils/strings'
import HeartIcon from '../Icons/HeartIcon'
import PersonIcon from '../Icons/PersonIcon'

const DisplaySegmentShiftSwap = (props) => {
  const { styles, shift, ownShift, flow, totalRequests } = props

  const renderShiftDate = () => {
    let shiftObj = {}
    shiftObj.schedule_date = shift.schedule_date || shift.shift_date
    shiftObj.shift_start = shift.shift_start || shift.segment_start
    shiftObj.shift_end = shift.shift_end || shift.segment_end
    shiftObj.shift_length =
      shift.shift_length || shift.shift_hours || handleHours(shiftObj.shift_start, shiftObj.shift_end)

    let sDate = moment(shiftObj.schedule_date, moment.HTML5_FMT.DATE)
    const outputDateFormat = 'dddd, MMMM D'
    if (shiftObj.schedule_date) {
      return (
        <div style={styles.dateContainer}>
          <Typography variant="body2" sx={styles.dateHeadingSwap} data-cy="nextSchedDisplaySegDateOnWeekly">
            {`${sDate?.format(outputDateFormat)} (${shiftObj.shift_length} Hrs)`}
          </Typography>
          {displayShiftLabels()}
        </div>
      )
    }
  }

  const displayShiftLabels = () => {
    if (shift.shift_label) {
      return (
        <Typography sx={styles.shiftLabelContainer}>
          <span style={styles.shiftLabel}>{`${truncateString(shift.shift_label, 3)}`}</span>
        </Typography>
      )
    } else {
      return null
    }
  }

  const renderLocation = (location) => {
    if (location) {
      return (
        <React.Fragment>
          <LocationIcon color="primary" sx={styles.iconMargin} />
          <Typography variant="body2" sx={styles.locationName} data-cy="nextSchedDisplaySegLoc">
            {location}
          </Typography>
        </React.Fragment>
      )
    }
  }

  const renderJob = (jobName, totalJobs) => {
    if (jobName && totalJobs != null) {
      return (
        <React.Fragment>
          <JobIcon color="primary" sx={styles.iconMargin} />
          {totalJobs > 1 ? renderIndicator(props) : null}
          <Typography variant="body2" sx={styles.jobName}>
            {jobName}
          </Typography>
        </React.Fragment>
      )
    }
  }

  //commented this block of code for temprary
  const renderTM = (short_name) => {
    if (short_name != null) {
      return (
        <React.Fragment>
          <PersonIcon color="primary" styles={styles} />
          <Typography variant="body2" sx={styles.jobName}>
            {short_name}
          </Typography>
        </React.Fragment>
      )
    }
  }

  const renderIndicator = (props) => {
    return <div style={styles.indicator}>*</div>
  }

  const handleHours = (shift_start, shift_end) => {
    let shiftInHours = differenceInMinutes(new Date(shift_end), new Date(shift_start)) / 60
    return Number.parseFloat(shiftInHours).toFixed(2)
  }

  const hideChars = (name) => {
    if (name.indexOf('(') > -1) {
      let tmName = name.substring(name.indexOf('(') + 1, name.indexOf(')'))
      tmName = tmName.padStart(8, 'x')
      return `${name.substring(0, name.indexOf('('))} (${tmName})`
    } else {
      return name
    }
  }

  const renderDisplaySegment = (props) => {
    let shiftObj = {}
    shiftObj.shift_start = shift.shift_start || shift.segment_start
    shiftObj.shift_end = shift.shift_end || shift.segment_end
    shiftObj.location = shift.location || shift.location_id
    shiftObj.job_name = shift.job_name
    shiftObj.total_jobs = shift.total_jobs || shift.jobs?.length
    shiftObj.short_name = ownShift ? 'You' : shift.short_name ? hideChars(shift.short_name) : null

    const inputTimeFormat = 'YYYY-MM-DD HH:mm:ss'
    const outputTimeFormat = 'hh:mmA'

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <div style={{ textDecoration: 'none', display: 'flex', flexDirection: 'row' }}>
              <Grid item xs={4} sm={4} sx={styles.displaySegmentContainerSwap}>
                <Typography variant="body2" sx={styles.startTimeItem}>
                  {moment(shiftObj.shift_start, inputTimeFormat)?.format(outputTimeFormat)}
                </Typography>
                <Typography variant="body2" sx={styles.endTimeItem}>
                  {moment(shiftObj.shift_end, inputTimeFormat)?.format(outputTimeFormat)}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} sx={styles.displaySegmentContainerSwap}>
                <div style={styles.locationContainer}>{renderLocation(shiftObj.location)}</div>
                <div style={styles.jobContainer}>{renderJob(shiftObj.job_name, shiftObj.total_jobs)}</div>
              </Grid>
            </div>
          </Grid>
          <Grid>
            <div style={styles.jobContainer}>{renderTM(shiftObj.short_name)}</div>
          </Grid>
          {flow === 'acceptor' && totalRequests > 0 && (
            <Grid item xs={1} sm={1} sx={styles.shiftSwapButton}>
              <div style={styles.likesContainer}>
                <HeartIcon />
                <span style={styles.likesCount}>{totalRequests}</span>
              </div>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    )
  }
  if (props.shift) {
    return (
      <>
        <div role="button" onClick={props.onClickShift} style={props.onClickShift ? { cursor: 'pointer' } : {}}>
          {renderShiftDate(
            props.shift.schedule_date,
            props.shift.shift_length || props.shift.shift_hours || handleHours(props.shift),
          )}
          {renderDisplaySegment(props)}
        </div>
      </>
    )
  } else {
    return null
  }
}

export default DisplaySegmentShiftSwap
