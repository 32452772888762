/* * * * * * * * * * * * * * * *
If you want to handle both onClick and onDoubleClick events, you will probably need to control the onClick handler to not be triggered when the click event comes from a dblClick event. To start with,
a promise wrapper that can be cancelled (rejected with extra info to know the rejection happened because of a cancellation)
* * * * * * * * * * * * * * * * */

export const CancelablePromise = (promise) => {
  let isCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error }),
    )
  })

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  }
}
