import {
  GET_TIMECARD_DATA_WEEKLY_ERROR,
  GET_TIMECARD_DATA_WEEKLY_SUCCESS,
  GET_WEEKLY_SCHEDULE_ERROR,
  GET_WEEKLY_SCHEDULE_SUCCESS,
  SET_TIMECARD_WEEKLY_LOADING,
  SET_TIMECARD_WEEKLY_SELECTED_DATE,
  SET_TOTAL_SCHEDULED_HRS_WEEKLY_LOADING,
} from './actionType'
import axios from 'axios'
import { endOfWeek, format, startOfWeek } from 'date-fns'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function getTimecardDataWeekly(tmNumber, startDate, endDate, locationId) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.timecards.url}` +
      '/timecard_weekly_view' +
      '?end_date=' +
      endDate +
      '&location_id=' +
      locationId +
      '&start_date=' +
      startDate +
      '&worker_id=' +
      tmNumber +
      '&key=' +
      `${apiConfig.timecards.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleWeeklySuccess(response))
      })
      .catch((err) => {
        dispatch(handleWeeklyError(err))
      })
  }
}

export function handleWeeklySuccess(record) {
  return {
    type: GET_TIMECARD_DATA_WEEKLY_SUCCESS,
    record,
  }
}

export function handleWeeklyError(record) {
  return {
    type: GET_TIMECARD_DATA_WEEKLY_ERROR,
    record,
  }
}

export function setTimecardWeeklyLoading(text) {
  return {
    type: SET_TIMECARD_WEEKLY_LOADING,
    payload: text,
  }
}

export function setTimecardWeeklySelectedDate(date) {
  return {
    type: SET_TIMECARD_WEEKLY_SELECTED_DATE,
    date,
  }
}

export function handleGetWeeklyScheduleData(tmNumber, selectedDate, locationId) {
  const dateFormat = 'yyyy-MM-dd'
  let startDate = format(startOfWeek(selectedDate), dateFormat)
  let endDate = format(endOfWeek(selectedDate), dateFormat)

  return (dispatch) => {
    let apiUrl =
      `${apiConfig.schedule.url}` +
      '/weekly_schedules' +
      '?team_member_number=' +
      tmNumber +
      '&start_date=' +
      startDate +
      '&end_date=' +
      endDate +
      '&location_id=' +
      locationId +
      '&key=' +
      `${apiConfig.schedule.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetWeeklyScheduleSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetWeeklyScheduleError(response))
      })
  }
}

export function handleGetWeeklyScheduleSuccess(record) {
  return {
    type: GET_WEEKLY_SCHEDULE_SUCCESS,
    record,
  }
}

export function handleGetWeeklyScheduleError(record) {
  return {
    type: GET_WEEKLY_SCHEDULE_ERROR,
    record,
  }
}

export function setTotalScheduledHrsWeeklyLoading(text) {
  return {
    type: SET_TOTAL_SCHEDULED_HRS_WEEKLY_LOADING,
    payload: text,
  }
}
