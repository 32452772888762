import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { truncateString } from '../../utils/strings'

const DailyScheduleShiftSegments = (props) => {
  const inputTimeFormat = 'YYYY-MM-DDThh:mm:ss'
  const outputTimeFormat = 'hh:mmA'

  const startTime = moment(props.element.shift_segment_start, inputTimeFormat)?.format(outputTimeFormat)
  const endTime = moment(props.element.shift_segment_end, inputTimeFormat)?.format(outputTimeFormat)

  const segmentTimeRange = `${startTime} - ${endTime}`

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={props.styles.gridContainer}
        aria-label={`${props.element.shift_segment_hours} hour segment from ${segmentTimeRange}`}
      >
        <ListItem>
          <Grid container>
            <Grid item xs={9} md={9}>
              <Typography variant="body2" sx={props.styles.scheduleItemContainer} aria-hidden={true}>
                {segmentTimeRange}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography variant="body2" sx={props.styles.gridItemContainer} aria-hidden={true}>
                {props.element.shift_segment_hours} Hrs
              </Typography>
            </Grid>
            <Grid item xs={8} md={8} aria-label={'segment location and type'}>
              {props.element.shift_segment_type === undefined ? props.viewableLocationJob : props.viewableSegmentType}
            </Grid>
            {props.element.shift_segment_type?.toLowerCase() !== 'break' && props.element.shift_label && (
              <Grid item xs={4} md={4} sx={props.styles.gridItemContainer}>
                <Typography sx={props.styles.shiftLabelContainer}>
                  <Box component={'span'} sx={props.styles.shiftLabel}>
                    {truncateString(props.element.shift_label, 3)}
                  </Box>
                </Typography>
              </Grid>
            )}
          </Grid>
        </ListItem>
        <Divider aria-hidden={true} />
      </Grid>
    </>
  )
}

export default DailyScheduleShiftSegments
