export const SET_LEADER_VIEW_AUTOMATION_SELECTED_LOCATION_ID = 'SET_LEADER_VIEW_AUTOMATION_SELECTED_LOCATION_ID'
export const SET_LEADER_VIEW_AUTOMATION_SELECTED_DATE = 'SET_LEADER_VIEW_AUTOMATION_SELECTED_DATE'
export const SET_LEADER_VIEW_AUTOMATION_SCREEN_NAME = 'SET_LEADER_VIEW_AUTOMATION_SCREEN_NAME'
export const GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_SUCCESS =
  'GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_SUCCESS'
export const GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_ERROR = 'GET_LEADER_VIEW_AUTOMATION_LOCATIONS_REQUEST_ERROR'
export const SET_LEADER_AUTOMATION_EXIT_DIALOG_OPEN = 'SET_LEADER_AUTOMATION_EXIT_DIALOG_OPEN'
export const LEADER_AUTOMATION_SET_NEXT_LOCATION = 'LEADER_AUTOMATION_SET_NEXT_LOCATION'
export const LEADER_AUTOMATION_SET_NAVIGATE_TO_NEXT_LOCATION = 'LEADER_AUTOMATION_SET_NAVIGATE_TO_NEXT_LOCATION'
export const SET_LEADER_AUTOMATION_FOOTER_CHANGE_DIALOG_OPEN = 'SET_LEADER_AUTOMATION_FOOTER_CHANGE_DIALOG_OPEN'
export const LEADER_AUTOMATION_LOCATION_SUCCESS = 'LEADER_AUTOMATION_LOCATION_SUCCESS '
export const LEADER_AUTOMATION_LOCATION_ERROR = 'LEADER_AUTOMATION_LOCATION_ERROR'
export const LEADER_AUTOMATION_SET_LOCATION_LOADING = 'LEADER_AUTOMATION_SET_LOCATION_LOADING'
export const SET_LEADER_VIEW_AUTOMATION_UP_DOWN = 'SET_LEADER_VIEW_AUTOMATION_UP_DOWN'
export const SET_LEADER_VIEW_DESIRED_SCREEN_NAME = 'SET_LEADER_VIEW_DESIRED_SCREEN_NAME'
export const SET_LEADER_VIEW_DIALOG_CHANGED_PAGE = 'SET_LEADER_VIEW_DIALOG_CHANGED_PAGE'
export const SET_LEADER_VIEW_UNSAVED_CHANGES = 'SET_LEADER_VIEW_UNSAVED_CHANGES'
export const SET_LEADER_VIEW_DESIRED_SELECTED_DATE = 'SET_LEADER_VIEW_DESIRED_SELECTED_DATE'
export const SET_LEADER_VIEW_DESIRED_LOCATION_ID = 'SET_LEADER_VIEW_DESIRED_LOCATION_ID'
export const SET_LEADER_VIEW_POP_UP_TEXT_LOCATION_CHANGE = 'SET_LEADER_VIEW_POP_UP_TEXT_LOCATION_CHANGE'
export const SET_LEADER_VIEW_POP_UP_TEXT_EXIT = 'SET_LEADER_VIEW_POP_UP_TEXT_EXIT'
export const SET_LEADER_VIEW_POP_UP_TEXT_SCREEN_CHANGE = 'SET_LEADER_VIEW_POP_UP_TEXT_SCREEN_CHANGE'
export const SET_LEADER_VIEW_POP_UP_TEXT_DATE_CHANGE = 'SET_LEADER_VIEW_POP_UP_TEXT_DATE_CHANGE'
export const SET_LEADER_VIEW_DISPLAY_LOADING_ICON = 'SET_LEADER_VIEW_DISPLAY_LOADING_ICON'
export const SET_LEADER_VIEW_REFRESH_TABLE = 'SET_LEADER_VIEW_REFRESH_TABLE'
export const SET_LEADER_VIEW_AUTOMATION_PTO_REQUESTS_SELECTED_END_DATE =
  'SET_LEADER_VIEW_AUTOMATION_PTO_REQUESTS_SELECTED_END_DATE'
