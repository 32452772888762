import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useAvailabilityUpdateRequest } from '../../api/availability/useAvailabilityUpdateRequest'
import { REQUEST_STATUS_TYPES_AVAILABILITY, REQUEST_TYPES } from '../../constants/AvailabilityConstants'
import { useUser } from '../../auth/hooks/useUser'

const CANCEL_DIALOG_TITLE = 'Are you sure you want to cancel this request?'
const CANCEL_DIALOG_TEXT = 'Once cancelled, this request can no longer be updated.'

const AvailabilityCancelRequest = (props) => {
  const { request } = props
  const queryClient = useQueryClient()
  const { locationData } = useUser().user

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const { mutate, isLoading } = useAvailabilityUpdateRequest(REQUEST_TYPES.availability)

  const handleCancelRequestButton = () => {
    setCancelDialogOpen(true)
  }

  const handleDialogYesButton = () => {
    let requestDetails = []
    request.request_details.forEach((detail) => {
      requestDetails.push({
        request_detail_id: detail.availability_request_detail_id,
        status: REQUEST_STATUS_TYPES_AVAILABILITY.CANCELLED,
      })
    })

    const requestBody = {
      leader_id: request.created_by_worker_id,
      availability_id: request.request_id,
      location_id: locationData.location_id,
      //request_details: requestDetails,
      updated_by: request.created_by_worker_id,
      status: requestDetails[0].status,
      status_id: 4,
      comment: '',
      // comment: comment, // Currently, TMs aren't sending comments during cancelling
    }
    //In future when My Requests page has more than one request type, pass appropriate
    // request type (from props.request.request_type)
    mutate(requestBody, {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['requests'])
        setCancelDialogOpen(false)
      },
    })
  }

  const renderCancelDialog = () => {
    return (
      <Dialog
        data-cy="requestCancelDialog"
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{CANCEL_DIALOG_TITLE}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">{CANCEL_DIALOG_TEXT}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setCancelDialogOpen(false)
            }}
            color="primary"
            disabled={isLoading}
          >
            NO
          </Button>
          <Button onClick={handleDialogYesButton} color="primary" variant="contained" disabled={isLoading}>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleCancelRequestButton}
        data-cy={`cancelRequestButton_${request.request_id}`}
        color="primary"
        size="small"
      >
        Cancel Request
      </Button>
      {renderCancelDialog()}
    </React.Fragment>
  )
}

export default AvailabilityCancelRequest
