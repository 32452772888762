import { Autocomplete, TextField } from '@mui/material'
import { useUserGroups } from '../../../../api/userProfiles/useUserGroups'

export default function UserGroupDropdown({
  handleFormValueChange,
  handleInputValueChange,
  formValues,
  selectInputValues,
}) {
  const { data: userGroups, isFetching, isLoading } = useUserGroups()

  return (
    <Autocomplete
      name="audience_roles"
      fullWidth
      multiple
      disableCloseOnSelect
      id="audience_roles-select"
      size="small"
      loading={isFetching || isLoading}
      options={userGroups?.data?.map((option) => option.name) || []}
      getOptionLabel={(option) => option}
      value={formValues.audience_roles}
      onChange={(event, newValue) => {
        handleFormValueChange('audience_roles', newValue)
      }}
      inputValue={selectInputValues.audience_roles}
      onInputChange={(event, newInputValue) => {
        handleInputValueChange('audience_roles', newInputValue)
      }}
      limitTags={3}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label="Audience Roles" placeholder="Leave blank for all roles" />
      )}
    />
  )
}
