import React, { useState } from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PunchCorrectionCancelModal from './PunchCorrectionCancelModal'
import PunchCorrectionSubmitModal from './PunchCorrectionSubmitModal'
import { showNotificationError } from '../../../store/notification/actionCreator'
import { useDispatch } from 'react-redux'

const styles = {
  bottomButtons: {
    margin: '10px',
  },
  subTitle: {
    fontWeight: 600,
  },
  voluntarySelection: {
    marginTop: '20px',
  },
}

const PunchCorrectionSubmitCancel = (props) => {
  const { showCancelDialog, disableSubmit, setNavigateToDailyScreen, handleSubmitRequest, isFormReadyToSubmit } = props

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleCancel = () => {
    if (showCancelDialog) {
      setCancelDialogOpen(true)
    } else {
      setNavigateToDailyScreen()
    }
  }

  const handleYesForCancelAction = () => {
    setCancelDialogOpen(false)
    navigate(-1)
  }

  const handleNoForCancelAction = () => {
    setCancelDialogOpen(false)
  }

  const handleYesForSubmitAction = () => {
    setSubmitDialogOpen(false)
    handleSubmitRequest()
  }

  const handleNoForSubmitAction = () => {
    setSubmitDialogOpen(false)
  }

  const handleSubmit = () => {
    if (isFormReadyToSubmit.status && isFormReadyToSubmit.reason !== '') {
      setSubmitDialogOpen(true)
    } else {
      showAlertNotification()
    }
  }

  const showAlertNotification = () => {
    if (isFormReadyToSubmit.message !== '') {
      dispatch(showNotificationError(true, isFormReadyToSubmit.message))
    }
  }

  return (
    <React.Fragment>
      <Button data-cy="punchCorrectionCancel" sx={styles.bottomButtons} onClick={handleCancel} color="primary">
        Cancel
      </Button>
      <Button
        data-cy="punchCorrectionSubmit"
        sx={styles.bottomButtons}
        variant="contained"
        disabled={disableSubmit}
        color="primary"
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <PunchCorrectionCancelModal
        cancelDialogOpen={cancelDialogOpen}
        handleYesForCancelAction={handleYesForCancelAction}
        handleNoForCancelAction={handleNoForCancelAction}
      />
      <PunchCorrectionSubmitModal
        submitDialogOpen={submitDialogOpen}
        handleYesForSubmitAction={handleYesForSubmitAction}
        handleNoForSubmitAction={handleNoForSubmitAction}
        showAccrualMessage={true}
      />
    </React.Fragment>
  )
}

export default PunchCorrectionSubmitCancel
