import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'
import {
  GET_DAILY_SCHEDULE_ERROR,
  GET_DAILY_SCHEDULE_SUCCESS,
  GET_TIMECARD_DATA_DAILY_ERROR,
  GET_TIMECARD_DATA_DAILY_SUCCESS,
  SET_TIMECARD_DAILY_LOADING,
  SET_TIMECARD_DAILY_SELECTED_DATE,
  SET_TOTAL_SCHEDULED_HRS_DAILY_LOADING,
} from './actionType'

const initialState = {
  data: {},
  loading: 'N',
  error: null,
  dailySelectedDate: getDateOfTodayWithNoTimestamp(),
  totalScheduledHrs: null,
  totalScheduledHrsError: null,
  totalScheduledHrsLoading: 'N',
}

export default function timecardDaily(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TIMECARD_DATA_DAILY_SUCCESS: {
      const { data } = action
      return {
        ...state,
        data: data.data,
        loading: 'N',
        error: null,
      }
    }
    case GET_TIMECARD_DATA_DAILY_ERROR: {
      const { record } = action
      return {
        ...state,
        data: {},
        error: record?.response ? record.response.data : record,
        loading: 'N',
      }
    }
    case SET_TIMECARD_DAILY_LOADING: {
      const { payload } = action
      return {
        ...state,
        loading: payload,
      }
    }
    case SET_TIMECARD_DAILY_SELECTED_DATE: {
      const { date } = action
      return {
        ...state,
        dailySelectedDate: date,
      }
    }

    case GET_DAILY_SCHEDULE_SUCCESS: {
      const { record } = action
      return {
        ...state,
        totalScheduledHrs: record.data.total_hours,
        totalScheduledHrsLoading: 'N',
        totalScheduledHrsError: null,
      }
    }

    case GET_DAILY_SCHEDULE_ERROR: {
      const { record } = action
      return {
        ...state,
        totalScheduledHrs: '--.--',
        totalScheduledHrsError: record.response ? record.response.data : record,
        totalScheduledHrsLoading: 'N',
      }
    }

    case SET_TOTAL_SCHEDULED_HRS_DAILY_LOADING: {
      const { payload } = action
      return {
        ...state,
        totalScheduledHrsLoading: payload,
      }
    }
    default:
      return state
  }
}
