/* eslint-disable react-hooks/exhaustive-deps */
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Paper, useTheme } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { addWeeks, endOfWeek, isAfter, isBefore, startOfWeek } from 'date-fns'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AVAILABILITY_CALENDAR_MAX_FUTURE_WEEKS, FIXED_END_DATE_FROM_UKG } from '../../constants/AvailabilityConstants'
import {
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER_AVAILABIILITY,
  LOAD_AVAILABILITY_ERROR_MESSAGE,
  NO_AVAILABILITY_MESSAGE,
  NO_AVAILABILITY_SET_FOR_THIS_WEEK_RANGE,
} from '../../constants/errorConstants'
import {
  getUserAvailability,
  setAvailabilityWeeklyLoading,
  setAvailabilityWeeklySelectedDate,
} from '../../store/availability/actionCreator'
import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { formatErrorCode } from '../../utils/ErrorHandling'
import { buildWeeklyAvailabilityData } from '../../utils/buildWeeklyAvailabilityData'
import TimecardWeeklyCalendar from '../Calendar/TimecardWeeklyCalendar'
import HeaderTitle from '../Header/HeaderTitle'
import Loader from '../Loader/Loader'
import { AvailabilityRulesEngine } from './AvailabilityRulesEngine'
import WeeklyAvailability from './WeeklyAvailability'

const getStyles = (theme) => ({
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  messageAdditionalStyle: {
    width: '90%',
    margin: '20px auto',
  },
  mainScrollContainer: {
    ...theme.mainScrollContainer,
    overflowY: 'auto',
    height: '100%',
  },
  mainContainerPosition: theme.mainContainerPosition,
  infoBoxCls: {
    padding: '10px',
    margin: '7px 0 5px 0',
    backgroundColor: '#EBEBEB',
  },
  infoTextCls: {
    fontSize: '0.95rem',
  },
  dateColumn: {
    textAlign: 'right',
  },
  approvedAvailabilitiesCls: {
    height: 35,
    padding: '10px 10px 0 0',
    textAlign: 'right',
  },
  linkCls: {
    color: '#cc0000',
  },
  btnStyles: {
    cursor: 'pointer',
  },
  loader: theme.loadingIconContainer,
})

const ViewAvailability = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  let pageTitle = 'View Availability'

  const { session } = useAuth()
  const dispatch = useDispatch()
  const userAvailability = useSelector((state) => state.availability.userAvailability)
  const weeklySelectedDate = useSelector((state) => state.availability.weeklySelectedDate)
  const [weeklyAvailabilityData, setWeeklyAvailabilityData] = useState({})
  const minDate = startOfWeek(getDateOfTodayWithNoTimestamp())
  const maxDate = endOfWeek(addWeeks(getDateOfTodayWithNoTimestamp(), AVAILABILITY_CALENDAR_MAX_FUTURE_WEEKS - 1))
  const userAvailabilityLoading = useSelector((state) => state.availability.userAvailabilityLoading)
  const [showMsgScreen, setShowMsgScreen] = useState(true)
  const userAvailabilityError = useSelector((state) => state.availability.userAvailabilityError)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    dispatch(setAvailabilityWeeklyLoading('Y'))
    if (session?.userInfo && Object.keys(session?.userInfo).length > 0) {
      dispatch(getUserAvailability(padEmpIdWithZeros(session?.userInfo?.empid, 10)))
    }
  }, [session?.userInfo, dispatch])

  useEffect(() => {
    if (Object.keys(userAvailability).length > 0) {
      if (userAvailability?.availabilities.length > 0) {
        setShowMsgScreen(false)
        let userAvailabilities = cloneDeep([...userAvailability.availabilities])

        let linearAvailablities = AvailabilityRulesEngine.process(userAvailabilities)
        let weeklyAvailability = buildWeeklyAvailabilityData(
          startOfWeek(getDateOfTodayWithNoTimestamp()),
          linearAvailablities,
        )

        setWeeklyAvailabilityData(weeklyAvailability)
      } else {
        setShowMsgScreen(true)
        setMessage(displayNoAvailabilityMsg())
      }
    }
  }, [userAvailability])

  useEffect(() => {
    if (Object.keys(userAvailability).length > 0) {
      if (userAvailability?.availabilities.length > 0) {
        setShowMsgScreen(false)
        let userAvailabilities = cloneDeep([...userAvailability.availabilities])

        let linearAvailablities = AvailabilityRulesEngine.process(userAvailabilities)
        let weeklyAvailability = buildWeeklyAvailabilityData(weeklySelectedDate, linearAvailablities)

        setWeeklyAvailabilityData(weeklyAvailability)
      }
    }
  }, [weeklySelectedDate])

  useEffect(() => {
    if (userAvailabilityError && Object.keys(userAvailabilityError).length > 0) {
      setShowMsgScreen(true)
      if (
        userAvailabilityError.message &&
        userAvailabilityError.code &&
        ERROR_CODES_SHOW_MESSAGE.includes(userAvailabilityError.code)
      ) {
        setMessage(displayInfoMessage(userAvailabilityError.message))
      } else {
        setMessage(displayErrors(userAvailabilityError))
      }
    }
  }, [userAvailabilityError])

  const displayNoAvailabilityMsg = () => {
    return <p style={{ ...styles.infoMessage, ...styles.messageAdditionalStyle }}>{NO_AVAILABILITY_MESSAGE}</p>
  }

  const displayInfoMessage = (message) => {
    return <p style={{ ...styles.infoMessage, ...styles.messageAdditionalStyle }}>{message}</p>
  }

  const displayErrors = (errorObj) => {
    if (errorObj.message && errorObj.code && ERROR_CODES_SHOW_MESSAGE.includes(errorObj.code)) {
      return <p style={{ ...styles.errorMessage, ...styles.messageAdditionalStyle }}>{errorObj.message}</p>
    } else {
      return (
        <p style={{ ...styles.errorMessage, ...styles.messageAdditionalStyle }}>
          {LOAD_AVAILABILITY_ERROR_MESSAGE}
          <span style={styles.errorCodeMessage}>
            {formatErrorCode(errorObj, ERROR_CODE_CANNOT_CONNECT_TO_SERVER_AVAILABIILITY)}
          </span>
        </p>
      )
    }
  }

  const handleDateChange = (date) => {
    let inCurrentWeek = isSelectedDateInWeekOfPrevSelectedDate(date)

    if (!inCurrentWeek) {
      handleDifferentWeekDateChange(date)
    } else {
      dispatch(setAvailabilityWeeklySelectedDate(date))
    }
  }

  const handleDifferentWeekDateChange = (date) => {
    dispatch(setAvailabilityWeeklySelectedDate(date))
  }

  const isSelectedDateInWeekOfPrevSelectedDate = (date) => {
    return !(isBefore(date, startOfWeek(weeklySelectedDate)) || isAfter(date, endOfWeek(weeklySelectedDate)))
  }

  const InfoBox = (props) => {
    const { data } = props
    let endDate = data.endDate === FIXED_END_DATE_FROM_UKG ? 'FOREVER' : data.endDate
    return (
      <div style={styles.infoBoxCls}>
        <Grid tabIndex={0} container>
          <Grid item xs={6}>
            <Typography sx={styles.infoTextCls}>
              <b>Start Date: </b>
              {data.startDate}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={styles.dateColumn}>
            <Typography sx={styles.infoTextCls}>
              <b>End Date: </b>
              {endDate}
            </Typography>
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderComponent = () => {
    return (
      <>
        <div style={{ ...styles.mainContainerPosition, ...styles.shiftForeGround }}>
          <div style={styles.gradiantBG} />
          <Paper elevation={1} square sx={styles.mainScrollContainer}>
            {userAvailabilityLoading === 'Y' ? loader() : showMsgScreen ? renderMsgScreen() : renderWeeklyAvailablity()}
          </Paper>
        </div>
      </>
    )
  }

  const renderMsgScreen = () => {
    return message
  }

  const renderWeeklyAvailablity = () => {
    if (weeklyAvailabilityData.pattern.length > 0) {
      return (
        <>
          <InfoBox data={weeklyAvailabilityData} />
          <WeeklyAvailability pattern={weeklyAvailabilityData.pattern} statOfWeek={startOfWeek(weeklySelectedDate)} />
        </>
      )
    } else {
      return (
        <p style={{ ...styles.infoMessage, ...styles.messageAdditionalStyle }}>
          {NO_AVAILABILITY_SET_FOR_THIS_WEEK_RANGE}
        </p>
      )
    }
  }

  const viewCalendar = () => {
    return (
      <TimecardWeeklyCalendar
        id="availability-weekly-calendar"
        selectedDate={new Date(weeklySelectedDate)}
        onChange={(date) => handleDateChange(date)}
        minDate={minDate}
        maxDate={maxDate}
      />
    )
  }

  const loader = (size = 48) => {
    return (
      <div style={styles.loader}>
        <Loader size={size} />
      </div>
    )
  }

  return (
    <>
      <HeaderTitle title={pageTitle} />
      {viewCalendar()}
      {renderComponent()}
    </>
  )
}

export default ViewAvailability
