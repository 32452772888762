import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import {
  AVAILABILITY_FOR_SINGLE_WEEK,
  CLEAR_PREVIOUS_STATE,
  GET_USER_AVAILABILITY_ERROR,
  GET_USER_AVAILABILITY_SUCCESS,
  POST_AVAILABILITY_REQUESTS_DATA_ERROR,
  POST_AVAILABILITY_REQUESTS_DATA_SUCCESS,
  SET_AVAILABILITY_PATTERN_ID,
  SET_AVAILABILITY_REQUEST_END_DATE,
  SET_AVAILABILITY_REQUEST_START_DATE,
  SET_AVAILABILITY_REQUEST_TYPE,
  SET_AVAILABILITY_WEEKLY_LOADING,
  SET_AVAILABILITY_WEEKLY_SELECTED_DATE,
  UPDATE_AVAILABILITY_REQUESTS_DATA_ERROR,
  UPDATE_AVAILABILITY_REQUESTS_DATA_SUCCESS,
} from './actionTypes'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function getUserAvailability(tmNumber) {
  let apiUrl =
    apiConfig.storeAvailability.url +
    '/availabilities' +
    '?worker_id=' +
    tmNumber +
    '&key=' +
    `${apiConfig.storeAvailability.key}`

  return (dispatch) => {
    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetUserAvailabilitySuccess(response))
      })
      .catch((err) => {
        dispatch(handleGetUserAvailabilityError(err))
      })
  }
}

export function handleGetUserAvailabilitySuccess(data) {
  return {
    type: GET_USER_AVAILABILITY_SUCCESS,
    data,
  }
}

export function handleGetUserAvailabilityError(data) {
  return {
    type: GET_USER_AVAILABILITY_ERROR,
    data,
  }
}

//POST availability
export function postAvailabilityRequestsData(userAvailabilityData) {
  const requestBody = userAvailabilityData

  // if (!/\S/.test(comment)) {
  //   // comment is empty or only contains whitespace
  //   delete requestBody.comment
  // }

  return (dispatch) => {
    let apiUrl =
      `${apiConfig.storeAvailability.url}` + '/availabilities' + '?key=' + `${apiConfig.storeAvailability.key}`

    return axios
      .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handlePostAvailabilityRequestsSuccess(response))
      })
      .catch((err) => {
        dispatch(handlePostAvailabilityRequestsError(err))
      })
  }
}

function handlePostAvailabilityRequestsSuccess(data) {
  return {
    type: POST_AVAILABILITY_REQUESTS_DATA_SUCCESS,
    data,
  }
}

function handlePostAvailabilityRequestsError(data) {
  return {
    type: POST_AVAILABILITY_REQUESTS_DATA_ERROR,
    data,
  }
}

//UPDATE availability
export function updateAvailabilityRequestsData(userAvailabilityData) {
  const requestBody = userAvailabilityData

  // if (!/\S/.test(comment)) {
  //   // comment is empty or only contains whitespace
  //   delete requestBody.comment
  // }

  return (dispatch) => {
    let apiUrl =
      `${apiConfig.storeAvailability.url}` + '/update_statuses' + '?key=' + `${apiConfig.storeAvailability.key}`

    return axios
      .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleUpdateAvailabilityRequestsSuccess(response))
      })
      .catch((err) => {
        dispatch(handleUpdateAvailabilityRequestsError(err))
      })
  }
}

function handleUpdateAvailabilityRequestsSuccess(data) {
  return {
    type: UPDATE_AVAILABILITY_REQUESTS_DATA_SUCCESS,
    data,
  }
}

function handleUpdateAvailabilityRequestsError(data) {
  return {
    type: UPDATE_AVAILABILITY_REQUESTS_DATA_ERROR,
    data,
  }
}

export function setAvailabilityWeeklyLoading(text) {
  return {
    type: SET_AVAILABILITY_WEEKLY_LOADING,
    payload: text,
  }
}

export function setAvailabilityWeeklySelectedDate(date) {
  return {
    type: SET_AVAILABILITY_WEEKLY_SELECTED_DATE,
    date,
  }
}

export function setAvailabilityRequestStartDate(date) {
  return {
    type: SET_AVAILABILITY_REQUEST_START_DATE,
    date,
  }
}

export function setAvailabilityRequestEndDate(date) {
  return {
    type: SET_AVAILABILITY_REQUEST_END_DATE,
    date,
  }
}

export function setAvailabilityRequestType(reqType) {
  return {
    type: SET_AVAILABILITY_REQUEST_TYPE,
    reqType,
  }
}

export function setAvailabilityPatternId(patternId) {
  return {
    type: SET_AVAILABILITY_PATTERN_ID,
    patternId,
  }
}

export function clearPreviousState() {
  return {
    type: CLEAR_PREVIOUS_STATE,
  }
}

export function setAvailabilityForSingleWeek(boo) {
  return {
    type: AVAILABILITY_FOR_SINGLE_WEEK,
    boo,
  }
}
