import { convertAmPmTimeFormat, formatDateString } from '../../../../../utils/DateUtil'
import { PUNCH_CORRECTION } from '../../../../../constants/RequestConstants'
import { LEADER_TIMESTAMP_DETAIL_FORMAT } from '../../../../../constants/leaderConstants'
import PunchCorrectionDetailPage from '../../PunchCorrectionDetailPage'
import { isValid } from 'date-fns'
import { months } from '../../../../../constants/AvailabilityConstants'

const getLabelValueList = (data, dEnum, map) => {
  const getLabelValueObj = (label, value, voiceOverTxt) => {
    return { label: label, value: value, voiceOverTxt: voiceOverTxt }
  }

  const voiceOverDate = (val) => {
    if (isValid(val)) {
      let date = val.getDate().toString()
      let month = val.getMonth()
      let year = val.getFullYear()
      let time = convertAmPmTimeFormat(`${val.getHours()}:${val.getMinutes()}`)
      return `${months[month].substring(0, 3)} ${date}, ${year} ${time}`
    } else {
      return ''
    }
  }

  return [
    getLabelValueObj(map.get(dEnum.Status)?.label, map.get(dEnum.Status)?.value(data)),
    getLabelValueObj(map.get(dEnum.JobClassification)?.label, map.get(dEnum.JobClassification)?.value(data)),
    getLabelValueObj(
      map.get(dEnum.CreatedTimestamp)?.label,
      formatDateString(
        map.get(dEnum.CreatedTimestamp)?.value(data),
        map.get(dEnum.CreatedTimestamp)?.format,
        LEADER_TIMESTAMP_DETAIL_FORMAT,
      ),
      voiceOverDate(new Date(map.get(dEnum.CreatedTimestamp)?.value(data))),
    ),
    getLabelValueObj(map.get(dEnum.ReasonForCorrection)?.label, map.get(dEnum.ReasonForCorrection)?.value(data)),
    getLabelValueObj(map.get(dEnum.LastUpdatedBy)?.label, map.get(dEnum.LastUpdatedBy)?.value(data)),
    getLabelValueObj(
      map.get(dEnum.LastUpdatedTimestamp)?.label,
      formatDateString(
        map.get(dEnum.LastUpdatedTimestamp)?.value(data),
        map.get(dEnum.LastUpdatedTimestamp)?.format,
        LEADER_TIMESTAMP_DETAIL_FORMAT,
      ),
      voiceOverDate(new Date(map.get(dEnum.LastUpdatedTimestamp)?.value(data))),
    ),
  ]
}

const PunchCorrectionDetail = ({ data }) => {
  return (
    <PunchCorrectionDetailPage detailsData={data} category={PUNCH_CORRECTION} getLabelValueList={getLabelValueList} />
  )
}

export default PunchCorrectionDetail
