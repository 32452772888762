import { Grid } from '@mui/material'
import { addDays, isBefore, isValid } from 'date-fns'
import { useMemo, useState } from 'react'
import DatePicker from './DatePicker'

const setDateToMidnight = (date) => {
  date.setHours(0, 0, 0, 0)
  return date
}

const DateRangePicker = (props) => {
  const {
    minDate,
    maxDate,
    defaultStartDate,
    defaultEndDate,
    maxDaysInRange,
    onBlurMessage,
    handleOnChange,
    containerSx,
    textFieldSx,
    pickerJoinComponent,
  } = props

  const [startDate, setStartDate] = useState(isValid(defaultStartDate) ? setDateToMidnight(defaultStartDate) : null)
  const [endDate, setEndDate] = useState(isValid(defaultEndDate) ? setDateToMidnight(defaultEndDate) : null)
  const min = useMemo(() => (isValid(minDate) ? setDateToMidnight(minDate) : null), [minDate])
  const max = useMemo(() => (isValid(maxDate) ? setDateToMidnight(maxDate) : null), [maxDate])

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue)
    handleOnChange('startDate', newValue, { startDate: newValue, endDate: null })
    handleEndDateChange(null)
  }

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue)
    handleOnChange('endDate', newValue, { startDate: startDate, endDate: newValue })
  }

  const getMaxEndDate = (startDate, maxDate, maxDaysInRange) => {
    if (isValid(startDate)) {
      if (isValid(maxDate) && maxDaysInRange) {
        return isBefore(addDays(startDate, maxDaysInRange - 1), maxDate)
          ? addDays(startDate, maxDaysInRange - 1)
          : maxDate
      } else if (maxDate) {
        return maxDate
      } else if (maxDaysInRange) {
        return addDays(startDate, maxDaysInRange - 1)
      }
    }
  }

  const renderPickerJoinComponent = () => {
    return <Grid item>{pickerJoinComponent}</Grid>
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        sx={containerSx}
        aria-label="Select date range"
        wrap="nowrap"
      >
        <Grid item>
          <DatePicker
            name="startDate"
            label="Start Date"
            defaultDate={startDate}
            handleDateChange={handleStartDateChange}
            minDate={min}
            maxDate={max}
            onBlurMessage={onBlurMessage}
            textFieldSx={textFieldSx}
          />
        </Grid>
        {pickerJoinComponent && renderPickerJoinComponent()}
        <Grid item>
          <DatePicker
            key={startDate}
            name="endDate"
            label="End Date"
            defaultDate={endDate}
            defaultCalendarMonth={startDate}
            handleDateChange={handleEndDateChange}
            minDate={startDate}
            maxDate={getMaxEndDate(startDate, max, maxDaysInRange)}
            disabled={!startDate}
            onBlurMessage={onBlurMessage}
            textFieldSx={textFieldSx}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DateRangePicker
