import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MuiDialog,
  TextField,
} from '@mui/material'
import { useRef } from 'react'
import { locationIsValid } from '../../../../utils/strings'

export default function BulkAddDialog(props) {
  const bulkAddValueRef = useRef()

  return (
    <MuiDialog
      open={props.openBulkAddDialog}
      onClose={props.handleBulkDialogNo}
      aria-labelledby="bulk-add-title"
      aria-describedby="bulk-add-description"
    >
      <DialogTitle id="bulk-add-title">Bulk Add Locations</DialogTitle>
      <DialogContent>
        <DialogContentText id="bulk-add-description">
          Please list locations with comma's to separate them.
        </DialogContentText>
        <TextField
          id="bulk-add-text-input"
          label="Locations"
          name="bulk values"
          variant="outlined"
          multiline
          fullWidth
          maxRows={10}
          inputRef={bulkAddValueRef}
          InputLabelProps={{ sx: { paddingTop: '10px' } }}
          sx={{ overflow: 'scroll', paddingTop: '10px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleBulkDialogNo} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            let { isValid, value } = isValidLocationsStringInput(bulkAddValueRef.current.value)
            if (isValid) {
              props.handleBulkDialogYes('audience_locations', value)
            }
          }}
          color="primary"
          autoFocus
        >
          Add
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

export function isValidLocationsStringInput(value) {
  let bulkValues = []
  if (value) {
    bulkValues = value.split(',')
    let invalidValues = []
    bulkValues.forEach((location, index) => {
      bulkValues[index] = bulkValues[index].trim()
      if (!locationIsValid(bulkValues[index])) {
        invalidValues.push(bulkValues[index])
      }
      bulkValues[index] = bulkValues[index].replace('T', '')
      bulkValues[index] = bulkValues[index].replace('t', '')
      bulkValues[index] = bulkValues[index].padStart(4, '0')
    })
    if (invalidValues.length > 0) {
      window.alert(`Input is invalid. ${invalidValues.join(', ')}`)
      return { isValid: false, value: invalidValues }
    }
    return { isValid: true, value: bulkValues }
  }
  return { isValid: false, value: bulkValues }
}
