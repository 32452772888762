import HomeIcon from '@mui/icons-material/Home'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Fab, useTheme } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { LogLevel, logEvent } from '@praxis/component-logging'
import { useLocation, useNavigate } from 'react-router-dom'
import { IFeature } from '../../../api/userAccess/responses/userAccess'
import { UnauthorizedCausedByEnum } from '../../../auth/User'
import HeaderTitle from '../../Header/HeaderTitle'

const NO_ACCESS_MESSAGE =
  'Your role does not have access to this screen. If you think this is a mistake, please see your HR or contact the Client Support Center at (612) 304-4357.'
const MORE_DETAILS = 'Note: If you have recently come back from LOA, it may take up to 24 hours to restore your access.'

const NO_ACCESS_DETAILS_NOT_PUNCHED_IN =
  'You must be clocked in to view this page. Please allow up to 3 minutes after clocking in for it to be reflected here. If you are still having issues, or you think this is a mistake, please see your HR or contact the Client Support Center at (612) 304-4357'

const NO_ACCESS_DETAILS_PUNCHED_IN =
  'You must not be clocked in to view this page. Please allow up to 3 minutes after clocking out for it to be reflected here. If you are still having issues, or you think this is a mistake, please see your HR or contact the Client Support Center at (612) 304-4357'

const getStyles = (theme: any) => ({
  mainContainerPosition: theme.mainContainerPosition,
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: '600px',
    padding: '20px 30px',
    textAlign: 'center',
  },
})

interface NotAuthorizedConfig {
  /**
   * Main title message of screen
   * @defaultValue You are not authorized to access this screen
   */
  titleFn: (screenName?: string) => string

  /**
   * More details for no auth
   * @defaultValue Your role does not have access to this screen. If you think this is a mistake, please see your HR or contact the Client Support Center at (612) 304-4357.
   */
  message?: string

  /** Any extra details
   * @defaultValue Note: If you have recently come back from LOA, it may take up to 24 hours to restore your access.
   */
  moreDetails?: string
}

type NotAuthorizedConfigType = {
  [key in UnauthorizedCausedByEnum]: NotAuthorizedConfig
}

const notAuthorizedConfig: NotAuthorizedConfigType = {
  [UnauthorizedCausedByEnum.FEATURE]: {
    titleFn: (screenName?: string) => `You are not authorized to access ${screenName ? screenName : 'this screen'} `,
    message: NO_ACCESS_MESSAGE,
    moreDetails: MORE_DETAILS,
  },
  [UnauthorizedCausedByEnum.PUNCHIN]: {
    titleFn: (screenName?: string) => `${screenName ? screenName : 'This screen'} requires you to be clocked in.`,
    message: NO_ACCESS_DETAILS_NOT_PUNCHED_IN,
  },
  [UnauthorizedCausedByEnum.PUNCHOUT]: {
    titleFn: (screenName?: string) =>
      `${screenName ? screenName : 'This screen'} is to be used outside of working hours.`,
    message: NO_ACCESS_DETAILS_PUNCHED_IN,
  },
}

export interface NotAuthorizedProps {
  causedByFeature?: IFeature

  causedBy?: UnauthorizedCausedByEnum
  /**
   * Show Fab to go home
   * @defaultValue true
   */
  showHome?: boolean

  /**
   * Show Fab to retry previous page load
   * @defaultValue true
   */
  showRetry?: boolean
}

const NotAuthorized = ({
  causedByFeature,
  causedBy = UnauthorizedCausedByEnum.FEATURE,
  showHome = true,
  showRetry = false,
}: NotAuthorizedProps) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const navigate = useNavigate()
  const { state } = useLocation()

  let retryValue = window.location.pathname === '/unauthorized' ? -1 : 0

  // if passing state through react router nav options: navigate(to, options)
  if (state && state?.causedBy) {
    causedBy = state.causedBy
    causedByFeature = state.causedByFeature
    showRetry = state?.showRetry
  }

  const noAuthConfig = notAuthorizedConfig[causedBy ? causedBy : UnauthorizedCausedByEnum.FEATURE]

  const { session } = useAuth()

  const loggingInfo = {
    message: `Unauthorized screen reached: Cause - ${causedBy ? causedBy : UnauthorizedCausedByEnum.FEATURE}`,
    url: window.location.href,
    error: {
      worker_id: session?.userInfo?.empid,
      location_id: session?.userInfo?.locationid,
    },
  }
  const optionalLoggingOptions = {
    level: LogLevel.Warn,
  }
  logEvent(loggingInfo, optionalLoggingOptions)

  return (
    <div style={styles.mainContainerPosition}>
      <HeaderTitle title={causedByFeature?.display_name || ''} />
      <Box sx={styles.content}>
        <p style={styles.infoMessage}>{noAuthConfig.titleFn(causedByFeature?.display_name)}</p>
        <p style={styles.infoMessageDetail}>{noAuthConfig.message}</p>
        {showRetry && (
          <Fab
            onClick={() => navigate(retryValue)}
            title={'Reload'}
            variant="extended"
            aria-label={'Reload'}
            color="primary"
          >
            <RefreshIcon />
            Try Again
          </Fab>
        )}
        &nbsp;
        {showHome && (
          <Fab
            onClick={() => navigate('/', { replace: true })}
            variant="extended"
            title={'Home'}
            aria-label={'Home'}
            color="primary"
          >
            <HomeIcon />
            Home
          </Fab>
        )}
        <p style={styles.infoMessageDetail}>{noAuthConfig.moreDetails}</p>
      </Box>
    </div>
  )
}

export default NotAuthorized
