const PostShiftIcon = (props) => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Post Shift</title>
      <desc>Created with Sketch.</desc>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="remove_circle-24px">
          <polygon id="Path" points="0 0 25 0 25 25 0 25"></polygon>
          <path
            d="M12.5,0 C5.6,0 0,5.6 0,12.5 C0,19.4 5.6,25 12.5,25 C19.4,25 25,19.4 25,12.5 C25,5.6 19.4,0 12.5,0 Z M18.75,13.75 L6.25,13.75 L6.25,11.25 L18.75,11.25 L18.75,13.75 Z"
            id="Shape"
            fill="#CC0202"
            fillRule="nonzero"
          ></path>
          <polygon
            id="Rectangle"
            fill="#FFFFFF"
            fillRule="nonzero"
            points="6.25 11.25 18.75 11.25 18.75 13.75 6.25 13.75"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}
export default PostShiftIcon
