import { Grid, ListItem, Typography } from '@mui/material'
import List from '@mui/material/List'

const CategorySegmentDetailRowItem = ({ label, value }) => {
  return (
    <>
      <Grid item xs={2}>
        <Typography sx={{ display: 'block', fontSize: '.75rem' }} variant="body2" color="tertiary.contrastText">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ display: 'block', fontSize: '.75rem' }} component="span" variant="body2">
          {value}
        </Typography>
      </Grid>
    </>
  )
}

const CategorySegmentDetailRow = ({ dataRow, rowIndex }) => {
  return (
    <ListItem
      sx={{ paddingY: '2px', paddingX: '8px', marginBottom: '4px', borderStyle: 'solid', borderColor: '#E0E0E0' }}
    >
      <Grid container>
        <Grid item xs={1}>
          <Typography sx={{ display: 'block' }} component="span" variant="h6">
            {rowIndex}
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Grid container>
            {dataRow?.map((dataItem, i) => {
              return <CategorySegmentDetailRowItem label={dataItem?.label} value={dataItem?.value} key={i} />
            })}
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  )
}

const CategorySegmentDetailList = ({ dataList }) => {
  return (
    <List component="h3" aria-label={'Category details segment detail list'}>
      {dataList?.map((dataRow, i) => {
        return <CategorySegmentDetailRow dataRow={dataRow} rowIndex={i + 1} key={i} />
      })}
    </List>
  )
}

export default CategorySegmentDetailList
