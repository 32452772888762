import {
  DAILY_SCHEDULE_GET_ERROR,
  DAILY_SCHEDULE_GET_SUCCESS,
  SET_DAILY_SCHEDULE_LOADING,
  SET_DAILY_SCHEDULE_SELECTED_DATE,
} from './actionType'

export const initialState = {
  data: null,
  loading: 'N',
  selectedDate: null,
  getError: null,
}

export default function dailySchedule(state = initialState, action = {}) {
  switch (action.type) {
    case DAILY_SCHEDULE_GET_ERROR: {
      const { record } = action
      return {
        ...state,
        getError: record.response ? record.response.data : record,
        loading: 'N',
      }
    }
    case DAILY_SCHEDULE_GET_SUCCESS: {
      const { record } = action
      return {
        ...state,
        data: record.data,
        loading: 'N',
        getError: null,
      }
    }
    case SET_DAILY_SCHEDULE_LOADING: {
      const { payload } = action
      return {
        ...state,
        loading: payload,
      }
    }
    case SET_DAILY_SCHEDULE_SELECTED_DATE: {
      const { date } = action
      return {
        ...state,
        selectedDate: date,
      }
    }
    default:
      return state
  }
}
