import {
  OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_ERROR,
  OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_SUCCESS,
  OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_ERROR,
  OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_SUCCESS,
  OPTIONAL_AVAILABILITY_SET_CANCEL_DIALOG_OPEN,
  OPTIONAL_AVAILABILITY_SET_DIFFERENT_WEEK_DIALOG_OPEN,
  OPTIONAL_AVAILABILITY_SET_EXIT_DIALOG_OPEN,
  OPTIONAL_AVAILABILITY_SET_EXPAND_COLLAPSE,
  OPTIONAL_AVAILABILITY_SET_NAVIGATE_TO_NEXT_LOCATION,
  OPTIONAL_AVAILABILITY_SET_NEXT_LOCATION,
  OPTIONAL_AVAILABILITY_SET_POST_RESPONSE,
  OPTIONAL_AVAILABILITY_SET_SAVE_DIALOG_OPEN,
  OPTIONAL_AVAILABILITY_SET_SESSION_DATA,
  OPTIONAL_AVAILABILITY_SET_TM_LOADING,
  OPTIONAL_AVAILABILITY_SET_UPDATED_SESSION_DATA,
  SET_PERSISTED_DATA,
  SET_TM_AVAILABILITY_POST_ERROR,
  SET_TM_AVAILABILITY_SELECTED_DATE,
} from './actionType'
import { endOfWeek, format, startOfWeek } from 'date-fns'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function setPersistedData(data) {
  return {
    type: SET_PERSISTED_DATA,
    payload: data,
  }
}

export function setExpand(panel) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_EXPAND_COLLAPSE,
    payload: panel,
  }
}

export function setSessionData(sessionData) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_SESSION_DATA,
    payload: sessionData,
  }
}

export function setUpdatedSessionData(updatedData) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_UPDATED_SESSION_DATA,
    payload: updatedData,
  }
}

export function loadGetRequest(tmNumber, selectedDate, locationId) {
  const dateFormat = 'yyyy-MM-dd'
  let startDate = format(startOfWeek(selectedDate), dateFormat)
  let endDate = format(endOfWeek(selectedDate), dateFormat)

  return (dispatch) => {
    let apiUrl =
      `${apiConfig.availability.url}` +
      '/voluntary_availability_preferences' +
      '?worker_id=' +
      tmNumber +
      '&location_id=' +
      locationId +
      '&start_date=' +
      startDate +
      '&end_date=' +
      endDate +
      '&key=' +
      `${apiConfig.availability.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetRequestSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetRequestError(response))
      })
  }
}

export function loadPostRequest(requestBody) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.availability.url}` +
      '/voluntary_availability_preferences' +
      '?key=' +
      `${apiConfig.availability.key}`

    return axios
      .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handlePostRequestSuccess(response))
      })
      .catch((response) => {
        dispatch(handlePostRequestError(response))
      })
  }
}

export function handleGetRequestError(record) {
  return {
    type: OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_ERROR,
    record,
  }
}

export function handleGetRequestSuccess(record) {
  return {
    type: OPTIONAL_AVAILABILITY_HANDLE_GET_REQUEST_SUCCESS,
    record,
  }
}

export function handlePostRequestError(record) {
  return {
    type: OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_ERROR,
    record,
  }
}

export function handlePostRequestSuccess(record) {
  return {
    type: OPTIONAL_AVAILABILITY_HANDLE_POST_REQUEST_SUCCESS,
    record,
  }
}

export function setPostResponse(response) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_POST_RESPONSE,
    payload: response,
  }
}

export function setLoading(text) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_TM_LOADING,
    payload: text,
  }
}

export function setSaveDialogOpen(isOpen) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_SAVE_DIALOG_OPEN,
    payload: isOpen,
  }
}

export function setCancelDialogOpen(isOpen) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_CANCEL_DIALOG_OPEN,
    payload: isOpen,
  }
}

export function setExitDialogOpen(isOpen) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_EXIT_DIALOG_OPEN,
    payload: isOpen,
  }
}

export function setDifferentWeekDialogOpen(isOpen) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_DIFFERENT_WEEK_DIALOG_OPEN,
    payload: isOpen,
  }
}

export function setNextLocation(location) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_NEXT_LOCATION,
    payload: location,
  }
}

export function setNavigateToNextLocation(navigate) {
  return {
    type: OPTIONAL_AVAILABILITY_SET_NAVIGATE_TO_NEXT_LOCATION,
    payload: navigate,
  }
}

export function setSelectedDate(date) {
  return {
    type: SET_TM_AVAILABILITY_SELECTED_DATE,
    date,
  }
}

export function setPostError(error) {
  return {
    type: SET_TM_AVAILABILITY_POST_ERROR,
    error,
  }
}
