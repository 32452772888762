import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MuiDialog,
} from '@mui/material'
import useConfirm from '../../../hooks/useConfirm'

export default function ConfirmDialog() {
  const { prompt = '', isOpen = false, proceed, cancel, title, confirmText, rejectText } = useConfirm()

  return (
    <MuiDialog
      open={isOpen}
      onClose={cancel}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{title || 'Are you sure?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">{prompt}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary">
          {rejectText || 'No'}
        </Button>
        <Button onClick={proceed} color="primary" variant="contained" autoFocus>
          {confirmText || 'Yes'}
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}
