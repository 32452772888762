import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import {
  AUTOMATION_MANDATORY_AVAILABILITY_SET_POST_RESPONSE,
  AUTOMATION_MANDATORY_SET_SAVE_DIALOG_OPEN,
  AUTOMATION_MANDATORY_SHOULD_LOAD_DATA,
  DOWNLOAD_MANDATORY_CSV_ERROR,
  DOWNLOAD_MANDATORY_CSV_SUCCESS,
  GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_ERROR,
  GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_SUCCESS,
  GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_ERROR,
  GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS,
  MANDATORY_LEADER_AUTOMATION_DECISION_ERROR,
  MANDATORY_LEADER_AUTOMATION_DECISION_SUCCESS,
  MANDATORY_LEADER_AUTOMATION_SET_END_TIME,
  MANDATORY_LEADER_AUTOMATION_SET_IS_INPUT_TYPE_TIME,
  MANDATORY_LEADER_AUTOMATION_SET_SHIFT_PREFERENCE,
  MANDATORY_LEADER_AUTOMATION_SET_START_TIME,
  SET_AUTOMATION_MANDATORY_DATA_COLUMN_VIEW,
  SET_AUTOMATION_MANDATORY_DATA_LOADING,
  SET_AUTOMATION_MANDATORY_DATA_PAGE,
  SET_AUTOMATION_MANDATORY_DATA_PER_PAGE,
  SET_AUTOMATION_MANDATORY_DATA_SAVING,
  SET_AUTOMATION_MANDATORY_DATA_SEARCH,
  SET_AUTOMATION_MANDATORY_DATA_SELECTED_FILTERS,
  SET_AUTOMATION_MANDATORY_DATA_SORT,
  SET_AUTOMATION_MANDATORY_DATA_TABLE_COLUMNS_TO_DEFAULT,
  SET_AUTOMATION_MANDATORY_DATA_TABLE_FILTERS_TO_DEFAULT,
  SET_AUTOMATION_MANDATORY_DATA_TABLE_STATE_TO_DEFAULT,
  SET_AUTOMATION_MANDATORY_POST_RESPONSE_INELIGIBILITIES,
} from './actionType'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function setMandatoryDataTableStateToDefault() {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_TABLE_STATE_TO_DEFAULT,
  }
}

export function setMandatoryDataTableFiltersToDefault() {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_TABLE_FILTERS_TO_DEFAULT,
  }
}

export function setMandatoryDataTableColumnsToDefault() {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_TABLE_COLUMNS_TO_DEFAULT,
  }
}

export function loadGetLeaderMandatoryAvailabilityFiltersRequest(upDownState, locationId) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.configuration.url}` +
      '/team_member_enablement_filters' +
      '?location_id=' +
      locationId +
      '&location_type=DC' +
      '&feature=mandatory_' +
      upDownState +
      '&key=' +
      `${apiConfig.configuration.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetLeaderMandatoryAvailabilityFiltersRequestSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetLeaderMandatoryAvailabilityFiltersRequestError(response))
      })
  }
}

function handleGetLeaderMandatoryAvailabilityFiltersRequestSuccess(record) {
  return {
    type: GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_SUCCESS,
    record,
  }
}

function handleGetLeaderMandatoryAvailabilityFiltersRequestError(record) {
  return {
    type: GET_LEADER_AUTOMATION_MANDATORY_AVAILABILITY_FILTERS_REQUEST_ERROR,
    record,
  }
}

export function setMandatoryDataLoading(loading, lastSearchRequest, lastSelectedFilters) {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_LOADING,
    payload: loading,
    search: lastSearchRequest,
    selectedFilters: lastSelectedFilters,
  }
}

export function setMandatoryDataSaving(isSaving) {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_SAVING,
    isSaving: isSaving,
  }
}

export function setMandatoryDataSearch(search) {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_SEARCH,
    payload: search,
  }
}

export function setMandatoryDataSort(sort, sortByColumn) {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_SORT,
    payload: sort,
    column: sortByColumn,
  }
}

export function setMandatoryDataPage(page) {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_PAGE,
    payload: page,
  }
}

export function setMandatoryDataPerPage(perPage) {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_PER_PAGE,
    payload: perPage,
  }
}

export function setMandatoryDataSelectedFilters(filters) {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_SELECTED_FILTERS,
    payload: filters,
  }
}

export function setMandatoryColumnView(column, columnAction) {
  return {
    type: SET_AUTOMATION_MANDATORY_DATA_COLUMN_VIEW,
    column: column,
    columnAction: columnAction,
  }
}

export function loadGetWorkersMandatoryAvailabilityPreferencesRequest(queryParams) {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {}, // this is an issue with axios. empty data needed so Content-Type isn't removed from request
  }

  return (dispatch) => {
    let apiUrl = `${apiConfig.mandatory.url}` + '/mandatory_leader_automated_scheduling_decisions' + queryParams

    return axios
      .get(apiUrl, requestConfig)
      .then((response) => {
        dispatch(handleGetWorkersMandatoryAvailabilityPreferencesRequestSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetWorkersMandatoryAvailabilityPreferencesRequestError(response))
      })
  }
}

export function handleGetWorkersMandatoryAvailabilityPreferencesRequestError(record) {
  return {
    type: GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_ERROR,
    record,
  }
}

export function handleGetWorkersMandatoryAvailabilityPreferencesRequestSuccess(record) {
  return {
    type: GET_WORKERS_AUTOMATION_MANDATORY_AVAILABILITY_PREFERENCES_REQUEST_SUCCESS,
    record,
  }
}

export function setIsInputTypeTime(isTime) {
  return {
    type: MANDATORY_LEADER_AUTOMATION_SET_IS_INPUT_TYPE_TIME,
    payload: isTime,
  }
}

export function setShiftPreference(shiftPreference, upDownState) {
  let upShiftType
  let downShiftType
  if (upDownState === 'up') {
    upShiftType = shiftPreference
    downShiftType = null
  } else {
    upShiftType = null
    downShiftType = shiftPreference
  }
  return {
    type: MANDATORY_LEADER_AUTOMATION_SET_SHIFT_PREFERENCE,
    upShiftType: upShiftType,
    downShiftType: downShiftType,
  }
}

export function setStartTime(startTime) {
  return {
    type: MANDATORY_LEADER_AUTOMATION_SET_START_TIME,
    payload: startTime,
  }
}

export function setEndTime(endTime) {
  return {
    type: MANDATORY_LEADER_AUTOMATION_SET_END_TIME,
    payload: endTime,
  }
}

export function downloadMandatoryCSV(queryParams) {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'text/csv',
    },
    data: {}, // this is an issue with axios. empty data needed so Content-Type isn't removed from request
  }

  return (dispatch) => {
    let apiUrl =
      `${apiConfig.mandatory.url}` +
      '/mandatory_leader_scheduling_decisions' +
      queryParams +
      '&key=' +
      apiConfig.mandatory.key

    return axios
      .get(apiUrl, requestConfig)
      .then((response) => {
        return dispatch(handleMandatoryCSVSuccess(response))
      })
      .catch((response) => {
        return dispatch(handleMandatoryCSVError(response))
      })
  }
}

function handleMandatoryCSVSuccess(record) {
  return {
    type: DOWNLOAD_MANDATORY_CSV_SUCCESS,
    record,
  }
}

function handleMandatoryCSVError(record) {
  return {
    type: DOWNLOAD_MANDATORY_CSV_ERROR,
    record,
  }
}

export function updateMandatoryLeaderDecisions(body) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.mandatory.url}` +
      '/mandatory_leader_automated_scheduling_decisions?key=' +
      `${apiConfig.mandatory.key}`

    return axios
      .post(apiUrl, body, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleUpdateMandatoryLeaderDecisionsSuccess(response))
      })
      .catch((response) => {
        dispatch(handleUpdateMandatoryLeaderDecisionsError(response))
      })
  }
}

export function handleUpdateMandatoryLeaderDecisionsSuccess(record) {
  return {
    type: MANDATORY_LEADER_AUTOMATION_DECISION_SUCCESS,
    record,
  }
}

export function handleUpdateMandatoryLeaderDecisionsError(record) {
  return {
    type: MANDATORY_LEADER_AUTOMATION_DECISION_ERROR,
    record,
  }
}

export function setMandatoryPostResponseIneligibilities(ineligibilities) {
  return {
    type: SET_AUTOMATION_MANDATORY_POST_RESPONSE_INELIGIBILITIES,
    ineligibilities: ineligibilities,
  }
}

export function setMandatoryPostResponse(response) {
  return {
    type: AUTOMATION_MANDATORY_AVAILABILITY_SET_POST_RESPONSE,
    payload: response,
  }
}

export function setShouldLoadMandatoryData(shouldLoad) {
  return {
    type: AUTOMATION_MANDATORY_SHOULD_LOAD_DATA,
    shouldLoad,
  }
}

export function setSaveMandatoryDialogOpen(isOpen) {
  return {
    type: AUTOMATION_MANDATORY_SET_SAVE_DIALOG_OPEN,
    payload: isOpen,
  }
}
