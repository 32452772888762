import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Button, ListItemButton, ListItemText, Skeleton } from '@mui/material'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { TimeOffRequestsQueryParams, useTimeOffRequests } from '../../../api/requests/useTimeOffRequests'
import { targetGrey } from '../../../config/themeConfig'

const getSelectedSortBy = (sortableColumns, index) => {
  if (sortableColumns === undefined || index === undefined) return null
  return sortableColumns[index]?.columnName
}
const TotalTimeOffRequestsByStatusButton = ({
  status,
  startDate,
  endDate,
  handleButtonClick,
  buttonMessage,
  selectedCategory,
  categoryName,
}) => {
  const TIME_OFF_ERROR_MESSAGE = `Error loading ${status.toLowerCase()} requests`

  const selectedLocation = useSelector((state) => state.leader.selectedLocation)
  const selectedCategoryState = useSelector((state) => state.leader[categoryName])

  let timeOffRequestsQueryParams = new TimeOffRequestsQueryParams(
    selectedLocation,
    format(startDate, 'yyyy-MM-dd'),
    format(endDate, 'yyyy-MM-dd'),
    '',
    getSelectedSortBy(selectedCategoryState?.sortableColumns, selectedCategoryState?.selectedSortByIndex),
    selectedCategoryState?.sortOrder,
    status ? [{ key: 'statuses', value: status }] : null,
    false, //TODO: Is filter_required supposed to be true for stores? Is this boolean still needed by the API?
    selectedCategoryState?.rowsPerPage,
    1,
    selectedCategory,
  )

  const { data: response, error } = useTimeOffRequests(timeOffRequestsQueryParams)

  const renderButton = () => {
    return (
      <ListItemButton data-cy={`TotalRequestsByStatusButton_${status + selectedCategory}`} onClick={handleButtonClick}>
        <ListItemText> {response.headers['x-total-count'] + ' ' + buttonMessage}</ListItemText>
        <NavigateNextIcon fontSize="medium" style={{ color: targetGrey.dark }} />
      </ListItemButton>
    )
  }

  const renderErrorMessage = () => {
    return (
      <Button
        data-cy={`TotalRequestsByStatusButton_Error_${selectedCategory}`}
        color="primary"
        variant="outlined"
        size="small"
        disabled={true}
      >
        {TIME_OFF_ERROR_MESSAGE}
      </Button>
    )
  }

  const renderSkeleton = () => {
    return <Skeleton height={31} />
  }

  const renderContent = () => {
    if (response && response?.headers['x-total-count']) return renderButton()
    if (error) return renderErrorMessage()
    return renderSkeleton()
  }

  return <>{renderContent()}</>
}

export default TotalTimeOffRequestsByStatusButton
