export const POST_REQUESTS_DATA_SUCCESS = 'POST_REQUESTS_DATA_SUCCESS'
export const POST_REQUESTS_DATA_ERROR = 'POST_REQUESTS_DATA_ERROR'
export const CLEAR_PREVIOUS_STATE = 'CLEAR_PREVIOUS_STATE'
export const UPDATE_REQUEST_DATE_RANGE = 'UPDATE_REQUEST_DATE_RANGE'
export const CHANGE_UNPAID_VALUE = 'CHANGE_UNPAID_VALUE'
export const ADD_DETAIL = 'ADD_DETAIL'
export const REMOVE_DETAIL = 'REMOVE_DETAIL'
export const TOGGLE_ALL_DAY_UNPAID = 'TOGGLE_ALL_DAY_UNPAID'
export const TOGGLE_USE_PTO = 'TOGGLE_USE_PTO'
export const SET_COMMENT = 'SET_COMMENT'
export const INITIALIZE_REQUEST_CONFIG = 'INITIALIZE_REQUEST_CONFIG'
export const CLEAR_REQUEST = 'CLEAR_REQUEST'
export const SET_USE_PTO_DETAILS = 'SET_USE_PTO_DETAILS'
export const CHANGE_PTO_VALUE = 'CHANGE_PTO_VALUE'
export const SET_DAY_ERROR = 'SET_DAY_ERROR'
export const SET_COMMENT_ERROR = 'SET_COMMENT_ERROR'
export const SET_REQUEST_HAS_ERRORS = 'SET_REQUEST_HAS_ERRORS'
