import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import CachedIcon from '@mui/icons-material/Cached'
import CancelIcon from '@mui/icons-material/Cancel'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Error from '@mui/icons-material/Error'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import HourglassFull from '@mui/icons-material/HourglassFull'
import PauseCircleFilled from '@mui/icons-material/PauseCircleFilled'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import Typography from '@mui/material/Typography'
import {
  ACKNOWLEDGED,
  APPROVED,
  CALL_IN_OFF_PREFIX,
  CANCELLED,
  DENIED,
  FAILED,
  FAILED_CANCELLED,
  PENDING,
  PROCESSED,
  PROCESSING,
  PTO_PREFIX,
  SCHEDULED,
  TIME_OFF_TYPES,
  WAITLISTED,
} from '../../constants/RequestConstants'

const RequestStatusIcon = ({ requestType, status, isTimeOffTeamMember, showFailed, size = 'medium' }) => {
  if (status === PENDING) {
    return <HourglassFull titleAccess={PENDING} aria-label={PENDING} fontSize={size} style={{ color: '#c38a04' }} />
  } else if (status === ACKNOWLEDGED) {
    return (
      <ThumbUpIcon titleAccess={ACKNOWLEDGED} aria-label={ACKNOWLEDGED} fontSize={size} style={{ color: '#0377fc' }} />
    )
  } else if (status === APPROVED) {
    return (
      <AssignmentTurnedInIcon
        titleAccess={APPROVED}
        aria-label={APPROVED}
        fontSize={size}
        style={{ color: '#03c04a' }}
      />
    )
  } else if (status === WAITLISTED) {
    return (
      <PauseCircleFilled
        titleAccess={WAITLISTED}
        aria-label={WAITLISTED}
        fontSize={size}
        style={{ color: '#f59402' }}
      />
    )
  } else if (status === SCHEDULED) {
    return (
      <EventAvailableIcon titleAccess={SCHEDULED} aria-label={SCHEDULED} fontSize={size} style={{ color: '#0eb505' }} />
    )
  } else if (status === DENIED) {
    return <CancelIcon titleAccess={DENIED} aria-label={DENIED} fontSize={size} style={{ color: '#cc0000' }} />
  } else if (status === CANCELLED) {
    return (
      <CancelPresentationIcon
        titleAccess={CANCELLED}
        aria-label={CANCELLED}
        fontSize={size}
        style={{ color: '#818589' }}
      />
    )
  } else if (status === FAILED) {
    return failureStatusScenarioIcon(isTimeOffTeamMember, requestType, size, showFailed)
  } else if (status === FAILED_CANCELLED) {
    return failureCancelledStatusScenarioIcon(isTimeOffTeamMember, requestType, size)
  } else if (status === PROCESSED) {
    return <CheckCircle titleAccess={PROCESSED} aria-label={PROCESSED} fontSize={size} style={{ color: '#212121' }} />
  } else if (status === PROCESSING) {
    return <CachedIcon titleAccess={PROCESSING} aria-label={PROCESSING} fontSize={size} style={{ color: '#212121' }} />
  } else return unknownStatusIcon()
}

function failureStatusScenarioIcon(isTimeOffTeamMember, requestType, size, showFailed) {
  if (isTimeOffTeamMember === true && !showFailed) {
    if (requestType.includes(PTO_PREFIX) || requestType.includes(TIME_OFF_TYPES.UNPAID)) {
      return (
        <EventAvailableIcon
          titleAccess={SCHEDULED}
          aria-label={SCHEDULED}
          fontSize={size}
          style={{ color: '#0eb505' }}
        />
      )
    } else if (requestType.includes(CALL_IN_OFF_PREFIX)) {
      return (
        <ThumbUpIcon
          titleAccess={ACKNOWLEDGED}
          aria-label={ACKNOWLEDGED}
          fontSize={size}
          style={{ color: '#0377fc' }}
        />
      )
    } else {
      return unknownStatusIcon()
    }
  } else {
    return <Error titleAccess={FAILED} aria-label={FAILED} fontSize={size} style={{ color: '#cc0000' }} />
  }
}

function failureCancelledStatusScenarioIcon(isTimeOffTeamMember, requestType, size) {
  if (isTimeOffTeamMember === true) {
    return (
      <CancelPresentationIcon
        titleAccess={CANCELLED}
        aria-label={CANCELLED}
        fontSize={size}
        style={{ color: '#818589' }}
      />
    )
  } else {
    return <Error titleAccess={FAILED} aria-label={FAILED_CANCELLED} fontSize={size} style={{ color: '#cc0000' }} />
  }
}

function unknownStatusIcon() {
  return <Typography>N/A</Typography>
}

export default RequestStatusIcon
