import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useConfirm from '../../hooks/useConfirm'
import { setDesiredScreenName } from '../../store/leaderViewAutomation/actionCreator'

const DEFAULT_DIALOG_TEXT = 'You have unsaved changes on this page. This action will cause you to lose these changes.'

const CHANGE_LOCATION_DIALOG_TEXT_WITH_CHANGES =
  'You have unsaved changes on this page. If you change locations, you will lose these changes.'

const EXIT_DIALOG_TEXT = 'You have unsaved changes to this page. If you change screens, you will lose these changes.'

const CHANGE_LEADER_VIEW_DIALOG_TEXT =
  'You have unsaved changes to this page. If you change screens, you will lose these changes.'

const CHANGE_LEADER_VIEW_DATE_DIALOG_TEXT =
  'You have unsaved changes to this page. If you change dates, you will lose these changes.'

const LeaderViewPromptContainer = ({
  madeUpdates,
  popUpTextToChangeLocation,
  popUpTextToExit,
  popUpTextToChangeRadioView,
  popUpTextToChangeDate,
  dialogChangedPage,
  handleNavigationChange,
}) => {
  const desiredScreenName = useSelector((store) => store.leaderViewAutomation.desiredScreenName)

  const navigate = useNavigate()

  const { isConfirmed } = useConfirm()
  const dispatch = useDispatch()

  let dialogContent

  if (popUpTextToChangeLocation) {
    dialogContent = CHANGE_LOCATION_DIALOG_TEXT_WITH_CHANGES
  } else if (popUpTextToExit) {
    dialogContent = EXIT_DIALOG_TEXT
  } else if (popUpTextToChangeRadioView) {
    dialogContent = CHANGE_LEADER_VIEW_DIALOG_TEXT
  } else if (popUpTextToChangeDate) {
    dialogContent = CHANGE_LEADER_VIEW_DATE_DIALOG_TEXT
  } else {
    dialogContent = DEFAULT_DIALOG_TEXT
  }

  const handleNavigation = useCallback(async () => {
    if (await isConfirmed(dialogContent)) {
      handleNavigationChange()
    } else {
      navigate(-1)
    }
    dispatch(setDesiredScreenName(''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (madeUpdates && desiredScreenName) {
      handleNavigation()
    }
  }, [madeUpdates, desiredScreenName, handleNavigation])

  return <></>
}

export default LeaderViewPromptContainer
