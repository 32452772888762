import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const SAVE_DIALOG_TITLE = 'Are you sure you want to submit ?'

const SAVE_DIALOG_TEXT =
  'Hours are scheduled across team members based on their availability and trained jobs while also balancing the needs of the business.'

const REMINDER_MESSAGE = (
  <span>
    {'I understand this availability change request requires leader approval before impacting any future schedules.'}
  </span>
)

const NOTE_MESSAGE = (
  <span>
    <b>Note: </b>
    <br />
    {'Once approved your availability will be updated within 24 hours.'}
  </span>
)

const styles = {
  subTitle: {
    fontWeight: 600,
  },
  voluntarySelection: {
    marginBottom: '20px',
  },
}

const AvailabilitySubmitModal = ({
  submitDialogOpen,
  handleYesForSubmitAction,
  handleNoForSubmitAction,
  requestedHours,
  requestedMinutes,
  requestDateRange,
}) => {
  const handleYes = () => {
    handleYesForSubmitAction()
  }

  const handleNo = () => {
    handleNoForSubmitAction()
  }

  return (
    <Dialog
      data-cy="requestTimeOffSubmitDialog"
      open={submitDialogOpen}
      aria-labelledby="availability-submit-dialog-title"
      aria-describedby="availability-save-dialog-content"
      aria-modal={true}
      keepMounted
    >
      <DialogTitle id="availability-submit-dialog-title">{SAVE_DIALOG_TITLE}</DialogTitle>
      <DialogContent id="save-dialog-content">
        <DialogContentText id="availability-save-dialog-description" sx={styles.voluntarySelection}>
          {SAVE_DIALOG_TEXT}
        </DialogContentText>
        <DialogContentText id="save-dialog-reminder" sx={styles.voluntarySelection}>
          {REMINDER_MESSAGE}
        </DialogContentText>
        <DialogContentText id="save-dialog-reminder">{NOTE_MESSAGE}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          NO
        </Button>
        <Button onClick={handleYes} color="primary" variant="contained" aria-label="Yes">
          YES
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AvailabilitySubmitModal
