import { useTheme } from '@mui/material'
import List from '@mui/material/List'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useQueryClient } from '@tanstack/react-query'
import {
  addDays,
  addMonths,
  differenceInMinutes,
  eachDayOfInterval,
  isAfter,
  isBefore,
  isSameDay,
  subWeeks,
} from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { useUser } from '../../../auth/hooks/useUser'
import {
  DC_UNPAID_MAX,
  REQUEST_SCREEN_TITLES,
  REQUEST_TYPES,
  TIME_OFF_TYPES,
  TIME_OFF_VALID_DATE_RANGE_START_MINUS_WEEKS,
} from '../../../constants/RequestConstants'
import { ERROR_CODES_SHOW_MESSAGE, SUCCESS_ON_SAVE, UNABLE_TO_SAVE } from '../../../constants/errorConstants'
import { showNotificationError, showNotificationSuccess } from '../../../store/notification/actionCreator'
import { clearPreviousState, postRequestsData } from '../../../store/requestTimeOff/actionCreator'
import { getFiscalYearStartEnd, getNowDateInTimezone } from '../../../utils/DateUtil'
import { formatErrorCode } from '../../../utils/ErrorHandling'
import { TIME_OFF_TEAM_MEMBER } from '../../../utils/ScreenName'
import HeaderTitle from '../../Header/HeaderTitle'
import DateRangePicker from '../../common/calendar/DateRangePicker'
import NotAuthorized from '../../common/fallback/NotAuthorized'
import RequestCommentForm from '../RequestCommentForm'
import RequestTimeOffList from './RequestPaidTimeOffList'
import RequestPaidTimeOffSubmitCancel from './RequestPaidTimeOffSubmitCancel'

const getStyles = (theme) => ({
  errorMessage: theme.errorMessages,
  totalRequestedMinutes: {
    fontWeight: 'bold',
    fontSize: '105%',
    paddingTop: '2px',
  },
  totalRequestedMinutesError: {
    paddingTop: '2px',
  },
  datePickerContainer: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
  },
  totalHoursContainer: {
    textAlign: 'center',
    borderBottom: `2px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
  },
  timeOffRequestScrollableContainer: {
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingTop: '0',
    paddingBottom: '0',
  },
  bottomSectionContainer: {
    textAlign: 'right',
    borderTop: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
  },
})

// const TOP_MESSAGE = 'Reminder: All time off requests have to be scheduled by a leader'
const MY_REQUESTS_PAGE_PATH = '/team-member/requests/time_off'
export const COMMENT_MAX_LENGTH = 280
const TIME_OFF_ERROR_CODE = 'wfm-15-0'

// const TIME_OFF_VALID_DATE_RANGE_START_PLUS_WEEKS = 56
const MAX_MINUTES_PER_DAY = 720
const OVERLAP_MESSAGE = 'Time ranges for this date must not overlap'
const GREATER_THAN_12_HOURS_MESSAGE = 'The total time per day must not be greater than 12 hours'
const NO_HOURS_MESSAGE = 'The total time per row must be greater than 0'
const FORM_HAS_ERRORS_MESSAGE = 'Please correct errors before submitting request'
export const COMMENT = 'Comment'
const COMMENT_REQUIRED = 'Comment Required *'
const THIS_TIME_RANGE_OVERLAPS_MESSAGE = 'This time range overlaps another time range'
const CROSS_MIDNIGHT_MESSAGE = 'Note: This time range crosses midnight'
// added inline string to replace the need for this variable --> leaving in for documentation purposes
// const COMMENT_REQUIRED_MESSAGE = 'Note: Request types "other" require a comment describing the purpose of the request'

const RequestPaidTimeOff = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const dispatch = useDispatch()
  const { user } = useUser()
  const [hasChanges, setHasChanges] = useState(false)
  const [formIncomplete, setFormIncomplete] = useState(true)
  const [formHasErrors, setFormHasErrors] = useState(false)
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [totalRequestedMinutes, setTotalRequestedMinutes] = useState(null)
  const [comment, setComment] = useState('')
  const [timeOffRequestMap, setTimeOffRequestMap] = useState(new Map())
  const [timeOffRequestMessageMap, setTimeOffRequestMessageMap] = useState(new Map())
  const [navigateToMyRequests, setNavigateToMyRequests] = useState(false)
  const [totalHoursRequested, setTotalHoursRequested] = useState(null)
  const [totalMinutesRequested, setTotalMinutesRequested] = useState(null)
  const [isCommentRequired, setIsCommentRequired] = useState(false)

  const screenAccess = useSelector((state) => state.layout.screenAccess)
  const screenAccessLoading = useSelector((state) => state.layout.screenAccessLoading)
  const timeOffPostResponse = useSelector((state) => state.requestTimeOff.timeOffPostResponse)
  const timeOffPostErrorResponse = useSelector((state) => state.requestTimeOff.timeOffPostErrorResponse)
  const queryClient = useQueryClient()

  const initializeTimeOffRequestMap = useCallback((selectedStartDate, selectedEndDate) => {
    const arrayDays = eachDayOfInterval({ start: selectedStartDate, end: selectedEndDate })

    let newTimeOffRequestMap = new Map()
    let newTimeOffRequestMessageMap = new Map()
    arrayDays.forEach((dateKey) => {
      let initialSubRow = {
        type: '',
        startTime: null,
        hours: '',
        minutes: '',
      }
      let initialMessageObj = {
        // Using Set instead of array so there are no duplicate messages per day
        dateLevel: new Set(),
        rowLevelInfo: new Map(),
        rowLevelError: new Map(),
      }
      newTimeOffRequestMap.set(dateKey?.toDateString(), new Array(initialSubRow))
      newTimeOffRequestMessageMap.set(dateKey?.toDateString(), initialMessageObj)
    })

    setTimeOffRequestMap(newTimeOffRequestMap)
    setTimeOffRequestMessageMap(newTimeOffRequestMessageMap)
  }, [])

  useEffect(() => {
    if (selectedStartDate && selectedEndDate && timeOffRequestMap.size === 0) {
      initializeTimeOffRequestMap(selectedStartDate, selectedEndDate)
      setHasChanges(true)
    } else if (!selectedStartDate || !selectedEndDate) {
      setFormHasErrors(false)
    }
  }, [selectedStartDate, selectedEndDate, initializeTimeOffRequestMap, timeOffRequestMap])

  const isFormIncomplete = useCallback((timeOffRequestMap) => {
    for (const detailsByDate of timeOffRequestMap.values()) {
      for (const detail of detailsByDate) {
        if (detail.startTime === '' || detail.hours === '' || detail.minutes === '' || detail.type === '') {
          return true
        }
      }
    }

    return false
  }, [])

  useEffect(() => {
    let hasErrors = false
    if (timeOffRequestMessageMap) {
      for (const detailsByDate of timeOffRequestMessageMap.values()) {
        if (detailsByDate.rowLevelError.size > 0 || detailsByDate.dateLevel.size > 0) {
          hasErrors = true
        }
      }
    }
    setFormHasErrors(hasErrors)
  }, [timeOffRequestMessageMap])

  const isTimeOffRequestMapEmpty = useCallback((timeOffRequestMap) => {
    return timeOffRequestMap.size === 0
  }, [])

  useEffect(() => {
    setFormIncomplete(isTimeOffRequestMapEmpty(timeOffRequestMap) || isFormIncomplete(timeOffRequestMap))

    let checkIfCommentIsRequired = () => {
      let commentRequired = false

      timeOffRequestMap.forEach((item) => {
        item.forEach((value) => {
          if (value.type === TIME_OFF_TYPES.PTO_OTHER) {
            commentRequired = true
          }
        })
      })
      return commentRequired
    }

    let commentRequired = checkIfCommentIsRequired()
    setIsCommentRequired(commentRequired)

    if (!isTimeOffRequestMapEmpty(timeOffRequestMap)) {
      let totalRequestedMinutes = 0
      for (let value of timeOffRequestMap.values()) {
        for (let detailRow of value) {
          if (detailRow.startTime && detailRow.hours !== '' && detailRow.minutes !== '') {
            totalRequestedMinutes += detailRow.hours * 60 + detailRow.minutes
          }
        }
      }
      setTotalRequestedMinutes(totalRequestedMinutes)
    }
  }, [isFormIncomplete, isTimeOffRequestMapEmpty, timeOffRequestMap])

  useEffect(() => {
    if (timeOffPostResponse) {
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
      dispatch(clearPreviousState())
      queryClient.invalidateQueries(['requests'], { requestType: REQUEST_TYPES.time_off })
      setNavigateToMyRequests(true)
    } else if (timeOffPostErrorResponse) {
      if (
        timeOffPostErrorResponse.code &&
        timeOffPostErrorResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(timeOffPostErrorResponse.code)
      ) {
        dispatch(showNotificationError(true, timeOffPostErrorResponse.message))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE + ' ' + formatErrorCode(timeOffPostErrorResponse, TIME_OFF_ERROR_CODE),
          ),
        )
      }
      dispatch(clearPreviousState())
    }
  }, [timeOffPostResponse, timeOffPostErrorResponse, dispatch, queryClient])

  useEffect(() => {
    const setHoursAndMinutesRequested = () => {
      setTotalHoursRequested(Math.floor(totalRequestedMinutes / 60))
      setTotalMinutesRequested(totalRequestedMinutes % 60)
    }
    setHoursAndMinutesRequested()
  }, [totalRequestedMinutes])

  const handleDateOnChange = (name, date) => {
    name === 'startDate' ? handleStartDateClick(date) : handleEndDateClick(date)
  }
  const handleStartDateClick = (date) => {
    setSelectedStartDate(date)
    setSelectedEndDate(null)
    setTotalRequestedMinutes(null)
    setTimeOffRequestMap(new Map())
  }

  const handleEndDateClick = (newEndDate) => {
    setSelectedEndDate(newEndDate)
    if (selectedEndDate && newEndDate && !isSameDay(selectedEndDate, newEndDate)) {
      adjustTimeOffRequestMapsWithNewEndDate(selectedEndDate, newEndDate)
    }
  }

  const adjustTimeOffRequestMapsWithNewEndDate = (selectedEndDate, newSelectedEndDate) => {
    let newTimeOffRequestMap = new Map(timeOffRequestMap)
    let newTimeOffRequestMessageMap = new Map(timeOffRequestMessageMap)

    if (isAfter(newSelectedEndDate, selectedEndDate)) {
      let interval = { start: addDays(selectedEndDate, 1), end: newSelectedEndDate }

      eachDayOfInterval(interval).forEach((dateKey) => {
        let initialSubRow = {
          type: '',
          startTime: null,
          hours: '',
          minutes: '',
        }
        let initialMessageObj = {
          dateLevel: new Set(),
          rowLevelInfo: new Map(),
          rowLevelError: new Map(),
        }
        newTimeOffRequestMap.set(dateKey?.toDateString(), new Array(initialSubRow))
        newTimeOffRequestMessageMap.set(dateKey?.toDateString(), initialMessageObj)
      })
    } else {
      let interval = { start: addDays(newSelectedEndDate, 1), end: selectedEndDate }
      eachDayOfInterval(interval).forEach((dateKey) => {
        newTimeOffRequestMap.delete(dateKey?.toDateString())
        newTimeOffRequestMessageMap.delete(dateKey?.toDateString())
      })
    }

    setTimeOffRequestMap(newTimeOffRequestMap)
    setTimeOffRequestMessageMap(newTimeOffRequestMessageMap)
  }

  const handleTimeOffRequestMap = (updatedMap) => {
    validateUpdatedMap(new Map(updatedMap))
    setTimeOffRequestMap(new Map(updatedMap))
  }

  const validateUpdatedMap = (updatedMap) => {
    let updatedMessageMap = new Map()
    let startEndTimestampsMap = getStartEndTimestampsMap(updatedMap)
    let previousUpdatedMapKey = null
    for (let [updatedMapKey, updatedMapValue] of startEndTimestampsMap) {
      let initialMessageObj = {
        dateLevel: new Set(),
        rowLevelInfo: new Map(),
        rowLevelError: new Map(),
      }
      updatedMessageMap.set(updatedMapKey, initialMessageObj)

      if (doesTotalTimeRangesExceedMax(updatedMapValue)) {
        updatedMessageMap.get(updatedMapKey).dateLevel.add(GREATER_THAN_12_HOURS_MESSAGE)
      }
      if (validateOverlapTimeSameDay(updatedMapKey, updatedMapValue, updatedMessageMap)) {
        updatedMessageMap.get(updatedMapKey).dateLevel.add(OVERLAP_MESSAGE)
      }
      if (
        previousUpdatedMapKey &&
        validateOverlapTimeDifferentDays(
          previousUpdatedMapKey,
          startEndTimestampsMap.get(previousUpdatedMapKey),
          updatedMapKey,
          updatedMapValue,
          updatedMessageMap,
          false,
        )
      ) {
        updatedMessageMap.get(previousUpdatedMapKey).dateLevel.add(OVERLAP_MESSAGE)
        updatedMessageMap.get(updatedMapKey).dateLevel.add(OVERLAP_MESSAGE)
      }
      validateUnpaidDays(updatedMapKey, updatedMapValue, updatedMessageMap.get(updatedMapKey).rowLevelError)
      validateMinHoursPerRow(updatedMapKey, updatedMap.get(updatedMapKey), updatedMessageMap)
      setInfoMessagesPerRow(updatedMapValue, updatedMessageMap.get(updatedMapKey).rowLevelInfo)
      previousUpdatedMapKey = updatedMapKey
    }

    setTimeOffRequestMessageMap(updatedMessageMap)
  }

  const validateUnpaidDays = (updatedMapKey, updatedMapValue, rowLevelError) => {
    const isMoreThanSixMonthsAway = isAfter(new Date(updatedMapKey), addMonths(new Date(), 6))

    updatedMapValue.forEach((detail, index) => {
      let errors = []

      if (detail.type === TIME_OFF_TYPES.UNPAID && isMoreThanSixMonthsAway) {
        errors.push(DC_UNPAID_MAX)
        rowLevelError.set(index, errors)
      }
    })
  }

  const validateMinHoursPerRow = (date, rowsDetails, updatedMessageMap) => {
    for (let i = 0; i < rowsDetails.length; i++) {
      if (rowsDetails[i].startTime && rowsDetails[i].hours === 0 && rowsDetails[i].minutes === 0) {
        updatedMessageMap.get(date).rowLevelError.set(i, NO_HOURS_MESSAGE)
      }
    }
  }

  const getStartEndTimestampsMap = (updatedMap) => {
    let startEndTimestampsMap = new Map()
    for (let [updatedMapKey, updatedMapValue] of updatedMap) {
      let startEndTimestampsForDate = []
      let date = new Date(updatedMapKey)
      updatedMapValue.forEach((detail) => {
        let startTimestamp = null
        let endTimestamp = null
        if (detail.startTime) {
          let timeArrayStart = [detail.startTime.getHours(), detail.startTime.getMinutes()]
          startTimestamp = addTimeToDateObj(parseInt(timeArrayStart[0]), parseInt(timeArrayStart[1]), date)
          if (detail.hours !== '' && detail.minutes !== '') {
            let totalMinTime =
              (parseInt(timeArrayStart[0]) + detail.hours) * 60 + parseInt(timeArrayStart[1]) + detail.minutes
            endTimestamp = addTimeToDateObj(Math.floor(totalMinTime / 60), totalMinTime % 60, date)
          }
        }

        if (endTimestamp && startTimestamp && isBefore(endTimestamp, startTimestamp)) {
          endTimestamp = addDays(endTimestamp, 1)
        }
        startEndTimestampsForDate.push({ start: startTimestamp, end: endTimestamp, type: detail.type })
      })
      startEndTimestampsMap.set(updatedMapKey, startEndTimestampsForDate)
    }
    return startEndTimestampsMap
  }

  const addTimeToDateObj = (hour, minute, dateObj) => {
    return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), hour, minute, 0, 0)
  }

  const doesTotalTimeRangesExceedMax = (startEndTimestampsByDate) => {
    let totalMinutes = 0

    startEndTimestampsByDate.forEach((startEndTimestamp) => {
      if (startEndTimestamp.end && startEndTimestamp.start) {
        totalMinutes += differenceInMinutes(startEndTimestamp.end, startEndTimestamp.start)
      }
    })

    return totalMinutes > MAX_MINUTES_PER_DAY
  }

  const validateOverlapTimeSameDay = (date, startEndTimestampsByDate, updatedMessageMap) => {
    return validateOverlapTimeDifferentDays(
      date,
      startEndTimestampsByDate,
      date,
      startEndTimestampsByDate,
      updatedMessageMap,
      true,
    )
  }

  const validateOverlapTimeDifferentDays = (
    date1,
    startEndTimestampsByDate1,
    date2,
    startEndTimestampsByDate2,
    updatedMessageMap,
    comparableArraysAreSame,
  ) => {
    let overlappingTimeRanges = false
    for (let index1 = 0; index1 < startEndTimestampsByDate1.length; index1++) {
      for (
        let index2 = getInnerIndexStart(comparableArraysAreSame, index1);
        index2 < startEndTimestampsByDate2.length;
        index2++
      ) {
        let time1 = startEndTimestampsByDate1[index1]
        let time2 = startEndTimestampsByDate2[index2]

        if (
          time1.start &&
          time1.end &&
          time2.start &&
          time2.end &&
          doesTimeRangeOverlap(time1.start, time1.end, time2.start, time2.end)
        ) {
          updatedMessageMap.get(date1).rowLevelError.set(index1, THIS_TIME_RANGE_OVERLAPS_MESSAGE)
          updatedMessageMap.get(date2).rowLevelError.set(index2, THIS_TIME_RANGE_OVERLAPS_MESSAGE)
          overlappingTimeRanges = true
        }
      }
    }
    return overlappingTimeRanges
  }

  const getInnerIndexStart = (comparableArraysAreSame, outerIndex) => {
    if (comparableArraysAreSame) {
      return outerIndex + 1
    } else {
      return 0
    }
  }

  const setInfoMessagesPerRow = (startEndTimestampsByDate, messageMapRowLevel) => {
    startEndTimestampsByDate.forEach((detail, index) => {
      let messages = []
      if (detail.start && detail.end) {
        if (!areDatesOnSameDay(detail.start, detail.end)) {
          messages.push(CROSS_MIDNIGHT_MESSAGE)
        }
      }
      // Potentially related to defect from line 214
      if (detail.type === TIME_OFF_TYPES.PTO_OTHER) {
        // Swap out const variable COMMENT_REQUIRED_MESSAGE for inline string
        messages.push(`Note: Request types "${detail.type}" require a comment describing the purpose of the request`)
      } else if (detail.type === TIME_OFF_TYPES.UNPAID) {
        messages.push(`Note: Request types "${detail.type}" can only be requested up to 6 months in the future`)
      }
      messageMapRowLevel.set(index, messages)
    })
  }

  const areDatesOnSameDay = (first, second) => {
    return (
      first.getYear() === second.getYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    )
  }

  const doesTimeRangeOverlap = (start, end, compareStart, compareEnd) => {
    return start < compareEnd && compareStart < end
  }

  const renderDatePickerSection = () => {
    if (user.locationData.iso_time_zone_code) {
      let validStartDateMin = subWeeks(
        getNowDateInTimezone(user.locationData.iso_time_zone_code),
        TIME_OFF_VALID_DATE_RANGE_START_MINUS_WEEKS,
      )

      let fiscalDates = getFiscalYearStartEnd('team_member')

      return (
        <Paper sx={styles.datePickerContainer} elevation={1} square>
          <DateRangePicker
            minDate={validStartDateMin}
            maxDate={fiscalDates.end}
            maxDaysInRange={14}
            handleOnChange={handleDateOnChange}
            onBlurMessage={true}
            containerSx={{ padding: '10px', justifyContent: 'space-evenly', alignItems: 'flex-start' }}
            textFieldSx={{ maxWidth: '126px' }}
            pickerJoinComponent={<Typography sx={{ fontSize: '16px', padding: '16px 8px 0px 8px' }}>to</Typography>}
          />
        </Paper>
      )
    }
  }

  const renderRequestLevelError = (errorMessage) => {
    return (
      <Paper sx={styles.totalHoursContainer} elevation={1} square>
        <Typography
          data-cy="requestTimeOffTotal_Errors"
          variant="body2"
          sx={styles.totalRequestedMinutesError}
          color={'primary'}
        >
          {errorMessage}
        </Typography>
      </Paper>
    )
  }

  const renderTotalHours = () => {
    if (formHasErrors) return renderRequestLevelError(FORM_HAS_ERRORS_MESSAGE)
    else if (totalRequestedMinutes) {
      return (
        <Paper sx={styles.totalHoursContainer} elevation={1} square>
          <Typography data-cy="requestTimeOffTotal" variant="body2" sx={styles.totalRequestedMinutes}>
            {'Total Requested Hours: ' + totalHoursRequested + ' Minutes: ' + totalMinutesRequested}
          </Typography>
        </Paper>
      )
    }
  }

  const renderScrollableContent = () => {
    let label = isCommentRequired ? COMMENT_REQUIRED : COMMENT
    return (
      <Paper
        sx={styles.timeOffRequestScrollableContainer}
        id="scrollableContainer"
        data-cy="scrollableContainer_weekly"
        elevation={1}
        square
      >
        <List sx={styles.list} data-cy="requestTimeOffList">
          <RequestTimeOffList
            timeOffRequestMap={timeOffRequestMap}
            handleTimeOffRequestMap={handleTimeOffRequestMap}
            timeOffRequestMessageMap={timeOffRequestMessageMap}
          />
        </List>
        {timeOffRequestMap.size > 0 ? (
          <RequestCommentForm value={comment} setComment={setComment} label={label} maxLength={COMMENT_MAX_LENGTH} />
        ) : null}
      </Paper>
    )
  }

  const handleSubmitRequest = () => {
    dispatch(postRequestsData(user, timeOffRequestMap, comment))
  }

  const renderBottomButtons = () => {
    return (
      <Paper sx={styles.bottomSectionContainer} elevation={1} square>
        <RequestPaidTimeOffSubmitCancel
          showCancelDialog={hasChanges}
          disableSubmit={formIncomplete || formHasErrors || (isCommentRequired && !/\S/.test(comment))}
          setNavigateToMyRequests={setNavigateToMyRequests}
          handleSubmitRequest={() => handleSubmitRequest()}
          requestedHours={totalHoursRequested}
          requestedMinutes={totalMinutesRequested}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
        />
      </Paper>
    )
  }

  const renderAllRequestTimeOffContent = () => {
    if (!screenAccessLoading) {
      if (!screenAccess.includes(TIME_OFF_TEAM_MEMBER)) {
        return <NotAuthorized />
      } else {
        if (navigateToMyRequests) {
          dispatch(clearPreviousState())
          return <Navigate to={MY_REQUESTS_PAGE_PATH} />
        } else {
          return (
            <React.Fragment>
              {renderDatePickerSection()}
              {renderTotalHours()}
              {renderScrollableContent()}
              {renderBottomButtons()}
            </React.Fragment>
          )
        }
      }
    }
  }

  return (
    <React.Fragment>
      <HeaderTitle title={REQUEST_SCREEN_TITLES.REQUEST_PAID_TIME_OFF} />
      {renderAllRequestTimeOffContent()}
    </React.Fragment>
  )
}

export default RequestPaidTimeOff
