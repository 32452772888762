import { Navigate, useLocation } from 'react-router-dom'

const DailySchduleRedirectTemp = () => {
  const location = useLocation()

  const fullPath = location.pathname + location.search

  return <Navigate to={`/team-member${fullPath}`} replace />
}

export default DailySchduleRedirectTemp
