import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'

const CANCEL_DIALOG_TITLE = 'Are you sure you want to cancel?'
const CANCEL_DIALOG_TEXT = 'You have unsaved changes to this page. If you cancel, you will lose these changes.'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CancelPromptDialog = (props) => {
  const { openCancelDialog, closeCancelDialog, cancelDialogNoButtonClikced, cancelDialogYesButtonClikced } = props
  return (
    <Dialog open={openCancelDialog} TransitionComponent={Transition} keepMounted onClose={closeCancelDialog}>
      <DialogTitle id="save-dialog-slide-title">{CANCEL_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText id="save-dialog-slide-description">{CANCEL_DIALOG_TEXT}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialogNoButtonClikced} color="primary">
          NO
        </Button>
        <Button onClick={cancelDialogYesButtonClikced} color="primary">
          YES
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelPromptDialog
