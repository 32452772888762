import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, Popover, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetFilters, setFilters } from '../../../../store/leader/actionCreator'

const FilterBy = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const dispatch = useDispatch()
  const selectedCategory = useSelector((state) => state.leader.selectedCategory)
  const filters = useSelector((state) => state.leader[selectedCategory]?.filters)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleResetFilters = () => {
    dispatch(resetFilters())
  }

  const handleChange = (filterKey, event) => {
    dispatch(setFilters(filterKey, event.target.value))
  }

  const filterDropdowns = () => {
    return (
      <>
        {Object.entries(filters).map(([filterKey, filterObject]) => {
          return (
            <TextField
              select
              key={filterKey}
              id={filterKey}
              value={filterObject.selectedValues}
              label={filterObject.display_name}
              onChange={(event) => {
                handleChange(filterKey, event)
              }}
              SelectProps={{ multiple: true }}
              fullWidth
              sx={{
                '& .MuiInputBase-input': {
                  whiteSpace: 'unset',
                },
                marginTop: 1,
              }}
            >
              {filterObject?.values?.map((option) => (
                <MenuItem data-cy={`filter-${option}`} key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )
        })}
      </>
    )
  }

  return (
    <>
      <Grid container alignItems={'center'}>
        <IconButton onClick={handleClick} aria-label={'filter'}>
          <FilterListIcon sx={{ height: 26, width: 26 }} />
        </IconButton>
      </Grid>
      <Popover
        sx={{
          '& .MuiPopover-paper': {
            padding: 1,
          },
        }}
        data-cy="filterPopover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: '235px' },
        }}
      >
        {selectedCategory && filters ? (
          <Grid container direction="column" justifyContent="center" spacing={1}>
            <Grid item>{filterDropdowns()}</Grid>
            <Grid item>
              <Button onClick={handleResetFilters} data-cy="reset-button">
                RESET
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container direction="row" alignItems={'center'} spacing={1}>
              <Grid item>
                <Typography label="Please select a category">Please select a category</Typography>
              </Grid>
              <Grid item>
                <IconButton size={'small'} aria-label={'Close'} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        )}
      </Popover>
    </>
  )
}

export default FilterBy
