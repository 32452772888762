import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'

const getStyles = () => ({
  auditDialog: {
    justifyContent: 'left',
    minWidth: 40,
  },
  defaultListItemIconCls: {
    justifyContent: 'center',
  },
  ListItemCls: {
    width: '100%',
    maxWidth: 640,
    marginBottom: 2,
  },
})

const CategoryDetailDataHeader = ({ requestStatusIcon, primary, secondaryList, purpose }) => {
  const styles = getStyles()
  return (
    <ListItem disablePadding disableGutters sx={styles.ListItemCls}>
      <ListItemIcon tabIndex={0} sx={purpose === 'audit' ? styles.auditDialog : styles.defaultListItemIconCls}>
        {requestStatusIcon}
      </ListItemIcon>
      <ListItemText
        tabIndex={0}
        primary={
          <Typography role="heading" aria-label={primary} variant="body1">
            {primary}
          </Typography>
        }
        secondary={
          <span>
            {secondaryList?.map((item, i) => {
              return (
                <Typography
                  role="heading"
                  aria-label={item}
                  sx={{ display: 'block' }}
                  component="span"
                  variant="body2"
                  key={i}
                >
                  {item}
                </Typography>
              )
            })}
          </span>
        }
      />
    </ListItem>
  )
}

export default CategoryDetailDataHeader
