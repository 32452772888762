import { Button, Divider, Grid, List, ListItem } from '@mui/material'
import React from 'react'
import useSyncTotalCount from './hooks/useSyncTotalCount'
import TableFallback from './TableFallback'

/**
 * This component is a wrapper for all mobile table rows. it will handle all data, error, and loading states
 *
 * @param {Row} the row to be rendered
 * @param {*} a react query response object
 * @returns rows as specified by Row and data from object
 */
export default function TableContent({ Row, data, totalCount, error, isLoading, isFetching, numberOfRecords }) {
  useSyncTotalCount(totalCount)

  if (data) {
    if (numberOfRecords === 0) {
      return <TableFallback variant="noData" />
    }

    return (
      <Grid width={'100%'} position={'relative'} alignItems={'flex-start'}>
        <List disablePadding>
          <div id="back-to-top-anchor" />
          {data.map((request) => {
            return (
              <React.Fragment key={request.request_id}>
                <Row data={request} />
                <Divider variant="middle" component="li" aria-hidden="true" />
              </React.Fragment>
            )
          })}
          <ScrollToTop />
        </List>
      </Grid>
    )
  }

  if (error) {
    return <TableFallback variant="error" error={error?.response?.data} />
  }

  // first load only, placeholder skeleton
  if (isLoading && isFetching) {
    return <TableFallback variant="spinner" />
  }

  return <TableFallback />
}

const ScrollToTop = () => {
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor')

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <ListItem sx={{ width: '100%', justifyContent: 'center' }}>
      <Button variant="text" onClick={handleClick} role="presentation" data-cy={'TableContent-scrollToTop'}>
        Back To Top
      </Button>
    </ListItem>
  )
}
