import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useUserFeatures } from '../../../api/userAccess/useUserFeatures'
import { LEADER_DASHBOARD_TITLE } from '../../../constants/leaderConstants.js'
import { setHeaderTitle } from '../../../store/layout/actionCreator'
import { AVAILABILITY_READ_ONLY_LEADER } from '../../../utils/ScreenName.js'
import InputLocationSelector from '../InputLocationSelector.js'
import LocationSelector from '../LocationSelector'
import AtAGlanceCard from './AtAGlanceCard.js'
import DailyActionsCard from './DailyActionsCard'

const getStyles = (theme) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  gridItem: {
    margin: theme.spacing(1),
  },
  gridItemInput: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
})

const DashboardPage = () => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setHeaderTitle(LEADER_DASHBOARD_TITLE))
  }, [dispatch])

  return (
    <Box style={styles.mainContainerPosition}>
      <Grid container sx={styles.mainScrollContainer}>
        <DashboardLocationSelector />
        <DashboardCards />
      </Grid>
    </Box>
  )
}
export default DashboardPage

const DashboardLocationSelector = () => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const { data: userFeatures } = useUserFeatures()
  const screenAccess = userFeatures?.screenAccess

  return (
    <Grid container item xs={12} md={12} sx={styles.gridItem} minHeight={'60px'} spacing={1}>
      <Grid item xs={3} md={3} sx={styles.gridItemInput}>
        {screenAccess?.includes(AVAILABILITY_READ_ONLY_LEADER) ? (
          <>
            <InputLocationSelector />
          </>
        ) : (
          <>
            <LocationSelector />
          </>
        )}
      </Grid>
    </Grid>
  )
}

const DashboardCards = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  return (
    <>
      <Grid item xs={12} md={12} sx={styles.gridItem}>
        <AtAGlanceCard />
      </Grid>
      <Grid item xs={12} md={12} sx={styles.gridItem}>
        <DailyActionsCard />
      </Grid>
    </>
  )
}
