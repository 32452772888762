import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import WarningIcon from '@mui/icons-material/Warning'
import { Button, Grid, useTheme } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsIssueCodeDialogOpen, setIsRedeemCodeDialogOpen } from '../../store/workerRedemptionCode/actionCreator'
import CopyToClipboard from '../../utils/CopyToClipboard'

const MAX_ISSUED_CODES = 3
const ISSUE_NEW_CODE_BTN_LABEL = 'Issue Me A New Code'
const ISSUE_ANOTHER_CODE = 'get another code'
const ISSUE_CODE_INFO_MSG_1 =
  'In this initial step, Target will issue you a unique code, which then can be redeemed in the next step.'
const ISSUED_MAX_CODES_MSG = 'You have already been issued the maximum number of codes.'
const RECENT_ISSUED_CODE_MSG = 'Your Current Code'
const REDEEM_CODE_BTN_LABEL = 'Redeem My Code'
const NO_CODES_ISSUED_MSG = 'You need to be issued a code before you can redeem it.'

const getStyles = (theme) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  cardTitleContainer: {
    display: 'flex',
    flexGrow: '1',
  },
  cardTitle: {
    marginLeft: '.4em',
    fontWeight: 'bold',
  },
  cardDescription: {
    paddingBottom: '12px',
  },
  issuedCodeMsg: {
    color: theme.palette.tertiary.contrastText,
  },
  numberOfIssuedCodesMsg: {
    fontStyle: 'italic',
    color: theme.palette.tertiary.contrastText,
  },
  buttonWarningMessageContainer: {
    display: 'flex',
  },
  icon: {
    marginRight: '8px',
    color: '#EED202',
    fontSize: '20px',
  },
  infoIcon: {
    marginRight: '4px',
    fontSize: '12px',
  },
})

const IssueCodeCard = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const dispatch = useDispatch()
  const data = useSelector((state) => state.workerRedemptionCode.data)

  const handleIssueNewCodeButtonClick = () => {
    dispatch(setIsIssueCodeDialogOpen(true))
  }

  const handleRedeemCodeButtonClick = () => {
    dispatch(setIsRedeemCodeDialogOpen(true))
  }

  const renderIssueCodeCard = () => {
    return (
      <Card data-cy="IssueCodeCard">
        <CardContent>
          <div style={styles.cardTitleContainer}>
            <ConfirmationNumberIcon />
            <Typography
              variant="subtitle1"
              component="h3"
              gutterBottom
              sx={styles.cardTitle}
              data-cy="IssueCodeCardTitle"
            >
              Issue & Redeem Code
            </Typography>
          </div>
          {data.total_redeemed_codes === '0' && renderIssueNewCodeText()}
          {data.total_redeemed_codes > 0 ? <></> : renderIssueNewCodeButton()}
          {renderExistingCodes()}
          {renderExistingCodesMsg()}
          {renderRedeemCodeButton()}
          {data.total_redeemed_codes > 0 ? (
            <Typography paddingTop={1} fontSize={'small'} color={'tertiary'}>
              New Apple ID? {renderIssueNewCodeButton()}
            </Typography>
          ) : (
            <></>
          )}
          {data.total_redeemed_codes >= MAX_ISSUED_CODES ? renderMaxCodesIssuedMsg() : null}
        </CardContent>
      </Card>
    )
  }

  const renderExistingCodesMsg = () => {
    let plural = data.total_redeemed_codes === '1' ? '' : 's'
    return (
      <Grid paddingBottom={2}>
        <Typography variant={'body2'} sx={styles.numberOfIssuedCodesMsg} data-cy="IssueCodeCardNumCodesIssued">
          {`* You have been issued ${data.total_redeemed_codes} code${plural} so far.`}
        </Typography>
      </Grid>
    )
  }

  const renderExistingCodes = () => {
    if (data.redemption_codes.length > 0) {
      return (
        <Grid paddingTop={2}>
          <Typography variant={'caption'} data-cy="IssueCodeCardRecentCode">
            <CopyToClipboard text={data.redemption_codes[0]} label={RECENT_ISSUED_CODE_MSG} labelWidth={240} />
          </Typography>
        </Grid>
      )
    }
  }

  const renderIssueNewCodeText = () => {
    return (
      <Typography sx={styles.cardDescription} data-cy="IssueCodeCardMsg1">
        {ISSUE_CODE_INFO_MSG_1}
      </Typography>
    )
  }

  const renderIssueNewCodeButton = () => {
    let alreadyHasCode = data.total_redeemed_codes > 0
    return (
      <Button
        onClick={handleIssueNewCodeButtonClick}
        color={'primary'}
        variant={alreadyHasCode ? 'text' : 'contained'}
        disabled={data.total_redeemed_codes >= MAX_ISSUED_CODES}
        size="small"
        data-cy="IssueNewCodeButton"
      >
        {alreadyHasCode ? ISSUE_ANOTHER_CODE : ISSUE_NEW_CODE_BTN_LABEL}
      </Button>
    )
  }

  const renderMaxCodesIssuedMsg = () => {
    return (
      <Grid paddingY={1} sx={styles.buttonWarningMessageContainer}>
        <WarningIcon sx={styles.icon} />
        <Typography data-cy="IssueCodeCardMaxCodesMsg">{ISSUED_MAX_CODES_MSG}</Typography>
      </Grid>
    )
  }

  const renderNoCodesIssuedMsg = () => {
    return (
      <div style={styles.buttonWarningMessageContainer}>
        <WarningIcon sx={styles.icon} />
        <Typography data-cy="RedeemCodeCardNoCodesMsg">{NO_CODES_ISSUED_MSG}</Typography>
      </div>
    )
  }

  const renderRedeemCodeButton = () => {
    return (
      <>
        <Button
          onClick={handleRedeemCodeButtonClick}
          color="primary"
          variant="contained"
          size="small"
          disabled={data.redemption_codes.length === 0}
          data-cy="RedeemCodeCardButton"
          sx={{ marginY: 1 }}
        >
          {REDEEM_CODE_BTN_LABEL}
        </Button>
        {data.redemption_codes.length === 0 ? renderNoCodesIssuedMsg() : null}
      </>
    )
  }

  return <React.Fragment>{renderIssueCodeCard()}</React.Fragment>
}

export default IssueCodeCard
