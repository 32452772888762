import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'
import {
  GET_TIMECARD_DATA_WEEKLY_ERROR,
  GET_TIMECARD_DATA_WEEKLY_SUCCESS,
  GET_WEEKLY_SCHEDULE_ERROR,
  GET_WEEKLY_SCHEDULE_SUCCESS,
  SET_TIMECARD_WEEKLY_LOADING,
  SET_TIMECARD_WEEKLY_SELECTED_DATE,
  SET_TOTAL_SCHEDULED_HRS_WEEKLY_LOADING,
} from './actionType'

const initialState = {
  data: {},
  loading: 'N',
  error: null,
  weeklySelectedDate: getDateOfTodayWithNoTimestamp(),
  totalScheduledHrs: null,
  totalScheduledHrsError: null,
  totalScheduledHrsLoading: 'N',
}

export default function timecardWeekly(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TIMECARD_DATA_WEEKLY_SUCCESS: {
      const { record } = action
      return {
        ...state,
        data: record.data,
        error: null,
        loading: 'N',
      }
    }
    case GET_TIMECARD_DATA_WEEKLY_ERROR: {
      const { record } = action
      return {
        ...state,
        error: record.response ? record.response.data : record,
        loading: 'N',
      }
    }

    case SET_TIMECARD_WEEKLY_LOADING: {
      const { payload } = action
      return {
        ...state,
        loading: payload,
      }
    }
    case SET_TIMECARD_WEEKLY_SELECTED_DATE: {
      const { date } = action
      return {
        ...state,
        weeklySelectedDate: date,
      }
    }
    case GET_WEEKLY_SCHEDULE_ERROR: {
      const { record } = action
      return {
        ...state,
        totalScheduledHrs: '--.--',
        totalScheduledHrsError: record.response ? record.response.data : record,
        totalScheduledHrsLoading: 'N',
      }
    }
    case GET_WEEKLY_SCHEDULE_SUCCESS: {
      const { record } = action
      return {
        ...state,
        totalScheduledHrs: record.data.total_hours,
        totalScheduledHrsLoading: 'N',
        totalScheduledHrsError: null,
      }
    }
    case SET_TOTAL_SCHEDULED_HRS_WEEKLY_LOADING: {
      const { payload } = action
      return {
        ...state,
        totalScheduledHrsLoading: payload,
      }
    }
    default:
      return state
  }
}
