export function removeNullValues(obj) {
  const newObj = {}

  for (const key in obj) {
    if (obj[key] !== null) {
      newObj[key] = obj[key]
    }
  }

  return newObj
}
