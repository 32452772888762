import { Divider } from '@mui/material'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLocationDetails } from '../../../../../api/location/useLocationDetails'
import { useUserFeatures } from '../../../../../api/userAccess/useUserFeatures'
import { CALL_IN_LATE, CATEGORY_FEATURE_MAP } from '../../../../../constants/RequestConstants'
import { getConfigDetails } from '../../../../../constants/leaderConstants'
import { setControlPanelSettings } from '../../../../../store/leader/actionCreator'
import TotalTimeOffRequestsByStatusButton from '../../TotalTimeOffRequestsByStatusButton'

const CallInAtAGlance = () => {
  const { data: locationDetails } = useLocationDetails()
  const { data: userFeatures } = useUserFeatures()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { configMap } = useMemo(() => getConfigDetails(locationDetails, userFeatures), [locationDetails, userFeatures])

  const handleCallInRequestsButtonClick = () => {
    dispatch(
      setControlPanelSettings(
        CALL_IN_LATE,
        configMap[CALL_IN_LATE].defaultStartDate,
        configMap[CALL_IN_LATE].defaultEndDate,
        {},
        configMap[CALL_IN_LATE].searchText,
        configMap[CALL_IN_LATE].selectedSortByIndex,
        configMap[CALL_IN_LATE].sortOrder,
      ),
    )
    navigate('/leader/myteam')
  }

  return (
    <>
      <Divider aria-hidden="true" />
      <TotalTimeOffRequestsByStatusButton
        status={''}
        startDate={configMap[CALL_IN_LATE].defaultStartDate}
        endDate={configMap[CALL_IN_LATE].defaultEndDate}
        handleButtonClick={handleCallInRequestsButtonClick}
        buttonMessage={'Call In Requests'}
        selectedCategory={CATEGORY_FEATURE_MAP.get(CALL_IN_LATE)}
        categoryName={CALL_IN_LATE}
      />
    </>
  )
}

export default CallInAtAGlance
