import { matchPath, useLocation } from 'react-router-dom'

export function useRouteMatch(patterns, end = false) {
  const { pathname } = useLocation()

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath({ path: pattern, end: end }, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}
