import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CallInDetail from './Categories/CallIn/CallInDetail'
import CallOffDetail from './Categories/CallOff/CallOffDetail'
import AvailabilityDetail from './Categories/Availability/AvailabilityDetail'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  AVAILABILITY,
  CALL_IN_LATE,
  CALL_OFF_ABSENT,
  TIME_OFF,
  PUNCH_CORRECTION,
} from '../../../constants/RequestConstants'
import { setBackToComponent } from '../../../store/header/actionCreator'
import { setHeaderTitle } from '../../../store/layout/actionCreator'
import { MY_TEAM_PAGE_PATH } from '../MyTeam/MyTeamPage'
import PunchCorrectionDetail from './Categories/PunchCorrection/PunchCorrectionDetail'
import TimeOffDetail from './Categories/TimeOff/TimeOffDetail'
import { TimeOffApiVO } from './TimeOffApiVO'

const CategoryComponent = ({ category, id, data }) => {
  switch (category) {
    case CALL_IN_LATE:
      return <CallInDetail id={id} dataVO={new TimeOffApiVO(data)} />
    case CALL_OFF_ABSENT:
      return <CallOffDetail id={id} dataVO={new TimeOffApiVO(data)} />
    case TIME_OFF:
      return <TimeOffDetail id={id} dataVO={new TimeOffApiVO(data)} />
    case AVAILABILITY:
      return <AvailabilityDetail id={id} data={data} />
    case PUNCH_CORRECTION:
      return <PunchCorrectionDetail id={id} data={data} />
    default:
      return <></>
  }
}

const CategoryDetailPage = () => {
  let { category, id } = useParams()
  const { state } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const backToComponent = useSelector((store) => store.header.backToComponent)

  useEffect(() => {
    dispatch(setHeaderTitle(category + ' Detail'))
  }, [dispatch, category])

  useEffect(() => {
    if (!backToComponent) {
      // During scenario user refreshes details screen
      dispatch(setBackToComponent(MY_TEAM_PAGE_PATH))
    }
  }, [dispatch, backToComponent])

  useEffect(() => {
    if (!state?.data) {
      // During scenario when user uses direct url with request id
      navigate(`/leader/myteam`)
    }
  }, [navigate, state?.data])

  return state?.data ? <CategoryComponent category={category} id={id} data={state?.data} /> : null
}

export default CategoryDetailPage
