import axios from 'axios'
import {
  DIRECTORY_COLUMNS_ENUM,
  DIRECTORY_COLUMNS_ORDER,
} from '../../components/LeaderViewAutomation/LeaderViewAutomation'
import apiConfig from '../../config/apiConfig'
import {
  ADD_AUTOMATION_DIRECTORY_SESSION_DATA,
  ADD_SELECTED_AUTOMATION_DIRECTORY_INDEXES,
  AUTOMATION_DIRECTORY_HANDLE_POST_ERROR,
  AUTOMATION_DIRECTORY_HANDLE_POST_SUCCESS,
  AUTOMATION_DIRECTORY_SET_DATA_COLUMN_VIEW,
  AUTOMATION_DIRECTORY_SET_POST_RESPONSE,
  AUTOMATION_DIRECTORY_SET_SAVE_DIALOG_OPEN,
  DOWNLOAD_AUTOMATION_DIRECTORY_CSV_ERROR,
  DOWNLOAD_AUTOMATION_DIRECTORY_CSV_SUCCESS,
  GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_ERROR,
  GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_SUCCESS,
  GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_ERROR,
  GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_SUCCESS,
  REMOVE_SELECTED_AUTOMATION_DIRECTORY_INDEX,
  SET_AUTOMATION_DIRECTORY_DATA_LOADING,
  SET_AUTOMATION_DIRECTORY_DATA_PAGE,
  SET_AUTOMATION_DIRECTORY_DATA_PER_PAGE,
  SET_AUTOMATION_DIRECTORY_DATA_SAVING,
  SET_AUTOMATION_DIRECTORY_DATA_SEARCH,
  SET_AUTOMATION_DIRECTORY_DATA_SELECTED_FILTERS,
  SET_AUTOMATION_DIRECTORY_DATA_SORT,
  SET_AUTOMATION_DIRECTORY_DATA_TABLE_COLUMNS_TO_DEFAULT,
  SET_AUTOMATION_DIRECTORY_DATA_TABLE_FILTERS_TO_DEFAULT,
  SET_AUTOMATION_DIRECTORY_DATA_TABLE_STATE_TO_DEFAULT,
  SET_AUTOMATION_DIRECTORY_POST_ERROR,
  MAKE_SCREEN_UNEDITABLE_FOR_ERLR,
} from './actionType'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function setDirectoryDataSelectedFilters(filters) {
  return {
    type: SET_AUTOMATION_DIRECTORY_DATA_SELECTED_FILTERS,
    payload: filters,
  }
}

export function setDirectoryColumnView(column, columnAction) {
  return {
    type: AUTOMATION_DIRECTORY_SET_DATA_COLUMN_VIEW,
    column: column,
    columnAction: columnAction,
  }
}

export function setDirectoryDataLoading(loading, lastSearchRequest, lastSelectedFilters) {
  return {
    type: SET_AUTOMATION_DIRECTORY_DATA_LOADING,
    payload: loading,
    search: lastSearchRequest,
    selectedFilters: lastSelectedFilters,
  }
}

export function setDirectoryDataSaving(isSaving) {
  return {
    type: SET_AUTOMATION_DIRECTORY_DATA_SAVING,
    isSaving: isSaving,
  }
}

export function setLeaderScreenUneditable(editable) {
  return {
    type: MAKE_SCREEN_UNEDITABLE_FOR_ERLR,
    editable: editable,
  }
}

export function setDirectoryDataSearch(search) {
  return {
    type: SET_AUTOMATION_DIRECTORY_DATA_SEARCH,
    payload: search,
  }
}

export function setDirectoryDataSort(sort) {
  return {
    type: SET_AUTOMATION_DIRECTORY_DATA_SORT,
    payload: sort,
  }
}

export function setDirectoryDataPage(page) {
  return {
    type: SET_AUTOMATION_DIRECTORY_DATA_PAGE,
    payload: page,
  }
}

export function setDirectoryDataPerPage(perPage) {
  return {
    type: SET_AUTOMATION_DIRECTORY_DATA_PER_PAGE,
    payload: perPage,
  }
}

export function setDirectoryDataTableStateToDefault() {
  return {
    type: SET_AUTOMATION_DIRECTORY_DATA_TABLE_STATE_TO_DEFAULT,
  }
}

export function setDirectoryDataTableFiltersToDefault() {
  return {
    type: SET_AUTOMATION_DIRECTORY_DATA_TABLE_FILTERS_TO_DEFAULT,
  }
}

export function setDirectoryDataTableColumnsToDefault() {
  return {
    type: SET_AUTOMATION_DIRECTORY_DATA_TABLE_COLUMNS_TO_DEFAULT,
  }
}

export function loadGetWorkersDirectoryRequest(query_params) {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {}, // this is an issue with axios. empty data needed so Content-Type isn't removed from request
  }

  return (dispatch) => {
    let apiUrl = `${apiConfig.worker.url}` + '/worker_details' + query_params
    return axios
      .get(apiUrl, requestConfig)
      .then((response) => {
        dispatch(handleGetWorkersDirectorySuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetWorkersDirectoryError(response))
      })
  }
}

export function loadGetLeaderDirectoryFilters(locationId) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.configuration.url}` +
      '/team_member_enablement_filters' +
      '?location_id=' +
      locationId +
      '&feature=team_member_directory' +
      '&location_type=DC' +
      '&key=' +
      `${apiConfig.configuration.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetLeaderDirectoryFiltersSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetLeaderDirectoryFiltersError(response))
      })
  }
}

export function handleGetLeaderDirectoryFiltersSuccess(record) {
  return {
    type: GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_SUCCESS,
    record,
  }
}

export function handleGetLeaderDirectoryFiltersError(record) {
  return {
    type: GET_LEADER_AUTOMATION_DIRECTORY_FILTERS_REQUEST_ERROR,
    record,
  }
}

export function handleGetWorkersDirectorySuccess(record) {
  return {
    type: GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_SUCCESS,
    record,
  }
}

export function handleGetWorkersDirectoryError(record) {
  return {
    type: GET_WORKERS_AUTOMATION_DIRECTORY_PREFERENCES_REQUEST_ERROR,
    record,
  }
}

export function setSaveDialogOpen(isOpen) {
  return {
    type: AUTOMATION_DIRECTORY_SET_SAVE_DIALOG_OPEN,
    payload: isOpen,
  }
}

export function loadDirectoryPostRequest(requestBody) {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {}, // this is an issue with axios. empty data needed so Content-Type isn't removed from request
  }

  return (dispatch) => {
    let apiUrl = `${apiConfig.worker.url}` + '/worker_details?key=' + `${apiConfig.worker.key}`
    return axios
      .post(apiUrl, requestBody, requestConfig)
      .then((response) => {
        dispatch(handlePostRequestSuccess(response))
      })
      .catch((response) => {
        dispatch(handlePostRequestError(response))
      })
  }
}

export function handlePostRequestSuccess(record) {
  return {
    type: AUTOMATION_DIRECTORY_HANDLE_POST_SUCCESS,
    record,
  }
}

export function handlePostRequestError(record) {
  return {
    type: AUTOMATION_DIRECTORY_HANDLE_POST_ERROR,
    record,
  }
}

export function setDirectoryPostResponse(response) {
  return {
    type: AUTOMATION_DIRECTORY_SET_POST_RESPONSE,
    payload: response,
  }
}

export function setDirectoryPostError(error) {
  return {
    type: SET_AUTOMATION_DIRECTORY_POST_ERROR,
    error,
  }
}

export function addDirectorySessionData(row, col, newVal) {
  let updateInfo = {
    row: row,
    col:
      col === DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.proficient)
        ? DIRECTORY_COLUMNS_ENUM.proficient
        : col === DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_up_group)
          ? DIRECTORY_COLUMNS_ENUM.schedule_up_group
          : DIRECTORY_COLUMNS_ENUM.schedule_down_group,
    newVal: newVal,
  }
  return {
    type: ADD_AUTOMATION_DIRECTORY_SESSION_DATA,
    payload: updateInfo,
  }
}

export function addSelectedDirectoryIndex(rowIndex) {
  return {
    type: ADD_SELECTED_AUTOMATION_DIRECTORY_INDEXES,
    payload: rowIndex,
  }
}

export function removeSelectedDirectoryIndex(rowIndex) {
  return {
    type: REMOVE_SELECTED_AUTOMATION_DIRECTORY_INDEX,
    payload: rowIndex,
  }
}

export function downloadDirectoryCSV(queryParams) {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'text/csv',
    },
    data: {}, // this is an issue with axios. empty data needed so Content-Type isn't removed from request
  }

  return (dispatch) => {
    let apiUrl = `${apiConfig.worker.url}` + '/worker_details' + queryParams + '&key=' + apiConfig.worker.key

    return axios
      .get(apiUrl, requestConfig)
      .then((response) => {
        return dispatch(handleDirectoryCSVSuccess(response))
      })
      .catch((response) => {
        return dispatch(handleDirectoryCSVError(response))
      })
  }
}

function handleDirectoryCSVSuccess(record) {
  return {
    type: DOWNLOAD_AUTOMATION_DIRECTORY_CSV_SUCCESS,
    record,
  }
}

function handleDirectoryCSVError(record) {
  return {
    type: DOWNLOAD_AUTOMATION_DIRECTORY_CSV_ERROR,
    record,
  }
}
