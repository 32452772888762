import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { format, isAfter, isBefore, isValid } from 'date-fns'
import { useState } from 'react'

const DatePicker = ({
  name,
  label,
  defaultDate,
  defaultCalendarMonth = new Date(),
  minDate,
  maxDate,
  disabled,
  onBlurMessage,
  handleDateChange,
  textFieldSx,
}) => {
  const [value, setValue] = useState(isValid(defaultDate) ? defaultDate : null)
  const [currentError, setCurrentError] = useState(null)
  const [errorDate, setErrorDate] = useState(false)
  const [touched, setTouched] = useState(false)

  const errorReasonToMessageMap = new Map([
    ['invalidDate', 'Invalid date format'],
    [
      'minDate',
      `Date should not be before ${minDate instanceof Date && !isNaN(minDate) ? format(minDate, 'P') : 'min date'}`,
    ],
    [
      'maxDate',
      `Date should not be after ${maxDate instanceof Date && !isNaN(maxDate) ? format(maxDate, 'P') : 'max date'}`,
    ],
  ])

  const handleOnChange = (newValue) => {
    setValue(newValue)
    if (isValid(newValue) && !isAfter(newValue, maxDate) && !isBefore(newValue, minDate)) {
      handleDateChange(newValue)
    }
  }

  const handleOnError = (reason) => {
    if (reason) {
      setCurrentError(errorReasonToMessageMap.get(reason))
      setErrorDate(true)
      handleDateChange(null)
    } else {
      setCurrentError(null)
      setErrorDate(false)
    }
  }

  return (
    <>
      <MuiDatePicker
        label={label}
        defaultValue={value}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        onChange={handleOnChange}
        onError={handleOnError}
        defaultCalendarMonth={defaultCalendarMonth}
        closeOnSelect={true}
        slotProps={{
          textField: (props) => ({
            ...props,
            error: errorDate,
            helperText: !onBlurMessage || touched ? currentError : null,
            onBlur: () => setTouched(true),
            sx: textFieldSx,
            'data-cy': name,
          }),
        }}
      />
    </>
  )
}

export default DatePicker
