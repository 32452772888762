import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import { FormControlLabel, FormGroup, Grid, IconButton, Popover, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { grey } from '@mui/material/colors'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleUsePTO } from '../../store/requestTimeOff/actionCreator'
import { createCypressDate } from '../../utils/DateUtil'

export default function PTOCheckbox({ dateKey, index }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'help-popover' : undefined

  const dispatch = useDispatch()
  const requestDay = useSelector((store) => store.requestTimeOff.request[dateKey])

  const onChange = (event, checked) => {
    dispatch(toggleUsePTO(dateKey, index))
  }

  return (
    <Grid item container xs alignItems={'center'}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={requestDay.requestDetails[index].usePTO}
              checked={requestDay.requestDetails[index].usePTO}
              aria-label="Use Paid Time Off Checkbox"
              onChange={(index) => onChange(requestDay.date, index)}
              size="small"
              data-cy={`usePTOToggle_${createCypressDate(new Date(dateKey))}_${index}`}
            />
          }
          label={<small>Use some of my accrued PTO time</small>}
        />
      </FormGroup>
      <IconButton
        aria-label="help icon"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        sx={{ marginLeft: '-.8em' }}
      >
        <HelpRoundedIcon sx={{ fontSize: 20, color: grey[700] }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{ style: { width: '55%', maxWidth: '250px' } }}
      >
        <Typography sx={{ p: 1 }}>You accrue hours as you work.</Typography>
        <Typography sx={{ p: 1 }}>You can check your current balance in Workday</Typography>
      </Popover>
    </Grid>
  )
}
