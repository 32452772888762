import {
  ACCEPTER_SWAP_SHIFT_REQUEST_POST_ERROR,
  ACCEPTER_SWAP_SHIFT_REQUEST_POST_SUCCESS,
  CLEAR_ACCEPTER_REQUEST,
  CLEAR_INITIATE_POST,
  CLEAR_SHIFTS_RESULTS,
  GET_SHIFTS_ERROR,
  GET_SHIFTS_SUCCESS,
  GET_TEAM_MEMBER_SEARCH_RESULTS_ERROR,
  GET_TEAM_MEMBER_SEARCH_RESULTS_SUCCESS,
  INITIATE_SWAP_SHIFT_REQUEST_ERROR,
  INITIATE_SWAP_SHIFT_REQUEST_SUCCESS,
  STORE_REQUESTER_CURRENT_SHIFT,
  STORE_REVIEWER_CURRENT_SHIFT,
} from './actionType'

const initialState = {
  isLoading: 'N',
  currentShiftForRequestor: {},
  shiftsForReview: {},
  shiftsForReviewError: null,
  shiftsForSwap: null,
  shiftsForSwapError: null,
  teamMembers: {},
  currentShiftForReviewer: {},
  requestToSwapPostResponse: null,
  requestToSwapErrResponse: null,
  accepterSwapPostResponse: null,
  accepterSwapErrResponse: null,
}

export default function swapShift(state = initialState, action = {}) {
  switch (action.type) {
    // case SET_REQUESTS_LOADING: {
    //   return {
    //     ...state,
    //     isLoading: action.data,
    //   }
    // }
    case GET_SHIFTS_SUCCESS: {
      const { record } = action
      return {
        ...state,
        shiftsForSwap: record?.data,
        shiftsForSwapError: null,
      }
    }
    case GET_SHIFTS_ERROR: {
      const { record } = action
      return {
        ...state,
        shiftsForSwap: null,
        shiftsForSwapError: record.response ? record.response.data : record,
      }
    }
    case CLEAR_SHIFTS_RESULTS: {
      return {
        ...state,
        shiftsForSwap: null,
        shiftsForSwapError: null,
      }
    }

    case INITIATE_SWAP_SHIFT_REQUEST_SUCCESS: {
      const { record } = action
      return {
        ...state,
        requestToSwapPostResponse: record.data,
        loading: 'N',
        requestToSwapErrResponse: null,
      }
    }
    case INITIATE_SWAP_SHIFT_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        requestToSwapErrResponse: record.response ? record.response.data : record,
        requestToSwapPostResponse: null,
        loading: 'N',
      }
    }

    case CLEAR_INITIATE_POST: {
      return {
        ...state,
        requestToSwapPostResponse: null,
        requestToSwapErrResponse: null,
      }
    }

    /* Accepter POST Call Starts here*/
    case ACCEPTER_SWAP_SHIFT_REQUEST_POST_SUCCESS: {
      const { record } = action
      return {
        ...state,
        accepterSwapPostResponse: record.data,
        loading: 'N',
        accepterSwapErrResponse: null,
      }
    }
    case ACCEPTER_SWAP_SHIFT_REQUEST_POST_ERROR: {
      const { record } = action
      return {
        ...state,
        accepterSwapErrResponse: record.response ? record.response.data : record,
        accepterSwapPostResponse: null,
        loading: 'N',
      }
    }

    case CLEAR_ACCEPTER_REQUEST: {
      return {
        ...state,
        accepterSwapErrResponse: null,
        accepterSwapPostResponse: null,
      }
    }

    /* Accepter POST Call Ends here*/

    case GET_TEAM_MEMBER_SEARCH_RESULTS_SUCCESS: {
      const { record } = action
      return {
        ...state,
        teamMembers: record?.data,
      }
    }
    case GET_TEAM_MEMBER_SEARCH_RESULTS_ERROR: {
      const { record } = action
      return {
        ...state,
        teamMembers: record.response ? record.response.data : record,
      }
    }
    case STORE_REQUESTER_CURRENT_SHIFT: {
      const { currentShift } = action
      return {
        ...state,
        currentShiftForRequestor: currentShift,
      }
    }
    case STORE_REVIEWER_CURRENT_SHIFT: {
      const { currentShift } = action
      return {
        ...state,
        currentShiftForReviewer: currentShift,
      }
    }

    default:
      return state
  }
}
