import axios from 'axios'
import { format } from 'date-fns'
import apiConfig from '../../config/apiConfig'
import { POST_DEVICE_REGISTRATION_ERROR, POST_DEVICE_REGISTRATION_SUCCESS } from './actionType'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function handlePostDeviceRegistrationError(record) {
  return {
    type: POST_DEVICE_REGISTRATION_ERROR,
    record,
  }
}

export function handlePostDeviceRegistrationSuccess(record) {
  return {
    type: POST_DEVICE_REGISTRATION_SUCCESS,
    record,
  }
}

export function postDeviceRegistration(deviceDetails, tmNumber, locationId) {
  const payload = {
    device_version: deviceDetails.deviceVersion,
    device_os_version: deviceDetails.deviceOsVersion,
    user_id: tmNumber,
    device_id: deviceDetails.deviceId,
    device_type: deviceDetails.deviceType,
    roles: [],
    app_name: 'mytime_v2',
    app_token: deviceDetails.deviceToken,
    app_version: '1.0.2',
    app_identifier: 'com.target.mytime',
    location_id: locationId,
    work_area_ids: [0],
  }

  return (dispatch) => {
    let apiUrl = `${apiConfig.device.url}` + '/' + '?key=' + `${apiConfig.device.key}`

    return axios
      .post(apiUrl, payload, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handlePostDeviceRegistrationSuccess(response))
      })
      .catch((response) => {
        dispatch(handlePostDeviceRegistrationError(response))
      })
  }
}

export function handleUserActions(actionName, actionType, tmNumber, pnId, deviceType, deviceVersion, deviceOsVersion) {
  const dateFormat = 'dd/MM/yyyy HH:mm'
  let actionDate = format(new Date(), dateFormat)

  const payload = {
    person_number: tmNumber,
    action_name: actionName,
    action_types: actionType,
    date_time: actionDate,
    pn_id: pnId,
    device_type: deviceType,
    device_version: deviceVersion,
    device_os_version: deviceOsVersion,
  }

  return (dispatch) => {
    let apiUrl = `${apiConfig.pnAck.url}` + '/acknowledgements' + '?key=' + `${apiConfig.pnAck.key}`

    return axios.post(apiUrl, payload, BASIC_REQUEST_CONFIG)
  }
}
