import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import usePostRequest from '../../../api/requests/usePostRequest'
import { TIME_OFF } from '../../../constants/RequestConstants'
import {
  clearRequest,
  setCommentError,
  setDayError,
  setRequestHasErrors,
} from '../../../store/requestTimeOff/actionCreator'
import { convertTimeOffRequest } from '../../../utils/requests'
import RequestCancelModal from '../RequestCancelModal'
import SubmitModal from './SubmitModal'

export default function RequestFooter(props) {
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
  const [totalMinutes, setTotalMinutes] = useState(null)
  const [totalPTO, setTotalPTO] = useState(null)
  const [totalUnpaid, setTotalUnpaid] = useState(null)
  const [requestDetails, setRequestDetails] = useState()
  const dispatch = useDispatch()
  const requestConfig = useSelector((store) => store.requestTimeOff.requestConfig)
  const commentError = useSelector((store) => store.requestTimeOff.commentError)
  const startDate = useSelector((store) => store.requestTimeOff.startDate)
  const endDate = useSelector((store) => store.requestTimeOff.endDate)
  const request = useSelector((store) => store.requestTimeOff.request)
  const comment = useSelector((store) => store.requestTimeOff.comment)
  const requestHasErrors = useSelector((store) => store.requestTimeOff.requestHasErrors)
  const navigate = useNavigate()
  const requests = usePostRequest()

  useEffect(() => {
    function hasErrors(state) {
      // Check if there's a comment level error
      if (commentError) {
        return true
      }

      // Check if there are any day level errors
      for (let date in request) {
        if (request[date].error) {
          return true
        }

        // Check if there are any errors in request details
        for (let detail of request[date].requestDetails) {
          if (detail.error.minutes || detail.error.startTime) {
            return true
          }
          // unpaid only
          if (detail.usePTO && (detail?.ptoDetails?.error.minutes || detail?.ptoDetails?.error.startTime)) {
            return true
          }
        }
      }

      return false
    }
    dispatch(setRequestHasErrors(hasErrors()))
  }, [request, commentError, dispatch])

  const handleYesForCancelAction = () => {
    setCancelDialogOpen(false)
    navigate(-1)
    dispatch(clearRequest())
  }

  const handleNoForCancelAction = () => {
    setCancelDialogOpen(false)
  }

  const handleYesForSubmitAction = () => {
    setSubmitDialogOpen(false)
    handleSubmitRequest()
  }

  const handleNoForSubmitAction = () => {
    setSubmitDialogOpen(false)
  }

  const handleSubmitRequest = () => {
    requests.mutate({ requestDetails, comment: comment })
  }

  const handleSubmit = () => {
    let formattedRequest = {}

    switch (requestConfig.requestType) {
      case TIME_OFF:
        formattedRequest = convertTimeOffRequest(request, requestConfig.content?.commentRequiredFor || [], comment)
        break
      default:
    }
    // if has errors, show them. else popup dialog with summary
    if (formattedRequest.commentError || formattedRequest.dayErrors.size) {
      formattedRequest.dayErrors.forEach((errorMessage, dateKey) => {
        dispatch(setDayError(dateKey, errorMessage))
      })
      if (formattedRequest.commentError) {
        dispatch(setCommentError(formattedRequest.commentError))
      }
    } else {
      setSubmitDialogOpen(true)
      setTotalMinutes(formattedRequest.totalMinutes)
      setTotalPTO(formattedRequest.totalPTOMinutes)
      setTotalUnpaid(formattedRequest.totalUnpaidMinutes)
      setRequestDetails(formattedRequest.requestDetails)
    }
  }

  return (
    <>
      <Button
        data-cy="requestTimeOffCancel"
        color="primary"
        sx={{ marginRight: '20px' }}
        onClick={() => setCancelDialogOpen(true)}
      >
        Cancel
      </Button>
      <Button
        data-cy="requestTimeOffSubmit"
        variant="contained"
        type="submit"
        aria-haspopup="true"
        color="primary"
        onClick={handleSubmit}
        disabled={requestHasErrors || !startDate || !endDate}
        aria-disabled={requestHasErrors || !startDate || !endDate}
      >
        Submit
      </Button>
      <RequestCancelModal
        cancelDialogOpen={cancelDialogOpen}
        handleYesForCancelAction={handleYesForCancelAction}
        handleNoForCancelAction={handleNoForCancelAction}
      />
      <SubmitModal
        submitDialogOpen={submitDialogOpen}
        handleYesForSubmitAction={handleYesForSubmitAction}
        handleNoForSubmitAction={handleNoForSubmitAction}
        totalMinutes={totalMinutes}
        showAccrualMessage={true}
        totalPTOMinutes={totalPTO}
        totalUnpaidMinutes={totalUnpaid}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  )
}
