import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
})

declare global {
  interface Window {
    Cypress?: Cypress.Cypress
  }
}
