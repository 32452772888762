import { MoreHoriz } from '@mui/icons-material'
import { Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ADD_ROW,
  MAX_ROWS_PER_DAY_STORES,
  MIN_ROWS_PER_DAY,
  REMOVE_ROW,
  requestRowVariants,
} from '../../../constants/RequestConstants'
import { addDetail, removeDetail } from '../../../store/requestTimeOff/actionCreator'
import { createCypressDate } from '../../../utils/DateUtil'
import Loader from '../../Loader/Loader'
import AllDayToggle from '../AllDayToggle'
import TimeOffPTORow from './RequestRows/TimeOffPTORow'
import { TimeOffUnpaidRow } from './RequestRows/TimeOffUnpaidRow'

export default function RequestRow({ dateKey }) {
  let date = new Date(dateKey) // JS uses the YYYY-MM-DD format
  let formattedDate = format(date, 'eeee, MMMM d') // date-fns uses eeee for day of the week
  const allowUnpaid = useSelector((store) => store.requestTimeOff.request[dateKey].config?.allowUnpaid)
  const rowError = useSelector((store) => store.requestTimeOff.request[dateKey].error)

  return (
    <>
      <Grid container direction={'column'} paddingLeft={1}>
        <Grid item container paddingY={1} direction={'row'} alignItems={'center'}>
          <Typography
            fontWeight={'bold'}
            fontSize={'medium'}
            component={'h3'}
            data-cy={`requestRowDate_${formattedDate}`}
          >
            {formattedDate}
          </Typography>
          {allowUnpaid ? <small>&nbsp; - Defaults to Unpaid</small> : <></>}
        </Grid>
        {rowError ? (
          <Grid item marginBottom={1}>
            <Typography color={'primary'} data-cy="requestRowError">
              {rowError}
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
        {allowUnpaid ? (
          <Grid item marginBottom={1} data-cy="requestAllDayToggle">
            <AllDayToggle dateKey={dateKey} />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item data-cy="requestRowList">
          <RowVariant dateKey={dateKey} />
        </Grid>
      </Grid>
      <Divider light aria-hidden="true" />
    </>
  )
}

function RowVariant({ dateKey }) {
  const requestVariant = useSelector((store) => store.requestTimeOff?.request[dateKey]?.config?.variant)

  switch (requestVariant) {
    case requestRowVariants.TIME_OFF_UNPAID:
      return <TimeOffUnpaidRow key={dateKey} dateKey={dateKey} />
    case requestRowVariants.TIME_OFF_PTO:
      return <TimeOffPTORow key={dateKey} dateKey={dateKey} />
    default:
      return <RequestFallback />
  }
}

const RequestFallback = () => {
  return <Loader />
}

export const MoreRowActions = ({ rowDetailIndex, numberOfDetailRows, dateKey }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event) => {
    if (event.target.textContent === ADD_ROW) {
      dispatch(addDetail(dateKey))
    } else if (event.target.textContent === REMOVE_ROW) {
      dispatch(removeDetail(dateKey, rowDetailIndex))
    }
    setAnchorEl(null)
  }

  const getMenuOptionsArray = () => {
    let moreOptions = []

    if (numberOfDetailRows > MIN_ROWS_PER_DAY) {
      moreOptions.push(REMOVE_ROW)
    }
    if (numberOfDetailRows < MAX_ROWS_PER_DAY_STORES) {
      moreOptions.push(ADD_ROW)
    }
    return moreOptions
  }

  return (
    <>
      <IconButton
        // data-cy={`requestTimeOffDetail_rowsButton_${requestDate}_${requestDetailPerDateIndex}`}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="Manage row"
        onClick={handleClick}
        color="primary"
        size="large"
        data-cy={`requestTimeOffDetail_rowsButton_${createCypressDate(new Date(dateKey))}_${rowDetailIndex}`}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        // data-cy={`requestTimeOffDetail_rowsMenu_${requestDate}_${requestDetailPerDateIndex}`}
        id="long-menu"
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        {getMenuOptionsArray().map((option) => (
          <MenuItem key={`${dateKey}_${rowDetailIndex}`} value={option} onClick={(event) => handleMenuItemClick(event)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
