import axios from 'axios'
import { format } from 'date-fns'
import apiConfig from '../../config/apiConfig'
import { toIsoStringWithoutTime } from '../../utils/DateUtil'
import {
  ADD_DETAIL,
  CHANGE_PTO_VALUE,
  CHANGE_UNPAID_VALUE,
  CLEAR_PREVIOUS_STATE,
  CLEAR_REQUEST,
  INITIALIZE_REQUEST_CONFIG,
  POST_REQUESTS_DATA_ERROR,
  POST_REQUESTS_DATA_SUCCESS,
  REMOVE_DETAIL,
  SET_COMMENT,
  SET_COMMENT_ERROR,
  SET_DAY_ERROR,
  SET_REQUEST_HAS_ERRORS,
  SET_USE_PTO_DETAILS,
  TOGGLE_ALL_DAY_UNPAID,
  TOGGLE_USE_PTO,
  UPDATE_REQUEST_DATE_RANGE,
} from './actionType'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function postRequestsData(user, timeOffDetails, comment) {
  const requestBody = {
    worker_id: user.userData?.worker_id,
    location: user.locationData?.location_id,
    request_details: createDetails(timeOffDetails),
    comment: comment,
  }

  if (!/\S/.test(comment)) {
    // comment is empty or only contains whitespace
    delete requestBody.comment
  }

  return (dispatch) => {
    let apiUrl = `${apiConfig.timeOff.url}` + '/time_off_requests' + '?key=' + `${apiConfig.timeOff.key}`

    return axios
      .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handlePostRequestsSuccess(response))
      })
      .catch((err) => {
        dispatch(handlePostRequestsError(err))
      })
  }
}

function createDetails(timeOffDetails) {
  let timeOffList = []
  for (let [key, value] of timeOffDetails) {
    for (let details of value) {
      let date = new Date(key)
      let startDateTime = toIsoStringWithoutTime(date) + 'T' + format(details.startTime, 'HH:mm') + ':00'
      timeOffList.push({
        type: details.type,
        start_time: startDateTime,
        minutes: details.hours * 60 + details.minutes,
        is_partial_approved: false, // hard coded for now. this field is not in mvp
      })
    }
  }
  return timeOffList
}

function handlePostRequestsSuccess(data) {
  return {
    type: POST_REQUESTS_DATA_SUCCESS,
    data,
  }
}

function handlePostRequestsError(data) {
  return {
    type: POST_REQUESTS_DATA_ERROR,
    data,
  }
}

export function clearPreviousState() {
  return {
    type: CLEAR_PREVIOUS_STATE,
  }
}

export function updateRequestDateRange(start, end) {
  return { type: UPDATE_REQUEST_DATE_RANGE, start, end }
}

export const changeValue = (date, index, field, value) => {
  return {
    type: CHANGE_UNPAID_VALUE,
    date,
    index,
    field,
    value,
  }
}

export const addDetail = (date) => ({
  type: ADD_DETAIL,
  date,
})

export const removeDetail = (date, index) => ({
  type: REMOVE_DETAIL,
  date,
  index,
})

export const toggleAllDayUnpaid = (date) => ({
  type: TOGGLE_ALL_DAY_UNPAID,
  date,
})

export const toggleUsePTO = (date, index) => ({
  type: TOGGLE_USE_PTO,
  date,
  index,
})

export function setUsePTODetails(date, index, field, value) {
  return {
    type: SET_USE_PTO_DETAILS,
    date,
    index,
    field,
    value,
  }
}

export const setComment = (comment) => ({
  type: SET_COMMENT,
  comment,
})

export const initializeRequestConfig = (configFunctionality, configResponseData) => ({
  type: INITIALIZE_REQUEST_CONFIG,
  configFunctionality,
  configResponseData,
})

export const clearRequest = () => ({
  type: CLEAR_REQUEST,
})

export const changePTODetailValue = (date, index, field, value) => ({
  type: CHANGE_PTO_VALUE,
  date,
  index,
  field,
  value,
})

export const setDayError = (date, error) => ({
  type: SET_DAY_ERROR,
  date,
  error,
})

export const setCommentError = (error) => ({
  type: SET_COMMENT_ERROR,
  error,
})

export const setRequestHasErrors = (hasErrors) => ({
  type: SET_REQUEST_HAS_ERRORS,
  hasErrors,
})
