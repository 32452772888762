import { POST_DEVICE_REGISTRATION_ERROR, POST_DEVICE_REGISTRATION_SUCCESS, SET_DEVICE_DETAILS } from './actionType'

export const initialState = {
  postDeviceRegistrationResponse: null,
  postDeviceRegistrationError: null,
  deviceDetails: null,
  deviceRegistered: false,
}

export default function pushNotification(state = initialState, action = {}) {
  switch (action.type) {
    case POST_DEVICE_REGISTRATION_ERROR: {
      const { record } = action
      return {
        ...state,
        postDeviceRegistrationError: record.response ? record.response.data : record,
      }
    }
    case POST_DEVICE_REGISTRATION_SUCCESS: {
      const { record } = action
      return {
        ...state,
        postDeviceRegistrationResponse: record.data,
        postDeviceRegistrationError: null,
        deviceRegistered: true,
      }
    }
    case SET_DEVICE_DETAILS: {
      const { payload } = action
      return {
        ...state,
        deviceDetails: payload,
      }
    }
    default:
      return state
  }
}
