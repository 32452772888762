import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { toLower } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { setLeaderFilterConfig } from '../../store/leader/actionCreator'
import { showNotificationError } from '../../store/notification/actionCreator'
import { useLocationDetails } from '../location/useLocationDetails'
import { LOCATION_TYPE_OFFICE, LOCATION_TYPE_STORE } from '../../constants/locationConstants'
import { useUserFeatures } from '../userAccess/useUserFeatures'
import { OFFICE_MANAGER_USER_GROUP } from '../../utils/roles'

const getConfiguration = async (configFeatureName, locationId, locationType, role) => {
  // TODO: ADMIN DEFAULTS - if user is an admin, set to default store for now
  if (locationType === LOCATION_TYPE_OFFICE) {
    locationType = LOCATION_TYPE_STORE
    locationId = '2180'
  }

  let feature = configFeatureName

  if (feature === 'availability_partial_action' || feature === 'availability_read_only') {
    feature = 'availability'
  } else if (feature === 'punch_correction_partial_action' || feature === 'punch_correction_read_only') {
    feature = 'punch_correction'
  } else if (feature === 'time_off_partial_action' || feature === 'time_off_read_only') {
    feature = 'time_off'
  }

  if (feature === 'time_off') {
    feature = `${feature}_${toLower(locationType)}`
  }

  if (role === OFFICE_MANAGER_USER_GROUP) {
    feature = 'punch_correction_office'
  }

  let apiUrl =
    `${apiConfig.configuration.urlv2}` +
    '/team_member_enablement_filters' +
    '?location_id=' +
    locationId +
    '&location_type=' +
    locationType +
    '&feature=' +
    feature +
    '&key=' +
    `${apiConfig.configuration.key}`

  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

function getFiltersArray(data) {
  return data?.filters?.reduce((filterObject, filterValues) => {
    filterObject[filterValues.key] = { ...filterValues, selectedValues: [] }
    return filterObject
  }, {})
}

export function useLeaderFilterConfiguration() {
  const { isAuthenticated, login } = useAuth()
  const dispatch = useDispatch()
  const { data: locationDetails } = useLocationDetails()
  const selectedCategory = useSelector((state) => state.leader.selectedCategory)
  const configFeatureName = useSelector((state) => state.leader[selectedCategory]?.configFeatureName)
  const authenticated = isAuthenticated()
  const { data: userFeatures } = useUserFeatures()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: [
      'configuration',
      {
        configFeatureName: configFeatureName,
        locationId: locationDetails?.location_id,
        locationType: locationDetails?.location_type,
        userGroup: userFeatures?.user_group,
      },
    ],
    queryFn: () =>
      getConfiguration(
        configFeatureName,
        locationDetails?.location_id,
        locationDetails?.location_type,
        userFeatures?.user_group,
      ),
    staleTime: Infinity,
    enabled:
      authenticated &&
      !!locationDetails?.location_id &&
      !!locationDetails?.location_type &&
      !!configFeatureName &&
      !!userFeatures?.user_group,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    onSuccess: (response) => {
      let filterData = getFiltersArray(response?.data)
      dispatch(setLeaderFilterConfig(filterData))
    },
    onError: (error) => {
      const loggingInfo = {
        message: 'GET /team_member_enablement_filters response has an error.',
        url: window.location.href,
        error: {
          url: error?.config?.url,
          data: error?.response?.data,
          headers: error?.response?.headers,
          status: error?.response?.status,
          statusText: error?.response?.statusText,
          selectedCategory: selectedCategory,
          configFeatureName: configFeatureName,
          location: locationDetails?.location_id,
          userGroup: userFeatures?.user_group,
        },
      }
      const optionalLoggingOptions = {
        level: LogLevel.Error,
      }
      logEvent(loggingInfo, optionalLoggingOptions)
    },
    onSettled: (response, error) => {
      if (!response?.data && !!error) {
        dispatch(showNotificationError(true, 'Error loading filter configuration'))
      }
    },
  })
}
