// Requester
export const STORE_REQUESTER_CURRENT_SHIFT = 'STORE_REQUESTER_CURRENT_SHIFT'
export const GET_TEAM_MEMBER_SEARCH_RESULTS_SUCCESS = 'GET_TEAM_MEMBER_SEARCH_RESULTS_SUCCESS'
export const GET_TEAM_MEMBER_SEARCH_RESULTS_ERROR = 'GET_TEAM_MEMBER_SEARCH_RESULTS_ERROR'
export const GET_SHIFTS_SUCCESS = 'GET_SHIFTS_SUCCESS'
export const GET_SHIFTS_ERROR = 'GET_SHIFTS_ERROR'
export const INITIATE_SWAP_SHIFT_REQUEST_SUCCESS = 'INITIATE_SWAP_SHIFT_REQUEST_SUCCESS'
export const INITIATE_SWAP_SHIFT_REQUEST_ERROR = 'INITIATE_SWAP_SHIFT_REQUEST_ERROR'
export const CLEAR_SHIFTS_RESULTS = 'CLEAR_SHIFTS_RESULTS'
export const CLEAR_INITIATE_POST = 'CLEAR_INITIATE_POST'

// Reviewer or Accepter
export const STORE_REVIEWER_CURRENT_SHIFT = 'STORE_REVIEWER_CURRENT_SHIFT'
export const ACCEPTER_SWAP_SHIFT_REQUEST_POST_SUCCESS = 'ACCEPTER_SWAP_SHIFT_REQUEST_POST_SUCCESS'
export const ACCEPTER_SWAP_SHIFT_REQUEST_POST_ERROR = 'ACCEPTER_SWAP_SHIFT_REQUEST_POST_ERROR'
export const CLEAR_ACCEPTER_REQUEST = 'CLEAR_ACCEPTER_REQUEST'
