import { Grid, Typography } from '@mui/material'

const SwapShiftStatus = (props) => {
  const { styles, requestCount, message } = props

  return (
    <center>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" component="p">
            {requestCount}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={styles.nxtAvlTotal}>
          <Typography variant="body2">{`${message}`}</Typography>
        </Grid>
      </Grid>
    </center>
  )
}

export default SwapShiftStatus
