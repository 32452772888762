import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useState } from 'react'
import InnerLoader from '../Loader/InnerLoader'

const SAVE_DIALOG_TITLE = 'Are you sure you want to save?'

const SAVE_DIALOG_TEXT = 'I confirm that what I am submitting is accurate.'

const REMINDER_MESSAGE = (
  <span>
    <strong> {'Note: '} </strong>
    {'Ensure you have the time accrued.'}
  </span>
)

const RequestSubmitModal = ({
  submitDialogOpen,
  handleYesForSubmitAction,
  handleNoForSubmitAction,
  requestedHours,
  requestedMinutes,
  requestDateRange,
  showAccrualMessage,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleYes = () => {
    if (buttonDisabled) return
    setButtonDisabled(true)
    handleYesForSubmitAction()
    setTimeout(() => {
      setButtonDisabled(false)
    }, 1000)
  }

  const handleNo = () => {
    handleNoForSubmitAction()
  }

  let hourText = `${requestedHours} hour `
  let hoursText = `${requestedHours} hours `
  let minuteText = `${requestedMinutes} minutes `

  let requestLengthText = `${requestedHours === 0 ? '' : requestedHours === 1 ? hourText : hoursText} ${
    requestedMinutes === 0 ? '' : minuteText
  }`

  return (
    <Dialog
      data-cy="requestTimeOffSubmitDialog"
      aria-labelledby="alert-dialog-title"
      open={submitDialogOpen}
      aria-describedby="save-dialog-content"
      aria-modal={true}
      keepMounted
    >
      <DialogTitle id="alert-dialog-title">{SAVE_DIALOG_TITLE}</DialogTitle>
      <DialogContent id="save-dialog-content" aria-label={`Requesting ${requestLengthText} on ${requestDateRange}`}>
        <DialogContentText id="save-dialog-date-title" sx={{ fontWeight: 600 }} color={'textPrimary'}>
          {'Requesting'}
        </DialogContentText>
        <DialogContentText id="save-dialog-dates">{requestDateRange}</DialogContentText>
        <DialogContentText id="save-dialog-length-title" sx={{ fontWeight: 600 }} color={'textPrimary'}>
          {'Request Length'}
        </DialogContentText>
        <DialogContentText id="save-dialog-length">{requestLengthText}</DialogContentText>
        <DialogContentText id="save-dialog-description" sx={{ marginTop: '20px' }}>
          {SAVE_DIALOG_TEXT}
        </DialogContentText>
        {showAccrualMessage ? (
          <DialogContentText id="save-dialog-reminder">{REMINDER_MESSAGE}</DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo} color="primary">
          NO
        </Button>
        <Button onClick={handleYes} color="primary" variant="contained" aria-label="Yes" disabled={buttonDisabled}>
          {buttonDisabled ? <InnerLoader size={25} /> : 'YES'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RequestSubmitModal
