import { TablePagination } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setPage, setRowsPerPage } from '../../../../store/leader/actionCreator'

const PaginationBar = () => {
  // const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'))
  const dispatch = useDispatch()

  const selectedCategory = useSelector((store) => store.leader.selectedCategory)
  const page = useSelector((store) => store.leader[selectedCategory]?.page)
  const rowsPerPage = useSelector((store) => store.leader[selectedCategory]?.rowsPerPage)
  const totalCount = useSelector((store) => store.leader[selectedCategory]?.totalCount)

  const handleChangePage = (event, page) => {
    dispatch(setPage(page + 1))
  }

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(event.target.value))
  }

  return (
    <TablePagination
      data-cy={'TableContent-pagination'}
      component="div"
      count={Number(totalCount) || 0}
      page={page - 1 || 0}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage || 25}
      onRowsPerPageChange={handleChangeRowsPerPage}
      color={'primary'}
      sx={{
        '& .MuiInputBase-root': {
          marginLeft: '1px',
          marginRight: '5px',
          minWidth: '56px',
        },
        '& .MuiTablePagination-actions': {
          marginLeft: '2px !important',
        },
        '& .MuiToolbar-root': {
          paddingLeft: '5px',
        },
        '& .MuiTablePagination-displayedRows': {
          width: '110px !important',
        },
      }}
    />
  )
}
export default PaginationBar
