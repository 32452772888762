import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useDispatch, useSelector } from 'react-redux'
import { showNotificationSuccess } from '../../store/notification/actionCreator'
import { Fade, Snackbar, useTheme } from '@mui/material'

const getStyles = (theme) => ({
  container: {
    position: 'absolute',
    margin: '0 auto',
    maxWidth: '640px',
    bottom: '50px !important',
    [theme.breakpoints.down('md')]: {
      bottom: '60px !important',
    },
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#ffffff',
  },
  icon: {
    marginRight: '8px',
    color: '#43a047',
  },
  closeButton: {
    color: '#ffffff',
  },
})

const NotificationSuccess = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const notificationIsShown = useSelector((state) => state.notification.isShownSuccess)
  const notificationMessage = useSelector((state) => state.notification.messageSuccess)
  const dispatch = useDispatch()

  const handleRequestClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(showNotificationSuccess(false))
  }

  return (
    <Snackbar
      data-cy="notificationSuccess"
      open={notificationIsShown}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={
        <span style={styles.message}>
          <CheckCircleIcon sx={styles.icon} />
          {notificationMessage}
        </span>
      }
      onClose={handleRequestClose}
      autoHideDuration={4000}
      TransitionComponent={Fade}
      sx={styles.container}
    />
  )
}

export default NotificationSuccess
