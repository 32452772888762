import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../../store/layout/actionCreator'

const HeaderTitle = ({ title }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setHeaderTitle(title))
  }, [title, dispatch])

  // This component does not render anything.
  return null
}

export default HeaderTitle
