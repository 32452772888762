import UnApproveIcon from '@mui/icons-material/Close'
import ScheduleIcon from '@mui/icons-material/Today'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Slide,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import Box from '@mui/material/Box'
import { DesktopTimePicker } from '@mui/x-date-pickers'
import { addDays, format } from 'date-fns'
import moment from 'moment'
import { useAuth } from '@praxis/component-auth'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setEndTime,
  setMandatoryDataSaving,
  setMandatoryPostResponse,
  setMandatoryPostResponseIneligibilities,
  setSaveMandatoryDialogOpen,
  setShiftPreference,
  setShouldLoadMandatoryData,
  setStartTime,
  updateMandatoryLeaderDecisions,
} from '../../store/leaderViewMandatoryAutomation/actionCreator'
import { toIsoStringWithoutTime } from '../../utils/DateUtil'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import DatePicker from '../common/calendar/DatePicker'
import {
  getAboutToSaveText,
  getHowSaveAffectsMandatoryText,
  getIfChooseYesText,
  getPart1MessageArray,
  getPart1Title,
  getPart2MessageArray,
  getPart2Title,
  render2PartDialogContent,
  renderDivider,
  renderRuleViolationPanels,
  renderUnableToSaveDownActionFromNoSchedule,
  renderUnableToSaveFromStatusDialogContent,
} from './SaveDialogAutomation'

const SAVE_DIALOG_TITLE = 'Are you sure you want to save?'

const LEADER_MANDATORY_STATUS_SCHEDULED = 'Scheduled'
const LEADER_MANDATORY_STATUS_NONE = 'None'
const PAYCODE_ACTION_CREATE = 'create'
const SHIFT_SEGMENT_TYPE_REGULAR = 'Regular'
const ISO_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const UP_DOWN_STATE_DOWN = 'down'
const UP_DOWN_STATE_UP = 'up'
const SCHEDULE = 'schedule'
const UN_SCHEDULE = 'unSchedule'
const DOWN_SHIFT_TYPE = ['Down - Full', 'Down - Half Early', 'Down - Half Late']
let UP_SHIFT_TYPE = ['Up - Full', 'Up - Half Early', 'Up - Half Late']

const getStyles = (theme) => ({
  // iconButton: theme.muiTableIconButton,
  muiTableIconButton: {
    marginRight: '1.5em',
    top: '50%',
    display: 'inline-block',
    position: 'relative',
  },
  textFieldDropDown: {
    width: 160,
    marginRight: '1.5em',
    position: 'relative',
  },
  textField: {
    marginRight: '1.5em',
    width: '8em',
  },
  inputSwitch: {
    marginRight: '1.5em',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarRightContainer: {
    alignContent: 'center',
    display: 'flex',
  },
  rulesViolatedTitle: {
    paddingLeft: '24px',
    paddingTop: '16px',
    color: theme.palette.primary.dark,
  },
  savingText: {
    color: theme.palette.tertiary.contrastText,
    paddingTop: '8px',
    fontSize: '12px',
  },
  loadingIconContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const MandatoryCustomToolbarAutomation = (props) => {
  const { selectedDate, upDownState, tableData, selectedRows, selectedLocationId } = props

  const { session } = useAuth()
  const dispatch = useDispatch()
  const theme = useTheme()
  const styles = getStyles(theme)
  const [startDate, setStartDate] = useState(format(new Date(selectedDate), 'yyyy-MM-dd'))
  const [startDateError, setStartDateError] = useState(false)
  const mandatoryData = useSelector((state) => state.leaderViewMandatoryAutomation.mandatoryData)
  const upShiftType = useSelector((state) => state.leaderViewMandatoryAutomation.upShiftType)
  const downShiftType = useSelector((state) => state.leaderViewMandatoryAutomation.downShiftType)
  const startTime = useSelector((state) => state.leaderViewMandatoryAutomation.startTime)
  const endTime = useSelector((state) => state.leaderViewMandatoryAutomation.endTime)
  const mandatorySaveDialogOpen = useSelector((state) => state.leaderViewMandatoryAutomation.mandatorySaveDialogOpen)
  const mandatoryPostResponseIneligibilities = useSelector(
    (state) => state.leaderViewMandatoryAutomation.mandatoryPostResponseIneligibilities,
  )
  const mandatoryDataSaving = useSelector((state) => state.leaderViewMandatoryAutomation.mandatoryDataSaving)
  const locationDetails = useSelector((state) => state.leaderViewAutomation.locationDetails)

  const handleOverrideSchedule = () => {
    sendRequest(LEADER_MANDATORY_STATUS_SCHEDULED)
  }

  const handleSchedule = () => {
    sendRequest(LEADER_MANDATORY_STATUS_SCHEDULED)
  }

  const handleUnSchedule = () => {
    sendRequest(LEADER_MANDATORY_STATUS_NONE)
  }

  const handleScheduleButton = () => {
    handleOpeningSaveDialog(SCHEDULE)
  }

  const handleUnScheduleButton = () => {
    handleOpeningSaveDialog(UN_SCHEDULE)
  }

  const handleOpeningSaveDialog = (saveButtonClicked) => {
    dispatch(setSaveMandatoryDialogOpen(saveButtonClicked))
  }

  const handleClosingSaveDialog = () => {
    dispatch(setSaveMandatoryDialogOpen(null))
    if (mandatoryPostResponseIneligibilities) {
      dispatch(setMandatoryPostResponse(null))
      dispatch(setMandatoryPostResponseIneligibilities(null))
      dispatch(setShouldLoadMandatoryData(true))
    }
  }

  const handleSaveDialogYesButton = () => {
    if (mandatorySaveDialogOpen === SCHEDULE) {
      if (mandatoryPostResponseIneligibilities) {
        handleOverrideSchedule()
      } else {
        handleSchedule()
      }
    } else if (mandatorySaveDialogOpen === UN_SCHEDULE) {
      handleUnSchedule()
    }
  }

  const sendRequest = (submissionStatus) => {
    let requestBody = buildBody(submissionStatus)
    dispatch(updateMandatoryLeaderDecisions(requestBody))
    dispatch(setMandatoryDataSaving(true))
  }

  const handlePreference = (preference) => {
    dispatch(setShiftPreference(preference, upDownState))
  }

  const handleStartDate = (startDate) => {
    setStartDateError(!startDate)
    setStartDate(moment(startDate).format('YYYY-MM-DD'))
  }

  const handleStartTime = (startTime) => {
    dispatch(setStartTime(startTime))
  }

  const handleEndTime = (endTime) => {
    dispatch(setEndTime(endTime))
  }

  const buildBody = (submissionStatus) => {
    let teamMemberIds = []
    let mandatorySchedules = []
    selectedRows.data.forEach((el) => {
      if (
        !mandatoryPostResponseIneligibilities ||
        mandatoryPostResponseIneligibilities.has(tableData[el.index].worker_id)
      ) {
        teamMemberIds.push(tableData[el.index].worker_id)
        let schedule = {
          worker_id: tableData[el.index].worker_id,
          has_schedule: !!tableData[el.index].schedule.length,
          early_in_rounding_rule: tableData[el.index].early_in_rounding_rule,
        }
        mandatorySchedules.push(schedule)
      }
    })

    let paycode = null
    let shiftSegment = null
    if (submissionStatus === LEADER_MANDATORY_STATUS_SCHEDULED) {
      paycode = buildPaycode()
      shiftSegment = buildShiftSegment()
    }

    let validateRules = false

    if (upDownState === 'up' && !mandatoryPostResponseIneligibilities) {
      validateRules = true
    }

    let requestBody = {
      location_id: selectedLocationId,
      is_rule_validation_applicable: validateRules,
      worker_ids: teamMemberIds,
      total_worker_ids: teamMemberIds.length,
      requester_id: padEmpIdWithZeros(session?.userInfo?.empid, 10),
      mandatory_type: upDownState,
      status: submissionStatus,
      schedule_date: toIsoStringWithoutTime(selectedDate),
      mandatory_shift: upDownState === 'up' ? upShiftType : downShiftType,
      paycode: paycode,
      shift_segment: shiftSegment,
      total_mandatory_schedules: mandatorySchedules.length,
      mandatory_schedules: mandatorySchedules,
    }

    // removing JSON values that are null from request
    if (!requestBody.paycode) {
      delete requestBody.paycode
    }

    if (!requestBody.shift_segment) {
      delete requestBody.shift_segment
    }

    return requestBody
  }

  const buildPaycode = () => {
    // paycode is only valid in down requests
    if (upDownState === UP_DOWN_STATE_UP) {
      return null
    }

    // type can be string or a double, based on the isInputTypeTime value
    let start = moment.tz(startDate + ' ' + moment(startTime).format('HH:mm'), locationDetails.iso_time_zone_code)
    let end = moment.tz(startDate + ' ' + moment(endTime).format('HH:mm'), locationDetails.iso_time_zone_code)

    if (start.isAfter(end)) {
      end.add(1, 'days')
    }

    let duration = moment.duration(end.diff(start))
    let hoursDifference = parseFloat(duration.asHours())

    let type = hoursDifference.toString()
    start = start?.format(ISO_DATE_FORMAT)

    // paycode action is always create
    return {
      action: PAYCODE_ACTION_CREATE,
      shift_type: type,
      start_timestamp: start,
    }
  }

  const buildShiftSegment = () => {
    // shift segment is only valid in up requests
    if (upDownState === UP_DOWN_STATE_DOWN) {
      return null
    }

    const segmentStart = moment.tz(
      startDate + ' ' + moment(startTime).format('HH:mm'),
      locationDetails.iso_time_zone_code,
    )
    const segmentEnd = moment.tz(startDate + ' ' + moment(endTime).format('HH:mm'), locationDetails.iso_time_zone_code)

    // if the hour selection of end time is before the start, its assumed to be overnight
    if (segmentEnd.hour() <= segmentStart.hour()) {
      segmentEnd.add(1, 'days')
    }

    // for now - regular is the only shift type option
    return {
      shift_segment_start: segmentStart?.format(ISO_DATE_FORMAT),
      shift_segment_end: segmentEnd?.format(ISO_DATE_FORMAT),
      shift_segment_type: SHIFT_SEGMENT_TYPE_REGULAR,
    }
  }

  const renderInput = () => {
    return (
      <Box sx={styles.inputContainer}>
        {renderPreferenceSelect()}
        {renderStartDate()}
        {renderStartTime()}
        {renderEndTime()}
      </Box>
    )
  }

  const renderPreferenceSelect = () => {
    let shiftTypes = upDownState === 'up' ? UP_SHIFT_TYPE : DOWN_SHIFT_TYPE
    let shiftType = upDownState === 'up' ? upShiftType : downShiftType
    return (
      <TextField
        id="mandatory_preference_select"
        label="Shift Type"
        select
        onChange={(event) => handlePreference(event.target.value)}
        value={shiftType}
        sx={styles.textFieldDropDown}
      >
        {shiftTypes.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  const renderStartDate = () => {
    if (upDownState === UP_DOWN_STATE_UP) {
      return <></>
    }
    return (
      <DatePicker
        label="Start Date"
        defaultDate={selectedDate}
        minDate={new Date(selectedDate)}
        maxDate={addDays(new Date(selectedDate), 1)}
        handleDateChange={handleStartDate}
        textFieldSx={styles.textField}
      />
    )
  }

  const renderStartTime = () => {
    return (
      <Tooltip title={'Start time for proposed shift'}>
        <DesktopTimePicker
          id="startTime"
          label="Start Time"
          value={startTime}
          onChange={(startTime) => handleStartTime(startTime)}
          minutesStep={5}
          closeOnSelect={true}
          slotProps={{
            textField: (props) => ({
              ...props,
              id: 'startTime',
              name: 'startTime',
              sx: styles.textField,
              'data-cy': 'mandatoryStartTime',
            }),
          }}
        />
      </Tooltip>
    )
  }

  const renderEndTime = () => {
    return (
      <Tooltip title={'End time for proposed shift'}>
        <DesktopTimePicker
          id="endTime"
          label="End Time"
          value={endTime}
          onChange={(endTime) => handleEndTime(endTime)}
          minutesStep={5}
          closeOnSelect={true}
          slotProps={{
            textField: (props) => ({
              ...props,
              id: 'endTime',
              name: 'endTime',
              sx: styles.textField,
              'data-cy': 'mandatoryEndTime',
            }),
          }}
        />
      </Tooltip>
    )
  }

  const renderUnScheduleButton = () => {
    return (
      <Tooltip title={'Un-Schedule'}>
        <IconButton sx={styles.iconButton} onClick={handleUnScheduleButton}>
          <UnApproveIcon color="primary" />
        </IconButton>
      </Tooltip>
    )
  }

  const renderScheduleButton = () => {
    return (
      <Tooltip title={'Schedule'}>
        <IconButton sx={styles.muiTableIconButton} disabled={startDateError} onClick={handleScheduleButton}>
          <ScheduleIcon color={startDateError ? 'disabled' : 'primary'} />
        </IconButton>
      </Tooltip>
    )
  }

  const renderSpinner = () => {
    return (
      <React.Fragment>
        <CircularProgress size={36} />{' '}
        <Box component={'span'} sx={styles.savingText}>
          SAVING
        </Box>
      </React.Fragment>
    )
  }

  const renderScheduleDialogContent = () => {
    const outputTimeFormat = 'hh:mmA'
    const outputDateFormat = 'dddd, MMMM D'
    let selectedDateString = moment(selectedDate)?.format(outputDateFormat)
    let shiftStartDate = moment(startDate).format(outputDateFormat)
    let startTimeString = moment(startTime)?.format(outputTimeFormat)
    let endTimeString = moment(endTime)?.format(outputTimeFormat)

    let scheduleText1 = getAboutToSaveText('mandatory', upDownState, selectedRows.data.length, selectedLocationId)
    let scheduleText2 = getHowSaveAffectsMandatoryText(
      upDownState,
      selectedLocationId,
      selectedDateString,
      startTimeString,
      endTimeString,
      shiftStartDate,
    )

    return (
      <React.Fragment>
        <DialogTitle id="save-dialog-slide-title">{SAVE_DIALOG_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>{scheduleText1}</DialogContentText>
          <DialogContentText gutterBottom>{scheduleText2}</DialogContentText>
        </DialogContent>
      </React.Fragment>
    )
  }

  const renderScheduleOverrideDialogContent = () => {
    const outputTimeFormat = 'hh:mmA'
    const outputDateFormat = 'dddd, MMMM D'
    let selectedDateString = moment(selectedDate)?.format(outputDateFormat)
    let shiftStartDate = moment(startDate).format(outputDateFormat)
    let startTimeString = moment(startTime)?.format(outputTimeFormat)
    let endTimeString = moment(endTime)?.format(outputTimeFormat)

    let workerIdToNameMap = new Map()
    selectedRows.data.forEach((row) => {
      let rowIndex = row.index
      workerIdToNameMap.set(mandatoryData[rowIndex].worker_id, mandatoryData[rowIndex].full_name)
    })
    let totalRuleViolatedWorkers = mandatoryPostResponseIneligibilities.size
    let totalSelected = selectedRows.data.length
    let totalSaved = totalSelected - mandatoryPostResponseIneligibilities.size

    let part1Title = getPart1Title(totalSaved, totalSelected)

    let part1MessageArray = getPart1MessageArray(totalRuleViolatedWorkers)

    let part2Title = getPart2Title(totalRuleViolatedWorkers)

    let part2Message1 = getAboutToSaveText('mandatory', upDownState, totalRuleViolatedWorkers, selectedLocationId)
    let part2Message2 = getHowSaveAffectsMandatoryText(
      upDownState,
      selectedLocationId,
      selectedDateString,
      startTimeString,
      endTimeString,
      shiftStartDate,
    )
    let part2Message3 = getIfChooseYesText(totalRuleViolatedWorkers)

    let part2MessageArray = getPart2MessageArray(part2Message1, part2Message2, part2Message3)

    let expandPanelsTitle = (
      <Typography variant="h6" component="p" sx={styles.rulesViolatedTitle}>
        Rules Violated:
      </Typography>
    )
    let expandPanels = renderRuleViolationPanels(mandatoryPostResponseIneligibilities, workerIdToNameMap)

    return render2PartDialogContent(
      part1Title,
      part1MessageArray,
      part2Title,
      part2MessageArray,
      expandPanelsTitle,
      expandPanels,
    )
  }

  const renderUnScheduleDialogContent = () => {
    const outputDateFormat = 'dddd, MMMM D'

    let scheduleText2 =
      'This action will only change the status column. It will not revert the last ' +
      upDownState +
      ' date value. You will need to manually update these schedules in myTime/Kronos.'

    let scheduleText1 =
      'You are about to un-schedule ' +
      selectedRows.data.length +
      ' team member(s) for location ' +
      selectedLocationId +
      ' on ' +
      moment(selectedDate)?.format(outputDateFormat) +
      '.'

    return (
      <DialogContent>
        <DialogContentText gutterBottom>{scheduleText1}</DialogContentText>
        <DialogContentText gutterBottom>{scheduleText2}</DialogContentText>
      </DialogContent>
    )
  }

  const renderButtonsOrSpinnerDialog = () => {
    let spinner
    let buttons
    if (mandatoryDataSaving) {
      spinner = renderSpinner()
    } else {
      buttons = (
        <React.Fragment>
          <Button onClick={handleClosingSaveDialog} color="primary">
            NO
          </Button>
          <Button onClick={handleSaveDialogYesButton} color="primary">
            YES
          </Button>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <DialogContent sx={styles.loadingIconContainer}>{spinner}</DialogContent>
        <DialogActions>{buttons}</DialogActions>
      </React.Fragment>
    )
  }

  const getNamesWithIncompatibleStatus = () => {
    let failedTmNames = []
    selectedRows.data.forEach((value) => {
      if (mandatoryData[value.index].status === 'Scheduled') {
        failedTmNames.push(mandatoryData[value.index].full_name)
      }
    })
    return failedTmNames
  }

  const getNamesWithNoSchedules = () => {
    let failedTmNames = []
    selectedRows.data.forEach((value) => {
      if (!mandatoryData[value.index].schedule.length) {
        failedTmNames.push(mandatoryData[value.index].full_name)
      }
    })
    return failedTmNames
  }

  const renderUnableToSaveDialog = (incompatibleStatusTmNames, noScheduleTmNames) => {
    const statusTmLength = incompatibleStatusTmNames.length
    const noScheduleTmLength = noScheduleTmNames.length

    return (
      <Dialog
        open={!!mandatorySaveDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClosingSaveDialog(event, reason)
          }
        }}
      >
        <DialogTitle id="failed-validation-dialog-slide-title">{'Unable to save'}</DialogTitle>
        {renderUnableToSaveFromStatusDialogContent('mandatory', statusTmLength)}
        {statusTmLength && noScheduleTmLength ? renderDivider() : null}
        {renderUnableToSaveDownActionFromNoSchedule('mandatory', noScheduleTmLength)}
        <DialogActions>
          <Button onClick={handleClosingSaveDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderConfirmSaveDialog = () => {
    let dialogOpenFunction

    if (mandatorySaveDialogOpen === SCHEDULE) {
      if (mandatoryPostResponseIneligibilities) {
        dialogOpenFunction = renderScheduleOverrideDialogContent
      } else {
        dialogOpenFunction = renderScheduleDialogContent
      }
    } else if (mandatorySaveDialogOpen === UN_SCHEDULE) {
      dialogOpenFunction = renderUnScheduleDialogContent
    }

    return (
      <Dialog
        open={!!mandatorySaveDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClosingSaveDialog(event, reason)
          }
        }}
      >
        {dialogOpenFunction()}
        {renderButtonsOrSpinnerDialog()}
      </Dialog>
    )
  }

  const renderSaveDialog = () => {
    let unableToSaveDialog
    if (upDownState === 'down' && mandatorySaveDialogOpen === SCHEDULE) {
      let incompatibleStatusTmNames = getNamesWithIncompatibleStatus()
      let noScheduleTmNames = getNamesWithNoSchedules()

      if (incompatibleStatusTmNames.length || noScheduleTmNames.length) {
        unableToSaveDialog = renderUnableToSaveDialog(incompatibleStatusTmNames, noScheduleTmNames)
      }
    }

    return unableToSaveDialog ? unableToSaveDialog : renderConfirmSaveDialog()
  }

  return (
    <Box sx={styles.toolbarRightContainer}>
      {renderInput()}
      {renderUnScheduleButton()}
      {renderScheduleButton()}
      {mandatorySaveDialogOpen ? renderSaveDialog() : null}
    </Box>
  )
}

export default MandatoryCustomToolbarAutomation
