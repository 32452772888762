import SaveIcon from '@mui/icons-material/Save'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Tooltip,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadDirectoryPostRequest,
  setDirectoryDataSaving,
  setSaveDialogOpen,
} from '../../store/leaderViewDirectoryAutomation/actionCreator'

const SAVE_DIALOG_TITLE = 'Are you sure you want to save?'
const SAVE_DIALOG_TEXT = 'Do you want to save your pending directory changes?'

const getStyles = (theme) => ({
  iconButton: theme.muiTableIconButton,
  muiTableIconButton: {
    marginRight: '24px',
    top: '50%',
    display: 'inline-block',
    position: 'relative',
  },
  icon: theme.muiTableIcon,
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const TmDirectoryCustomToolbarAutomation = (props) => {
  const { setDisplayLoadingIcon } = props
  const theme = useTheme()
  const styles = getStyles(theme)
  const dispatch = useDispatch()
  const directorySessionData = useSelector((state) => state.leaderViewDirectoryAutomation.directorySessionData)
  const saveDialogOpen = useSelector((state) => state.leaderViewDirectoryAutomation.saveDialogOpen)
  const selectedDirectoryIndexes = useSelector((state) => state.leaderViewDirectoryAutomation.selectedDirectoryIndexes)

  // Makes post call for selected rows
  const handlePostCall = () => {
    let rowsToSave = getSpecificSessionDataRows(selectedDirectoryIndexes)
    let formatRows = JSON.stringify(formatPostRows(rowsToSave))
    dispatch(loadDirectoryPostRequest(formatRows))
    dispatch(setDirectoryDataSaving(true))
    dispatch(setDisplayLoadingIcon(true))
  }

  const handleOpeningSaveDialog = () => {
    dispatch(setSaveDialogOpen(true))
  }

  const handleClosingSaveDialog = () => {
    dispatch(setSaveDialogOpen(false))
  }

  const handleSaveDialogYesButton = () => {
    dispatch(setSaveDialogOpen(false))
    handlePostCall()
  }

  const formatPostRows = (rows) => {
    return {
      total_worker_details: rows.length,
      worker_details: rows.map((element) => {
        return {
          proficient: element.proficient,
          schedule_down_group: element.schedule_down_group,
          schedule_up_group: element.schedule_up_group,
          status: 'only for Response',
          worker_id: element.worker_id,
        }
      }),
    }
  }

  /* Based off of the array of indexes, build an array of worker rows to post
   Uses input instead of selectedDirectoryIndexes so that in future "save unselected changes" could be implemented*/
  const getSpecificSessionDataRows = (indexes) => {
    let specificRows = []
    for (let i = 0; i < indexes.length; i++) {
      specificRows.push(directorySessionData[indexes[i]])
    }
    return specificRows
  }

  const renderSaveButton = () => {
    return (
      <Tooltip title={'Save'}>
        <IconButton sx={styles.muiTableIconButton} onClick={handleOpeningSaveDialog}>
          <SaveIcon sx={styles.icon} />
        </IconButton>
      </Tooltip>
    )
  }

  const renderSaveDialog = () => {
    return (
      <Dialog open={saveDialogOpen} TransitionComponent={Transition} keepMounted onClose={handleClosingSaveDialog}>
        <DialogTitle id="save-dialog-slide-title">{SAVE_DIALOG_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText id="save-dialog-slide-description">{SAVE_DIALOG_TEXT}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosingSaveDialog} color="primary">
            NO
          </Button>
          <Button onClick={handleSaveDialogYesButton} color="primary">
            YES
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div>
      {renderSaveButton()}
      {renderSaveDialog()}
    </div>
  )
}

export default TmDirectoryCustomToolbarAutomation
