import { useRequestConfig } from '../api/configuration/useRequestConfig'
import InnerLoader from '../components/Loader/InnerLoader'
import RequestPaidTimeOff from '../components/Requests/PaidTimeOff/RequestPaidTimeOff'
import TimeOffRequest from '../components/Requests/TimeOff/TimeOffRequest'
import { LOCATION_TYPE_OFFICE, LOCATION_TYPE_RGD, LOCATION_TYPE_STORE } from '../constants/locationConstants'
import { useUser } from '../auth/hooks/useUser'

export default function RequestTimeOff() {
  const { locationData } = useUser().user
  const requestConfig = useRequestConfig('time_off')

  return (
    <>
      {requestConfig?.isLoading ? (
        <InnerLoader />
      ) : [LOCATION_TYPE_STORE, LOCATION_TYPE_OFFICE, LOCATION_TYPE_RGD].includes(locationData.location_type) ? (
        <TimeOffRequest />
      ) : (
        <RequestPaidTimeOff />
      )}
    </>
  )
}
