import { useAuth } from '@praxis/component-auth'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import {
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
  RULES_VIOLATION_ERROR,
  SHIFT_NO_LONGER_AVAIL_MSG,
  SUCCESS_ON_SAVE,
  UNABLE_TO_SAVE,
} from '../../constants/errorConstants'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { formatErrorCode, parseRuleViolation } from '../../utils/ErrorHandling'

const coverShift = async (shift, workerId) => {
  const requestBody = {
    worker_id: workerId,
    segment_start: shift.shift_start,
    segment_end: shift.shift_end,
    org_ids_id: shift.org_ids_id,
    generated_by: shift.generated_by,
    available_shift_id: shift.available_shift_id,
    requester_worker_id: shift.requester_worker_id,
    jobs: shift.jobs,
  }

  let apiUrl =
    `${apiConfig.availableShifts.url}` + '/available_shifts/assignment' + '?key=' + `${apiConfig.availableShifts.key}`

  const { data } = await axios.post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)

  return data
}

/**
 * this hook returns a react query hook that has function mutate in it. use mutate(shift) to make the POST api call
 * @param locationIds query key options to be invalidated
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
export const useCoverShift = (locationIds) => {
  const queryClient = useQueryClient()
  const { session, isAuthenticated, login } = useAuth()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  const dispatch = useDispatch()

  return useMutation((shift) => coverShift(shift, padEmpIdWithZeros(session?.userInfo?.empid, 10)), {
    onSuccess: (data, variables, context) => {
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
      // this will refetch existing queries in cache that match query key. not explicit.
      queryClient.invalidateQueries(['availableShifts', { locationIds: locationIds }])
    },
    onError: (error, variables, context) => {
      if (ERROR_CODES_SHOW_MESSAGE.includes(error?.response?.data.code)) {
        if (error?.response?.data.message === SHIFT_NO_LONGER_AVAIL_MSG) {
          queryClient.invalidateQueries(['availableShifts', { locationIds: locationIds }])
        }
        dispatch(showNotificationError(true, error?.response?.data.message))
      } else if (error?.response?.data.code && RULES_VIOLATION_ERROR.includes(error?.response?.data.code)) {
        dispatch(showNotificationError(true, parseRuleViolation(error?.response?.data)))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE + ' ' + formatErrorCode(error?.response?.data, ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
          ),
        )
      }
    },
  })
}
