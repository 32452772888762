import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'
import Button from '@mui/material/Button'
import { useState } from 'react'

const CopyToClipboard = (props) => {
  const [isCopied, setIsCopied] = useState(false)
  const { text, label, labelWidth } = props

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(text).then(() => {
      setIsCopied(true)
      setTimeout(() => {
        // set back to false after small amount of time
        setIsCopied(false)
      }, 3000)
    })
  }

  return (
    <FormControl fullWidth size="small" variant="standard">
      <InputLabel id="outlined-adornment-password">{label}</InputLabel>
      <Input
        value={text}
        labelId="label"
        id="ios-download-code"
        readOnly
        endAdornment={
          <InputAdornment position="end">
            <Button onClick={handleCopyClick} color="primary" size={'small'}>
              {isCopied ? 'Copied' : 'Copy'}
            </Button>
          </InputAdornment>
        }
        labelWidth={labelWidth}
      />
    </FormControl>
  )
}

export default CopyToClipboard
