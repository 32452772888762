import { Box, Grid, Typography, useTheme } from '@mui/material'

import MuiAccordionPanel from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { addDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { MultiClickButton } from '../../utils/MultiClickButton'
import AutoCompleteTME from '../FormElementsTME/AutoCompleteTME'
import CheckBoxTME from '../FormElementsTME/CheckBoxTME'
import DateTimePickerInDialogTME from '../FormElementsTME/DateTimePickerInDialogTME'

const getStyles = (theme) => ({
  hide: {
    display: 'block',
  },
  searchBtnContainer: {
    marginTop: '15px',
    textAlign: 'right',
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  errorMsg: {
    color: '#cc0000',
    textAlign: 'right',
    padding: '10px',
  },
  datetimepicker: {
    marginTop: '20px',
  },
  autocompletetext: {
    marginTop: '16px',
  },
  accordionPanel: {
    border: '1px solid rgba(0,0,0,.125)',
    background: 'rgba(242,238,238,0.78)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    border: '1px solid rgba(0,0,0,.125)',
    background: 'rgba(242,238,238,0.78)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  accordionDetails: {
    padding: theme.spacing(2),
  },
})

const FilterOptions = (props) => {
  const { ownShift, searchClicked, expand, toggleExpand } = props
  const theme = useTheme()
  const styles = getStyles(theme)

  const now = new Date()
  const twoHrsLaterFromNow = new Date(now.getTime() + 2 * 60 * 60 * 1000)
  const twoHrsLaterFromNowForEndTimeCalc = new Date(now.getTime() + 2 * 60 * 60 * 1000)
  const endOfDay = addDays(new Date(twoHrsLaterFromNowForEndTimeCalc.setHours(23, 59, 59)), 2)

  const [state, setState] = useState({
    location: ownShift.location || '',
    job: ownShift.jobs ? (ownShift.jobs.length ? ownShift.jobs : '') : '',
    shiftLength: '',
    startsAfterTime: twoHrsLaterFromNow,
    endsBeforeTime: endOfDay,
    selectedTmNumber: '',
  })

  const [expanded, setExpanded] = useState(expand ? 'panel1' : 'panel0')
  const [displayErrMsg, setDisplayErrMsg] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    setExpanded(expand ? 'panel1' : 'panel0')
  }, [expand])

  /* const onLocChange = (val) => {
    setState({ ...state, location: val === true ? ownShift.location : '' })
  } */

  const onJobChange = (val) => {
    setState({ ...state, job: val === true ? ownShift.jobs : '' })
  }

  const onLengthChange = (val) => {
    setState({ ...state, shiftLength: val === true ? ownShift.shift_hours : '' })
  }

  const onStartTimeChange = (val) => {
    setState({
      ...state,
      startsAfterTime: val,
    })
  }

  const onEndTimeChange = (val) => {
    setState({ ...state, endsBeforeTime: val })
  }

  const onTMSelection = (val) => {
    setState({ ...state, selectedTmNumber: val })
  }

  const handleSearchClick = () => {
    if (state.startsAfterTime > state.endsBeforeTime) {
      setDisplayErrMsg(true)
      setErrMsg('End time should be greater than start time')
    } else {
      setDisplayErrMsg(false)
      setErrMsg('')
      searchClicked(state)
    }
  }

  const handleExpandChange = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : 'panel0')
    toggleExpand(expanded)
  }

  return (
    <Box sx={styles.root}>
      <MuiAccordionPanel
        sx={styles.accordionPanel}
        expanded={expanded === 'panel1'}
        onChange={handleExpandChange('panel1')}
      >
        <MuiAccordionSummary sx={styles.accordionSummary} expandIcon={<ExpandMoreIcon data-cy="expand-more-icon" />}>
          <Typography sx={styles.heading}>Search Options</Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails sx={styles.accordionDetails}>
          <Box>
            <Grid container>
              <Grid item xs={5} md={7} sx={{ ...styles.displaySegmentContainer, ...styles.hide }}>
                <CheckBoxTME label="same job" checked={!!state.job} handleChange={(val) => onJobChange(val)} />
              </Grid>
              <Grid item xs={7} md={5} sx={{ ...styles.displaySegmentContainer, ...styles.hide }}>
                <CheckBoxTME
                  label="same shift length"
                  checked={!!state.shiftLength}
                  handleChange={(val) => onLengthChange(val)}
                />
              </Grid>
              <Grid item xs={12} md={5} sx={styles.datetimepicker}>
                <DateTimePickerInDialogTME
                  label="starts after"
                  dateTime={state.startsAfterTime}
                  handleTimeChange={(val) => onStartTimeChange(val)}
                />
              </Grid>
              <Grid item md={2} />
              <Grid item xs={12} md={5} sx={styles.datetimepicker}>
                <DateTimePickerInDialogTME
                  label="ends before"
                  dateTime={state.endsBeforeTime}
                  handleTimeChange={(val) => onEndTimeChange(val)}
                  readonly={false}
                />
              </Grid>

              <Grid item xs={12} sm={12} sx={styles.autocompletetext}>
                <AutoCompleteTME handleTMChange={(val) => onTMSelection(val)} />
              </Grid>
              {displayErrMsg && (
                <Grid item xs={12} sm={12}>
                  <Typography variant="caption" display="block" gutterBottom sx={styles.errorMsg}>
                    {errMsg}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={12} sx={styles.searchBtnContainer}>
                <MultiClickButton
                  label="Search Shifts"
                  handleClick={handleSearchClick}
                  handleDoubleClick={handleSearchClick}
                  styles={styles}
                  pageName="searchSwapShifts"
                />
              </Grid>
            </Grid>
          </Box>
        </MuiAccordionDetails>
      </MuiAccordionPanel>
    </Box>
  )
}

export default FilterOptions
