import { Paper, Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FORM_HAS_ERRORS_MESSAGE } from '../../../constants/RequestConstants'
import { setComment } from '../../../store/requestTimeOff/actionCreator'
import RequestCommentForm from '../RequestCommentForm'
import RequestRow from './RequestRow'

export default function RequestContent() {
  const requestDays = useSelector((store) => store.requestTimeOff.request)
  const request = useSelector((store) => store.requestTimeOff)
  const requestHasErrors = useSelector((store) => store.requestTimeOff.requestHasErrors)
  const commentAllowed = useSelector((store) => store.requestTimeOff?.requestConfig?.content?.commentAllowed)
  const dispatch = useDispatch()

  const requestErrorRef = useRef(null)

  useEffect(() => {
    if (requestHasErrors) {
      const requestErrorMessage = requestErrorRef.current
      requestErrorMessage.focus()
    }
  }, [requestHasErrors])

  const handleSetComment = (comment) => {
    dispatch(setComment(comment))
  }

  const requestKeys = Object.keys(requestDays)

  return (
    <>
      {requestHasErrors ? (
        <Typography ref={requestErrorRef} textAlign={'center'} paddingTop={1} color="primary" data-cy="requestError">
          {FORM_HAS_ERRORS_MESSAGE}
        </Typography>
      ) : (
        <></>
      )}
      <Paper square elevation={0} data-cy="requestList">
        {requestKeys.map((dateKey, i) => {
          return <RequestRow key={dateKey} dateKey={dateKey} />
        })}
      </Paper>
      {requestKeys.length > 0 && commentAllowed ? (
        <RequestCommentForm
          key={'Comment'}
          value={request.comment}
          setComment={handleSetComment}
          errorMessage={request?.commentError}
          label={request?.commentError ? 'Comment Required' : 'Comment'}
          maxLength={280}
        />
      ) : (
        <></>
      )}
    </>
  )
}
