import { Button, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import HomePageCard from '../common/HomePageCard'
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'
import moment from 'moment'
import { usePunchedInStatus } from '../../api/timecard/useTimeCardDaily'
import { NON_EXEMPT_USER_GROUPS } from '../../constants/userConstants'

const getStyles = (theme) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  gridItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '6px',
    marginBottom: '6px',
  },
  feedbackLeft: {
    alignSelf: 'center',
  },
})

function FeedbackSurveyCard() {
  const theme = useTheme()
  const styles = getStyles(theme)
  const surveyStart = moment.tz('2024-03-26', 'America/Chicago')
  const surveyEnd = moment.tz('2024-04-09', 'America/Chicago')
  const today = moment()
  const { punchedInStatus, isError, isFetching } = usePunchedInStatus(NON_EXEMPT_USER_GROUPS)

  let navigate = useNavigate()
  const goToSurveyPage = () => {
    let path = '/survey'
    navigate(path)
  }

  const renderFeedbackButton = () => {
    return (
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          size="small"
          data-cy="viewFeedbackBtn"
          role="link"
          target="_system"
          onClick={goToSurveyPage}
        >
          Start Survey
        </Button>
      </CardActions>
    )
  }

  const renderFeedbackData = () => {
    return (
      <Grid container>
        <Grid item xs={12} sx={styles.feedbackLeft}>
          <Typography>We want to hear from you!</Typography>
          <Typography>
            The user feedback survey on myTime for Target is voluntary and should take about 5 minutes to complete.
          </Typography>
        </Grid>
      </Grid>
    )
  }

  if (
    !today.isBefore(surveyStart) &&
    today.isBefore(surveyEnd) &&
    punchedInStatus.isPunchedIn &&
    !isFetching &&
    !isError
  ) {
    return (
      <Grid item xs={12} md={12} sx={styles.gridItem} data-cy="viewFeedbackCard">
        <HomePageCard title="Feedback" icon={<FeedbackOutlinedIcon />} cardActions={renderFeedbackButton}>
          {renderFeedbackData()}
        </HomePageCard>
      </Grid>
    )
  }
}

export default FeedbackSurveyCard
