import { Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import HeartIconCircle from '../components/Icons/HeartIconCircle'
import PostShiftIcon from '../components/Icons/PostShiftIcon'
import RetractTMAvailableShiftIcon from '../components/Icons/RetractTMAvailableShiftIcon'
import ShiftSwapIcon from '../components/Icons/ShiftSwapIcon'
import ShiftSwapMsgIconCircle from '../components/Icons/ShiftSwapMsgIconCircle'
import SwapRequestInitatedIcon from '../components/Icons/SwapRequestInitatedIcon'
import InnerLoader from '../components/Loader/InnerLoader'
import useClickPreventionOnDoubleClick from '../hooks/use-click-prevention-on-double-click'

const CustomButtonUsingHook = ({
  hasMissedPunch,
  label,
  styles,
  processIndicator,
  onClick,
  onDoubleClick,
  pageName,
  ariaLabel,
  cyId,
}) => {
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick)

  return (
    <>
      {!processIndicator && (
        <>
          {pageName === 'coverShift' && (
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              sx={styles.mobileButton}
              disabled={hasMissedPunch}
              data-cy={cyId}
            >
              {label}
            </Button>
          )}
          {pageName === 'postShift' && (
            <IconButton
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              sx={styles.postShiftButton}
              aria-label={ariaLabel}
              size="large"
              data-cy={cyId}
            >
              <PostShiftIcon />
            </IconButton>
          )}
          {pageName === 'retractShift' && (
            <IconButton
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              sx={styles.postShiftButton}
              aria-label={ariaLabel}
              size="large"
              data-cy={cyId}
            >
              <RetractTMAvailableShiftIcon />
            </IconButton>
          )}
          {pageName === 'postShiftConfirm' && (
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              sx={styles.mobileButton}
              data-cy={cyId}
            >
              {label}
            </Button>
          )}
          {pageName === 'shiftswapaccept' && (
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              sx={styles.mobileButton}
              data-cy="AcceptSwapButton"
            >
              {label}
            </Button>
          )}
          {pageName === 'shift-swap-search' && (
            <IconButton
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              sx={styles.postShiftButton}
              aria-label={ariaLabel}
              size="large"
              data-cy={cyId}
            >
              <ShiftSwapIcon />
            </IconButton>
          )}
          {pageName === 'shift-swap-message' && (
            <IconButton
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              sx={styles.shiftSwapMsgIconStyle}
              aria-label={ariaLabel}
              size="large"
            >
              <ShiftSwapMsgIconCircle />
            </IconButton>
          )}
          {pageName === 'shift-swap-withdraw' && (
            <IconButton
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              sx={styles.postShiftButton}
              aria-label={ariaLabel}
              size="large"
              data-cy={cyId}
            >
              <SwapRequestInitatedIcon />
            </IconButton>
          )}
          {pageName === 'searchSwapShifts' && (
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              sx={styles.mobileButton}
              data-cy="SearchShiftsForSwapButton"
            >
              {label}
            </Button>
          )}
          {pageName === 'shiftswapreview' && (
            <Button
              color="primary"
              size="small"
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              style={{
                fontSize: '10px',
                width: '70px',
                height: '40px',
                margin: '2px',
              }}
              data-cy="SwapShiftForReview"
              aria-label={ariaLabel}
            >
              <HeartIconCircle />
            </Button>
          )}
        </>
      )}

      {processIndicator && (
        <span style={styles.loaderStyles}>
          <InnerLoader size={25} />
        </span>
      )}
    </>
  )
}

export const MultiClickButton = (props) => (
  <CustomButtonUsingHook
    hasMissedPunch={props.hasMissedPunch}
    label={props.label}
    onClick={props.handleClick}
    onDoubleClick={props.handleDoubleClick}
    processIndicator={props.processIndicator}
    styles={props.styles}
    pageName={props.pageName}
    cyId={props.cyId}
  />
)
