import {
  CLEAR_USER_PREFERENCES_DATA,
  CLEAR_USER_PREFERENCES_RESPONSES,
  GET_USER_PREFERENCES_ERROR,
  GET_USER_PREFERENCES_SUCCESS,
  POST_USER_PREFERENCES_ERROR,
  POST_USER_PREFERENCES_SUCCESS,
  SET_USER_PREFERENCES_LOADING,
} from './actionTypes'

const initialState = {
  userPreferences: {},
  userPreferencesError: null,
  userPreferencesLoading: 'N',
  userPreferencesPostResponse: null,
  userPreferencesPostErrResponse: null,
}

export default function settings(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_PREFERENCES_SUCCESS: {
      const { data } = action
      return {
        ...state,
        userPreferences: data.data,
        userPreferencesLoading: 'N',
        userPreferencesError: null,
      }
    }
    case GET_USER_PREFERENCES_ERROR: {
      const { record } = action
      return {
        ...state,
        userPreferences: {},
        userPreferencesError: record?.response ? record.response.data : record,
        userPreferencesLoading: 'N',
      }
    }
    case SET_USER_PREFERENCES_LOADING: {
      const { text } = action
      return {
        ...state,
        loading: text,
      }
    }
    case CLEAR_USER_PREFERENCES_DATA: {
      return {
        ...state,
        userPreferences: {},
        userPreferencesError: null,
        userPreferencesLoading: 'Y',
      }
    }
    case POST_USER_PREFERENCES_SUCCESS: {
      const { data } = action
      return {
        ...state,
        userPreferencesPostResponse: data.data,
        userPreferencesLoading: 'N',
        userPreferencesPostErrResponse: null,
      }
    }
    case POST_USER_PREFERENCES_ERROR: {
      const { data } = action
      return {
        ...state,
        userPreferencesPostErrResponse: data.response ? data.response.data : data,
        userPreferencesPostResponse: null,
        userPreferencesLoading: 'N',
      }
    }
    case CLEAR_USER_PREFERENCES_RESPONSES: {
      return {
        ...state,
        userPreferencesPostResponse: null,
        userPreferencesPostErrResponse: null,
      }
    }
    default:
      return state
  }
}
