import { Button, Grid, TextField } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { usePostFeedbackResponse } from '../api/feedback/usePostFeedbackResponse'
import { useLocationDetails } from '../api/location/useLocationDetails'
import { useUser } from '../auth/hooks/useUser'
import StarRating from '../components/Feedback/StarRating'
import useFeedback, { FeedbackResponse } from '../components/Feedback/hooks/useFeedback'
import { useStarRating } from '../components/Feedback/hooks/useStarRating'
import HeaderTitle from '../components/Header/HeaderTitle'
import useConfirm from '../hooks/useConfirm'
import { showNotificationError, showNotificationSuccess } from '../store/notification/actionCreator'

const Feedback: React.FC = () => {
  const { isConfirmed } = useConfirm()
  const { rating, handleRating, ratingError, handleRatingError } = useStarRating()
  const { data, responses, handleResponse } = useFeedback()
  const { user } = useUser()
  const { data: locationDetails } = useLocationDetails()
  const dispatch = useDispatch()

  const handleConfirmSubmit = async () => {
    return await isConfirmed('Thank you for your feedback!')
  }

  const mutation = usePostFeedbackResponse()

  const handleSubmit = async () => {
    if (rating === 0) {
      handleRatingError(true)
      return
    }

    if (await handleConfirmSubmit()) {
      mutation.mutate(
        {
          worker_id: user.userData.worker_id,
          location_id: locationDetails?.location_id || '',
          persona: user.userData.persona,
          rating,
          responses: responses,
        },
        {
          onSuccess: () => {
            responses.forEach((res) => {
              handleResponse(res.question_id, '')
            })
            handleRating(0)
            dispatch(showNotificationSuccess(true, 'Success! Thank you for your feedback.'))
          },
          onError: (error: any) => {
            let message = ['1', '11']?.includes(error?.response?.data?.code?.split('-')?.[2])
              ? error?.response?.data?.message
              : 'There was a error submitting your feedback, please try again later.'
            dispatch(showNotificationError(true, message))
          },
        },
      )
    }
  }

  return (
    <Grid container spacing={2} padding={1} alignItems="center" paddingY={2}>
      <HeaderTitle title={'Feedback'} />
      <StarRating
        title={'How would you rate the myTime for Target app?'}
        rating={rating}
        ratingError={ratingError}
        handleRating={handleRating}
      />
      {data &&
        data?.questions.map((question) => (
          <Grid item xs={12} key={question.id}>
            <TextField
              label={`${question.question}`}
              name={`${question.question}`}
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={responses.find((f: FeedbackResponse) => f.question_id === question.id)?.feedback || ''}
              onChange={(e) => handleResponse(question.id, e.target.value)}
            />
          </Grid>
        ))}
      <Grid container item xs={12} justifyContent={'flex-end'}>
        <Button variant="contained" color="primary" type="submit" title="Submit Feedback" onClick={handleSubmit}>
          Submit Feedback
        </Button>
      </Grid>
    </Grid>
  )
}

export default Feedback
