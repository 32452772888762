import { Info } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsAdditionalDeviceStepsDialogOpen } from '../../store/workerRedemptionCode/actionCreator'

const getStyles = (theme) => ({
  infoIcon: {
    marginRight: '4px',
    fontSize: '12px',
  },
  dialogContent: { padding: '0 8px 8px' },
  icon: {
    marginRight: '8px',
    color: '#EED202',
    fontSize: '20px',
  },
  listItemRoot: {
    padding: '4px 16px 4px 16px',
  },
  subMsg: {
    color: theme.palette.tertiary.contrastText,
  },
})

const AdditionalDeviceStepsDialog = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const DIALOG_TITLE = 'Get myTime App On An Additional Apple/iOS Device'
  const SUMMARY_MSG =
    'Redemption codes for an iOS Application are associated with an ' +
    'Apple ID, so users with multiple Apple devices only need to redeem a code once and then ' +
    'download the application on each additional device.'

  const APPLE_ID_WARNING_MSG =
    'If you are attempting to download the app to another device linked to a different Apple ID than the previous device that you redeemed the code with, then you need to redo the process of getting issued a new code and redeeming it.'

  const INSTRUCTIONS_HEADER = 'Steps:'
  const INSTRUCTIONS_MSG_2 = '2) Tap on "Apps" and then "My Apps".'
  const INSTRUCTIONS_MSG_5 = '5) Tap on the "Open" button to start the myTime for Target app.'
  const INSTRUCTIONS_MSG_6 =
    '6) When the app starts, you will be prompted to login into ID2.0 and will be asked to "Allow" Notifications to be sent to you.'
  const INSTRUCTIONS_MSG_7 = 'Your myTime for Target app should now be up and running!'

  const EXIT_BTN_LABEL = 'Exit'

  const dispatch = useDispatch()

  const isAdditionalDeviceStepsDialogOpen = useSelector(
    (state) => state.workerRedemptionCode.isAdditionalDeviceStepsDialogOpen,
  )

  const handleExitButtonClick = () => {
    dispatch(setIsAdditionalDeviceStepsDialogOpen(false))
  }

  const handleClosingDialog = () => {
    dispatch(setIsAdditionalDeviceStepsDialogOpen(false))
  }

  const renderSummary = () => {
    return <Typography gutterBottom>{SUMMARY_MSG}</Typography>
  }

  const renderAppleIdWarning = () => {
    return (
      <Typography gutterBottom variant="caption">
        <Info sx={styles.infoIcon} />
        {APPLE_ID_WARNING_MSG}
      </Typography>
    )
  }

  const renderInstructionSteps = () => {
    return (
      <List dense>
        <Typography variant="subtitle2">{INSTRUCTIONS_HEADER}</Typography>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">
            1) Go to the Apple App Store using your additional device, and click on the "Person" icon in the upper
            right.{' '}
            <i style={styles.subMsg}>
              (If you have not logged in recently, you may be prompted to login into your Apple ID.)
            </i>
          </Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{INSTRUCTIONS_MSG_2}</Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">
            3) Toggle to "Not on this iPhone": "myTime for Target" (TM) should be listed.{' '}
          </Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">
            4) Tap on the Download icon.{' '}
            <i style={styles.subMsg}>
              (The Download icon will be replaced by a spinning circle icon as it downloads the application to the
              device, and then by a button labeled "Open" when the download is complete.)
            </i>
          </Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{INSTRUCTIONS_MSG_5}</Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{INSTRUCTIONS_MSG_6}</Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{INSTRUCTIONS_MSG_7}</Typography>
        </ListItem>
      </List>
    )
  }

  const renderDialogContent = () => {
    return (
      <React.Fragment>
        {renderSummary()}
        {renderAppleIdWarning()}
        {renderInstructionSteps()}
      </React.Fragment>
    )
  }
  const renderDialogActions = () => {
    return (
      <React.Fragment>
        <Button
          onClick={handleExitButtonClick}
          color="primary"
          variant="contained"
          data-cy="AdditionalDeviceStepsDialogExitButton"
        >
          {EXIT_BTN_LABEL}
        </Button>
      </React.Fragment>
    )
  }

  const renderDialogActionAndContent = () => {
    return (
      <React.Fragment>
        <DialogContent sx={styles.dialogContent}>{renderDialogContent()}</DialogContent>
        <DialogActions>{renderDialogActions()}</DialogActions>
      </React.Fragment>
    )
  }

  const renderDialog = () => {
    return (
      <Dialog
        open={isAdditionalDeviceStepsDialogOpen}
        data-cy="AdditionalDeviceStepsDialog"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClosingDialog(event, reason)
          }
        }}
        PaperProps={{
          sx: {
            margin: '8px',
          },
        }}
      >
        <DialogTitle id="save-dialog-slide-title">{DIALOG_TITLE}</DialogTitle>
        {renderDialogActionAndContent()}
      </Dialog>
    )
  }

  return renderDialog()
}

export default AdditionalDeviceStepsDialog
