import { Button, Grid, Link, Typography } from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const styles = {
  nxtAvlTotal: {
    marginTop: '8px',
  },
  nxtAvlBtnCls: {
    marginTop: '20px',
  },
}

const NextAvailableShift = (props) => {
  const { nextAvlShiftDate, avlShiftCount } = props
  const outputDateFormat = 'ddd, MMM D'

  let navigate = useNavigate()

  const handleLink = () => {
    navigate('/availableShifts?expand=first')
  }

  return (
    <center>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" component="p">
            {moment(nextAvlShiftDate)?.format(outputDateFormat)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={styles.nxtAvlTotal}>
          <Typography variant="body2">{`${avlShiftCount} available shifts`}</Typography>
        </Grid>
        {avlShiftCount ? (
          <Grid item xs={12} md={12} sx={styles.nxtAvlBtnCls}>
            <Link to="/availableShifts" style={{ textDecoration: 'none' }}>
              <Button color="primary" variant="contained" size="small" onClick={handleLink}>
                Next Avail Shift
              </Button>
            </Link>
          </Grid>
        ) : null}
      </Grid>
    </center>
  )
}

export default NextAvailableShift
