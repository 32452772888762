import { Grid } from '@mui/material'
import Chip from '@mui/material/Chip'
import { useDispatch, useSelector } from 'react-redux'
import { setFilters } from '../../../../store/leader/actionCreator'

const FilterChips = () => {
  const dispatch = useDispatch()
  const selectedCategory = useSelector((state) => state.leader.selectedCategory)
  const filters = useSelector((state) => state.leader[selectedCategory]?.filters)
  const categoryState = useSelector((state) => state.leader[selectedCategory])

  const deleteChip = (filterKey, valueToRemove) => {
    const newValues = [...categoryState.filters[filterKey].selectedValues].filter((value) => value !== valueToRemove)
    dispatch(setFilters(filterKey, newValues))
  }

  if (filters) {
    return Object.entries(filters).map(([filterKey, filterObject]) => {
      return filterObject.selectedValues?.map((value) => (
        <Grid item key={`${filterKey}_${value}`}>
          <Chip
            key={value}
            label={value}
            data-cy={`chip-${value}`}
            onDelete={() => {
              deleteChip(filterKey, value)
            }}
            sx={{ marginTop: '2px', marginRight: '2px' }}
            aria-label={`remove ${value} filter`}
          />
        </Grid>
      ))
    })
  }
  return <></>
}

export default FilterChips
