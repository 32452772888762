import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { addWeeks, endOfWeek, intervalToDuration, startOfWeek } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  AVAILABILITY_CALENDAR_MAX_FUTURE_WEEKS,
  FIXED_END_DATE_FROM_UKG,
} from '../../../constants/AvailabilityConstants'
import {
  clearPreviousState,
  setAvailabilityForSingleWeek,
  setAvailabilityPatternId,
  setAvailabilityRequestEndDate,
  setAvailabilityRequestStartDate,
  setAvailabilityRequestType,
} from '../../../store/availability/actionCreator'
import { setBackToComponent } from '../../../store/header/actionCreator'
import { handleSetRequestType } from '../../../store/requests/actionCreator'
import { getDateOfTodayWithNoTimestamp } from '../../../utils/DateUtil'
import HeaderTitle from '../../Header/HeaderTitle'
import AvailabilityDatePicker from './AvailabilityDatePicker'

const setDateToMidnight = (date) => {
  date.setHours(0, 0, 0, 0)
  return date
}

const PAGE_TITLE = 'Update Availability'
const CREATE_AVAILABILITY_PAGE = '/team-member/availability/create-new-availability'
const AVAILABILITY_HOME = '/team-member/availability'

const getStyles = (theme) => ({
  mainScrollContainer: {
    ...theme.mainScrollContainer,
    overflowY: 'auto',
    height: '100%',
  },
  mainContainerPosition: theme.mainContainerPosition,
  pageStyles: {
    padding: '15px',
  },
  sectionStyles: {
    marginBottom: 4,
  },
  headingText: {
    fontWeight: 'bold',
    color: 'black',
  },
  formControl: {
    // width: 250,
    height: 30,
    marginBottom: '8px',
  },
  bottomButtons: {
    margin: '2px 10px',
  },
  gridContainer: {
    marginTop: '10px',
  },
  actionControlsContainer: {
    bottom: 60,
    position: 'absolute',
    width: '100%',
    borderTop: `1px solid ${theme.palette.tertiary.main}`,
    maxWidth: 640,
    textAlign: 'right',
    backgroundColor: '#FFF',
  },
  action: {
    display: 'inline-block',
    padding: '0.7rem 0.7rem',
    verticalAlign: 'middle',
  },
  linkCls: {
    color: '#cc0000',
    fontSize: 14,
  },
  disableLinkCls: {
    color: '#999',
    fontSize: 14,
    textDecoration: 'none',
  },
  mobileButton: {
    maxWidth: 120,
  },
})

const RequestAvailability = () => {
  const defaultStartDate = useSelector((state) => state.availability.availabilityReqStartDate)
  const defaultEndDate = setDateToMidnight(endOfWeek(defaultStartDate))
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [minEndDate, setMinEndDate] = useState(defaultEndDate)

  const maxStart = startOfWeek(addWeeks(getDateOfTodayWithNoTimestamp(), AVAILABILITY_CALENDAR_MAX_FUTURE_WEEKS - 1))
  const maxEnd = setDateToMidnight(
    endOfWeek(addWeeks(getDateOfTodayWithNoTimestamp(), AVAILABILITY_CALENDAR_MAX_FUTURE_WEEKS - 1)),
  )
  const [availabilityType, setAvailabilityType] = useState('Core')
  const [continueBtnDisabled, setContinueBtnDisabled] = useState(false)

  let navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const styles = getStyles(theme)
  useEffect(() => {
    dispatch(setBackToComponent(AVAILABILITY_HOME))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setEndDate(setDateToMidnight(endOfWeek(startDate)))
  }, [startDate])

  useEffect(() => {
    dispatch(setAvailabilityForSingleWeek(false))
    dispatch(clearPreviousState())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRadioChange = (event) => {
    setAvailabilityType(event.target.value)
    dispatch(setAvailabilityPatternId(event.target.id))
  }

  const reqStartDateChanged = (date) => {
    // checkRules(date, 'start-date')
    setStartDate(date)
    setEndDate(setDateToMidnight(endOfWeek(date)))
    setMinEndDate(setDateToMidnight(endOfWeek(date)))
  }

  // checkRules

  const enableOnlySundays = (date) => {
    return date.getDay() !== 0
  }

  const enableOnlySaturdays = (date) => {
    return date.getDay() !== 6
  }

  const hasError = (boo) => {
    setContinueBtnDisabled(boo)
  }

  const handleCancel = () => {
    dispatch(handleSetRequestType('availability'))
    navigate(-1)
  }

  const handleContinue = () => {
    dispatch(setBackToComponent(CREATE_AVAILABILITY_PAGE))
    if (availabilityType === 'Temporary') {
      dispatch(setAvailabilityForSingleWeek(isAvailabilityForSingleWeek()))
      dispatch(setAvailabilityRequestStartDate(startDate))
      dispatch(setAvailabilityRequestEndDate(endDate))
      navigate('/team-member/availability/display-availability')
    } else {
      let availbilityEndDate = FIXED_END_DATE_FROM_UKG
      let year = availbilityEndDate.split('-')[0]
      let month = availbilityEndDate.split('-')[1]
      let date = availbilityEndDate.split('-')[2]

      dispatch(setAvailabilityForSingleWeek(false))
      dispatch(setAvailabilityRequestStartDate(startDate))
      dispatch(setAvailabilityRequestEndDate(new Date(year, month - 1, date)))
      navigate('/team-member/availability/display-availability')
    }
    dispatch(setAvailabilityRequestType(availabilityType))
  }

  const isAvailabilityForSingleWeek = () => {
    const timeGap = intervalToDuration({
      start: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0),
      end: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0),
    })
    return timeGap.years === 0 && timeGap.months === 0 && timeGap.days < 8
  }

  const renderComponent = () => {
    return (
      <div style={styles.mainContainerPosition}>
        <Paper sx={styles.mainScrollContainer}>
          {renderFormElements()}
          {renderBottomButtons()}
        </Paper>
      </div>
    )
  }

  const renderFormElements = () => {
    return (
      <div style={styles.pageStyles}>
        <Grid container sx={styles.sectionStyles}>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="avl-type-radio-group" sx={styles.headingText}>
                Select Availability Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="avl-type-radio-group"
                defaultValue="Core"
                name="radio-buttons-group"
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="Core"
                  label="Core"
                  sx={styles.formControl}
                  control={<Radio inputProps={{ 'aria-label': 'availability type core' }} id="1" />}
                />
                <FormControlLabel
                  value="Temporary"
                  label="Temporary"
                  sx={styles.formControl}
                  control={<Radio inputProps={{ 'aria-label': 'availability type temporary' }} id="2" />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container sx={styles.sectionStyles}>
          <Grid item xs={12}>
            <Typography aria-label="start date" variant="subtitle1" component="h4" sx={styles.headingText}>
              Start Date
            </Typography>
          </Grid>
          <Grid item>
            <FormControl sx={styles.headingText}>
              <AvailabilityDatePicker
                name="reqAvlStartDate"
                label="Only Sunday"
                defaultDate={startDate}
                handleDateChange={reqStartDateChanged}
                minDate={defaultStartDate}
                maxDate={maxStart}
                disableSpecificDates={(date) => enableOnlySundays(date)}
                disablePast={true}
                hasError={hasError}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography aria-label="end date" variant="subtitle1" component="h4" sx={styles.headingText}>
              End Date
            </Typography>
          </Grid>
          {availabilityType === 'Core' && <Grid item>FOREVER</Grid>}
          {availabilityType === 'Temporary' && (
            <Grid item>
              <FormControl sx={styles.headingText}>
                <AvailabilityDatePicker
                  name="reqAvlEndDate"
                  label="Only Saturday"
                  defaultDate={endDate}
                  handleDateChange={(newValue) => {
                    setEndDate(newValue)
                  }}
                  minDate={minEndDate}
                  maxDate={maxEnd}
                  defaultEndDate={defaultEndDate}
                  disableSpecificDates={(date) => enableOnlySaturdays(date)}
                  disablePast={true}
                  hasError={hasError}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }

  const renderBottomButtons = () => {
    return (
      <div style={styles.actionControlsContainer}>
        <div style={styles.action}>
          <Button data-cy="requestTimeOffCancel" sx={styles.bottomButtons} onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleContinue}
            sx={styles.mobileButton}
            disabled={continueBtnDisabled}
          >
            Continue
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <HeaderTitle title={PAGE_TITLE} />
      {renderComponent()}
    </>
  )
}

export default RequestAvailability
