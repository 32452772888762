const MissedPunchIcon = (props) => {
  return (
    <svg
      aria-label={`missed ${props.punchType} punch`}
      role="img"
      width="63px"
      height="15px"
      viewBox="0 0 63 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>missed punch</title>
      <desc>Created with Sketch.</desc>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
        <line x1="1.5" y1="1.5" x2="1.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="5.5" y1="1.5" x2="5.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="9.5" y1="1.5" x2="9.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="13.5" y1="1.5" x2="13.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="17.5" y1="1.5" x2="17.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="21.5" y1="1.5" x2="21.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="25.5" y1="1.5" x2="25.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="29.5" y1="1.5" x2="29.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="33.5" y1="1.5" x2="33.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="37.5" y1="1.5" x2="37.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="41.5" y1="1.5" x2="41.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="49.5" y1="1.5" x2="49.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="53.5" y1="1.5" x2="53.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="57.5" y1="1.5" x2="57.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="61.5" y1="1.5" x2="61.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
        <line x1="45.5" y1="1.5" x2="45.5" y2="13.5" id="Line" stroke="#CC0000" strokeWidth="3"></line>
      </g>
    </svg>
  )
}
export default MissedPunchIcon
