import { Box, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { addDays } from 'date-fns'
import { days, months } from '../../constants/AvailabilityConstants'
import { convertAmPmTimeFormat } from '../../utils/DateUtil'

const getStyles = (theme) => ({
  listContainer: {
    padding: '2px',
    marginTop: '3px',
  },
  rowCls: {
    marginBottom: 0,
    borderBottom: '1px solid #DEDEDE',
    '&:nth-child(1)': {
      borderTop: '1px solid #DEDEDE',
    },
  },
  dateCls: {
    textAlign: 'center',
    height: 50,
    padding: '6px',
    backgroundColor: '#F4F4F4',
    fontSize: theme.typography.pxToRem(14),
  },
  timeCls: {
    padding: '14px 0 0 10px',
    fontSize: theme.typography.pxToRem(14),
  },
})

const WeeklyAvailability = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { pattern, statOfWeek } = props

  const printDate = (index) => {
    let date = addDays(statOfWeek, index).getDate().toString()
    let month = (addDays(statOfWeek, index).getMonth() + 1).toString()
    return `${month.padStart(2, 0)}/${date.padStart(2, 0)}`
  }

  const ariaLabelDate = (index) => {
    let date = addDays(statOfWeek, index).getDate().toString()
    let month = addDays(statOfWeek, index).getMonth()
    return `${months[month]} ${date}`
  }

  return (
    <div style={styles.listContainer}>
      {pattern.map((item, index) => {
        let dayName
        let dayNameShort
        if (item.day.toUpperCase() === days[index].toUpperCase()) {
          dayName = item.day
          dayNameShort = item.day.substring(0, 3).toUpperCase()
        } else {
          dayName = days[index]
          dayNameShort = days[index].substring(0, 3).toUpperCase()
        }
        return (
          <Box key={index} sx={styles.rowCls}>
            <Grid container>
              <Grid tabIndex={0} item xs={2} sm={2} md={2} sx={styles.dateCls}>
                <Typography role="heading" aria-label={`${ariaLabelDate(index)}`} variant="body2">
                  {printDate(index)}
                </Typography>
                <Typography role="heading" aria-label={dayName} variant="body2">
                  {dayNameShort}
                </Typography>
              </Grid>
              {item.time_intervals.map(
                (interval, indexKey) =>
                  interval.level === 'AVAILABLE' && (
                    <Grid tabIndex={0} item xs={5} sm={5} md={5} sx={styles.timeCls} columnSpacing={1} key={indexKey}>
                      <Typography variant="body2">{`${convertAmPmTimeFormat(
                        interval.start_time,
                      )} - ${convertAmPmTimeFormat(interval.end_time)}`}</Typography>
                    </Grid>
                  ),
              )}
            </Grid>
          </Box>
        )
      })}
    </div>
  )
}

export default WeeklyAvailability
