import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { pnTypes } from '../../constants/PNTypesConstants'
import {
  CLEAR_USER_PREFERENCES_DATA,
  CLEAR_USER_PREFERENCES_RESPONSES,
  GET_USER_PREFERENCES_ERROR,
  GET_USER_PREFERENCES_SUCCESS,
  POST_USER_PREFERENCES_ERROR,
  POST_USER_PREFERENCES_SUCCESS,
  SET_USER_PREFERENCES_LOADING,
} from './actionTypes'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function getUserPreferences(teamMemberNumber, locationId, locationType) {
  let apiUrl =
    `${apiConfig.worker.url}` +
    '/team_member_preferences' +
    '?worker_id=' +
    teamMemberNumber +
    '&location_id=' +
    locationId +
    '&location_type=' +
    locationType +
    '&key=' +
    `${apiConfig.worker.key}`

  return (dispatch) => {
    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        for (let i of response.data.team_member_preference_list) {
          if (!i.sub_types) {
            i.sub_types = []
          }
          for (let j of pnTypes) {
            if (i.type.toLowerCase() === j.title.toLowerCase()) {
              i.targetAudience = j.targetAudience
              break
            }
          }

          i.label = prettify(i.type)
        }
        dispatch(handleGetUserPreferencesSuccess(response))
      })
      .catch((err) => {
        dispatch(handleGetUserPreferencesError(err))
      })
  }
}

const prettify = (str) => {
  return str.replace(/(-|^)([^-]?)/g, function (_, prep, letter) {
    return (prep && ' ') + letter.toUpperCase()
  })
}

export function setUserPreferencesLoading(text) {
  return {
    type: SET_USER_PREFERENCES_LOADING,
    text,
  }
}

export function clearUserPreferencesData() {
  return {
    type: CLEAR_USER_PREFERENCES_DATA,
  }
}

export function handleGetUserPreferencesSuccess(data) {
  return {
    type: GET_USER_PREFERENCES_SUCCESS,
    data,
  }
}

export function handleGetUserPreferencesError(data) {
  return {
    type: GET_USER_PREFERENCES_ERROR,
    data,
  }
}

export function postUserPreferences(requestBody) {
  let apiUrl = `${apiConfig.worker.url}` + '/worker_push_notification_preferences' + '?key=' + `${apiConfig.worker.key}`

  return (dispatch) => {
    return axios
      .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handlePostUserPreferencesSuccess(response))
      })
      .catch((response) => {
        dispatch(handlePostUserPreferencesError(response))
      })
  }
}

export function handlePostUserPreferencesSuccess(data) {
  return {
    type: POST_USER_PREFERENCES_SUCCESS,
    data,
  }
}

export function handlePostUserPreferencesError(data) {
  return {
    type: POST_USER_PREFERENCES_ERROR,
    data,
  }
}

export function clearUserPreferencesResponses() {
  return {
    type: CLEAR_USER_PREFERENCES_RESPONSES,
  }
}
