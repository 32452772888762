import { Box, CircularProgress, Theme, useTheme } from '@mui/material'
import React from 'react'

export interface LoaderProps {
  fullScreen?: boolean
  size?: number
}

const Loader: React.FC<LoaderProps> = ({ fullScreen = false, size }) => {
  const theme: Theme = useTheme()

  const boxStyle = fullScreen
    ? {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.drawer + 1,
      }
    : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }

  return (
    <Box sx={boxStyle}>
      <CircularProgress size={size || fullScreen ? 40 : 25} />
    </Box>
  )
}

export default Loader
