import JobIcon from '@mui/icons-material/Label'
import LocationIcon from '@mui/icons-material/Place'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React from 'react'

const getStyles = (theme) => ({
  iconMargin: {
    marginRight: '.25em',
  },
  jobName: {
    paddingTop: '3px',
  },
  indicator: {
    marginTop: '-2px',
    marginLeft: '-15px',
    marginRight: '5px',
    fontSize: '24px',
    color: 'black',
    zIndex: '1',
  },
  displaySegmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
    color: theme.palette.secondary.dark,
  },
  startTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
    color: theme.palette.secondary.dark,
  },
  endTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
    color: theme.palette.secondary.dark,
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: '1',
    minHeight: '32px',
    paddingBottom: '.25em',
  },
  jobContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  mobileButton: {
    maxWidth: 120,
  },
  container: {
    marginTop: '5px',
    marginBottom: '7px',
  },
})

const DisplayAvailableShiftSegment = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const { shift, renderedOn, isHomeLocation } = props
  const inputTimeFormat = 'YYYY-MM-DD hh:mm:ss'
  const outputTimeFormat = 'hh:mmA'

  const renderLocation = (location) => {
    if (location) {
      return (
        <React.Fragment>
          <LocationIcon color="primary" sx={styles.iconMargin} />
          <Typography variant="body2" sx={styles.locationName}>
            {`${location}${isHomeLocation ? '' : '*'}`}
          </Typography>
        </React.Fragment>
      )
    }
  }

  const renderJob = (jobName, totalJobs) => {
    if (jobName) {
      return (
        <React.Fragment>
          <JobIcon color="primary" sx={styles.iconMargin} />
          {totalJobs > 1 && renderedOn !== 'modal' ? renderIndicator() : null}
          <Typography variant="body2" sx={styles.jobName}>
            {jobName}
          </Typography>
        </React.Fragment>
      )
    }
  }

  const renderIndicator = () => {
    return <div style={styles.indicator}>*</div>
  }

  return (
    <Grid container sx={styles.container}>
      <Grid item xs={5} sm={6} sx={styles.displaySegmentContainer}>
        <Typography variant="body2" sx={styles.startTimeItem}>
          {moment(shift.shift_start, inputTimeFormat)?.format(outputTimeFormat)}
        </Typography>
        <Typography variant="body2" sx={styles.endTimeItem}>
          {moment(shift.shift_end, inputTimeFormat)?.format(outputTimeFormat)}
        </Typography>
      </Grid>
      <Grid item xs={7} sm={6} sx={styles.displaySegmentContainer}>
        <div style={styles.locationContainer}>{renderLocation(shift.org_structure.location_id)}</div>
        <div style={styles.jobContainer}>{renderJob(shift.org_structure.job, shift.jobs.length)}</div>
      </Grid>
    </Grid>
  )
}

export default DisplayAvailableShiftSegment
