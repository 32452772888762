import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import { Fade, IconButton, Snackbar, SnackbarContent, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { showNotificationError } from '../../store/notification/actionCreator'

const getStyles = (theme) => ({
  container: {
    position: 'absolute',
    margin: '0 auto',
    maxWidth: '640px',
    bottom: '50px !important',
    [theme.breakpoints.down('md')]: {
      bottom: '60px !important',
    },
  },
  snackbarContent: {
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    padding: '6px 10px',
  },
  messageContainer: {
    display: 'flex',
    maxHeight: 120,
    overflowY: 'auto',
  },
  message: {
    paddingRight: 10,
    color: '#ffffff',
  },
  icon: {
    marginRight: '8px',
    color: theme.palette.primary.main,
  },
  closeButton: {
    color: '#ffffff',
  },
})

const NotificationError = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const notificationIsShown = useSelector((state) => state.notification.isShownError)
  const notificationMessage = useSelector((state) => state.notification.messageError)
  const dispatch = useDispatch()

  const handleRequestClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(showNotificationError(false))
  }

  const createMarkup = (str) => {
    return { __html: str }
  }

  return (
    <Snackbar
      data-cy="notificationError"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={notificationIsShown}
      autoHideDuration={null}
      TransitionComponent={Fade}
      sx={styles.container}
    >
      <SnackbarContent
        sx={styles.snackbarContent}
        aria-describedby="client-snackbar"
        message={
          <span style={styles.messageContainer}>
            <ErrorIcon sx={styles.icon} />
            <p style={styles.message} dangerouslySetInnerHTML={createMarkup(notificationMessage)} />
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleRequestClose}>
            <CloseIcon sx={styles.closeButton} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

export default NotificationError
