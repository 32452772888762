import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ADD_ROW,
  MAX_ROWS_PER_DAY_DC,
  MIN_ROWS_PER_DAY,
  REMOVE_ROW,
  REQUEST_SCREEN_TITLES,
} from '../../../constants/RequestConstants'
import RequestTimeOffRowInput from '../RequestTimeOffRowInput'

const getStyles = (theme) => ({
  rowGrid: {
    padding: '10px 0px 10px 0px',
  },
  moreButton: {
    '&.MuiIconButton-root': {
      padding: 0,
    },
  },
  infoMessages: {
    color: theme.palette.tertiary.contrastText,
    fontStyle: 'italic',
  },
  errorMessage: {
    color: theme.palette.primary.main,
  },
})

const RequestPaidTimeOffDetail = (props) => {
  const {
    requestDetailPerDateIndex,
    requestDetailPerDateLength,
    requestDate,
    rowDetail,
    rowDetailError,
    rowDetailInfo,
    handleRowDetail,
    handleAddRow,
    handleRemoveRow,
  } = props

  const theme = useTheme()
  const styles = getStyles(theme)

  const selectableTypes = useSelector((state) => state.requestTimeOff?.requestConfig?.content?.types?.selectableTypes)
  const [anchorElMoreOptions, setAnchorElMoreOptions] = useState(null)
  const [currentMenuOptions, setCurrentMenuOptions] = useState(null)
  const openMoreOptions = Boolean(anchorElMoreOptions)

  const handleMenuItemClick = (event, index) => {
    let selectedMenuOption = currentMenuOptions[index]
    if (selectedMenuOption === ADD_ROW) {
      handleAddRow(requestDetailPerDateIndex)
    } else if (selectedMenuOption === REMOVE_ROW) {
      handleRemoveRow(requestDetailPerDateIndex)
    }

    setAnchorElMoreOptions(null)
  }

  const handleOpenMoreOptions = (event) => {
    setAnchorElMoreOptions(event.currentTarget)
  }

  const handleCloseMoreOptions = () => {
    setAnchorElMoreOptions(null)
  }

  const handleRowDetailOnChange = (rowDetailName, rowDetailValue) => {
    rowDetail[rowDetailName] = rowDetailValue
    handleRowDetail(requestDetailPerDateIndex, rowDetail)
  }

  const renderDetail = () => {
    return (
      <React.Fragment>
        <Grid
          container
          alignItems="flex-end"
          data-cy={`requestTimeOffDetail_${requestDate}_${requestDetailPerDateIndex}`}
          sx={styles.rowGrid}
          justifyContent="space-between"
          wrap="nowrap"
        >
          <RequestTimeOffRowInput
            rowDetail={rowDetail}
            handleRowDetail={handleRowDetailOnChange}
            requestTypes={selectableTypes.map((type) => {
              return { primary_value: type }
            })}
            rowId={`${requestDate}_${requestDetailPerDateIndex}`}
            screenTitle={REQUEST_SCREEN_TITLES.REQUEST_TIME_OFF}
          />
          {renderMoreButton()}
        </Grid>
        {renderInfoMessage()}
        {renderErrorMessage()}
      </React.Fragment>
    )
  }
  const renderMoreButton = () => {
    return (
      <React.Fragment>
        <IconButton
          data-cy={`requestTimeOffDetail_rowsButton_${requestDate}_${requestDetailPerDateIndex}`}
          aria-label="more row options"
          aria-controls="long-menu"
          aria-haspopup="true"
          color="primary"
          onClick={handleOpenMoreOptions}
          sx={styles.moreButton}
          size="large"
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          data-cy={`requestTimeOffDetail_rowsMenu_${requestDate}_${requestDetailPerDateIndex}`}
          id="long-menu"
          anchorEl={anchorElMoreOptions}
          keepMounted
          open={openMoreOptions}
          onClose={handleCloseMoreOptions}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
          {renderMenuItems()}
        </Menu>
      </React.Fragment>
    )
  }

  const renderInfoMessage = () => {
    if (rowDetailInfo) {
      return rowDetailInfo.map((message) => (
        <Typography
          data-cy={`requestTimeOffDetail_error_message_${requestDate}_${requestDetailPerDateIndex}`}
          sx={styles.infoMessages}
          value={message}
          key={message}
        >
          {message}
        </Typography>
      ))
    }
  }

  const renderErrorMessage = () => {
    if (rowDetailError) {
      return (
        <Typography
          data-cy={`requestTimeOffDetail_error_message_${requestDate}_${requestDetailPerDateIndex}`}
          sx={styles.errorMessage}
          value={rowDetailError}
        >
          {rowDetailError}
        </Typography>
      )
    }
  }

  const getMenuOptionsArray = () => {
    let moreOptions = []

    if (requestDetailPerDateLength > MIN_ROWS_PER_DAY) {
      moreOptions.push(REMOVE_ROW)
    }
    if (requestDetailPerDateLength < MAX_ROWS_PER_DAY_DC) {
      moreOptions.push(ADD_ROW)
    }
    return moreOptions
  }

  const renderMenuItems = () => {
    let moreOptions = getMenuOptionsArray()
    if (JSON.stringify(moreOptions) !== JSON.stringify(currentMenuOptions)) {
      setCurrentMenuOptions(moreOptions)
    }

    return moreOptions.map((option, index) => (
      <MenuItem key={index} value={option} onClick={(event) => handleMenuItemClick(event, index)}>
        {option}
      </MenuItem>
    ))
  }

  return renderDetail()
}

export default RequestPaidTimeOffDetail
