import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, Grid, IconButton, MenuItem, TextField, Typography, styled, useTheme } from '@mui/material'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { isValid } from 'date-fns'
import dayjs from 'dayjs'
import { useState } from 'react'
import { REQUEST_CADENCE } from '../../../constants/AvailabilityConstants'
import TimePicker from '../../common/calendar/TimePicker'

const getStyles = (theme) => ({
  hide: {
    display: 'block',
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: '2px',
  },
  errorMsg: {
    color: '#cc0000',
    paddingTop: '10px',
    fontSize: '0.85rem',
  },
  autocompletetext: {
    marginTop: '16px',
  },
  dot: {
    height: '10px',
    width: '10px',
    backgroundColor: '#04AA6D',
    borderRadius: '50%',
    display: 'inline-block',
    border: '1px solid #000',
    margin: '8px 6px 0 0',
  },
  iconsCls: {
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: '-1px',
    right: '5px',
  },
  repeatRowContainer: {
    marginTop: '20px',
  },
  repeatTextStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  repeatDropDownContainer: {
    marginTop: '-2px',
  },
  repeatDropDownStyle: {
    width: '100%',
  },
})

const AccordionSummary = styled(MuiAccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})(({ theme, expanded }) => ({
  backgroundColor: '#fff',
  borderRadius: 0,
  borderBottom: '1px solid rgba(0,0,0,.125)',
  marginBottom: -1,
  minHeight: 20,
  ...(expanded && {
    minHeight: 20,
    '& > .MuiAccordionSummary-content': {
      margin: '12px 0',
    },
  }),
  '&.Mui-expanded': {
    minHeight: 20,
  },
  '& > .MuiAccordionSummary-content': {
    '&.Mui-expanded': {
      margin: '12px 0',
    },
  },
  // Add other styles or expanded conditional styles as needed
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
}))

const TimePeriodCard = (props) => {
  const {
    startTime,
    endTime,
    repeatOptionSelected,
    onStartTimeChange,
    onEndTimeChange,
    onRepeatOptionChange,
    onRemovalOfTimePeriod,
    id,
    weekLabel,
    weekAndDayLblA11y,
    isEndTimeGreater,
    isEveryOtherWeek,
    isLessThanMinHrs,
    isOverlapping,
    singleWeekAvailability,
    disableRepeatdropDown,
  } = props

  const theme = useTheme()
  const styles = getStyles(theme)

  const [state, setState] = useState({
    startTime: startTime ? dayjs('2001-01-01T' + startTime) : null,
    endTime: endTime ? dayjs('2001-01-01T' + endTime) : null,
    repeatOption: repeatOptionSelected || 'Every week',
    startDateInvalid: false,
    endDateInvalid: false,
  })

  const [expanded, setExpanded] = useState(`panel_${id}`)

  const handleStartTimeChange = (val) => {
    if (val !== '' && isValid(val)) {
      let hours = new Date(val).getHours().toString().padStart(2, 0)
      let mins = new Date(val).getMinutes().toString().padStart(2, 0)
      let hourAndMin = val ? `${hours}:${mins}` : ''
      setState({ ...state, startTime: dayjs('2001-01-01T' + hourAndMin), startDateInvalid: false })
      onStartTimeChange(hourAndMin)
    } else {
      setState({ ...state, startDateInvalid: true })
    }
  }

  const handleEndTimeChange = (val) => {
    if (val !== '' && isValid(val)) {
      let hours = new Date(val).getHours().toString().padStart(2, 0)
      let mins = new Date(val).getMinutes().toString().padStart(2, 0)
      let hourAndMin = val ? `${hours}:${mins}` : ''
      setState({ ...state, endTime: dayjs('2001-01-01T' + hourAndMin), endDateInvalid: false })
      onEndTimeChange(hourAndMin)
    } else {
      setState({ ...state, endDateInvalid: true })
    }
  }

  const handleRepeatOptionChange = (event) => {
    event.preventDefault()
    let val = event.target.value
    setState({ ...state, repeatOption: val })
    onRepeatOptionChange(val)
  }

  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleRemoveTimePeriod = (e, id) => {
    onRemovalOfTimePeriod(id)
  }

  return (
    <div style={styles.root}>
      <Accordion
        id={`panel_${id}`}
        aria-label={`time interval card for ${weekAndDayLblA11y}`}
        expanded={expanded === `panel_${id}`}
        onChange={handleExpandChange(`panel_${id}`)}
        square
      >
        <div style={{ display: 'flex' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel2a-header"
            sx={{ flexGrow: 1, height: '50px' }}
          >
            <Grid container direction="row">
              <Grid item xs={1} md={1} lg={1}>
                <span style={styles.dot} />
              </Grid>
              <Grid item xs={9} md={9} lg={9}>
                <Typography sx={styles.heading}>Available</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <div style={styles.iconsCls}>
            <IconButton
              aria-label={`delete the time interval card under ${weekAndDayLblA11y}`}
              onClick={(e) => handleRemoveTimePeriod(e, id)}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </div>
        </div>
        <AccordionDetails>
          <div>
            <Grid container>
              <Grid item xs={5} md={5} sx={styles.inputContainer}>
                <TimePicker
                  label="Start Time"
                  defaultValue={state.startTime?.toDate()}
                  onChange={handleStartTimeChange}
                  minutesStep={15}
                  renderInput={(params) => (
                    <TextField
                      data-cy="startTime"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        pattern: '[a-zA-Z0-9_.-]',
                        type: 'text',
                        // inputmode: 'visible-password',
                        placeholder: 'hh:mm am or pm',
                        'aria-label':
                          'start time, Enter time in 12 hours format. For example, zero nine zero zero p for 09:00 pm',
                        disabled: false,
                      }}
                    />
                  )}
                  // DialogProps={{ sx: { '& .MuiPickersToolbar-penIconButton': { display: 'none' } } }}
                />
              </Grid>
              <Grid item xs={2} md={2} />
              <Grid item xs={5} md={5} sx={styles.inputContainer}>
                <TimePicker
                  label="End Time"
                  defaultValue={state.endTime?.toDate()}
                  onChange={handleEndTimeChange}
                  minutesStep={15}
                  renderInput={(params) => (
                    <TextField
                      data-cy="endTime"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        pattern: '[a-zA-Z0-9_.-]',
                        type: 'text',
                        // inputmode: 'visible-password',
                        placeholder: 'hh:mm am or pm',
                        'aria-label':
                          'end time, Enter time in 12 hours format. For example, zero nine zero zero p for 09:00 pm',
                        disabled: false,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {state.startDateInvalid && (
              <Grid item xs={12} sm={12}>
                <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
                  Start Time is invalid.
                </Typography>
              </Grid>
            )}
            {state.endDateInvalid && (
              <Grid item xs={12} sm={12}>
                <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
                  End Time is invalid.
                </Typography>
              </Grid>
            )}
            {isEndTimeGreater && (
              <Grid item xs={12} sm={12}>
                <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
                  End Time should be greater than Start Time.
                </Typography>
              </Grid>
            )}
            {isLessThanMinHrs && (
              <Grid item xs={12} sm={12}>
                <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
                  The difference between Start Time and End Time should be minimum 2 hours.
                </Typography>
              </Grid>
            )}
            {isOverlapping && (
              <Grid item xs={12} sm={12}>
                <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
                  Time intervals are overlapping with each other.
                </Typography>
              </Grid>
            )}
            <Grid container item xs={12} md={12} sx={styles.repeatRowContainer}>
              <Grid item xs={12} md={12} sx={styles.gridContainer}>
                <Typography variant="body2" sx={styles.repeatTextStyle}>
                  Repeat:
                </Typography>
              </Grid>
              <Grid item xs={7} md={7} sx={styles.repeatDropDownContainer}>
                <TextField
                  select
                  // value={weekLabel.toLowerCase() === 'week_2' ? REQUEST_CADENCE[1].value : state.repeatOption}
                  value={state.repeatOption}
                  // defaultValue={REQUEST_CADENCE[0].value}
                  onChange={handleRepeatOptionChange}
                  disabled={singleWeekAvailability || disableRepeatdropDown}
                  inputProps={{
                    'aria-label': `repeat ${state.repeatOption}`,
                  }}
                >
                  {REQUEST_CADENCE.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={styles.repeatDropDownStyle}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {isEveryOtherWeek && (
                <Grid item xs={12} sm={12}>
                  <Typography variant="caption" role="alert" display="block" gutterBottom sx={styles.errorMsg}>
                    {`Reminder: Please fill in week ${weekLabel === 'week_1' ? '2' : '1'} details as well.`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default TimePeriodCard
