import { Box, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { charRegex, emojiRegex } from '../../utils/strings'

const RequestCommentForm = (props) => {
  const { setComment, maxLength, label, value, disabled, errorMessage } = props

  const handleChange = async (event) => {
    event.preventDefault()

    if (emojiRegex.test(event.target.value) || !charRegex.test(event.target.value)) {
      return null
    }

    setComment(event.target.value)
  }

  return (
    <Box sx={{ margin: '11px 16px' }}>
      <TextField
        id="outlined-textarea"
        data-cy="requestCommentForm"
        variant="outlined"
        label={label}
        placeholder={`${maxLength} character max`}
        error={!!errorMessage}
        multiline
        fullWidth
        onChange={handleChange}
        value={value}
        disabled={disabled}
        aria-describedby="comment-rules"
        inputProps={{
          maxLength: maxLength,
        }}
      />
      <Typography aria-label="comment rules" variant="caption" id="comment-rules">
        Comments cannot contain special characters or emojis.
      </Typography>
    </Box>
  )
}

export default RequestCommentForm
