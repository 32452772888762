import { CircularProgress, Grid } from '@mui/material'

const CenteredLoader = () => {
  return (
    <Grid container item height={'100%'} alignItems="center" justifyContent="center">
      <CircularProgress />
    </Grid>
  )
}

export default CenteredLoader
