const ShiftSwapMsgIconCircle = (props) => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Message related to your recent shift swap activity</title>
      <g id="Speech-Bubble-Circle-with-arrows" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Heart-icon" stroke="#CC0000">
          <circle id="Oval" cx="12.5" cy="12.5" r="12"></circle>
        </g>
        <g id="speech-bubble" transform="translate(3.500000, 4.250000)" fill="#CC0000">
          <ellipse id="Oval" cx="9" cy="6.5" rx="9" ry="6.5"></ellipse>
          <polygon
            id="Triangle"
            transform="translate(6.059788, 13.685255) rotate(-133.000000) translate(-6.059788, -13.685255) "
            points="6.05978814 8.68525523 8.55978814 18.6852552 3.55978814 18.6852552"
          ></polygon>
        </g>
        <g id="arrows" transform="translate(6.500000, 6.500000)" fill="#FFFFFF">
          <polygon
            id="Path"
            points="2.5 2.68937745 6.68402318 2.68937745 6.68402318 0 11.5 4 6.68402318 4 2.5 4"
          ></polygon>
          <polygon
            id="Path"
            transform="translate(5.000000, 7.000000) rotate(-180.000000) translate(-5.000000, -7.000000) "
            points="0.5 7.77962698 4.68402318 7.77962698 4.68402318 5 9.5 9 4.68402318 9 0.5 9"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}
export default ShiftSwapMsgIconCircle
