import { SvgIcon } from '@mui/material'

const ASC = 'ASC'
const DESC = 'DESC'

export default function SwapVertIcon({ sortOrder }) {
  return (
    <SvgIcon sx={{ fontSize: 32 }}>
      <path d="M0 0h24v24H0z" fill="none" />
      <SvgIcon color={sortOrder === ASC ? 'primary' : 'default'}>
        <path d="M9 3L5 6.99h3V14h2V6.99h3L9 3z" />
      </SvgIcon>
      <SvgIcon color={sortOrder === DESC ? 'primary' : 'default'}>
        <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3z" />
      </SvgIcon>
    </SvgIcon>
  )
}
