import { Typography } from '@mui/material'
import FormGroup from '@mui/material/FormGroup'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { toggleAllDayUnpaid } from '../../store/requestTimeOff/actionCreator'
import { createCypressDate } from '../../utils/DateUtil'

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 37,
  height: 24,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 18,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '2px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#42b386',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 19,
    height: 19,
    borderRadius: 9,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

export default function AllDayToggle({ dateKey }) {
  const dispatch = useDispatch()
  const allDayUnpaid = useSelector((store) => store.requestTimeOff.request[dateKey].allDayUnpaid)

  const onChange = (event, checked) => {
    dispatch(toggleAllDayUnpaid(dateKey))
  }

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <AntSwitch
          checked={allDayUnpaid}
          value={allDayUnpaid}
          onChange={onChange}
          inputProps={{ 'aria-label': 'All Day switch' }}
          data-cy={`allDayToggle_${createCypressDate(new Date(dateKey))}`}
        />
        <Typography>All Day</Typography>
      </Stack>
    </FormGroup>
  )
}
