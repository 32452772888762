import { Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import ExpandCollapsePannelButton from './ExpandCollapsePanelButton'

const CollapsedControlPanel = () => {
  const selectedLocation = useSelector((state) => state.leader.selectedLocation)
  const selectedCategory = useSelector((state) => state.leader.selectedCategory)
  const startDate = useSelector((state) => state?.leader[selectedCategory]?.startDate)
  const endDate = useSelector((state) => state?.leader[selectedCategory]?.endDate)

  return (
    <Grid container>
      <Grid container item columnGap={0.75} xs alignContent={'center'} alignItems={'center'}>
        <Grid item>
          <Typography>{selectedLocation ? selectedLocation : <></>}</Typography>
        </Grid>
        {selectedCategory ? (
          <>
            <Grid item>
              <Typography color={'tertiary.contrastText'}>|</Typography>
            </Grid>
            <Grid item>
              <Typography>{selectedCategory}</Typography>
            </Grid>
          </>
        ) : (
          <></>
        )}
        {startDate && endDate ? (
          <>
            <Grid item>
              <Typography color={'tertiary.contrastText'}>|</Typography>
            </Grid>
            <Grid item>
              <Typography>{`${format(new Date(startDate), 'M/d/yy')} - ${format(
                new Date(endDate),
                'M/d/yy',
              )}`}</Typography>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Grid>
        <ExpandCollapsePannelButton />
      </Grid>
    </Grid>
  )
}

export default CollapsedControlPanel
