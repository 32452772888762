import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AxiosError } from 'axios'
import { differenceInCalendarDays, subMonths } from 'date-fns'
import { useEffect, useState } from 'react'
import { usePersonas } from '../api/feedback/usePersonas'
import { useUserFeedbackMutation } from '../api/feedback/useUserFeedbackMutation'
import { IError } from '../api/globalResponses'
import HeaderTitle from '../components/Header/HeaderTitle'
import FeedbackBody from '../components/admin/feedback/FeedbackBody'
import LinearLoader from '../components/common/loaders/LinearLoader'

// allow for 31 days, but calculation is inclusive of first day
const MAX_DATE_RANGE = 30

export default function FeedbackAnalysis() {
  const [selectedStartDate, setSelectedStartDate] = useState<any>(subMonths(new Date(), 1))
  const [selectedEndDate, setSelectedEndDate] = useState<any>(new Date())
  const [selectedPersonas, setSelectedPersonas] = useState<string[]>([])
  const { data: personas } = usePersonas()

  const { mutate, data: response, isLoading, error } = useUserFeedbackMutation()

  const feedbackError = error as AxiosError<IError>

  useEffect(() => {
    if (personas) {
      setSelectedPersonas(personas)
    }
  }, [personas])

  const handleStartDateChange = (date: any) => {
    setSelectedStartDate(date)
    if (selectedEndDate) {
      const maxEndDate = new Date(date).setDate(new Date(date).getDate() + MAX_DATE_RANGE)
      if (selectedEndDate < date || selectedEndDate > maxEndDate) {
        setSelectedEndDate(null)
      }
    }
  }

  const handleEndDateChange = (date: any) => {
    setSelectedEndDate(date)
    if (selectedStartDate) {
      const minStartDate = new Date(date).setDate(new Date(date).getDate() - MAX_DATE_RANGE)
      if (selectedStartDate < minStartDate || selectedStartDate > date) {
        setSelectedStartDate(null)
      }
    }
  }

  const handleChange = (event: any, value: string[]) => {
    setSelectedPersonas(value)
  }

  const handleClearDates = () => {
    setSelectedEndDate(null)
    setSelectedStartDate(null)
  }

  const handleGenerateAnalysis = () => {
    mutate({ startDate: selectedStartDate, endDate: selectedEndDate, module: 'summary', personas: selectedPersonas })
  }

  const maxStartDate = selectedEndDate
    ? new Date(selectedEndDate).setDate(new Date(selectedEndDate).getDate() - MAX_DATE_RANGE)
    : null
  const minEndDate = selectedStartDate
    ? new Date(selectedStartDate).setDate(new Date(selectedStartDate).getDate() + 1)
    : null

  return (
    <>
      <HeaderTitle title={'Feedback Analysis'} />
      <Grid
        container
        item
        spacing={1}
        wrap="nowrap"
        alignItems={'flex-end'}
        justifyContent={'space-around'}
        marginBottom={2}
      >
        <Grid item>
          <Grid item marginBottom={2}>
            <DatePicker
              name="startDate"
              label="Start Date"
              onChange={handleStartDateChange}
              value={selectedStartDate}
              maxDate={
                selectedStartDate
                  ? new Date(selectedStartDate).setDate(new Date(selectedStartDate).getDate() + MAX_DATE_RANGE)
                  : maxStartDate
              }
              disableFuture
            />
          </Grid>
          <Grid item marginBottom={1}>
            <DatePicker
              name="endDate"
              label="End Date"
              onChange={handleEndDateChange}
              value={selectedEndDate}
              minDate={minEndDate}
              maxDate={
                selectedStartDate
                  ? new Date(selectedStartDate).setDate(new Date(selectedStartDate).getDate() + MAX_DATE_RANGE)
                  : null
              }
              disableFuture
            />
          </Grid>
          <Grid container item justifyContent={'space-between'}>
            <Grid item>
              <Typography>
                Days:
                {selectedEndDate &&
                  selectedStartDate &&
                  differenceInCalendarDays(new Date(selectedEndDate), new Date(selectedStartDate)) + 1}
              </Typography>
            </Grid>
            <Grid item>
              <Button size={'small'} variant="contained" onClick={handleClearDates}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          {personas && (
            <Autocomplete
              multiple
              id="multiple-limit-tags"
              options={personas}
              getOptionLabel={(option: any) => option}
              defaultValue={personas}
              onChange={handleChange}
              value={selectedPersonas}
              renderInput={(params) => <TextField {...params} label="Personas" placeholder="Personas" />}
              disableCloseOnSelect
            />
          )}
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleGenerateAnalysis}>
            Generate Analysis
          </Button>
        </Grid>
      </Grid>
      {isLoading && (
        <Grid container item textOverflow={'hidden'}>
          <LinearLoader width="99%" />
        </Grid>
      )}
      {response?.data && !isLoading ? (
        <FeedbackBody summaryContent={response.data} personas={selectedPersonas} />
      ) : feedbackError ? (
        <Grid container item textOverflow={'hidden'}>
          <Typography color={'primary'}>{feedbackError?.response?.data?.message}</Typography>
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}
