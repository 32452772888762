import { useLocationDetails } from '../../../../../api/location/useLocationDetails'
import { useUserFeatures } from '../../../../../api/userAccess/useUserFeatures'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { getConfigDetails } from '../../../../../constants/leaderConstants'
import { setControlPanelSettings } from '../../../../../store/leader/actionCreator'
import { AVAILABILITY, CATEGORY_FEATURE_MAP, FAILED, PENDING } from '../../../../../constants/RequestConstants'
import { Divider } from '@mui/material'
import TotalTimeOffRequestsByStatusButton from '../../TotalTimeOffRequestsByStatusButton'

const AvailabilityDailyActions = () => {
  const { data: locationDetails } = useLocationDetails()
  const { data: userFeatures } = useUserFeatures()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { configMap } = useMemo(() => getConfigDetails(locationDetails, userFeatures), [locationDetails, userFeatures])

  const handleAvailabilityRequestsButtonClick = (statuses) => {
    let filterOverrides = { statuses: { selectedValues: statuses } }

    dispatch(
      setControlPanelSettings(
        AVAILABILITY,
        configMap[AVAILABILITY].defaultStartDate,
        configMap[AVAILABILITY].defaultEndDate,
        filterOverrides,
        configMap[AVAILABILITY].searchText,
        configMap[AVAILABILITY].selectedSortByIndex,
        configMap[AVAILABILITY].sortOrder,
      ),
    )
    navigate('/leader/myteam')
  }

  return (
    <>
      <Divider aria-hidden="true" />
      <TotalTimeOffRequestsByStatusButton
        status={PENDING}
        startDate={configMap[AVAILABILITY].defaultStartDate}
        endDate={configMap[AVAILABILITY].defaultEndDate}
        handleButtonClick={() => handleAvailabilityRequestsButtonClick(['Pending'])}
        buttonMessage={'Pending Availability Requests'}
        selectedCategory={CATEGORY_FEATURE_MAP.get(AVAILABILITY)}
        categoryName={AVAILABILITY}
      />
      <Divider aria-hidden="true" />
      <TotalTimeOffRequestsByStatusButton
        status={FAILED}
        startDate={configMap[AVAILABILITY].defaultStartDate}
        endDate={configMap[AVAILABILITY].defaultEndDate}
        handleButtonClick={() => handleAvailabilityRequestsButtonClick(['Failed'])}
        buttonMessage={'Failed Availability Requests'}
        selectedCategory={CATEGORY_FEATURE_MAP.get(AVAILABILITY)}
        categoryName={AVAILABILITY}
      />
    </>
  )
}

export default AvailabilityDailyActions
