import { Info } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import React from 'react'
import { isSafari } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { setIsRedeemCodeDialogOpen } from '../../store/workerRedemptionCode/actionCreator'
import CopyToClipboard from '../../utils/CopyToClipboard'

const styles = {
  gridItemCard: {
    padding: '8px 0px 8px 0px',
  },
  gridItemText: {
    padding: '8px 8px 8px 8px',
  },
  cardTitleContainer: {
    display: 'flex',
    flexGrow: '1',
  },
  cardTitle: {
    marginLeft: '.4em',
    fontWeight: 'bold',
  },
  cardAction: {
    padding: '4px 16px 4px 16px',
    display: 'flex',
  },
  cardDescription: {
    paddingBottom: '12px',
  },
  dialogContent: { padding: '0 8px 8px' },
  heading: {
    color: 'white',
    paddingRight: '0px',
  },
  infoIcon: {
    marginRight: '4px',
    fontSize: '12px',
  },
  buttonWarningMessageContainer: {
    display: 'flex',
  },
  icon: {
    marginRight: '8px',
    color: '#EED202',
    fontSize: '20px',
  },
  listItemRoot: {
    padding: '4px 0px 4px 0px',
  },
}

const RedeemCodeDialogCodeDialog = (props) => {
  const DIALOG_TITLE = 'Redeem My Code'
  const INFO_MSG_1 = 'There are 2 different ways you can redeem your code through Apple.'
  const INFO_MSG_2 =
    'A code can only be redeemed through Apple once. There are rare occurrences where you would need to redeem again. If this is the case, you would need to redeem a newly issued code.'

  const REDEEM_AUTOMATICALLY_HEADER = 'Option 1 - Redeem Automatically'
  const REDEEM_MANUALLY_HEADER = 'Option 2 - Redeem Manually'
  const REDEEM_AUTOMATICALLY_MSG_1a =
    'Recommended - This will open a new tab in your Safari browser which will then open iTunes on your device. iTunes will then automatically redeem code: '
  const REDEEM_AUTOMATICALLY_MSG_1b = ' and initiate the download.'
  const REDEEM_AUTOMATICALLY_MSG_2 = 'In order for you to successfully automatically redeem your code, you must:'
  const REDEEM_AUTOMATICALLY_MSG_2a = '- have iTunes on this device.'
  const REDEEM_AUTOMATICALLY_MSG_2b = '- have this page open in the Safari browser.'
  const REDEEM_AUTOMATICALLY_MSG_2c = '- have an Apple Id.'
  const REDEEM_AUTOMATICALLY_MSG_2d = '- be on the device that you want to download the app.'

  const REDEEM_MANUALLY_MSG = "If you don't have iTunes on this device, you can redeem your code manually."
  const REDEEM_INSTRUCTIONS_1 = '1) Copy or write down your issued code.'
  const REDEEM_INSTRUCTIONS_2 = '2) Open the App Store app.'
  const REDEEM_INSTRUCTIONS_3 = '3) At the bottom of the screen, tap "Today".'
  const REDEEM_INSTRUCTIONS_4 = '4) At the top of the screen, tap the sign-in button or your photo.'
  const REDEEM_INSTRUCTIONS_5 =
    '5) Tap "Redeem Gift Card or Code." (If you don’t see “Redeem Gift Card or Code,” you must first sign in with your Apple ID.)'
  const REDEEM_INSTRUCTIONS_6 = '6) Tap "Enter Code Manually" and then paste or enter your code.'
  const REDEEM_INSTRUCTIONS_7 = '7) Tap "Redeem" in the upper right corner.'
  const REDEEM_AUTOMATICALLY_BTN_LABEL = 'REDEEM MY CODE AUTOMATICALLY'
  const REDEEM_MANUALLY_PANEL_LABEL = 'REDEEM MY CODE MANUALLY'
  const EXIT_BTN_LABEL = 'Exit'
  const RECENT_ISSUED_CODE_MSG = 'Your Most Recently Issued Code:'
  const NOT_SAFARI_MSG = 'You must be using the Safari browser to redeem automatically.'

  const { code } = props
  const dispatch = useDispatch()

  const isRedeemCodeDialogOpen = useSelector((state) => state.workerRedemptionCode.isRedeemCodeDialogOpen)

  const handleExitButtonClick = () => {
    dispatch(setIsRedeemCodeDialogOpen(false))
  }

  const handleRedeemCodeDialogCodeClick = () => {
    dispatch(setIsRedeemCodeDialogOpen(false))
  }

  const handleClosingRedeemCodeDialogCodeDialog = () => {
    dispatch(setIsRedeemCodeDialogOpen(false))
  }

  const renderInformationalSection = () => {
    return (
      <React.Fragment>
        <Typography gutterBottom>{INFO_MSG_1}</Typography>
        <Typography variant={'caption'}>
          <Info sx={styles.infoIcon} />
          {INFO_MSG_2}
        </Typography>
      </React.Fragment>
    )
  }

  const renderRedeemAutomaticallyActions = () => {
    let appleUrl = `${apiConfig.worker.appleUrl}` + Object.values({ code })
    return (
      <Button
        onClick={handleRedeemCodeDialogCodeClick}
        color="primary"
        variant="contained"
        target="_blank"
        href={appleUrl}
        rel="noopener noreferrer"
        disabled={!isSafari}
      >
        {REDEEM_AUTOMATICALLY_BTN_LABEL}
      </Button>
    )
  }

  const renderNotSafariMsg = () => {
    return (
      <div style={styles.buttonWarningMessageContainer}>
        <WarningIcon sx={styles.icon} />
        <Typography>{NOT_SAFARI_MSG}</Typography>
      </div>
    )
  }

  const renderRedeemAutomaticallyContent = () => {
    return (
      <React.Fragment>
        <Typography variant={'body2'} gutterBottom>
          {REDEEM_AUTOMATICALLY_MSG_1a}
          <strong>{code}</strong>
          {REDEEM_AUTOMATICALLY_MSG_1b}
        </Typography>
        <Typography variant="caption">
          <Info sx={styles.infoIcon} />
          {REDEEM_AUTOMATICALLY_MSG_2}
        </Typography>
        <Typography variant="caption">{REDEEM_AUTOMATICALLY_MSG_2a}</Typography>
        <Typography variant="caption">{REDEEM_AUTOMATICALLY_MSG_2b}</Typography>
        <Typography variant="caption">{REDEEM_AUTOMATICALLY_MSG_2c}</Typography>
        <Typography variant="caption">{REDEEM_AUTOMATICALLY_MSG_2d}</Typography>
      </React.Fragment>
    )
  }

  const renderRedeemAutomaticallySection = () => {
    return (
      <Card>
        <CardContent>
          <div style={styles.cardTitleContainer}>
            <Typography variant="subtitle1" component="h3" gutterBottom sx={styles.cardTitle}>
              {REDEEM_AUTOMATICALLY_HEADER}
            </Typography>
          </div>
          {renderRedeemAutomaticallyContent()}
        </CardContent>
        <CardActions sx={styles.cardAction}>{renderRedeemAutomaticallyActions()}</CardActions>
        <CardActions sx={styles.cardAction}>{!isSafari ? renderNotSafariMsg() : null}</CardActions>
      </Card>
    )
  }

  const renderExitDialogSection = () => {
    return (
      <Button onClick={handleExitButtonClick} color="primary" variant="contained" data-cy="RedeemCodeDialogExitButton">
        {EXIT_BTN_LABEL}
      </Button>
    )
  }

  const renderRedeemManuallyContent = () => {
    return (
      <React.Fragment>
        <Typography variant={'body2'}>{REDEEM_MANUALLY_MSG}</Typography>
      </React.Fragment>
    )
  }

  const renderRedeemManuallyAction = () => {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{REDEEM_MANUALLY_PANEL_LABEL}</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderExpansionPanelDetailsContent()}</AccordionDetails>
      </Accordion>
    )
  }

  const renderExpansionPanelDetailsContent = () => {
    return (
      <List dense>
        <CopyToClipboard text={code} label={RECENT_ISSUED_CODE_MSG} labelWidth={240} />
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{REDEEM_INSTRUCTIONS_1}</Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{REDEEM_INSTRUCTIONS_2}</Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{REDEEM_INSTRUCTIONS_3}</Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{REDEEM_INSTRUCTIONS_4}</Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{REDEEM_INSTRUCTIONS_5}</Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{REDEEM_INSTRUCTIONS_6}</Typography>
        </ListItem>
        <ListItem sx={styles.listItemRoot}>
          <Typography variant="caption">{REDEEM_INSTRUCTIONS_7}</Typography>
        </ListItem>
      </List>
    )
  }

  const renderRedeemManuallySection = () => {
    return (
      <Card>
        <CardContent>
          <div style={styles.cardTitleContainer}>
            <Typography variant="subtitle1" component="h3" gutterBottom sx={styles.cardTitle}>
              {REDEEM_MANUALLY_HEADER}
            </Typography>
          </div>
          {renderRedeemManuallyContent()}
        </CardContent>
        <CardActions sx={styles.cardAction}>{renderRedeemManuallyAction()}</CardActions>
      </Card>
    )
  }

  const renderDialog = () => {
    return (
      <Dialog
        open={isRedeemCodeDialogOpen}
        // TransitionComponent={Transition}
        onClose={handleClosingRedeemCodeDialogCodeDialog}
        aria-labelledby="redeem-code-dialog-label"
        aria-describedby="redeem-code-dialog-description"
        data-cy="RedeemCodeDialog"
        PaperProps={{
          sx: {
            margin: '8px',
          },
        }}
      >
        <DialogTitle id="save-dialog-slide-title">{DIALOG_TITLE}</DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Grid container>
            <Grid item xs={12} sx={styles.gridItemText}>
              {renderInformationalSection()}
            </Grid>
            <Grid item xs={12} sx={styles.gridItemCard}>
              {renderRedeemAutomaticallySection()}
            </Grid>
            <Grid item xs={12} sx={styles.gridItemCard}>
              {renderRedeemManuallySection()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>{renderExitDialogSection()}</DialogActions>
      </Dialog>
    )
  }

  return renderDialog()
}

export default RedeemCodeDialogCodeDialog
