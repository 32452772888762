const HeartIcon = (props) => {
  return (
    <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Request Count</title>
      <g id="Heart-Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M4.80955708,1.00090478 C5.27369172,1.00936555 6.33365558,1.09692441 7.53998732,2.00286108 C7.85445234,2.2390195 8.28536235,2.8222801 8.83271736,3.75264288 L8.799,3.696 L8.89959678,3.52728348 C9.38354867,2.72748043 9.76965441,2.21933963 10.057914,2.00286108 C11.2642458,1.09692441 12.3242096,1.00936555 12.7883442,1.00090478 L12.9145829,1 L12.9145829,1 C13.413917,1 14.0707443,1.12636285 14.8850648,1.37908855 C15.6816972,1.95979504 16.1860538,2.45636066 16.3981347,2.86878541 C16.6102156,3.28121016 16.637676,4.14272538 16.4805158,5.45333105 C16.0755932,6.63589377 15.3800542,7.80613289 14.3938988,8.96404839 C13.4158385,10.1124588 11.5622483,11.6111025 8.83312834,13.4599794 L8.83271736,13.5059592 C8.82153278,13.4983985 8.81036274,13.4908436 8.79920725,13.4832944 L8.76518397,13.5059592 L8.76518397,13.5059592 L8.764773,13.4599794 C6.03565299,11.6111025 4.18206285,10.1124588 3.20400257,8.96404839 C2.21784717,7.80613289 1.53619987,6.68210998 1.15906067,5.59197967 C0.946979775,4.27260497 0.946979775,3.40670524 1.15906067,2.99428049 C1.37114157,2.58185574 1.88906685,2.04345843 2.71283651,1.37908855 C3.52715706,1.12636285 4.18398437,1 4.68331844,1 L4.68331844,1 Z"
          id="Combined-Shape"
          fill="#CC0000"
        ></path>
      </g>
    </svg>
  )
}
export default HeartIcon
