import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { showNotificationSuccess } from '../notification/actionCreator'
import {
  RESET_APPLE_IOS_DOWNLOAD_PAGE,
  SET_IS_ADDITIONAL_DEVICE_STEPS_DIALOG_OPEN,
  SET_IS_ISSUE_CODE_DIALOG_OPEN,
  SET_IS_REDEEM_CODE_DIALOG_OPEN,
  SET_SCREEN_CONTENT,
  SET_WORKER_REDEMPTION_CODE_LOADING,
  WORKER_REDEMPTION_CODE_GET_ERROR,
  WORKER_REDEMPTION_CODE_GET_SUCCESS,
} from './actionType'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

export function handleWorkerRedemptionCodeGetData(tmNumber, fetchNewCode) {
  return (dispatch) => {
    let apiUrl =
      `${apiConfig.worker.url}` +
      '/worker_redemption_codes?key=' +
      `${apiConfig.worker.key}` +
      '&worker_id=' +
      tmNumber +
      '&fetch_new_code=' +
      fetchNewCode

    return axios
      .put(apiUrl, null, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleSuccess(response))
        fetchNewCode && dispatch(showNotificationSuccess(true, 'Code issued successfully'))
      })
      .catch((response) => {
        dispatch(handleError(response))
      })
  }
}

export function handleError(record) {
  return {
    type: WORKER_REDEMPTION_CODE_GET_ERROR,
    record,
  }
}

export function handleSuccess(record) {
  return {
    type: WORKER_REDEMPTION_CODE_GET_SUCCESS,
    record,
  }
}

export function setLoading(text) {
  return {
    type: SET_WORKER_REDEMPTION_CODE_LOADING,
    payload: text,
  }
}

export function setScreenContent(screenContent) {
  return {
    type: SET_SCREEN_CONTENT,
    payload: screenContent,
  }
}

export function setIsIssueCodeDialogOpen(isOpen) {
  return {
    type: SET_IS_ISSUE_CODE_DIALOG_OPEN,
    isOpen: isOpen,
  }
}

export function setIsRedeemCodeDialogOpen(isOpen) {
  return {
    type: SET_IS_REDEEM_CODE_DIALOG_OPEN,
    isOpen: isOpen,
  }
}

export function setIsAdditionalDeviceStepsDialogOpen(isOpen) {
  return {
    type: SET_IS_ADDITIONAL_DEVICE_STEPS_DIALOG_OPEN,
    isOpen: isOpen,
  }
}

export function handleResetAppleIosDownloadPage() {
  return {
    type: RESET_APPLE_IOS_DOWNLOAD_PAGE,
  }
}
