import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import moment from 'moment'
import React from 'react'
import { Element } from 'react-scroll'
import RequestTimeOffDetailsByDate from './RequestPaidTimeOffDetailsByDate'

const RequestPaidTimeOffList = (props) => {
  const { timeOffRequestMap, handleTimeOffRequestMap, timeOffRequestMessageMap } = props

  const handleRequestDetailPerDate = (requestDate, requestDetailPerDate) => {
    timeOffRequestMap.set(requestDate, requestDetailPerDate)
    handleTimeOffRequestMap(timeOffRequestMap)
  }

  const renderRequestTimeOffDetailsByDateList = () => {
    const outputDateFormat = 'dddd, MMMM D'

    let list = []
    timeOffRequestMap.forEach((v, date) => {
      let scheduleDate = moment(new Date(date), moment.HTML5_FMT.DATE)?.format(outputDateFormat)
      list.push(
        <Element name={date.toString()} key={date}>
          <ListItem sx={{ paddingX: '10px' }}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <RequestTimeOffDetailsByDate
                  data-cy={`requestTimeOffDetailsByDate_${scheduleDate}`}
                  requestDate={scheduleDate}
                  date={date}
                  requestDetailPerDate={v}
                  handleRequestDetailPerDate={(requestDate, requestDetailPerDate) =>
                    handleRequestDetailPerDate(requestDate, requestDetailPerDate)
                  }
                  messagesPerDate={timeOffRequestMessageMap.get(date)}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider aria-hidden="true" />
        </Element>,
      )
    })

    return list
  }

  return <React.Fragment>{renderRequestTimeOffDetailsByDateList()}</React.Fragment>
}

export default RequestPaidTimeOffList
