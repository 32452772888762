import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { toLower } from 'lodash'
import { useDispatch } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { showNotificationError } from '../../store/notification/actionCreator'
import { initializeRequestConfig } from '../../store/requestTimeOff/actionCreator'
import { useUser } from '../../auth/hooks/useUser'

const getRequestConfig = async (configFunctionality, locationType) => {
  let feature = configFunctionality

  if (feature === 'time_off') {
    feature = `${feature}_${toLower(locationType)}`
  }

  let apiUrl =
    `${apiConfig.configuration.url}` +
    '/configurations' +
    '?configuration_type=types' +
    '&configuration_functionality=' +
    feature +
    '&key=' +
    `${apiConfig.configuration.key}`

  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

export function useRequestConfig(configFunctionality) {
  const { isAuthenticated, login } = useAuth()
  const dispatch = useDispatch()
  const { userData, locationData } = useUser().user
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: [
      'requestConfigurations',
      {
        configFunctionality: configFunctionality,
        locationType: locationData.location_type,
      },
    ],
    queryFn: () => getRequestConfig(configFunctionality, locationData.location_type),
    staleTime: 1000 * 5,
    keepPreviousData: true,
    enabled: authenticated && !!configFunctionality && !!userData.features,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    onSuccess: (response) => {
      dispatch(initializeRequestConfig(configFunctionality, response.data))
    },
    onError: (error) => {
      const loggingInfo = {
        message: 'GET /configurations response has an error.',
        url: window.location.href,
        error: {
          url: error?.config?.url,
          data: error?.response?.data,
          headers: error?.response?.headers,
          status: error?.response?.status,
          statusText: error?.response?.statusText,
          configFunctionality: configFunctionality,
          location: locationData.location_id,
        },
      }
      const optionalLoggingOptions = {
        level: LogLevel.Error,
      }
      logEvent(loggingInfo, optionalLoggingOptions)
    },
    onSettled: (response, error) => {
      if (!response?.data && error) {
        dispatch(showNotificationError(true, 'Error loading configuration items'))
      } else if (response?.data?.total_configuration_values === 0) {
        dispatch(showNotificationError(true, 'No configured types for your role'))
      }
    },
  })
}
