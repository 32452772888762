/* * * * * * * * * * * * * * * *
In order to differentiate onClick and onDoubleClick events, the solution lies in delaying the execution of the onClick handler until we know that the click is not part of a double-click: The onClick handlers are delayed for a while in a queue of pending promises and, when a dblclick event is triggered, all the pending promises are cancelled, so they will never be executed.

The delay is added like a wrapper. In other words, isolated from original cancalable promise logic so that we reuse it (original cancelable promise) for other purpose
* * * * * * * * * * * * * * * * */

import { CancelablePromise } from './cancellable-promise'

export const delay = (n) => new Promise((resolve) => setTimeout(resolve, n))

export default CancelablePromise
