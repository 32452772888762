import { MenuItem, TextField } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setSelectedCategory } from '../../../../store/leader/actionCreator'

const SelectCategory = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { category } = useParams()
  const selectableCategories = useSelector((state) => state.leader.selectableCategories)
  const selectedCategory = useSelector((state) => state.leader.selectedCategory)

  useEffect(() => {
    if (selectableCategories?.includes(category) && category !== selectedCategory) {
      dispatch(setSelectedCategory(category))
    }
  }, [category, dispatch, selectableCategories, selectedCategory])

  const handleSelectCategoryChange = (event) => {
    dispatch(setSelectedCategory(event.target.value))
    navigate('/leader/myteam/' + event.target.value)
  }

  return (
    <TextField
      id="select-category"
      select
      label="Select Category"
      aria-label={'Select category'}
      value={selectedCategory}
      onChange={handleSelectCategoryChange}
      data-cy="selectCategory"
      fullWidth
      sx={{ minWidth: '200px' }}
    >
      {selectableCategories ? (
        selectableCategories?.map((selectCategoryValue) => (
          <MenuItem key={selectCategoryValue} value={selectCategoryValue}>
            {selectCategoryValue}
          </MenuItem>
        ))
      ) : (
        <MenuItem key={'No Categories Available'}>No Categories Available</MenuItem>
      )}
    </TextField>
  )
}

export default SelectCategory
